import { push } from "connected-react-router";

import { setError, clearError } from "State/error/creators";
import { setPolicy } from "State/policy/creators";
import {
  clearJoinData,
  setJoinShipping,
  setJoinPlan,
  clearJoinStatus,
  setJoinAccount,
  setJoinType,
  setGiftRejoin,
  setJoinStatusFail,
  setRejoinBasePath,
} from "State/joinData/creators";
import {
  leadCapture,
  ecommerceTracking,
  enrollConfirmation,
  dataLayerEventTracking,
} from "Utils/analytics";
import { getPlans } from "Utils/getPlans";
import {
  setCancelStatusPending,
  setCancelStepSuccess,
  setCancelStatusFailure,
  setCancelStatusSuccess,
} from "State/cancel/creators";
import { setAccountHistory } from "State/accountHistory/creators";
import {
  setAccount,
  setAccountRenewalDate,
  setAccountPolicyEffectiveDate,
  setAccountReferrals,
  setAccountAnchorResponse,
  setAccountRenewalPlan,
} from "State/account/creators";

import { setBlueBox, setMD22 } from "State/promo/creators";
import { setSiteResponse, showBoxCoupon } from "State/ui/creators";
import { setLoginAttempt, setCourtesyPassword } from "State/loading/creators";

import {
  impactAffiliatePixelEnroll,
  sasAffiliatePixelEnroll,
} from "State/affiliates/flows";

import { setSiteMessage } from "State/ui/creators";
import { setBox } from "State/box/creators";
import { setTranche } from "State/tranche/creators";
import { setAddress } from "State/address/creators";
import { setBookshelf } from "State/bookshelf/creators";
import { setMessage } from "State/accountMessage/creators";
import { setGiftPurchasePromo } from "State/gift/creators";
import * as accountSelectors from "State/account/selectors";
import * as accountMessageSelectors from "State/accountMessage/selectors";
import { JOIN_LOGGING_IN } from "State/joinData/actions";
import { setLoginFlow } from "State/loginFlow/creators";
import { setJoinFlow } from "State/joinFlow/creators";
import { replaceRejoinPlans } from "State/store/creators";

import { setOverlay } from "State/ui/creators";
import { expireActiveCookies } from "Utils/cookie";
import { identifyAccount } from "Utils/klaviyo";
import { setWishlist } from "State/accountWishlist/creators";
import { getPaymentMethod } from "State/memberPaymentMethod";
import { datadogLogs } from "@datadog/browser-logs";
import { setAppFeatures } from "State/appFeatures/creators";
import { cleanupMessages } from "../accountMessage/flows";

import * as accountApi from "./api";
import * as accountHistoryApi from "../accountHistory/api";
import * as addressApi from "../address/api";
import * as appFeatureApi from "../appFeatures/api";
import * as bookshelfApi from "../bookshelf/api";
import * as boxApi from "../box/api";
import * as svcApi from "../svc/api";
import * as trancheApi from "../tranche/api";
import * as storeApi from "../store/api";
import * as wishlistApi from "../accountWishlist/api";
import * as accountMessageApi from "../accountMessage/api";
import { snesRedirect } from "Utils/snesRedirect";

export const authUser =
  (track, store, isFromSnesEnroll) => async (dispatch) => {
    try {
      const loginData = await accountApi.authedLoginData(isFromSnesEnroll);
      dispatch(loginFlows(loginData, store, track, false));
      dispatch(setLoginAttempt());
    } catch (err) {
      dispatch(setLoginAttempt());
      dispatch(setPolicy(store.visitorPolicy));
    }
  };

export const checkCourtesyPassword = (oFormData) => async (dispatch) => {
  try {
    await accountApi.checkCourtesyPassword(oFormData);
    dispatch(setCourtesyPassword(oFormData?.password));
  } catch (e) {
    dispatch(setError(e.message, "login"));
    console.error(e);
  }
};

export const accountLogin =
  (oFormData, store, track) => async (dispatch, getState) => {
    const entryPoint = getState().analytics.entryPoint;
    const isLoyaltyRejoin = ["/friend", "/bff1", "/bff2"].includes(
      entryPoint?.toLowerCase(),
    );
    try {
      const loginData = await accountApi.login(oFormData);
      loginData.policyType === "Rejoin" &&
      loginData.policySubType !== "CC Fail" &&
      loginData.policySubType !== "Grace Period" &&
      typeof window !== "undefined"
        ? window?.location.assign(isLoyaltyRejoin ? entryPoint : loginData.url)
        : snesRedirect("snes", loginData.url); //refactor in fastLogin route to use accountUrl after rejoin flow moved
      dispatch(loginFlows(loginData, store, track));
      // setting the account on datadog whenever an account is set
      datadogLogs.logger.info("Login", {
        id: loginData?.account?.id, // required
        username: loginData?.account?.firstName,
        email: loginData?.account?.email,
      });
    } catch (e) {
      dispatch(setError(e.message, "login"));
      console.error(e);
      // need this for fb login
      if (typeof document !== "undefined") {
        window?.scrollTo(0, 0);
        document.getElementById("bodyWrapper").scrollTop = 0;
      }
      return { failedToLogin: true };
    }
  };

export const createAccount =
  (accountInfo, successFunction, failFunction, acceptTos = false) =>
  async (dispatch, getState) => {
    try {
      const account = await accountApi.createAccount(accountInfo, acceptTos);
      const loyaltyStats = await accountHistoryApi.getLoyaltyStats(account.id);
      if (typeof document !== undefined) {
        let pixel = document.createElement("img");
        pixel.src = "https://p.liadm.com/p?c=18200";
        window.document.body.appendChild(pixel);
      }
      leadCapture({ id: account.id, email: account.email });
      dispatch(clearError());
      account.policy.planSet.plans = getPlans(
        account.policy.planSet,
        getState()?.store?.data?.track?.id,
      );
      dispatch(setAccount({ ...account, loyaltyStats }));
      identifyAccount(account);
      dispatch(setPolicy(account.policy));
      dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
      if (account.policy.planSet) {
        dispatch(replaceRejoinPlans(account.policy.planSet));
      }
      dispatch(setAccountHistory(await accountHistoryApi.history()));
      if (account.addressId) {
        dispatch(setAddress(await addressApi.get(account.addressId)));
      }
      if (account.hasLeadCapture) {
        return account;
      }
      successFunction?.();
    } catch (e) {
      if (e.message === "Rejoin") {
        dispatch(setJoinType("rejoin"));
        switch (getState().loginFlow) {
          case "rejoin-a":
            dispatch(setLoginFlow("rejoin-a"));
            dispatch(setJoinFlow("rejoin-a"));
            break;
          case "rejoin-c":
            dispatch(setLoginFlow("rejoin-c"));
            dispatch(setJoinFlow("rejoin-c"));
            break;
          case "rejoin-f":
            dispatch(setLoginFlow("rejoin-f"));
            dispatch(setJoinFlow("rejoin-f"));
            break;
          default:
            dispatch(setLoginFlow("rejoin"));
            dispatch(setJoinFlow("rejoin"));
            break;
        }
        dispatch(setError("Looks like you already have an account."));
        failFunction?.({ error: "Looks like you already have an account." });
      } else {
        dispatch(setError(e.message, "email"));
        failFunction?.({ error: e.message });
      }
      console.error("flows.js:createAccount:Error creating account", e);
    }
  };

export const join =
  ({
    type,
    address,
    plan,
    nonce,
    promoCode,
    confLink,
    holidayCode,
    renewalPlan,
    planUpgrade,
    applePayNonce,
    successFn = null,
    failFn = null,
  }) =>
  async (dispatch, getState) => {
    // dispatch(setJoinStatusPending());
    const isRejoin = type === "rejoin";
    const isUnPause = type === "unpause";
    const isUpgrade = planUpgrade;
    const isUpsell = type === "upsell";
    const isGiftRejoin = type === "gift rejoin";
    const isYearlyUpsell = type === "yearly upsell";
    const isMonthlyPlus = type === "monthly+";
    try {
      const account = getState().account;
      const store = await storeApi.get();
      const referCode = getState().joinData.referralCode;
      const newAddressId = address
        ? (await addressApi.create(address))["id"]
        : account.addressId;
      const billingUrl = `${getState().analytics.origin}${
        getState().analytics.location
      }`;
      const canJoinAndShip = accountSelectors.canJoinAndShip(getState());
      if (!account.hasPassword) {
        throw new Error(
          "Please enter a password that’s at least 4 characters.",
        );
      }
      let shipBox = false;
      expireActiveCookies("referCode");

      const preShippedBox = await boxApi.get();
      if (
        (store.pickingPeriodOpen || canJoinAndShip) &&
        preShippedBox.books.length &&
        (preShippedBox.books.some((b) => b && b.isSelection) ||
          account.policy.canShipWithoutSelection) && // Ship first box without selection if policy allows
        !isUpgrade //don't ship box for membership upgrades
      ) {
        shipBox = true;
      }
      const [joinAh] = await accountApi.join({
        account: { ...account, addressId: newAddressId },
        plan,
        nonce,
        promoCode,
        holidayCode,
        referralCode: referCode,
        billingUrl,
        shipBox,
        renewalPlan,
        isUpgrade,
        isUpsell: planUpgrade || isUpsell,
        applePayNonce,
      });
      const updatedAccount = await accountApi.findAccountById(account.id);
      const loyaltyStats = await accountHistoryApi.getLoyaltyStats(account.id);
      const box = await boxApi.get();

      const confirmationLink = (() => {
        if (confLink === "loyalty") {
          return "/loyalty-rejoin-confirmation";
        } else if (confLink == "bff") {
          return "/bff-rejoin-confirmation";
        } else if (confLink == "tier") {
          return "/tier-rejoin-confirmation";
        }
        if (plan?.id === 200054) {
          return "/enroll/confirmation";
        }
        if (isUpsell) {
          return "/membership-upgrade/confirmation";
        }

        if (isRejoin || isGiftRejoin) {
          if (loyaltyStats?.boxes == 12) {
            return "/bff-rewards";
          }
          return "/rejoin-confirmation";
        }
        if (isUnPause) {
          return "/unpause-confirmation";
        }
        if (planUpgrade) {
          return null;
        }
        if (isYearlyUpsell) {
          dispatch(clearJoinData());
          return "/post-enroll/survey/step-flow/1";
        }
        if (isMonthlyPlus) {
          return null;
        }
        dispatch(clearJoinData());
        return "/post-enroll/survey/step-flow/1";
        //return "/enroll/confirmation";
      })();
      // this is only needed for beta feature rejoins
      dispatch(setAppFeatures(await appFeatureApi.all()));

      let enrollmentType = (() => {
        if (isRejoin || isGiftRejoin) {
          return "rejoin";
        } else if (isUpsell) {
          return "upsell";
        } else if (isUnPause) {
          return "unpause";
        } else {
          return "enroll";
        }
      })();
      dispatch(setJoinType("join"));

      if (isGiftRejoin) {
        dispatch(setGiftRejoin());
      }
      if (confirmationLink) {
        if ([301, 311, 341].includes(account.policy?.id)) {
          successFn?.();
        } else {
          dispatch(
            push({
              pathname: confirmationLink,
              state: shipBox,
            }),
          );
        }
      }
      dispatch(setJoinFlow(""));
      dispatch(clearError());
      dispatch(setBox(box));
      updatedAccount.policy.planSet.plans = getPlans(
        updatedAccount.policy.planSet,
        getState()?.store?.data?.track?.id,
      );

      dispatch(setAccount({ ...updatedAccount, loyaltyStats }));
      identifyAccount(account);
      dispatch(setAccountHistory(await accountHistoryApi.history()));
      dispatch(setPolicy(updatedAccount.policy));
      if (updatedAccount.policy.planSet) {
        dispatch(replaceRejoinPlans(updatedAccount.policy.planSet));
      }
      dispatch(
        setAccountPolicyEffectiveDate(
          await accountHistoryApi.getEffectivePolicyDate(),
        ),
      );
      dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
      dispatch(setBookshelf(await bookshelfApi.getAccountBookshelf()));
      dispatch(setWishlist(await wishlistApi.getWishlist()));
      dispatch(setAccountRenewalPlan(updatedAccount.renewalPlan));
      if (updatedAccount.tranche) {
        dispatch(setTranche(await trancheApi.get(updatedAccount.tranche)));
      }
      if (updatedAccount.addressId) {
        dispatch(setAddress(await addressApi.get(updatedAccount.addressId)));
      }
      dispatch(clearJoinStatus());
      dispatch(setBlueBox(false)); //remove when bluebox rejoin campaign ends (see: promo/reducer)
      dispatch(setMD22(false)); //remove when MD22 campaign ends (see: promo/reducer)

      //assign post cycle survey to those enrolling with book
      const messages = await accountMessageApi.getMessagesByMember();
      dispatch(setMessage(cleanupMessages(messages)));

      window?.sessionStorage?.removeItem("userType");
      let storeState = getState();
      try {
        ecommerceTracking(enrollmentType, joinAh, updatedAccount.lastPlan, {
          code: promoCode,
        });
        if (enrollmentType == "rejoin") {
          dataLayerEventTracking("Rejoin", undefined, {
            orderId: joinAh?.id,
          });
        }
      } catch (e) {
        console.error("flows.js:enroll:Error dispatch ecommerceTracking", e, {
          storeState,
          enrollmentType,
        });
      }
      if (type !== "rejoin") {
        try {
          enrollConfirmation({
            enrollmentType,
            email: updatedAccount.email,
            firstName: updatedAccount.firstName,
            lastName: updatedAccount.lastName,
            planName: updatedAccount.lastPlan.name,
            orderId: joinAh.id,
            orderTotal: joinAh.lines.reduce(
              (sum, line) => (sum += line.amount),
              0,
            ),
          });
        } catch (e) {
          console.error("enrollConf", e);
        }
        if (updatedAccount.trackId === 1) {
          //CORE
          try {
            sasAffiliatePixelEnroll(updatedAccount, plan, promoCode);
          } catch (e) {
            console.error("flows.js:enroll:Error sasAffiliatePixelEnroll", e, {
              storeState,
              enrollmentType,
            });
          }
        }
      }
      try {
        impactAffiliatePixelEnroll(
          updatedAccount,
          plan,
          storeState,
          enrollmentType,
          promoCode,
        );
      } catch (e) {
        console.error("flows.js:enroll:Error impactAffiliatePixelEnroll", e, {
          storeState,
          enrollmentType,
        });
      }
      successFn?.(loyaltyStats);
    } catch (e) {
      if (typeof document !== "undefined") {
        window.scrollTo(0, 0);
        document.getElementById("bodyWrapper").scrollTop = 0;
      }
      console.error("e.message", e.message);
      if (
        e.message == "Please enter a password that’s at least 4 characters."
      ) {
        dispatch(setError(e.message, "password"));
        failFn?.({ error: e.message, type: "password" });
      } else {
        console.error("e", e);
        dispatch(setError(e.message, "braintree"));
        failFn?.({ error: e.message, type: "braintree" });
      }
      dispatch(setJoinStatusFail());
    }
  };

export const updateAccount =
  (updateObj, store, successFn = null, failFn = null, acceptTos = false) =>
  async (dispatch, getState) => {
    try {
      const account = await accountApi.updateAccount(updateObj, acceptTos);
      if (account) {
        account.policy.planSet.plans = getPlans(
          account.policy.planSet,
          getState()?.store?.data?.track?.id,
        );
        let paymentMethod = getState().account.paymentMethod;
        const loyaltyStats = await accountHistoryApi.getLoyaltyStats(
          account.id,
        );
        dispatch(setAccount({ ...account, paymentMethod, loyaltyStats }));
        identifyAccount(account);
        dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
        dispatch(setAccountRenewalPlan(account.renewalPlan));
        // DATA-LOAD TODO fix this reference to store data
        if (account?.policy?.id === store?.leadPolicy?.id) {
          dispatch(setJoinAccount(account));
          if (account.addressId) {
            dispatch(setJoinShipping(await addressApi.get(account.addressId)));
          }
        } else if (account?.addressId) {
          dispatch(setAddress(await addressApi.get(account.addressId)));
        }
      }
      successFn?.();
    } catch (e) {
      if (
        e.message == "Please enter a password that’s at least 4 characters."
      ) {
        dispatch(setError(e.message, "password"));
        failFn?.({ error: e.message, type: "password" });
      } else if (e.message.toLowerCase().includes("email")) {
        dispatch(setError(e.message, "email"));
        failFn?.({ error: e.message, type: "email" });
      } else {
        dispatch(setError(e.message, "braintree"));
        failFn?.({ error: e.message, type: "braintree" });
      }
      console.error("Error updating member info: ", e);
    }
  };

export const resetPassword = async (email, successFn, failFn) => {
  try {
    await accountApi.resetPassword(email);
    if (successFn) {
      successFn();
    }
  } catch (e) {
    console.error("resetPassword error: ", e);
    if (failFn) {
      failFn();
    }
  }
};
export const saveAccountAvatar = async (
  profileImg,
  accountId,
  successFn = null,
  failFn = null,
) => {
  try {
    await accountApi.updateAvatar(profileImg, accountId);
    if (successFn) {
      successFn();
    }
  } catch (e) {
    console.error("saveAccountAvatar error: ", e);
    if (failFn) {
      failFn();
    }
  }
};

export const changePassword = (updateObj) => async (dispatch) => {
  try {
    await accountApi.changePassword(updateObj);
    dispatch(
      setSiteResponse({
        message: "Success! Your password has been reset. Please log in.",
        type: "success",
      }),
    );
    dispatch(push("/login"));
  } catch (e) {
    dispatch(
      setSiteResponse({
        message: "Error updating your password",
        type: "failure",
      }),
    );
    console.error("Error updating member password: ", e);
  }
};

export const checkChangeToken = (token) => async () => {
  try {
    await accountApi.checkChangeToken(token);
    return "valid";
  } catch (e) {
    console.error("Error with change password token: ", e);
  }
};

export const addHolidayCredit = (holidayCode) => async (dispatch, getState) => {
  try {
    const account = await accountApi.addHolidayCredit(holidayCode);
    if (account) {
      account.policy.planSet.plans = getPlans(
        account.policy.planSet,
        getState()?.store?.data?.track?.id,
      );
      dispatch(setAccount(account));
    }
  } catch (e) {
    console.error("Error adding credit to member: ", e);
  }
};

export const changeRenewalPlan =
  (planId, overridePlans = false, plans = null, acceptTos = false) =>
  async (dispatch, getState) => {
    try {
      const account = await accountApi.changeRenewalPlan(
        planId,
        overridePlans,
        plans,
        acceptTos,
      );
      if (account) {
        const loyaltyStats = await accountHistoryApi.getLoyaltyStats(
          account.id,
        );
        dispatch(clearError());
        account.policy.planSet.plans = getPlans(
          account.policy.planSet,
          getState()?.store?.data?.track?.id,
        );
        dispatch(setAccount({ ...account, loyaltyStats }));
        dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
        dispatch(setAccountRenewalPlan(account.renewalPlan));
      }
    } catch (e) {
      dispatch(setError(e.message, "renewal_plan"));
      console.error("failure to change", e);
    }
  };

export const changeMustRenewSaveOfferRenewalPlan =
  (newPlanCredits) => async (dispatch, getState) => {
    try {
      const account = await accountApi.changeMustRenewSaveOfferRenewalPlan(
        newPlanCredits,
      );
      if (account) {
        const loyaltyStats = await accountHistoryApi.getLoyaltyStats(
          account.id,
        );
        dispatch(clearError());
        account.policy.planSet.plans = getPlans(
          account.policy.planSet,
          getState()?.store?.data?.track?.id,
        );
        dispatch(setAccount({ ...account, loyaltyStats }));
        dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
        dispatch(setAccountRenewalPlan(account.renewalPlan));
      }
    } catch (e) {
      dispatch(setError(e.message, "renewal_plan"));
      console.error("failure to change", e);
    }
  };

export const changeEmailPreference =
  (account, preferences, successFn, failFn) => async (dispatch) => {
    try {
      await svcApi.klaviyoUnsubscribe(account, preferences);
      successFn();
    } catch (error) {
      failFn(error);
    }
  };

export const getEmailPreference = (email) => async (dispatch) => {
  try {
    return await svcApi.getEmailPreferences(email);
  } catch (error) {
    console.error("TCL:: getEmailPreference -> error", error);
    return {};
  }
};

export const giveSMSConsent =
  (phone, phoneType, successFn, failFn) => async (dispatch) => {
    try {
      await fetch(`${global.apiHost}/api/v2/botm/account/smsConsent`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ phoneNumber: phone, phoneType: phoneType }),
      });
      successFn();
    } catch (error) {
      console.error("TCL:: giveSMSConsent -> error", error);
      failFn(error);
    }
  };

export const logout = (nextPath) => async (dispatch, getState) => {
  await accountApi.logout();
  // DATA-LOAD TODO fix this reference to store data
  if (getState().store.data.defaultGiftPromo) {
    dispatch(setGiftPurchasePromo(getState().store.data.defaultGiftPromo));
  }
  let basename =
    nextPath ||
    (getState().analytics.basename.length && getState().analytics.basename) ||
    "/";
  location.replace(basename);
};

export const cancelAccount = () => async (dispatch, getState) => {
  dispatch(setCancelStatusPending());
  try {
    const account = await accountApi.cancelAccount();
    const loyaltyStats = await accountHistoryApi.getLoyaltyStats(account.id);
    account.policy.planSet.plans = getPlans(
      account.policy.planSet,
      getState()?.store?.data?.track?.id,
    );

    dispatch(setAccount({ ...account, loyaltyStats }));
    dispatch(setPolicy(account.policy));
    if (account.policy.planSet) {
      dispatch(replaceRejoinPlans(account.policy.planSet));
    }
    dispatch(setAccountHistory(await accountHistoryApi.history()));
    dispatch(
      setAccountPolicyEffectiveDate(
        await accountHistoryApi.getEffectivePolicyDate(),
      ),
    );
    dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
    dispatch(setAccountRenewalPlan(account.renewalPlan));
    dispatch(
      setAccountAnchorResponse(
        await accountHistoryApi.getAnchorResponse(account),
      ),
    );
    dispatch(setBox(await boxApi.get()));
    dispatch(setCancelStatusSuccess());
    dispatch(setCancelStepSuccess());
    dispatch(push("/cancel-membership/success"));
  } catch (e) {
    console.error("Error canceling account", e);
    dispatch(setCancelStatusFailure());
  }
};

export const getReferrals = (accountId) => async (dispatch) => {
  try {
    const referrals = await accountApi.getReferrals(accountId);
    dispatch(setAccountReferrals(referrals));
  } catch (e) {
    console.error("Error getting account referrals", e);
  }
};

export const checkEmail = (email) => async () => {
  return await accountApi.checkEmail(email);
};

export const skipRenewal =
  (adjustment, pause = false, successFn, failFn) =>
  async (dispatch, getState) => {
    //cc:skipRenewal#2
    //cc:pauseRenewal#2
    try {
      const updatedAccount = await accountApi.skipRenewal(adjustment, pause);
      const loyaltyStats = await accountHistoryApi.getLoyaltyStats(
        updatedAccount.id,
      );
      updatedAccount.policy.planSet.plans = getPlans(
        updatedAccount.policy.planSet,
        getState()?.store?.data?.track?.id,
      );

      dispatch(setAccount({ ...updatedAccount, loyaltyStats }));
      dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
      dispatch(setAccountHistory(await accountHistoryApi.history()));
      dispatch(setPolicy(updatedAccount.policy));
      dispatch(setJoinType("rejoin"));
      // DATA-LOAD TODO fix this reference to store data
      dispatch(setJoinPlan(updatedAccount.policy.planSet.plans[0]));
      if (successFn) {
        successFn();
      }
    } catch (e) {
      console.error("skipRenewal error: ", e);
      if (failFn) {
        failFn("Hmm, something went wrong.");
      }
    }
  };

export const loginFlows =
  (loginData, store, track, isLogin = true) =>
  async (dispatch, getState) => {
    if (loginData) {
      let { joinData, loginFlow } = getState();

      const getMessage = (messageName) =>
        accountMessageSelectors.getMessages(getState(), messageName);
      dispatch(clearError());
      identifyAccount(loginData.account);
      if (loginData?.account?.policy?.planSet) {
        loginData.account.policy.planSet.plans = getPlans(
          loginData.account.policy.planSet,
          getState()?.store?.data?.track?.id,
        );
      }
      dispatch(setAccount(loginData.account));
      dispatch(setAccountHistory(loginData.accountHistory));
      dispatch(setAccountPolicyEffectiveDate(loginData.effectiveDate));
      dispatch(setAccountAnchorResponse(loginData.anchorResponse));
      dispatch(setAccountRenewalDate(loginData.renewalDate));

      if (!joinData.plan) {
        dispatch(setAccountRenewalPlan(loginData.account.renewalPlan));
      }
      if (loginData.tranche) {
        dispatch(setTranche(loginData.tranche));
      }
      if (loginData.address) {
        dispatch(setAddress(loginData.address));
      }
      dispatch(setMessage(loginData.messages));
      dispatch(setBookshelf(loginData.bookshelf));
      dispatch(setWishlist(loginData.wishlist));
      dispatch(setPolicy(loginData.account.policy));
      dispatch(setBox(loginData.box));
      dispatch(setAppFeatures(await appFeatureApi.all()));
      if (loginData.account.policy.planSet) {
        const rejoinPlanSet = loginData.account.policy.planSet;
        if (rejoinPlanSet && rejoinPlanSet.plans) {
          dispatch(replaceRejoinPlans(rejoinPlanSet));
        }
      }
      const bffShipFreeBoty =
        getState()?.appFeatures?.find((f) => f?.feature == "BFF Ship BOTY")
          ?.status == "Active";
      const isFreeBotyQualified =
        loginData.account?.cycleActions?.bffMemberNoCycleResponse;

      const shipsFreeBoty = bffShipFreeBoty && isFreeBotyQualified;

      const toteMessage = getMessage("Bff welcome");
      const bdayMessage = getMessage("Bff birthday");

      let zeroIndexedCycle = ((store.currentCycle.id + 12) % 12) - 1;

      if (loginData.account.policy.subType !== "CC Fail") {
        if (
          toteMessage ||
          (bdayMessage &&
            !(
              loginData.account.birthday &&
              loginData.account.birthday.month !== null
            ))
        ) {
          setTimeout(
            () =>
              dispatch(
                setOverlay("loyalty", "", {
                  step: toteMessage ? "welcome" : "birthday",
                }),
              ),
            2500,
          );
        }
        if (zeroIndexedCycle < 0) {
          zeroIndexedCycle = 12 + zeroIndexedCycle;
        }
        if (
          loginData.account.coupons?.some?.((c) => c.type === "Birthday") &&
          loginData.account.birthday?.month === zeroIndexedCycle &&
          loginData.account.cycleActions?.bffMemberNoCycleResponse &&
          store.pickingPeriodOpen
        ) {
          setTimeout(
            () =>
              dispatch(
                setOverlay("LoyaltyBirthdayMonthModal", "", {
                  birthdaymonth: loginData.account.birthday.month,
                }),
              ),
            2500,
          );
        } else if (shipsFreeBoty) {
          setTimeout(
            () => dispatch(setOverlay("freeBotyModal", "cleanSlate")),
            1000,
          );
        }
      }
      if (
        !["Lead", "User"].includes(loginData.account.policy.type) &&
        (loginData.account.policy.canShip ||
          loginData.account.policy.type === "Rejoin")
      ) {
        joinData = getState().joinData;
        loginFlow = getState().loginFlow;
        const policy = loginData.account.policy;

        if (policy.type === "Rejoin") {
          dispatch(setJoinType("rejoin"));
          if (joinData.rejoinBase === "") {
            dispatch(setRejoinBasePath("credit-rejoin"));
          }
          if (
            !(
              ["gift-holiday-offer", "bff-rewards", "/gift/redeem"].includes(
                loginFlow,
              ) || joinData.grifterCode
            )
          ) {
            if (!joinData.plan) {
              if (loginData.account.renewalPlan) {
                dispatch(setJoinPlan(loginData.account.renewalPlan));
              } else if (
                (loginFlow === "rejoin-a" ||
                  policy.subType === "Rejoin A" ||
                  (policy.type === "Rejoin" && policy.subType === "Gift") ||
                  policy.id === 405) /*EXPERIMENT*/ &&
                !loginData.account.renewalPlan
              ) {
                if (joinData.rejoinBase !== "rejoin-a") {
                  dispatch(setRejoinBasePath("credit-rejoin-a"));
                }
                if (loginFlow !== "rejoin-a") {
                  dispatch(setLoginFlow("rejoin-a"));
                }
              } else if (
                (loginFlow === "rejoin-c" ||
                  policy.subType === "Rejoin C" ||
                  policy.id === 406) /*EXPERIMENT*/ &&
                !loginData.account.renewalPlan
              ) {
                if (joinData.rejoinBase !== "rejoin-c") {
                  dispatch(setRejoinBasePath("credit-rejoin-c"));
                }
                if (loginFlow !== "rejoin-c") {
                  dispatch(setLoginFlow("rejoin-c"));
                }
              }
            }
            if (!getState().loginFlow) {
              dispatch(setLoginFlow("rejoin"));
            }
          }
          if (isLogin && policy.subType === "CC Fail") {
            if (!loginData.account.renewalPlan) {
              dispatch(
                setJoinPlan(loginData.account?.policy?.planSet?.plans?.[0]),
              );
            }
          }
        } else {
          dispatch(clearJoinData());
        }

        if (loginData.account?.coupons?.length) {
          dispatch(
            showBoxCoupon({
              type: "addOnCoupon",
              showPlace: { giftSuccess: false, boxMessaging: true },
            }),
          );
        }
        if (joinData?.grifterCode) {
          dispatch(
            showBoxCoupon({
              type: "freeMonth",
              showPlace: { giftSuccess: true },
            }),
          );
        }

        if (global.location && /redirect/.test(global.location.search)) {
          const redirect = global.location.search
            .split("&")
            .map((q) => q.split("="))
            .filter((q) => /redirect/.test(q[0]))[0][1];
          return dispatch(push(decodeURIComponent(redirect)));
        }
      } else if (
        loginData.account.policy.type === "Lead" ||
        loginData.account.policy.type === "User"
      ) {
        if (!(joinData.referralCode || joinData.grifterCode)) {
          dispatch(setJoinPlan(store.enrollmentPlans.plans[0]));
        }
        let currentPath = getState().analytics.location;
        if (
          currentPath.indexOf("enroll") >= 0 ||
          currentPath.indexOf("login") >= 0
        ) {
          if (getState().joinData.step !== JOIN_LOGGING_IN) {
            return dispatch(push("/logging-in"));
          } else {
            return dispatch(push("/enroll/plan"));
          }
        }
      } else if (
        accountSelectors.filedChargeback(getState()) ||
        accountSelectors.underAge(getState()) ||
        accountSelectors.deleteInfo(getState())
      ) {
        dispatch(setOverlay("BanModal"));
      }
    }
  };

export const optOutOfLoyaltyTote = (successFn, failFn) => async (dispatch) => {
  try {
    await accountHistoryApi.optOutOfLoyaltyTote();
    if (successFn) {
      successFn?.();
    }
  } catch (e) {
    if (e.message === "User does not have tote") {
      return successFn?.();
    }
    if (failFn) {
      failFn?.("Hmm, something went wrong.");
    }
    dispatch(setError(e.message, "toteDecline"));
  }
  dispatch(setBox(await boxApi.get()));
  dispatch(setAccountHistory(await accountHistoryApi.history()));
};

export const optIntoLoyaltyTote = (successFn, failFn) => async () => {
  try {
    await accountHistoryApi.optIntoLoyaltyTote();
    if (successFn) {
      successFn?.();
    }
  } catch (e) {
    console.error("Error on account optIntoLoyatlyTote: ", e.message);
    failFn?.(e.message);
  }
};

export const clearJoin = () => (dispatch) => {
  dispatch(clearJoinData());
};

export const markUnderThirteenAccount = async () => {
  try {
    await accountApi.markUnderThirteenAccount();
  } catch (e) {
    console.error("markUnderThirteen error: ", e);
  }
};

export const updateOccupation = (occupation, successFn) => async (dispatch) => {
  try {
    const account = await accountApi.updateOccupation(occupation);
    if (account) {
      dispatch(setAccount(account));
    }
    if (successFn) {
      successFn?.();
    }
  } catch (e) {
    console.error("Updating Occupation error: ", e);
  }
};
