import * as actions from "./actions";

export default function reducer(state = [], action) {
  switch (action.type) {
    case actions.SET_DISPLAYED_ORDER_HISTORY:
      return action.payload;
    default:
      return state;
  }
}
