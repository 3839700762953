/**
 * Displays message to user if app fails to properly load all css.
 * Prevents broken page view.
 * Child of BOM.jsx
 */

import React from "react";
import { Link } from "react-router-dom";
import LogoBom from "UI/elements/LogoBom";

const CssFailMessage = () => {
  const styleBoxWrapper = {
    textAlign: "center",
    height: "100vh",
    backgroundColor: "var(--color-gray1)",
    paddingTop: "80px",
  };

  const styleBox = {
    borderRadius: "4px",
    border: "1px solid var(--color-gray2)",
    backgroundColor: "var(--color-white)",
    padding: "60px",
    textAlign: "center",
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
  };

  const logoWrapper = {
    marginBottom: "20px",
    display: "block",
    textAlign: "center",
  };

  const logo = <LogoBom />;

  return (
    <div className="dontShow" style={styleBoxWrapper}>
      <div style={styleBox}>
        <div style={logoWrapper}>
          <Link to="/">{logo}</Link>
        </div>
        Oops! The page didn’t load right. Please refresh and try again.
      </div>
    </div>
  );
};

export default CssFailMessage;
