/**
 * AlertPage.jsx
 * Default Alert page for Oops(crashes), 404s, and Coming Soon Splash Pages  (ie. Québec)
 * Child of BOM.jsx
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import { Link } from "UI/elements/navigation/Link";
import DownloadAppButtons from "UI/components/app_download/DownloadAppButtons.jsx";
import { useWindowSize } from "UI/hooks";
import { COLORS } from "CSS/Consts.js";
import { useLocation } from "react-router";
import { snesRedirect } from "Utils/snesRedirect";

const AlertPage = ({
  imageUrl,
  header,
  dek,
  vhFix = false,
  imageCustomStyle = {},
  headerMarginBottom = 0,
  secondaryHeader,
  secondaryDek,
  prevExistedPage,
  showLink = true,
}) => {
  const [width] = useWindowSize();
  const isMobile = width <= 480;
  const location = useLocation();
  const { pathname } = location || {};

  useEffect(() => {
    snesRedirect("snes", pathname);
  }, []);

  if (prevExistedPage) {
    return (
      <StyledAlertPageWrapper id={"Alert page"}>
        <img
          src={
            imageUrl
              ? imageUrl
              : "//static.bookofthemonth.com/discussions/Illustration_404Page.jpg"
          }
          style={{
            width: isMobile ? 140 : 184,
            height: isMobile ? 150 : 204,
            marginBottom: 20,
          }}
          alt="Oops!"
        />
        <h1>
          This page no <span className="nowrap">longer exists.</span>
        </h1>
        <p style={{ maxWidth: 440, textAlign: "center", marginTop: 20 }}>
          You’ve reached a page that is no longer here. Don’t fret! We are
          always improving our services, so head to our app for our latest and{" "}
          <span className="nowrap">greatest features!</span>
        </p>
        <StyledAppDownloadWrapper>
          <DownloadAppButtons />
        </StyledAppDownloadWrapper>
      </StyledAlertPageWrapper>
    );
  }

  return (
    <StyledAlertPageWrapper
      id={"Alert page"}
      headerMarginBottom={headerMarginBottom}
      vhFix={vhFix}
    >
      <img
        src={
          imageUrl || "//static.bookofthemonth.com/informers/Icon_Murder.svg"
        }
        style={{ height: 80, marginBottom: 10, ...imageCustomStyle }}
        alt="Oops!"
      />
      <h1
        style={{
          paddingBottom: isMobile ? 20 : 40,
        }}
      >
        {header || "Oops!"}
      </h1>
      <p>{dek || "Looks like something went wrong."}</p>
      {Boolean(secondaryHeader || secondaryDek) && <StyledDivider />}
      {secondaryHeader && <h1>{secondaryHeader || "Oops!"}</h1>}
      {secondaryDek && (
        <p>{secondaryDek || "Looks like something went wrong."}</p>
      )}
      {showLink ? (
        <Link
          top={20}
          bottom={0}
          text={"See the books"}
          size={18}
          navigate={() =>
            window.location.assign(
              "https://www.bookofthemonth.com/the-best-new-books",
            )
          }
        />
      ) : null}
    </StyledAlertPageWrapper>
  );
};

const StyledDivider = styled.div`
  width: 560px;
  background: ${COLORS.gray2};
  height: 1px;
  margin-top: 4px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const StyledAlertPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  text-align: center;
  padding: 80px 20px ${({ vhFix }) => (vhFix ? 160 : 0)}px 20px;
  h1 {
    margin-bottom: ${({ headerMarginBottom }) => headerMarginBottom}px;
  }
  p {
    max-width: 52ch;
  }
  @media screen and (max-width: 480px) {
    padding: 40px 20px 40px 20px;
  }
`;

export const StyledAppDownloadWrapper = styled.div.attrs({
  className: "sc-StyledAppDownloadWrapper",
})`
  margin-top: 20px;
  margin-bottom: 40px;
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default connect(null, mapDispatchToProps)(AlertPage);
