import React from "react";
import SkeletonWrapper, {
  P,
  RadioButton,
  Button,
  Word,
} from "./SkeletonWrapper.jsx";

const SurveyQuestionsSkeleton = () => (
  <SkeletonWrapper>
    <Word className="-smallFont" />
    <ul className="checkWrapper surveyQuestions">
      {new Array(6).fill(0).map((ignore, i) => (
        <li key={i}>
          <label>
            <RadioButton />
            <P className="-smallFont" />
          </label>
        </li>
      ))}
    </ul>
    <div className="actions">
      <Button />
    </div>
  </SkeletonWrapper>
);

export default SurveyQuestionsSkeleton;
