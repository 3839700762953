import {
  defaultForm,
  changeAddressForm,
  issueWithBookForm,
  findMyboxForm,
  bffBirthdayForm,
  orderMarkedAsDeliveredButDidntReceive,
} from "../pages/help/FormData";
import { IssueTypeValue } from "../pages/help";
import { mappedIssueTypes, FormAction } from "../pages/help/util";
import { SelectOption, FieldType, ImageData, FieldSet } from "../elements/form";
import { subjectDropdownOptions } from "../pages/help/FormData";
import { CountryCode } from "../../utils/countryPricing";

// An interface for our actions
type FormReducerType = {
  type: FormAction;
  payload: {
    fieldName?: string;
    value: string | ImageData;
    options?: SelectOption[];
    canadaMode?: boolean;
  };
};

const getFieldsWithValues = (state: FieldSet) => {
  let result: FieldSet = {};
  const fieldsToCheck = ["name", "email", "description"];

  fieldsToCheck.forEach((k) => {
    const f = state[k];
    if (f) {
      const fieldValue = f.value as string;
      if (fieldValue?.length > 0) {
        result[k] = f;
      }
    }
  });
  return result;
};

export const formReducer = (state: FieldSet, action: FormReducerType) => {
  const fieldsWithValues = getFieldsWithValues(state);
  const { fieldName = "", value, canadaMode } = action.payload || {};
  const isDamagedOrMisprinted =
    value == "Damaged" || value == "Misprinted book";
  const type: FieldType = canadaMode ? "text" : "number";

  switch (action.type) {
    case FormAction.CLEAR_FORM:
      return {
        ...defaultForm,
      };
    case FormAction.DEFAULT_FORM:
      return {
        ...defaultForm,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
      };
    case FormAction.BFF_BIRTHDAY:
      return {
        ...bffBirthdayForm,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
      };
    case FormAction.CHANGE_ADDRESS:
      return {
        ...changeAddressForm,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
        country: {
          ...changeAddressForm["country"],
          value: canadaMode ? CountryCode.CA : CountryCode.USA,
        },
        province: {
          ...changeAddressForm["province"],
          required: canadaMode,
          visible: canadaMode,
        },
        state: {
          ...changeAddressForm["state"],
          required: !canadaMode,
          visible: !canadaMode,
        },
        zip: { ...changeAddressForm["zip"], type },
      };
    case FormAction.FIND_MY_BOX:
      return {
        ...findMyboxForm,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
        country: {
          ...findMyboxForm["country"],
          value: canadaMode ? CountryCode.CA : CountryCode.USA,
        },
        province: {
          ...findMyboxForm["province"],
          required: canadaMode,
          visible: canadaMode,
        },
        state: {
          ...findMyboxForm["state"],
          required: !canadaMode,
          visible: !canadaMode,
        },
        zip: { ...findMyboxForm["zip"], type: type },
      };
    case FormAction.MARKED_AS_DELIVERED_NOT_RECEIVED:
      return {
        ...orderMarkedAsDeliveredButDidntReceive,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
        country: {
          ...orderMarkedAsDeliveredButDidntReceive["country"],
          value: canadaMode ? CountryCode.CA : CountryCode.USA,
        },
        province: {
          ...orderMarkedAsDeliveredButDidntReceive["province"],
          required: canadaMode,
          visible: canadaMode,
        },
        state: {
          ...orderMarkedAsDeliveredButDidntReceive["state"],
          required: !canadaMode,
          visible: !canadaMode,
        },
        zip: { ...orderMarkedAsDeliveredButDidntReceive["zip"], type },
      };
    case FormAction.ISSUE_WITH_BOOK:
      return {
        ...issueWithBookForm,
        ...fieldsWithValues,
        issueType: { ...state["issueType"] },
        [fieldName]: { ...state[fieldName], value },
      };
    case FormAction.SET_ISSUE_TYPE:
      const isSubjectVisible = value != mappedIssueTypes.SOMETHING_ELSE;
      return {
        ...defaultForm,
        ...fieldsWithValues,
        [fieldName]: { ...state[fieldName], value },
        subject: {
          ...state["subject"],
          value: "",
          options: subjectDropdownOptions[value as IssueTypeValue],
          visible: isSubjectVisible,
          required: isSubjectVisible,
        },
      };
    case FormAction.SET_FIELD_VALUE:
      let currentState = state;
      if (fieldName == "country" && value == CountryCode.CA) {
        currentState = {
          ...currentState,
          province: {
            ...currentState["province"],
            required: true,
            visible: true,
          },
          state: {
            ...currentState["state"],
            required: false,
            visible: false,
            value: "",
          },
          zip: { ...currentState["zip"], type: "text", value: "" },
        };
      } else if (fieldName == "country" && value == CountryCode.USA) {
        currentState = {
          ...currentState,
          province: {
            ...currentState["province"],
            required: false,
            visible: false,
            value: "",
          },
          state: { ...currentState["state"], required: true, visible: true },
          zip: { ...currentState["zip"], type: "number", value: "" },
        };
      }
      // if (fieldName == 'bookIssueType' && isDamagedOrMisprinted){
      //   currentState =  {
      //     ...currentState,
      //     'attachment': { ...currentState['attachment'], required: true},
      //     [fieldName]: { ...state[fieldName], value },
      //   };
      // } else if (fieldName == 'bookIssueType' && !isDamagedOrMisprinted) {
      //   currentState =  {
      //     ...currentState,
      //     'attachment': { ...currentState['attachment'], required: false},
      //     [fieldName]: { ...state[fieldName], value },
      //   };
      // }
      return {
        ...currentState,
        [fieldName]: { ...state[fieldName], value },
      };
    default:
      return state;
  }
};
