import { createSelector } from "reselect";

const getJoinData = (state) => state.joinData;
const getReferralPlan = (state) =>
  (state.store && state.store.data && state.store.data.referralPlan) || [];
const getEnrollmentPlans = (state) =>
  (state.store && state.store.data && state.store.data.enrollmentPlans.plans) ||
  [];
const getRejoinPlans = (state) =>
  (state.store && state.store.data && state.store.data.rejoinPlans.plans) || [];

export const isReferred = createSelector(
  getJoinData,
  getReferralPlan,
  (jd, rp) => {
    return !!(jd.referralCode || (jd.plan && rp.id && jd.plan.id === rp.id));
  },
);

export const joinPlans = createSelector(
  getJoinData,
  getEnrollmentPlans,
  getRejoinPlans,
  (jd, ep, rp) => {
    return jd.type === "rejoin" ? rp : ep;
  },
);
