import React, { Component, Fragment } from "react";

class Button extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      id = "",
      type,
      style,
      modifier,
      action,
      isPending,
      text = "",
    } = this.props;
    text = isPending ? <Fragment>&nbsp;</Fragment> : text;

    const handleAction = (evt) => {
      evt?.stopPropagation();
      action?.(evt);
    };

    return (
      <button
        id={id}
        className={style + (isPending ? " -pending" : "")}
        style={modifier}
        type={type}
        onClick={handleAction}
      >
        {text}
      </button>
    );
  }
}

export default Button;
