/**
 * Modal for App Download.
 *
 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "UI/elements/form/Button";
import { useWindowSize } from "UI/hooks";

const BffExperimentModal = ({ overlay, setOverlay }) => {
  const { push } = useHistory();
  const hero = "//static.bookofthemonth.com/boty/Hero_BffPopup";
  const [width] = useWindowSize();
  const smallScreen = width < 400;
  const hedSize = smallScreen ? "2.5rem" : "4rem";
  const lineHeight = smallScreen ? "3rem" : "5rem";

  if (!overlay || (overlay && overlay.name !== "BffExperimentModal")) {
    return null;
  }

  const closeNGo = () => {
    setOverlay(null);
    push("/best-books-of-the-year");
  };

  return (
    <StyledBffExperimentModal id="StyledBffExperimentModal">
      <div className="heroContainer">
        <picture>
          <source
            srcSet={`${hero}.webp`}
            className="-heroImage"
            type="image/webp"
          />
          <source srcSet={`${hero}.jpg`} className="-heroImage" />
          <img src={`${hero}.jpg`} className="-heroImage" alt="Hero image" />
        </picture>
      </div>
      <div className="contentContainer">
        <img
          src="//static.bookofthemonth.com/boty/Bff_Badge.png"
          className="bffBadge"
        />
        <h4
          style={{ marginTop: 50, lineHeight: lineHeight, fontSize: hedSize }}
        >
          Hey BFF, ready for your{" "}
          <span className="nowrap">free BOTY finalist?</span>
        </h4>
        <p className="dek">
          Add your pick of the Book of the Year finalists to your December box,
          on us. Go, go, go!
        </p>
        <Button
          id="viewFinalists"
          style="primary"
          handleClick={() => closeNGo()}
          isPending={false}
          title={"View the finalists"}
        />
      </div>
    </StyledBffExperimentModal>
  );
};

export const StyledBffExperimentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  min-width: 200px;
  max-width: 594px;

  picture {
    display: flex;
  }

  .heroContainer img {
    width: 100%;
    align-self: center;
  }

  .contentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 40px;
  }

  img.bffBadge {
    width: 70px;
    position: absolute;
    left: calc(50% - 35px);
    top: -35px;
  }

  .contentContainer h2 {
    padding-top: 60px;
  }

  .contentContainer .dek {
    padding-bottom: 40px;
  }

  #viewFinalists {
    width: unset;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    store: state,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BffExperimentModal);
