/**
 * Shared App Download buttons.
 *
 */

import React from "react";
import { logClick } from "State/analytics/flows";
import styled from "styled-components";
import { connect } from "react-redux";
import { useWindowSize } from "UI/hooks";
import { browserCheck } from "Utils/browserCheck";

const DownloadAppButtons = ({ size, iOSOnly, ...props }) => {
  const [width] = useWindowSize();
  const isMobile = width <= 480;
  const isIphone = browserCheck.isIphone;
  const isAndroid = browserCheck.isAndroid;

  const buttonSize = () => {
    switch (size) {
      case "medium":
        return 148;
      case "small":
        return 120;
      default:
        return 175;
    }
  };
  const [downloadIOSAppLink, downloadAndroidAppLink] = [
    <a
      key={"IsoDownload"}
      onClick={() =>
        handleOutsideTrackNavigate(
          "Featured selections - clicks_ios_app_download",
          "itms-apps://apps.apple.com/us/app/book-of-the-month/id1473873226",
        )
      }
    >
      <img
        src={`//static.bookofthemonth.com/svgs/app_store_badge_ios.svg`}
        alt="get the ios app"
        className="iosImg"
      />
    </a>,
    <a
      key={"IAndroidDownload"}
      onClick={() =>
        handleOutsideTrackNavigate(
          "Featured selections - clicks_android_app_download",
          "https://play.google.com/store/apps/details?id=com.bookofthemonth",
        )
      }
    >
      <img
        src={`//static.bookofthemonth.com/svgs/app_store_badge_android.svg`}
        alt="get the android app"
        className="androidImg"
      />
    </a>,
  ];

  const handleOutsideTrackNavigate = (trackLink, path) => {
    logClick(trackLink, { getState: () => props.store });
    window?.open(path, "_blank");
  };

  let buttons;
  if (isMobile && !isAndroid) {
    buttons = downloadIOSAppLink;
  } else if (isMobile && isAndroid) {
    buttons = downloadAndroidAppLink;
  } else if (isMobile && !isIphone && iOSOnly) {
    buttons = null;
  } else if (!isMobile && !iOSOnly) {
    buttons = [downloadIOSAppLink, downloadAndroidAppLink];
  } else if (!isMobile && iOSOnly) {
    buttons = downloadIOSAppLink;
  }

  return (
    <StyledAppButtonsWrapper
      iosButtonSize={buttonSize()}
      buttonSize={buttonSize()}
    >
      {buttons}
    </StyledAppButtonsWrapper>
  );
};

export const StyledAppButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a:first-of-type {
    margin-right: 10px;
  }
  a:last-of-type {
    margin-left: 10px;
  }
  .androidImg,
  iosImg {
    width: ${(props) => `${props.buttonSize}px`};
  }
`;

export const StyledEXPAppButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  img {
    width: 170px;
  }
`;

function mapStateToProps(state) {
  return {
    store: state,
  };
}

export default connect(mapStateToProps)(DownloadAppButtons);
