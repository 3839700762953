/**
 * JoinEnterEmailOnly.jsx
 * Lead capture form
 *   props
    - buttonText
    - nextAction 
    - fullWidthButtons 
    - isLeadsGate 
    - showSecondaryLink
 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { createAccount } from "State/account/flows";
import { clearError } from "State/error/creators";
import { experimentFlowTracking } from "Utils/analytics"; //exp7
import { addToBox, makeSelection } from "State/box/flows";
import { FormSkeleton } from "UI/loadingSkeletons/FormPageSkeleton.jsx";
import FieldsetAccountInfo from "UI/components/forms/FieldsetAccountInfo";
import { setOverlay } from "State/ui/creators";
import { snesRedirect } from "Utils/snesRedirect";

const JoinEnterEmailOnly = ({ setOverlay, ...props }) => {
  const successCB = (account, isAlt) => {
    let { joinData } = props;
    let plan = joinData.plan;
    experimentFlowTracking("Create Account");

    const handlePush = (path) => {
      props.push(path);
      setOverlay(null);
    };

    if (joinData?.product) {
      if (joinData.promo && joinData.promo.productConsumesCredit) {
        props.makeSelection(joinData.product, null, props.canadaMode);
      } else {
        props.addToBox(joinData.product, null, props.canadaMode);
      }
    }

    if (props.nextAction) {
      if (isAlt && !props.canadaMode) {
        handlePush("/join-billing");
      }
      props.nextAction();
    } else if (plan || isAlt) {
      handlePush("/join-billing");
    } else {
      snesRedirect("snes", "/the-best-new-books");
    }
  };

  if (props.showSkeleton) {
    return <FormSkeleton numInput={1} />;
  }

  return (
    <>
      {props.isMobileLanding ? <p>Create your free account.</p> : ""}
      <FieldsetAccountInfo
        fieldsToShow={["email"]}
        successCB={successCB}
        bottom={0}
        showTermsBlock={props.showTermsBlock}
        buttonText={props.buttonText}
        showSecondaryLink={props.showSecondaryLink}
        logClickData={"Lead Gate - clicks_sign_up"}
        top={20}
        formId={props.inputId}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    joinData: state.joinData,
    account: state.account,
    error: state.error ? state.error.error : null,
    overlay: state.ui ? state.ui.overlay : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { push, clearError, createAccount, addToBox, makeSelection, setOverlay },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinEnterEmailOnly);
