/**
 * PaymentInfoEdit.jsx
 * This is a wrapper for Braintree so that a user can update their payment info
 */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { clearError } from "State/error/creators";
import { getPaymentMethod, getClientToken } from "State/memberPaymentMethod";
import Braintree from "UI/components/Braintree.jsx";

const PaymentInfoEdit = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);

  useEffect(() => {
    setPaymentMethod(props.paymentMethod);
    if (paymentMethod?.updatedAt != props?.paymentMethod?.updatedAt) {
      props.setOverlay("");
    }
  });

  return (
    <>
      <form id="hosted-fields-form">
        <Braintree
          useSubmitButton={true}
          submitButtonText={"Save"}
          error={props.braintreeError}
        />
      </form>
    </>
  );
};

function mapStatetoProps(state) {
  return {
    paymentMethod: state.account?.paymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getPaymentMethod, getClientToken, clearError, setOverlay },
    dispatch,
  );
}

export default connect(mapStatetoProps, mapDispatchToProps)(PaymentInfoEdit);
