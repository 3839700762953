import * as React from "react";
import styled from "styled-components";
import { BORDER } from "../../../css/Consts";

export const MobileNavSecondaryBlock: React.FC<{}> = ({ children }) => {
  return (
    <StyledMobileNavSecondaryBlock id={"StyledMobileNavSecondaryBlock"}>
      {children}
    </StyledMobileNavSecondaryBlock>
  );
};

export const StyledMobileNavSecondaryBlock = styled.div`
  .button,
  button {
    width: 100%;
    margin-bottom: 12px;
  }
  li.subMenu {
    padding-bottom: 0px;
    padding-top: 12px;
    a {
      color: var(--color-gray5);
    }
  }
`;

export const StyledMobileNavSecondaryBlockWrapper = styled.div`
  text-align: center;
  border-top: ${BORDER.defaultBorder};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 20px;
  background-color: var(--color-gray1);
`;

export default MobileNavSecondaryBlock;
