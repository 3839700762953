import flattenArray from "./flattenArray";
import urlify from "./urlify.js";
import getGracePeriodCancelDate from "./getGracePeriodCancelDate.js";
import { queryparser } from "./queryparser.js";
import { scrollAnimate } from "./animations.js";
import * as cookies from "./cookie.js";
import mainGenre from "./mainGenre";
import * as analytics from "./analytics";
import { IPlan, IPlanSet } from "../../../types/plan";
import { countryPlanPrice } from "./countryPricing";
import { AppState } from "../types";
import { ICycle } from "../../../types/cycle";

export const convertStringMonthToNumber = (month: string) => {
  if (isNaN(parseInt(month))) {
    switch (month) {
      case "January":
        return 1;
      case "February":
        return 2;
      case "March":
        return 3;
      case "April":
        return 4;
      case "May":
        return 5;
      case "June":
        return 6;
      case "July":
        return 7;
      case "August":
        return 8;
      case "September":
        return 9;
      case "October":
        return 10;
      case "November":
        return 11;
      case "December":
        return 12;
      default:
        return 0;
    }
  }

  return parseInt(month);
};

const isDefined = (obj: any) =>
  Array.isArray(obj)
    ? obj.every((o) => typeof o !== "undefined" && o !== null)
    : typeof obj !== "undefined" && obj !== null;

const _MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const _SELECT_MONTHS = [
  { label: "", value: null },
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sept", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];

export const _SELECT_NUMBERS: Record<number, string> = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
};

const getNumericalMonth = (cycle: Partial<ICycle> | ICycle | undefined) => {
  return !cycle || !cycle.id
    ? -1
    : (cycle.id + 12) % 12
    ? (cycle.id + 12) % 12
    : 12;
};
const getDateSuffix = (date: number) => {
  if (date > 3 && date < 21) {
    return "th";
  }
  switch (date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getCostPerBook = (
  price: number | undefined,
  cycles: number,
  countryCode = "US",
) => {
  if (!price || !cycles) {
    return null;
  }
  const costPerBook = Math.round((price / cycles) * 100) / 100;
  if (countryCode === "CA") {
    // return exact price per book value for Canadian users
    return costPerBook.toFixed(2);
  }
  let integerValue = Math.floor(costPerBook);
  let decimalValue = costPerBook - integerValue;

  if (integerValue == 5) {
    return integerValue;
  } else if (decimalValue < 0.1) {
    decimalValue = 0.99;
    integerValue -= 1;
  } else if (decimalValue < 0.6) {
    decimalValue = 0.5;
  } else {
    decimalValue = 0.99;
  }

  return (integerValue + decimalValue).toFixed(2);
};

const getSaveAmount = (monthlyPrice: number, yearlyPrice: number) => {
  if (!monthlyPrice || !yearlyPrice) return "";
  const difference = monthlyPrice * 12 - yearlyPrice;
  return Math.ceil(difference).toFixed(0);
};

// @ts-ignore
const matchedKeys = (regex: RegExp, obj) =>
  Object.keys(obj).reduce((o, key) => {
    if (regex.test(key)) {
      // @ts-ignore
      o[key] = obj?.[key];
    }
    return o;
  }, {});

export {
  flattenArray,
  getGracePeriodCancelDate,
  urlify,
  isDefined,
  cookies,
  analytics,
  getNumericalMonth,
  getDateSuffix,
  queryparser,
  scrollAnimate,
  mainGenre,
  getCostPerBook,
  getSaveAmount,
  _MONTHS,
  _SELECT_MONTHS,
  matchedKeys,
};

export const getPricing = (
  plan?: IPlan,
  countryCode?: string,
  account?: AppState["account"],
) => {
  if (!plan)
    return {
      PRICE: "",
      PRICE_PER_CREDIT: "",
      SAVINGS_PER_CREDIT: "",
    };
  //! getCostPerBook should be costPerCredit and be driven by plan.price and plan.credits
  const planPrice = countryPlanPrice(plan, countryCode) || 0;
  const planPricePerCredit =
    getCostPerBook(planPrice, plan.credits, countryCode) || 0;
  const purchasedPlanPrice =
    countryPlanPrice(account?.renewalPlan as IPlan, countryCode) || 0;
  const purchasedPlanPricePerCredit =
    getCostPerBook(
      purchasedPlanPrice,
      account?.renewalPlan?.credits || 0,
      countryCode,
    ) || 0;
  let savings = Math.ceil(
    Number(purchasedPlanPricePerCredit) - Number(planPricePerCredit),
  );
  savings = savings > 0 ? savings : 0;
  countryPlanPrice(account?.renewalPlan as IPlan, countryCode) || 0;
  return {
    PRICE: planPrice,
    PRICE_PER_CREDIT: planPricePerCredit,
    SAVINGS_PER_CREDIT: savings,
  };
};

export const getRenewalDate = (
  renealDate: Date,
  overrideOptions?: Intl.DateTimeFormatOptions,
) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;
  const RENEWAL_DATE = renealDate.toLocaleDateString(
    "en-US",
    overrideOptions || options,
  );
  return RENEWAL_DATE;
};

export const getPlanType = (plan?: IPlan) => {
  if (!plan) return "";
  if (plan?.credits == 1) return "Monthly";
  if (plan?.credits == 2) return "2 credit";
  if (plan?.credits >= 12) return "Yearly";
  return "";
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export function planSetPlanFromCredits(planset: IPlanSet, credits: number) {
  return planset?.tracks?.[1]?.find((p) => p?.credits == credits);
}
