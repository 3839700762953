export function browserCheck() {
  if (typeof navigator !== "undefined") {
    let appName = navigator.appName,
      userAgent = navigator.userAgent,
      tem,
      m = userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i,
      );

    if (m && (tem = userAgent.match(/version\/([\.\d]+)/i)) != null) {
      m[2] = tem[1];
    }
    m = m ? [m[1], m[2]] : [appName, navigator.appVersion, "-?"];

    let browser = m[0],
      versionRaw = m[1].split("."),
      version = Number(versionRaw[0]);
    const isIOS = /iPhone|iPod|iPad/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    return {
      name: browser,
      version: version,
      os: navigator.appVersion,
      isIOS: isIOS,
      isAndroid: isAndroid,
    };
  } else {
    return {
      name: null,
      version: null,
      os: null,
      isIOS: null,
      isAndroid: null,
    };
  }
}

export function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}
