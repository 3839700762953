import * as actions from "./actions";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_GENRES:
      return action.payload;
    default:
      return state;
  }
}
