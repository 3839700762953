import React from "react";
import { useParams } from "react-router";
import Landing from "./Landing";
import Authenticate from "../../../ui/skeleton/Authenticate";
import PauseLanding from "../pause_membership/PauseLanding";
import RelationshipStatus from "../cancel_membership/RelationshipStatus";
import CancelConfirmation from "./CancelConfirmation";
import ResubscribeSurvey from "./ResubscribeSurvey";
import ThankYou from "./ThankYou";
import Confirmation from "../cancel_membership/Confirmation";
import SaveOffer from "../cancel_membership/SaveOffer";
import SavedConfirmation from "../cancel_membership/SavedConfirmation";
import CancelWithSaveOffer from "../cancel_membership/CancelWithSaveOffer";

type ParamProps = {
  path?: string;
};
const CancelMembership: React.FC = () => {
  const params = useParams<ParamProps>();
  const content = params?.path || "";

  const setContent = () => {
    switch (content) {
      case "pause":
        return <PauseLanding />;
      case "perks":
        return <RelationshipStatus />;
      case "continue":
        return <Confirmation />;
      case "confirmation-perks":
        return <CancelConfirmation showPerks={true} />;
      case "confirmation":
        return <CancelConfirmation />;
      case "resubscribe":
        return <ResubscribeSurvey />;
      case "thanks":
        return <ThankYou />;
      case "save-offer":
        return <SaveOffer />;
      case "saved-confirmation":
        return <SavedConfirmation />;
      case "cancel-with-save-offer":
        return <CancelWithSaveOffer />;
      default:
        return <Landing />;
    }
  };
  return <Authenticate>{setContent()}</Authenticate>;
};

export default CancelMembership;
