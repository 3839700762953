import React from "react";
import { connect } from "react-redux";
import { useStoreData } from "../../hooks";
import dateformat from "dateformat";
import { countryPlanPrice } from "../../../utils/countryPricing";
import { AppState } from "../../../types";
import Spinner from "../../elements/Spinner";
import {
  CreditCommitProvider,
  useCreditCommit,
} from "../../contexts/CreditCommitContext";
import { _SELECT_NUMBERS } from "../../../utils/index";

const NonCancelModal: React.FC<NonCancelModalProps> = ({
  renewalPlanPrice,
  renewalPlanCredits,
  renewalDate,
  creditCommitCycles,
}) => {
  const nextCycleLabel = useStoreData()?.nextCycle?.label;
  const { renewalsRemainingInCommitment } = useCreditCommit();
  if (!renewalPlanCredits || !renewalPlanPrice || !nextCycleLabel) {
    return <Spinner />;
  }

  let renewalCommitmentText = `at least one time`;
  if (creditCommitCycles && creditCommitCycles > 1) {
    renewalCommitmentText = `at least ${_SELECT_NUMBERS[creditCommitCycles]} times`;
  }
  if (renewalsRemainingInCommitment) {
    if (renewalsRemainingInCommitment === 1) {
      renewalCommitmentText += `, and you have one renewal left`;
    } else if (renewalsRemainingInCommitment > 1) {
      renewalCommitmentText += `, and you have ${
        renewalsRemainingInCommitment in _SELECT_NUMBERS
          ? _SELECT_NUMBERS[renewalsRemainingInCommitment]
          : creditCommitCycles
      } renewals left`;
    }
  }
  const offerType =
    creditCommitCycles == 3 ? `$${renewalPlanPrice} special` : "enrollment";
  let copy = `As part of your ${offerType} offer, you agreed to be renewed ${renewalCommitmentText}. You are currently scheduled to be renewed on ${renewalDate} for $${renewalPlanPrice} and ${renewalPlanCredits} credit${
    renewalPlanCredits > 1 ? "s" : ""
  } will be added to your account. You can use ${
    renewalPlanCredits > 1 ? "those" : "that"
  } credit${
    renewalPlanCredits > 1 ? "s" : ""
  } to shop for one of our ${nextCycleLabel} selections. If you still want to cancel after fulfilling your renewal commitment (though we hope you decide to stick around a while), you can do so by returning to this page.`;

  return (
    <div>
      <p>{copy}</p>
    </div>
  );
};

type NonCancelModalProps = {
  renewalPlanCredits?: number;
  renewalPlanPrice?: number;
  renewalDate: string;
  creditCommitCycles?: number;
};
function mapStateToProps(state: AppState & NonCancelModalProps) {
  const countryCode = state?.account?.countryCode;
  const renewalPlan = state?.account?.renewalPlan;
  const { credits } = renewalPlan || {};
  const renewalPlanPrice = countryPlanPrice(renewalPlan, countryCode);
  const renewalDate = dateformat(
    state?.account?.renewalDate,
    "UTC:mmmm dS, yyyy",
  );

  return {
    renewalPlanCredits: credits,
    renewalPlanPrice: renewalPlanPrice,
    renewalDate: renewalDate,

    creditCommitCycles: state?.account?.policy?.creditCommitCycles,
  };
}

const NonCancelModalWithConnect = connect(mapStateToProps)(NonCancelModal);

const NonCancelModalWithProvider = () => (
  <CreditCommitProvider>
    <NonCancelModalWithConnect />
  </CreditCommitProvider>
);

export default NonCancelModalWithProvider;
