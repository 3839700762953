import { combineReducers } from "redux";
import * as actions from "./actions";

const list = (state = [], action) => {
  switch (action.type) {
    case actions.SET_SURVEYS:
      // if the state already contains the survey that just pulled, just return the original state,
      // otherwise push the new survey(s) onto the state array.
      return state.map((s) => s.id).includes(action.list?.[0].id)
        ? state
        : [...state, ...action.list];
    case actions.SET_SURVEY_RESPONSE:
      if (
        action.response &&
        action.response.surveyId &&
        state.filter((s) => s.id === action.response.surveyId)[0]
      ) {
        state
          .filter((s) => s.id === action.response.surveyId)[0]
          .surveyResponses.push(action.response);
      }
      return state;
  }
  return state;
};

const allSurveys = (state = [], action) => {
  switch (action.type) {
    case actions.SET_ALL_SURVEYS:
      return action.surveys;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case actions.REQUEST_SURVEYS:
      return false;
    case actions.SURVEYS_LOADED:
      return true;
  }
  return state;
};

const accountResponse = (state = {}, action) => {
  switch (action.type) {
    case actions.GET_ACCOUNT_SURVEY_RESPONSE:
      return state;
  }
  return state;
};

export default combineReducers({
  allSurveys,
  list,
  loaded,
  accountResponse,
});
