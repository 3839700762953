import * as actions from "./actions";

export function setError(error, type) {
  return {
    type: actions.SET_ERROR,
    payload: { error, type },
  };
}

export function clearError() {
  return {
    type: actions.CLEAR_ERROR,
  };
}
