import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppState, Dispatch } from "../../../types";
import {
  optOutOfLoyaltyTote,
  optIntoLoyaltyTote,
} from "../../../state/account/flows";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts.js";
import ClickTracker from "../../elements/ClickTracker";

type IToteActionsProps = {
  optOutOfLoyaltyTote: (
    successMessage: () => void,
    failMessage: (err: string) => void,
  ) => void;
  optIntoLoyaltyTote: (
    successMessage: () => void,
    failMessage: (err: string) => void,
  ) => void;
  setStep: (step?: string) => void;
} & MappedToteActionsProps;

const ToteActions: React.FC<IToteActionsProps> = ({
  optOutOfLoyaltyTote,
  optIntoLoyaltyTote,
  setStep,
  account,
}) => {
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState("");

  const successMessage = () => {
    setIsPending("");
    setStep(account?.birthday?.month ? "step4" : "step3");
  };

  const failMessage = (err: string) => {
    setIsPending("");
    setError(err);
  };

  const declineTote = async () => {
    setIsPending("decline");
    await optOutOfLoyaltyTote(successMessage, failMessage);
  };

  const acceptTote = async () => {
    setIsPending("accept");
    if (account?.cycleActions?.hasCycleResponse) {
      // since loyalty is opt in by default, we only really perform changes when a shipment has already occured.
      // in that case we refresh order statuts
      await optIntoLoyaltyTote(successMessage, failMessage);
    }
  };

  if (error && error == "User has already redeemed their tote") {
    return (
      <>
        {error && <p className="error">{error}</p>}
        <ClickTracker
          ctaType={"button"}
          id="loyalty-tier-tote-error-action"
          style={"primary"}
          handleClick={() =>
            setStep(account?.birthday?.month ? "step4" : "step3")
          }
          isPending={false}
          title={"Continue"}
        />
      </>
    );
  }

  return (
    <>
      {error && <p className="error">{error}</p>}
      <StyledTotActionsWrapper>
        <ClickTracker
          ctaType={"button"}
          id="loyalty-tier-tote-accept-action"
          style={"secondary"}
          handleClick={() => acceptTote()}
          isPending={isPending == "accept"}
          title={"Send the tote"}
          logClickData={"clicked-send-my-tote"}
        />

        <ClickTracker
          ctaType={"button"}
          id="loyalty-tier-tote-decline-action"
          style={"secondary"}
          handleClick={() => declineTote()}
          isPending={isPending == "decline"}
          title={"No thanks"}
          logClickData={"clicked-sno-thanks-tote"}
        />
      </StyledTotActionsWrapper>
    </>
  );
};

export const StyledTotActionsWrapper = styled.div.attrs({
  className: "sc-StyledTotActionsWrapper" as string,
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    max-width: 315px;
  }
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    button {
      max-width: 100%;
    }
  }
`;

type MappedToteActionsProps = Pick<AppState, "account">;

const mapStateToProps = (state: AppState): MappedToteActionsProps => {
  return {
    account: state.account,
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    { optOutOfLoyaltyTote, optIntoLoyaltyTote },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ToteActions);
