import React from "react";

const ModalClose = ({ onClick }) => (
  <div className="modalClose" onClick={onClick}>
    <svg
      width="14.782px"
      height="14.533px"
      viewBox="0 0 14.782 14.533"
      enableBackground="new 0 0 14.782 14.533"
    >
      <polygon
        fill="var(--color-white)"
        points="14.572,13.966 7.881,7.275 14.571,0.584 14.119,0.131 7.428,6.823 0.737,0.131 0.284,0.584
        6.976,7.275 0.284,13.966 0.737,14.419 7.428,7.728 14.119,14.419   "
      />
    </svg>
  </div>
);

export default ModalClose;
