import React from "react";
import styled from "styled-components";
import getRelationshipStatus from "../../utils/relationshipStatus";
import { AppState } from "../../types";

const RelationshipTierHeart = ({
  size = 36,
  color,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  account,
  className,
}: {
  size?: number;
  color?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  account?: AppState["account"];
  className?: string;
}) => {
  const tierData = account ? getRelationshipStatus(account) : null;
  const heartColor = color
    ? color
    : tierData?.primaryColor
    ? tierData.primaryColor
    : "var(--color-white)";

  return (
    <StyledHeartWrapper
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      className={className}
    >
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width={size ? size + "px" : "28px"}
        height={size ? size - 3 + "px" : "25px"}
        viewBox="0 0 500 500"
      >
        <path
          fill={heartColor}
          d="M250.03,92.92c-3.34-4.95-8.42-11.8-15.5-19.12-34.07-35.22-77.02-42.43-85.52-43.71-11.9-1.78-76.46-11.46-118.54,33.53C6.41,
        89.36,1.27,120.58,.25,136.06c-1.94,29.39,7.53,53.59,20.84,78.23,34.72,64.27,77.06,97.28,123.31,142.63,26.75,26.23,64.53,66.11,
        105.62,120.63l-.06-.03c41.1-54.52,78.87-94.4,105.62-120.63,46.25-45.35,88.59-78.36,123.31-142.63,13.31-24.65,22.78-48.85,
        20.84-78.23-1.02-15.48-6.16-46.7-30.22-72.43-42.08-45-106.63-35.32-118.54-33.53-8.51,1.28-51.45,8.49-85.52,43.71-7.07,
        7.31-12.15,14.16-15.5,19.12"
        />
      </svg>
    </StyledHeartWrapper>
  );
};
interface IStyledHeartWrapper {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const StyledHeartWrapper = styled.div.attrs({
  className: "sc-StyledHeartWrapper" as string,
})<IStyledHeartWrapper>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
  margin-left: ${(props) => `${props.left}px`};
  margin-right: ${(props) => `${props.right}px`};
`;

export default RelationshipTierHeart;
