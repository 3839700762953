/**
 * FriendInviteLink.jsx
 * Form for inviting friends using Link
 * Child of ReferFriendForm.jsx
 * */
import React, { Component } from "react";
import ClickTracker from "UI/elements/ClickTracker";
import { COLORS } from "CSS/Consts.js";
import styled from "styled-components";
class FriendInviteLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  async copyAction() {
    let copyTextarea = document.querySelector(".js-copytextarea");
    copyTextarea.select();
    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.setState({ copied: true }, () =>
          setTimeout(() => {
            this.setState({ copied: false });
            copyTextarea.blur();
          }, 2000),
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    let { referralLink, location, entryPoint } = this.props;
    let buttonText = this.state.copied ? "Copied!" : "Copy";

    return (
      <div
        className={"rafFormWrapper"}
        style={{ marginTop: this.props.top ? this.props.top : 0 }}
      >
        <fieldset>
          {!this.props.noLable ? (
            <StyledLabel for={"refLink"}>Your unique referral link</StyledLabel>
          ) : null}
          <input
            id="refLink"
            className="js-copytextarea fullWidth -noSelectHighlight"
            type="text"
            required
            value={referralLink}
            placeholder=""
            readOnly
            style={{ marginBottom: 20 }}
            contentEditable={true}
          />
        </fieldset>
        <ClickTracker
          ctaType={"button"}
          id="raf-invite-link"
          style={"primary"}
          customClass={"link-p1 doNotStyle"}
          handleClick={() => this.copyAction(referralLink)}
          title={buttonText}
          logClickData={"raf-link-" + location + "-" + entryPoint}
        />
      </div>
    );
  }
}

export const StyledLabel = styled.label.attrs({ className: "sc-StyledLabel" })`
  font-size: 12px;
  color: "var(--color-gray4)";
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${COLORS.white};
  padding-left: 6px;
  padding-right: 6px;
  top: -8px;
  margin-left: 6px;
  transition: all 250ms ease-out;
  border-radius: 3px;
`;

export default FriendInviteLink;
