import { combineReducers } from "redux";
import * as actions from "./actions";

const loaded = (state = false, action) => {
  switch (action.type) {
    case actions.REQUEST_CATEGORIES:
      return false;
    case actions.REQUEST_CATEGORIES_SUCCESS:
    case actions.REQUEST_CATEGORIES_FAILURE:
      return true;
  }
  return state;
};

const list = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  loaded,
});
