// Bake'em - expiry time defaults to 1 month
export function createCookie(name, value, minutes = null) {
  if (typeof document !== "undefined") {
    if (minutes) {
      let date = new Date();
      date.setTime(
        date.getTime() +
          (minutes
            ? parseInt(minutes, 10) * 60 * 1000
            : 30 * 24 * 60 * 60 * 1000),
      );
      document.cookie =
        name + "=" + value + "; expires=" + date.toUTCString() + ";";
    } else {
      document.cookie = name + "=" + value + ";";
    }
  }
}

("test=testvalue; expires=Sat, 01 Jan 2050 05:00:00 GMT;");

// Check if they are editable  - Find a cookie and return its value as a string, or return null if cookie isn't found
export function getCookie(name) {
  let regexp = new RegExp(name + "=([^;]+)");
  let value = null;
  if (typeof document !== "undefined") {
    value = regexp.exec(document.cookie);
  }
  return value === null ? null : value[1];
}

// Convert the document.cookie string into an object
export function getCookies() {
  if (typeof document !== "undefined") {
    return document.cookie.split(/;\s+/).reduce((o, c) => {
      const [k, v] = c.split("=");
      o[k] = v;
      return o;
    }, {});
  }
}

// Convert querystring into an object.
export function getQueryString() {
  if (typeof document !== "undefined") {
    return document.location.search
      .slice(1)
      .split("&")
      .reduce((o, c) => {
        const [k, v] = c.split("=");
        o[k] = v;
        return o;
      }, {});
  }
}

function expireCookiePaths(name, paths) {
  var expires = new Date(0).toUTCString();
  // expire null-path cookies as well
  document.cookie = name + "=; expires=" + expires;
  for (var i = 0, l = paths.length; i < l; i++) {
    document.cookie = name + "=; path=" + paths[i] + "; expires=" + expires;
  }
}

// Get rid of all instances of a cookie, even if there are multiple with different paths
export function expireActiveCookies(name) {
  var pathname = window.location.pathname.replace(/\/$/, ""),
    segments = pathname.split("/"),
    paths = [];
  for (var i = 0, l = segments.length, path; i < l; i++) {
    path = segments.slice(0, i + 1).join("/");
    paths.push(path); // as file
    paths.push(path + "/"); // as directory
  }
  expireCookiePaths(name, paths);
}
