/**
 * Loyalty modals content for gift step.
 * Child of LoyaltyModal.jsx
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { optOutOfLoyaltyTote, optIntoLoyaltyTote } from "State/account/flows";
import { withStoreData } from "UI/hoc/withStoreData";
class LoyaltyStepGift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.failMessage = this.failMessage.bind(this);
    this.successMessage = this.successMessage.bind(this);
    this.declineTote = this.declineTote.bind(this);
    this.acceptTote = this.acceptTote.bind(this);
  }

  successMessage() {
    this.props.setContent(
      this.props.account?.birthday?.month ? "success" : "birthday",
    );
  }

  failMessage(err) {
    this.setState({
      error: err,
    });
  }

  async declineTote() {
    await this.props.optOutOfLoyaltyTote(this.successMessage, this.failMessage);
  }

  async acceptTote() {
    if (this.props.hasCycleResponse) {
      // since loyalty is opt in by default, we only really perform changes when a shipment has already occured.
      // in that case we refresh order statuts
      await this.props.optIntoLoyaltyTote(
        this.successMessage,
        this.failMessage,
      );
    }
  }

  render() {
    let { storeData } = this.props;

    return (
      <div className="loyaltyStep -gift">
        {this.state.error && <p className="error">{this.state.error}</p>}
        <picture>
          <source
            srcSet={`${storeData.track.images}bff_tote/Web_Header.webp`}
            type="image/webp"
          />
          <source srcSet={`${storeData.track.images}bff_tote/Web_Header.jpg`} />
          <img src={`${storeData.track.images}bff_tote/Web_Header.jpg`} />
        </picture>
        <div className="-content">
          <h4>Reading looks good on you.</h4>
          <p>
            First things first: All our BFFs get a free tote—and yours is coming
            this month (or whenever you ship your next box)!
          </p>
        </div>
        <div className="-actions toteActions">
          <button
            className="secondary -flexible toteFlex"
            onClick={this.acceptTote}
          >
            Send the tote
          </button>
          <button
            className="secondary -flexible toteFlex"
            onClick={this.declineTote}
          >
            No thanks
          </button>
        </div>
        {this.props.account?.birthday?.month == null ||
        this.props.account?.birthday?.month == undefined ? (
          <div className="smallText -finePrint">1 of 2</div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { optOutOfLoyaltyTote, optIntoLoyaltyTote },
    dispatch,
  );
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(LoyaltyStepGift),
);
