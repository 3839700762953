import React from "react";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts.js";
import ClickTracker from "../../elements/ClickTracker";
import ToteActions from "./ToteActions";
import LoyaltyStepBirthday from "../../pages/loyalty/LoyaltyStepBirthday";

type BlockProps = {
  data: {
    image: string;
    imageAlt?: string;
    hed?: string;
    superHed?: string;
    dek?: string;
    showClose?: boolean;
    buttonCTA?: string;
    nextStep?: string;
    closeNGoPath?: string;
    logClickData?: string;
    pageCount?: string;
    specialActions?: string;
  };
  setOverlay: (o: null) => void;
  setStep: (step?: string) => void;
  closeNGo: (path?: string) => void;
};

const LoyaltyTierConfContentBlock: React.FC<BlockProps> = ({
  data,
  setOverlay,
  setStep,
  closeNGo,
}) => {
  return (
    <StyledContentBlockWrapper>
      <img src={`${data.image}`} alt={data.imageAlt} />
      {/* {data.superHed && <h2>{data.superHed}</h2>} --might need to handle mobile h2*/}
      {data.hed && <h4>{data.hed}</h4>}
      {data.dek && <p>{data.dek}</p>}
      {!data.specialActions && (
        <ClickTracker
          ctaType={"button"}
          id="loyalty-tier-content-block-action"
          style={"primary fullWidth"}
          handleClick={() =>
            data.closeNGoPath
              ? closeNGo(data.closeNGoPath)
              : setStep(data.nextStep)
          }
          isPending={false}
          title={data.buttonCTA}
          logClickData={data.logClickData}
        />
      )}
      {data?.specialActions == "toteActions" && (
        <ToteActions setStep={setStep} />
      )}
      {data?.specialActions == "birthdayActions" && (
        <LoyaltyStepBirthday
          nextAction={() => setStep("step4")}
          noHeader
          noPagination
        />
      )}
      {data.pageCount && (
        <p className={"smallText -finePrint"}>{data.pageCount}</p>
      )}
      {data.showClose && (
        <a onClick={() => (setOverlay ? setOverlay(null) : null)}>Close</a>
      )}
    </StyledContentBlockWrapper>
  );
};

export const StyledContentBlockWrapper = styled.div.attrs({
  className: "sc-StyledContentBlockWrapper" as string,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 420px;
  text-align: center;
  img {
    max-height: 140px;
    margin-bottom: 24px;
  }
  p {
    margin: 16px auto 24px !important;
    max-width: 445px;
  }
  button {
    max-width: 315px;
  }
  a {
    padding-top: 24px;
    font-size: 1.8rem;
  }
  .smallText {
    margin: 24px auto 0;
  }
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    button {
      max-width: 100%;
    }
    img {
      max-height: 120px;
    }
  }
`;

export default LoyaltyTierConfContentBlock;
