import { connect } from "react-redux";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import React, { useState } from "react";
import CustomSelectDropdown from "UI/components/CustomSelectDropdown-v1.jsx";
import ClickTracker from "UI/elements/ClickTracker";
import { updateOccupation } from "State/account/flows";
import { Occupation } from "../../utils/occupations";
import { bindActionCreators } from "redux";

const OccupationModal = (props) => {
  const [dropdownValue, setDropdownValue] = useState(
    Occupation[props.account?.occupation],
  );
  const handleHelpSelect = (val) => {
    setDropdownValue(val);
  };
  const handleClick = async () => {
    await props.updateOccupation(dropdownValue, () => props.removeOverlay());
  };

  return (
    <div>
      <ModalEditWrapper
        header={"Edit occupation"}
        className={"OccupationModalWrapper"}
        style={{ height: 400 }}
      >
        <p className="label2">Occupation</p>
        <div style={{ marginBottom: 40, position: "relative" }}>
          <CustomSelectDropdown
            origin="occupationModal"
            onChange={handleHelpSelect}
            value={dropdownValue}
            closeSelectRef={() => {}}
            hideDefaultOption
          >
            <div className="option" data-value="Select an option">
              Select an option
            </div>
            <div className="option" data-value="Teaching and education">
              Teaching and education
            </div>
            <div className="option" data-value="Nursing and healthcare">
              Nursing and healthcare
            </div>
            <div
              className="option"
              data-value="Office and administrative support"
            >
              Office and administrative support
            </div>
            <div className="option" data-value="Financial services">
              Financial services
            </div>
            <div
              className="option"
              data-value="Technology and computer software/services"
            >
              Technology and computer software/services
            </div>
            <div className="option" data-value="Government employee">
              Government employee
            </div>
            <div className="option" data-value="Not currently in the workforce">
              Not currently in the workforce
            </div>
            <div className="option" data-value="Retired">
              Retired
            </div>
            <div className="option" data-value="Other">
              Other
            </div>
          </CustomSelectDropdown>
        </div>
        <ClickTracker
          ctaType={"button"}
          id="occupation-submit"
          style={"primary"}
          handleClick={() => handleClick()}
          isPending={false}
          title={"Save"}
          isDisabled={false}
          customStyles={{ width: "100%" }}
        />
      </ModalEditWrapper>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOccupation }, dispatch);
}
function mapStateToProps(state) {
  return {
    location: state.analytics.location ? state.analytics.location : null,
    account: state.account,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OccupationModal);
