import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import { setBFFRejoin } from "State/promo/creators";
import { getPromo } from "State/promo/flows";

const LoyaltyTierRejoinInitiator = ({
  account,
  setBFFRejoin,
  getPromo,
  entryPoint,
  router,
  themeId,
  ...props
}) => {
  useEffect(() => {
    if (account?.policy?.type == "Rejoin") {
      setBFFRejoin(entryPoint);
    }

    props.history.push("/credit-rejoin/step-join");
  }, []);

  return <LoadingCoinSkeleton />;
};

const LoyaltyTierRejoin = withRouter(
  connect(
    (state, ownProps) => {
      const { theme: themeUS, themeCA } = state?.store?.data || {};
      const theme = state.account?.countryCode == "CA" ? themeCA : themeUS;
      return {
        account: state.account,
        entryPoint: ownProps.bffEntryPoint,
        themeId: theme?.id,
      };
    },
    (dispatch) =>
      bindActionCreators(
        {
          setBFFRejoin,
          getPromo,
        },
        dispatch,
      ),
  )(LoyaltyTierRejoinInitiator),
);

export default LoyaltyTierRejoin;
