export const getScrollPosition = () => {
  if (typeof document !== "undefined") {
    let D = document,
      W = window,
      scrollPosition = Math.max(
        parent.window.pageYOffset,
        W.scrollY,
        D.documentElement.scrollTop,
        D.body.scrollTop,
      );

    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      scrollPosition = parent.window.pageYOffset;
    }
    return scrollPosition;
  } else {
    return 0;
  }
};

export const getDocHeight = () => {
  if (typeof document !== "undefined") {
    let body = document.body,
      html = document.documentElement,
      bodyHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        body.getBoundingClientRect().height,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
    return bodyHeight;
  }
};
