import React from "react";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { ExperimentsProvider } from "UI/skeleton/Experiments.jsx";
import { RequestQueueProvider } from "./RequestQueue";
import { StoreDataProvider } from "./StoreData";
import { SearchProvider } from "./Search";
import { RegionProvider } from "./RegionContext";
import { HelpCenterProvider } from "./HelpCenterContext";
import { GiftFormProvider } from "./GiftFormContext";
import { BotyProvider } from "./BotyContext";

const Providers = ({
  children,
  storeData,
  store,
  track,
  experiments,
  initialData,
  ssr,
  helmetContext,
  variants,
  locationHeader,
}) => (
  <RegionProvider locationHeader={locationHeader}>
    <RequestQueueProvider ssr={ssr} initialData={initialData}>
      <StoreDataProvider store={storeData} track={track}>
        <Provider store={store}>
          <HelmetProvider context={helmetContext}>
            <ExperimentsProvider experiments={experiments} variants={variants}>
              <SearchProvider>
                <HelpCenterProvider>
                  <GiftFormProvider>
                    <BotyProvider>{children}</BotyProvider>
                  </GiftFormProvider>
                </HelpCenterProvider>
              </SearchProvider>
            </ExperimentsProvider>
          </HelmetProvider>
        </Provider>
      </StoreDataProvider>
    </RequestQueueProvider>
  </RegionProvider>
);

export default Providers;
