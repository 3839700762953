/**
 * Component used in cancel flow to display modal when account is not eligible to cancel (policy is must renew).
 * Child of HelpForm.jsx and MyMembership.jsx
 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BulletList } from "UI/components/structure/BulletList";
import { Button } from "UI/elements/form/Button";
import styled from "styled-components";
import { setOverlay } from "State/ui/creators";
import { useWindowSize } from "UI/hooks";

const bulletPoints = [
  "Does not include personal information, such as addresses and phone numbers",
  "Is free of hate speech, profanity, and spam",
  "Is related to the book you’re reviewing",
];

const RateGuidelinesModal = ({ setOverlay, ...props }) => {
  const [width] = useWindowSize();
  const isDesktop = width >= 680;

  return (
    <StyledOuterWrapper>
      <h4>Review Guidelines</h4>
      <p>Make sure your review:</p>
      <BulletList
        bullets={bulletPoints}
        top={0}
        bottom={20}
        indent={20}
        fontSize={16}
      />
      <p>
        Any reviews that do not meet these guidelines may not be publicly
        posted. Otherwise, expect to see your review soon. Now hit us with your
        honest opinion!
      </p>
      {isDesktop && (
        <Button
          style={"fullWidth"}
          handleClick={() => setOverlay("")}
          title={"Got it"}
          top={30}
        />
      )}
    </StyledOuterWrapper>
  );
};

export const StyledOuterWrapper = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 680px) {
    margin-top: 10px;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RateGuidelinesModal);
