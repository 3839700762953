import { useState } from "react";
import { useAsyncEffect } from "./useAsyncEffect";
import { getLastShipment } from "../../state/accountHistory/api";

type QuoteParams = {
  planId?: number;
  promoId?: number;
  countryCode?: "CA" | "US";
};

type AdditionalFeesParams = {
  addressId?: number;
  productCount?: number;
};

type AdditionalFee = {
  amount: number;
  feeName: string;
  id: number;
  information?: string;
  stateCode?: string;
  stateName?: string;
};
export interface AdditionalFees {
  additionalFees: AdditionalFee[];
  additionalFeesTotal: number;
  taxRate: number;
}

const VERSION = "v0";
const QUOTE = "quote";
const BASE_URL = `${global.apiHost}/api/${VERSION}/${QUOTE}`;

const getSubscriptionQuote = async (params: QuoteParams) => {
  const queryString = `?planId=${params.planId}&promoId=${params.promoId}&countryCode=${params.countryCode}`;
  const url = `${BASE_URL}/subscription${queryString}`;
  const res = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error(await res.text());
  }

  return await res.json();
};

const getAdditionalFees = async (params: AdditionalFeesParams) => {
  const queryString = `?addressId=${params.addressId}&productCount=${params.productCount}`;
  const url = `${BASE_URL}/additionalFees${queryString}`;
  const res = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error(await res.text());
  }

  return await res.json();
};

const useFreshQuote = (params: QuoteParams) => {
  const [quote, setQuote] = useState();

  useAsyncEffect(async () => {
    const data = await getSubscriptionQuote(params);

    return () => {
      setQuote(data);
    };
  }, [params.promoId, params.planId]);

  return quote;
};

const useAdditionalFees = (params: AdditionalFeesParams) => {
  const [additionalFees, setAdditionalFees] = useState();

  useAsyncEffect(async () => {
    const data = await getAdditionalFees(params);

    return () => {
      setAdditionalFees(data);
    };
  }, [params.addressId, params.productCount]);

  return additionalFees || ({} as AdditionalFees);
};

const useLastShipmentQuote = () => {
  const [lastShipment, setLastShipment] = useState();

  useAsyncEffect(async () => {
    const data = await getLastShipment();
    return () => {
      setLastShipment(data);
    };
  });

  return lastShipment;
};

export { useFreshQuote, useAdditionalFees, useLastShipmentQuote };
