export function pluralize(word: string, count: number) {
  // List of common exceptions (irregular plurals)
  const exceptions = {} as { [key: string]: string };

  if (count === 1) {
    return word; // Singular form
  }

  if (exceptions.hasOwnProperty(word)) {
    return exceptions[word]; // Irregular plural form
  }

  // Simple default pluralization rule for nouns
  if (
    word.endsWith("s") ||
    word.endsWith("x") ||
    word.endsWith("sh") ||
    word.endsWith("ch")
  ) {
    return word + "es"; // Words ending with s, x, sh, or ch typically add "es" for plural.
  } else if (word.endsWith("y") && "aeiou".includes(word[word.length - 2])) {
    return word + "s"; // Words ending with "y" preceded by a vowel, add "s" for plural.
  } else {
    return word + "s"; // Add "s" for regular pluralization.
  }
}
