import React from "react";
import { Modal } from "../structure/Modal";
// import ImageUploading, { ImageListType } from 'react-images-uploading';

interface IPhotoSelect {
  // isActive?: boolean;
  showing?: boolean;
  closeModal?: any;
  // onChange?: (arg?: ImageListType) => void;
}
const PhotoSelect: React.FC<IPhotoSelect> = ({
  // isActive,
  showing,
  closeModal,
  // onChange
}) => {
  const children = (
    <div className="App">
      <>
        <p style={styles.header}>ADD A PHOTO</p>
        <div style={styles.border} />
        <div style={styles.optionsWrapper}>
          <div style={{ display: "flex" }}>
            <img
              width={"22px"}
              height={"22px"}
              style={{ marginRight: 10, marginTop: 2 }}
              src={`https://static.bookofthemonth.com/svgs/Connections_take_a_photo.svg`}
            />
            <p>Take a photo</p>
          </div>
          <div style={styles.border} />
          {/* <ImageUploading
        multiple
        value={[]}
        onChange={(onChange)}
        maxNumber={68}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
          <div onClick={onImageUpload} style={{display:'flex'}}>
          <img
            width={'22px'}
            height={'22px'}
            style={{marginRight: 10, marginTop:2 }}
            src={`https://static.bookofthemonth.com/svgs/Connections_camera_roll.svg`}
          />
          <p>Pick from camera roll</p>
      </div>
      </div>
        )}
      </ImageUploading>  */}
        </div>
      </>
    </div>
  );

  return (
    <Modal handleClose={() => closeModal?.(false)} showing={showing} halfModal>
      {children}
    </Modal>
  );
};

const styles = {
  header: {
    fontSize: 12,
  },
  optionsWrapper: {
    width: "100%",
  },
  photoTypeSelectButton: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  border: {
    marginTop: 10,
    paddingTop: 10,
    borderTop: `1px solid var(--color-gray2)`,
  },
};

export default PhotoSelect;
