import React from "react";
import SkeletonWrapper, {
  P,
  H2,
  InvisibleBlock,
  IMG,
} from "./SkeletonWrapper.jsx";
import Skeleton from "./Index.jsx";

const RafLandingSkeleton = ({ showBox, meta }) => {
  return (
    <SkeletonWrapper className="referFriend rafLanding">
      {meta}
      <section className="pairedBlocks">
        <div className="contentWrapper">
          <div className="contentBlock">
            <H2 className="-center" />
            <P className="-center" />
            <P className="-center" />
            <Skeleton.FormSkeleton
              numInput={1}
              withButton={true}
              buttonStyle="fullWidth"
            />
          </div>
        </div>
        <IMG className="imageBlock" />
      </section>
      {showBox && (
        <Skeleton.SkeletonWrapper className="myBotmPage">
          <div className="">
            <div className="content -wide">
              <section className="pageHeader" />
            </div>
          </div>
          <div className="content -wide">
            <Skeleton.FeaturedProductListSkeleton count={2} />
          </div>
        </Skeleton.SkeletonWrapper>
      )}
      <section>
        <div className="content center">
          <P className="-center" />
          <P className="-center" />
          <P className="-center" />
        </div>
      </section>
      <Skeleton.CheckerboardSkeleton />

      <section>
        <div className="content center">
          <P className="-center" />
          <P className="-center" />
          <P className="-center" />
        </div>
      </section>

      <section>
        <div className="innerContent center">
          <div className="half" style={{ backgroundColor: "#C9CCD1" }}>
            {/* Insert image here */}
          </div>
          <div className="half right">
            <H2 className="-center" />
            <P className="-center" />
            <P className="-center" />
            <Skeleton.FormSkeleton
              numInput={1}
              withButton={true}
              buttonStyle="fullWidth"
            />
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#C9CCD1" }}>
        <div className="content center">
          <InvisibleBlock width="80px" height="80px" />
          <P className="-center" />
          <P className="-center" />
          <P className="-center" />
        </div>
      </section>
    </SkeletonWrapper>
  );
};

export default RafLandingSkeleton;
