import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { saveAccountAvatar } from "State/account/flows";
import { push } from "connected-react-router";
import { SelectAvatar } from "./SelectAvatar";
import { EditAvatar } from "./EditAvatar";
import { useWindowSize } from "UI/hooks";
import { updateAccount } from "State/account/flows";
import { withStoreData } from "UI/hoc/withStoreData";

const AvatarEditWrapper = ({
  account,
  setOverlay,
  isPostEnroll,
  hideHeader,
  showPreview,
  buttonText,
  storeData,
  appFeatures,
  updateAccount,
  ...props
}) => {
  const { store } = storeData;
  const [error, setError] = useState(null);
  const [width] = useWindowSize();
  const isMobile = width < 680;

  const connections = appFeatures?.find((f) => f?.feature == "Connections");
  const socialUser =
    (connections?.status == "Beta" && account?.isBeta) ||
    connections?.status == "Active";
  const accountAvatar = account?.pictureUrl ? account?.pictureUrl : "";

  const handleSave = async (av) => {
    const successFunction = () => {
      setOverlay("");
      if (isPostEnroll) {
        props.push("/post-enroll/welcome");
      } else {
        props.push(`/my-account/account`);
      }
    };
    const failFunction = (err) => {
      setError(err);
    };

    let oUpdate = account;
    if (socialUser) {
      oUpdate["profileImg"] = av;
      await saveAccountAvatar(av, account.id, successFunction, failFunction);
    } else {
      oUpdate["pictureUrl"] = av;
      await updateAccount(oUpdate, store, successFunction, failFunction);
    }
  };

  return (
    <>
      {socialUser ? (
        <SelectAvatar
          updateAvatar={handleSave}
          account={account}
          hideHeader={hideHeader}
          buttonText={buttonText}
          isMobile={isMobile}
        />
      ) : (
        <EditAvatar
          updateAvatar={handleSave}
          accountAvatar={accountAvatar}
          hideHeader={hideHeader}
          showPreview={showPreview}
          buttonText={buttonText}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    store: state,
    account: state.account,
    appFeatures: state?.appFeatures || [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push, updateAccount }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(AvatarEditWrapper),
);
