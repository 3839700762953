import React from "react";
import { StyledFlexWrapper } from "./StyledFlexWrapper";

type PageLayoutProps = {
  isMobile?: boolean;
  maxWidth?: number;
  gap?: number;
  children: React.ReactNode;
};
const PageLayout: React.FC<PageLayoutProps> = ({
  isMobile = false,
  maxWidth = 600,
  gap = 0,
  children,
}) => {
  return (
    <StyledFlexWrapper
      paddingTop={isMobile ? 40 : 80}
      paddingBottom={isMobile ? 150 : 120}
      paddingLeft={isMobile ? 20 : 0}
      paddingRight={isMobile ? 20 : 0}
      maxWidth={maxWidth}
      gap={gap}
    >
      {children}
    </StyledFlexWrapper>
  );
};

export default PageLayout;
