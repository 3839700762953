import React from "react";
import SkeletonWrapper from "./SkeletonWrapper.jsx";

const HeaderBlockSkeleton = ({ children, className, tagType }) => (
  <SkeletonWrapper className={className} tagType={tagType}>
    <div className="innerContent">
      <div>{children}</div>
    </div>
  </SkeletonWrapper>
);

export default HeaderBlockSkeleton;
