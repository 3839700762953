import React from "react";
import { getNumericalMonth, _MONTHS } from "../../utils";
import { ICycle } from "../../../../types/cycle";
import { IStore } from "../../../../types/store";
import { ITrack } from "../../../../types/track";

export interface IStoreDataContext {
  store: Partial<IStore>;
  track: Partial<ITrack>;
  children?: JSX.Element;
  currentCycle?: ICycle;
  nextCycle?: Partial<ICycle>;
  getCycleRelative?: IgetCycleRelative;
}

interface IgetCycleRelative {
  (offset: number): Partial<ICycle>;
}

const StoreDataContext = React.createContext<IStoreDataContext>({
  store: {},
  track: {},
});

const StoreDataProvider = ({ children, store, track }: IStoreDataContext) => {
  // store['rejoinPlans'] = store.rejoinFPolicy.planSet;
  // store['enrollmentPlans'] = store.leadPolicy.planSet;
  const { currentCycle } = store;
  const currentCycleId = currentCycle?.id || 0;
  const getCycleRelative: IgetCycleRelative = (offset) => {
    const currentMonth = getNumericalMonth(currentCycle);
    let newMonth = offset + currentMonth;
    const newYear = 2014 + Math.ceil((currentCycleId + offset) / 12);
    if (newMonth > 12) {
      newMonth = newMonth % 12 ? newMonth % 12 : currentMonth;
    } else if (newMonth < 1) {
      newMonth = 12 + newMonth;
      if (newMonth - 1 < 0 || newMonth - 1 > 11) {
        newMonth = (newMonth % 12) + 12;
      }
    }
    const newMonthLabel = _MONTHS[newMonth - 1];
    newMonth = parseInt(
      Array(2 - String(newMonth).length + 1).join("0") + newMonth,
    ); // pad month
    return {
      id: offset + currentCycleId,
      storeId: 1,
      label: newMonthLabel,
      isCurrent: false,
      description: `${newMonthLabel} ${newYear}`,
      startDate: `${newYear}-${newMonth}-01T00:00:00.000Z`,
    };
  };

  return (
    <StoreDataContext.Provider
      value={{
        store,
        track,
        currentCycle,
        getCycleRelative,
        nextCycle: getCycleRelative(1),
      }}
    >
      {children}
    </StoreDataContext.Provider>
  );
};

export { StoreDataProvider, StoreDataContext };
