/**
 * Shared Form component for adding validation and error checking and setting.
 */

import React, { Component } from "react";

export const validation = {
  name: /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-\s]{0,22}$/i,
  first_name:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-\s]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-\s]+){0,22}$/i,
  firstName:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  last_name:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  lastName:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  street: /^.{2,63}$/i,
  street1: /^.{2,63}$/i,
  street2: /^.{0,63}$/i,
  zip: /^\d{5}([\s-]\d{4})?$/i,
  city: /^[a-zA-Z ]{2,63}$/i,
  email:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  password: /^.{4,40}$/i,
  state:
    /^(AL)|(AK)|(AZ)|(AR)|(AA)|(AE)|(AP)|(CA)|(CO)|(CT)|(DE)|(DC)|(FL)|(GA)|(HI)|(ID)|(IL)|(IN)|(IA)|(KS)|(KY)|(LA)|(ME)|(MD)|(MA)|(MI)|(MN)|(MS)|(MO)|(MT)|(NE)|(NV)|(NH)|(NJ)|(NM)|(NY)|(NC)|(ND)|(OH)|(OK)|(OR)|(PA)|(PR)|(RI)|(SC)|(SD)|(TN)|(TX)|(UT)|(VT)|(VA)|(WA)|(WV)|(WI)|(WY)$/i,
  defaultValidation: /^.{4,63}$/i,
  displayName: /^[A-Z0-9_ ]{0,63}$/i,
  fbId: /^\S+$/i,
  fbToken: /^\S+$/i,
  email_mag: /^\S*$/i,
  email_promo: /^\S*$/i,
  picture_url: /^[A-Z0-9]{2,20}\.(jpg|png|gif|svg)$/i,
  month: /^[0-9]{0,1}/,
  year: /^[0-9]{4}$/,
  numEmployees: /^\d{1}/i,
  company: /^.{2,63}$/i,
};

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.findInputsToValidate = this.findInputsToValidate.bind(this);
    const inputsToValidate = this.findInputsToValidate(this);
    this.traverseChildren = this.traverseChildren.bind(this);
    this.updateValidationState = this.updateValidationState.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validate = validate.bind(this);
    this.scrollToError = scrollToError.bind(this);
    this.state = {
      hasCaughtError: false,
      validation:
        inputsToValidate &&
        inputsToValidate.reduce((output, cur) => {
          if (cur && cur.props) {
            const { type, name, value, checked, invalidmessage } = cur.props;
            if (type === "radio" || type === "checkbox") {
              output[name] = this.validate(checked, name, type, invalidmessage);
            } else {
              let isEmptyValue;
              if (!value) {
                isEmptyValue = true;
              } else if (typeof value === "number") {
                isEmptyValue = false;
              } else if (typeof value === "string") {
                isEmptyValue = value.length === 0;
              }
              output[name] = isEmptyValue
                ? null
                : this.validate(value, name, type, invalidmessage);
            }
          }
          return output;
        }, {}),
      error: null,
    };
    //  this.updatedChildren = React.Children.map(this.props.children, e => this.traverseChildren(e));
  }

  componentDidCatch(error, info) {
    this.setState({ hasCaughtError: true }, scrollToError());
    console.log("error", error);
    console.log("info", info);
  }

  componentDidUpdate(prevProps) {
    let currentFields = React.Children.map(prevProps.children, (e) =>
        this.findInputsToValidate(e),
      ).map((f) => f.props),
      currentFieldValues = currentFields.reduce((output, cur) => {
        output[cur.name] = cur.value;
        return output;
      }, {}),
      newFields = React.Children.map(this.props.children, (e) =>
        this.findInputsToValidate(e),
      ).map((f) => f.props),
      newFieldValues = newFields.reduce((output, cur) => {
        output[cur.name] = cur.value;
        return output;
      }, {});

    if (!this.props.isResetting) {
      Object.entries(currentFieldValues).forEach(([key, val], i) => {
        newFieldValues[key] !== val &&
          this.updateValidationState(
            newFields[i].value,
            newFields[i].name,
            newFields[i].type,
            newFields[i].invalidmessage,
            newFields[i].canbeempty,
          );
      });
      if (prevProps.setValidity) {
        prevProps.setValidity(
          !Object.values(this.state.validation).some((v) => v !== true),
        );
      }
      // for errors returned from server
      if (prevProps.error !== this.props.error) {
        this.setState({ error: this.props.error });
      }
      if (
        this.props.formSubmitting === true &&
        prevProps.formSubmitting !== true
      ) {
        this.validateForm();
      } else if (
        prevProps.formSubmitting === true &&
        this.props.formSubmitting !== true
      ) {
        this.validateForm();
        // this.setValidity(!Object.values(this.state.validation).some(v => v !== true));
        // this.updatedChildren = React.Children.map(this.props.children, e => this.traverseChildren(e));
      }
    }
  }

  validateForm() {
    let currentFields = React.Children.map(this.props.children, (e) =>
        this.findInputsToValidate(e),
      ).map((f) => f.props),
      currentFieldValues = currentFields.reduce((output, cur) => {
        output[cur.name] = cur.value;
        return output;
      }, {}),
      newFields = React.Children.map(this.props.children, (e) =>
        this.findInputsToValidate(e),
      ).map((f) => f.props);
    Object.entries(currentFieldValues).forEach(([key, val], i) => {
      this.updateValidationState(
        newFields[i].value,
        newFields[i].name,
        newFields[i].type,
        newFields[i].invalidmessage,
        newFields[i].canbeempty,
        true,
      );
    });
    if (this.props.setValidity)
      this.props.setValidity(
        !Object.values(this.state.validation).some((v) => v !== true),
      );
    // this.updatedChildren = React.Children.map(this.props.children, e => this.traverseChildren(e));
  }

  findInputsToValidate(element, inputs = []) {
    const inputTypes = ["input", "checkbox", "textarea", "select"];
    if (!element) {
      return;
    }
    if (
      element.props &&
      element.props.children &&
      !inputTypes.some((i) => i === element.type)
    ) {
      React.Children.forEach(element.props.children, (e) =>
        this.findInputsToValidate(e, inputs),
      );
    }

    if (inputTypes.some((i) => i === element.type) && element.props.required) {
      inputs.push(element);
    }
    return inputs;
  }

  updateValidationState(
    value,
    fieldName,
    fieldType,
    overwrittenErrorMessage = null,
    canBeEmpty = false,
    formSubmitting = false,
  ) {
    let newValidation = this.state.validation;
    newValidation[fieldName] = this.validate(
      value,
      fieldName,
      fieldType,
      overwrittenErrorMessage,
      canBeEmpty,
    );
    let errorMessage = null,
      invalid = Object.values(newValidation).filter(
        (v) => v !== null && v !== true,
      );
    if (invalid.length) {
      if (invalid.length === 1) errorMessage = invalid[0];
      else errorMessage = "Please fix the fields highlighted in red";
      if (this.props.setError && formSubmitting)
        this.props.setError(errorMessage);
    } else {
      errorMessage = null;
      if (this.props.setError && formSubmitting)
        this.props.setError(errorMessage);
    }
    if (formSubmitting) this.setState({ error: errorMessage });
  }

  traverseChildren(element) {
    if (!element) return element;
    if (
      element.props &&
      element.props.children &&
      !["input", "checkbox", "textarea", "select"].some(
        (i) => i === element.type,
      )
    ) {
      let newChildren = React.Children.map(element.props.children, (e) =>
        this.traverseChildren(e),
      );
      if (element.props.className === "passwordInput") {
        let errorClass =
          this.state.validation["password"] !== null &&
          this.state.validation["password"] !== true
            ? " error-highlight"
            : "";
        return React.cloneElement(
          element,
          {
            ...element.props,
            className: element.props.className
              ? element.props.className + errorClass
              : errorClass,
          },
          newChildren,
        );
      }
      return React.cloneElement(element, { ...element.props }, newChildren);
    }
    if (
      ["input", "checkbox", "textarea", "select"].some(
        (i) => i === element.type,
      ) &&
      element.props.required
    ) {
      let errorClass =
        this.state.validation[element.props.name] !== null &&
        this.state.validation[element.props.name] !== true
          ? " error-highlight"
          : "";
      return React.cloneElement(element, {
        ...element.props,
        className: element.props.className
          ? element.props.className + errorClass
          : errorClass,
      });
    } else if (React.isValidElement(element)) {
      return React.cloneElement(element);
    } else return element;
  }

  render() {
    let { className, id, showError, formSubmitting, onSubmit } = this.props;
    const emptyFunction = () => {};

    const children = React.Children.map(this.props.children, (e) =>
      this.traverseChildren(e),
    );

    return (
      <form
        className={className || ""}
        id={id || ""}
        onSubmit={onSubmit || emptyFunction}
      >
        {this.state.error &&
          showError !== false &&
          (formSubmitting === true || formSubmitting !== false) && (
            <div className="error">
              <p>{this.state.error}</p>
            </div>
          )}
        {children}
      </form>
    );
  }
}

export function validate(
  value,
  fieldName,
  fieldType,
  overwrittenErrorMessage = null,
  canBeEmpty = false,
) {
  let testFieldName =
    fieldName === "street2" ? fieldName : fieldName.replace(/\d/, "");
  let nameRgx = /^(.*?)name$/i;
  let error = fieldName
    ? `Please enter a valid ${testFieldName.replace("_", " ")}.`
    : "Please fix the field highlighted in red.";

  if (testFieldName === "password" && ((value && value.length < 4) || !value)) {
    error = "Please enter a password that’s at least 4 characters.";
  }
  if (nameRgx.test(testFieldName)) {
    error = `Please enter your ${testFieldName
      .replace(nameRgx, "$1")
      .toLowerCase()} name`;
  }
  if (
    testFieldName in validation &&
    new RegExp(validation[testFieldName]).test(value)
  ) {
    return true;
  } else if (validation[fieldType] && validation[fieldType].test(value)) {
    return true;
  } else if ((fieldType === "radio" || fieldType === "checkbox") && value) {
    return true;
  } else if (
    !(fieldType === "radio" || fieldType === "checkbox") &&
    !validation[testFieldName] &&
    validation["defaultValidation"].test(value)
  ) {
    return true;
  } else if (canBeEmpty && !value) {
    return true;
  } else {
    return overwrittenErrorMessage || error;
  }
}

/*
  - This function is used to set the error in the parent component's state
  - Its not normally necessary, but if the error messaging is shown outside the form compononent use
    this in conjuction with the property showError={false} on the <Form />
    - an example can be found in EmailCaptureModel.jsx
*/
export function setError(error) {
  this && this.setState({ error });
}

/*
  This function is used to set the validity in the parent component's
*/
export function setValidity(isValid) {
  this && this.state.isValid !== isValid && this.setState({ isValid });
}

/*
  These functions are used in the parent component when findInputsToValidate does not return results
*/
export function getValidationMessages(valObj, formSubmitting = false) {
  let bool = formSubmitting
    ? (v) => v !== true
    : (v) => v !== null && v !== true;
  if (Array.isArray(valObj)) {
    return valObj.filter(bool);
  } else {
    return Object.values(valObj).filter(bool);
  }
}
export function determineValidity(valObj) {
  if (Array.isArray(valObj)) {
    return valObj.some((v) => v !== true);
  } else {
    return Object.values(valObj).some((v) => v !== true);
  }
}

export function initValidState(fieldsToValidate) {
  return Object.keys(fieldsToValidate).reduce((output, cur) => {
    output[cur] =
      fieldsToValidate[cur] && fieldsToValidate[cur].length
        ? validate(fieldsToValidate[cur], cur, "input")
        : cur === "street2"
        ? true
        : null;
    return output;
  }, {});
}

export function scrollToError(error) {
  if (typeof document !== "undefined") {
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      function (C) {
        return setTimeout(function () {
          C(+new Date());
        }, 30);
      };
    window.requestAnimationFrame(() => {
      let errors = document.getElementsByClassName("error"),
        position = 0;
      error = error || errors[0];
      if (error) {
        let rect = error.getBoundingClientRect(),
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        position = rect.top + scrollTop - 120;
        if (position !== 0) {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: position,
          });
        }
      }
    });
  }
}
