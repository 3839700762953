export const getFaqs = async (region: string) => {
  const response = await fetch(
    `${global.apiHost}/api/v0/help?region=${region}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

export const sendHelpMessage = async (
  message: { [key: string]: string },
  recaptchaToken?: string | null,
) => {
  try {
    const response = await fetch(`${global.apiHost}/api/v0/help`, {
      method: "post",
      body: JSON.stringify({ ...message, recaptchaToken }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
  } catch (e) {
    // TODO is there a phone number or an email we can include here?
    throw new Error((e as string) || "Failed to send help message.");
  }
};
