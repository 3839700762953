import { BORDER } from "../../../css/Consts";
import styled from "styled-components";

type StyledFlexWrapperProps = {
  id?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderRadius?: boolean;
  backgroundColor?: string;
  withShadow?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  maxWidth?: number;
  gap?: number;
  resetSpacing?: boolean; // clears margins and padding until we can complete spacing refactor
  className?: string;
  customStyles?: string;
};

export const StyledFlexWrapper = styled.div.attrs(({ id, className }) => ({
  className: `sc${
    id ? "-" + id : "-flexWrapper" + (className ? " " + className : "")
  }`,
}))<StyledFlexWrapperProps>`
  ${({ resetSpacing = false }) =>
    resetSpacing &&
    `
    * {
      margin: initial;
      padding: initial;
    }
  `}

  display: flex;
  flex-direction: ${({ flexDirection = "column" }) => flexDirection};

  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-left: ${({ marginLeft }) =>
    marginLeft || marginLeft == 0 ? marginLeft + "px" : "auto"};
  margin-right: ${({ marginRight }) =>
    marginRight || marginRight == 0 ? marginRight + "px" : "auto"};

  padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
  padding-bottom: ${({ paddingBottom = 0 }) => paddingBottom}px;
  padding-left: ${({ paddingLeft = 0 }) => paddingLeft}px;
  padding-right: ${({ paddingRight = 0 }) => paddingRight}px;

  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "px" : "none")};
  width: 100%;
  gap: ${({ gap = 0 }) => gap}px;
  justify-content: ${({ justifyContent = "initial" }) => justifyContent};
  align-items: ${({ alignItems = "initial" }) => alignItems};
  text-align: ${({ textAlign = "initial" }) => textAlign};

  ${({ borderTop = false }) =>
    borderTop &&
    `
    border-top: ${BORDER.defaultBorder};
  `}
  ${({ borderBottom = false }) =>
    borderBottom &&
    `
    border-bottom: ${BORDER.defaultBorder};
  `}
  ${({ borderLeft = false }) =>
    borderLeft &&
    `
    border-top: ${BORDER.defaultBorder};
  `}
  ${({ borderRight = false }) =>
    borderRight &&
    `
    border-top: ${BORDER.defaultBorder};
  `}
  ${({ borderRadius = false }) =>
    borderRadius &&
    `
    border-radius: ${BORDER.defaultBorderRadius};
  `}
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
  `}
  ${({ withShadow = false }) =>
    withShadow &&
    `
    box-shadow: ${BORDER.defaultBoxShadow};
  `}
  ${({ customStyles }) =>
    customStyles &&
    `
    ${customStyles}
  `}
`;
StyledFlexWrapper.displayName = "StyledFlexWrapper";
