export const LOCATION_CHANGE = "LOCATION_CHANGE";
export const EMAIL_CAPTURE = "EMAIL_CAPTURE";
export const ENROLL_CONFIRMATION_ANALYTICS = "ENROLL_CONFIRMATION_ANALYTICS";
export const ENROLL_ECOMMERCE_ANALYTICS = "ENROLL_ECOMMERCE_ANALYTICS";
export const GIFT_CONFIRMATION_TRACKING = "GIFT_CONFIRMATION_TRACKING";
export const GIFT_ENROLL_PLAN_TRACKING = "GIFT_ENROLL_PLAN_TRACKING";
export const ENROLL_PLAN_TRACKING = "ENROLL_PLAN_TRACKING";
export const INITIAL_LOCATION = "INITIAL_LOCATION";
export const QUANT_LEAD_CAPTURE = "QUANT_LEAD_CAPTURE";
export const CANCEL_FLOW_TRACKING = "CANCEL_FLOW_TRACKING";
export const EXPERIMENT_FLOW_TRACKING = "EXPERIMENT_FLOW_TRACKING";
export const SET_ENTRYPOINT = "SET_ENTRYPOINT";
export const SET_PDP_GATE = "SET_PDP_GATE";
export const SET_REFERRER = "SET_REFERRER";
export const IS_PDP = "IS_PDP";
