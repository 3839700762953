import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { searchIndex } from "Core/Search";
import SurveyQuestion from "UI/elements/SurveyQuestion";

const SurveySearch = ({ index, selectedQuestions, handleChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedQuestionsToShow, setSelectedQuestionsToShow] = useState([]); //even if a question is selected, it should not be moved down to the bottom of the list until the user has altered the searchTerm
  const [refreshCounter, setRefreshCounter] = useState(0); //using this state to prevent selectedQuestionsToShow from being updated when user selects a search result.

  useEffect(() => {
    if (index) {
      const results = searchIndex(index, searchTerm);
      const formattedResults = results.map((a) => {
        return {
          id: a.document.itemId,
          name: a.document.itemName,
          type: "checkbox",
        };
      });
      setSearchResults(
        formattedResults
          .filter(
            (result) =>
              !selectedQuestions.some(
                (selectedQuestion) => result.id === selectedQuestion,
              ),
          )
          .slice(0, 5),
      );
      setSelectedQuestionsToShow(selectedQuestions);
    }
  }, [searchTerm, refreshCounter]);

  // when selectedQuestions are initially being loaded in, we want to update the selectedQuestionsToShow.
  // when the user has already inputted a searchTerm and is has selected a result, we do not want to update the selectedQuestionsToShow yet, since we should still show it in the result array.
  // Known issue - this has weird behavior when unchecking a selected question. If the searchTerm is empty, it will immediately dissapear, if it is not empty, it will stay until the user types something else. This issue is minor enough for now.
  useEffect(() => {
    if (!searchTerm) {
      setRefreshCounter((prev) => prev + 1);
    }
  }, [selectedQuestions, searchTerm]);

  const selectedQuestionsToShowFormatted = useMemo(() => {
    if (!index?.documentIndex?.values() || !selectedQuestionsToShow?.length) {
      return [];
    }
    return (
      selectedQuestionsToShow
        .map((q) => {
          return {
            id: q,
            name: [...index?.documentIndex?.values()].find(
              (v) => v.itemId === q,
            )?.itemName,
            type: "checkbox",
          };
        })
        // only show questions that are in the index
        ?.filter((q) => q.name)
    );
  }, [selectedQuestionsToShow, index]);

  return (
    <StyledSearchSurveyWrapper>
      <StyledSearchSurveyInputWrapper>
        <img
          src={`//static.bookofthemonth.com/elements/magnifying-glass.svg`}
          className="magnifyingGlass"
          id="magnifyingGlass"
          alt="Search icon"
        />
        <input
          id="searchIt"
          type="text"
          placeholder={"Type creator's name"}
          className="fullWidth"
          value={searchTerm ? searchTerm : ""}
          onChange={(ev) => setSearchTerm(ev.target.value)}
          style={{ border: "none", padding: 0, margin: 0 }}
        />
        {searchTerm && (
          <img
            src={`//static.bookofthemonth.com/elements/search-clearquery.svg`}
            onClick={() => {
              setSearchTerm("");
            }}
          />
        )}
      </StyledSearchSurveyInputWrapper>
      {/* search results */}
      {searchResults.length > 0 && (
        <StyledSearchSurveyResultsWrapper>
          {searchResults.map((result) => (
            <li key={result.id}>
              <SurveyQuestion
                question={result}
                handleChange={handleChange}
                selectedQuestions={selectedQuestions}
              />
            </li>
          ))}
        </StyledSearchSurveyResultsWrapper>
      )}
      {/* selected results */}
      {selectedQuestionsToShowFormatted?.map((result) => (
        <StyledSearchSurveyResultsWrapper>
          <li key={result.id}>
            <SurveyQuestion
              question={result}
              handleChange={handleChange}
              selectedQuestions={selectedQuestions}
            />
          </li>
        </StyledSearchSurveyResultsWrapper>
      ))}
    </StyledSearchSurveyWrapper>
  );
};

export default SurveySearch;

const StyledSearchSurveyWrapper = styled.div.attrs({
  className: "sc-StyledSearchSurveyWrapper",
})`
  display: block !important;
  width: 100%;
`;

const StyledSearchSurveyInputWrapper = styled.div.attrs({
  className: "sc-StyledSearchSurveyInputWrapper",
})`
  width: 100%;
  height: 40px;
  padding: 0 12px;
  margin: 0 auto;
  position: relative;
  border-radius: 20px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray2);
  background: var(--color-white);
  input,
  input::placeholder {
    border: none;
    font-size: 1.2rem !important;
  }
  img {
    height: 16px;
    margin-right: 10px;
  }
`;

const StyledSearchSurveyResultsWrapper = styled.div.attrs({
  className: "sc-StyledSearchSurveyResultsWrapper",
})`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid var(--color-gray2);
  li {
    width: 100%;
  }
`;
