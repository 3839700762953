import * as actions from "./actions.js";

export const cancelStepHierarchy = {
  skip: 1,
  survey: 2,
  credits: 3,
  stay: 4,
  confirm: 4,
  success: 5,
};

const initialState = {
  step: "skip",
  status: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CANCEL_STEP_SKIP:
      return { ...state, step: "skip" };
    case actions.SET_CANCEL_STEP_SURVEY:
      return { ...state, step: "survey" };
    case actions.SET_CANCEL_STEP_CREDITS:
      return { ...state, step: "credits" };
    case actions.SET_CANCEL_STEP_STAY:
      return { ...state, step: "stay" };
    case actions.SET_CANCEL_STEP_CONFIRM:
      return { ...state, step: "confirm" };
    case actions.SET_CANCEL_STEP_SUCCESS:
      return { ...state, step: "success" };
    case actions.SET_CANCEL_STATUS_PENDING:
      return { ...state, status: actions.CANCEL_STATUS_PENDING };
    case actions.SET_CANCEL_STATUS_SUCCESS:
      return { ...state, status: actions.CANCEL_STATUS_SUCCESS };
    case actions.SET_CANCEL_STATUS_FAILURE:
      return { ...state, status: actions.CANCEL_STATUS_FAILURE };
    case actions.RESET_CANCEL_STATUS:
      return { ...state, status: null };
    default:
      return state;
  }
}
