import { snesRedirect } from "../../../utils/snesRedirect";
import { COLORS } from "../../../css/Consts";
import ClickTracker from "../../../ui/elements/ClickTracker";
import React from "react";

type NegativeOptionCheckboxProps = {
  style: "termsAgreementBlock" | "standalone";
  isMobile: boolean;
  id: string;
};

const NegativeOptionCheckbox: React.FC<NegativeOptionCheckboxProps> = ({
  style,
  isMobile,
  id,
}) => {
  const ID = `negativeOptionCheckbox-${id}`;

  let copy;
  switch (style) {
    case "termsAgreementBlock":
      copy = (
        <p className="p2-alt" style={{ color: COLORS.gray5 }}>
          I agree to the terms listed below
        </p>
      );
      break;
    case "standalone":
      copy = (
        <span className={`miniText --${null}`}>
          I agree to the terms listed above and{" "}
          <ClickTracker
            ctaType={"link"}
            id={`privacy_policy-${ID}`}
            size={isMobile ? 12 : 14}
            style={"primary fullWidth"}
            handleClick={() => {
              snesRedirect("snes", "/terms-of-membership");
            }}
            isBold={true}
            color={"var(--color-primary5)"}
            title={"Terms of use"}
            logClickData={`click_see_trems_of_membership-${ID}`}
          />{" "}
          and{" "}
          <ClickTracker
            ctaType={"link"}
            id={`privacy_policy-${ID}`}
            size={isMobile ? 12 : 14}
            style={"primary fullWidth"}
            handleClick={() => {
              snesRedirect("snes", "/privacy-policy");
            }}
            isBold={true}
            color={"var(--color-primary5)"}
            title={"Privacy policy"}
            logClickData={`click_see_privacy_policy-${ID}`}
          />
          .{" "}
        </span>
      );
      break;
  }
  return copy ? <span style={{ color: COLORS.gray5 }}>{copy}</span> : null;
};

export default NegativeOptionCheckbox;
