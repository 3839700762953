import React from "react";
import { ILocationDataContext } from "../../../../types/location";
import { CountryCode } from "../../../../types/address";

type RegionContext = {
  canadaMode: boolean;
  quebecMode: boolean;
  locationHeader: {
    countryHeader: CountryCode;
    regionHeader: string;
  };
};
const RegionContext = React.createContext<RegionContext>({
  canadaMode: false,
  quebecMode: false,
  locationHeader: {
    countryHeader: "US",
    regionHeader: "NY",
  },
});

const RegionProvider = ({ locationHeader, children }: ILocationDataContext) => {
  const canadaMode = locationHeader.countryHeader === "CA";
  const quebecMode = locationHeader.regionHeader === "QC";
  return (
    <RegionContext.Provider value={{ canadaMode, quebecMode, locationHeader }}>
      {children}
    </RegionContext.Provider>
  );
};

export { RegionProvider, RegionContext };
