import React from "react";
import { useWindowSize } from "../hooks/useWindowSize";

const withWindowSize = (Component) => {
  const WithWindowSize = (props) => {
    const [width, height] = useWindowSize();
    return <Component {...props} width={width} height={height} />;
  };
  return WithWindowSize;
};

export { withWindowSize };
