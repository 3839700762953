import { setAddress } from "State/address/creators";
// import { setError } from 'State/error/creators';

import * as addressApi from "./api";

export const saveAddress =
  (address, originalChosen = false, successFn = null, failFn = null) =>
  async (dispatch, getState) => {
    try {
      const account = getState().account;
      const update = {
        ...address,
        accountId: account.id,
        name: address.name
          ? address.name
          : `${account.firstName} ${account.lastName}`,
      };
      const newAddress = await addressApi.create(update, originalChosen);
      dispatch(setAddress(newAddress));
      successFn?.(newAddress);
    } catch (e) {
      if (e.message == "Please enter a valid zip.") {
        // dispatch(setError(e.message, 'zip'));
        failFn?.({ error: e.message, type: "zip" });
      }
      failFn?.({ error: e.message });
      console.error("TCL: flows > saveAddress -> e", e);
    }
  };

export const validateAddress = async (address) => {
  try {
    const res = await addressApi.validate({
      street: address.street1 + (address.street2 ? " " + address.street2 : ""),
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
    });
    return res.addresses || [];
  } catch (e) {
    console.error("TCL: flows > validateAddress -> e", e);
    return [];
  }
};
