export const COLORS = {
  // Grays
  black: "#020202",
  gray1: "#F4F2F0",
  gray2: "#DCDEE0",
  gray3: "#C8CCD0",
  gray4: "#A8ACB0",
  gray5: "#6F7276",
  white: "#ffffff",
  offWhite: "#F3F3EC",
  offwhite1: "#FAF7F4",
  offwhite2: "#EDEBE9",

  // Badges
  colorBadgeDebut: "#00A74C",
  colorBadgeEarlyRelease: "#B19ACA",
  colorBadgeReward: "#A4D8A4",
  colorBadgeRepeatAuthor: "#6FC5E5",
  colorBadgeThreepeatAuthor: "#6FC5E5",
  colorBadgeFourpeatAuthor: "#6FC5E5",
  colorBadgePerformedByAuthor: "#B19ACA",
  colorBadgeMissed: "#F287B7",
  colorBadgeBotyFinalist: "#A0A0A0",
  colorBadgeBoty: "#D59E0E",
  colorBadgeBotyLight: "#CEBC8C",

  primary1: "#C2D9E9",
  primary2: "#84B3D3",
  primary3: "#5495C1",
  primary5: "#0A68A6",
  primary7: "#074974",

  BOTYPrimary: "#D59E0E",
  BOTYPrimary1: "#FCF7EC",

  // Loyalty Tier
  preFriendBG: "#F4F6F8",
  preFriendPrimary: "#A8ACB0",
  preFriendSecondary: "#FFFFFF",
  friendBG: "#FFF6F6",
  friendPrimary: "#FF9C9C",
  friendSecondary: "#E26565",
  bffBG: "#FFE5E3",
  bffPrimary: "#E26565",
  bffSecondary: "#D63838",
  soulmateBG: "#FFE9F7",
  soulmatePrimary: "#B11B6D",
  soulmateSecondary: "#840E4E",

  // Other
  red: "#D94A27",
  error1: "#F5D2C9",
  error2: "#ECA493",
  error6: "#C34323",
  error7: "#98341B",

  green: "#00A74C",
  success1: "#BFE9D2",
  success2: "#80D3A5",
  success6: "#009644",
  success7: "#007535",
  lightGreen: "#CDE5BD",

  yellow: "#FBB514",
  warning1: "#FEEDC4",
  warning2: "#FDDA89",
  warning6: "#E2A312",
  warning7: "#B07F0E",

  blue: "#6FC5E5",
  skyBlue: "#D2E9F8",
  pink: "#F287B7",
  lilac: "#B19ACA",

  // VBT
  vbtPink: "#D74298",
  briefPurple: "#A77DDD",

  buttonColor: "#0A68A6",
  buttonHoverColor: "#074974",

  earnings5: "#FFDB21",
  earnings4: "#FFE664",
  earnings3: "#FFDB21",
  earnings2: "#FFF4BC",
  earnings1: "#FFFBEB",

  //DEPRECATED - TO BE REMOVED
  // green: '#58CBA8',//deprected
  // greenDark: '#149850',//deprecated
  // lightBlue: '#11AFE2', //deprecated
  // hoverLightBlue: '#10a0cf',//deprecated
  // fontLightGray: '#444444',//deprecated
  // red: '#EF2929',//deprecated
  // secondarySiteColor: '#11afe2',//deprecated
  // orange: '#EE4A26',//deprecated
  // siteColor: '#204399',//deprecated
  // peach: '#F47159',//deprecated
  // disabledBlue: '#11AFE24D',//deprecated
  // caution: ' #ffefae',//deprecated
  // grayDark: '#777777',//deprecated
  // grayBlueDark: '#282A2C',//deprecated
  // grayMed: '#e8e8e8',//deprecated
  // grayMed1: '#54585C',//deprecated
  // grayMed2: '#606468',//deprecated
  // grayMed3: '#7C8084',//deprecated
  // grayLight: '#F6F7F8',//deprecated
  // siteColorDark: '#111e3f',//deprecated*
  // tertiarySiteColor: '#F7B4C5',//deprecated*
  // yellow: '#FEF4D0',//deprecated
};

/**
 * @deprecated
 */
export const FONTS = {
  serifStack: '"LardentPro-SlabRegular", "Georgia", "Times New Roman", serif',
  // deprecated
  serifStackItalic:
    '"LardentPro-SlabRegularItalics", "Georgia Italic", "Times New Roman Italic", serif',
  serifStackMedium:
    '"LardentPro-SlabMedium", "Georgia", "Times New Roman", serif',
  // deprecated
  serifStackBold:
    '"LardentPro-SlabMedium", "Georgia", "Times New Roman", serif',
  serifStackAlt:
    '"LardentPro-ClassicRegular", "Georgia", "Times New Roman", serif',
  serifStackAltItalic:
    '"LardentPro-ClassicRegularItalics", "Georgia Italic", "Times New Roman Italic", serif',

  sansSerifStackRegular:
    '"FoldGrotesquePro-Regular", "Helvetica", "Arial", sans-serif',
  sansSerifStackRegularItalic:
    '"FoldGrotesquePro-Italic", "Helvetica Italic", "Arial Italic", sans-serif',
  sansSerifStackMedium:
    '"FoldGrotesquePro-Medium", "Helvetica", "Arial", sans-serif',
  sansSerifStackMediumItalic:
    '"FoldGrotesquePro-MediumItalic", "Helvetica Italic", "Arial Italic", sans-serif',
  sansSerifStackBold:
    '"FoldGrotesquePro-Bold", "Helvetica Bold", "Arial Bold", sans-serif',
  // deprecated
  sansSerifStackBoldItalic:
    '"FoldGrotesquePro-Italic", "Helvetica Italic", "Arial Italic", sans-serif',
  // deprecated
  untitledBold: '"LardentPro-SlabRegular", "Georgia", "Times New Roman", serif',
  // deprecated
  untitledBoldItalic:
    '"LardentPro-SlabRegularItalics", "Georgia Italic", "Times New Roman Italic", serif',
  // deprecated
  essayStack: '"LardentPro-SlabRegular", "Georgia", "Times New Roman", serif',
  // deprecated
  essayStackItalic:
    '"LardentPro-SlabRegularItalics", "Georgia Italic", "Times New Roman Italic", serif',
  essayStackBold:
    '"LardentPro-SlabMedium", "Georgia", "Times New Roman", serif',
  essayStackBoldItalic:
    '"LardentPro-SlabRegularItalics", "Georgia Italic", "Times New Roman Italic", serif',
};

export const BORDER = {
  defaultBorder: `1px solid ${COLORS.gray2}`,
  defaultBorderRadius: "3px",
  defaultBoxShadow: "2px 2px 6.400000095367432px 0px rgba(40, 42, 44, 0.12)",
  mobileOrderBorder: `1px solid ${COLORS.black}`,
};

export const MEDIAQUERIES = {
  burgerView: "1210px",
  desktop: "1024px",
  tablet: "960px",
  phablet: "800px",
  mobile: "680px",
  narrow: `480px`,
  micro: `295px`,
};

export const WIDTHS = {
  siteWidth: `1224px`,
  wide: `960px`,
  narrow: `480px`,
  micro: `295px`,
  PX: {
    tablet: 960,
    phablet: 800,
    mobile: 680,
    narrow: 480,
    micro: 295,
  },
};

export const HEADERHEIGHT = {
  headerHeightMain: 62,
  desktop: 70,
  mobile: 50,
};

export const ICON_SIZE = {
  small: 16,
  medium: 24,
  large: 32,
  xLarge: 36,
};

export const BookClubImagePath = "//static.bookofthemonth.com/bookclubs/";
export const BotmImagePath = "//static.bookofthemonth.com/";
