import * as actions from "./actions";

export function setLoginFlow(loginFlow) {
  let flow = loginFlow;
  switch (loginFlow) {
    case "rejoinA":
      flow = "rejoin-a";
      break;
    case "rejoinC":
      flow = "rejoin-c";
      break;
    case "rejoinF":
      flow = "rejoin-f";
      break;
    default:
      flow = loginFlow;
  }

  return {
    type: actions.SET_LOGIN_FLOW,
    payload: flow,
  };
}
