import * as React from "react";
import styled from "styled-components";
import { FONTS, MEDIAQUERIES } from "../../../css/Consts";

interface ErrorProps {
  message: string;
  forwardRef?: React.MutableRefObject<any>;
  top?: number;
  bottom?: number;
}

export const Error: React.FC<ErrorProps> = ({
  message,
  forwardRef,
  top,
  bottom = 20,
}) =>
  message ? (
    <StyledError top={top} bottom={bottom}>
      <p ref={forwardRef}>{message}</p>
    </StyledError>
  ) : null;

interface IStyledError {
  top?: number;
  bottom?: number;
}

export const StyledError = styled.div<IStyledError>`
  background: var(--color-error6);
  border-radius: 3px;
  padding: 12px 20px;
  color: var(--color-white) !important;
  text-align: center !important;
  font-size: 1.6rem !important;
  line-height: 2.4rem;
  font-family: ${FONTS.sansSerifStackBold} !important;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  p {
    font-family: ${FONTS.sansSerifStackRegular} !important;
    font-size: 1.6rem !important;
    line-height: 2.4rem;
    color: var(--color-white) !important;
    text-align: center !important;
    margin: 0;
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  a {
    color: var(--color-white) !important;
    font-family: ${FONTS.sansSerifStackBold} !important;
    font-size: 1.6rem !important;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    padding: 6px 20px;
    p {
      font-size: 1.4rem !important;
      line-height: 2.2rem !important;
    }
    a {
      font-size: 1.4rem !important;
      line-height: 2.2rem !important;
    }
  }
`;

StyledError.displayName = "StyledError";
