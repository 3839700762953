import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts";

interface IStyledPageHeaderProps {
  top?: number;
  bottom?: number;
  mobileBottom?: number;
  maxWidth?: number;
  withPadding?: boolean;
  centered?: boolean;
}

export const StyledPageHeader = styled.div.attrs({
  className: "sc-StyledPageHeader" as string,
})<IStyledPageHeaderProps>`
  /* text-align: center; */
  text-align: ${(props) => (props.centered ? "center" : "left")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "800px")};
  margin-left: ${(props) => (props.centered ? "auto" : 0)};
  margin-right: ${(props) => (props.centered ? "auto" : 0)};
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "60px")};
  padding-left: ${(props) => (props.withPadding ? "20px" : 0)};
  padding-right: ${(props) => (props.withPadding ? "20px" : 0)};
  h1.hero {
    margin: 0 auto 20px;
    + p {
      max-width: 600px;
      margin: 0 auto;
    }
  }
  h1 {
    margin: 0 auto;
    + p {
      max-width: 600px;
      margin: ${(props) => (props.centered ? "30px auto 0" : "30px 0 0")};
    }
  }

  button {
    margin: 30px auto 0;
  }
  .actionWrapper {
    max-width: 240px;
    margin: 30px auto 0;
    text-align: center;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: ${(props) =>
      props.mobileBottom
        ? `${props.mobileBottom}px`
        : props.bottom
        ? `${props.bottom - 20}px`
        : 0};
    h2 + p {
      margin-top: 15px;
    }
    .actionWrapper {
      margin: 20px auto 0;
    }
  }
`;
StyledPageHeader.displayName = "StyledPageHeader";
