import { COLORS } from "../../../css/Consts";
import * as React from "react";
import styled from "styled-components";

interface PointerProps {
  pointerNextActive?: any;
  handleClick?: any;
  isActive?: boolean;
  animate?: boolean;
  isBold?: boolean;
  type?: "up" | "down" | "right" | "left";
  id?: string;
  custom?: any;
  color?: string;
  filled?: boolean;
}

interface IPointerProps {
  animate: boolean;
  type: "up" | "down" | "right" | "left";
  custom?: any;
}

export const Pointer: React.FC<PointerProps> = ({
  pointerNextActive = null,
  handleClick = null,
  isActive = null,
  animate = true,
  isBold = false,
  type = "up",
  id = "",
  custom,
  color = COLORS.black,
  filled = true,
}) => {
  const getPointer = () => {
    switch (type) {
      case "right":
        return (
          <svg
            width="10px"
            height="16px"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L8.5 8L1 15"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "left":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 20L8 12L16 3.99999"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "down":
        return filled ? (
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="11px"
            height="7px"
            viewBox="0 0 12.781 5.761"
            enableBackground="new 0 0 12.781 5.761"
          >
            <polygon
              fill={color}
              points="6.478,5.719 0.243,0.049 12.603,0.049 "
            />
          </svg>
        ) : (
          <svg
            width="11px"
            height="6px"
            viewBox="0 0 11 6"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Final"
              stroke="none"
              strokeWidth="1"
              fill={color}
              fillRule="evenodd"
            >
              <g
                id="Members"
                transform="translate(-1211.000000, -33.000000)"
                fill={color}
              >
                <g id="Group" transform="translate(1163.000000, 22.000000)">
                  <path
                    d="M58.7980039,11.9642761 C58.7980039,11.8839189 58.7578253,11.793517 58.6975574,11.7332491 L58.1953247,11.2310163 C58.1350567,11.1707484 58.0446548,11.1305698 57.9642976,11.1305698 C57.8839404,11.1305698 57.7935385,11.1707484 57.7332705,11.2310163 L53.7857212,15.1785657 L49.8381718,11.2310163 C49.7779039,11.1707484 49.687502,11.1305698 49.6071448,11.1305698 C49.5167429,11.1305698 49.4363856,11.1707484 49.3761177,11.2310163 L48.873885,11.7332491 C48.813617,11.793517 48.7734384,11.8839189 48.7734384,11.9642761 C48.7734384,12.0446334 48.813617,12.1350353 48.873885,12.1953032 L53.5546941,16.8761123 C53.6149621,16.9363803 53.7053639,16.9765589 53.7857212,16.9765589 C53.8660784,16.9765589 53.9564803,16.9363803 54.0167482,16.8761123 L58.6975574,12.1953032 C58.7578253,12.1350353 58.7980039,12.0446334 58.7980039,11.9642761 Z"
                    id="Arrow-Open-Outline"
                  />
                </g>
              </g>
            </g>
          </svg>
        );
      case "up":
      default:
        return isBold ? (
          <svg
            width="16px"
            height="8px"
            viewBox="0 0 28.1 17.1"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline
              fill="none"
              stroke="#020202"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="26.1,15.1 14.1,2 2,15.1 "
            />
          </svg>
        ) : (
          <svg
            width="16px"
            height="8px"
            viewBox="0 0 11 7"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Final"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g transform="translate(-796.000000, -35.000000)" fill={color}>
                <path
                  d="M806.798004,40.4642815 C806.798004,40.3839243 806.757825,40.2935224 806.697557,40.2332544 L802.016748,35.5524453 C801.95648,35.4921774 801.866078,35.4519987 801.785721,35.4519987 C801.705364,35.4519987 801.614962,35.4921774 801.554694,35.5524453 L796.873885,40.2332544 C796.813617,40.2935224 796.773438,40.3839243 796.773438,40.4642815 C796.773438,40.5446387 796.813617,40.6350406 796.873885,40.6953086 L797.376118,41.1975413 C797.436386,41.2578092 797.526788,41.2979879 797.607145,41.2979879 C797.687502,41.2979879 797.777904,41.2578092 797.838172,41.1975413 L801.785721,37.249992 L805.733271,41.1975413 C805.793538,41.2578092 805.88394,41.2979879 805.964298,41.2979879 C806.054699,41.2979879 806.135057,41.2578092 806.195325,41.1975413 L806.697557,40.6953086 C806.757825,40.6350406 806.798004,40.5446387 806.798004,40.4642815 Z"
                  id="Arrow-Close-Outlined"
                />
              </g>
            </g>
          </svg>
        );
    }
  };

  return (
    <StyledPointer
      className={isActive ? "active" : ""}
      id={id}
      type={type}
      animate={animate}
      onClick={handleClick}
      custom={custom}
    >
      {getPointer()}
    </StyledPointer>
  );
};

export const StyledPointer = styled.div<IPointerProps>`
  svg {
    transition: ${(props) =>
      !props.animate
        ? "0s"
        : props.type == "up" || props.type == "down"
        ? "0.6s"
        : props.type == "right" || props.type == "left"
        ? "0.4s"
        : "0s"};
  }
  &.active {
    svg {
      transform: ${(props) =>
        props.type == "up" || props.type == "down"
          ? "rotate(180deg)"
          : props.type == "right" || props.type == "left"
          ? "rotate(90deg)"
          : "rotate(0deg)"};
    }
  }
  &:hover {
    cursor: hand;
    cursor: pointer;
  }
  ${(props) => props.custom || null}
`;
interface PointerUpDownProps {
  pointerUp?: string;
  pointerDown?: string;
  color?: string;
}

export const PointerUpDown: React.FC<PointerUpDownProps> = ({
  color = "#020202",
}) => (
  <div className="upDownPointer">
    <div className={`pointerUp`}>
      <svg
        width="11px"
        height="7px"
        viewBox="0 0 11 7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Final"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Members-Dropdown-Desktop"
            transform="translate(-796.000000, -35.000000)"
            fill={color}
          >
            <path
              d="M806.798004,40.4642815 C806.798004,40.3839243 806.757825,40.2935224 806.697557,40.2332544 L802.016748,35.5524453 C801.95648,35.4921774 801.866078,35.4519987 801.785721,35.4519987 C801.705364,35.4519987 801.614962,35.4921774 801.554694,35.5524453 L796.873885,40.2332544 C796.813617,40.2935224 796.773438,40.3839243 796.773438,40.4642815 C796.773438,40.5446387 796.813617,40.6350406 796.873885,40.6953086 L797.376118,41.1975413 C797.436386,41.2578092 797.526788,41.2979879 797.607145,41.2979879 C797.687502,41.2979879 797.777904,41.2578092 797.838172,41.1975413 L801.785721,37.249992 L805.733271,41.1975413 C805.793538,41.2578092 805.88394,41.2979879 805.964298,41.2979879 C806.054699,41.2979879 806.135057,41.2578092 806.195325,41.1975413 L806.697557,40.6953086 C806.757825,40.6350406 806.798004,40.5446387 806.798004,40.4642815 Z"
              id="Arrow-Close-Outlined"
            />
          </g>
        </g>
      </svg>
    </div>

    <div className={`pointerDown`}>
      <svg
        width="11px"
        height="6px"
        viewBox="0 0 11 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Final"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Members"
            transform="translate(-1211.000000, -33.000000)"
            fill={color}
          >
            <g id="Group" transform="translate(1163.000000, 22.000000)">
              <path
                d="M58.7980039,11.9642761 C58.7980039,11.8839189 58.7578253,11.793517 58.6975574,11.7332491 L58.1953247,11.2310163 C58.1350567,11.1707484 58.0446548,11.1305698 57.9642976,11.1305698 C57.8839404,11.1305698 57.7935385,11.1707484 57.7332705,11.2310163 L53.7857212,15.1785657 L49.8381718,11.2310163 C49.7779039,11.1707484 49.687502,11.1305698 49.6071448,11.1305698 C49.5167429,11.1305698 49.4363856,11.1707484 49.3761177,11.2310163 L48.873885,11.7332491 C48.813617,11.793517 48.7734384,11.8839189 48.7734384,11.9642761 C48.7734384,12.0446334 48.813617,12.1350353 48.873885,12.1953032 L53.5546941,16.8761123 C53.6149621,16.9363803 53.7053639,16.9765589 53.7857212,16.9765589 C53.8660784,16.9765589 53.9564803,16.9363803 54.0167482,16.8761123 L58.6975574,12.1953032 C58.7578253,12.1350353 58.7980039,12.0446334 58.7980039,11.9642761 Z"
                id="Arrow-Open-Outline"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
);

export const CarouselPointer: React.FC<{
  type: "right" | "left";
  action: any;
}> = ({ type, action }) => {
  let pointer =
    type == "right" ? (
      <svg x="0px" y="0px" viewBox="0 0 68 166.4">
        <line
          fill="none"
          stroke="var(--color-gray2)"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="3.8"
          y1="4.2"
          x2="64.2"
          y2="83.2"
        />
        <line
          fill="none"
          stroke="var(--color-gray2)"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="3.8"
          y1="162.2"
          x2="64.2"
          y2="83.2"
        />
      </svg>
    ) : (
      <svg x="0px" y="0px" viewBox="0 0 68 166.4">
        <line
          fill="none"
          stroke="var(--color-gray2)"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="64.2"
          y1="162.2"
          x2="3.8"
          y2="83.2"
        />
        <line
          fill="none"
          stroke="var(--color-gray2)"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="64.2"
          y1="4.2"
          x2="3.8"
          y2="83.2"
        />
      </svg>
    );

  return (
    <StyledCarouselPointer className={type} onClick={action}>
      {pointer}
    </StyledCarouselPointer>
  );
};

export const StyledCarouselPointer = styled.div`
  width: 48px;
  position: absolute;
  cursor: pointer;
  top: 55px;
  &.left {
    left: -75px;
  }
  &.right {
    right: -64px;
  }
  svg line {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    svg line {
      stroke: var(--color-primary5);
    }
  }
  .prev {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 1124px) {
    display: none;
  }
`;
