import * as actions from "./actions";
import { combineReducers } from "redux";

export const giftStepHierarchy = {
  1: {
    give: 0,
    "gift-delivery-method": 1,
    "gift-account": 1,
    "gift-info": 2,
    "gift-billing": 3,
    "purchase-confirmation": 4,
  },
};

export const giftRedemptionDefault = {
  step: "redeem",
  plan: null,
};

const giverDefault = {
  name: "",
  email: "",
  accountId: null,
};

const recipientDefault = {
  name: "",
  email: "",
  deliveryMethod: null,
  deliveryDate: null,
  message: "",
};

export const giftPurchaseDefault = {
  step: "give",
  plan: null,
  address: null,
  promoError: null,
  promo: null,
  recipient: recipientDefault,
  giver: giverDefault,
  payment: {
    taxRate: 0,
  },
  corporateCode: null,
};

const giftPurchase = (state = giftPurchaseDefault, action) => {
  switch (action.type) {
    case actions.SET_GIFT_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case actions.SET_DELIVERY_METHOD:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          deliveryMethod: action.payload,
        },
      };
    case actions.SET_GIFT_PURCHASE_PROMO:
      return {
        ...state,
        promo: action.payload,
      };
    case actions.SET_GIFT_PURCHASE_PROMO_ERROR:
      return {
        ...state,
        promoError: action.payload,
      };
    case actions.CLEAR_GIFT_PURCHASE_PROMO_ERROR:
      return {
        ...state,
        promoError: null,
      };
    case actions.CLEAR_GIFT_INFO:
      return action.payload === "keepGiver"
        ? { ...giftPurchaseDefault, giver: state.giver }
        : giftPurchaseDefault;
    case actions.SET_GIFT_INFO:
      return {
        ...state,
        giver: {
          ...state.giver,
          name: action.payload.giverName,
          email: action.payload.giverEmail,
          activeAccount: action.payload.activeAccount,
        },
        recipient: {
          ...state.recipient,
          name: action.payload.recipientName,
          email: action.payload.recipientEmail,
          message: action.payload.message,
          deliveryDate: action.payload.deliveryDate,
          giftStyle: action.payload.giftStyle,
        },
        zipcode: action.payload.zipcode,
      };
    // case actions.SET_GIFT_PERSONALIZATIONS:
    // return { ...state, ...action.payload };
    case actions.SET_GIFT_TAX_RATE:
      return {
        ...state,
        payment: { ...state.payment, taxRate: action.payload },
      };
    case actions.SET_GIFT_PURCHASE_ADDRESS:
      return { ...state, address: action.payload };
    case actions.SET_GIFT_PURCHASE_CORPORATE_CODE:
      return { ...state, corporateCode: action.payload };
    case actions.SET_GIFT_STEP:
      return { ...state, step: action.payload };
    case actions.SET_GIFT_STEP_DEFAULT:
      return { ...state, plan: actions.GIFT_PLAN };
    default:
      return state;
  }
};

const corporateGiftPurchase = (state = null, action) => {
  switch (action.type) {
    case actions.SET_CORP_INQUIRY_ERROR:
      return action.payload;
    default:
      return state;
  }
};

const currencyCode = (state = null, action) => {
  switch (action.type) {
    case actions.SET_CURRENCY_CODE:
      return action.payload;
    default:
      return state;
  }
};

const giftRedemption = (state = giftRedemptionDefault, action) => {
  switch (action.type) {
    case actions.SET_GIFT_REDEEM_STEP:
      return { ...state, step: action.payload };
    case actions.SET_GIFT_REDEEM_STEP_DEFAULT:
      return { ...state, step: actions.GIFT_REDEEM_REDEEM };
    default:
      return state;
  }
};

export default combineReducers({
  giftPurchase,
  giftRedemption,
  corporateGiftPurchase,
  currencyCode,
});
