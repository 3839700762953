import * as React from "react";
import { useRef, useEffect } from "react";
import { CancelX } from "../../elements/navigation/CancelX";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts";

export interface IModalProps {
  handleClose: (...args: any) => void;
  modalStyle?: any;
  showing?: boolean;
  noClose?: boolean;
  scrollit?: boolean;
  isNonStandard?: boolean;
  halfModal?: boolean;
  adaClass?: string;
}

export const Modal: React.FC<IModalProps> = ({
  children,
  modalStyle,
  handleClose,
  showing,
  noClose,
  scrollit,
  isNonStandard,
  halfModal,
  adaClass,
}) => {
  const thisModal = useRef(null);
  const halfModalStyling = halfModal
    ? { height: "30%", bottom: "0" }
    : { top: "0" };

  const scrollUp = () => {
    const current: any = thisModal.current;
    current.scrollTo(0, 0);
  };
  const escape = (e: any) => {
    if (e.key == "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    scrollUp();
  }, [scrollit]);

  return (
    <StyledModalWrapper
      id={"StyledModalWrapper"}
      className={modalStyle + (showing && " open")}
      style={halfModalStyling}
      onKeyDown={escape}
    >
      <StyledModal
        id={"StyledModal"}
        ref={thisModal}
        isNonStandard={isNonStandard}
        role="dialog"
      >
        {!noClose && <CancelX handleClose={() => handleClose()} />}
        {children}
      </StyledModal>
    </StyledModalWrapper>
  );
};

export const StyledModalWrapper = styled.div`
  position: fixed;
  left: -20000px;
  bottom: -20000px;
  max-height: 1;
  max-width: 1;
  opacity: 0;
  transition: opacity 300ms ease-out;
  &.open {
    position: fixed;
    z-index: 10000;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    overflow: scroll;
    justify-content: flex-start;
    align-items: flex-start;
    &.open {
      background: rgba(0, 0, 0, 0);
    }
  }
`;

// ------ StyledModal ------

export interface IStyledModalProps {
  isNonStandard?: boolean;
}

export const StyledModal = styled.div<IStyledModalProps>`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--color-white);
  z-index: 10000;
  border-radius: 4px;
  opacity: 0;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  transition: opacity 300ms ease-out;
  width: 100%;
  width: ${(props) => (props.isNonStandard ? "auto" : "100%")};
  min-width: 200px;
  max-width: ${(props) => (props.isNonStandard ? "100%" : "594px")};
  max-height: 90vh;
  padding: ${(props) => (props.isNonStandard ? 0 : "50px")};
  .takeover & {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .noPadding & {
    padding: 0;
  }
  .open & {
    opacity: 1;
    margin-top: 0;
    top: 0;
  }
  .cancelX {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 200;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    position: absolute;
    transition:
      opacity 300ms ease-out,
      top 400ms cubic-bezier(0.28, 1.02, 0.86, 0.99);
    min-height: 100% !important;
    /* min-height: 100vh; */
    margin-top: 0;
    top: 250vh;
    border-radius: 0;
    /* padding: 50px 20px 30px; */
    padding: ${(props) => (props.isNonStandard ? 0 : "50px 20px 30px")};
    .open & {
      opacity: 1;
      top: 0;
    }
  }
`;
