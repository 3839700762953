export function snesRedirect(
  entryPoint: string,
  path?: string,
  newTab?: boolean,
) {
  if (
    ["snes", "snesOnboard"].includes(entryPoint) &&
    typeof window !== "undefined"
  ) {
    const hostname = window.location.hostname;
    const location = hostname.includes("bookofthemonth")
      ? `https://www.bookofthemonth.com`
      : hostname.includes("mookofthebonth")
      ? `https://www.mookofthebonth.com`
      : hostname.includes("bookofthemoment")
      ? `https://${hostname.replace("app.", "")}`
      : "http://localhost:3000";

    const setRoute = () => {
      if (path) {
        if (path.length > 0 && path[0] === "/") {
          path = path.substring(1);
        }
        return `${location}/${path}`;
      } else if (entryPoint === "snesOnboard") {
        return `${location}/flow`;
      } else {
        return location;
      }
    };
    if (newTab) {
      window.open(setRoute());
    } else {
      window.location.replace(setRoute());
    }
    return true;
  } else {
    return false;
  }
}
