import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";

const Meta = (props) => {
  return (
    <Helmet>
      {props.title && <title>{props.title}</title>}

      {/* Basic metadata */}
      {props.description && (
        <meta
          name="description"
          content={props.description}
          data-react-helmet="true"
        />
      )}
      {props?.keywords?.length && (
        <meta name="keywords" content={props.keywords.join(",")} />
      )}
      {props.canonicalUrl && <link rel="canonical" href={props.canonicalUrl} />}

      {/* og: namespace */}
      <meta property="og:type" content="website" />
      {props.title && <meta property="og:title" content={props.title} />}
      {props.canonicalUrl && (
        <meta property="og:url" content={props.canonicalUrl} />
      )}
      {props.image && <meta property="og:image" content={props.image} />}
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}

      {/* twitter: namespace */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@bookofthemonth" />
      {props.title && <meta name="twitter:title" content={props.title} />}
      {props.description && (
        <meta name="twitter:description" content={props.description} />
      )}
      {props.image && <meta name="twitter:image" content={props.image} />}
      {props.noIndex && <meta name="robots" content="noindex,follow" />}
    </Helmet>
  );
};

export default Meta;
