import isAccountLess3hrs from "./accountTimeCheck";
import { AccountWithLoyaltyAndPayment, IAccount } from "../../../types/account";
import { ICycle } from "../../../types/cycle";

export const getIsNewMemberHold = (
  account: IAccount | AccountWithLoyaltyAndPayment,
) => {
  const { enrollCycle, policyEffectiveDate, cycleActions } = account || {};
  return (
    !!enrollCycle?.isCurrent &&
    isAccountLess3hrs(policyEffectiveDate) &&
    !!cycleActions?.cycleResponseIsShipment
  );
};

export const canRefundNewMemberShipment = (
  account: IAccount | AccountWithLoyaltyAndPayment,
) => {
  const mustRenew = !!account?.policy?.mustRenew;
  const isUSMember = account.countryCode === "US";
  const isNewMemberHold = getIsNewMemberHold(account);
  const hasUpgraded = (account?.lastPlan?.credits || 0) > 1;
  return isUSMember && isNewMemberHold && !mustRenew && !hasUpgraded;
};
