import { getCreditCommitHistory } from "../../state/accountHistory/api";
import React, { useEffect, useState } from "react";

type TCreditCommit = {
  wasCreditCommitMember: boolean;
  pastCreditCommitPolicyId?: number;
  creditCommitPolicyCycles?: number;
  ccFailCreditCommitPlan?: { id: number };
  renewalsRemainingInCommitment?: number;
};

const defaultState: TCreditCommit = {
  wasCreditCommitMember: false,
};

const CreditCommitContext = React.createContext(defaultState);
export const CreditCommitProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<TCreditCommit>(defaultState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getCreditCommitHistory();
        setData(resp);
      } catch (error) {
        console.error("Failed to fetch cc fail credit commit data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <CreditCommitContext.Provider value={data}>
      {children}
    </CreditCommitContext.Provider>
  );
};

export const useCreditCommit = (): TCreditCommit => {
  return React.useContext(CreditCommitContext);
};
