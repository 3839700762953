import * as actions from "./actions";
import { combineReducers } from "redux";

const sort = (state = null, action) => {
  switch (action.type) {
    case actions.SET_SORT:
      return action.payload;
    default:
      return state;
  }
};

const overlay = (state = null, action) => {
  switch (action.type) {
    case actions.SET_OVERLAY:
      return action.payload;
    default:
      return state;
  }
};

const siteResponse = (state = null, action) => {
  switch (action.type) {
    case actions.SET_SITE_RESPONSE:
      return action.payload;
    case actions.CLEAR_SITE_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const siteMessage = (state = null, action) => {
  switch (action.type) {
    case actions.SET_SITE_MESSAGE:
      return action.payload;
    case actions.CLEAR_SITE_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

const boxCoupon = (state = false, action) => {
  switch (action.type) {
    case actions.SHOW_BOX_COUPON:
      return action.payload;
    case actions.CLOSE_BOX_COUPON:
      return false;
    default:
      return state;
  }
};

const snowing = (state = null, action) => {
  switch (action.type) {
    case actions.LET_IT_SNOW:
      return action.payload;
    default:
      return state;
  }
};

const justCompletedRC = (state = null, action) => {
  switch (action.type) {
    case actions.SET_JUST_COMPLETED_RC:
      return action.payload;
    default:
      return state;
  }
};

const rcBadgesStatus = (state = null, action) => {
  switch (action.type) {
    case actions.SET_RC_BADGES_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  overlay,
  siteMessage,
  siteResponse,
  boxCoupon,
  snowing, //for holiday
  sort,
  justCompletedRC,
  rcBadgesStatus,
});
