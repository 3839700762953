import { IPlan } from "../../../../types/plan";

export const _APPLE_PAY_FINE_PRINT = {
  finePrint: (
    plan: IPlan,
    renewalDate: string,
    yearlyRenewalDate: string,
    planPrice: string,
    renewalMonth: string,
    futureYear: string,
    showingTerms: boolean,
    isMobile: boolean,
    cta?: string,
  ) => {
    const leadInText = showingTerms
      ? "You"
      : `By ${isMobile ? "tapping" : "clicking"} "${cta} with Apple Pay", you`;

    const DATA = {
      monthly: `${leadInText} acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed and charged to the payment method provided for $${planPrice} plus tax on the ${renewalDate} of each month and one credit will be added to your account redeemable for your next book.`,
      audiobook: `${leadInText} acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed and charged to the payment method provided for $${planPrice} plus tax on ${renewalDate} of each month and two credits will be added to your account redeemable for your next two books.`,
      annual: `${leadInText} acknowledge that you are signing up for a recurring annual subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st day of ${renewalMonth} ${futureYear}, you will be renewed and charged to the payment method provided for $${planPrice} plus tax on ${yearlyRenewalDate} and 12 credits will be added to your account redeemable for your next 12 books.`,
    };
    if (plan.credits == 1 && plan.cycles == 1) {
      return DATA.monthly;
    }
    if (plan.credits == 2 && plan.cycles == 1) {
      return DATA.audiobook;
    }
    if (plan.credits >= 12 && plan.cycles >= 1) {
      return DATA.annual;
    }
  },
};
