import React, { useContext } from "react";
import { useCategoryLoader } from "../hooks/useCategory";
import { StoreDataContext } from "../contexts/StoreData";

import { Category } from "../../../../types/category";

type WithCategoryProps = {
  storeData?: object;
  getCategory: (productId: number | string | null | undefined) => {
    pending: boolean;
    error?: Error;
    data?: Category;
  };
};

const withCategory = <T extends WithCategoryProps>(
  Component: React.ComponentType<T>,
) => {
  const WithCategory = (props: Omit<T, keyof WithCategoryProps>) => {
    const { store, track } = useContext(StoreDataContext);
    const getCategory = useCategoryLoader();
    return (
      <Component
        {...(props as T)}
        storeData={{ store, track }}
        getCategory={getCategory}
      />
    );
  };
  return WithCategory;
};

export { withCategory };
