/**
 * Parent wrapper for loyalty modals.
 * Child of SiteOverlay.jsx
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { setOverlay } from "State/ui/creators";
import LoyaltyStepGift from "./LoyaltyStepGift.jsx";
import LoyaltyStepSuccess from "./LoyaltyStepSuccess.jsx";
import LoyaltyStepWelcome from "./LoyaltyStepWelcome.jsx";
import LoyaltyStepBirthday from "./LoyaltyStepBirthday.jsx";

class LoyaltyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.step || "gift",
    };
    this.setContent = this.setContent.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  setContent(step = this.state.step) {
    this.setState({
      step,
    });
  }

  closeModal() {
    if (typeof document !== "undefined") {
      document.getElementById("bodyWrapper").className = "modalClosed";
    }
    if (this.props.forRelationshipTier) {
      this.props.push("/relationship-status");
    } else {
      this.props.push("/bff-rewards");
    }
    this.props.setOverlay(null);
  }

  closeDropdown(e) {
    if (!/customSelectOption|dummyChild/.test(e.target.className)) {
      if (this.closeSearchRef) {
        this.closeSearchRef();
      }
      if (this.closeSelectRef) {
        this.closeSelectRef();
      }
    }
  }

  getContent() {
    let content;

    switch (this.state.step) {
      case "gift":
        content = (
          <LoyaltyStepGift
            {...this.props}
            step={this.state.step}
            setContent={this.setContent}
          />
        );
        break;
      case "birthday":
        content = (
          <LoyaltyStepBirthday
            {...this.props}
            step={this.state.step}
            setContent={this.setContent}
            closeModal={this.closeModal}
            closeSelectRef={(e) => (this.closeSelectRef = e)}
          />
        );
        break;
      case "success":
        content = (
          <LoyaltyStepSuccess
            {...this.props}
            step={this.state.step}
            setContent={this.setContent}
            closeModal={this.closeModal}
          />
        );
        break;
      case "welcome":
        content = (
          <LoyaltyStepWelcome
            {...this.props}
            step={this.state.step}
            setContent={this.setContent}
            closeModal={this.closeModal}
          />
        );
        break;
      default:
        content = null;
    }
    return content;
  }

  render() {
    const { overlay } = this.props;

    if (!overlay || (overlay && overlay.name !== "loyalty")) {
      return null;
    }

    return <div>{this.getContent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    hasCycleResponse:
      state.account && state.account?.cycleActions?.hasCycleResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyModal);
