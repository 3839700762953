import React from "react";
import styled from "styled-components";

const DATA = {
  apple: {
    imgAlt: "Download on the App Store",
    icon: "https://static.bookofthemonth.com/svgs/app_store_badge_ios.svg",
    link: "https://apps.apple.com/us/app/book-of-the-month/id1473873226",
  },
  google: {
    imgAlt: "Get it on Google Play",
    icon: "https://static.bookofthemonth.com/svgs/app_store_badge_android.svg",
    link: "https://play.google.com/store/apps/details?id=com.bookofthemonth",
  },
};
type DownloadButtonProps = {
  type: "apple" | "google";
};
const DownloadButton: React.FC<DownloadButtonProps> = ({ type }) => {
  const button = (
    <StyledDownloadButton>
      <a href={DATA[type].link}>
        <img
          className="appStoreIcon"
          src={DATA[type].icon}
          alt={DATA[type].imgAlt}
        />
      </a>
    </StyledDownloadButton>
  );
  return button;
};

const StyledDownloadButton = styled.div`
  position: relative;
  width: 100%;
  height: 35px;

  .downloadAppButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .appStoreIconWrapper {
    position: relative;
    width: 175px;
    height: 52px;
  }

  .appStoreIcon {
    object-fit: contain;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
`;

export default DownloadButton;
