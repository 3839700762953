import React from "react";
import styled from "styled-components";
import {
  _PRE_FRIEND_DATA,
  _FRIEND_DATA,
  _PRE_BFF_DATA,
  _BFF_DATA,
} from "./LoyaltyTierBenefitsListData";
import { MEDIAQUERIES } from "../../../css/Consts.js";

interface IBenefitsList {
  relationshipTier: string | null;
  boxes: number | null;
  top?: number;
  bottom?: number;
  isCanadianResident?: boolean;
}

interface IBlocksData {
  hed: string;
  dek: string;
}

const BenefitsList: React.FC<IBenefitsList> = ({
  relationshipTier,
  isCanadianResident,
  boxes,
  top = 0,
  bottom = 0,
}) => {
  const setBlockData = () => {
    if (relationshipTier == undefined || relationshipTier == "New Member") {
      return _PRE_FRIEND_DATA;
    } else if (relationshipTier == "Friend") {
      if (boxes && boxes <= 9) {
        return _FRIEND_DATA;
      } else {
        return _PRE_BFF_DATA;
      }
    } else {
      return _BFF_DATA;
    }
  };

  const filterBenefitData = (data: Array<IBlocksData>) => {
    let filteredBlocksData = data;
    if (isCanadianResident) {
      filteredBlocksData = data.filter(
        (blocksData) => blocksData.hed != "Lower Priced Add-Ons",
      );
    }
    return filteredBlocksData;
  };

  const blocksData = filterBenefitData(setBlockData());

  const getBlocks = () => {
    return blocksData.map((data, idx) => (
      <StyledBenefitsBlock key={idx}>
        <div>
          <h5>{data?.hed}</h5>
          <p>{data?.dek}</p>
        </div>
      </StyledBenefitsBlock>
    ));
  };

  return (
    <StyledBenefitsListWrapper top={top} bottom={bottom}>
      {getBlocks()}
    </StyledBenefitsListWrapper>
  );
};

interface IStyledBenefitsListWrapper {
  top?: number;
  bottom?: number;
}
export const StyledBenefitsListWrapper = styled.div.attrs({
  className: "sc-StyledBenefitsListWrapper" as string,
})<IStyledBenefitsListWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(props) => (props.top ? props.top + "px" : 0)};
  margin-bottom: ${(props) => (props.bottom ? props.bottom + "px" : 0)};
`;

export const StyledBenefitsBlock = styled.div.attrs({
  className: "sc-StyledBenefitsBlock" as string,
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: 40px;
  align-items: center;
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    img {
      max-height: 74px;
    }
  }
`;

export const StyledImageWrapper = styled.div.attrs({
  className: "sc-StyledImageWrapper" as string,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80px;
  margin-right: 16px;
  img {
    max-height: 74px;
  }
`;

export default BenefitsList;
