import React from "react";
import SkeletonWrapper, { Input, Button, H2, P } from "./SkeletonWrapper.jsx";

export const FormSkeleton = ({
  className,
  numInput,
  buttonStyle,
  withButton = true,
}) => {
  let button = !withButton ? null : <Button className={buttonStyle} />;
  if (withButton && buttonStyle === "rightAlign") {
    button = (
      <div className="enrollActionWrapper singleAction">
        <Button />
      </div>
    );
  }
  return (
    <SkeletonWrapper className={className || ""}>
      {new Array(numInput).fill(0).map((ignore, i) => (
        <Input key={i} />
      ))}
      {button}
    </SkeletonWrapper>
  );
};

const FormPageSkeleton = ({
  className,
  numInput,
  formClassName,
  hasSubhead = false,
  buttonStyle,
  withButton = true,
}) => (
  <SkeletonWrapper className={`-formPage ${className || ""}`}>
    <section className="pageHeader">
      <H2 className="-word -center" />
      {hasSubhead && <P className="content -narrow" />}
    </section>

    <section className="content -narrow ">
      <form>
        <FormSkeleton
          numInput={numInput}
          className={formClassName}
          buttonStyle={buttonStyle}
          withButton={withButton}
        />
      </form>
    </section>
  </SkeletonWrapper>
);

export default FormPageSkeleton;
