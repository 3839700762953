import React from "react";

const BIcon = ({ size = 64, isKnockout = false }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="32"
      cy="32"
      r="32"
      fill={isKnockout ? "var(--color-white)" : "var(--color-primary5)"}
      style={{ fillOpacity: 1 }}
    />
    <path
      d="M43.196 31.6348C47.168 30.7228 50 27.6948 50 23.9588C50 19.7988 46.52 18.0388 41.8 18.0388H25.88C17.92 18.0388 14 23.7988 14 28.6388C14 32.1588 16 35.2388 20.4 35.2388C24.96 35.2388 27.12 31.9588 27.12 29.4388C27.12 27.9188 26.28 26.6388 24.64 26.6388C23.2 26.6388 22.28 27.5588 22.28 28.9188C22.28 29.6388 22.52 30.1588 22.68 30.5588C22.84 30.9588 22.96 31.3188 22.96 31.6388C22.96 32.6788 21.8 33.1588 20.44 33.1588C17.6 33.1588 16.32 30.9988 16.32 28.4788C16.32 24.7588 19.24 20.1588 25.6 20.1588H29.84C31.8 20.1588 32.76 20.3188 32.32 21.9988L27.64 40.8788C27 43.5588 26.6 44.3988 23.16 44.3588L22.8 45.9588H37.88C44.76 45.9588 48.48 41.7588 48.48 37.5988C48.48 34.8228 46.792 32.3308 43.196 31.6348ZM37.56 20.1588H40.84C44.12 20.1588 46.48 21.3588 46.48 24.3188C46.48 27.5188 43.68 30.5588 39.12 30.5588H33.72L36.04 21.2788C36.24 20.4788 36.76 20.1588 37.56 20.1588ZM36.96 43.8388H31.96C31.16 43.8388 30.68 43.4788 30.68 42.8388C30.68 42.7188 30.72 42.5588 30.76 42.3988L33.188 32.6788H38.56C42.08 32.6788 44.88 34.0788 44.88 37.5188C44.88 40.6788 42.48 43.8388 36.96 43.8388Z"
      fill={isKnockout ? "var(--color-black)" : "var(--color-white)"}
      style={{ fillOpacity: 1 }}
    />
  </svg>
);

export default BIcon;
