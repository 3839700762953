/**
 * Shared form component used for login.
 *
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { accountLogin } from "State/account/flows.js";
import { clearError } from "State/error/creators";
import CheckMarkIcon from "UI/elements/CheckMarkIcon.jsx";
import FieldsetAccountInfo from "UI/components/forms/FieldsetAccountInfo";
import ClickTracker from "UI/elements/ClickTracker";
import { withStoreData } from "UI/hoc/withStoreData";
import { useAsyncEffect } from "UI/hooks";

const LoginForm = ({ accountLogin, ...props }) => {
  const [remember, setRemember] = useState(false);
  const [account, setAccount] = useState({
    email: "",
    password: "",
  });
  const [accountIsValid, setAccountIsValid] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useAsyncEffect(async () => {
    if (isSubmitting && window) {
      try {
        // TODO: get this to actually catch errors :o
        const { store, track } = props.storeData;
        const email = account.email;
        const password = account.password;
        await accountLogin({ email, password, remember }, store, track);
        setIsSubmitting(false);
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  }, [isSubmitting]);

  const submitLogin = async () => {
    setIsSubmitting(true);
  };

  const showRememberMe = props.hideRemember ? false : true;
  const showForgotPassword = props.hideForgotPassword ? false : true;

  const rememberMe = (
    <div className="checkWrapper">
      <label>
        <input
          type="checkbox"
          name="remember" //only place currently used.
          value={remember}
          onChange={() => {
            logClick("Clicked - Remember me");
            setRemember(!remember);
          }}
        />
        <div>
          <CheckMarkIcon />
          <span className="">Remember me</span>
        </div>
      </label>
    </div>
  );

  const forgotPassword = (
    <ClickTracker
      ctaType={"link"}
      tabIndex={0}
      id="forgot-password-link"
      linkTo={"forgot-password"}
      title={"Forgot password?"}
      logClickData={"Clicked - Forgot password"}
      size={14}
    />
  );

  return (
    <StyledLoginWrapper>
      {props.headline}
      <FieldsetAccountInfo
        fieldsToShow={
          props.fieldsToShow ? props.fieldsToShow : ["email", "password"]
        }
        onChangeTextCb={(a) => setAccount(a)}
        validFormCheckCB={(v) => setAccountIsValid(v)}
        overrideSubmitError={props.loginError}
        bottom={0}
        overrideAction={() => submitLogin()}
        buttonText={isSubmitting ? "Updating..." : "Log in"}
      />
      {(showRememberMe || showForgotPassword) && (
        <StyledRemeberForgotWrapper>
          {showRememberMe && rememberMe}
          {showForgotPassword && forgotPassword}
        </StyledRemeberForgotWrapper>
      )}
    </StyledLoginWrapper>
  );
};

export const StyledLoginWrapper = styled.div.attrs({
  className: "sc-StyledLoginWrapper",
})`
  margin-bottom: 20px;
`;

export const StyledRemeberForgotWrapper = styled.div.attrs({
  className: "sc-StyledRemeberForgotWrapper",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

function mapStatetoProps(state) {
  return {
    loginError: state.error.type === "login" ? state.error.error : null,
    enrollEmail: state.joinData.email || state.gift?.giftPurchase?.giver?.email,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ accountLogin, clearError }, dispatch);
}

export default withStoreData(
  connect(mapStatetoProps, mapDispatchToProps)(LoginForm),
);
