import { SelectOption } from "../../../../../ui/elements/form";

export const generateYearsList = (): SelectOption[] => {
  const today = new Date();

  const currentYear = today.getUTCFullYear();
  const futureYear = today.getUTCFullYear() + 1;

  const years: SelectOption[] = [
    { value: currentYear.toString(), label: currentYear.toString() },
    { value: futureYear.toString(), label: futureYear.toString() },
  ];

  return years;
};
