import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { withStoreData } from "UI/hoc/withStoreData";
import { useAsyncEffect } from "UI/hooks/useAsyncEffect";
import { changeEmailPreference, getEmailPreference } from "State/account/flows";
import Spinner from "UI/elements/Spinner";
import { EditEmailPrefs } from "UI/components/account/EditEmailPrefs";

const EmailPrefsEditWrapper = (props) => {
  const [error, setError] = useState(null);
  const [account, setAccount] = useState(props.account);
  const [initialValues, setInitialValues] = useState({});
  const [isLoadingPrefs, setIsLoadingPrefs] = useState(true);
  const vbtEmailPref = props.appFeatures?.find(
    (f) => f?.feature == "New PES Questions",
  );
  const showVbtEmailPref =
    vbtEmailPref?.status == "Active" ||
    (vbtEmailPref?.status == "Beta" && vbtEmailPref?.isBetaEligible);

  useAsyncEffect(async () => {
    const preferences = await props.getEmailPreference(account.email);
    return () => {
      setInitialValues(preferences);
      setIsLoadingPrefs(false);
    };
  }, []);

  const updatePreferences = async (ep) => {
    setError(null);
    const successFunction = () => {
      props.setOverlay(null);
    };
    const failFunction = (err) => {
      setError(err);
    };
    await props.changeEmailPreference(
      account,
      ep,
      successFunction,
      failFunction,
    );
  };

  const removeOverlay = () => {
    props.setOverlay(null);
  };

  if (isLoadingPrefs) return <Spinner size="large" />;

  return (
    <>
      <EditEmailPrefs
        closeAction={removeOverlay}
        updatePreferences={updatePreferences}
        error={error}
        currentEmailPrefs={initialValues}
        showVbtEmailPref={showVbtEmailPref}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
    appFeatures: state.appFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeEmailPreference,
      getEmailPreference,
      setOverlay,
    },
    dispatch,
  );
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(EmailPrefsEditWrapper),
);
