import { useReducer } from "react";

const sliderReducer = (state: any, action: any) => {
  const currentPosition = state.position;
  let position;
  switch (action.direction) {
    case "length":
      return {
        ...state,
        totalLength: action.totalLength,
        pointerNextActive: action.totalLength < 0,
      };
    case "next":
      position = currentPosition - state.moveAmount;
      return {
        ...state,
        position,
        pointerPrevActive: position != 0,
        pointerNextActive:
          position - 900 > state.totalLength &&
          position - state.moveAmount !== state.totalLength,
      };
    case "prev":
      position = currentPosition + state.moveAmount;
      return {
        ...state,
        position,
        pointerPrevActive: position != 0,
        pointerNextActive: position - 900 > state.totalLength,
      };
    default:
      return state;
  }
};

const useSlider = (totalLength: number, moveAmount: number) => {
  return useReducer(sliderReducer, {
    position: 0,
    pointerPrevActive: false,
    pointerNextActive: totalLength < 0,
    totalLength,
    moveAmount,
  });
};

export { useSlider };
