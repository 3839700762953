import globalScope from "../../global"; // For tests, ugh

export async function get(id: number) {
  const response = await fetch(`${global.apiHost}/api/v0/plan/` + id);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const plan = await response.json();
  return await plan;
}

export async function getPlanSet(id: number) {
  const response = await fetch(`${global.apiHost}/api/v0/plan/planSet/` + id);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const planSet = await response.json();
  return await planSet;
}

export async function getPlanSetByName(name: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/plan/planSet/` + name + "?name",
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const planSet = await response.json();
  return await planSet;
}
