/**
 * Displays message to user if their browser does not meet apps minimun requirements.
 * Child of BOM.jsx
 */

import React, { Component } from "react";
import { browserCheck } from "Utils/browserCheck";

export default class BrowserNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShowing: false,
    };
  }

  componentDidMount() {
    let isOK = this.checkTheBrowser();
    if (typeof document !== "undefined") {
      if (!isOK && document.cookie.indexOf("seenUpdateBrowserModal=") <= -1) {
        this.setState({ modalShowing: true });
        document.cookie = "seenUpdateBrowserModal=true;";
      }
    }
  }

  checkTheBrowser() {
    let ok = true,
      browser = browserCheck();
    const name = browser.name,
      version = browser.version;

    switch (name) {
      case "Chrome":
        if (version <= 55) {
          ok = false;
        }
        break;
      case "Firefox":
        if (version <= 55) {
          ok = false;
        }
        break;
      case "Safari":
        if (version <= 7) {
          ok = false;
        }
        break;
      case "MSIE":
        if (version <= 11) {
          ok = false;
        }
        break;
      case "trident":
        if (version <= 48) {
          ok = false;
        }
        break;
      default: {
        ok = true;
      }
    }
    return ok;
  }

  closeModal() {
    this.setState({
      modalShowing: false,
    });
  }

  render() {
    let { storeTrack } = this.props;
    return (
      <div
        className={
          "browserUpdateWrapper modalWrapper" +
          (this.state.modalShowing ? " showing" : "")
        }
      >
        <div className="modal browserUpdate">
          <div className="modalClose" onClick={() => this.closeModal()}>
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 14.782 14.533"
              enableBackground="new 0 0 14.782 14.533"
            >
              <polygon
                fill="#020202"
                points="14.572,13.966 7.881,7.275 14.571,0.584 14.119,0.131 7.428,6.823 0.737,0.131 0.284,0.584
              6.976,7.275 0.284,13.966 0.737,14.419 7.428,7.728 14.119,14.419   "
              />
            </svg>
          </div>
          <div className="center">
            <h3>
              Please update <span className="nowrap">your browser</span>
            </h3>
            <p className="smallText">
              Our site works best with the latest versions of these web
              browsers. Some BOTM features may not work on older or outdated
              browsers.
            </p>
            <p className="smallText">
              To update, click your preferred browser below and follow the
              instructions.
            </p>
            <ul>
              <li>
                <div className="smallText">
                  <img
                    src={`${storeTrack.images}elements/browserDetect/chrome.png`}
                    alt="Chrome browser logo"
                  />
                  Chrome
                </div>
                <a
                  target="_new"
                  href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
                  className="button primary"
                >
                  Learn more
                </a>
              </li>
              <li>
                <div className="smallText">
                  <img
                    src={`${storeTrack.images}elements/browserDetect/firefox.png`}
                    alt="Firefox browser logo"
                  />
                  Firefox
                </div>
                <a
                  target="_new"
                  href="https://support.mozilla.org/en-US/kb/update-firefox-latest-version"
                  className="button primary"
                >
                  Learn more
                </a>
              </li>
              <li>
                <div className="smallText">
                  <img
                    src={`${storeTrack.images}elements/browserDetect/ie.png`}
                    alt="Internet Explorer browser logo"
                  />
                  Internet Explorer
                </div>
                <a
                  target="_new"
                  href="https://www.microsoft.com/en-us/windows/microsoft-edge/microsoft-edge"
                  className="button primary"
                >
                  Learn more
                </a>
              </li>
              <li>
                <div className="smallText">
                  <img
                    src={`${storeTrack.images}elements/browserDetect/safari.png`}
                    alt="Safari browser logo"
                  />
                  Safari
                </div>
                <a
                  target="_new"
                  href="https://support.apple.com/en-us/HT201541"
                  className="button primary"
                >
                  Learn more
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
