import * as React from "react";

interface IAlarmClockIcon {
  handleClick?: (...args: any) => void;
  showBackground?: boolean;
  color?: string;
  width?: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}
export const AlarmClockIcon: React.FC<IAlarmClockIcon> = ({
  showBackground = false,
  color = "#D2E9F8",
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  width = 64,
  height = 64,
}) => (
  <svg
    x="0px"
    y="0px"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 64 64"
    fill="none"
    style={{
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left,
    }}
  >
    {showBackground && (
      <path
        fill="#D2E9F8"
        d="M55.5,18c-0.3-1.5-1.4-2.6-2.4-3.5c-2.2-2.1-4.6-4.1-7.6-4.8C44.1,9.4,42.4,9.3,41,10s-2.2,2.3-1.8,3.9
	c0.3,0.9,0.9,1.7,1.6,2.4c-4.6-2.1-10-2.4-14.8-0.8c0.4-0.8,0.7-1.7,0.5-2.8c-0.2-1.5-0.9-2.8-2.4-3.4c-1.3-0.6-2.9-0.5-4.3-0.1
	c-2.7,0.8-5.6,2.3-7.6,4.3c-1.8,1.9-2.8,4.9-1.2,7.3c1,1.5,2.8,2.1,4.6,1.7c0.7-0.1,1.3-0.4,1.9-0.8c-2.9,3.6-4.6,8.2-4.7,12.8
	c-0.1,5,1.7,9.8,4.9,13.6c-0.4,0.3-0.7,0.7-1,1c-0.8,0.9-1.8,2.2-1.7,3.5c0,0.9,0.5,1.7,1.2,2.1c0.7,0.4,1.6,0.4,2.4,0.1
	c1.1-0.5,2.1-1.3,2.9-2c0.3-0.3,0.5-0.5,0.8-0.8c6.2,3.8,13.9,3.7,20,0.2c0.1,0.2,0.2,0.4,0.4,0.6c0.8,1,1.7,1.9,3,2.2
	c1.4,0.4,3.1,0.1,3.6-1.4c0.5-1.4-0.7-2.7-1.4-3.9c-0.1-0.2-0.3-0.4-0.4-0.6c3.1-4,4.8-9,4.7-14.1c-0.1-5-2-9.8-5.2-13.6
	c2,1.3,4.4,2.1,6.5,0.6C55,21.1,55.8,19.6,55.5,18z"
      />
    )}
    <path
      fill="black"
      d="M51.6,14.3l-5.8-3.9c-1.9-1.2-4.4-0.7-5.5,1c-0.5,0.6-0.7,1.4-0.6,2.2c0.1,0.8,0.6,1.6,1.3,2l3,2l-1.1,1.6
  c-3-2-6.5-3.2-10.4-3.2c-3.8,0-7.4,1.2-10.4,3.2l-1-1.5l2.9-2c0.7-0.4,1.1-1.1,1.2-2c0.1-0.8,0-1.6-0.5-2.3c-1.3-1.8-3.7-2.3-5.6-1
  l-5.8,3.9c-1.8,1.2-2.3,3.6-1.1,5.6c0.4,0.7,1.1,1.1,2,1.2c0.2,0,0.3,0,0.5,0c0.6,0,1.2-0.2,1.7-0.5l2.9-1.9l1.1,1.6
  c-4,3.4-6.5,8.4-6.5,14.1c0,5.5,2.4,10.3,6.2,13.7l-3.6,3.6c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3.7-3.7
  c3,2.2,6.8,3.5,10.8,3.5s7.7-1.3,10.8-3.5l3.7,3.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-3.6-3.6
  C48.6,44.8,51,40,51,34.5c0-5.6-2.5-10.7-6.5-14.1l1.1-1.7l2.8,1.9c0.5,0.4,1.1,0.6,1.7,0.6c0.2,0,0.3,0,0.5,0
  c0.8-0.1,1.6-0.6,2-1.3c0,0,0,0,0,0C53.8,18,53.4,15.6,51.6,14.3z M15.4,19.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.4-0.1-0.6-0.3
  c-0.6-1.1-0.4-2.3,0.5-2.8c0,0,0,0,0,0l5.8-3.9c0.4-0.2,0.8-0.4,1.2-0.4c0.6,0,1.3,0.3,1.7,0.9c0.1,0.2,0.2,0.5,0.2,0.8
  c0,0.2-0.1,0.4-0.4,0.6l-3.7,2.4c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1L15.4,19.1z M49,34.5C49,43.6,41.6,51,32.5,51
  C23.4,51,16,43.6,16,34.5C16,25.4,23.4,18,32.5,18C41.6,18,49,25.4,49,34.5z M51,18.8c-0.2,0.2-0.4,0.4-0.7,0.4
  c-0.2,0-0.4,0-0.7-0.2l-7.5-5c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.2,0-0.4,0.2-0.7c0.4-0.6,1-0.9,1.6-0.9c0.4,0,0.8,0.1,1.2,0.3l5.8,3.9
  C51.4,16.6,51.6,17.8,51,18.8z"
    />
    <polygon
      fill="black"
      points="33,31.6 33,21 31,21 31,36.4 37.7,29.7 36.3,28.3 	"
    />
  </svg>
);
