import React from "react";

const TrackSwitchModal = ({ removeOverlay, title, desk, btnTitle }) => (
  <div className="trackSwitchModal">
    <h3>{title}</h3>
    <p>{desk}</p>
    <button className="button primary center" onClick={removeOverlay}>
      {btnTitle}
    </button>
  </div>
);

export default TrackSwitchModal;
