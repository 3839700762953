/**
 * Shared custom form field component used inplace of html select input.
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Pointer } from "UI/elements/navigation/Pointers";

import { COLORS, FONTS } from "CSS/Consts.js";

export default class CustomSelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointerTop: 0,
      isOpen: false,
      arrowIdx: 1,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.setPointerPosition = this.setPointerPosition.bind(this);
    this.arrowKeys = this.arrowKeys.bind(this);
    props.closeSelectRef(this.closeDropdown.bind(this));
  }

  componentDidMount() {
    this.setPointerPosition();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ isOpen: false });
    }
  }

  setPointerPosition() {
    const element = document.getElementsByClassName("selectWrapper")[0];
    const pointerHeight = document.getElementsByClassName("pointerWrapper")[0];
    let top =
      element && pointerHeight
        ? `${Math.floor(
            (element.clientHeight - pointerHeight.clientHeight) / 2,
          )}px`
        : 0;
    this.setState({ pointerTop: top });
  }

  toggleDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeDropdown() {
    if (this.state.isOpen) this.setState({ isOpen: false });
  }
  arrowKeys(e) {
    e.preventDefault();
    const ele = document.getElementsByClassName("customSelectOption");
    if (e.key == "ArrowUp") {
      if (this.state.arrowIdx < 1) {
        this.setState({ arrowIdx: ele.length - 1 });
      } else {
        this.setState({ arrowIdx: this.state.arrowIdx - 1 });
      }
    } else {
      if (this.state.arrowIdx >= ele.length) {
        this.setState({ arrowIdx: 1 });
      } else {
        this.setState({ arrowIdx: this.state.arrowIdx + 1 });
      }
    }
    ele?.[this.state.arrowIdx]?.focus();
  }

  render() {
    const { isOpen } = this.state;
    const {
      value,
      onChange,
      children,
      placeholder,
      origin = null,
    } = this.props;
    const childrenWithProps = React.Children.map(
      children,
      (child) =>
        child &&
        React.cloneElement(child, {
          onClick:
            child.props.isclickable === "false"
              ? null
              : () => {
                  onChange(child.props["data-value"]);
                },
          onKeyPress:
            child.props.isclickable === "false"
              ? null
              : () => {
                  onChange(child.props["data-value"]);
                },
          onKeyDown: (e) => {
            e.key == "ArrowDown" || e.key == "ArrowUp"
              ? this.arrowKeys(e)
              : null;
          },
          className: `${child.props.className} customSelectOption option ${
            value == child.props["data-value"] ? "active" : ""
          }`,
        }),
    ).filter((c) => c);
    const dummyChild = (
      <div
        className={
          "option dummyChild" +
          (value && value !== "Select an option" ? " active" : "")
        }
        data-value={value ? value : childrenWithProps[0].props["data-value"]}
      >
        {value ? value : childrenWithProps[0].props["data-value"]}
      </div>
    );
    return (
      <StyledCustomSelectDropdown
        className={`customSelectDropdown ${isOpen ? "isOpen" : ""}`}
        id="customSelectDropdown"
        onClick={this.toggleDropdown}
        onKeyPress={this.toggleDropdown}
        tabIndex="0"
      >
        <div
          className={
            "selectWrapper" +
            ((value || childrenWithProps[0].props["data-value"]).length >= 30
              ? " longOption"
              : "") +
            (origin ? ` ${origin}` : "")
          }
        >
          <div
            className="pointerWrapper"
            style={{ top: this.state.pointerTop }}
          >
            {origin == "occupationModal" ? (
              <Pointer
                id="pointer"
                type={"down"}
                filled={false}
                isActive={isOpen}
              />
            ) : (
              <Pointer id="pointer" isActive={isOpen} animate={true} />
            )}
          </div>
          {dummyChild}
          {placeholder && isOpen && (
            <div className="placeholder">{placeholder}</div>
          )}
          {childrenWithProps}
        </div>
        {isOpen && !this.props.hideDefaultOption && (
          <div className="dummy">Select an option</div>
        )}
      </StyledCustomSelectDropdown>
    );
  }
}

export const StyledCustomSelectDropdown = styled.div`
  margin-top: 6px;
  cursor: pointer;
  .dummy {
    height: 48px;
  }
  option {
    white-space: normal;
  }
  .dummyChild {
    color: ${COLORS.gray5};
  }
  .option {
    padding: 5px 0;
    color: ${COLORS.gray5};
    &.active {
      color: ${COLORS.black};
    }
    &:first-of-type {
      padding: 2px 0;
    }
    &.dummyChild.active {
      white-space: nowrap;
      overflow: hidden;
      margin-right: 20px;
      text-overflow: ellipsis;
    }
  }
  .option:nth-of-type(n + 3) {
    display: none;
  }
  .selectWrapper {
    position: relative;
    font-size: 1.8rem;
    font-family: ${FONTS.sansSerifStackRegular};
    background: white;
    margin: 0;
    .pointerWrapper {
      position: absolute;
      right: 18px;
    }
  }
  &.isOpen {
    position: relative;
    .selectWrapper {
      border: 1px solid ${COLORS.primary5};
      height: auto;
      position: absolute;
      z-index: 100;
      width: 100%;
    }
    .occupationModal {
      position: relative;
    }
    .option {
      display: block;
      &.dummyChild {
        display: none;
      }
      &:hover {
        color: ${COLORS.black};
      }
    }
  }
`;
