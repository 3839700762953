import React from "react";
import Login from "../pages/account/Login";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import { connect } from "react-redux";
import { setLoginFlow } from "State/loginFlow/creators";
import { snesRedirect } from "Utils/snesRedirect";

const Authenticate = (props) => {
  if (props.loginAttempted) {
    const authenticated =
      props.account &&
      ["Member", "Rejoin", "Courtesy"].some(
        (t) => t === props.account.policy.type,
      );
    if (authenticated) {
      if (props.pageClassName) {
        return <div className={props.pageClassName}>{props.children}</div>;
      } else {
        return props.children;
      }
    } else if (!props.account) {
      props.loginFlow !== "DONOTHING" && props.setLoginFlow("DONOTHING");
      return <Login tierEntryPoint={props?.entryPoint || null} />;
    } else {
      snesRedirect("snes", "/the-best-new-books");
      return <LoadingCoinSkeleton />;
    }
  } else {
    return <LoadingCoinSkeleton />;
  }
};

function mapStateToProps(state) {
  return {
    account: state.account,
    loginAttempted: state.loading.loginAttempted,
    loginFlow: state.loginFlow,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLoginFlow, push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
