import * as actions from "./actions";

export default function reducer(state = [], action) {
  switch (action.type) {
    case actions.SET_ACCOUNT_HISTORY:
      return action.payload;
    case actions.ADD_TO_ACCOUNT_HISTORY:
      return [...state, action.payload];
    case actions.CANCEL_ACCOUNT_HISTORY_RECORD: {
      const historyRecordIndex = state.findIndex(
        (hr) => hr.id === action.payload.id,
      );
      const historyRecord = {
        ...state[historyRecordIndex],
        status: "Cancelled",
      };
      return [
        ...state.slice(0, historyRecordIndex),
        historyRecord,
        ...state.slice(historyRecordIndex + 1),
      ];
    }
    default:
      return state;
  }
}
