import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStoreData } from "UI/hoc";
import Confirmation from "UI/pages/cancel_pause/Confirmation.jsx";
import { snesRedirect } from "Utils/snesRedirect";

const SuccessRejoin = (props) => {
  const navigate = () => {
    props.removeOverlay();
    snesRedirect("snes", "/the-best-new-books");
  };

  return (
    <Confirmation
      header={"You did it!"}
      dek={`Excellent work! Your account is now up to date.`}
      icon={<img src="https://static.bookofthemonth.com/svgs/Icon_LOL.svg" />}
      buttonText={"See the books"}
      action={navigate}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default withStoreData(connect(null, mapDispatchToProps)(SuccessRejoin));
