import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { logClick } from "../../../state/analytics/flows";
import { useStore } from "react-redux";

type FormCheckboxProps = {
  checkboxCB?: (status: "checked" | "unchecked" | "error") => void;
  checkboxStatus: "checked" | "unchecked" | "error";
  id: string;
  trackingData?: {};
  error?: boolean;
  label?: string | JSX.Element | ReactNode;
  top?: number;
  isMobile?: boolean;
  isDisabled?: boolean;
};

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  checkboxStatus = "unchecked",
  error = false,
  label,
  id,
  trackingData = {},
  checkboxCB,
  top = 20,
  isMobile = false,
  isDisabled = false,
}) => {
  const ID = `form-checkbox-${id}`;
  const store = useStore();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const state = evt.target.checked ? "checked" : "unchecked";
    checkboxCB?.(state);
    logClick({ ...trackingData, eventName: ID, state }, store);
  };

  const setLabel = () => {
    if (!label) {
      return null;
    } else {
      return (
        <span className={`miniText ${"--" + checkboxStatus}`}>{label}</span>
      );
    }
  };

  return (
    <StyledFormCheckboxWrapper top={top} isMobile={isMobile}>
      <label className={`label --${checkboxStatus}`}>
        <input
          type="checkbox"
          onChange={isDisabled ? undefined : (e) => handleChange(e)}
          checked={checkboxStatus == "checked"}
        />
      </label>
      {setLabel()}
      <style>{`
        .sc-formCheckboxWrapper{
          margin-top:${top}px`}</style>
    </StyledFormCheckboxWrapper>
  );
};

type StyledFormCheckboxWrapperProps = {
  top: number;
  isMobile?: boolean;
};
const StyledFormCheckboxWrapper = styled.div.attrs({
  className: "sc-FormCheckboxWrapper",
})<StyledFormCheckboxWrapperProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  margin-top: ${({ top }) => top}px;

  input[type="checkbox"] {
    display: none;
  }

  .miniText {
    color: var(--color-gray5);
  }
  .miniText.--error {
    color: var(--color-error6);
  }

  .label {
    height: 28px;
    width: 28px;
    display: inline-block;
    padding: ${({ isMobile }) => (isMobile ? 2 : 4)}px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
  }
  .label.--unchecked {
    background-image: url("https://static.bookofthemonth.com/icons/selector_unchecked.svg");
  }

  .label.--checked {
    background-image: url("https://static.bookofthemonth.com/icons/selector_checked_updated.svg");
  }
  .label.--error {
    background-image: url("https://static.bookofthemonth.com/icons/selector_error.svg");
  }
`;

export default FormCheckbox;
