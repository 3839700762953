import React from "react";
import styled from "styled-components";
import { COLORS } from "../../css/Consts";

const ColorBlock = () => {
  const colors = [
    COLORS.yellow,
    COLORS.pink,
    COLORS.primary5,
    COLORS.green,
    COLORS.blue,
    COLORS.lilac,
    COLORS.red,
  ];

  return (
    <StyledColorBlock>
      {colors.map((color, idx) => (
        <div
          key={idx}
          style={{ backgroundColor: color, width: `${100 / colors.length}%` }}
        />
      ))}
    </StyledColorBlock>
  );
};

const StyledColorBlock = styled.div`
  display: flex;
  height: 12px;

  @media screen and (max-width: 375px) {
    height: 6px;
  }
`;

export default ColorBlock;
