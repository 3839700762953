import React from "react";

const Checkmark = ({
  order = 0,
  color = "var(--color-gray5)",
  strokeWidth = 1.5,
  width = 18,
  height = 12,
}) => (
  <div className={`checkmark toAnimate -o${order}`}>
    <svg width={width} height={height} viewBox="0 0 18 12" fill="none">
      <path
        d="M1.5 6L6.5 11L16.5 1"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Checkmark;
