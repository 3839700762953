import * as React from "react";
import styled from "styled-components";

//-----------------

export const CheckmarkIcon: React.FC<{
  isActive?: boolean;
}> = ({ isActive }) => {
  return (
    <StyledCheckmarkIcon isActive={isActive}>
      <svg version="1.1" width="20px" height="20px" viewBox="0 0 20 20">
        <path
          fill="var(--color-white)"
          d="M15.271,5.246C14.9,4.953,14.362,5.017,14.07,5.388l-5.947,7.546l-3.028-2.295
          c-0.378-0.285-0.915-0.211-1.199,0.166c-0.285,0.375-0.211,0.913,0.165,1.198l3.53,2.676c0.423,0.4,1.065,0.307,1.457-0.152
          l6.365-8.078C15.706,6.076,15.643,5.539,15.271,5.246z"
        />
      </svg>
    </StyledCheckmarkIcon>
  );
};

export interface IStyledCheckmarkIconProps {
  isActive?: boolean;
}

export const StyledCheckmarkIcon = styled.div.attrs({
  className: "sc-StyledCheckmarkIcon" as string,
})<IStyledCheckmarkIconProps>`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${(props) =>
    props.isActive
      ? `4px solid var(--color-primary5)`
      : `2px solid var(--color-gray2)`};
  svg {
    cursor: pointer;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transform: scale(0) opacity(0);
    transition: 0.2s ease-in-out;
  }
`;

//-----------------

export const RadioButton: React.FC<{
  handleChange: (ep?: string | boolean) => void;
  isActive?: boolean;
  groupName?: string;
  value: string;
  text?: string;
  image?: string;
  isChecked?: boolean;
}> = ({ isActive, groupName, value, text, image, handleChange, isChecked }) => {
  return (
    <StyledRadioButton className={"styledRadioButton"} id={"StyledRadioButton"}>
      <div>
        <label>
          <input
            type="radio"
            name={groupName}
            value={value}
            onChange={() => handleChange(value)}
            checked={isChecked}
          />
          <div />
          {image && <img src={image} />}
          {text && <p>{text}</p>}
        </label>
      </div>
    </StyledRadioButton>
  );
};

export const StyledRadioButton = styled.div`
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    &~ div {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
      border: 2px solid var(--color-gray2)};
    }
  }
  input:checked ~ div {
    border: 6px solid var(--color-primary5);
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  p {
    font-size: 1.6rem;
    cursor: pointer;
    text-transform: none;
  }
  img {
    max-width: 30px;
    height: auto;
    margin-right: 8px;
  }

  @keyframes toggleOnCheckbox {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    70% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;

//-----------------

export const RadioButtonList: React.FC<{
  top?: number;
  bottom?: number;
  handleUpdate: (ep?: string | boolean) => void;
  radioButtonGroupName?: string;
  radioButtonValues: Array<{
    text: string;
    image?: string;
    value: string | boolean;
    isChecked?: boolean;
  }>;
}> = ({
  radioButtonGroupName,
  radioButtonValues,
  handleUpdate,
  top = 0,
  bottom = 16,
}) => {
  return (
    <StyledRadioButtonList
      id={"StyledRadioButtonList"}
      top={top}
      bottom={bottom}
    >
      {radioButtonValues.map((buttonValues: any, index: number) => (
        <RadioButton
          key={index}
          groupName={radioButtonGroupName}
          value={buttonValues.value}
          text={buttonValues.text}
          image={buttonValues.image}
          handleChange={handleUpdate}
          isChecked={buttonValues.isChecked}
        />
      ))}
    </StyledRadioButtonList>
  );
};

interface IStyledRadioButtonListProps {
  top?: number;
  bottom?: number;
}

export const StyledRadioButtonList = styled.div.attrs({
  className: "sc-StyledPageNarrowContent" as string,
})<IStyledRadioButtonListProps>`
  .styledRadioButton {
    margin-bottom: ${(props) => props.bottom + "px"};
    margin-top: ${(props) => props.top + "px"};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default StyledRadioButtonList;
