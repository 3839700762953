import React, { useEffect, useMemo, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { skipThisMonth } from "State/box/flows";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import { setSurveyResponse, getSurveyByName } from "State/survey/flows";
import styled from "styled-components";
import ClickTracker from "UI/elements/ClickTracker";
import { COLORS, MEDIAQUERIES } from "CSS/Consts";
import SurveyQuestions from "./SurveyQuestions";
import SurveyQuestionsSkeleton from "UI/loadingSkeletons/SurveyQuestionsSkeleton";
import { useWindowSize } from "UI/hooks";
import TermsAgreementBlock from "UI/components/TermsAgreementBlock.tsx";
import { RegionContext } from "UI/contexts/RegionContext";
import showAppFeature from "Utils/showAppFeature";
import * as SurveyNames from "Utils/surveyNames";

const SkipSurvey = (props) => {
  const {
    surveyList,
    setSavedResponse,
    getSurveyByName,
    currentMonth,
    skipThisMonth,
    setOverlay,
    removeOverlay,
    appFeatures,
    account,
  } = props;
  const [survey, setSurvey] = useState(
    surveyList?.find((s) => s?.name == SurveyNames.SKIP) || null,
  );
  const [pendingSkip, setPendingSkip] = useState(false);
  const [step, setStep] = useState(1);
  const [content, setContent] = useState(null);
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const { locationHeader } = useContext(RegionContext);
  const audiobookOptionFeature = appFeatures?.find(
    (f) => f?.feature == "Skip Survey Audiobook Option",
  );
  const showAudiobookQ = showAppFeature(
    audiobookOptionFeature,
    account?.countryCode || locationHeader?.countryHeader,
  );

  useEffect(() => {
    getSurveyByName(SurveyNames.SKIP);
    setContent(data[step]);
  }, []);

  useEffect(() => {
    setContent(data[step]);
  }, [step]);

  useEffect(() => {
    if (!survey) {
      setSurvey(surveyList.find((s) => s?.name == SurveyNames.SKIP));
    }
  }, [surveyList]);

  let surveyQs = <SurveyQuestionsSkeleton />;

  const bookIMG = (
    <img
      src={"https://static.bookofthemonth.com/svgs/Icon_SkipBook.svg"}
      style={{ width: "192px" }}
    />
  );
  const checkIMG = (
    <img
      src={"https://static.bookofthemonth.com/elements/check.gif"}
      style={{ width: "58px" }}
    />
  );
  const skip = { title: "Skip", fn: () => skipMonth() };
  const cancel = { title: "Nevermind", fn: () => removeOverlay() };
  const confirm = { title: "Ok", fn: () => removeOverlay() };

  const data = {
    1: {
      hed: "Are you sure you want to skip?",
      dek: "You can always save your book credits for future months. Credits are flexible, priced right, and good for a whole year. ",
      img: bookIMG,
      cta1: skip,
      cta2: cancel,
    },

    2: {
      hed: "What’s the top reason you’re skipping this month?",
      showSurvey: surveyQs,
      img: bookIMG,
    },

    3: {
      hed: `You skipped ${currentMonth}.`,
      dek: "You won’t be charged at the end of this month, and your existing book credit will roll over to next month.",
      img: checkIMG,
      cta1: confirm,
    },
  };

  const nextStep = () => {
    setStep((step) => step + 1);
  };

  const skipMonth = async () => {
    setPendingSkip(true);
    await skipThisMonth(true);
    setPendingSkip(false);
    nextStep();
  };

  const parents = useMemo(() => {
    if (survey?.surveyQuestions?.parents) {
      const filteredParents = !showAudiobookQ
        ? survey.surveyQuestions.parents.filter(
            (p) => p.name !== "I want to use my credit(s) on an audiobook",
          )
        : survey.surveyQuestions.parents;
      const prefix = [
        ...filteredParents
          .filter((p) => !p.priority)
          .sort(() => Math.random() - 0.5),
      ];
      const suffix = [...filteredParents.filter((p) => p.priority)];
      return [...prefix, ...suffix];
    }
  }, [survey]);

  const children = survey?.surveyQuestions?.children || [];
  surveyQs = (
    <StyledSurvey id="StyledSurvey" isMobile={isMobile}>
      <SurveyQuestions
        surveyId={survey?.id}
        surveyQuestions={{ parents, children }}
        accountId={props.accountID}
        buttonText="Next"
        multipleChoice={true}
        holdSurveySubmit={false}
        nextStep={nextStep}
        setSavedResponse={setSavedResponse}
        setSurveyResponse={props.setSurveyResponse}
        responseOnClick={null}
        dek={true}
        errorMessage=""
        mustComplete={false}
        maxChoice={1}
      />
    </StyledSurvey>
  );

  const ButtonCTA = (cta) => (
    <ClickTracker
      id="SkipSurvey Button"
      buttonType="SkipSurvey CTA1"
      ctaType="button"
      color="primary"
      isCentered={true}
      title={cta.title}
      handleClick={cta.fn}
      isPending={pendingSkip}
    />
  );

  const LinkCTA = (cta) => (
    <ClickTracker
      id="SkipSurvey CTA2"
      buttonType="button"
      ctaType="link"
      isCentered={true}
      title={cta.title}
      handleClick={cta.fn}
    />
  );

  if (!content) {
    return null;
  }
  const { hed, dek, img, cta1, cta2 } = content;
  const showSurvey = step === 2;
  const showTOS = step === 1;

  return (
    <StyledFlowWrapper step={step} id="StyledFlowWrapper" isMobile={isMobile}>
      {img}
      <ModalEditWrapper header={hed} dek={dek}>
        {showSurvey && surveyQs}
        <StyledActionWrapper id="StyledActionWrapper" isMobile={isMobile}>
          {cta1 && ButtonCTA(cta1)}
          {cta2 && LinkCTA(cta2)}
        </StyledActionWrapper>
        {showTOS && (
          <TermsAgreementBlock
            isCentered
            cta={cta1 && cta1.title}
            mustShowTerms={false}
          />
        )}
      </ModalEditWrapper>
    </StyledFlowWrapper>
  );
};

function mapStateToProps(state) {
  return {
    surveyList: state.store.survey.list || null,
    accountID: state.account.id,
    account: state.account,
    appFeatures: state.appFeatures,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOverlay,
      setSurveyResponse,
      skipThisMonth,
      getSurveyByName,
    },
    dispatch,
  );
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  & * {
    margin-bottom: 0px !important;
  }

  h4,
  p,
  li,
  label {
    line-height: 26px;
    color: ${COLORS.black};
  }
`;
export const StyledFlowWrapper = styled(Column)`
  padding-block: 40px;
  width: ${(props) => (props.isMobile ? "100vw" : "592px")};
  padding-inline: ${(props) => (props.isMobile ? "20px" : "56px")};
  align-items: center;
  h4 {
    margin-top: ${(props) => (props.isMobile ? "20px" : "24px")};
    font-size: ${(props) => (props.isMobile ? "24px" : "28px")};
    line-height: ${(props) => (props.isMobile ? "28px" : "32px")};
  }
  p {
    margin-top: ${(props) => (props.isMobile ? "8px" : "16px")};
    font-size: ${(props) => (props.isMobile ? "16px" : "18px")};
    line-height: ${(props) => (props.isMobile ? "24px" : "26px")};
  }
`;
export const StyledActionWrapper = styled(Column)`
  align-items: center;
  & button {
    margin-top: ${(props) => (props.isMobile ? "30px" : "35px")};
    width: 100%;
  }
  & a {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const StyledSurvey = styled(Column)`
  & p {
    margin-top: 30px;
    border-top: 1px solid ${COLORS.gray2};
    @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
      border-top: 1px solid transparent;
      margin-top: 0px;
    }
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: ${(props) => (props.isMobile ? "30px" : "32px")};
  }
  & label {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  & .radio-question,
  textarea {
    font-size: ${(props) => (props.isMobile ? "16px" : "18px")};
    line-height: ${(props) => (props.isMobile ? "24px" : "26px")};
    font-weight: ${(props) => (props.isMobile ? "400" : "300")};
    text-transform: none !important;
  }
  & .radio {
    width: ${(props) => (props.isMobile ? "16px" : "18px")} !important;
    height: ${(props) => (props.isMobile ? "16px" : "18px")} !important;
    padding: ${(props) => (props.isMobile ? "3px" : "3.375px")} !important;
    margin-right: 0px !important;

    .radioAda {
      width: ${(props) => (props.isMobile ? "8px" : "9px")};
      height: ${(props) => (props.isMobile ? "8px" : "9px")};
    }
  }
  button {
    margin-top: ${(props) => (props.isMobile ? "30px" : "35px")};
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(SkipSurvey);
