export enum FormAction {
  DEFAULT_FORM = "DEFAULT_FORM",
  PRINT_AT_HOME = "PRINT_AT_HOME",
  GIFT_CARD_DETAILS = "GIFT_CARD_DETAILS",
  CARD_ADDRESS_FORM = "CARD_ADDRESS_FORM",
  DATE_FORM = "DATE_FORM",
  SET_FIELD_VALUE = "SET_FIELD_VALUE",
  CLEAR_FORM = "CLEAR_FORM",
  SET_TO_NULL = "SET_TO_NULL",
}
