import * as React from "react";

import { MEDIAQUERIES } from "../../../css/Consts";

import styled from "styled-components";

interface IModalEditWrapper {
  header?: string | JSX.Element;
  dek?: string | JSX.Element;
}

export const ModalEditWrapper: React.FC<IModalEditWrapper> = ({
  children,
  header,
  dek,
}) => {
  return (
    <StyledModalEditWrapper>
      <StyledEditModalHeader>
        <h4 id="hed" aria-labelledby="hed dek">
          {header}
        </h4>
        {dek && <p id="dek">{dek}</p>}
      </StyledEditModalHeader>
      {children}
    </StyledModalEditWrapper>
  );
};

export const StyledModalEditWrapper = styled.div`
  padding-bottom: 90px;
  form .buttons-wrapper {
    flex-direction: row;
  }
  .buttons-wrapper button:first-child {
    margin-right: 10px;
  }
  @media screen and (min-width: ${MEDIAQUERIES.mobile}) {
    padding-bottom: 0;
    .buttons-wrapper {
      justify-content: space-evenly;
      button {
        flex: 1 1 auto;
      }
    }
  }
`;

export const StyledEditModalHeader = styled.div`
  margin-bottom: 20px;
  h4,
  p {
    text-align: center;
  }
  @media screen and (min-width: ${MEDIAQUERIES.mobile}) {
    h4 {
      font-size: 2.8rem;
    }
  }
`;
