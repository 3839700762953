/**
 * Modal for Holiday gifting.
 *
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { markMessageAsSeen } from "State/accountMessage/flows";
import { setOverlay } from "State/ui/creators";
import ClickTracker from "UI/elements/ClickTracker";

const HolidayModal = ({
  overlay,
  setOverlay,
  markMessageAsSeen,
  isReallyLoggedIn,
}) => {
  const { push } = useHistory();
  const type = overlay?.data?.type;
  const hed =
    type == "BFCM" ? (
      <>
        Cyber Week: <span className="nowrap">$10 Off Gifts</span>
      </>
    ) : (
      <>
        Get great gifts <br />
        just in time!
      </>
    );
  const dek =
    type == "BFCM"
      ? "Hire Boxie to handle all your holiday gifts. Just use code HIREBOXIE."
      : "Boxie delivers on time but you have to act fast to give the perfect gifts.";
  const image = type == "BFCM" ? "BF_CM_Popup_WOffer" : "BF_CM_Popup_NoOffer";

  useEffect(() => {
    return () => {
      setOverlay(null);
      if (isReallyLoggedIn && type == "BFCM") {
        markMessageAsSeen(27);
      } else if (isReallyLoggedIn || (!isReallyLoggedIn && type == "core")) {
        markMessageAsSeen(26);
      }
    };
  }, []);

  if (!overlay || (overlay && overlay.name !== "HolidayModal")) {
    return null;
  }

  const closeNGo = () => {
    setOverlay(null);
    push("/gift");
  };

  return (
    <StyledHolidayModal id={"StyledHolidayModal"}>
      <StyledImageWrapper className={type === "core" ? "core" : "bfcm"}>
        <picture>
          <source
            srcSet={`//static.bookofthemonth.com/holiday/${image}.webp`}
            type="image/webp"
          />
          <source srcSet={`//static.bookofthemonth.com/holiday/${image}.png`} />
          <img
            src={`//static.bookofthemonth.com/holiday/${image}.png`}
            alt="Give a gift"
          />
        </picture>
      </StyledImageWrapper>
      <StyledContentWrapper>
        <h1>{hed}</h1>
        <p className={"dek"}>{dek}</p>
        <ClickTracker
          ctaType={"button"}
          id="got-it"
          style={"primary fullWidth"}
          handleClick={() => closeNGo()}
          isPending={false}
          title={"Give a gift"}
          logClickData={`Clicked give gift from ${type} modal`}
        />
      </StyledContentWrapper>
    </StyledHolidayModal>
  );
};

export const StyledHolidayModal = styled.div`
  width: 100%;
  max-width: 580px;
`;
export const StyledImageWrapper = styled.div`
  text-align: center;
  img {
    width: 100%;
  }
  &.core {
    img {
      width: 80%;
      margin-top: 30px;
    }
  }
`;
export const StyledContentWrapper = styled.div`
  padding: 0 20px 20px;
  margin: 30px auto 30px;
  text-align: center;
  max-width: 520px;
  h2 {
    padding: 0 20px;
  }
  .dek {
    margin: 20px auto 30px;
    max-width: 350px;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
    isReallyLoggedIn:
      state.account &&
      state.policy.type !== "Visitor" &&
      state.policy.type !== "Lead",
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, markMessageAsSeen }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayModal);
