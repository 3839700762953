import React, { useState } from "react";
import styled from "styled-components";
import FieldClearButton from "./FieldClearButton.jsx";
import { connect } from "react-redux";
import { useWindowSize } from "UI/hooks";

const DynamicInput = ({ ...props }) => {
  const [showSecure, setShowSecure] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const shouldAutoFocus = props.overlay?.name && !isMobile;

  const toggleSecure = () => {
    setShowSecure(!showSecure);
  };

  let {
    label,
    value,
    onChangeText,
    placeholder,
    secureTextEntry,
    autoComplete,
    required,
    clearIt,
    error,
    inputType = "text",
    name,
    id,
    inputId,
    size = "full",
    isInFocus,
    onBlur,
    onFocus,
    custom,
    accessibilityLabel,
    noSpaces,
    readOnly,
    minNumber,
    maxNumber,
    disabled = false,
    top = 0,
    bottom = 20,
  } = props;

  let toggleText = showSecure ? "hide" : "show";
  const hasLabel = label ? true : false;
  let inputSize;

  switch (size) {
    case "full":
      inputSize = "100%";
      break;
    case "half":
      inputSize = "49%";
      break;
    case "third":
      inputSize = "32%";
      break;
    case "halfPlus":
      inputSize = "60%";
      break;
    case "halfMinus":
      inputSize = "38%";
      break;
  }

  return (
    <StyledOuterWrapper
      size={inputSize}
      onFocus={onFocus}
      style={custom}
      top={top}
      bottom={bottom}
    >
      <StyledInputWrapper error={error}>
        <input
          className="input"
          type={secureTextEntry && showSecure ? "text" : inputType}
          inputMode={inputType == "number" ? "numeric" : ""}
          placeholder={isInFocus ? placeholder : ""}
          value={value}
          onChange={(ev) => {
            noSpaces
              ? onChangeText(ev.target.value.replace(/\s+/g, ""))
              : inputType == "number"
              ? onChangeText(ev.target.value.replace(/[^0-9]/g, ""))
              : onChangeText(ev.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          name={name}
          id={inputId}
          autoCorrect="on" //Safari only
          autoComplete={autoComplete}
          aria-label={accessibilityLabel}
          readOnly={readOnly}
          min={minNumber}
          max={maxNumber}
          autoFocus={shouldAutoFocus ? true : false}
          disabled={disabled}
        />

        {hasLabel ? (
          <StyledLabel
            htmlFor={inputId}
            error={error}
            isActive={value?.length || typeof value === "number" || isInFocus}
          >
            {label}
          </StyledLabel>
        ) : null}

        {!isInFocus && <StyledFadeBlock />}

        {secureTextEntry && (value?.length || isInFocus) ? (
          <StyledSecureField onClick={() => toggleSecure()}>
            {toggleText}
          </StyledSecureField>
        ) : null}

        {clearIt && value?.length && !secureTextEntry ? ( //&& isInFocus
          <StyledClearIt>
            <FieldClearButton action={() => clearIt()} />
          </StyledClearIt>
        ) : null}

        {error ? (
          <StyledErrorWrapper>
            <p className="formLabel">{error}</p>
          </StyledErrorWrapper>
        ) : null}
      </StyledInputWrapper>
    </StyledOuterWrapper>
  );
};

export const StyledOuterWrapper = styled.div.attrs({
  className: "sc-StyledOuterWrapper",
})`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  width: ${(props) => (props.size ? `${props.size}` : `100%`)};
`;

export const StyledInputWrapper = styled.div.attrs({
  className: "sc-StyledInputWrapper",
})`
  position: relative;
  input {
    border-color: ${(props) =>
      props.error ? "var(--color-error6)" : "var(--color-gray2)"}!important;
    height: 54px;
  }
  input::placeholder {
    color: var(--color-gray4);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:hover {
    .sc-StyledClearIt {
      opacity: 1;
    }
  }
`;

export const StyledLabel = styled.label.attrs({ className: "sc-StyledLabel" })`
  font-family: "FoldGrotesquePro-Regular", "Helvetica", "Arial", sans-serif;
  line-height: ${(props) =>
    props.isActive || props.error ? "1.6rem" : "2.2rem"};
  letter-spacing: 0;
  font-size: ${(props) =>
    props.isActive || props.error ? "1.2rem" : "1.6rem"};
  color: ${(props) =>
    props.error
      ? "var(--color-error6)"
      : props.isActive
      ? "var(--color-black)"
      : "var(--color-gray4)"};
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isActive || props.error ? "var(--color-white)" : "transparent"};
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 4px;
  padding-right: 4px;
  top: ${(props) => (props.isActive || props.error ? -8 + "px" : 16 + "px")};
  transition: all 250ms ease-out;
  border-radius: 4px;
`;

export const StyledClearIt = styled.div.attrs({
  className: "sc-StyledClearIt",
})`
  position: absolute;
  top: 6px;
  right: 8px;
  opacity: 0;
  transition: opacity 500ms;
`;

export const StyledSecureField = styled.p.attrs({
  className: "sc-StyledSecureField",
})`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 8px;
  font-size: 1.2rem;
`;

export const StyledErrorWrapper = styled.div.attrs({
  className: "sc-StyledErrorWrapper",
})`
  padding-left: 8px;
  text-align: left;
  margin-top: 4px;
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--color-error6);
  }
`;

export const StyledFadeBlock = styled.p.attrs({
  className: "sc-StyledFadeBlock",
})`
  height: 46px;
  /* width: 20%; */
  top: 1px;
  bottom: 1px;
  right: 1px;
  position: absolute;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff, #fff);
  pointer-events: none;
`;
function mapStateToProps(state) {
  return {
    overlay: state.ui.overlay,
  };
}
export default connect(mapStateToProps)(DynamicInput);
