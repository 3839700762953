export const queryparser = (query) =>
  query
    .replace(/^\?/, "")
    .split("&")
    .map((kv) => kv.split("="))
    .reduce((o, kv) => {
      let isBoolean = false;
      if (kv[1] && typeof kv[1] === "string") {
        isBoolean = /^(true|false)$/gim.test(kv[1]);
      }
      o[kv[0]] = kv[1] ? (isBoolean ? JSON.parse(kv[1]) : kv[1]) : true;
      return o;
    }, {});

// for converting Objects with Maps and Sets to JSON to pass from core to the client and vice versa
// usage:
// const json = JSON.stringify(obj, JsonReplacer)
// const obj = JSON.parse(json, JsonReviver)
export const jsonReviver = (key, value) => {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    } else if (value.dataType === "Set") {
      return new Set(value.value);
    }
  }
  return value;
};
