/**
 * Modal for gating botm's.
 * Child of FeaturedProductList
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push, goBack } from "connected-react-router";
import { setOverlay } from "State/ui/creators";
import styled from "styled-components";
import JoinEnterEmailOnly from "UI/pages/join/JoinEnterEmailOnly.jsx";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import ClickTracker from "UI/elements/ClickTracker";
import { logVisit } from "State/analytics/flows";
import { useHistory } from "react-router-dom";
import { useRegion } from "UI/hooks/useRegion";
import { snesRedirect } from "Utils/snesRedirect";

const GateModal = ({
  account,
  landedOnPdp,
  store,
  setOverlay,
  push,
  overlay,
  ...props
}) => {
  const history = useHistory();
  const { countryCode } = useRegion();

  useEffect(() => {
    logVisit({
      store: {
        getState: () => ({ ...store, trackClick: `Seen app download Popup` }),
      },
      countryCode,
    });
  }, []);

  const hideGate = () => {
    setOverlay(null);
  };

  const goBack = () => {
    let backPath = "";
    if (landedOnPdp) {
      if (overlay?.data?.gateData?.isFeatured) {
        backPath = "/the-best-new-books";
      } else {
        backPath = "/all-hardcovers";
      }
      setOverlay(null);
      snesRedirect("snes", backPath);
    } else {
      setOverlay(null);
      history.goBack();
    }
  };

  if (account) {
    return null;
  }
  const data = overlay?.data?.gateData;
  const headline = data?.headline ? data.headline : "Create a free account!",
    dek = data?.dek
      ? data.dek
      : "Sign up to see book details, our quick takes, and more.",
    buttonText = data?.buttonText ? data.buttonText : "Sign up",
    fullWidthButtons = data?.fullWidthButtons ? data.fullWidthButtons : true;

  return (
    <ModalEditWrapper header={headline} dek={dek}>
      <JoinEnterEmailOnly
        showTermsBlock={true}
        buttonText={buttonText}
        hideBack={true}
        nextAction={hideGate}
        fullWidthButtons={fullWidthButtons}
        isLeadsGate={true}
        canadaMode={true}
      />
      <StyledLinkWrapper>
        <ClickTracker
          ctaType={"link"}
          tabIndex={0}
          id="leads-gate-go-back-link"
          handleClick={() => goBack()}
          title={" No thanks, I’ll go back."}
          logClickData={"click_leadsGate_goback"}
        />
      </StyledLinkWrapper>
    </ModalEditWrapper>
  );
};

export const StyledLinkWrapper = styled.p.attrs({
  className: "sc-StyledLinkWrapper",
})`
  text-align: center;
  margin-top: 20px;
`;

function mapStateToProps(state) {
  const { analytics } = state;
  return {
    store: state,
    account: state.account,
    overlay: state.ui ? state.ui.overlay : null,
    landedOnPdp: Boolean(
      analytics.entryPoint && analytics.entryPoint.match(/-\d{3}$/),
    ),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, goBack, setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GateModal);
