import * as surveyCreators from "State/survey/creators";

import * as surveyApi from "./api";

export const getSurveyByName = (surveyName, population) => async (dispatch) => {
  dispatch(surveyCreators.requestSurveys());
  const surveys = await surveyApi.getSurveyByName(surveyName, population);
  dispatch(surveyCreators.setSurveys([surveys]));
  dispatch(surveyCreators.setSurveysLoaded());
};

export const getSurveyPreview =
  (surveyName, previewDate) => async (dispatch) => {
    dispatch(surveyCreators.requestSurveys());
    const surveys = await surveyApi.getSurveyByName(
      surveyName,
      undefined,
      previewDate,
    );
    dispatch(surveyCreators.setSurveys([surveys]));
    dispatch(surveyCreators.setSurveysLoaded());
  };

export const getSurveyByType =
  (surveyType, trackId = 1) =>
  async (dispatch) => {
    dispatch(surveyCreators.requestSurveys());
    const surveys = await surveyApi.getByType(surveyType, trackId);
    dispatch(surveyCreators.setSurveys([surveys]));
    dispatch(surveyCreators.setSurveysLoaded());
  };

export const getAllSurveys = () => async (dispatch) => {
  dispatch(surveyCreators.requestSurveys());
  const surveys = await surveyApi.list();
  dispatch(surveyCreators.setAllSurveys(surveys));
  dispatch(surveyCreators.setSurveysLoaded());
};

export const getAllSurveysWithQuestions = () => async (dispatch) => {
  dispatch(surveyCreators.requestSurveys());
  const surveys = await surveyApi.all();
  dispatch(surveyCreators.setSurveys(surveys));
  dispatch(surveyCreators.setSurveysLoaded());
};

export const setSurveyResponse = (surveyResponse) => async () => {
  try {
    return await surveyApi.setSurveyResponse(surveyResponse);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const updateSurveyResponse = (surveyResponse) => async () => {
  try {
    return await surveyApi.updateSurveyResponse(surveyResponse);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getAccountSurveyResponse = (surveyId) => async () => {
  try {
    return await surveyApi.getAccountSurveyResponse(surveyId);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getInfluencerSurveyIndex = () => async () => {
  try {
    return await surveyApi.getInfluencerSurveyIndex();
  } catch (e) {
    console.error(e);
    return null;
  }
};
