/**
 * Displays global site message.
 * Child of BOM.jsx.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearSiteMessage } from "State/ui/creators";

class SiteMessage extends Component {
  hideMessage() {
    this.props.clearSiteMessage();
  }

  render() {
    let { siteMessage } = this.props;
    let aPath = this.props.path.split("/");
    let hasFixedNav = aPath.indexOf("add-ons") !== -1;

    if (!siteMessage) {
      return null;
    }
    return (
      <div
        className={
          "siteMessageBar " +
          siteMessage.type +
          (siteMessage.showMessage ? " showing" : " hidden") +
          (hasFixedNav ? " belowNav" : "")
        }
      >
        <div className="content -siteWidth">
          <div
            className="messageBarClose"
            onClick={() => {
              this.hideMessage();
            }}
          >
            <svg
              width="14.782px"
              height="14.533px"
              viewBox="0 0 14.782 14.533"
              enableBackground="new 0 0 14.782 14.533"
            >
              <polygon
                fill="var(--color-white)"
                points="14.572,13.966 7.881,7.275 14.571,0.584 14.119,0.131 7.428,6.823 0.737,0.131 0.284,0.584
									6.976,7.275 0.284,13.966 0.737,14.419 7.428,7.728 14.119,14.419   "
              />
            </svg>
          </div>
          <span className="smallText">{siteMessage.content}</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteMessage: state.ui ? state.ui.siteMessage : null,
    path: state.analytics.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearSiteMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMessage);
