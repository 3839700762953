export async function get(url) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    { credentials: "same-origin" },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}

export async function post(url, data) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    {
      method: "post",
      body: JSON.stringify(data), // JSON.stringify need for body to send
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}

export async function put(url, data) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    {
      method: "put",
      body: JSON.stringify(data), // JSON.stringify need for body to send
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
    },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}

export async function getWithHeaders(url, headers) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    {
      method: "get",
      headers: { ...headers },
      credentials: "same-origin",
    },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}

export async function postWithHeaders(url, headers, data) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    {
      method: "post",
      body: JSON.stringify(data), // JSON.stringify need for body to send
      headers: { "Content-Type": "application/json", ...headers },
      credentials: "same-origin",
    },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}

export async function putWithHeaders(url, headers, data) {
  let hostnameRegEx = new RegExp(".*?.com/.*");
  const response = await fetch(
    (globalThis && globalThis.serving && !hostnameRegEx.test(url)
      ? "localhost:8080"
      : "") + url,
    {
      method: "put",
      body: JSON.stringify(data), // JSON.stringify need for body to send
      headers: { "Content-Type": "application/json", ...headers },
      credentials: "same-origin",
    },
  );
  if (!response.ok) throw new Error(await response.text());
  return /application\/json/.test(response.headers.get("Content-Type"))
    ? await response.json()
    : await response.text();
}
