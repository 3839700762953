import * as actions from "./actions";
import { IAccount } from "../../../../types/account";
import { AnyAction } from "redux";

export default function reducer(
  state: Partial<IAccount> | null = null,
  action: AnyAction,
) {
  switch (action.type) {
    case actions.SET_ACCOUNT:
      return action.payload ? { ...state, ...action.payload } : action.payload;
    case actions.SET_ACCOUNT_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case actions.SET_ACCOUNT_RENEWAL_PLAN:
      return { ...state, renewalPlan: action.payload };
    case actions.SET_ACCOUNT_RENEWAL_DATE:
      return {
        ...state,
        renewalDate: action.payload,
      };
    case actions.SET_ACCOUNT_POLICY_EFFECTIVE_DATE:
      return {
        ...state,
        policyEffectiveDate: action.payload,
      };
    case actions.SET_ACCOUNT_REFERRALS:
      return {
        ...state,
        referrals: action.payload,
      };
    case actions.SET_ACCOUNT_ANCHOR_RESPONSE:
      return {
        ...state,
        anchorResponse: action.payload,
      };
    case actions.SET_VISITOR_ID:
      return {
        ...state,
        visitorId: action.payload,
      };
    case actions.SET_EXPERIMENT_VARIANT:
      return {
        ...state,
        variantId: action.payload,
      };
    case actions.SET_LOYALTY_STATS:
      return {
        ...state,
        loyaltyStats: action.payload,
      };
    case actions.UPDATE_EMAIL_PREF_SUCCESS:
    case actions.UPDATE_EMAIL_PREF_FAILURE:
      return state;
    case actions.UPDATE_EMAIL_PREF_ERROR:
      return {
        ...state,
        emailPrefError: action.payload,
      };
    default:
      return state;
  }
}
