import React, { useEffect, useReducer, useState } from "react";
import { useRegion } from "../hooks/useRegion";

import { FormAction } from "../pages/gift/purchase/util/formAction";
import { defaultForm, dateForm } from "../pages/gift/purchase/GiftFormData";
import { FieldSet } from "../elements/form";
import { formReducer } from "./GiftFormReducer";

import { AccountWithLoyaltyAndPayment } from "../../../../types/account";

export type GiftFormContextProps = {
  children?: JSX.Element;
  firstFormState: FieldSet;
  secondFormState: FieldSet;
  setFormValue: (fieldName: string, v: string) => void;
  clearForm: () => void;
  setIsFirstFormValid: (v: boolean) => void;
  setIsSecondFormValid: (v: boolean) => void;
  isFormValid: () => boolean;
  mapToReduxState: (
    setGiftPurchaseAddress: any,
    setGiftInfo: any,
    theme: any,
    planId: any,
    billingZipcode?: any,
  ) => void;
  setNameAndEmailForMembers: (account?: AccountWithLoyaltyAndPayment) => void;
};

const initialFirstFormState = { ...defaultForm };
const initialSecondFormState = { ...dateForm };

const defaultState: GiftFormContextProps = {
  firstFormState: initialFirstFormState,
  secondFormState: initialSecondFormState,
  setFormValue: () => {},
  clearForm: () => {},
  setIsFirstFormValid: () => {},
  setIsSecondFormValid: () => {},
  isFormValid: () => false,
  mapToReduxState: () => {},
  setNameAndEmailForMembers: () => {},
};

const GiftFormContext = React.createContext<GiftFormContextProps>(defaultState);

const GiftFormProvider: React.FC<[GiftFormContextProps]> = ({ children }) => {
  const [isFirstFormValid, setIsFirstFormValid] = useState<boolean>(false);
  const [isSecondFormValid, setIsSecondFormValid] = useState<boolean>(false);

  const { canadaMode } = useRegion();
  const [firstFormState, setFirstFormState] = useReducer(
    formReducer,
    initialFirstFormState,
  );
  const [secondFormState, setSecondFormState] = useReducer(
    formReducer,
    initialSecondFormState,
  );

  useEffect(() => {
    if (isObjEmpty(secondFormState)) {
      setIsSecondFormValid(true);
    }
  }, [secondFormState]);

  const mapToReduxState = (
    setGiftPurchaseAddress: any,
    setGiftInfo: any,
    theme: any,
    planId: any,
    billingZipcode?: any,
  ) => {
    //map context forms to redux state.
    const deliveryDate = secondFormState?.month?.value
      ? new Date(
          parseInt(secondFormState?.year?.value as string),
          parseInt(secondFormState?.month?.value as string) - 1,
          parseInt(secondFormState?.day?.value as string),
          12,
          0,
          0,
        )
      : null;
    const zipcode = billingZipcode || null;

    const address = firstFormState?.zip?.value
      ? {
          city: firstFormState?.city?.value,
          countryCode: "US",
          firstName: firstFormState?.firstName?.value,
          lastName: firstFormState?.lastName?.value,
          name: "",
          state: firstFormState?.state?.value,
          street1: firstFormState?.address?.value,
          street2: firstFormState?.apt?.value,
          zip: firstFormState?.zip?.value,
        }
      : null;

    if (address) {
      setGiftPurchaseAddress(address);
    }

    let giftObj = {
      address,
      planId,
      giverName:
        firstFormState?.senderName?.value || secondFormState?.senderName?.value,
      giverEmail:
        firstFormState?.senderEmail?.value ||
        secondFormState?.senderEmail?.value,
      recipientName:
        firstFormState?.recipientName?.value ||
        secondFormState?.recipientName?.value,
      recipientEmail:
        firstFormState?.recipientEmail?.value ||
        secondFormState?.recipientEmail?.value,
      message:
        firstFormState?.message?.value || secondFormState?.message?.value,
      zipcode: zipcode,
      deliveryDate,
      giftStyle: theme?.giftStyle === "holiday" ? theme?.giftStyle : "generic",
    };

    setGiftInfo(giftObj);
  };

  function isObjEmpty(obj: FieldSet) {
    return Object.keys(obj).length === 0;
  }

  const isFormValid = () => {
    return isFirstFormValid && isSecondFormValid;
  };

  const clearForm = () => {
    setFirstFormState({ type: FormAction.CLEAR_FORM, payload: { value: "" } });
    setSecondFormState({ type: FormAction.CLEAR_FORM, payload: { value: "" } });
  };

  const setFormValue = (fieldName: string, v: string) => {
    if (fieldName == "deliveryMethod") {
      switch (v) {
        case "email":
          setFirstFormState({
            type: FormAction.DEFAULT_FORM,
            payload: { fieldName, value: v },
          });
          setSecondFormState({
            type: FormAction.DATE_FORM,
            payload: { fieldName, value: v },
          });
          break;
        case "voucher":
          setFirstFormState({
            type: FormAction.PRINT_AT_HOME,
            payload: { fieldName, value: v, canadaMode },
          });
          setSecondFormState({
            type: FormAction.SET_TO_NULL,
            payload: { fieldName, value: v },
          });
          break;
        case `card`:
          setFirstFormState({
            type: FormAction.CARD_ADDRESS_FORM,
            payload: { fieldName, value: v, canadaMode },
          });
          setSecondFormState({
            type: FormAction.GIFT_CARD_DETAILS,
            payload: { fieldName, value: v },
          });
          break;
        default:
          setFirstFormState({
            type: FormAction.DEFAULT_FORM,
            payload: { fieldName, value: v },
          });
          setSecondFormState({
            type: FormAction.DATE_FORM,
            payload: { fieldName, value: v },
          });
          break;
      }
    } else {
      setFirstFormState({
        type: FormAction.SET_FIELD_VALUE,
        payload: { fieldName, value: v },
      });
      setSecondFormState({
        type: FormAction.SET_FIELD_VALUE,
        payload: { fieldName, value: v },
      });
    }
  };

  const setNameAndEmailForMembers = (
    account?: AccountWithLoyaltyAndPayment,
  ) => {
    if (account && (account.firstName || account.lastName)) {
      setFormValue("senderName", `${account.firstName} ${account.lastName}`);
    }

    if (account?.email) {
      setFormValue("senderEmail", account.email);
    }
  };

  return (
    <GiftFormContext.Provider
      value={{
        isFormValid,
        setIsFirstFormValid,
        setIsSecondFormValid,
        firstFormState,
        secondFormState,
        setFormValue,
        clearForm,
        mapToReduxState,
        setNameAndEmailForMembers,
      }}
    >
      {children}
    </GiftFormContext.Provider>
  );
};

export { GiftFormProvider, GiftFormContext };
