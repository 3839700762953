export const SET_OVERLAY = "SET_OVERLAY";
export const SET_SITE_RESPONSE = "SET_SITE_RESPONSE";
export const CLEAR_SITE_RESPONSE = "CLEAR_SITE_RESPONSE";
export const SET_SITE_MESSAGE = "SET_SITE_MESSAGE";
export const CLEAR_SITE_MESSAGE = "CLEAR_SITE_MESSAGE";
export const CLOSE_BOX_COUPON = "CLOSE_BOX_COUPON";
export const SHOW_BOX_COUPON = "SHOW_BOX_COUPON";
export const LET_IT_SNOW = "LET_IT_SNOW"; //for holiday
export const SET_SORT = "SET_SORT";
export const SET_JUST_COMPLETED_RC = "SET_JUST_COMPLETED_RC";
export const SET_RC_BADGES_STATUS = "SET_RC_BADGES_STATUS";
