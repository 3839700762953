import { combineReducers } from "redux";
import * as actions from "./actions";

const list = (state = [], action) => {
  switch (action.type) {
    case actions.SET_BLOG_POSTS:
      return action.posts;
    case actions.ADD_BLOG_POST: {
      const idx = state.findIndex((p) => p.id === action.post.id);
      if (idx < 0) {
        return [action.post, ...state];
      } else {
        return [...state.slice(0, idx), action.post, ...state.slice(idx + 1)];
      }
    }
    case actions.MERGE_BLOG_POSTS: {
      const merged = Object.values(
        state.concat(action.posts).reduce((o, p) => {
          o[p.id] = p;
          return o;
        }, {}),
      );
      merged.sort((a, b) => {
        const d = new Date(b.liveDate) - new Date(a.liveDate);
        if (d === 0) {
          return b.id - a.id;
        }
        return d;
      });
      return merged;
    }
  }
  return state;
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case actions.REQUEST_BLOG_POSTS:
      return false;
    case actions.ALL_POSTS_LOADED:
      return true;
  }
  return state;
};

export default combineReducers({
  list,
  loaded,
});
