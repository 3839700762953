import React, { useState, useEffect, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setOverlay } from "State/ui/creators";
import {
  _FRIEND_TIER_STEPS,
  _BFF_TIER_STEPS,
} from "./LoyaltyTierConfModalData";
import Spinner from "../../elements/Spinner";
import LoyaltyTierConfContentBlock from "./LoyaltyTierConfContentBlock";
import { RegionContext } from "UI/contexts/RegionContext";

const LoyaltyTierConfModalComponent = (props) => {
  const { overlay, account, tier } = props;
  const { canadaMode: canadaMode_ } = useContext(RegionContext);
  const canadaMode = account?.countryCode
    ? account?.countryCode === "CA"
    : canadaMode_;
  const [data, setData] = useState();
  const [step, setStep] = useState(props.step || "step1");

  const setTierData = () => {
    if (tier == "BFF") {
      setData(_BFF_TIER_STEPS);
    } else if (tier == "Friend") {
      let dataSteps = _FRIEND_TIER_STEPS;
      // if canadaMode, skip (and delete) step 2
      if (canadaMode) {
        dataSteps.step1.nextStep = "step3";
        delete dataSteps.step2;
      }

      // except for the first and last dataStep, we want it to show a pageCount that is dynamic since we may be removing a step for canadaMode
      const dataStepsLength = Object.keys(dataSteps).length;
      Object.keys(dataSteps).forEach((dataStep, index) => {
        if (
          index !== 0 &&
          index !== dataStepsLength - 1 &&
          dataSteps.hasOwnProperty(dataStep)
        ) {
          dataSteps[dataStep].pageCount = `${index} of ${dataStepsLength - 2}`;
        }
      });
      setData(dataSteps);
    }
  };

  useEffect(() => {
    setTierData();
  }, [account?.loyaltyStats?.loyaltyTier]);

  if (
    !overlay ||
    (overlay && overlay.name !== "LoyaltyTierConfModal") ||
    !data
  ) {
    return (
      <div>
        <Spinner size="large" />
      </div>
    );
  }

  const closeNGo = (path) => {
    props.push(path);
    props.setOverlay(null);
  };

  return (
    <LoyaltyTierConfContentBlock
      data={data[step]}
      setOverlay={props.setOverlay}
      setStep={setStep}
      closeNGo={closeNGo}
    />
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
    overlay: state.ui ? state.ui.overlay : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoyaltyTierConfModalComponent);
