import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import Account from "./Account";
import Cancel from "./Cancel";
import Gift from "./Gift";
import Join from "./Join";
import Redirects from "./Redirects";
import Site from "./Site";
import Internal from "./Internal";
import HelpCenterRoutes from "./HelpCenterRoutes";
import { RouterProvider } from "UI/contexts/RouterContext";
import AlertPage from "UI/skeleton/AlertPage.jsx";
import { snesRedirect } from "Utils/snesRedirect";

const ProductRedirect = () => {
  const { productId: productUrl } = useParams();
  return snesRedirect("snes", `/all-hardcovers/${productUrl}`);
};

const pdpRegEx = "/:book([A-Za-z0-9-]+-[0-9]+)";

const Routes = () => {
  return (
    <RouterProvider>
      <Switch>
        <Route
          exact
          path={[
            "/",
            "/getstarted",
            "/844747367367462",
            "/popular-picks",
            "/mailbox",
            "/bookmail",
            "/bookbox",
            "/AARP-registered-users",
            "/AARP-members-new",
            "/AARP-members-existing",
          ]}
          component={() => snesRedirect("snes", "/")}
        ></Route>
        {Internal}
        {Site}
        {Join}
        {Gift}
        {Cancel}
        {Account}
        {Redirects}
        {HelpCenterRoutes}
        <Route exact path={pdpRegEx} component={ProductRedirect} />
        <Route exact path={pdpRegEx} component={ProductRedirect} />
        <Route exact path={["/discussions", "/discussions/*"]}>
          <AlertPage
            prevExistedPage={true}
            imageUrl={
              "//static.bookofthemonth.com/bookclubs/Dog_Sniffing_Desktop.png"
            }
          />
        </Route>

        <Route exact path={["/clubs", "/clubs/*"]}>
          <AlertPage
            prevExistedPage={true}
            imageUrl={
              "//static.bookofthemonth.com/bookclubs/Dog_Sniffing_Desktop.png"
            }
          />
        </Route>

        <Route exact path={"/my-connections"}>
          <AlertPage
            imageUrl="//static.bookofthemonth.com/connections/ConnectionsSunsetLight.jpg"
            header="Connections rode into the sunset."
            dek="We hope you continue to connect with friends over your BOTM books, but we ended our Connections feature so that we can focus on making your membership amazing in other ways."
            showLink={false}
          />
        </Route>
        <Route path="*">
          <AlertPage header="404" dek="Oh no! This page does not exist!" />
        </Route>
      </Switch>
    </RouterProvider>
  );
};

export default Routes;
