import React from "react";
import { StoreDataContext } from "../contexts/StoreData";

type WithStoreDataProps = {
  storeData: object;
};

const withStoreData =
  <P extends object>(
    Component: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof WithStoreDataProps>> =>
  (props) => (
    <StoreDataContext.Consumer>
      {(storeData) => <Component {...(props as P)} storeData={storeData} />}
    </StoreDataContext.Consumer>
  );

export { withStoreData };
