import React from "react";
import styled from "styled-components";
import { LockIcon } from "../../../ui/elements/icons/SecurePaymentIcon";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { COLORS } from "../../../css/Consts";
interface CurrentPaymentMethodBlockProps {
  top?: number;
  bottom?: number;
  ctaAction?: () => void;
  cta?: string;
}

const SecurePaymentBlock: React.FC<CurrentPaymentMethodBlockProps> = ({
  top = 0,
  bottom = 0,
  cta,
  ctaAction,
}) => {
  return (
    <StyledSecurePaymentBlock top={top} bottom={bottom}>
      <StyledSecurePaymentBlockHed>
        <LockIcon color={COLORS.gray5} />
        <p className="label2 securePaymentLabel">Secure Payment</p>
      </StyledSecurePaymentBlockHed>
      {cta && (
        <ClickTracker
          ctaType={"link"}
          id="secure-payment-cta"
          // style={'primary -fullWidth'}
          handleClick={ctaAction}
          title={cta}
          logClickData={`Clicked secure payment hed : ${cta}`}
        />
      )}
    </StyledSecurePaymentBlock>
  );
};

interface IStyledSecurePaymentBlock {
  top?: number;
  bottom?: number;
}

export const StyledSecurePaymentBlock = styled.div.attrs({
  className: "sc-StyledSecurePaymentBlock" as string,
})<IStyledSecurePaymentBlock>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
`;

export const StyledSecurePaymentBlockHed = styled.div.attrs({
  className: "sc-StyledSecurePaymentBlockHed" as string,
})<IStyledSecurePaymentBlock>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;

  .securePaymentLabel {
    color: var(--color-gray5);
  }

  h6 {
    color: ${COLORS.gray5};
  }
`;

export default SecurePaymentBlock;
