/**
 * Catches and shows error.
 * Child of BOM.jsx.
 */

import React, { Component } from "react";
import AlertPage from "./AlertPage.jsx";
import { datadogLogs } from "@datadog/browser-logs";
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }
  componentDidCatch(error, info) {
    console.error("TCL:: ErrorBoundry -> error", this.state.errorMessage);
    //logErrorBackendWhenBuilt(error.toString(), info.componentStack)
    datadogLogs.logger.log(error.toString(), info.componentStack, "error");
  }

  render() {
    if (this.state.errorMessage) {
      return <AlertPage />;
    }
    return this.props.children;
  }
}
