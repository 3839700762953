import { createSelector } from "reselect";

const getBox = (state) => state.account && state.box;

export const boxCount = createSelector([getBox], (box) =>
  !(box && box.items) ? 0 : box.items.length,
);

export const boxItems = createSelector([getBox], (box) =>
  !(box && box.items) ? [] : box.items,
);
