import { createHash } from "crypto";

export function setClickId(clickId) {
  if (typeof document !== undefined && typeof window) {
    window.clickId = clickId;
  }
}

export function setCustomerInfo(customerId = "", customerEmail = "") {
  if (typeof document !== undefined) {
    if (customerEmail) {
      customerEmail = createHash("sha1").update(customerEmail).digest("hex");
    }
    if (
      !(window.shaEmail && window.shaEmail === customerEmail) &&
      document.getElementById("affScript")
    ) {
      // make sure its called once
      window.shaEmail = customerEmail;
      let affScript = document.createElement("script");
      affScript.id = "affScript";
      affScript.setAttribute("type", "text/javascript");
      affScript.innerText = `ire('identify', {customerId: '${customerId}', customerEmail: '${customerEmail}'});`;
      document.body.appendChild(affScript);
    }
  }
}

const enrollSkus = { 1: 11111, 12: 33333, 3: 22222, 2: 77777 },
  giftSkus = { 6: 55555, 12: 66666, 3: 44444 };

export function impactAffiliatePixelEnroll(
  account,
  plan,
  storeState,
  enrollmentType,
  promoCode,
) {
  if (typeof document !== undefined && enrollmentType === "enroll") {
    let sku = enrollSkus[plan.cycles];
    let orderId = storeState.accountHistory?.find(
      (b) => (b.type = "Subscription"),
    )?.id;
    let orderTotal = storeState.accountHistory
      ?.map((b) => b.lines)
      ?.flat()
      ?.reduce(
        (total, line) =>
          (total += line.resourceType == "Tax" ? 0 : line.amount),
        0,
      );
    let trackId = account.trackId;
    let affScript = document.createElement("script");
    affScript.setAttribute("type", "text/javascript");
    affScript.innerText = `
      ire('trackConversion', ${trackId === 2 ? "17494" : "18903"}, {
        clickId: "${window.clickId}",
        orderId: "${orderId}",
        customerId: "${account.id}",
        customerEmail: "${createHash("sha1")
          .update(account.email)
          .digest("hex")}",
        currencyCode: "USD",
        orderPromoCode: "${promoCode}",
        items: [
          {
            subTotal: ${orderTotal},
            category: "Subscription",
            sku: "${sku}",
            quantity: 1,
            name: "${plan.cycles} month"
          }
        ]
      },
      {verifySiteDefinitionMatch:true});
    `
      .replace(/\n|\t|\s\s/g, "")
      .trim();
    document.body.appendChild(affScript);
  }
}

export function impactAffiliatePixelGiftPurchase(
  purchasedGiftAH,
  giftPurchase,
  trackId,
) {
  if (typeof document !== undefined && window.clickId) {
    let orderTotal = purchasedGiftAH.lines
        .filter((ah) => ah.resourceType !== "Tax")
        .reduce((total, line) => (total += line.amount), 0),
      {
        plan: { cycles },
        giver: { email: giverEmail },
      } = giftPurchase;
    let sku = giftSkus[cycles];

    let affScript = document.createElement("script");
    affScript.setAttribute("type", "text/javascript");
    affScript.innerText = `
      ire('trackConversion', ${trackId === 2 ? "17495" : "18904"}, {
        clickId: "${window.clickId}",
        orderId: "${purchasedGiftAH.id}",
        customerId: "${purchasedGiftAH.accountId}",
        customerEmail: "${createHash("sha1").update(giverEmail).digest("hex")}",
        currencyCode: "USD",
        orderPromoCode: "${
          giftPurchase.promo && giftPurchase.promo.code
            ? giftPurchase.promo.code
            : ""
        }",
        items: [
          {
            subTotal: ${orderTotal},
            category: "Gfit Card",
            sku: "${sku}",
            quantity: 1,
            name: "${cycles} month"
          }
        ]
      },
      {verifySiteDefinitionMatch:true});
    `
      .replace(/\n|\t|\s\s/g, "")
      .trim();
    document.body.appendChild(affScript);
  }
}

export function sasAffiliatePixelEnroll(account, plan, promoCode) {
  if (typeof document !== "undefined") {
    let price = plan ? plan.price : "",
      sku = enrollSkus[plan.cycles],
      pixel = document.createElement("img"),
      sasScript = document.createElement("script"),
      pixel1 = document.createElement("img"),
      pixel2 = document.createElement("img"),
      pixel3 = document.createElement("img"),
      div = document.createElement("div");
    pixel.width = pixel.height = 1;
    pixel.src = `https://shareasale.com/sale.cfm?amount=${price}&tracking=${
      account.id
    }&couponcode=${
      promoCode ? promoCode : ""
    }&transtype=sale&merchantID=61119&storeID=1&newcustomer=1&skulist=${sku}&quantitylist=1&pricelist=${price}&sscidmode=6&sscid=${
      window &&
      window.shareasaleGetCookie &&
      window.shareasaleGetCookie("shareasaleSSCID")
    }`;
    sasScript.setAttribute("defer", "");
    sasScript.setAttribute("async", "");
    sasScript.setAttribute("type", "text/javascript");
    sasScript.src = "https://shareasale-analytics.com/j.js";
    div.appendChild(pixel);
    div.appendChild(sasScript);
    (pixel1.src = "https://p.liadm.com/p?c=18173"), div.appendChild(pixel1);
    pixel2.src = "https://p.liadm.com/p?c=18172";
    div.appendChild(pixel2);
    pixel3.width = pixel3.height = 1;
    pixel3.src = "https://secure.adnxs.com/px?id=662871&seg=4550354&t=2";
    div.appendChild(pixel3);

    div.className = "trackingPixelsWrapper";
    window.document.body.appendChild(div);
  }
}

export function sasAffiliatePixelGiftPurchase(account_id, plan, promo) {
  if (typeof document !== undefined) {
    let planPrice = plan ? plan.price : "",
      sku = plan ? giftSkus[plan.cycles] : "",
      pixel = document.createElement("img"),
      sasScript = document.createElement("script"),
      div = document.createElement("div");

    pixel.width = pixel.height = 1;
    pixel.src = `https://shareasale.com/sale.cfm?amount=${planPrice}&tracking=${account_id}&couponcode=${
      promo ? promo.code : ""
    }&transtype=sale&merchantID=61119&storeID=1&newcustomer=1&skulist=${sku}&quantitylist=1&pricelist=${planPrice}&sscidmode=6&sscid=${
      window &&
      window.shareasaleGetCookie &&
      window.shareasaleGetCookie("shareasaleSSCID")
    }`;
    sasScript.setAttribute("defer", "");
    sasScript.setAttribute("async", "");
    sasScript.setAttribute("type", "text/javascript");
    sasScript.src = "https://shareasale-analytics.com/j.js";
    div.appendChild(pixel);
    div.appendChild(sasScript);
    div.className = "trackingPixelsWrapper";
    window.document.body.appendChild(div);
  }
}

export function linkedInCorporatePixel() {
  if (typeof document !== undefined) {
    let pixel = document.createElement("img"),
      div = document.createElement("div");

    pixel.width = pixel.height = 1;
    pixel.src =
      "https://px.ads.linkedin.com/collect/?pid=1473746&conversionId=1472090&fmt=gif";
    div.appendChild(pixel);
    div.className = "trackingPixelsWrapper";
    window.document.body.appendChild(div);
  }
}
