import { combineReducers } from "redux";
import * as actions from "./actions";
import blog from "State/blog/reducers";
import categories from "State/categories/reducers";
import cycle from "State/cycle/reducers";
import genres from "State/genres/reducers";
import survey from "State/survey/reducers";
import tranche from "State/tranche/reducers";
import track from "./track/reducers";

const data = function reducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_STORE_DATA:
      return state === null ? action.payload : { ...state, ...action.payload };
    case actions.SET_STORE_DATA_EXPIRED:
      return { ...state, ...action.payload, expired: true };
    case actions.SET_STORE_ENROLL_PLANS:
      return { ...state, enrollmentPlans: action.payload };
    case actions.SET_STORE_REJOIN_PLANS:
      return { ...state, rejoinPlans: action.payload };
    case actions.SET_STORE_GIFT_PLANS:
      return { ...state, giftPlans: action.payload };
    case actions.SET_DEFAULT_POLICY:
      return { ...state, defaultPolicy: action.payload };
    case actions.SET_STORE_SPECIAL_PLANS:
      return { ...state, specialPlans: action.payload };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  blog,
  categories,
  cycle,
  genres,
  survey,
  tranche,
  track,
});
