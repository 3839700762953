import React from "react";
import SkeletonWrapper, {
  H1,
  H6,
  P,
  InvisibleBlock,
  Button,
} from "UI/loadingSkeletons/SkeletonWrapper.jsx";
import HeaderBlockSkeleton from "UI/loadingSkeletons/HeaderBlockSkeleton.jsx";

export const GiftPlansSkeleton = () => (
  <div className="SelectPlanItems">
    <ul className="planMonthsWrapper">
      {new Array(3).fill(0).map((ignore, i) => (
        <li key={i}>
          {/* <InvisibleBlock width="20px" height="20px" /> */}
          <div style={{ padding: 20 }}>
            <p className="label2 -p -center" />
          </div>
        </li>
      ))}
    </ul>
  </div>
);

const GiftFlowSkeleton = () => (
  <SkeletonWrapper>
    <div className="content -narrow">
      <HeaderBlockSkeleton className="-headerWrapper" tagType="section">
        <div className="innerContent">
          <H1 className="hero -center" />
          <P className="-center" />
          <P className="-center" />
        </div>
      </HeaderBlockSkeleton>
      <section className="selectPlan center">
        <GiftPlansSkeleton />
      </section>

      <div
        style={{
          padding: "0 20px 20px 20px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Button className="primary -fullWidth" />
      </div>

      <InvisibleBlock width="auto" height={300} />
    </div>
  </SkeletonWrapper>
);

export default GiftFlowSkeleton;
