import * as actions from "./actions";

export function resetGiftStep() {
  return { type: actions.SET_GIFT_STEP_DEFAULT };
}

export function setGiftPurchasePlan(plan) {
  return {
    type: actions.SET_GIFT_PLAN,
    payload: plan,
  };
}

export function setGiftDeliveryMethod(method) {
  return {
    type: actions.SET_DELIVERY_METHOD,
    payload: method,
  };
}

export function setGiftInfo(giftInfo) {
  return {
    type: actions.SET_GIFT_INFO,
    payload: giftInfo,
  };
}

export function clearGiftInfo(giftInfo) {
  return {
    type: actions.CLEAR_GIFT_INFO,
    payload: giftInfo,
  };
}

export function setGiftPurchaseTaxRate(tax_rate) {
  return {
    type: actions.SET_GIFT_TAX_RATE,
    payload: tax_rate,
  };
}

export function setGiftPurchasePersonalization(personalizations) {
  return {
    type: actions.SET_GIFT_PERSONALIZATIONS,
    payload: personalizations,
  };
}

export function setGiftPurchasePromoError(error) {
  return { type: actions.SET_GIFT_PURCHASE_PROMO_ERROR, payload: error };
}

export function clearGiftPurchasePromoError() {
  return { type: actions.CLEAR_GIFT_PURCHASE_PROMO_ERROR };
}

export function setGiftPurchasePromo(promo) {
  return { type: actions.SET_GIFT_PURCHASE_PROMO, payload: promo };
}

export function setGiftPurchaseAddress(address) {
  return {
    type: actions.SET_GIFT_PURCHASE_ADDRESS,
    payload: address,
  };
}
export function setGiftPurchaseCorporateCode(code) {
  return {
    type: actions.SET_GIFT_PURCHASE_CORPORATE_CODE,
    payload: code,
  };
}

// gift redeem
export function pureEnrollRedeemStep(step) {
  return {
    type: actions.SET_GIFT_REDEEM_STEP,
    payload: step,
  };
}

export function resetGiftRedeemStep() {
  return { type: actions.SET_GIFT_REDEEM_STEP_DEFAULT };
}

export function pureJoinStep(step) {
  return {
    type: actions.SET_GIFT_STEP,
    payload: step,
  };
}

//CORPORATE GIFT INQUIRY FORM SUBMISSION
export function setCorpGiftInquiryStatusSuccess() {
  return { type: actions.CORP_INQUIRY_STATUS_SUCCESS };
}

export function setCorpGiftInquiryStatusFailure() {
  return { type: actions.CORP_INQUIRY_STATUS_FAILURE };
}

export function setCorpGiftInquiryError(error) {
  return { type: actions.SET_CORP_INQUIRY_ERROR, payload: error };
}

export function setCurrencyCode(currencyCode) {
  return { type: actions.SET_CURRENCY_CODE, payload: currencyCode };
}
