import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Skeleton from "UI/loadingSkeletons/Index.jsx";

export const HeaderSkeleton = () => (
  <Skeleton.SkeletonWrapper>
    <header id="header" className="header defaultBg">
      <div className="content -siteWidth -innerWrapper">
        <div className="logoWrapper">
          <div className="logo"></div>
        </div>
      </div>
    </header>
  </Skeleton.SkeletonWrapper>
);
export const FooterSkeleton = () => (
  <Skeleton.SkeletonWrapper>
    <footer id="footer" className="footer" />
  </Skeleton.SkeletonWrapper>
);

const pdpRegEx = "/:book([A-Za-z0-9-]+-[0-9]+)";

const BOMSkeleton = (props) => {
  const location = useLocation();
  return (
    <div>
      <div className="outerWrapper">
        <HeaderSkeleton />
        <div className="bodyContent"></div>
        <FooterSkeleton />
      </div>
    </div>
  );
};

export default BOMSkeleton;
