import React from "react";
import SkeletonWrapper, { Jacket, IMG, Word } from "./SkeletonWrapper.jsx";

const BoxSkeleton = () => (
  <SkeletonWrapper className="myBox">
    <div className="cardBlock">
      <div className="boxHeader">
        <Word />
      </div>
      <table className="dataTable boxItems">
        <tbody>
          <tr>
            <td colSpan="3">
              <p className="label2">
                <Word />
              </p>
            </td>
          </tr>
          <tr className="12">
            <td className="imageHolder">
              <Jacket>
                <IMG />
              </Jacket>
            </td>
            <td className="detailsHolder">
              <h5>
                <Word />
              </h5>
              <h5 className="miniText">
                <Word />
              </h5>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <p className="label2">
                <Word />
              </p>
            </td>
          </tr>
          <tr className="0">
            <td className="imageHolder">
              <Jacket>
                <IMG />
              </Jacket>
            </td>
            <td className="detailsHolder">
              <h5>
                <Word />
              </h5>
              <h5 className="miniText">
                <Word />
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </SkeletonWrapper>
);

export default BoxSkeleton;
