/**
 * Modal for sign up first step.
 * Child of FeaturedProductList
 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { push } from "connected-react-router";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import JoinEnterEmailOnly from "UI/pages/join/JoinEnterEmailOnly.jsx";

const SignUpModal = (props) => {
  if (props.account) {
    return null;
  }
  const hed = "We’ll make this quick.";
  const dek = "First, enter your email. Then choose your move.";
  const cta = "Pick a book now";

  return (
    <ModalEditWrapper header={hed} dek={dek}>
      <JoinEnterEmailOnly
        buttonText={cta}
        hideBack={true}
        fullWidthButtons={true}
        isLeadsGate={true}
        showSecondaryLink={true}
        showTermsBlock={true}
        canadaMode={props.canadaMode}
      />
    </ModalEditWrapper>
  );
};

function mapStateToProps(state) {
  return {
    isReallyLoggedIn:
      state.account && !["Visitor", "Lead", "User"].includes(state.policy.type),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
