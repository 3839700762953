/**
 * Modal for gating pdp add buttons.
 *
 */

import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import { setOverlay } from "State/ui/creators";
import { makeSelection } from "State/box/flows";
import { RegionContext } from "UI/contexts/RegionContext";

import JoinEnterEmailOnly from "UI/pages/join/JoinEnterEmailOnly.jsx";

const JoinAddModal = (props) => {
  const { canadaMode } = useContext(RegionContext);

  if (props.account) {
    return null;
  }

  return (
    <ModalEditWrapper
      header={"Excellent choice"}
      dek={"Just enter your email to add this book to your box."}
    >
      <JoinEnterEmailOnly
        showTermsBlock={true}
        buttonText={"Add to box"}
        hideBack={true}
        nextAction={() => {
          props.makeSelection(props?.id, null, canadaMode);
          props.setOverlay(null);
        }}
        fullWidthButtons={true}
        isLeadsGate={true}
      />
    </ModalEditWrapper>
  );
};

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    isReallyLoggedIn:
      state.account && !["Visitor", "Lead", "User"].includes(state.policy.type),
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, setOverlay, makeSelection }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinAddModal);
