import { createSelector } from "reselect";
import { AppState } from "../../types";

const getMessage = (state: AppState, messageTitle = null) => {
  return messageTitle
    ? (state.message?.activeMessages || []).filter(
        (m) => m && m.message === messageTitle,
      )[0] || null
    : state.message?.activeMessages || [];
};

const getInactiveMessage = (state: AppState, messageTitle = null) =>
  messageTitle
    ? (state.message?.inactiveMessages || []).filter(
        (m) => m && m.message === messageTitle,
      )[0] || null
    : state.message?.inactiveMessages || [];

export const getMessages = createSelector([getMessage], (messages) => messages);

export const getInactiveMessages = createSelector(
  [getInactiveMessage],
  (messages) => messages,
);
