import * as actions from "./actions";

export function setClientToken(token) {
  return {
    type: actions.SET_CLIENT_TOKEN,
    payload: token,
  };
}
export function gettingClientToken(gettingToken) {
  return {
    type: actions.GETTING_CLIENT_TOKEN,
    payload: gettingToken,
  };
}
