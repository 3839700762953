import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getVisitorId, convertVisitorIdToNum } from "Utils/visitorId";

const MODULUS = 65537;

const modPow = (base, exponent) => {
  let result = 1;
  base = base % MODULUS;
  while (exponent > 0) {
    if (exponent % 2 === 1) {
      result = (result * base) % MODULUS;
    }
    exponent = exponent >> 1;
    base = (base * base) % MODULUS;
  }
  return result;
};

const getVariant = (exp, accountId = null) => {
  if (exp) {
    const exponent =
      exp.type === "loggedin" && accountId
        ? accountId
        : convertVisitorIdToNum(getVisitorId());
    const bucket = modPow(exp.expKey, exponent);
    for (let buck of exp.buckets) {
      if (bucket > buck.low && bucket < buck.high) {
        return buck.variant;
      }
    }
  }
  return 0;
};

let experimentsList = [];

export function getAllVariants(accountId = null) {
  return experimentsList.map((exp) => {
    return {
      id: exp.id,
      buckets: exp.buckets,
      variant: getVariant(exp, accountId),
    };
  });
}

const ExperimentsContext = React.createContext({
  dataCache: {},
  experiments: [],
});
const mapStateToProps = (state) => ({
  loginAttempted: state?.loading?.loginAttempted,
  accountId: state?.account?.id,
});
const ExperimentsProvider = connect(mapStateToProps)(({
  experiments,
  variants = {},
  loginAttempted,
  accountId,
  children,
}) => {
  const initialVariants = experiments.reduce((initialVariants, experiment) => {
    initialVariants[experiment.id] = variants[experiment.id] || 0;
    return initialVariants;
  }, {});
  const [dataCache, setDataCache] = useState(initialVariants);
  experimentsList = experiments; // Bad hack

  const setVariant = (experimentId, variant) => {
    setDataCache({
      ...dataCache,
      [experimentId]: variant,
    });
  };

  const setAllVariant = (variant) => {
    const data = {};
    for (let experiment of experiments) {
      data[experiment.id] = variant;
    }
    setDataCache(data);
  };

  global.setVariant = setVariant;
  global.setAllVariant = setAllVariant;

  const reassignVariants = (accountId = null) => {
    const data = {};
    for (let experiment of experiments) {
      data[experiment.id] = getVariant(experiment, accountId);
    }
    setDataCache(data);
  };

  useEffect(() => {
    if (loginAttempted) {
      reassignVariants(accountId);
    }
  }, [loginAttempted, accountId]);

  return (
    <ExperimentsContext.Provider
      value={{
        experiments,
        dataCache,
        setVariant,
        reassignVariants,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  );
});

const Variant = ({ experimentId, children }) => (
  <ExperimentsContext.Consumer>
    {({ experiments, dataCache }) => {
      const experiment = experiments.find((exp) => exp.id === experimentId);
      const variant = dataCache[experimentId] || 0;

      return children({ variant, experiment, experiments });
    }}
  </ExperimentsContext.Consumer>
);

const ExperimentsDisplay = () => (
  <ul>
    <ExperimentsContext.Consumer>
      {({ experiments, dataCache, setVariant }) => {
        return experiments.map((exp) => {
          const variant = dataCache[exp.id] || 0;
          return (
            <li key={exp.id}>
              <h4>Experiment: {exp.id}</h4>
              <p>Name: {exp.name}</p>
              <p>Number of variations: {exp.buckets.length}</p>
              <p>Current variant: {variant}</p>
              <label>New variant</label>
              <div className="selectWrapper">
                <select
                  name={`exp-${exp.id}-variant`}
                  id={`exp-${exp.id}-variant`}
                  value={variant}
                  onChange={(event) => {
                    setVariant(exp.id, parseInt(event.target.value, 10));
                  }}
                >
                  <option value="choose">Choose new variant</option>
                  {exp.buckets.map((bucket) => (
                    <option key={bucket.variant} value={bucket.variant}>
                      {bucket.variant}: {bucket.description} [{bucket.low} -{" "}
                      {bucket.high}]
                    </option>
                  ))}
                </select>
              </div>
            </li>
          );
        });
      }}
    </ExperimentsContext.Consumer>
  </ul>
);

export {
  Variant,
  ExperimentsProvider,
  ExperimentsDisplay,
  ExperimentsContext,
  getVariant,
};
