declare global {
  interface Window {
    ApplePaySession: any;
  }
}

export const canUseApplePay = () => {
  if (typeof window !== undefined) {
    if (window.ApplePaySession) {
      const hasSupportedVersion = window.ApplePaySession.supportsVersion(3);
      if (hasSupportedVersion) {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
