const emailTemplate = ({
  firstName,
  lastName,
  country,
}) => `<table align="center" border="0" cellpadding="0" cellspacing="0" id="bodyTable" width="100%"
data-upload-file-url="/ajax/email-editor/file/upload" data-upload-files-url="/ajax/email-editor/files/upload"
style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:auto;padding:0;background-color:#FFF;height:100%;margin:0;width:100%">
<tbody>
  <tr>
    <td align="center" id="bodyCell" valign="top"
      style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:auto;padding-top:30px;padding-left:20px;padding-bottom:20px;padding-right:20px;border-top:0;height:100%;margin:0;width:100%">
      <!--[if !mso]><!-->
      <div class="templateContainer"
        style="border:0 none #AAA;background-color:#fff;border-radius:0;display: table; max-width:600px">
        <div class="templateContainerInner" style="padding:0">
          <!--<![endif]-->
          <!--[if mso]>
              <table border="0" cellpadding="0" cellspacing="0" class="templateContainer"  width="600" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
              <tbody>
                <tr>
                  <td class="templateContainerInner" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
            <![endif]-->
          <table border="0" cellpadding="0" cellspacing="0" width="100%"
            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
            <tbody>
              <tr>
                <td align="center" valign="top"
                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                  <table border="0" cellpadding="0" cellspacing="0" class="templateRow" width="100%"
                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                    <tbody>
                      <tr>
                        <td class="rowContainer kmFloatLeft" valign="top"
                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                          <table border="0" cellpadding="0" cellspacing="0" class="kmTextBlock" width="100%"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                            <tbody class="kmTextBlockOuter">
                              <tr>
                                <td class="kmTextBlockInner" valign="top"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    class="kmTextContentContainer" width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                    <tbody>
                                      <tr>
                                        <td class="kmTextContent" valign="top"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;color:#204399;font-family:Arial;font-size:14px;line-height:1.3;letter-spacing:0;text-align:left;max-width:100%;word-wrap:break-word;font-size:12px;color:#727272;padding-bottom:9px;text-align:center;padding-right:18px;padding-left:18px;padding-top:9px;">
                                          <span style="color:#A9A9A9;">Can't see this email? <span
                                              class="unsubscribe-link"
                                              style="color:#A9A9A9;font-weight:normal;text-decoration:underline;">View
                                              in Your Browser</span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" class="kmImageBlock" width="100%"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                            <tbody class="kmImageBlockOuter">
                              <tr>
                                <td class="kmImageBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0px;padding-right:9;padding-left:9;padding-bottom:20px;"
                                  valign="top">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    class="kmImageContentContainer" width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td class="kmImageContent" valign="top"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0;font-size:0;padding:0;text-align: center;">
                                         
                                            <img align="center" alt="Book of the month" class="kmImage"
                                              src="https://d3k81ch9hvuctc.cloudfront.net/company/JSKcw7/images/fdf70016-5346-40f6-b4eb-3d1d93f82349.png"
                                              width=" 200 "
                                              style="border:0;height:auto;line-height:100%;outline:none;text-decoration:none;max-width:100%;padding-bottom:0;display:inline;vertical-align:top;font-size:12px;width:100%;max-width:200px;padding:0;border-width:0;">
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" valign="top"
                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                  <table border="0" cellpadding="0" cellspacing="0" class="templateRow" width="100%"
                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                    <tbody>
                      <tr>
                        <td class="rowContainer kmFloatLeft" valign="top"
                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                          <table border="0" cellpadding="0" cellspacing="0" class="kmImageBlock" width="100%"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                            <tbody class="kmImageBlockOuter">
                              <tr>
                                <td class="kmImageBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0px;padding-right:0px;padding-left:0px;"
                                  valign="top">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    class="kmImageContentContainer" width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td class="kmImageContent" valign="top"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0;font-size:0;padding:0;">
                                          
                                            <img align="left" alt="" class="kmImage"
                                              src="https://static.bookofthemonth.com/RAF/RAF_Email_Desktop.png"
                                              width="600"
                                              style="border:0;height:auto;line-height:100%;outline:none;text-decoration:none;max-width:100%;padding-bottom:0;display:inline;vertical-align:top;font-size:12px;width:100%;margin-right:0;max-width:600px;padding:0;border-width:0;">
                                      
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="kmDividerBlock"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;background-color:#FFFFFF">
                            <tbody class="kmDividerBlockOuter">
                              <tr>
                                <td class="kmDividerBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding-top:30px;">
                                  <table class="kmDividerContent" border="0" cellpadding="0" cellspacing="0"
                                    width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                          <span></span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" class="kmImageBlock" width="100%"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                            <tbody class="kmImageBlockOuter">
                              <tr>
                                <td class="kmImageBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0px;padding-right:9;padding-left:9;"
                                  valign="top">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    class="kmImageContentContainer" width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td class="kmImageContent" valign="top"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding:0;font-size:0;padding:0;text-align: center;">
                                          
                                          <h2>Get a new read for just ${
                                            country == "US" ? "$5" : "$14.99"
                                          }.</h2>
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="kmDividerBlock"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;background-color:#FFFFFF">
                            <tbody class="kmDividerBlockOuter">
                              <tr>
                                <td class="kmDividerBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding-top:30px;">
                                  <table class="kmDividerContent" border="0" cellpadding="0" cellspacing="0"
                                    width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                          <span></span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" class="kmTextBlock" width="100%"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                            <tbody class="kmTextBlockOuter">
                              <tr>
                                <td class="kmTextBlockInner" valign="top"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0"
                                    class="kmTextContentContainer" width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                    <tbody>
                                      <tr>
                                        <td class="kmTextContent" valign="top"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;color:#204399;font-family:Arial;font-size:14px;line-height:1.3;letter-spacing:0;text-align:left;max-width:100%;word-wrap:break-word;padding-top:0px;padding-bottom:0px;padding-left:18px;padding-right:18px;">
                                          <p style="margin:0;padding-bottom:0;text-align: center;">
                                            <font color="#020202"
                                              face="Roboto, RobotoDraft, Helvetica, Arial, sans-serif"><span
                                                style="font-size: 15px; white-space: pre-wrap;"></span></font>You also
                                            might move higher on ${firstName} ${lastName}’s friend list, just for
                                            redeeming their referral.<br />It’s a win-win.<font color="#020202"
                                              face="Roboto, RobotoDraft, Helvetica, Arial, sans-serif"><span
                                                style="font-size: 15px; white-space: pre-wrap;"></span></font>
                                            </referring>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="kmDividerBlock"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                            <tbody class="kmDividerBlockOuter">
                              <tr>
                                <td class="kmDividerBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;padding-top:40px;">
                                  <table class="kmDividerContent" border="0" cellpadding="0" cellspacing="0"
                                    width="100%"
                                    style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed">
                                          <span></span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="kmButtonBlock"
                            style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;width: 100% !important">
                            <tbody class="kmButtonBlockOuter">
                              <tr>
                                <td valign="top" align="center" class="kmButtonBlockInner"
                                  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;min-width:60px;padding:9px 18px;padding-top:0px;padding-bottom:0px;padding-left:18;padding-right:18;">
                                  <table border="0" cellpadding="0" cellspacing="0" width=""
                                    class="kmButtonContentContainer"
                                    style="border-collapse:separate;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;border-top-left-radius:5px;border-top-right-radius:5px;border-bottom-right-radius:5px;border-bottom-left-radius:5px;background-color:#999;background-color:#C8CCD0;border-radius:5px;">
                                    <tbody>
                                      <tr>
                                        <!--[if !mso]><!-->
                                        <td align="center" valign="middle" class="kmButtonContent"
                                          style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed;color:white;font-family:Arial;font-size:16px;color:#FFFFFF;letter-spacing:0px;font-size:16px;font-family:&quot;Helvetica Neue&quot;, Arial;font-weight:normal;">
                                          <span class="kmButton" title="" target="_self"
                                            style="word-wrap:break-word;max-width:100%;font-weight:normal;line-height:100%;text-align:center;text-decoration:underline;color:#A9A9A9;font-family:Arial;font-size:16px;text-decoration:none; display: inline-block; padding-top:15px;padding-bottom:15px;font-size:18px;color:#FFFFFF;letter-spacing:0px;font-weight:normal;padding-left:15px;padding-right:15px;font-family:&quot;FoldGrotesquePro-Medium&quot;, &quot;Helvetica&quot;, &quot;Arial&quot;, sans-serif; ;">Redeem offer now</span>
                                        </td>
                                        <!--<![endif]-->
                                        <!--[if mso]>
                                        <td align="center" valign="middle" class="kmButtonContent"
                                          style="padding:15px; padding-top:15px;padding-bottom:15px;font-size:16px;color:#FFFFFF;letter-spacing:0px;font-weight:normal;padding-left:15px;padding-right:15px;font-family:&quot;Helvetica Neue&quot;, Arial;">
                                          <span class="kmButton" title=""  target="_self" style="text-decoration:none;padding-top:15px;padding-bottom:15px;font-size:16px;color:#FFFFFF;letter-spacing:0px;font-weight:normal;padding-left:15px;padding-right:15px;font-family:&quot;Helvetica Neue&quot;, Arial;; margin-top:-15px;margin-bottom:-15px;margin-left:-15px;margin-right:-15px;">Redeem offer now</span>
                                        </td>
                                        <![endif]-->
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!--[if !mso]><!-->
        </div>
      </div>
      <!--<![endif]-->
      <!--[if mso]>
                    </td>
                  </tr>
                </tbody>
              </table>
            <![endif]-->
    </td>
  </tr>
</tbody>
</table>
`;

export default emailTemplate;
