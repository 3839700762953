import React, { useState, useRef, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { clearError } from "State/error/creators";
import styled from "styled-components";
import DynamicInput from "UI/elements/form/DynamicInput";
import DynamicSelect from "UI/elements/form/DynamicSelect";
import { validationRegExp } from "UI/elements/form/util";
import { States, Countries, Provinces } from "UI/elements/form/SelectData.js";
import { Error } from "UI/elements/form/Error";
import ClickTracker from "UI/elements/ClickTracker";
import { validateAddress, saveAddress } from "State/address/flows";
import { updateAccount } from "State/account/flows";
import { useWindowSize } from "UI/hooks";
import { withStoreData } from "UI/hoc/withStoreData";
import { RegionContext } from "UI/contexts/RegionContext";

import { getCountryCode } from "Utils/countryPricing";

const PLACEHOLDER_LAST_NAME = "UNKNOWN";

const FieldsetShippingInfo = ({
  buttonText = "Save",
  account,
  address,
  top = 30,
  bottom = 20,
  skipValidateAddress,
  preventCountrySwitch = false,
  overrideAction,
  onChangeTextCb,
  successCB,
  failCB,
  noButton,
  validFormCheckCB,
  logClickData,
  appFeatures,
  isDisabled,
  showErrors = true,
  ...props
}) => {
  const { locationHeader } = useContext(RegionContext);
  const canadaAppFeature = appFeatures?.find((f) => f?.feature == "True North");
  const canadaActive = canadaAppFeature?.status == "Active";
  const canadaBeta =
    canadaAppFeature?.status == "Beta" && canadaAppFeature.isBetaEligible;
  const canadaExcludeGiftCard = appFeatures?.find(
    (f) => f?.feature == "True North gifting",
  );
  const { storeData } = props;
  const { store } = storeData;
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const [oAddress, setOAddress] = useState({
    firstName: address?.name?.split(" ")?.[0] || account?.firstName || "",
    lastName:
      address?.name?.split(" ")?.[1] ||
      (account?.lastName !== PLACEHOLDER_LAST_NAME && account?.lastName) ||
      "",
    countryCode: getCountryCode(
      canadaActive || canadaBeta,
      address?.countryCode,
      locationHeader.countryHeader,
    ),
    name: oAddress?.firstName
      ? oAddress?.firstName + " " + oAddress?.lastName
      : account?.firstName
      ? account?.firstName + " " + account?.lastName
      : "",
    street1: address?.street1 || "",
    street2: address?.street2 || "",
    city: address?.city || "",
    state: address?.state || null,
    zip: address?.zip || "",
  });
  const [activeField, setActiveField] = useState("");
  const fieldsToValidate = [
    "firstName",
    "lastName",
    "street1",
    "city",
    "state",
    "zip",
  ];
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [skipValidation, setSkipValitation] = useState(false);
  // const [recommendedAddresses, setRecommendedAddresses] = useState([]);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const street1Ref = useRef(null);
  const street2Ref = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipRef = useRef(null);
  let newAddress = null;
  const formIsValid =
    oAddress.firstName?.length >= 1 &&
    oAddress.lastName?.length >= 1 &&
    oAddress.street1?.length >= 1 &&
    oAddress.city?.length >= 1 &&
    oAddress.state?.length >= 2 &&
    oAddress.zip?.length >= 5 &&
    invalidFields?.length <= 0;

  const submitErrorMessage = (() => {
    if (submitError) {
      if (
        typeof submitError === "string" ||
        submitError.type === React.Fragment
      ) {
        return submitError;
      } else if (typeof submitError?.error === "string") {
        return submitError.error;
      } else if (typeof submitError === "object") {
        return "Please check address.";
      }
    }
    return null;
  })();

  let errorToShow =
    !isSubmitting && submitError ? (
      <StyledErrorWrapper>
        <Error message={submitErrorMessage} top={0} bottom={30} />
      </StyledErrorWrapper>
    ) : null;

  useEffect(() => {
    const formIsValid = checkIfFormIsValid();
    if (validFormCheckCB) {
      validFormCheckCB(formIsValid);
    }
  }, [oAddress]);

  const validateInputData = (field, data, fromBlur = false) => {
    if (
      oAddress?.[field]?.length &&
      oAddress?.[field].replace(/\s/g, "").length == 0
    ) {
      return false;
    } else if (fromBlur && oAddress?.[field]?.length < 1) {
      clearErrorField(field);
      return true;
    } else if (oAddress.countryCode == "CA" && field == "zip") {
      return validationRegExp?.["zipCa"].test(data || oAddress?.[field]);
    } else if (oAddress.countryCode == "CA" && field == "state") {
      //Remove this if we start shipping to Quebec
      const isQuebecValid = quebecExclusionValidation(field);
      if (!isQuebecValid) {
        return false;
      }
      return validationRegExp?.["province"].test(data || oAddress?.[field]);
    } else {
      return validationRegExp?.[field].test(data || oAddress?.[field]);
    }
  };

  //Remove this if we start shipping to Quebec
  const quebecExclusionValidation = (field) => {
    let isQuebecValid = true;
    const provinceSelected = oAddress?.[field];
    if (provinceSelected == "QC") {
      isQuebecValid = false;
      setSubmitError(
        <>
          Sorry, we don’t ship to Quebec yet!
          <br />
          Désolé, nous ne livrons pas encore au Québec!
        </>,
      );
      getAndSetInvalidFields(field);
    }
    return isQuebecValid;
  };

  const checkIfFormIsValid = () => {
    let fieldIsValid = true;
    let invalidFormFields = [];
    fieldsToValidate.map((f) => {
      fieldIsValid = checkIfValid(f, oAddress[f]);
      if (!fieldIsValid) {
        invalidFormFields.push(f);
      }
    });
    // setInvalidFields(invalidFormFields);
    if (invalidFormFields?.length >= 1) {
      return false;
    } else return true;
  };

  const checkIfValid = (field, data) => {
    const isValid = validateInputData(field, data);
    if (isValid && invalidFields.includes(field)) {
      clearErrorField(field);
    }
    return isValid;
  };

  const getAndSetInvalidFields = (errorField) => {
    if (!invalidFields.includes(errorField)) {
      setInvalidFields([errorField, ...invalidFields]);
    }
    setIsSubmitting(false);
    if (failCB) {
      failCB();
    }
  };

  const clearErrorField = (field) => {
    if (invalidFields.includes(field)) {
      const newInvalidFields = invalidFields.filter((f) => f !== field);
      setInvalidFields(newInvalidFields);
    }
  };

  const handleInputChange = (f, v) => {
    setSubmitError(null);
    props.clearError();
    let oUpdate = oAddress;
    oUpdate[f] = v;
    const name = oUpdate.firstName + " " + oUpdate.lastName;
    setOAddress({ ...oUpdate, name });
    checkIfValid(f, v);
    if (onChangeTextCb) {
      onChangeTextCb({ ...oAddress });
    }
  };

  const setOnFocus = (input) => {
    setActiveField(input);
  };

  const onBlur = (field) => {
    setOnFocus("");
    const fieldIsVaild = validateInputData(field, null, true);
    if (!fieldIsVaild) {
      getAndSetInvalidFields(field);
    }
    const isValid = checkIfFormIsValid();
    if (validFormCheckCB) {
      validFormCheckCB(isValid);
    }
  };

  const clearIt = (field) => {
    clearErrorField(field);
    let oUpdate = oAddress;
    oUpdate[field] = "";
    setOAddress({ ...oAddress });
  };

  const buttonAction = async () => {
    const successFn = () => {
      setIsSubmitting(false);
      if (successCB && newAddress) {
        successCB(newAddress);
      }
    };

    const failFn = (e) => {
      //e obj => { error: e.message, type: 'password' }
      setIsSubmitting(false);
      setSubmitError(e);
      // getAndSetInvalidFields(e.type);//Not doing right now!
      // setActiveField(e.type);//Not doing right now!
      if (failCB) {
        failCB(e);
      }
    };

    const saveSuccess = async (a) => {
      newAddress = a;
      await props?.updateAccount(a, store, successFn, failFn);
      setIsSubmitting(false);
    };

    let errorField = fieldsToValidate.find(
      (field) => !validateInputData(field),
    );

    if (errorField) {
      getAndSetInvalidFields(errorField);
    } else {
      setIsSubmitting(true);

      try {
        const shouldSkipValidation =
          skipValidateAddress || oAddress.countryCode == "CA";
        let recommendedAddresses = !shouldSkipValidation
          ? await validateAddress(oAddress)
          : [];

        recommendedAddresses = recommendedAddresses.filter((addr) =>
          validationRegExp.state.test(addr.state),
        );
        const hasRecommendedAddress = Boolean(
          recommendedAddresses.some(
            (
              possibleAddress, // or these fields are mismatched
            ) =>
              !new RegExp(oAddress.zip, "i").test(possibleAddress.zip) ||
              !new RegExp(oAddress.city, "i").test(possibleAddress.city) ||
              !new RegExp(oAddress.state, "i").test(possibleAddress.state),
          ),
        );
        if (!recommendedAddresses.length && !hasRecommendedAddress) {
          try {
            if (overrideAction) {
              await overrideAction(oAddress);
            } else {
              await props.saveAddress(oAddress, null, saveSuccess, failFn);
            }
          } catch (e) {
            console.error("ERROR-->", e);
            failFn(e);
          }
        } else {
          const cancelSubmit = () => setIsSubmitting(false);
          props.setOverlay("addressValidationModal", "modal", {
            recommendedAddresses,
            oAddress,
            skipValidation: () => setSkipValitation(true),
            cb: async (address, removeOverlay, originalChosen = false) => {
              let formattedAddress = {
                name:
                  oAddress?.name ||
                  address?.name ||
                  account?.firstName + " " + account?.lastName,
                firstName: oAddress?.firstName || account?.firstName,
                lastName: oAddress?.lastName || account?.lastName,
                ...address,
              };
              try {
                removeOverlay();
                if (overrideAction) {
                  await overrideAction(formattedAddress);
                } else {
                  await props.saveAddress(
                    formattedAddress,
                    originalChosen,
                    saveSuccess,
                    failFn,
                  );
                }
              } catch (e) {
                console.error("ERROR-->", e);
                failFn(e);
              }
            },
            cancelSubmit,
          });
        }
      } catch (e) {
        setIsSubmitting(false);
        console.warn("ShippingAddress error --> ", e);
      }
    }
  };

  return (
    <StyledAddressFormWrapper
      top={top}
      bottom={bottom}
      onSubmit={(evt) => {
        evt.preventDefault();
        buttonAction();
      }}
    >
      {showErrors && errorToShow}
      <DynamicInput
        placeholder={activeField == "firstName" ? "Jane" : "First name"}
        label="First name"
        value={oAddress.firstName}
        accessibilityLabel={`First Name`}
        onChangeText={(v) => {
          handleInputChange("firstName", v);
        }}
        onFocus={() => setOnFocus("firstName")}
        onBlur={() => onBlur("firstName")}
        isInFocus={activeField == "firstName"}
        clearIt={() => clearIt("firstName")}
        error={
          invalidFields.includes("firstName")
            ? "Please enter a valid first name."
            : null
        }
        forwardRef={firstNameRef}
        required={"required"}
        name={"given-name"}
        inputId={"given-name"}
        autoComplete={"given-name"}
        size={isMobile ? "full" : "half"}
      />
      <DynamicInput
        placeholder={activeField == "lastName" ? "Doe" : "Last name"}
        label="Last name"
        value={oAddress.lastName}
        accessibilityLabel={`Last Name`}
        onChangeText={(v) => {
          handleInputChange("lastName", v);
        }}
        onFocus={() => setOnFocus("lastName")}
        onBlur={() => onBlur("lastName")}
        isInFocus={activeField == "lastName"}
        clearIt={() => clearIt("lastName")}
        error={
          invalidFields.includes("lastName")
            ? "Please enter a valid last name."
            : null
        }
        forwardRef={lastNameRef}
        required={"required"}
        name={"family-name"}
        inputId={"family-name"}
        autoComplete={"family-name"}
        size={isMobile ? "full" : "half"}
      />
      {canadaActive && !preventCountrySwitch && !canadaExcludeGiftCard ? (
        <DynamicSelect
          placeholder={"Country"}
          label="Country"
          onChangeSelection={(v) => {
            handleInputChange("countryCode", v);
          }}
          selectedValue={oAddress.countryCode}
          accessibility={true}
          required={true}
          selectOptions={Countries}
          isInFocus={activeField == "countryCode"}
          onFocus={() => setOnFocus("countryCode")}
          onBlur={() => onBlur("countryCode")}
          error={
            invalidFields.includes("countryCode")
              ? "Please select a country."
              : null
          }
        />
      ) : null}
      <DynamicInput
        placeholder={activeField == "address" ? "1 main St" : "Address"}
        label="Address"
        value={oAddress.street1}
        accessibility={true}
        accessibilityLabel={`JoinStreet1Address`}
        onChangeText={(v) => {
          handleInputChange("street1", v);
        }}
        onFocus={() => setOnFocus("street1")}
        onBlur={() => onBlur("street1")}
        isInFocus={activeField == "street1"}
        clearIt={() => clearIt("street1")}
        error={
          invalidFields.includes("street1")
            ? "Please enter a valid address."
            : null
        }
        forwardRef={street1Ref}
        required={"required"}
        name={"address2"}
        inputId={"street1"}
        autoComplete={"address-line1"}
        size={isMobile ? "full" : "halfPlus"}
      />
      <DynamicInput
        placeholder={activeField == "street2" ? "Apt 1" : "Apt./Suite"}
        label="Apt./Suite"
        value={oAddress.street2}
        accessibility={true}
        accessibilityLabel={`JoinStreet2Address`}
        onChangeText={(v) => {
          handleInputChange("street2", v);
        }}
        onFocus={() => setOnFocus("street2")}
        onBlur={() => onBlur("street2")}
        isInFocus={activeField == "street2"}
        clearIt={() => clearIt("street2")}
        error={
          invalidFields.includes("street2")
            ? "Please enter a valid address."
            : null
        }
        forwardRef={street2Ref}
        name={"address2"}
        inputId={"street2"}
        autoComplete={"address-line2"}
        size={isMobile ? "half" : "halfMinus"}
      />
      <DynamicInput
        placeholder={activeField == "city" ? "Anytown" : "City"}
        label="City"
        value={oAddress.city}
        accessibility={true}
        accessibilityLabel={`JoinCityAddress`}
        onChangeText={(v) => {
          handleInputChange("city", v);
        }}
        onFocus={() => setOnFocus("city")}
        onBlur={() => onBlur("city")}
        isInFocus={activeField == "city"}
        clearIt={() => clearIt("city")}
        error={
          invalidFields.includes("city") ? "Please enter a valid city." : null
        }
        forwardRef={cityRef}
        required={"required"}
        name={"city"}
        inputId={"city"}
        autoComplete={"address-level2"}
        size={isMobile ? "half" : "third"}
      />
      <DynamicSelect
        forwardRef={stateRef}
        placeholder={oAddress.countryCode == "CA" ? "Province" : "State"}
        label={oAddress.countryCode == "CA" ? "Province" : "State"}
        onChangeSelection={(v) => {
          handleInputChange("state", v);
        }}
        selectedValue={oAddress.state}
        accessibility={true}
        required={true}
        selectOptions={oAddress.countryCode == "CA" ? Provinces : States}
        isInFocus={activeField == "state"}
        onFocus={() => setOnFocus("state")}
        onBlur={() => onBlur("state")}
        error={(() => {
          let errorMessage = null;
          let field = oAddress.countryCode == "CA" ? "province" : "state";
          if (oAddress.countryCode == "CA" && oAddress.state == "QC") {
            errorMessage = " "; //Just to highlight the province field in red
          } else if (invalidFields.includes("state")) {
            errorMessage = `Please select a ${field}.`;
          }
          return errorMessage;
        })()}
        size={isMobile ? "half" : "third"}
      />
      <DynamicInput
        placeholder={activeField == "zip" ? "XXXXX" : "Zip"}
        inputType={oAddress.countryCode == "US" && isMobile ? "number" : "text"}
        label="Zip"
        value={oAddress.zip}
        accessibility={true}
        accessibilityLabel={`JoinZipAddress`}
        onChangeText={(v) => {
          handleInputChange("zip", v);
        }}
        onFocus={() => setOnFocus("zip")}
        onBlur={() => onBlur("zip")}
        isInFocus={activeField == "zip"}
        clearIt={() => clearIt("zip")}
        error={
          invalidFields.includes("zip") ? "Please enter a valid zipcode." : null
        }
        forwardRef={zipRef}
        required={"required"}
        name={"zip"}
        inputId={"zip"}
        autoComplete={"postal-code"}
        size={isMobile ? "half" : "third"}
      />

      {!noButton && (
        <StyledActionsWrapper>
          <ClickTracker
            ctaType={"button"}
            id="shipping-submit"
            style={"primary fullWidth"}
            handleClick={formIsValid ? () => buttonAction() : null}
            isPending={isSubmitting}
            title={isSubmitting ? "Updating..." : buttonText}
            isDisabled={!formIsValid || isDisabled}
            logClickData={logClickData}
            customClass="link-p1"
            size={isMobile ? 16 : 18}
          />
        </StyledActionsWrapper>
      )}
    </StyledAddressFormWrapper>
  );
};

export const StyledAddressFormWrapper = styled.form.attrs({
  className: "sc-StyledAddressFormWrapper",
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
`;

export const StyledActionsWrapper = styled.div.attrs({
  className: "sc-StyledActionsWrapper",
})`
  flex: 1;
`;

export const StyledErrorWrapper = styled.div.attrs({
  className: "sc-StyledErrorWrapper",
})`
  width: 100%;
`;

function mapStateToProps(state) {
  return {
    store: state,
    account: state.account,
    appFeatures: state.appFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setOverlay, saveAddress, updateAccount, clearError },
    dispatch,
  );
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(FieldsetShippingInfo),
);
