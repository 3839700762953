export async function get() {
  const response = await fetch(`${global.apiHost}/api/v0/store/`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const store = await response.json();
  store.currentCycle.startDate = new Date(store.currentCycle.startDate);
  return store;
}
export async function match(host: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/store?match=${encodeURIComponent(host)}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const store = await response.json();
  return store;
}
