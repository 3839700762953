import { getCookies } from "./cookie";

export function getVisitorId() {
  const visitorCookie = getCookies()["visitor_id"];
  if (!visitorCookie) {
    const newVisitorId = Math.random().toString(36).slice(2);
    document.cookie = `visitor_id=${newVisitorId}; expires=Sat, 01 Jan 2050 05:00:00 GMT; domain=${location.host
      .split(":")[0]
      .replace(/^app\./, "")}`;
    return newVisitorId;
  }
  return visitorCookie;
}
export function convertVisitorIdToNum(visitorId) {
  return Number.parseInt(
    Number.parseInt(visitorId, 36).toString().slice(0, 8),
    10,
  );
}
