import * as React from "react";
import styled from "styled-components";

interface ListSortModalProps {
  sortAction: (sa?: string, sb?: string) => void;
  activeSort?: string;
  sortItems?: Array<{
    name?: string;
    value?: string | number | null;
    icon?: JSX.Element;
  }>;
}

export const ListSortModal: React.FC<ListSortModalProps> = ({
  sortAction,
  activeSort,
  sortItems,
}) => {
  const sortIt = (sortBy?: string) => {
    sortAction("categories", sortBy);
  };
  const sortBy = activeSort;

  const setSortItems = () => {
    const sortByItems = sortItems?.map((si, i) => (
      <li
        key={i}
        onClick={() => sortIt(si.name)}
        className={sortBy == si ? "active" : ""}
      >
        <div>
          {si.icon && si.icon}
          {si.name && si.name}
        </div>
        <div>{si.value && si.value}</div>
      </li>
    ));
    return sortByItems;
  };

  return (
    <StyledSortWrapper>
      <ul>{setSortItems()}</ul>
    </StyledSortWrapper>
  );
};

export const StyledSortWrapper = styled.div`
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(232, 232, 232);
    div {
      display: flex;
      align-items: center;
      line-height: 3.2rem;
      font-size: 1.8rem;
      transition: color 0.3s;
      white-space: nowrap;
    }
    &:hover {
      div {
        color: var(--color-primary5);
      }
    }
    &.active {
      div {
        color: var(--color-primary5);
      }
    }
    svg {
      min-width: 22px;
      height: 22px;
      margin-right: 12px;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }
`;
