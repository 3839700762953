export function sendKlaviyoTrackEvent(event = "", data) {
  global._learnq.push(["track", event, data]);
}

export function identifyAccount(account) {
  global._learnq.push([
    "identify",
    {
      $email: account.email,
      $first_name: account.firstName,
      $last_name: account.lastName,
    },
  ]);
}

export function viewedProduct(product) {
  global._learnq.push([
    "track",
    "Viewed Product",
    {
      ProductName: product.title,
      ProductID: product.id,
      Categories: [],
      ImageURL: "",
      URL: "",
      Brand: "",
      Price: 9.99,
    },
  ]);

  global._learnq.push([
    "trackViewedItem",
    {
      Title: product.title,
      ItemId: product.id,
      Categories: [],
      ImageURL: "",
      Url: "",
      Metadata: {
        Brand: "",
        Price: 9.99,
      },
    },
  ]);
}

export function viewedGiftPage(account) {
  global._learnq.push(["track", "Viewed Gift Page"]);
}

export function noPromotions(account) {
  global._learnq.push([
    "identify",
    {
      $email: account.email,
      EmailPreferences: "No Promotions",
    },
  ]);
}
