import React, { useState, useEffect } from "react";

const AUDIOBOOK_PLATFORM_QUESTION_ID = 6683;

const SurveyQuestion = ({
  question,
  handleChange,
  selectedQuestions,
  surveyResponses,
  setState,
  arrowIdx,
  isSelected,
}) => {
  switch (question.type) {
    case "checkbox":
      const checked = selectedQuestions?.indexOf(question.id) > -1;
      const className = checked ? "surveyAda" : "";
      const toggleCheckbox = (e, question) => {
        handleChange(question.id, question.type, e, !checked);
      };
      return (
        <label>
          <div className="checkboxWrapper">
            <div
              type="checkbox"
              name={question.name}
              onClick={(e) => {
                toggleCheckbox(e, question);
              }}
              onKeyPress={(e) => {
                toggleCheckbox(e, question);
              }}
              tabIndex="0"
              className={`checkBox ${checked ? "--checked" : ""}`}
            >
              <div className={className} />
            </div>
          </div>
          <div className="p1-alt">{question.name}</div>
        </label>
      );
    case "text":
      return (
        <>
          <label>{question.name}</label>
          <input
            type="text"
            name={question.name}
            value={surveyResponses[question.id] || ""}
            placeholder={question.name}
            onChange={(e) => handleChange(question.id, question.type, e)}
            checked={selectedQuestions.indexOf(question.id) > -1}
          />
        </>
      );
    case "textarea":
      const isAudiobookQuestion =
        question.id === AUDIOBOOK_PLATFORM_QUESTION_ID;
      return (
        <textarea
          className="survey-textarea"
          name={question.name}
          value={surveyResponses[question.id]}
          placeholder={
            isAudiobookQuestion
              ? "Where do you get your audiobooks?"
              : question.name
          }
          onChange={(e) => handleChange(question.id, question.type, e)}
        />
      );
    case "radio":
      const toggleRadio = (e, question) => {
        setState({ radioSelectionId: question.id });
        handleChange(question.id, question.type, e, true, question.name);
      };
      const arrowkeys = (e) => {
        e.preventDefault();
        const ele = document.getElementsByClassName("radio");
        if (e.key == "ArrowUp") {
          if (arrowIdx < 0) arrowIdx = ele?.length - 1;
          setState({ arrowIdx: arrowIdx - 1 });
        } else {
          if (arrowIdx >= ele?.length) arrowIdx = 0;
          setState({ arrowIdx: arrowIdx + 1 });
        }
        ele?.[arrowIdx]?.focus();
      };

      return (
        <label className="radioLabelWrapper">
          <div className="radioWrapper">
            <div
              type="radio"
              name={question.name}
              onClick={(e) => {
                toggleRadio(e, question);
              }}
              onKeyPress={(e) => {
                toggleRadio(e, question);
              }}
              onKeyDown={(e) => {
                if (e.key == "ArrowDown" || e.key == "ArrowUp") {
                  arrowkeys(e);
                }
              }}
              tabIndex="0"
              className={`radio ${isSelected ? "selected" : ""}`}
            >
              <div className={isSelected ? "radioAda" : ""} />
            </div>
          </div>
          <div
            className="radio-question"
            onClick={(e) => {
              toggleRadio(e, question);
            }}
          >
            {question.name}
          </div>
        </label>
      );

    case "label":
      return <label className="text-question">{question.name}</label>;
    default:
      return null;
  }
};

export default SurveyQuestion;
