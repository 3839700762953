/**
 * Parent wrapper for apps main/top navigation.
 * Sets nav type - (Member/condensed/Non-member)
 * Child of Header.jsx
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { withStoreData } from "UI/hoc/withStoreData";
import ClickTracker from "UI/elements/ClickTracker";
import { boxCount } from "State/box/selectors";
import { experimentFlowTracking } from "Utils/analytics";
import { setOverlay } from "State/ui/creators";
import { snesRedirect } from "Utils/snesRedirect";

class Nav extends Component {
  constructor(props) {
    super(props);
    let joinLink = "enroll";
    this.state = { joinLink };
    this.gaTracking = this.gaTracking.bind(this);
    this.toggleReferActive = this.toggleReferActive.bind(this);
  }

  componentDidUpdate() {
    this.toggleReferActive();
  }

  toggleReferActive() {
    let referLink = document.getElementById("refer");
    if (this.props.location == "/refer-a-friend") {
      referLink?.classList?.add?.("active");
    } else {
      referLink?.classList?.remove?.("active");
    }
  }

  navigate(path) {
    this.props.setOverlay("");
    this.props.push(path);
    if (typeof document !== "undefined") {
      document.getElementById("bodyWrapper").className = "modalClosed";
    }
    return true;
  }

  gaTracking() {
    experimentFlowTracking("Click Signup");
  }

  render() {
    let { isMemberRejoinBan, isCourtesy, isLoggedIn } = this.props;

    return (
      <nav
        className={
          !isMemberRejoinBan && !isCourtesy ? "-loggedOut" : "-loggedIn"
        }
      >
        <ul className="forDesktop">
          <li className="navLinkWrapper">
            <a
              tabIndex="0"
              onClick={() => snesRedirect("snes", "/the-best-new-books")}
            >
              {"Current books"}
            </a>
          </li>
          <li tabIndex="-1" className="navLinkWrapper">
            <a
              onClick={() => snesRedirect("snes", "/all-hardcovers")}
              tabIndex="0"
            >
              All books
            </a>
          </li>
          <li className="navLinkWrapper">
            {isLoggedIn ? (
              <ClickTracker
                ctaType={"navLink"}
                tabIndex="0"
                id="refer"
                to={"/refer-a-friend"}
                logClickData={"Clicked-header-refer-a-friend"}
                title={"Refer a friend"}
              />
            ) : (
              <a onClick={() => snesRedirect("snes", "/about-us")} tabIndex="0">
                About us
              </a>
            )}
          </li>
          <li className="mobileHide navLinkWrapper">
            <a onClick={() => snesRedirect("snes", "/gift")} tabIndex="0">
              Gifts
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const { appFeatures } = state;
  const swagAppFeature = appFeatures?.find((f) => f?.feature == "Swag shop");
  const isSwagShopActive = swagAppFeature?.status == "Active";

  return {
    account: state.account,
    policy: state.account ? state.account.policy : state.policy,
    isMemberRejoinBan:
      (state.policy && state.policy.type === "Member") ||
      state.policy.type === "Rejoin" ||
      state.policy.type === "Ban",
    isLoggedIn:
      (state.loading.loginAttempted && state.policy.type !== "Visitor") ||
      false,
    isReallyLoggedIn:
      state.account &&
      state.policy.type !== "Visitor" &&
      state.policy.type !== "Lead",
    joinFlow: state.joinFlow,
    enrollProduct: state.joinData.product,
    boxCount: boxCount(state),
    location: state.analytics.location,
    overlay: state.ui ? state.ui.overlay : null,
    entryPoint: state.entryPoint,
    isCourtesy: state?.account?.policy?.type === "Courtesy", //Not valid check - There is no policy with type 'Courtesy'
    isSwagShopActive,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      setOverlay,
    },
    dispatch,
  );
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Nav),
);
