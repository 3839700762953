import React from "react";
import SkeletonWrapper, {
  P,
  H3,
  InvisibleBlock,
  Jacket,
} from "./SkeletonWrapper.jsx";
import HeaderBlockSkeleton from "./HeaderBlockSkeleton.jsx";
import LifestyleHeaderSkeleton from "./LifestyleHeaderSkeleton.jsx";

const BOTYSkeleton = () => (
  <SkeletonWrapper className="visitor bookofyear">
    <HeaderBlockSkeleton className="-headerWrapper" tagType="section">
      <div className="innerContent">
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </HeaderBlockSkeleton>
    <LifestyleHeaderSkeleton style={{ backgroundColor: "#C9CCD1" }}>
      <section className="-winnerCopy" key={"winner-copy"}>
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </section>
    </LifestyleHeaderSkeleton>

    <div className="-nominees">
      <div className="-header">
        <H3 />
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
      <ul className="booksGrid">
        {new Array(4).fill(0).map((item, i) => (
          <li className="-bookListItem" key={i}>
            <Jacket />
          </li>
        ))}
      </ul>
    </div>
    <section style={{ backgroundColor: "#C9CCD1" }}>
      <div className="content -narrow center">
        <InvisibleBlock width="80px" height="80px" />
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </section>
  </SkeletonWrapper>
);

export default BOTYSkeleton;
