import React, { useRef, useState, useEffect } from "react";

interface RequestQueueValue {
  getData: GetData;
  sync: any;
}

interface ProviderProps {
  ssr?: any;
  initialData?: any;
}

interface Obj {
  [key: string]: any;
}

interface RequestRef {
  ssr: Obj;
  requests: Obj;
  pending: Set<string>;
}

interface RequestResponse {
  pending: boolean;
  error?: any;
  data?: any;
}

interface GetData {
  (
    resourceName: string,
    key: number | string | null | undefined,
    loader: any,
    forceRefresh?: boolean,
  ): RequestResponse;
}

const RequestQueueContext = React.createContext<RequestQueueValue>({
  getData: () => ({ pending: true, error: null, data: null }),
  sync: () => null,
});

const RequestQueueProvider: React.FC<ProviderProps> = ({
  children,
  ssr = {},
  initialData = {},
}) => {
  const requestRef = useRef<RequestRef>({
    ssr,
    requests: {},
    pending: new Set(),
  });
  const localRef = useRef({ mounted: false, syncing: false });
  const [data, setData] = useState(ssr.data || initialData);

  const getData = (
    resourceName: string,
    key: any,
    loader: any,
    forceRefresh = false,
  ): RequestResponse => {
    // If we already have the data, return it
    if (!forceRefresh && data?.[resourceName]?.[key]) {
      return data[resourceName][key];
    }
    // We are loading this resource. Set to pending, will check
    // again next render.
    if (requestRef.current.pending.has(resourceName)) {
      return {
        pending: true,
        error: null,
        data: null,
      };
    }
    // else add to requests list for that resource
    if (!requestRef.current.requests[resourceName]) {
      requestRef.current.requests[resourceName] = { keys: {}, loader };
    }
    requestRef.current.ssr.dirty = true; // Flag for SSR
    requestRef.current.requests[resourceName].keys[key] = true;
    return {
      pending: true,
      error: null,
      data: null,
    };
  };

  const processResource = async (resourceName: string) => {
    const { requests } = requestRef.current;
    const resource = requests[resourceName];

    requestRef.current.pending.add(resourceName);
    const keys = Object.keys(resource.keys);
    resource.keys = {};
    try {
      if (keys.length === 0) {
        return null;
      }
      const loaded = await resource.loader(keys);
      return keys.reduce(
        (newData, key, i) => ({
          ...newData,
          [key]: loaded[i],
        }),
        {},
      );
    } catch (err) {
      /* Since loader needs to handle an array of keys, assume
        a full failure for entire array */
      return keys.reduce(
        (errData, key) => ({
          ...errData,
          [key]: { pending: false, error: err, data: null },
        }),
        {},
      );
    } finally {
      requestRef.current.pending.delete(resourceName);
    }
  };

  const processRequests = async (oldData: any) => {
    const { requests } = requestRef.current;
    const resources = Object.keys(requests);
    const responses = await Promise.all(resources.map(processResource));
    if (responses.filter((r) => r).length === 0) {
      return oldData;
    }
    const updateObject = { ...oldData };
    responses.forEach((response, i) => {
      if (response) {
        if (!updateObject[resources[i]]) {
          updateObject[resources[i]] = {};
        }
        Object.assign(updateObject[resources[i]], response);
      }
    });
    return updateObject;
  };

  // Expose the processRequests function to server
  // for SSR.
  requestRef.current.ssr.processRequests = processRequests;

  const hasRequests = () => {
    const { requests } = requestRef.current;
    for (const resource of Object.keys(requests)) {
      if (Object.keys(requests[resource].keys).length > 0) {
        return true;
      }
    }
    return false;
  };

  const sync = async () => {
    if (!localRef.current.syncing) {
      localRef.current.syncing = true;
      let newData = data;
      while (hasRequests()) {
        newData = await processRequests(newData);
      }
      localRef.current.syncing = false;
      if (localRef.current.mounted && newData !== data) {
        setData(newData);
      }
    }
  };

  useEffect(() => {
    const { current } = localRef;
    current.mounted = true;
    current.syncing = false;
    return () => {
      current.mounted = false; // Prevent state update on unmounted component
    };
  });

  return (
    <RequestQueueContext.Provider value={{ getData, sync }}>
      {children}
    </RequestQueueContext.Provider>
  );
};

export { RequestQueueContext, RequestQueueProvider };
