import React, { useContext, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../css/Consts.js";
import ClickTracker from "../../ui/elements/ClickTracker";
import { useWindowSize } from "../hooks";
import { getLegalDocsStatus } from "../../state/termsOfUse/api";
import { RegionContext } from "../../ui/contexts/RegionContext";
import { useAsyncEffect } from "../../ui/hooks/useAsyncEffect";
import { connect } from "react-redux";
import { AppState } from "../../types";
import AutoRenewFinePrint from "../../ui/components/AutoRenewFinePrint.jsx";
import { snesRedirect } from "../../utils/snesRedirect";

interface IPTermsBlock {
  idAmend?: string;
  top?: number;
  bottom?: number;
  knockout?: boolean;
  maxWidth?: number;
  isCentered?: boolean;
  cta?: string;
  applePayCta?: string;
  copy?: string;
  canShowCanadaCopy?: boolean;
  mustShowTerms?: boolean;
  customPlan?: any;
  showAutoRenewFinePrint?: boolean;
  account?: any;
  showApplePayFinePrint?: boolean;
  showNegativeOptionRuleFinePrint?: boolean;
}

const TermsAgreementBlock = ({
  idAmend,
  top = 20,
  bottom = 20,
  knockout,
  isCentered,
  cta,
  copy,
  canShowCanadaCopy,
  mustShowTerms = false,
  account,
  customPlan,
  showAutoRenewFinePrint,
  showApplePayFinePrint,
  applePayCta,
  showNegativeOptionRuleFinePrint = false,
}: IPTermsBlock) => {
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const [termsBody, setTermsBody] = useState(<></>);
  const { canadaMode } = useContext(RegionContext);
  let isCanada: boolean;

  if (account && account.countryCode) {
    isCanada = account.countryCode === "CA";
  } else {
    isCanada = canadaMode;
  }

  let ctaText;
  if (copy) {
    ctaText = isMobile ? `tapping ${copy}` : `clicking ${copy}`;
  } else if (cta) {
    ctaText = isMobile ? `tapping "${cta}"` : `clicking "${cta}"`;
  } else {
    ctaText = "continuing";
  }

  const showCanadaCopy = isCanada && canShowCanadaCopy;
  const agreementCopy = showCanadaCopy
    ? `By ${ctaText}, you agree to receiving emails from Book of the Month about updates, products, promotions, and other services. You are also agreeing to the${" "}`
    : `By ${ctaText}, you agree to Book of the Month’s${" "}`;

  const finePrint = (
    <StyledTermsWrapper top={top} bottom={bottom}>
      <h5
        className={`${isMobile ? "xSmallText" : "miniText"}`}
        style={{
          color: knockout ? "var(--color-white)" : "var(--color-gray5)",
          textAlign: isCentered ? "center" : "start",
        }}
      >
        <AutoRenewFinePrint
          customPlan={customPlan}
          showApplePayFinePrint={showApplePayFinePrint}
          isMobile={isMobile}
          showingTerms={false}
          applePayCta={applePayCta}
          showNegativeOptionRuleFinePrint={showNegativeOptionRuleFinePrint}
        />
      </h5>
    </StyledTermsWrapper>
  );

  const termsBlock = (
    <StyledTermsWrapper top={top} bottom={bottom}>
      <h5
        className={`${isMobile ? "xSmallText" : "miniText"}`}
        style={{
          color: knockout ? "var(--color-white)" : "var(--color-gray5)",
          textAlign: isCentered ? "center" : "start",
        }}
      >
        {agreementCopy}
        <ClickTracker
          ctaType={"link"}
          id={`link-tos-${idAmend}`}
          size={isMobile ? 12 : 14}
          style={"primary fullWidth"}
          handleClick={() => {
            snesRedirect("snes", "/terms-of-membership", true);
          }}
          isBold={true}
          color={knockout ? "var(--color-white)" : "var(--color-primary5)"}
          underlined={knockout}
          title={"Terms of use"}
          logClickData={"click_see_trems_of_membership"}
        />{" "}
        and{" "}
        <ClickTracker
          ctaType={"link"}
          id={`link-pp-${idAmend}`}
          size={isMobile ? 12 : 14}
          style={"primary fullWidth"}
          handleClick={() => {
            snesRedirect("snes", "/privacy-policy", true);
          }}
          isBold={true}
          color={knockout ? COLORS.white : COLORS.primary5}
          underlined={knockout}
          title={"Privacy policy"}
          logClickData={"click_see_privacy_policy"}
        />
        .{" "}
        {showAutoRenewFinePrint && (
          <AutoRenewFinePrint
            customPlan={customPlan}
            showApplePayFinePrint={showApplePayFinePrint}
            applePayCta={applePayCta}
            isMobile={isMobile}
            showingTerms={true}
            showNegativeOptionRuleFinePrint={showNegativeOptionRuleFinePrint}
          />
        )}
      </h5>
    </StyledTermsWrapper>
  );

  useAsyncEffect(async () => {
    let legalDocsStatus;
    if (account) {
      legalDocsStatus = await getLegalDocsStatus(isCanada ? "CA" : "US");
    } else {
      legalDocsStatus = ["privacy_policy", "terms_of_use"];
    }
    return () => {
      if (Boolean(!legalDocsStatus?.length) && !mustShowTerms) {
        if (showAutoRenewFinePrint && !showNegativeOptionRuleFinePrint) {
          setTermsBody(finePrint);
        }
      } else {
        setTermsBody(termsBlock);
      }
    };
  }, [
    isCanada,
    isMobile,
    customPlan,
    cta,
    showApplePayFinePrint,
    showNegativeOptionRuleFinePrint,
  ]);

  return <>{termsBody}</>;
};

export const StyledTermsWrapper = styled.div.attrs({
  className: "sc-StyledTermsWrapper" as string,
})<IPTermsBlock>`
  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
`;

function mapStateToProps(state: AppState) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(TermsAgreementBlock);
