export const ENROLLMENT_SOURCE = "Enrollment Source";
export const ENROLLMENT_CONSIDERATION = "Enrollment Consideration";
export const VIRTUAL_BOOK_TOUR = "Virtual Book Tour";
export const AUDIOBOOK = "Audiobook";
export const OCCUPATION = "Occupation";
export const AUDIOBOOKS_PLATFORM = "Audiobooks Platform";
export const BOOK_FORMAT = "Book Format";
export const AUDIOBOOK_LISTENING = "Audiobook Listening";
export const GIFT_PURCHASE = "Gift Purchase Survey";
export const SKIP = "Skip Survey";
export const TOP_CANCEL_REASON = "Top cancel reason";
export const PREFER_TO_GET_BOOKS = "Where do you prefer to get books";
export const SUBSCRIPTION_MODEL_FEELINGS = "Subscription model feelings";
export const WHY_DISLIKE_BOOKS = "Why you dislike our books";
export const PREFERRED_GENRES = "Preferred genres";
export const PREFERRED_BOOKS = "Preferred books";
export const PREFERRED_BOOK_FORMAT = "Preferred book format";
export const CANCEL_REASON = "Cancellation Reason";
export const CANCEL_SECOND_PAGE = "Cancellation Second Page";
