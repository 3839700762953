import React from "react";
import SkeletonWrapper, { Jacket, IMG } from "./SkeletonWrapper";

const JacketListSkeleton = ({
  wrapperClassName,
  ulClassName,
  numDisplay = 5,
}) => (
  <SkeletonWrapper className={`-jacketList ${wrapperClassName}`}>
    <ul className={ulClassName || ""}>
      {new Array(numDisplay).fill(0).map((ignore, i) => (
        <li key={i}>
          <Jacket>
            <IMG />
          </Jacket>
        </li>
      ))}
    </ul>
  </SkeletonWrapper>
);

export default JacketListSkeleton;
