/**
 * Loyalty modals content for success step.
 * Child of LoyaltyModal.jsx
 */

import React from "react";

const LoyaltyStepSuccess = (props) => {
  const icon = props.forRelationshipTier
    ? `https://static.bookofthemonth.com/Relationship/Icons/Badge/BFF_Icon.svg`
    : `https://static.bookofthemonth.com/loyalty/logos/bff_badge.png`;

  return (
    <div className="loyaltyStep -success">
      <img src={icon} alt="Book of the Month BFF Badge" />
      <div className="-content">
        <h4>You’re in.</h4>
        <p>
          Check your BFF status and see the benefits you’ll{" "}
          <span className="nowrap">get throughout the year.</span>
        </p>
      </div>
      <div className="-actions">
        <button className="primary" onClick={() => props.closeModal()}>
          Woo!
        </button>
      </div>
    </div>
  );
};

export default LoyaltyStepSuccess;
