import { combineReducers } from "redux";
import * as actions from "./actions";

const list = (state = [], action) => {
  switch (action.type) {
    case actions.SET_REVIEWS:
      return action.payload;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case actions.SET_REVIEWS_LOADING:
      return false;
    case actions.SET_REVIEWS_LOADING_SUCCESS:
    case actions.SET_REVIEWS_LOADING_FAIL:
      return true;
  }
  return state;
};

export default combineReducers({
  list,
  loaded,
});
