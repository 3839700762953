import * as actions from "./actions";

export default function reducer(state = null, action) {
  switch (action.type) {
    case actions.SET_BOX:
      return action.payload;
    default:
      return state;
  }
}

export function creditNavReducer(state = { type: null, status: null }, action) {
  switch (action.type) {
    case actions.SET_CREDIT_NAV_STATUS:
      return action.payload;
    default:
      return state;
  }
}
