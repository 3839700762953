import * as actions from "State/survey/actions";

export const setSurveys = (list) => {
  return {
    type: actions.SET_SURVEYS,
    list,
  };
};

export const setAllSurveys = (surveys) => {
  return {
    type: actions.SET_ALL_SURVEYS,
    surveys,
  };
};

export const requestSurveys = () => {
  return {
    type: actions.REQUEST_SURVEYS,
  };
};

export const setSurveysLoaded = () => {
  return {
    type: actions.SURVEYS_LOADED,
  };
};

export const setSurveyResponse = (response) => {
  return {
    type: actions.SET_SURVEY_RESPONSE,
    response,
  };
};

export const updateSurveyResponse = (response) => {
  return {
    type: actions.UPDATE_SURVEY_RESPONSE,
    response,
  };
};

export const getAccountSurveyResponse = (response) => {
  return {
    type: actions.GET_ACCOUNT_SURVEY_RESPONSE,
    response,
  };
};
