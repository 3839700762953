import * as actions from "./actions";

export function setSort(type = null, data = null) {
  return {
    type: actions.SET_SORT,
    payload: { type, data },
  };
}

export function setOverlay(name = "", type = "", data = {}) {
  return {
    type: actions.SET_OVERLAY,
    payload: { name, type, data },
  };
}
export function setSiteResponse(response = {}) {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SITE_RESPONSE,
      payload: { showResponse: true, response },
    });
    if (setTimeout) {
      setTimeout(() => dispatch(clearSiteResponse(response)), 6000);
    }
  };
}

export function clearSiteResponse(response) {
  return (dispatch) => {
    dispatch({
      type: actions.CLEAR_SITE_RESPONSE,
      payload: { showResponse: false, response },
    });
  };
}

export function setSiteMessage(content = [], type = null) {
  return (dispatch) => {
    dispatch({
      type: actions.SET_SITE_MESSAGE,
      payload: { content, type, showMessage: true },
    });
  };
}

export function clearSiteMessage(message) {
  return (dispatch) => {
    dispatch({
      type: actions.CLEAR_SITE_MESSAGE,
      payload: { showMessage: false, message },
    });
  };
}

export function showBoxCoupon(coupon) {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_BOX_COUPON,
      payload: coupon,
    });
  };
}

export function closeBoxCoupon() {
  return (dispatch) => {
    dispatch({
      type: actions.CLOSE_BOX_COUPON,
    });
  };
}

export function letItSnow(snowing) {
  // for holiday
  return {
    type: actions.LET_IT_SNOW,
    payload: snowing,
  };
}

export function setJustCompletedRC(completed) {
  return {
    type: actions.SET_JUST_COMPLETED_RC,
    payload: completed,
  };
}

export function setRCBadgesStatus(seenAnimation = null, justUpdated = []) {
  // seenAnimation = Already seen for stopping animation on page revisits.
  // justUpdated: Array of badges just updated.
  return {
    type: actions.SET_RC_BADGES_STATUS,
    payload: { seenAnimation, justUpdated },
  };
}
