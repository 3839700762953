/**
 * Mobile hamburger icon.
 * Toggles the view of the mobile nav or closes active modals.
 * Child of Header.jsx
 */

import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { push, goBack } from "connected-react-router";

import { setOverlay } from "State/ui/creators";
import { hideModal } from "State/activeModal/creators";
import { useCategory } from "UI/hooks";
import { snesRedirect } from "Utils/snesRedirect";

const Burger = ({ isDarkMode, ...props }) => {
  let isActiveBook = props.path.match(/-(\d{2,})$/);
  const { data: features } = useCategory("current-features");

  const accountSubs = ["/my-account/orders?isDetail"];
  const bookshelfSubs = ["rate", "my-rating", "reading-challenge"];
  const isAccountSub = !!accountSubs.find((as) => as === props.location);
  const aLocations = props?.location?.split?.("/");
  const isBookshelfSub = !!bookshelfSubs?.some?.((bs) =>
    aLocations?.includes(bs),
  );
  const isChangeAvatar = props?.location == "/avatar";
  const closeEverything = () => {
    if (typeof document !== "undefined") {
      document.getElementById("bodyWrapper").className = "modalClosed";
    }
  };

  const navigateBack = (path) => {
    if (!path) {
      props.goBack();
    } else if (path == "forBookshelfSub") {
      const currentPath = props.path.split("/");
      if (currentPath.indexOf("my-rating") > -1) {
        props.push("/bookshelf");
      } else {
        props.goBack();
      }
    } else {
      props.hideModal();
      props.setOverlay("");
      if (path === "/box") {
        snesRedirect("snes", "/box");
      } else {
        props.push(path);
      }
    }
  };

  const action = () => {
    const { activeModal, activeJudge, isLoggedIn, isLanding } = props;

    if (props.location == "/my-account/orders?isDetail") {
      navigateBack("/my-account/orders");
    } else if (isBookshelfSub) {
      navigateBack("forBookshelfSub");
    } else if (activeModal == "boxEdit") {
      navigateBack("/box");
    } else if (activeModal == "accountPage") {
      navigateBack("/my-account");
    } else if (activeModal == "mobileRenewalPlans") {
      navigateBack("/rejoin");
    } else if (isAccountSub) {
      navigateBack("/my-account/orders");
    } else if (isActiveBook && isLanding.current == false) {
      let backPath;
      if (
        isLoggedIn &&
        features?.children?.some((feature) => feature == isActiveBook[1])
      ) {
        backPath = "/the-best-new-books";
      }
      snesRedirect("snes", "/the-best-new-books");
      navigateBack(backPath);
    } else if (isChangeAvatar) {
      navigateBack("/my-account/account");
    } else if (activeJudge) {
      closeEverything();
    } else {
      if (props.showMobileNav) {
        props.setHideMobileNav();
      } else {
        props.setShowMobileNav();
      }
    }
  };

  return (
    <div
      className={
        "burger" +
        (isDarkMode ? " darkMode" : "") +
        (props.showMobileNav ? " open" : "") +
        (props.activeModal ||
        (isActiveBook && props.isLanding?.current == false) ||
        isAccountSub ||
        isChangeAvatar ||
        isBookshelfSub
          ? " back"
          : "") +
        (props.isLoggedIn ? " -loggedIn" : "")
      }
      onClick={action}
      id="burger"
    >
      <span className="burgerLines" />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
    isLoggedIn: state.policy.type !== "Visitor",
    activeModal: state.activeModal,
    overlay: state.ui ? state.ui.overlay : null,
    origin: state.origin,
    features: [],
    faves: state.faves,
    isMember:
      state.account &&
      state.policy.type !== "Visitor" &&
      state.policy.type !== "Lead",
    location: state.analytics.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, goBack, hideModal, setOverlay }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Burger));
