import globalScope from "../../global"; // For tests, ugh

export async function getBoty(year: string | number) {
  const response = await fetch(`${global.apiHost}/api/v2/botm/boty/${year}`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const botyYear = await response.json();
  return botyYear;
}

export async function addBotyVote(pdpId: number, year: string | number) {
  const response = await fetch(`${global.apiHost}/api/v2/botm/boty/${year}`, {
    method: "post",
    body: JSON.stringify({ pdpId, year }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const botyVote = await response.json();
  return botyVote;
}

export async function getFinalists(year: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/boty/finalists/${year}`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const finalists = await response.json();
  return finalists;
}

export async function bffShipsFreeBotyWebstoreImages(year: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/boty/bff_boty_webstore_images/${year}`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const freeBotyImages = await response.json();
  return freeBotyImages;
}
