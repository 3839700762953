import * as React from "react";

interface IEmotionalIcon {
  handleClick?: (...args: any) => void;
  showBackground?: boolean;
  color?: string;
  width?: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}
export const EmotionalIcon: React.FC<IEmotionalIcon> = ({
  showBackground = false,
  color = "#D2E9F8",
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  width = 64,
  height = 64,
}) => (
  <svg
    x="0px"
    y="0px"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 64 64"
    fill="none"
    style={{
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left,
    }}
  >
    {showBackground && (
      <path
        fill={color}
        d="M47.8,47.6c1.5-0.2,2.8-1.3,3.2-2.8c0.6-2.3,1-4.6,1.1-6.9c1.4-6.3,0.5-13-2.5-18.7c-1-1.9-2.3-3.7-3.8-5.3
			C39.9,6.8,30,8.7,22.5,12.5C11.3,18.2,6.9,31.8,12.5,43c2.6,5.2,7.1,9.1,12.5,11.1C35.1,57.8,43.1,54.2,47.8,47.6z"
      />
    )}
    <path d="M38.7,34.9c1.3,0,2.5-0.7,3-1.9h-5.8C36.4,34.1,37.4,34.8,38.7,34.9L38.7,34.9z" />
    <path d="M23.6,34.9c1.3,0.1,2.5-0.7,3-1.9h-5.8C21.4,34.1,22.4,34.8,23.6,34.9L23.6,34.9z" />

    <path
      fill="black"
      d="M23.7,34.9c-1.2,0-2.3-0.8-2.8-1.9h-2.1c0.6,2.2,2.5,3.8,4.8,3.9h0.1c2.4,0,4.4-1.6,5-3.9h-2.1
				C26.1,34.2,24.9,34.9,23.7,34.9z"
    />
    <path
      fill="black"
      d="M38.6,34.9c-1.2,0-2.3-0.8-2.7-1.9h-2.1c0.6,2.2,2.5,3.8,4.8,3.9h0.1c2.4,0,4.4-1.6,5-3.9h-2.1
				C41.1,34.2,39.9,34.9,38.6,34.9z"
    />
    <path
      fill="black"
      d="M54,32c0-5.6-2.2-10.9-6.2-14.8c-4-4-9.2-6.1-14.8-6.1l0,0c-5.6,0-10.9,2.2-14.8,6.2c-4,4-6.1,9.2-6.1,14.9
				c0,11.6,9.4,21,21,21l0,0c0.2,0,0.4-0.1,0.6-0.2c1.2,1.4,2.9,2.2,4.9,2.2c3.3,0,6-2.5,6.4-5.7C50.6,45.4,54,39,54,32z M38.5,53
				C36,53,34,51,34,48.5c0-1.7,2.5-5.4,4.5-8c2,2.5,4.5,6.3,4.5,8C42.9,51,40.9,53,38.5,53z M44.6,47c-1.1-3.4-4.9-8.1-5.4-8.7
				l-0.8-0.9l-0.8,0.9c-0.6,0.7-5.7,6.9-5.7,10.2c0,0.9,0.2,1.7,0.5,2.4C22.2,50.7,14,42.3,14,32c0-5.1,2-9.8,5.6-13.4
				C23.1,15,27.9,13,33,13l0,0c5.1,0,9.8,2,13.4,5.6C50,22.2,52,26.9,52,32C52,37.9,49.3,43.4,44.6,47z"
    />
  </svg>
);
