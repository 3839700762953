import { combineReducers } from "redux";
import * as actions from "./actions";

const loaded = function reducer(state = false, action) {
  switch (action.type) {
    case actions.SET_STORE_LOADED:
      return true;
    default:
      return state;
  }
};

const readyToRender = function reducer(state = false, action) {
  switch (action.type) {
    case actions.SET_READY_TO_RENDER:
      return true;
    default:
      return state;
  }
};

const loginAttempted = function reducer(state = false, action) {
  switch (action.type) {
    case actions.SET_LOGIN_ATTEMPT:
      return true;
    default:
      return state;
  }
};

const landingTime = function reducer(state = null, action) {
  switch (action.type) {
    case actions.SET_LANDING_TIME:
      return state || Date.now();
    default:
      return state;
  }
};

const courtesyPassword = function reducer(state = null, action) {
  switch (action.type) {
    case actions.SET_COURTESY_PASSWORD:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  loaded,
  loginAttempted,
  landingTime,
  readyToRender,
  courtesyPassword,
});
