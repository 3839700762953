export const SET_CANCEL_STEP_SKIP = "SET_CANCEL_STEP_SKIP";
export const SET_CANCEL_STEP_SURVEY = "SET_CANCEL_STEP_SURVEY";
export const SET_CANCEL_STEP_CREDITS = "SET_CANCEL_STEP_CREDITS";
export const SET_CANCEL_STEP_STAY = "SET_CANCEL_STEP_STAY";
export const SET_CANCEL_STEP_CONFIRM = "SET_CANCEL_STEP_CONFIRM";
export const SET_CANCEL_STEP_SUCCESS = "SET_CANCEL_STEP_SUCCESS";

export const RESET_CANCEL_STATUS = "RESET_CANCEL_STATUS";
export const SET_CANCEL_STATUS_PENDING = "SET_CANCEL_STATUS_PENDING";
export const SET_CANCEL_STATUS_SUCCESS = "SET_CANCEL_STATUS_SUCCESS";
export const SET_CANCEL_STATUS_FAILURE = "SET_CANCEL_STATUS_FAILURE";
export const CANCEL_STATUS_PENDING = "pending";
export const CANCEL_STATUS_SUCCESS = "success";
export const CANCEL_STATUS_FAILURE = "failure";
