import getTax from "../../../../utils/getTax";
import { IPromo } from "../../../../../../types/promo";

export const calculateGiftPurchaseTax = (
  planPrice: number,
  taxRate: number,
  promo?: IPromo,
) => {
  let price = planPrice;
  if (promo && promo.value) {
    switch (promo.adjustmentType) {
      case "Fixed Price":
        price = promo.value;
        break;
      case "Fixed Off":
        price = planPrice - promo.value;
        break;
      default:
        price = promo.value;
    }
  }

  const tax = getTax(price, taxRate);
  return tax;
};

export const generateDates = (month: number) => {
  let longMonths = [1, 3, 5, 7, 8, 10, 12].includes(month);
  let shortMonths = [4, 6, 9, 11].includes(month);
  let feb = month == 2;
  let dates = [];
  let lastDate = longMonths ? 31 : shortMonths ? 30 : feb ? 28 : 0;

  for (let i = 0; i < lastDate; i++) {
    dates.push({ label: `${i + 1}`, value: `${i + 1}` }); //`${i + 1}`
  }
  return dates;
};
