import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { updateAccount } from "State/account/flows";
import ClickTracker from "UI/elements/ClickTracker";
import DynamicInput from "UI/elements/form/DynamicInput";
import DynamicSelect from "UI/elements/form/DynamicSelect";
import { MonthsV3 } from "UI/elements/form/SelectData";
import { validation } from "UI/components/forms/Form.jsx";
import { setOverlay } from "State/ui/creators";
import { logVisit } from "State/analytics/flows";
import { withStoreData } from "UI/hoc/withStoreData";
import { useRegion } from "UI/hooks/useRegion";

const FieldsetBirthday = (props) => {
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [activeField, setActiveField] = useState("");
  const [invalidFields, setInvalidFields] = useState([]);
  const [birthdayMonth, setBirthdayMonth] = useState(
    props.account?.birthday?.month || "",
  );
  const [birthdayYear, setBirthdayYear] = useState(
    props.account?.birthday?.year || null,
  );
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear;
  const { countryCode } = useRegion();

  useEffect(() => {
    logVisit({
      store: { getState: () => props.store },
      countryCode: props.account?.countryCode || countryCode,
    });
  }, []);

  const setOnFocus = (input) => {
    setActiveField(input);
  };

  const onBlur = (field) => {
    setOnFocus("");
  };

  const getAndSetInvalidFields = (errorField) => {
    if (!invalidFields.includes(errorField)) {
      setInvalidFields([errorField, ...invalidFields]);
    }
    setSubmitting(false);
  };

  const handleMonthSelection = (e) => {
    setBirthdayMonth(e);
    if (props?.monthCB) {
      props.monthCB(e);
    }
  };

  const checkAge = () => {
    const dob = new Date(`${birthdayMonth}/1/${birthdayYear}`);
    const dob_diff = Date.now() - dob.getTime();
    const age_date = new Date(dob_diff);
    const year = age_date.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age;
  };

  const setBirthday = async () => {
    const successFunction = () => {
      props.nextAction();
    };
    const failFunction = (err) => {
      console.log("logged- error,", err);
      setError(err);
    };
    logVisit({ getState: () => props.store });

    if (birthdayMonth == -1) {
      setBirthdayMonth("");
    }

    if (
      (birthdayYear?.length && birthdayMonth?.length) ||
      (props.account?.birthday?.month && props.account?.birthday?.year)
    ) {
      const age = checkAge();
      if (age < 13) {
        props.setOverlay("AgeVerificationModal", "", { age });
        getAndSetInvalidFields("year");
        return false;
      }
    }

    if (
      (birthdayMonth === null ||
        birthdayMonth === "" ||
        birthdayMonth === -1) &&
      (birthdayYear == null || birthdayYear == "")
    ) {
      if (props.optional) {
        props.nextAction();
      }
      if (props.closeModal) {
        props.closeModal();
      }
    } else {
      if (invalidFields.length) {
        return false;
      }
      // don't let them press continue without submitting birthdayMonth
      if (
        !(
          typeof birthdayMonth !== undefined &&
          ((typeof birthdayMonth !== "string" && birthdayMonth >= 0) ||
            birthdayMonth?.length)
        )
      ) {
        getAndSetInvalidFields("month");
        return false;
      }

      //if they entered something in the optional year, verify it
      if (
        birthdayYear?.length &&
        (!validation["year"].test(birthdayYear) || birthdayYear > maxYear)
      ) {
        getAndSetInvalidFields("year");
        return false;
      }
      setSubmitting(true);
      const { storeData } = props;
      const { store } = storeData;
      await props.updateAccount(
        {
          ...props.account,
          birthday: { month: birthdayMonth - 1, year: birthdayYear },
        },
        store,
        successFunction,
        failFunction,
      );
    }
  };

  const clearErrorField = (field) => {
    if (invalidFields.includes(field)) {
      const newInvalidFields = invalidFields.filter((f) => f !== field);
      setInvalidFields(newInvalidFields);
    }
  };

  const checkIfValidYear = (y) => {
    if (!validation["year"].test(y) || y > maxYear) {
      getAndSetInvalidFields("year");
    } else {
      if (invalidFields.includes("year")) {
        clearErrorField("year");
      }
    }
  };

  return (
    <>
      <StyledFieldsetWrapper>
        <DynamicSelect
          forwardRef={monthRef}
          placeholder={"Month"}
          label="Month"
          onChangeSelection={(month) => {
            handleMonthSelection(month);
          }}
          selectedValue={birthdayMonth}
          accessibility={true}
          accessibilityLabel={`BirthdatMonth`}
          selectOptions={MonthsV3}
          isInFocus={activeField == "month"}
          onFocus={() => setOnFocus("month")}
          onBlur={() => onBlur("month")}
          error={
            invalidFields.includes("state") ? "Please select a state." : null
          }
          size={"half"}
        />

        <DynamicInput
          inputType={"number"}
          minNumber={"1900"}
          maxNumber={maxYear}
          placeholder={activeField == "year" ? "YYYY" : "Year"}
          label="Year"
          value={birthdayYear}
          accessibilityLabel={`Birthday year`}
          onChangeText={(year) => {
            setBirthdayYear(year);
            checkIfValidYear(year);
          }}
          noSpaces
          onFocus={() => setOnFocus("year")}
          onBlur={() => onBlur("year")}
          isInFocus={activeField == "year"}
          clearIt={() => setBirthdayYear("")}
          error={
            invalidFields.includes("year") ? "Please enter a valid year." : null
          }
          forwardRef={yearRef}
          name={"birthday-year"}
          inputId={"birthday-year"}
          size={"half"}
          bottom={48}
        />
      </StyledFieldsetWrapper>

      <div className="-actions">
        <ClickTracker
          ctaType={"button"}
          id="shipping-submit"
          style={`primary ${props.isFullWidthButton ? " -fullWidth" : ""}`}
          handleClick={
            birthdayMonth && birthdayYear && !invalidFields?.length
              ? () => setBirthday()
              : null
          }
          isPending={submitting}
          title={props.cta}
          isDisabled={!birthdayMonth || !birthdayYear || invalidFields?.length}
          logClickData={props.trackLinkLabel}
          top={props.postCycleModal ? 20 : 0}
        />
      </div>
    </>
  );
};

export const StyledFieldsetWrapper = styled.div.attrs({
  className: "sc-StyledFieldsetWrapper",
})`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 400px;
  margin: 0 auto;

  @media screen and (max-width: 680px) {
    width: 100%;
    max-width: 100%;
  }
`;

function mapStateToProps(state) {
  return {
    account: state.account,
    store: state,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateAccount, setOverlay }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(FieldsetBirthday),
);
