import * as actions from "State/analytics/actions";

export default function reducer(state = { location: "/" }, action) {
  if (action.type === actions.INITIAL_LOCATION) {
    return { ...state, ...action.payload };
  } else if (action.type == actions.LOCATION_CHANGE) {
    return { ...state, ...action.payload };
  } else if (action.type === actions.SET_ENTRYPOINT) {
    return { ...state, ...action.payload };
  } else if (action.type === actions.SET_PDP_GATE) {
    return { ...state, ...action.payload };
  } else if (action.type === actions.SET_REFERRER) {
    return { ...state, ...action.payload };
  } else if (action.type === actions.IS_PDP) {
    return { ...state, isPdp: action.payload };
  } else {
    return state;
  }
}
