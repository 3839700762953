import * as actions from "./actions";

import { post } from "Utils/net.js";
import { getCookies, getQueryString } from "Utils/cookie.js";
import { getAllVariants } from "UI/skeleton/Experiments.jsx";
import { getVisitorId } from "Utils/visitorId";
import { logVisit } from "./logVisitFlow";
import { matchedKeys } from "Utils/index";

export function setLocation(
  store,
  location,
  query,
  origin,
  basename,
  state = {},
  countryCode,
) {
  logVisit({ store, location, countryCode });
  let { account, analytics } = store.getState();
  if (!analytics.entryPoint) {
    store.dispatch({
      type: actions.SET_ENTRYPOINT,
      payload: { entryPoint: location },
    });
    store.dispatch({
      type: actions.SET_PDP_GATE,
      payload: { showGate: location.match(/-\d{3}$/) ? false : true },
    });
  } else {
    if (!analytics.showGate) {
      store.dispatch({
        type: actions.SET_PDP_GATE,
        payload: { showGate: true },
      });
    }
  }
  store.dispatch({
    type: actions.LOCATION_CHANGE,
    payload: {
      location,
      query,
      account,
      basename,
      origin,
      state,
    },
  });
}

export async function logClick(clickData, store) {
  console.log("logged- logClick:", clickData);
  // Should only log in browser
  if (typeof document !== "undefined") {
    let { account } = store.getState() || {};
    const visitorId = getVisitorId();
    const hitTimeStamp = new Date();
    const cookies = getCookies();
    const query = getQueryString();

    const utm = matchedKeys(/^utm/, query);
    const ab = matchedKeys(/^ab_\d+$/, cookies);
    post("https://thb0wlgxld.execute-api.us-east-1.amazonaws.com/default/", {
      utm,
      ab,
      eventType: "click",
      visitorId,
      accountId: account?.id || null,
      hitTimeStamp,
      clickData,
      production: globalThis.xavier_env === "production",
      referrer: document.referrer,
    });
  }
}

export function setDimension(dimensionName, dimensionValue) {
  if (
    typeof window !== "undefined" &&
    window.dataLayer &&
    dimensionName &&
    dimensionValue
  ) {
    let dimensionObj = {};
    dimensionObj[dimensionName] = dimensionValue;
    window.dataLayer.push(dimensionObj);
  }
}

export function dataLayerTracking(event, trackingObj = {}, variables = {}) {
  /**
   *  trackingObj:
   *    'eventCategory'
   *    'eventAction'
   *    'eventLabel'
   */
  if (!(global && global.serving) && window) {
    // client side only
    window.dataLayer.push({
      event,
      ...trackingObj,
      ...variables,
    });
  }
}

export { logVisit };
