import * as actions from "./actions";

export function setAccountHistory(accountHistory) {
  return {
    type: actions.SET_ACCOUNT_HISTORY,
    payload: accountHistory,
  };
}

export function addToAccountHistory(accountHistoryRecord) {
  return {
    type: actions.ADD_TO_ACCOUNT_HISTORY,
    payload: accountHistoryRecord,
  };
}

export function cancelAccountHistoryRecord(accountHistoryRecord) {
  return {
    type: actions.CANCEL_ACCOUNT_HISTORY_RECORD,
    payload: accountHistoryRecord,
  };
}
