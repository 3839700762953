import React, { lazy, useContext, ReactElement } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";

import Authenticate from "UI/skeleton/Authenticate.jsx";
import Skeleton from "UI/loadingSkeletons/Index.jsx";

const CancelPause = loadable(
  () => import("UI/pages/cancel_pause/Wrapper.jsx"),
  { fallback: <Skeleton.UserFlowsSkeleton /> },
);

const Cancel = (() => [
  <Route path="/cancel-pause" component={CancelPause} />,
])();

export default Cancel;
