import React from "react";
import SkeletonWrapper from "./SkeletonWrapper.jsx";
import Spinner from "UI/elements/Spinner";

const LoadingCoinSkeleton = () => (
  <SkeletonWrapper className="content -loggingIn ">
    <section className="center" style={{ marginBottom: "60px" }}>
      <Spinner size={"xlarge"} />
    </section>
  </SkeletonWrapper>
);

export default LoadingCoinSkeleton;
