import { useContext, useEffect } from "react";
import { RequestQueueContext } from "../contexts/RequestQueue";
import {
  ProductThumbnail,
  ProductThumbnailAPIResponse,
} from "../../../../types/pdp";
import globalScope from "../../global"; // For tests, ugh

const getProducts = async (productIds: number[]) => {
  const res = await fetch(
    `${global.apiHost}/api/v2/botm/product?list=${productIds.join(",")}`,
  );
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return await res.json();
};

const useProductLoader = () => {
  const { getData, sync } = useContext(RequestQueueContext);
  useEffect(() => {
    sync();
  });

  return (productId: number) =>
    getData("product", productId, async (productIds: number[]) => {
      try {
        const products = await getProducts(productIds);
        return products.map((product: ProductThumbnail) => {
          return product
            ? { pending: false, error: null, data: product }
            : {
                pending: false,
                error: new Error("Product not found"),
                data: null,
              };
        });
      } catch (err) {
        return productIds.map((_) => ({
          pending: false,
          error: err,
          data: null,
        }));
      }
    });
};

const useProduct = (productId: number): ProductThumbnailAPIResponse => {
  const getProduct = useProductLoader();
  return getProduct(productId);
};

export { useProduct, useProductLoader };
