import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RegionContext } from "../contexts/RegionContext";
import { Modal } from "../containers/Modal";
import ClickTracker from "../elements/ClickTracker";
import {
  setSurveyResponse,
  getAccountSurveyResponse,
  getSurveyByName,
} from "../../state/survey/flows";
import { Dispatch, AppState } from "../../types";
import { PopulationType } from "../../../../types/survey";
import SurveyQuestions from "../components/SurveyQuestions.jsx";
import showAppFeature from "../../utils/showAppFeature";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { setOverlay } from "../../state/ui/creators";
import { getMessages } from "../../state/accountMessage/selectors";
import { Message } from "../../../../types/message";
import { markMessageAsSeen } from "../../state/accountMessage/flows";
import LoadingCoinSkeleton from "../loadingSkeletons/LoadingCoinSkeleton.jsx";
import { Variant } from "../skeleton/Experiments";
import * as SurveyNames from "../../utils/surveyNames";

type surveyResponse = {
  surveyId?: number;
  response?: number;
  source?: string;
  storeId?: number;
};
type IPrevResponse = {
  id: number;
  response?: string;
  source?: string;
  createdAt?: Date;
  storeId?: number;
  accountId?: number;
};
type responseOnClick = {
  surveyId: number;
  selectedQuestions: number[];
  klaviyoResponse: string;
};

type PostCycleSurveyWrapperProps = {
  getAccountSurveyResponse: (surveyId: number) => () => Promise<IPrevResponse>;
  setOverlay: (
    name?: string,
    type?: string,
    data?: Record<string, unknown>,
  ) => {
    type: string;
    payload: {
      name: string;
      type: string;
      data: Record<string, unknown>;
    };
  };
  exp125: number | undefined;
  markMessageAsSeen: (
    messageId: number,
  ) => (dispatch: Dispatch, getState: () => AppState) => Promise<void>;
} & MappedPostCycleProps;

type PostCycleSurveyProps = {
  removeOverlay: () => void;
  setSurveyResponse: (arg0: surveyResponse) => void;
  getSurveyByName: (name: string) => void;
} & MappedPostCycleProps;

const PostCycleAudiobooksModal: React.FC<PostCycleSurveyProps> = (props) => {
  const {
    getSurveyByName,
    setSurveyResponse,
    removeOverlay,
    surveyList,
    account,
  } = props;

  useEffect(() => {
    getSurveyByName(SurveyNames.AUDIOBOOK_LISTENING);
    getSurveyByName(SurveyNames.BOOK_FORMAT);
    getSurveyByName(SurveyNames.AUDIOBOOKS_PLATFORM);
  }, []);

  const [primarySurvey, setPrimarySurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.AUDIOBOOK_LISTENING) || null,
  );
  const [secondSurvey, setSecondSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.BOOK_FORMAT) || null,
  );
  const [thirdSurvey, setThirdSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.AUDIOBOOKS_PLATFORM) || null,
  );
  const [step, setStep] = useState(0);
  const [showThirdSurvey, setShowThirdSurvey] = useState(false);

  useEffect(() => {
    if (!primarySurvey) {
      setPrimarySurvey(
        surveyList.find((s) => s?.name == SurveyNames.AUDIOBOOK_LISTENING),
      );
    }
    if (!secondSurvey) {
      setSecondSurvey(
        surveyList.find((s) => s?.name == SurveyNames.BOOK_FORMAT),
      );
    }
    if (!thirdSurvey) {
      setThirdSurvey(
        surveyList.find((s) => s?.name == SurveyNames.AUDIOBOOKS_PLATFORM),
      );
    }
  }, [surveyList]);

  const nextStep = () => {
    if (step == 3) {
      removeOverlay();
    } else {
      setStep((step) => step + 1);
    }
  };

  const thirdSurveyFn = (d: responseOnClick) => {
    //Only show the 3rd survey if user selects these 2 options during 1st survey.
    if (
      (d && d.surveyId == 41 && d.klaviyoResponse == "Regularly") ||
      d.klaviyoResponse == "On occasion"
    ) {
      setShowThirdSurvey(true);
    }
  };

  const setContent = () => {
    let surveyId, hed, dek, parents, children, ctaText, surveyName, maxChoice;
    if (!step) {
      return (
        <StyledInnerContent>
          <h4>Audiobooks are here!</h4>
          <p>
            Please let us know your interest in audiobooks with these quick
            questions. Thanks in advance!
          </p>
          <StyledButtonWrapper>
            <ClickTracker
              ctaType={"button"}
              id="start"
              style={"primary fullWidth"}
              handleClick={nextStep}
              title={"Let’s go"}
              customStyles={{ marginTop: 32 }}
            />
          </StyledButtonWrapper>
        </StyledInnerContent>
      );
    } else if (step == 1) {
      surveyId = primarySurvey?.id;
      hed = "How often do you listen to audiobooks?";
      dek = "Please select one option.";
      children = primarySurvey?.surveyQuestions?.children || [];
      parents = primarySurvey?.surveyQuestions?.parents || [];
      ctaText = "Next";
      surveyName = primarySurvey?.name;
      maxChoice = 1;
    } else if (step == 2) {
      surveyId = secondSurvey?.id;
      (hed = "In which format do you plan to buy your BOTM books?"),
        (dek = "Please select one option.");
      children = secondSurvey?.surveyQuestions?.children || [];
      parents = secondSurvey?.surveyQuestions?.parents || [];
      ctaText = "Next";
      surveyName = secondSurvey?.name;
      maxChoice = 1;
    } else if (step == 3 && showThirdSurvey) {
      surveyId = thirdSurvey?.id;
      (hed = "Where do you currently get your audiobooks?"),
        (dek = "Select all that apply.");
      children = thirdSurvey?.surveyQuestions?.children || [];
      parents = thirdSurvey?.surveyQuestions?.parents || [];
      ctaText = "Submit";
      surveyName = thirdSurvey?.name;
      maxChoice = null;
    } else if (step == 3 && !showThirdSurvey) {
      return nextStep();
    }

    return (
      <StyledInnerContent id="StyledInnerContent">
        <h4>{hed}</h4>
        <p>{dek}</p>
        <StyledSurvey id="StyledSurvey">
          <SurveyQuestions
            surveyId={surveyId}
            surveyQuestions={{ parents, children }}
            account={account}
            buttonText={ctaText}
            multipleChoice={true}
            holdSurveySubmit={false}
            nextStep={nextStep}
            setSavedResponse={() => null}
            setSurveyResponse={setSurveyResponse}
            responseOnClick={(d: responseOnClick) => thirdSurveyFn(d)}
            dek={true}
            errorMessage=""
            mustComplete={true}
            maxChoice={maxChoice}
            surveyName={surveyName}
          />
        </StyledSurvey>
        <p className="label2">
          {step} of {showThirdSurvey || step == 1 ? "3" : "2"}
        </p>
      </StyledInnerContent>
    );
  };

  return (
    <Modal handleClose={() => null} showing={true} noClose={true}>
      <StyledModalWrapper>
        <picture>
          <img
            src={`//static.bookofthemonth.com/audiobooks/Audiobook_survey_icon.svg`}
            alt="Audiobook Headphones Icon"
          />
        </picture>
        {setContent()}
      </StyledModalWrapper>
    </Modal>
  );
};

const PostCycleAudiobooksModalWrapper: React.FC<
  PostCycleSurveyWrapperProps
> = ({
  setOverlay,
  markMessageAsSeen,
  getAccountSurveyResponse,
  appFeatures,
  overlay,
  path,
  postCycleSurveyMsg,
  exp125,
  account,
}) => {
  const [surveyResponded, setSurveyResponded] = useState("loading");
  const [notSeenCurrentCycle, setNotSeenCurrentCycle] = useState(false);
  const { locationHeader } = useContext(RegionContext);
  const countryCode =
    account?.countryCode || (locationHeader?.countryHeader as PopulationType);
  const postCycleSurveyFeature = appFeatures?.find(
    (f) => f?.feature == "Audiobook Post Cycle Response Surveys",
  );
  const showSurvey =
    exp125 != undefined &&
    ![2, 4, 5].includes(exp125) &&
    postCycleSurveyFeature &&
    showAppFeature(postCycleSurveyFeature, countryCode);

  const anotherOverlayExists = !!(
    (overlay?.name && overlay?.name != "PostCycleAudiobooksModal") ||
    path?.includes("/post-enroll/survey/step-flow/") ||
    path?.includes("/gift-offer")
  );

  useEffect(() => {
    setNotSeenCurrentCycle(!!postCycleSurveyMsg);
  }, [postCycleSurveyMsg]);

  const showPostCycleModal = () => {
    setOverlay("PostCycleAudiobooksModal");
  };

  useAsyncEffect(async () => {
    if (surveyResponded == "loading" && account?.id) {
      const surveyResponse = (await getAccountSurveyResponse(
        41,
      )) as unknown as IPrevResponse;
      const response = surveyResponse?.id ? "responded" : "no response";
      setSurveyResponded(response);
    }
  }, [account]);

  useEffect(() => {
    if (
      surveyResponded == "no response" &&
      showSurvey &&
      notSeenCurrentCycle &&
      !anotherOverlayExists
    ) {
      markMessageAsSeen(42);
      setTimeout(() => showPostCycleModal(), 1000);
    }
  }, [surveyResponded, notSeenCurrentCycle, anotherOverlayExists]);

  if (!appFeatures?.length) {
    return <LoadingCoinSkeleton />;
  }

  return null;
};

export const StyledModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 140px;
    height: 99px;
  }
  picture {
    display: flex;
    justify-content: center;
  }
`;
export const StyledInnerContent = styled.div`
  text-align: center;
  padding: 25px 20px 40px;
  h4 {
    font-size: 2.4rem;
  }
  p {
    font-size: 1.6rem;
  }
  h6 {
    text-transform: none;
    color: var(--color-preFriendPrimary);
  }
`;
export const StyledButtonWrapper = styled.div`
  display: flex;
`;
export const StyledSurvey = styled.div`
  & ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  & label {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  button {
    margin-top: 34px;
  }
  .checkWrapper {
    label {
      text-transform: none;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

type MappedPostCycleProps = {
  surveyList: any[];
  postCycleSurveyMsg: Message | Message[];
  overlay: {
    name: string;
  } | null;
  path: string;
} & Pick<AppState, "account" | "appFeatures">;

const mapStatetoProps = (state: AppState): MappedPostCycleProps => {
  const { analytics } = state;
  return {
    overlay: state.ui ? state.ui.overlay : null,
    surveyList: state.store.survey.list,
    account: state.account,
    appFeatures: state.appFeatures || [],
    postCycleSurveyMsg: getMessages(state, "Post Cycle Survey"),
    path: analytics.location,
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setSurveyResponse,
      setOverlay,
      getAccountSurveyResponse,
      markMessageAsSeen,
      getSurveyByName,
    },
    dispatch,
  );
}

const PostCycleWrapperExp125: React.FC<PostCycleSurveyWrapperProps> = (
  props,
) => {
  return (
    <Variant experimentId={125}>
      {(variant?: { variant: number }) => (
        <PostCycleAudiobooksModalWrapper {...props} exp125={variant?.variant} />
      )}
    </Variant>
  );
};

const ConnectedPostCycleModalWrapper = connect(
  mapStatetoProps,
  mapDispatchToProps,
)(PostCycleWrapperExp125);
const ConnectedPostCycleModal = connect(
  mapStatetoProps,
  mapDispatchToProps,
)(PostCycleAudiobooksModal);

export { ConnectedPostCycleModalWrapper, ConnectedPostCycleModal };
