import { useEffect } from "react";

const useAsyncEffect = (asyncFn, deps) => {
  useEffect(() => {
    let cancelled = false;
    (async () => {
      const syncFn = await asyncFn();
      if (!cancelled && syncFn) {
        syncFn();
      }
    })();
    return () => {
      cancelled = true;
    };
  }, deps);
};

export { useAsyncEffect };
