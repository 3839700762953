/**
 * Displays box icon with counter of items in current box.
 * Used in both member and non member navs.
 */

import { COLORS } from "CSS/Consts";
import React from "react";

const BoxIcon = ({ label, boxItems, color = COLORS.black }) => (
  <div className="boxIconContainer">
    <div className="boxLabel">
      <span>{label ? label : "My box"}</span>
    </div>
    <div className="boxIcon">
      {boxItems > 0 && <div className="boxCount p5">{boxItems}</div>}

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7215 1.30364C11.9003 1.23212 12.0997 1.23212 12.2785 1.30364L17.2772 3.30309C17.278 3.30343 17.2789 3.30377 17.2797 3.30411L22.2785 5.30364C22.5633 5.41754 22.75 5.69332 22.75 6V18C22.75 18.3067 22.5633 18.5825 22.2785 18.6964L12.2785 22.6964C12.0997 22.7679 11.9003 22.7679 11.7215 22.6964L1.72146 18.6964C1.43671 18.5825 1.25 18.3067 1.25 18V6C1.25 5.69332 1.43671 5.41754 1.72146 5.30364L11.7215 1.30364ZM14.9806 4L12 2.80777L4.01944 6L7 7.19222L14.9806 4ZM9.01944 8L17 4.80778L19.9806 6L12 9.19222L9.01944 8ZM6.25 8.50777V12C6.25 12.4142 6.58579 12.75 7 12.75C7.41421 12.75 7.75 12.4142 7.75 12V9.10778L11.25 10.5078V20.8922L2.75 17.4922V7.10777L6.25 8.50777ZM12.75 20.8922L21.25 17.4922V7.10778L12.75 10.5078V20.8922Z"
          style={{ fill: color, fillOpacity: 1 }}
        />
      </svg>
    </div>
  </div>
);

export default BoxIcon;
