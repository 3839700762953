import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";

import PaymentInfoEdit from "./PaymentInfoEdit";

import FieldsetShippingInfo from "UI/components/forms/FieldsetShippingInfo.jsx";
import FieldsetAccountInfo from "UI/components/forms/FieldsetAccountInfo";
import { withStoreData } from "UI/hoc/withStoreData";
import ApplePaymentInfoEdit from "../apple_pay/ApplePaymentInfoEdit";

const EditAccount = (props) => {
  let { overlay } = props;
  const successFn = () => {
    props.setOverlay("");
  };

  const failFn = (error) => {
    console.log("ERROR-->", error);
  };

  const getBodyContent = (type) => {
    switch (type) {
      case "name":
        return (
          <FieldsetAccountInfo
            fieldsToShow={["firstName", "lastName", "displayName"]}
            successCB={(a) => successFn(a)}
            failCB={(e) => failFn(e)}
          />
        );

      case "email":
        return (
          <FieldsetAccountInfo
            fieldsToShow={["email"]}
            successCB={(a) => successFn(a)}
            failCB={(e) => failFn(e)}
          />
        );

      case "password":
        return (
          <FieldsetAccountInfo
            fieldsToShow={["password", "passwordConf"]}
            successCB={(a) => successFn(a)}
            failCB={(e) => failFn(e)}
          />
        );

      case "shipping address":
        return (
          <FieldsetShippingInfo
            address={props.address}
            buttonText={"Save"}
            preventCountrySwitch={true}
            successCB={(a) => successFn(a)}
            failCB={(e) => failFn(e)}
          />
        );

      case "payment method":
        return <PaymentInfoEdit />;

      case "unsupported Apple Pay device":
        return <ApplePaymentInfoEdit />;

      default:
        return <div />;
    }
  };

  const getDek = (type) => {
    //This is done so that the dek can be passed in from the overlay or set directly here to avoid passing same dek in multiple places.
    if (overlay?.data?.dek) {
      return overlay?.data?.dek;
    } else if (type == "payment method") {
      return (
        <>
          This credit card will become your new default{" "}
          <span className={"nowrap"}>payment method.</span>
        </>
      );
    } else {
      return null;
    }
  };

  const header = overlay?.data?.header
    ? overlay?.data?.header
    : `Update ${overlay?.data?.type}`;
  const dek = overlay?.data?.dek
    ? overlay?.data?.dek
    : getDek(overlay?.data?.type);
  const bodyContent = overlay?.data?.bodyContent
    ? overlay?.data?.bodyContent
    : getBodyContent(overlay?.data?.type);

  return (
    <ModalEditWrapper header={header} dek={dek}>
      {bodyContent}
    </ModalEditWrapper>
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
    address: state.address,
    overlay: state.ui ? state.ui.overlay : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(EditAccount),
);
