export const validationRegExp = {
  name: /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-\s]{0,22}$/i,
  first_name:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  firstName:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  last_name:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  lastName:
    /^[a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+( [a-zA-Z\u00C0-\u017F][a-zA-Z\u00C0-\u017F0-9-]+){0,22}$/i,
  street: /^.{0,63}$/i,
  street1: /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/i, //atleast 1 number and 1 alphabetic character
  street2: /^.{0,63}$/i,
  zip: /^\d{5}([\s-]\d{4})?$/i,
  zipCa: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  city: /^[a-zA-Z ]{2,63}$/i,
  email:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  password: /^.{4,40}$/i,
  countryCode: /^(US)|(CA)$/i,
  state:
    /^(AL)|(AK)|(AZ)|(AR)|(AA)|(AE)|(AP)|(CA)|(CO)|(CT)|(DE)|(DC)|(FL)|(GA)|(HI)|(ID)|(IL)|(IN)|(IA)|(KS)|(KY)|(LA)|(ME)|(MD)|(MA)|(MI)|(MN)|(MS)|(MO)|(MT)|(NE)|(NV)|(NH)|(NJ)|(NM)|(NY)|(NC)|(ND)|(OH)|(OK)|(OR)|(PA)|(PR)|(RI)|(SC)|(SD)|(TN)|(TX)|(UT)|(VT)|(VA)|(WA)|(WV)|(WI)|(WY)$/i,
  province:
    /^(AB)|(NB)|(BC)|(NS)|(NT)|(PE)|(SK)|(MB)|(QC)|(NL)|(YT)|(NU)|(ON)$/i,
  defaultValidation: /^.{4,63}$/i,
  displayName: /^[A-Z0-9_ ]{0,63}$/i,
  fbId: /^\S+$/i,
  fbToken: /^\S+$/i,
  email_mag: /^\S*$/i,
  email_promo: /^\S*$/i,
  picture_url: /^[A-Z0-9]{2,20}\.(jpg|png|gif|svg)$/i,
  month: /^[0-9]{0,1}/,
  year: /^[0-9]{4}$/,
  numEmployees: /^\d{1}/i,
};
