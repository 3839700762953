import globalScope from "../../global"; // For tests, ugh

export async function saveAvatarImage(accountId: number, img: any) {
  const res = await fetch(
    global.apiHost + "/svc/account/signedurl/" + accountId,
  );
  const presignedURL = await res.text();
  const req = await fetch(presignedURL, {
    method: "PUT",
    headers: {
      "Content-Type": "image/png",
    },
    body: img,
  });
  if (!req.ok) {
    throw new Error("Error saving image!");
  }
}
export async function getClientToken(headers = {}) {
  const req = await fetch(global.apiHost + "/svc/commerce/token", {
    headers,
    credentials: "include",
  });
  if (!req.ok) {
    throw new Error(await req.text());
  }
  return await req.text();
}
export async function getPaymentMethod(headers = {}) {
  const req = await fetch(global.apiHost + "/svc/commerce", {
    headers,
    credentials: "include",
  });
  if (!req.ok) {
    throw new Error(await req.text());
  }
  return await req.json();
}
export async function addPaymentMethod(addObj: any, headers = {}) {
  const req = await fetch(global.apiHost + "/svc/commerce/addPayment", {
    method: "POST",
    body: JSON.stringify(addObj),
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  if (!req.ok) {
    throw new Error(await req.text());
  }
}
export async function editPaymentMethod(updateObj: any, headers = {}) {
  const req = await fetch(global.apiHost + "/svc/commerce/updatePayment", {
    method: "PUT",
    body: JSON.stringify(updateObj),
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  if (!req.ok) {
    throw new Error(await req.text());
  }
}
export async function getZipTax(zip: string, currencyCode = "USD") {
  const req = await fetch(
    `${global.apiHost}/svc/commerce/ziptax/${zip}?currencyCode=${currencyCode}`,
  );
  if (!req.ok) {
    throw new Error(await req.text());
  }
  return await req.text();
}

export async function klaviyoUnsubscribe(account: any, preferences: any) {
  const req = await fetch(global.apiHost + "/svc/klaviyo/unsubscribe", {
    method: "PUT",
    body: JSON.stringify({ account, preferences }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  if (!req.ok) {
    throw new Error(await req.text());
  }
  // not sure if you should return anything or not, also depends on klaviyo's response
}

export async function getEmailPreferences(email: string) {
  try {
    const response = await fetch(
      global.apiHost + "/svc/klaviyo/email_preferences/" + email,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      },
    );
    return await response.json();
  } catch (e) {
    console.error("TCL:: getEmailPreferences -> e", e);
    return {};
  }
}
