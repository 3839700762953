import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { join } from "../../../state/account/flows";
import { createApplePayRequest } from "../../../utils/apple_pay/createApplePayRequest";
import getTax from "../../../utils/getTax";
import TroubleShootBlock from "../../components/apple_pay/TroubleShootBlock";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { AppState, Dispatch } from "../../../types";
import { IPlan } from "../../../../../types/plan";
import PurchaseConsentBlock from "../../../ui/components/forms/PurchaseConsentBlock";

type ApplePayRejoinBlockProps = {
  //need to type this
  plan: IPlan;
  join: (...args: any) => void;
  successCB: (...args: any) => void;
  failCB: (...args: any) => void;
  clearError: () => void;
  formShowing: string;
  changeForm: (form: string) => void;
  formIsValid: boolean;
  canPayWithApplePay: boolean;
} & Pick<AppState, "account" | "joinData">;

const ApplePayRejoinBlock: React.FC<ApplePayRejoinBlockProps> = ({
  account,
  plan,
  join,
  successCB,
  failCB,
  clearError,
  formShowing,
  changeForm,
  joinData,
  formIsValid,
  canPayWithApplePay,
}) => {
  const [showingFallBack, setShowingFallBack] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const reJoinPlan =
    account.renewalPlan || plan || account?.policy?.planSet?.tracks[1]?.[0];

  const rejoinFN = async (applePayNonce: string) => {
    clearError();
    if (reJoinPlan) {
      try {
        setIsPending(true);
        await join({
          type: "Rejoin",
          plan: reJoinPlan,
          renewalPlan: reJoinPlan,
          planUpgrade: null,
          applePayNonce,
          successFn: successCB,
          failFn: failCB,
        });
      } catch (e) {
        setIsPending(false);
        console.error("error", e);
        successCB();
      }
    }
  };

  const unsupportedView = (
    <StyledUnsupportedViewBlock>
      <h4>
        Sorry! Your Apple Pay payment is{" "}
        <span className={"nowrap"}>not processing</span>
      </h4>
      <p>
        Please update your payment method or try one of the{" "}
        <span className={"nowrap"}>below actions.</span>
      </p>
      <TroubleShootBlock />
      <ClickTracker
        ctaType={"button"}
        id="apple-pay-unsupported-cta-for-ccfail"
        style={"primary fullWidth"}
        handleClick={() => changeForm("updateCCForm")}
        title={"Update payment method"}
        isDisabled={false}
        logClickData={"clicks_unsupported_applepay_cta_in_ccfail"}
        top={40}
        bottom={0}
      />
    </StyledUnsupportedViewBlock>
  );

  return (
    <>
      {formShowing == "applePay" && canPayWithApplePay ? (
        <PurchaseConsentBlock
          customPlan={reJoinPlan}
          cta={"Update payment method"}
          buttonId="apple-pay-unsupported-cta-for-ccfail"
          btnIsPending={isPending}
          btnIsDisabled={showingFallBack ? !formIsValid : false}
          btnAction={() => changeForm("updateCCForm")}
          idAmend={"rejoin-billing"}
          marginBottom={0}
          isForApplePay={canPayWithApplePay}
          applePayCta={"Continue"}
          nonSupportedButtonCta={"Renew now"}
          paymentRequestObj={createApplePayRequest(
            reJoinPlan.price,
            getTax(reJoinPlan.price, joinData?.tax_rate),
            reJoinPlan,
          )}
          paymentForFn={(nounce) => rejoinFN(nounce)}
          showingFallBackCB={(v) => setShowingFallBack(v)}
        />
      ) : (
        unsupportedView
      )}
    </>
  );
};

function mapStateToProps(state: AppState) {
  return {
    account: state.account,
    joinData: state.joinData,
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ join }, dispatch);
}

type UnsupportedViewBlock = {
  isMobile?: boolean;
};

const StyledUnsupportedViewBlock = styled.div.attrs({
  className: "sc-unsupported-view-block",
})<UnsupportedViewBlock>`
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  margin-top: 40px;
  text-align: center;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplePayRejoinBlock);
