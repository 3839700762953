import React from "react";
import styled from "styled-components";
import { COLORS } from "../../css/Consts.js";
import RelationshipTierHeart from "../elements/RelationshipTierHeart";
import { useWindowSize } from "../../ui/hooks/useWindowSize.js";

interface IProgressBar {
  completedAmount: number;
  totalAmount: number;
  headline1?: string;
  headline2?: string;
  barColor?: string;
  backgroundColor?: string;
  completedIcon?: string;
  top?: number;
  bottom?: number;
}

const ProgessBar: React.FC<IProgressBar> = ({
  completedAmount,
  totalAmount,
  headline1,
  headline2,
  barColor,
  backgroundColor,
  top,
  bottom,
}) => {
  const progress = (100 * completedAmount) / totalAmount;
  const [width] = useWindowSize();
  const isMobile = width <= 680;

  return (
    <StyledProgressBarWrapper top={top} bottom={bottom}>
      {headline1 && (
        <StyledProgressHeaderWrapper>
          <p className={isMobile ? "label3" : "label2"}>{headline1}</p>
          {headline2 && (
            <p className={isMobile ? "label3" : "label2"}>{headline2}</p>
          )}
        </StyledProgressHeaderWrapper>
      )}
      <StyledProgressBar
        id={"progressBar"}
        backgroundColor={backgroundColor}
        barColor={barColor}
      >
        <StyledIconHolder>
          <RelationshipTierHeart color={COLORS.white} size={14} />
        </StyledIconHolder>
        <StyledBar barColor={barColor} progress={`${progress}%`} />
      </StyledProgressBar>
    </StyledProgressBarWrapper>
  );
};

interface IStyledProgressBar {
  backgroundColor?: string;
  barColor?: string;
}

interface IStyledProgressBarWrapper {
  top?: number;
  bottom?: number;
}

interface IStyledBar {
  progress?: string;
  barColor?: string;
}

export const StyledProgressBarWrapper = styled.div.attrs({
  className: "sc-StyledProgressBarWrapper" as string,
})<IStyledProgressBarWrapper>`
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
`;

export const StyledProgressHeaderWrapper = styled.div.attrs({
  className: "sc-StyledProgressHeaderWrapper" as string,
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const StyledProgressBar = styled.div.attrs({
  className: "sc-StyledProgressBar" as string,
})<IStyledProgressBar>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--color-white)"};
  width: 100%;
  height: 16px;
  border: 1px solid ${(props) => props.barColor};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const StyledBar2 = styled.div.attrs({
  className: "sc-StyledBar" as string,
})<IStyledBar>`
  @keyframes barAni {
    0% {
      width: 0;
    }
    100% {
      width: ${(props) => props.progress};
    }
  }
  width: 0;
  background: ${(props) => props.barColor};
  animation-name: barAni;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 10px;
  border-radius: 8px 0 0 8px;
`;

export const StyledBar = styled.div.attrs({
  className: "sc-StyledBar" as string,
})<IStyledBar>`
  width: ${(props) => props.progress};
  background: ${(props) => props.barColor};
  animation-name: barAni;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  height: 14px;
  border-radius: 8px 0 0 8px;
`;

export const StyledIconHolder = styled.div.attrs({
  className: "sc-StyledIconHolder" as string,
})`
  width: 12px;
  position: absolute;
  right: 5px;
  top: 2px;
`;

export default ProgessBar;
