import React, { useState, useRef, useContext } from "react";
import debounce from "lodash.debounce";
import { RegionContext } from "./RegionContext";

import * as searchApi from "../../state/search/api";

const defaultResults = {
  genre: [],
  pdp: [],
};

const SearchContext = React.createContext({
  searchResults: defaultResults,
  searchTerm: "",
  isPending: false,
  search: () => null,
  clearSearch: () => null,
});

const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(defaultResults);
  const [isPending, setIsPending] = useState(false);
  const { canadaMode } = useContext(RegionContext);

  const debouncedFunction = useRef(
    debounce(async (query) => {
      const searchResults = await searchApi.query(query, canadaMode);
      setSearchResults(searchResults);
      setIsPending(false);
    }, 200),
  );

  const search = async (query) => {
    setSearchTerm(query);
    debouncedFunction.current.cancel();
    if (query.length >= 3) {
      setIsPending(true);
      debouncedFunction.current(query);
    } else {
      setIsPending(false);
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    debouncedFunction.current.cancel();
    setSearchTerm("");
    setSearchResults([]);
  };

  return (
    <SearchContext.Provider
      value={{ search, clearSearch, searchResults, searchTerm, isPending }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { SearchProvider, SearchContext };
