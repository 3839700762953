import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { BORDER, COLORS } from "../../../css/Consts";
import { Button } from "../../elements/form/Button";
import { Error } from "../../elements/form/Error";
import { ModalEditWrapper } from "../structure/ModalEditWrapper";
import { RadioButtonList } from "../../elements/form/RadioButton";
import { Link } from "../../elements/navigation/Link";

interface Preferences {
  BookclubBooksUpdateSubscribe?: "all" | "none" | "once";
  BookclubPromoSubscribe?: "all" | "nogift" | "none";
  BookclubAllEmailSubscribe?: "all" | "none";
  BookclubMemberUpdateSubscribe?: "all" | "none" | "once";
  BOTMMonthlySubscribe?: "all" | "none" | "once";
  BOTMPromoSubscribe?: "all" | "nogift" | "none";
  BOTMEmailUnsubscribe?: "all" | "none";
  BOTMVBTSubscribe?: "all" | "none";
}

interface IEditEmailPrefs {
  closeAction?: () => void;
  updatePreferences?: (ep?: Preferences) => void;
  error?: string;
  isBookclub?: boolean;
  currentEmailPrefs?: Preferences;
  showVbtEmailPref?: boolean;
}

export const EditEmailPrefs: React.FC<IEditEmailPrefs> = ({
  error,
  updatePreferences,
  closeAction,
  isBookclub,
  currentEmailPrefs,
  showVbtEmailPref,
}) => {
  const [pending, setPending] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [promoSubscribe, setPromoSubscribe] = useState<
    "all" | "nogift" | "none" | undefined
  >(
    currentEmailPrefs?.BookclubPromoSubscribe ||
      currentEmailPrefs?.BOTMPromoSubscribe ||
      "all",
  ); //bookclubPromoSubscribe : Markerting and promo
  const [updateSubscribe, setUpdateSubscribe] = useState<
    "all" | "none" | "once" | undefined
  >(
    currentEmailPrefs?.BookclubBooksUpdateSubscribe ||
      currentEmailPrefs?.BOTMMonthlySubscribe ||
      "all",
  ); //bookclubUpdateSubscribe : New book selections
  const [emailUnsubscribe, setEmailUnsubscribe] = useState<
    "all" | "none" | undefined
  >(
    currentEmailPrefs?.BookclubAllEmailSubscribe ||
      currentEmailPrefs?.BOTMEmailUnsubscribe ||
      "all",
  ); //BookclubAllEmailSubscribe : Unsub fro all botm email
  const [memberUpdate, setMemberUpdate] = useState(
    currentEmailPrefs?.BookclubMemberUpdateSubscribe || "all",
  ); //BookclubMemberUpdateSubscribe : Members join or leave
  const [vbtSubscribe, setVbtSubscribe] = useState(
    currentEmailPrefs?.BOTMVBTSubscribe || "all",
  );

  useEffect(() => {
    if (currentEmailPrefs) {
      if (
        currentEmailPrefs.BookclubPromoSubscribe ||
        currentEmailPrefs.BOTMPromoSubscribe
      ) {
        setPromoSubscribe(
          currentEmailPrefs.BookclubPromoSubscribe ||
            currentEmailPrefs.BOTMPromoSubscribe,
        );
      }
      if (
        currentEmailPrefs.BookclubBooksUpdateSubscribe ||
        currentEmailPrefs.BOTMMonthlySubscribe
      ) {
        setUpdateSubscribe(
          currentEmailPrefs.BookclubBooksUpdateSubscribe ||
            currentEmailPrefs.BOTMMonthlySubscribe,
        );
      }
      if (
        currentEmailPrefs.BookclubAllEmailSubscribe ||
        currentEmailPrefs.BOTMEmailUnsubscribe
      ) {
        setEmailUnsubscribe(
          currentEmailPrefs.BookclubAllEmailSubscribe ||
            currentEmailPrefs.BOTMEmailUnsubscribe,
        );
      }
      if (currentEmailPrefs.BookclubMemberUpdateSubscribe) {
        setMemberUpdate(currentEmailPrefs.BookclubMemberUpdateSubscribe);
      }
    }
  }, [currentEmailPrefs]);

  let preferences = isBookclub
    ? {
        BookclubBooksUpdateSubscribe: updateSubscribe,
        BookclubPromoSubscribe: promoSubscribe,
        BookclubAllEmailSubscribe: emailUnsubscribe,
        BookclubMemberUpdateSubscribe: memberUpdate,
      }
    : {
        BOTMMonthlySubscribe: updateSubscribe,
        BOTMPromoSubscribe: promoSubscribe,
        BOTMEmailUnsubscribe: emailUnsubscribe,
        BOTMVBTSubscribe: vbtSubscribe,
      };

  const buttonList = isBookclub
    ? [
        {
          text: "All updates",
          value: "all",
          isChecked: promoSubscribe == "all",
        },
        {
          text: "Unsubscribe",
          value: "none",
          isChecked: promoSubscribe == "none",
        },
      ]
    : [
        {
          text: "All updates",
          value: "all",
          isChecked: promoSubscribe == "all",
        },
        {
          text: "No gift emails",
          value: "nogift",
          isChecked: promoSubscribe == "nogift",
        },
        {
          text: "Unsubscribe",
          value: "none",
          isChecked: promoSubscribe == "none",
        },
      ];

  const setMemberChange = (value: "none" | "all" | "once") => {
    setMemberUpdate(value);
  };
  const setPromoChange = (value: "none" | "nogift" | "all") => {
    setPromoSubscribe(value);
  };
  const setUpdateChange = (value: "none" | "all" | "once") => {
    setUpdateSubscribe(value);
  };
  const setUnsubscribeChange = (value: "none" | "all") => {
    if (!pending) {
      //prevent click if already has pending action
      setEmailUnsubscribe(value);
      if (value == "none") {
        setShowConfirm(true);
      }
    }
  };

  if (showSuccess) {
    return (
      <ModalEditWrapper
        header={"You’re all set."}
        dek={"If you ever change your mind, we’ll be around."}
      >
        <Button
          style="primary fullWidth"
          handleClick={closeAction}
          title={"Done"}
        />
      </ModalEditWrapper>
    );
  }

  const unSubAll = () => {
    preferences = isBookclub
      ? {
          BookclubBooksUpdateSubscribe: "none",
          BookclubPromoSubscribe: "none",
          BookclubAllEmailSubscribe: "none",
          BookclubMemberUpdateSubscribe: "none",
        }
      : {
          BOTMMonthlySubscribe: "none",
          BOTMPromoSubscribe: "none",
          BOTMEmailUnsubscribe: "none",
          BOTMVBTSubscribe: "none",
        };

    setPending(true);
    updatePreferences?.(preferences);
    setShowSuccess(true);
  };

  if (showConfirm) {
    return (
      <ModalEditWrapper
        header={"Are you sure?"}
        dek={"We don’t want you to miss out on new books!"}
      >
        <Button
          style="primary fullWidth"
          handleClick={() => {
            if (pending) {
              null;
            } else {
              setShowConfirm(false);
              setUnsubscribeChange("all");
            }
          }}
          title={"Go back"}
        />
        <Button
          style="primary fullWidth dangerous"
          top={20}
          handleClick={() => {
            unSubAll();
          }}
          isPending={pending}
          title={pending ? "Updating..." : "Unsubscribe from all updates"}
        />
      </ModalEditWrapper>
    );
  }

  return (
    <ModalEditWrapper header={"Email preferences"}>
      {error && <Error message={error} />}
      <StyledPrefsWrapper>
        <p className="label2">New book selections</p>
        <RadioButtonList
          radioButtonGroupName="BookclubUpdateSubscribe"
          radioButtonValues={[
            {
              text: "All updates",
              value: "all",
              isChecked: updateSubscribe == "all",
            },
            {
              text: "1x  month",
              value: "once",
              isChecked: updateSubscribe == "once",
            },
            {
              text: "Unsubscribe",
              value: "none",
              isChecked: updateSubscribe == "none",
            },
          ]}
          handleUpdate={
            setUpdateChange as (ep?: string | boolean | undefined) => void
          }
        />
        {showVbtEmailPref && (
          <>
            <p className="label2">Virtual Book Tour</p>
            <RadioButtonList
              radioButtonGroupName="vbtSubscribe"
              radioButtonValues={[
                {
                  text: "All updates",
                  value: "all",
                  isChecked: vbtSubscribe == "all",
                },
                {
                  text: "Unsubscribe",
                  value: "none",
                  isChecked: vbtSubscribe == "none",
                },
              ]}
              handleUpdate={
                setVbtSubscribe as (ep?: string | boolean | undefined) => void
              }
            />
          </>
        )}
        <p className="label2">Marketing and promotional updates</p>
        <RadioButtonList
          radioButtonGroupName="BookclubPromoSubscribe"
          radioButtonValues={buttonList}
          handleUpdate={
            setPromoChange as (ep?: string | boolean | undefined) => void
          }
        />

        {isBookclub && (
          <>
            <p className="label2">Members join or leave club (leaders only)</p>
            <RadioButtonList
              radioButtonGroupName="BookclubMemberUpdateSubscribe"
              radioButtonValues={[
                {
                  text: "All updates",
                  value: "all",
                  isChecked: memberUpdate == "all",
                },
                {
                  text: "Fewer updates (weekly summary)",
                  value: "once",
                  isChecked: memberUpdate == "once",
                },
                {
                  text: "Unsubscribe",
                  value: "none",
                  isChecked: memberUpdate == "none",
                },
              ]}
              handleUpdate={
                setMemberChange as (ep?: string | boolean | undefined) => void
              }
            />
          </>
        )}
      </StyledPrefsWrapper>
      <StyledActionWrapper>
        <Button
          id="edit email prefs"
          style="primary fullWidth"
          handleClick={() => {
            setPending(true);
            updatePreferences?.(preferences);
          }}
          isPending={pending}
          title={pending ? "Updating..." : "Update"}
          bottom={20}
        />
        <Link
          navigate={setUnsubscribeChange}
          linkTo={"none"}
          color={COLORS.gray5}
          size={14}
          bottom={20}
          whiteSpace={"wrap"}
          text={
            isBookclub
              ? "Unsubscribe me from all Bookclubs emails"
              : "Unsubscribe me from all Book of the Month emails"
          }
        />
      </StyledActionWrapper>
    </ModalEditWrapper>
  );
};

export const StyledPrefsWrapper = styled.div`
  border-bottom: ${BORDER.defaultBorder};
  padding-bottom: 30px;
  margin-bottom: 40px;
  h6 {
    padding: 30px 0 30px;
    margin-top: 30px;
    border-top: ${BORDER.defaultBorder};
  }
`;

export const StyledActionWrapper = styled.div`
  text-align: center;
`;
