/**
 *
 * Shared component used as parent wrapper for survey questions.
 * Displays questions and handles response.
 *
 */
import React, { Component } from "react";
import SurveyQuestion from "UI/elements/SurveyQuestion.jsx";
import ClickTracker from "UI/elements/ClickTracker";
import { StyledFlexWrapper } from "./structure/StyledFlexWrapper";
import SurveySearch from "./SurveySearch";
import { COLORS } from "CSS/Consts";
import { withWindowSize } from "UI/hoc";

const SURVEY_QUESTION_INDEX_MAP = {
  730: "instagram",
  726: "youtube",
  1051: "tiktok",
};

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQuestions: [],
      selectedQuestions: [],
      surveyResponses: {},
      error: null,
      pending: false,
      arrowIdx: 0,
      radioSelectionId: 0,
      klaviyoResponse: null,
    };
    this.resetMap = this.resetMap.bind(this);
    this.captureSurveyData = this.captureSurveyData.bind(this);
    this.openQuestions = new Map();
    this.selectedQuestions = new Map();
    this.hasChildren = this.hasChildren.bind(this);
    this.isChild = this.isChild.bind(this);
    this.resetSiblings = this.resetSiblings.bind(this);
    this.resetChildren = this.resetChildren.bind(this);
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    if (
      this.props.surveyQuestions.parents &&
      this.props.surveyQuestions.children
    ) {
      this.resetMap(true, true, true);
    }
    if (this.props?.account?.id && this.props.getAccountSurveyResponse) {
      this.getPreviousResponse();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.surveyQuestions.parents &&
      this.props.surveyQuestions.parents &&
      !prevProps.surveyQuestions.children &&
      this.props.surveyQuestions.children
    ) {
      this.resetMap(true, true, true);
    }
    if (prevProps.surveyId !== this.props.surveyId) {
      this.getPreviousResponse();
      this.resetMap(true, true, true);
    }
  }

  async getPreviousResponse() {
    if (this.props?.account && this.props.getAccountSurveyResponse) {
      const previousResponse = await this.props.getAccountSurveyResponse(
        this.props.surveyId,
      );
      if (previousResponse) {
        const previousResponseArray =
          previousResponse?.response
            ?.split(",")
            ?.map((response) => parseInt(response)) || [];
        const openQuestionsArray = previousResponseArray.filter((q) =>
          this.hasChildren(q),
        );
        this.setState({
          selectedQuestions: previousResponseArray,
          radioSelectionId: previousResponse?.response,
          openQuestions: openQuestionsArray,
        });
        previousResponseArray.forEach((q) => {
          this.selectedQuestions.set(q, true);
        });
        openQuestionsArray.forEach((q) => {
          this.openQuestions.set(q, true);
        });
      }
    }
  }

  resetMap(clearQuestions, clearParents, clearResponses) {
    let { parents = [], children = [] } = this.props.surveyQuestions || {};
    if (clearQuestions) {
      [...parents, ...children].forEach((q) =>
        this.selectedQuestions.set(q.id, false),
      );
      this.setState({
        selectedQuestions:
          [...this.selectedQuestions.entries()]
            .filter((q) => q[1])
            .map((q) => q[0]) || [],
      });
    }
    if (clearParents) {
      // the only questions that would be always open are those with type label or text
      children.forEach((q) => {
        const parent = parents.find((p) => p.id == q.parent);
        this.openQuestions.set(
          q.parent,
          parent?.type == "text" || parent?.type == "label" ? true : false,
        );
      });
      this.setState({
        openQuestions:
          [...this.openQuestions.entries()]
            .filter((q) => q[1])
            .map((q) => q[0]) || [],
      });
    }
    if (clearResponses) {
      let surveyResponses = [...parents, ...children].reduce((acc, q) => {
        if (q.type.match("text")) {
          acc[q.id] = "";
        }
        return acc;
      }, {});
      this.setState({ surveyResponses });
    }
  }

  handleChange(id, type, evt, checked = false, klaviyoResponse = null) {
    let { surveyResponses } = this.state;
    switch (type) {
      case "checkbox":
        if (checked || evt?.target?.checked) {
          this.selectedQuestions.set(id, true);
          if (this.openQuestions.has(id) || this.hasChildren(id)) {
            this.openQuestions.set(id, true);
          }
        } else {
          this.selectedQuestions.set(id, false);
          if (this.openQuestions.has(id)) {
            this.openQuestions.set(id, false);
          }
          if (this.hasChildren(id)) {
            this.resetChildren(id);
          }
        }
        if (
          this.props.maxChoice &&
          this.state.selectedQuestions?.length >= this.props.maxChoice
        ) {
          this.setState({
            error: this.props.errorMessage || "Select up to two.",
          });
        }

        this.setState(
          {
            selectedQuestions:
              [...this.selectedQuestions.entries()]
                .filter((q) => q[1])
                .map((q) => q[0]) || [],
            openQuestions:
              [...this.openQuestions.entries()]
                .filter((q) => q[1])
                .map((q) => q[0]) || [],
          },
          () => {
            if (
              this.props.maxChoice &&
              this.state.selectedQuestions?.length <= this.props.maxChoice
            )
              this.setState({ error: null });
          },
        );
        break;
      case "radio":
        if (checked || evt?.target?.checked) {
          this.resetMap(!this.isChild(id), !this.isChild(id), true);
          this.selectedQuestions.set(id, true);
          if (this.openQuestions.has(id) || this.hasChildren(id)) {
            this.openQuestions.set(id, true);
          }
          if (this.isChild(id)) {
            this.resetSiblings(id);
          }
        }
        this.setState({
          selectedQuestions:
            [...this.selectedQuestions.entries()]
              .filter((q) => q[1])
              .map((q) => q[0]) || [],
          openQuestions:
            [...this.openQuestions.entries()]
              .filter((q) => q[1])
              .map((q) => q[0]) || [],
          klaviyoResponse: klaviyoResponse,
        });
        break;
      case "text":
      case "textarea":
        surveyResponses[id] = evt.target.value;
        this.setState({ surveyResponses });
        break;
    }
  }

  renderQuestions() {
    let { parents = [], children = [] } = this.props.surveyQuestions || {};
    return parents.map((parent, i) => {
      let subList;
      if (children.some((c) => c.parent === parent.id)) {
        let searchList;
        if (
          parent.id in SURVEY_QUESTION_INDEX_MAP &&
          this.props.searchIndex?.[SURVEY_QUESTION_INDEX_MAP[parent.id]]
        ) {
          searchList = (
            <div>
              <SurveySearch
                index={
                  this.props.searchIndex?.[SURVEY_QUESTION_INDEX_MAP[parent.id]]
                }
                selectedQuestions={this.state.selectedQuestions}
                handleChange={this.handleChange.bind(this)}
              />
            </div>
          );
        }
        subList = (
          <ul
            className={
              "subList " +
              (this.state.openQuestions.indexOf(parent.id) > -1
                ? " show"
                : " hide")
            }
          >
            {searchList}
            {children
              .filter((c) => c.parent === parent.id)
              .map((c, i) => {
                return (
                  <li key={c.id}>
                    {
                      <SurveyQuestion
                        question={c}
                        selectedQuestions={this.state.selectedQuestions}
                        surveyResponses={this.state.surveyResponses}
                        handleChange={this.handleChange.bind(this)}
                        arrowIdx={this.state.arrowIdx}
                        setState={this.setState}
                        isSelected={this.state.radioSelectionId == c.id}
                      />
                    }
                  </li>
                );
              })}
          </ul>
        );
      }

      return (
        <li key={i}>
          <SurveyQuestion
            question={parent}
            selectedQuestions={this.state.selectedQuestions}
            surveyResponses={this.state.surveyResponses}
            handleChange={this.handleChange.bind(this)}
            arrowIdx={this.state.arrowIdx}
            setState={this.setState}
            isSelected={this.state.radioSelectionId == parent.id}
          />
          {subList}
        </li>
      );
    });
  }

  async captureSurveyData() {
    let source = this.props.source ? this.props.source : null;
    this.setState({ error: null, pending: true });
    const responseArr = Object.entries(this.state.surveyResponses);
    const restructuredChildResponses = responseArr?.reduce((list, curQ) => {
      if (curQ[1]?.length)
        list.push(`${curQ[0]}:${curQ[1].replace(/,/g, "&#44;")}`);
      return list;
    }, []);
    //only save response if there is a response.
    if (
      restructuredChildResponses?.length ||
      this.state.selectedQuestions?.length
    ) {
      if (this.props.holdSurveySubmit) {
        this.props.setSavedResponse({
          surveyId: this.props.surveyId,
          response: [
            ...this.state.selectedQuestions,
            ...restructuredChildResponses,
          ]
            .filter((r) => r)
            .join(","),
          source,
          storeId: this.props.storeId,
        });
      } else {
        this.props.setSurveyResponse({
          surveyId: this.props.surveyId,
          response: [
            ...this.state.selectedQuestions,
            ...restructuredChildResponses,
          ]
            .filter((r) => r)
            .join(","),
          source,
          storeId: this.props.storeId,
          surveyName: this.props.surveyName,
          klaviyoResponse: this.state.klaviyoResponse,
        });
      }
      if (
        this.props.responseOnClick &&
        this.props.surveyQuestions.parents &&
        this.props.surveyQuestions.parents?.length &&
        this.state.selectedQuestions?.length
      ) {
        this.props.responseOnClick({
          surveyId: this.props.surveyId,
          selectedQuestions: this.state.selectedQuestions,
          klaviyoResponse: this.state.klaviyoResponse,
        });
      }
    }
    if (this.props.nextStep) {
      this.props.nextStep();
      this.setState({ pending: false });
    }
  }

  hasChildren(questionId) {
    return this.props.surveyQuestions.children.some(
      (c) => c.parent === parseInt(questionId),
    );
  }

  isChild(questionId) {
    return this.props.surveyQuestions.children.some(
      (c) => c.id === parseInt(questionId),
    );
  }

  getParent(questionId) {
    return this.props.surveyQuestions.children.find(
      (c) => c.id === parseInt(questionId),
    )["parent"];
  }

  resetSiblings(childId) {
    const parentId = this.getParent(childId);
    this.props.surveyQuestions.children
      .filter(
        (c) => c.id !== parseInt(childId) && c.parent === parseInt(parentId),
      )
      .forEach((q) => this.selectedQuestions.set(q.id, false));
    this.setState({
      selectedQuestions:
        [...this.selectedQuestions.entries()]
          .filter((q) => q[1])
          .map((q) => q[0]) || [],
    });
  }

  resetChildren(parentId) {
    this.props.surveyQuestions.children
      .filter((c) => c.parent === parseInt(parentId))
      .forEach((q) => this.selectedQuestions.set(q.id, false));
    this.setState({
      selectedQuestions:
        [...this.selectedQuestions.entries()]
          .filter((q) => q[1])
          .map((q) => q[0]) || [],
    });
  }

  setActions(buttonText, trackLabel, isDisabled) {
    const isMobile = this.props.width && this.props.width <= 680;
    const clickAction = () => {
      if (isDisabled) {
        return null;
      } else if (
        !this.state.selectedQuestions?.length &&
        !this.props.mustComplete
      ) {
        return this.props.responseOnClick({
          surveyId: this.props.surveyId,
          selectedQuestions: null,
        });
      } else {
        return this.captureSurveyData();
      }
    };

    if (this.props.showNORPauseCancelExp134) {
      return (
        <ClickTracker
          ctaType={"button"}
          id="cancel-survey-submit-NORPauseCancelExp134"
          style={"primary fullWidth"}
          handleClick={() => clickAction()}
          isPending={this.state.pending}
          title={"Submit feedback"}
          isDisabled={isDisabled}
          logClickData={
            "Clicked - cancel survey - submit - NORPauseCancelExp134"
          }
        />
      );
    } else if (this.props.isForCancelFlow) {
      return (
        <>
          <ClickTracker
            ctaType={"button"}
            id="cancel-survey-submit"
            style={"primary fullWidth"}
            handleClick={() => clickAction()}
            isPending={this.state.pending}
            title={"Continue canceling"}
            isDisabled={isDisabled}
            logClickData={"Clicked - cancel survey - submit"}
          />
          <ClickTracker
            ctaType={"button"}
            id="cancel-survey-keep"
            style={"secondary -fullWidth"}
            linkTo={"/pause-membership/thanks"}
            title={"Keep my membership"}
            isDisabled={this.state.pending}
            logClickData={"Clicked - cancel survey - keep membership"}
          />
          <ClickTracker
            ctaType={"link"}
            id="cancel-survey-pause"
            linkTo={"/pause-membership"}
            title={"Pause my membership"}
            isDisabled={this.state.pending}
            logClickData={"Clicked - cancel survey - pause membership"}
          />
        </>
      );
    } else {
      return (
        <ClickTracker
          ctaType={"button"}
          id="cancel-survey-submit"
          style={"primary fullWidth link-p1"}
          size={isMobile ? 16 : 18} // 16 mobile 18 desktop
          handleClick={() => (isDisabled ? null : this.captureSurveyData())}
          isPending={this.state.pending}
          title={buttonText}
          isDisabled={isDisabled}
          logClickData={trackLabel ? trackLabel : ""}
          customClass="link-p1"
        />
      );
    }
  }

  render() {
    let {
      headline,
      buttonText,
      multipleChoice,
      dek,
      mustComplete,
      trackLabel,
      showNORPauseCancelExp134,
      disabled,
    } = this.props;
    let { error, pending, selectedQuestions } = this.state;
    let isDisabled =
      error ||
      pending ||
      (mustComplete && !selectedQuestions?.length) ||
      disabled;
    let errorDisplay = error ? <div className="error">{error}</div> : null;
    let subhead = dek
      ? dek
      : multipleChoice
      ? "Select all that apply."
      : "Please select one option.";

    return (
      <div
        className={`${this.props.surveyName || ""}`}
        style={SurveyQuestionsStyles}
      >
        {errorDisplay}
        {headline && <h3 style={{ textAlign: "center" }}>{headline}</h3>}
        {!this.props.hideDek && subhead ? (
          <p
            className="p1-alt"
            style={{
              marginBottom: "48px",
              marginTop: "12px",
              textAlign: "center",
              color: COLORS.gray5,
            }}
          >
            {subhead}
          </p>
        ) : null}
        <StyledFlexWrapper
          gap={20}
          className={"checkWrapper surveyQuestions"}
          customStyles={showNORPauseCancelExp134 ? checkWrapperStyles : ""}
        >
          {this.renderQuestions()}
        </StyledFlexWrapper>
        <StyledFlexWrapper textAlign="center" gap={20}>
          {this.setActions(buttonText, trackLabel, isDisabled)}
        </StyledFlexWrapper>
      </div>
    );
  }
}

const checkWrapperStyles = `
    padding-bottom: 0 !important;
    border-bottom: none !important;
`;

const SurveyQuestionsStyles = {
  listStyle: "none",
};

export default withWindowSize(SurveyQuestions);
