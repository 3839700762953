import React from "react";
import { RegionContext } from "../contexts/RegionContext";

type WithRegionProps = {
  canadaMode: boolean;
  quebecMode: boolean;
  locationHeader: {
    countryHeader: string;
    regionHeader: string;
  };
};

const withRegion =
  <P extends object>(
    Component: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof WithRegionProps>> =>
  (props) => (
    <RegionContext.Consumer>
      {({ canadaMode, quebecMode, locationHeader }) => (
        <Component
          {...(props as P)}
          canadaMode={canadaMode}
          quebecMode={quebecMode}
          locationHeader={locationHeader}
        />
      )}
    </RegionContext.Consumer>
  );

export { withRegion };
