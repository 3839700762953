/**
 * Parent component for entire app
 */

import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useHistory } from "react-router-dom";
import { useStoreData } from "UI/hooks/useStoreData";
import { queryparser } from "Utils/queryparser";
import { useWindowSize } from "UI/hooks";
import { pageView } from "Utils/analytics";
import { setDimension } from "State/analytics/flows";
import { setOverlay } from "State/ui/creators";
import { setEntryPoint } from "State/entryPoint/creators";
import { setReadyToRender } from "State/loading/creators";
import { setJoinPromo, setAutoPromoCode } from "State/joinData/creators";
import { authUser } from "State/account/flows";
import { isReferred } from "State/joinData/selectors";
import { setCustomerInfo, setClickId } from "State/affiliates/flows";
import { setStoreData } from "State/store/creators";
import { setAppFeatures } from "State/appFeatures/creators";
import SiteMessageRejoin from "UI/pages/gift/SiteMessageRejoin.jsx";
import MobileNav from "UI/skeleton/MobileNav.jsx";
import Header from "UI/skeleton/Header.jsx";
import Footer from "UI/skeleton/Footer.jsx";
import BrowserNotification from "UI/skeleton/BrowserNotification.jsx";
import SiteMessage from "UI/skeleton/SiteMessage.jsx";
import CssFailMessage from "UI/skeleton/CssFailMessage.jsx";
import SiteOverlay from "UI/skeleton/SiteOverlay.jsx";
import { HeaderSkeleton } from "UI/loadingSkeletons/BOMSkeleton.jsx";
import Routes from "Root/Routes/index.jsx";
import OnlyTrack from "UI/skeleton/OnlyTrack.jsx";
import Meta from "UI/skeleton/Meta.jsx";
import ErrorBoundary from "UI/skeleton/ErrorBoundary.jsx";
import { BOTMLogoSet } from "../elements/LogoSet";
import { dataLayerTracking } from "Utils/analytics";
import { useAsyncEffect } from "UI/hooks";
import { useRegion } from "UI/hooks/useRegion";
import * as appFeatureApi from "../../state/appFeatures/api";
import { ConnectedPostCycleModalWrapper } from "UI/components/PostCycleAudiobooksModal";
import { snesRedirect } from "Utils/snesRedirect";
import { WIDTHS } from "CSS/Consts";

const fullPages = [
  "instastories",
  "instastories1",
  "instastories2",
  "instastories3",
  "instastories4",
  "instastories5",
  "instastories6",
  "instastories7",
  "instastories8",
  "instastories9",
  "instastoriesdojo",
  "instastoryrules",
  "guides",
  "join-billing",
  "new_member_offer",
  "gift-account-info",
  "beta-account",
  "loyalty-offer",
  "join-offer",
  "step-join",
  "join-now",
  "box-review",
  "ship-billing",
  "holiday-rewards",
  "checkout",
  "bff",
  "step-flow", // grifter
  "/step-join",
  "credit-rejoin",
  "age-requirement",
];

const navless = ["lead", "credit-rejoin"];

const BOMBody = (props) => {
  const {
    isReferred,
    account,
    isEnrollFlow,
    isRenewalFlow,
    isNavless,
    setHideMobileNav,
    setShowMobileNav,
    showMobileNav,
    isLanding,
    width,
    isCanadaMode,
    loginAttempted,
    overlay,
  } = props;
  const { track } = useStoreData();
  const { pathname } = useLocation();
  const isMobile = width <= WIDTHS.PX.narrow;
  const isMobileScrollLock = isMobile && overlay?.type === "special";
  const BOMwrapperRef = useRef(null);
  const BOMbodyRef = useRef(null);
  let mobileOpenTimeout;

  if (isMobileScrollLock) {
    BOMwrapperRef.current?.classList?.add("fadeout");
    mobileOpenTimeout = setTimeout(
      () => BOMwrapperRef.current?.classList?.add("mobileOpen"),
      1000,
    );
  } else {
    BOMwrapperRef.current?.classList?.remove("fadeout", "mobileOpen");
    clearTimeout(mobileOpenTimeout);
  }

  if (isNavless) {
    BOMwrapperRef.current?.classList?.add("navLess");
    BOMbodyRef.current?.classList?.remove("bodyContent");
  }

  useEffect(() => {
    if (document) {
      setCustomerInfo(account?.id || "", account?.email || "");
    }
  });

  const isMember =
    account &&
    account?.policy?.type !== "Visitor" &&
    account?.policy?.type !== "Lead";

  const inlineBanner = ["/step-join", "/join-billing"].includes(pathname);
  const showPostCycleSurvey =
    isMember && account?.cycleActions?.hasCycleResponse;

  if (
    account?.policy?.type === "Ban" &&
    ["Chargeback", "Under Age"].includes(account?.policy?.subType)
  ) {
    setOverlay("BanModal");
  }

  return (
    <div
      ref={BOMwrapperRef}
      className={`outerWrapper ${isNavless ? " navLess" : ""} ${
        showMobileNav ? " mobileOpen" : ""
      }`}
    >
      {!inlineBanner && <div id="banners">{/* Hiding promo banner */}</div>}

      <div style={{ position: "relative" }}>
        {!loginAttempted ? (
          <HeaderSkeleton />
        ) : (
          <Header
            track={track}
            setHideMobileNav={setHideMobileNav}
            setShowMobileNav={setShowMobileNav}
            showMobileNav={showMobileNav}
            renewalFlow={isRenewalFlow}
            isReferred={isReferred}
            isLanding={isLanding}
            width={width}
            isCanadaMode={isCanadaMode}
          />
        )}
        <SiteMessageRejoin account={account} location={pathname} />
        <div ref={BOMbodyRef} className={!isNavless ? "bodyContent" : ""}>
          <SiteMessage />
          {showPostCycleSurvey && <ConnectedPostCycleModalWrapper />}
          <Routes />
        </div>
        <Footer isEnroll={isEnrollFlow} isCanadaMode={isCanadaMode} />
      </div>
    </div>
  );
};

const BOMPage = (props) => {
  const {
    loginAttempted,
    overlay,
    setOverlay,
    setStoreData,
    setAppFeatures,
    account,
    isCanadaMode,
  } = props;
  const { store, track } = useStoreData();
  const [width, height] = useWindowSize();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [showMobileNav, dispatchMobileNav] = useReducer((state, action) => {
    switch (action) {
      case "show":
        return true;
      case "hide":
      default:
        return false;
    }
  }, false);

  useAsyncEffect(async () => {
    setAppFeatures(await appFeatureApi.all());
  }, []);

  // For now lets set state.store.data
  // curly brackets are needed to avoid console warnings
  useEffect(() => {
    setStoreData?.({ ...store, track });
  }, [store]);

  const setShowMobileNav = () => {
    dispatchMobileNav("show");
    setOverlay("mobileNav", "special");
  };

  const setHideMobileNav = () => {
    if (overlay?.name === "mobileNav") {
      dispatchMobileNav("hide");
      setOverlay("");
    }
  };

  useEffect(() => {
    setHideMobileNav();
    pageView(account, pathname);

    if (pathname.includes("getstarted")) {
      props.setEntryPoint("getstarted");
    } else if (pathname.includes("bookbox")) {
      props.setEntryPoint("bookbox");
      props.setAutoPromoCode("OHJOY");
    } else if (pathname.includes("bookmail")) {
      props.setEntryPoint("bookmail");
      props.setAutoPromoCode("OPENJOY");
    } else if (pathname.includes("mailbox")) {
      props.setEntryPoint("mailbox");
      props.setAutoPromoCode("CHEER");
    } else if (pathname.includes("bffholiday")) {
      props.setEntryPoint("bffholiday");
    }
  }, [pathname]);

  if (store?.maintenance) {
    snesRedirect("snes");
  }

  return (
    <ErrorBoundary>
      <div className={track.name}>
        <CssFailMessage />
        <SiteOverlay />
        {width <= 1024 && (
          <MobileNav
            setHideMobileNav={setHideMobileNav}
            currentMonth={store.currentCycle?.label || "This month’s"}
            isCanadaMode={isCanadaMode}
          />
        )}
        <BrowserNotification storeTrack={track} />
        <BOMBody
          {...props}
          loginAttempted={loginAttempted}
          showMobileNav={showMobileNav}
          setHideMobileNav={setHideMobileNav}
          setShowMobileNav={setShowMobileNav}
          width={width}
          isCanadaMode={isCanadaMode}
        />
      </div>
    </ErrorBoundary>
  );
};

const BOM = (props) => {
  const {
    account,
    authUser,
    loginAttempted,
    setReadyToRender,
    overlay,
    entryPoint,
    origin,
  } = props;
  const { pathname, search, state } = useLocation();
  let { store, track } = useStoreData(),
    trackId = track?.id || 1;
  const isNavless =
    pathname.split("/").some((l) => navless.includes(l)) ||
    pathname.includes("credit-rejoin") ||
    pathname === "/gift/purchase/account" ||
    pathname.split("/").some((l) => fullPages.includes(l));
  // || pathname === "/gift/redeem/account";
  const isRenewalFlow = /rejoin/.test(pathname);
  const isEnrollFlow = /enroll/.test(pathname);

  const hasDimension = (dimensionName, dimensionValue) => {
    return window?.dataLayer?.some?.(
      (p) => p && p[dimensionName] && p[dimensionName] === dimensionValue,
    );
  };

  const isLanding = useRef(true);
  const { canadaMode, countryCode } = useRegion();
  const isCanadaMode = canadaMode;

  useEffect(() => {
    if (search && typeof queryparser(search).referCode !== "undefined") {
      snesRedirect(entryPoint, `refer/${queryparser(search).referCode}`);
    }
    //For utm promoprice exp
    const parsedQuery = queryparser(location?.search || "");

    if (parsedQuery?.utm_campaign == "intro_price_control") {
      props.setEntryPoint("promoPriceTestV0");
    } else if (parsedQuery?.utm_campaign == "intro_price_1") {
      props.setEntryPoint("promoPriceTestV1");
    } else if (parsedQuery?.utm_campaign == "intro_price_2") {
      props.setEntryPoint("promoPriceTestV2");
    } else if (parsedQuery?.source == "snes") {
      props.setEntryPoint("snes");
    } else if (parsedQuery?.source == "snesOnboard") {
      props.setEntryPoint("snesOnboard");
    } else if (parsedQuery?.exp125SnesVariant == "4") {
      props.setEntryPoint("exp125Snes4");
    } else if (parsedQuery?.exp125SnesVariant == "5") {
      props.setEntryPoint("exp125Snes5");
    }
    //End utm promoprice exp
  }, []);

  useEffect(() => {
    const parsedQuery = queryparser(location?.search || "");

    //removes leads gate from users with leads emails entry point
    if (
      !account &&
      (parsedQuery?.utm_campaign?.includes("Core-Leads") ||
        parsedQuery?.utm_source?.includes("Leads"))
    ) {
      props.setEntryPoint("leadEmail");
    }
  }, []);

  useEffect(() => {
    //  Below use for Direct PDP lionk and back arrow issue. Commented out because conflicting with utm promoprice exp. Need to address.
    if (origin) {
      const originPath = origin.path;
      const scrolltop = origin.scrolltop;
      if (pathname === originPath && scrolltop !== 0) {
        window.scrollTo(0, scrolltop);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
    props.setLocation(
      pathname,
      search,
      window.location.origin,
      "/",
      state,
      countryCode,
    );
    if (
      entryPoint &&
      entryPoint !== "default" &&
      entryPoint !== pathname &&
      isLanding
    ) {
      isLanding.current = false;
    } else {
      isLanding.current = true;
    }
  }, [pathname]);

  useEffect(() => {
    const parsedQuery = queryparser(location?.search || "");
    const isFromSnesEnroll = parsedQuery?.fromSnesEnroll;
    if (!loginAttempted) {
      (async () => {
        try {
          await authUser?.(track, store, isFromSnesEnroll);
        } finally {
          setReadyToRender?.();
        }
      })();
    }
  }, [authUser, track, store, loginAttempted, setReadyToRender]);

  useEffect(() => {
    if (document) {
      [...document.querySelectorAll("input")].forEach((i) =>
        i.addEventListener("invalid", (e) => e.preventDefault()),
      );

      if (!hasDimension("trackId", trackId)) {
        setDimension("trackId", trackId);
      }
      if (search && typeof queryparser(search).irclickid !== "undefined") {
        setClickId(queryparser(search).irclickid);
      }
      setCustomerInfo(account?.id || "", account?.email || "");
    }
  });

  useEffect(() => {
    if (account && account.policy) {
      const policyId = account.policy.id;
      if (!hasDimension("policyIdDimension", `policy-id ${policyId}`)) {
        setDimension("policyIdDimension", `policy-id ${policyId}`);
        if (
          account?.policy?.type === "Rejoin" &&
          account?.policy?.subType !== "Pause"
        ) {
          window.hj =
            window.hj ||
            function () {
              (hj.q = hj.q || []).push(arguments);
            };
          switch (account.policy.subType) {
            case "Rejoin A":
              window.hj("trigger", "Rejoin_A");
              window.hj("tagRecording", ["Rejoin", "Rejoin_A"]);
            case "Rejoin C":
              window.hj("trigger", "Rejoin_C");
              window.hj("tagRecording", ["Rejoin", "Rejoin_C"]);
            case "Rejoin F":
              window.hj("trigger", "Rejoin_F");
              window.hj("tagRecording", ["Rejoin", "Rejoin_F"]);
            case "Gift":
              window.hj("trigger", "Rejoin_Gift");
              window.hj("tagRecording", ["Rejoin", "Rejoin_Gift"]);
          }
        }
      }
      if (account.firstAppLogin) {
        dataLayerTracking({
          event: "appUserLogin",
          firstAppLogin: account.firstAppLogin,
        });
      }
      setCustomerInfo(account?.id || "", account?.email || "");
    } else {
      if (!hasDimension("policyIdDimension", "policy-id none")) {
        setDimension("policyIdDimension", "policy-id none");
      }
    }
  }, [account]);

  return (
    <>
      <OnlyTrack trackId={1}>
        <span className="sr-only" data-nosnippet>
          If you are having difficulty navigating this website please contact us
          at member.services@bookofthemonth.com.
        </span>
        <Meta
          title="Monthly book subscription box | Book of the Month"
          description="Treat yourself to the original book subscription box! We curate each month's best new books so you can spend more time reading and less time researching."
          image="https://static.bookofthemonth.com/landing/lifestyleHero/hero.jpg"
          canonicalUrl="https://www.bookofthemonth.com/"
          keywords={[
            "Book of the Month",
            "Best books",
            "book",
            "books",
            "what should I read next",
            `Best books of ${store?.currentCycle?.description}`,
            "book club",
            "book subscription",
            "good reads",
            "books to read",
            "trending books",
          ]}
        />
      </OnlyTrack>
      <BOMPage
        loginAttempted={props.loginAttempted}
        setOverlay={props.setOverlay}
        isNavless={isNavless}
        isRenewalFlow={isRenewalFlow}
        isEnrollFlow={isEnrollFlow}
        overlay={overlay}
        setStoreData={props.setStoreData}
        setAppFeatures={props.setAppFeatures}
        account={props.account}
        isLanding={isLanding}
        setEntryPoint={props.setEntryPoint}
        setAutoPromoCode={props.setAutoPromoCode}
        isCanadaMode={isCanadaMode}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { analytics } = state;
  return {
    account: state.account,
    isReferred: isReferred(state),
    overlay: state.ui ? state.ui.overlay : null,
    loginAttempted: state.loading.loginAttempted,
    storeLoaded: state.loading.loaded,
    entryPoint: analytics.entryPoint || state.entryPoint,
    origin: state.origin,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOverlay,
      authUser,
      setReadyToRender,
      setStoreData,
      setAppFeatures,
      setEntryPoint,
      setJoinPromo,
      setAutoPromoCode,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => <BOM {...props} />);

if (global.xavier_env === "local") {
  module.hot.accept();
}
