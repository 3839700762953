import styled from "styled-components";
import { COLORS, BORDER, MEDIAQUERIES } from "../../css/Consts";

interface IStyledCardBlock {
  top?: number;
  bottom?: number;
  minWidth?: number;
  maxWidth?: number;
  padding?: string;
  mobilePadding?: string;
  background?: string;
  isHighlighted?: boolean;
  hasShadow?: boolean;
  radius?: number;
  noMobileBorder?: boolean;
}

export const StyledCardBlock = styled.div.attrs({
  className: "sc-StyledCardBlock" as string,
})<IStyledCardBlock>`
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : "3px")};
  max-width: 100%;
  flex: 1;
  padding: ${(props) => (props.padding ? `${props.padding}` : "20px")};
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 0)};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
  background-color: ${(props) =>
    props.background ? `${props.background}` : "var(--color-white)"};
  box-shadow: ${(props) =>
    props.hasShadow ? `0px 2px 4px ${COLORS.gray2}` : "none"};
  border: ${(props) =>
    props.isHighlighted
      ? `1px solid ${COLORS.primary5}`
      : BORDER.defaultBorder};

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    padding: ${(props) =>
      props.mobilePadding ? `${props.mobilePadding}` : "20px"};
    box-shadow: ${(props) =>
      props.hasShadow ? `2px 2px 6.4px rgba(40, 42, 44, 0.08);` : "none"};
    border: ${(props) => {
      if (props.isHighlighted) {
        return `1px solid ${COLORS.primary5}`;
      } else if (props.noMobileBorder) {
        return "transparent";
      } else {
        return BORDER.defaultBorder;
      }
    }};
  }
`;

StyledCardBlock.displayName = "StyledCardBlock";
