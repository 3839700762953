import { combineReducers } from "redux";
import * as actions from "./actions";

const wishlistItems = (state = [], action) => {
  switch (action.type) {
    case actions.SET_WISHLIST:
      return action.payload;
    default:
      return state;
  }
};

const wishlistUpdateSuccess = (state = null, action) => {
  switch (action.type) {
    case actions.SET_WISHLIST_UPDATE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const wishlistUpdateError = (state = null, action) => {
  switch (action.type) {
    case actions.SET_WISHLIST_UPDATE_ERROR:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  wishlistItems,
  wishlistUpdateSuccess,
  wishlistUpdateError,
});
