import React, { Component } from "react";

class LinkBar extends Component {
  constructor(props) {
    super(props);
  }

  onPress(value) {
    this.props.action(value);
  }

  renderLinks() {
    const { links, activeLink } = this.props;
    let baseStyle = styles.linkStyle;
    let activeStyle = {
      backgroundColor: "var(--color-gray2)",
      boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, .2)",
    };

    let linkBlocks = links.map((l, i) => {
      if (l.value == activeLink) {
        activeStyle = {
          backgroundColor: "var(--color-white)",
          boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, .2)",
        };
      } else {
        activeStyle = {
          backgroundColor: "transparent",
        };
      }

      return (
        <div
          onClick={() => this.onPress(l.value)}
          style={{ ...baseStyle, ...activeStyle }}
          key={i}
        >
          <p
            className="smallText"
            style={{ lineHeight: "16px", whiteSpace: "nowrap" }}
          >
            {l.name}
          </p>
        </div>
      );
    });
    return linkBlocks;
  }

  render() {
    const { top = 0, bottom = 0 } = this.props;
    return (
      <div style={{ marginBottom: bottom, marginTop: top }}>
        <div style={styles.barWrapper}>{this.renderLinks()}</div>
      </div>
    );
  }
}

const styles = {
  barWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "var(--color-gray2)",
    backgroundColor: "var(--color-gray1)",
    borderRadius: 50,
    height: 34,
  },
  linkStyle: {
    display: "flex",
    flex: 1,
    // paddingTop: 8,
    // paddingBottom: 6,
    paddingLeft: 14,
    paddingRight: 14,
    borderRadius: 50,
    height: 34,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};

export default LinkBar;
