import React from "react";
import SkeletonWrapper, { H2, H4, P, IMG } from "./SkeletonWrapper.jsx";

const CheckerboardSkeleton = ({ withHeadline = true, count = 3 }) => (
  <SkeletonWrapper className="checkerboard">
    <div className="content -siteWidth">
      {withHeadline && (
        <div className="headerBlock center">
          <H2 />
        </div>
      )}
      {new Array(count).fill(0).map((item, i) => (
        <div className={`-row ${i % 2 === 1 ? "-mobileReverse" : ""}`} key={i}>
          {i % 2 === 0 && <IMG width={"58%"} height={275} />}
          <div className={`-content ${i % 2 === 1 ? "-left" : ""}`}>
            <H4 />
            <P />
          </div>
          {i % 2 === 1 && <IMG width={"58%"} height={275} />}
        </div>
      ))}
    </div>
  </SkeletonWrapper>
);

export default CheckerboardSkeleton;
