import React from "react";
import { useProductDetailLoader } from "../hooks/useProductDetail";
import { useProductLoader } from "../hooks/useProduct";

import { SwagPdp, ProductDetail } from "../../../../types/pdp";

type WithProductDetailProps = {
  getProduct?: (productId: number | string | null | undefined) => {
    pending: boolean;
    error?: Error;
    data?: SwagPdp;
  };
  getProductDetail?: (productId: number | string | null | undefined) => {
    pending: boolean;
    error?: Error;
    data?: ProductDetail;
  };
};

const withProductDetail = <T extends WithProductDetailProps>(
  Component: React.ComponentType<T>,
) => {
  const WithProductDetail = (props: Omit<T, keyof WithProductDetailProps>) => {
    const getProductDetail = useProductDetailLoader();
    const getProduct = useProductLoader();

    return (
      <Component
        {...(props as T)}
        getProduct={getProduct}
        getProductDetail={getProductDetail}
      />
    );
  };
  return WithProductDetail;
};

export { withProductDetail };
