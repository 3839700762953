import * as actions from "./actions";

export function setStoreData(storeData) {
  return {
    type: actions.SET_STORE_DATA,
    payload: storeData,
  };
}
export function setStoreDataExpired() {
  return {
    type: actions.SET_STORE_DATA_EXPIRED,
  };
}

export function replaceGiftPlans(giftPlans) {
  return {
    type: actions.SET_STORE_GIFT_PLANS,
    payload: giftPlans,
  };
}

export function replaceEnrollmentPlans(enrollmentPlans) {
  return {
    type: actions.SET_STORE_ENROLL_PLANS,
    payload: enrollmentPlans,
  };
}

export function replaceRejoinPlans(rejoinPlans) {
  return {
    type: actions.SET_STORE_REJOIN_PLANS,
    payload: rejoinPlans,
  };
}

export function replaceDefaultPolicy(defaultPolicy) {
  return {
    type: actions.SET_DEFAULT_POLICY,
    payload: defaultPolicy,
  };
}

export function setSpecialPlans(specialPlans) {
  return {
    type: actions.SET_STORE_SPECIAL_PLANS,
    payload: specialPlans,
  };
}
