import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import ShipBilling from "UI/pages/join/ShipBilling.jsx";
import PaymentMethodSelect from "UI/components/forms/PaymentMethodSelect.tsx";
import SuccessRejoin from "UI/pages/join/SuccessRejoin.jsx";
import { checkPaidWithApplePay } from "Utils/apple_pay/checkPaidWithApplePay";
import ApplePayRejoinBlock from "UI/pages/join/ApplePayRejoinBlock";
import { Error } from "UI/elements/form/Error";
import { canUseApplePay } from "Utils/apple_pay/canUseApplePay";
import { CreditCommitProvider } from "UI/contexts/CreditCommitContext";

const ShipBillingModal = (props) => {
  const [status, setStatus] = useState("billing");
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState("");
  const hasApplePay = checkPaidWithApplePay(
    props?.account?.paymentMethod?.cardType,
  );
  const canSeeApplePay = canUseApplePay(); //Checks supported device and browser.
  const canPayWithApplePay = hasApplePay && canSeeApplePay;
  const [formShowing, setFormShowing] = useState("ccForm");

  const changeForm = (form) => {
    setFormShowing(form);
  };

  return (
    <CreditCommitProvider>
      <div>
        {status === "billing" ? (
          <ModalEditWrapper
            header={"Update payment method"}
            dek={
              "Your membership failed to renew. Update your payment info for more great reads."
            }
          >
            {error && (
              <Error
                message={
                  "Oops! Please double-check your credit card info and try again."
                }
              />
            )}
            {hasApplePay && formShowing !== "updateCCForm" ? (
              <PaymentMethodSelect
                changePaymentType={changeForm}
                selected={formShowing}
                top={20}
                bottom={0}
              />
            ) : null}
            {["updateCCForm", "ccForm"].includes(formShowing) ? (
              <ShipBilling
                {...props}
                setStatus={setStatus}
                setFormIsValid={setFormIsValid}
              />
            ) : (
              <ApplePayRejoinBlock
                successCB={() => setStatus("success")}
                failCB={(e) => setError(e)}
                clearError={() => setError("")}
                changeForm={changeForm}
                formIsValid={formIsValid}
                formShowing={formShowing}
                canPayWithApplePay={canPayWithApplePay}
              />
            )}
          </ModalEditWrapper>
        ) : (
          <SuccessRejoin removeOverlay={props.removeOverlay} />
        )}
      </div>
    </CreditCommitProvider>
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipBillingModal);
