import * as actions from "./actions";

export function setAccount(account) {
  return {
    type: actions.SET_ACCOUNT,
    payload: account,
  };
}

export function setAccountPaymentMethod(paymentMethod) {
  return {
    type: actions.SET_ACCOUNT_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export function setAccountRenewalPlan(plan) {
  return {
    type: actions.SET_ACCOUNT_RENEWAL_PLAN,
    payload: plan,
  };
}

export function setAccountRenewalDate(date) {
  return {
    type: actions.SET_ACCOUNT_RENEWAL_DATE,
    payload: date,
  };
}

export function setAccountPolicyEffectiveDate(date) {
  return {
    type: actions.SET_ACCOUNT_POLICY_EFFECTIVE_DATE,
    payload: date,
  };
}

export function setAccountReferrals(referrals) {
  return {
    type: actions.SET_ACCOUNT_REFERRALS,
    payload: referrals,
  };
}

export function setAccountAnchorResponse(response) {
  return {
    type: actions.SET_ACCOUNT_ANCHOR_RESPONSE,
    payload: response,
  };
}

export function setChangePasswordFailure() {
  return {
    type: actions.SET_CHANGE_PASSWORD_STATUS,
    payload: "failed",
  };
}
export function setChangePasswordSuccess() {
  return {
    type: actions.SET_CHANGE_PASSWORD_STATUS,
    payload: "success",
  };
}
export function clearChangePasswordStatus() {
  return { type: actions.CLEAR_CHANGE_PASSWORD_STATUS };
}
export function setVisitorId(visitorId) {
  return {
    type: actions.SET_VISITOR_ID,
    payload: visitorId,
  };
}
export function setExperimentVariant(variantId) {
  return {
    type: actions.SET_EXPERIMENT_VARIANT,
    payload: variantId,
  };
}

export function setLoyaltyStats(loyaltyStats) {
  return {
    type: actions.SET_LOYALTY_STATS,
    payload: loyaltyStats,
  };
}
