import React from "react";

const Spinner = ({
  top = 0,
  bottom = 0,
  size,
  position,
}: {
  top?: number;
  bottom?: number;
  size?: string;
  position?: string;
}) => (
  <div
    style={{ marginTop: top + "px", marginBottom: bottom + "px" }}
    className={"spinner " + size + " " + position}
  />
);

export default Spinner;
