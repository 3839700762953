import React from "react";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts";
import DownloadAppButtons from "../../../ui/components/app_download/DownloadAppButtons";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import Meta from "../../../ui/skeleton/Meta.jsx";
import { useWindowSize } from "../../hooks/useWindowSize";

const LitMag: React.FC = () => {
  const [width] = useWindowSize();
  const isMobile = width <= 680;
  const DATA = {
    hed: `Our hot new literary magazine is only available through our app.`,
    dek: `Download the Book of the Month app here.`,
  };

  return (
    <StyledLitMag>
      <Meta
        title="Volume Ø"
        description="We see many great ones in our literary pursuits, and we want to share them with you. So we brought our book passion to short fiction, and carefully gathered seven intense stories, all in one premium soft cover collection."
        canonicalUrl="https://www.bookofthemonth.com/lit-mag/volume-2123"
        keywords={[
          "Literary Magazine",
          "Volume Ø",
          "Volume 0",
          "short stories",
          "magazine",
          "literary",
        ]}
      />
      <StyledFlexWrapper paddingBottom={246}>
        <StyledFlexWrapper
          maxWidth={552}
          paddingTop={isMobile ? 0 : 56}
          paddingBottom={isMobile ? 20 : 40}
          gap={isMobile ? 40 : 56}
          paddingLeft={0}
          paddingRight={0}
        >
          <div className="jacketWrapper">
            <img
              alt="Literary magazine"
              src={
                "//static.bookofthemonth.com/Lit_Mag/Issue 2/02_Realified_VolumeZeroIssue2_higherres.png"
              }
            />
          </div>
          <StyledFlexWrapper
            resetSpacing
            gap={isMobile ? 12 : 20}
            textAlign="center"
            paddingLeft={isMobile ? 20 : 0}
            paddingRight={isMobile ? 20 : 0}
          >
            <h2>{DATA.hed}</h2>
            <p className="p1">{DATA.dek}</p>
          </StyledFlexWrapper>
        </StyledFlexWrapper>
        <DownloadAppButtons />
      </StyledFlexWrapper>
    </StyledLitMag>
  );
};

const StyledLitMag = styled.div`
  .jacketWrapper {
    width: 600px;
    aspect-ratio: 3/2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .jacketWrapper img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    .jacketWrapper {
      width: 100%;
    }
  }
`;

export default LitMag;
