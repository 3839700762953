import globalScope from "../../global"; // For tests, ugh

export async function getWishlist() {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/account_wishlist`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const wishlist = await response.json();
  return wishlist;
}

export async function addWishlistItem(pdpId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/account_wishlist`,
    {
      method: "post",
      body: JSON.stringify({ pdpId }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const wishlist = await response.json();
  return wishlist;
}

export async function deleteWishlistItem(pdpId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/account_wishlist`,
    {
      method: "delete",
      body: JSON.stringify({ pdpId }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const wishlist = await response.json();
  return wishlist;
}
