import * as actions from "./actions";

export function resetCancelStep() {
  return { type: actions.SET_CANCEL_STEP_SKIP };
}

export function setCancelStepSkip() {
  return { type: actions.SET_CANCEL_STEP_SKIP };
}

export function setCancelStepSurvey() {
  return { type: actions.SET_CANCEL_STEP_SURVEY };
}

export function setCancelStepCredits() {
  return { type: actions.SET_CANCEL_STEP_CREDITS };
}

export function setCancelStepStay() {
  return { type: actions.SET_CANCEL_STEP_STAY };
}

export function setCancelStepConfirm() {
  return { type: actions.SET_CANCEL_STEP_CONFIRM };
}

export function setCancelStepSuccess() {
  return { type: actions.SET_CANCEL_STEP_SUCCESS };
}

export function ressetCancelStatus() {
  return { type: actions.RESET_CANCEL_STATUS };
}

export function setCancelStatusPending() {
  return { type: actions.SET_CANCEL_STATUS_PENDING };
}

export function setCancelStatusSuccess() {
  return { type: actions.SET_CANCEL_STATUS_SUCCESS };
}

export function setCancelStatusFailure() {
  return { type: actions.SET_CANCEL_STATUS_FAILURE };
}
