import { AccountWithLoyaltyAndPayment } from "../types";
import { COLORS } from "../css/Consts.js";
import { LoyaltyTier } from "../../../types/account";

export default function getRelationshipStatus(
  account: AccountWithLoyaltyAndPayment,
  overideTier?: string | null,
) {
  const tier = overideTier ? overideTier : account?.loyaltyStats?.loyaltyTier;
  const primaryColor =
    tier == "Friend"
      ? COLORS.friendPrimary
      : tier == "BFF"
      ? COLORS.bffPrimary
      : COLORS.preFriendPrimary;
  const secondaryColor = tier == "Friend" ? COLORS.friendBG : COLORS.bffBG;
  const accentColor =
    tier == "Friend" ? COLORS.friendSecondary : COLORS.bffSecondary;

  return { primaryColor, secondaryColor, accentColor };
}

export const getLoyaltyColors = (loyaltyTier: LoyaltyTier) => {
  let background, primary, shade;
  switch (loyaltyTier) {
    case "New Member":
      background = COLORS.preFriendBG;
      primary = COLORS.preFriendPrimary;
      shade = COLORS.preFriendSecondary;
      break;
    case "Friend":
      background = COLORS.friendBG;
      primary = COLORS.friendPrimary;
      shade = COLORS.friendSecondary;
      break;
    case "BFF":
      background = COLORS.bffBG;
      primary = COLORS.bffPrimary;
      shade = COLORS.bffSecondary;
      break;
    case "Soulmate":
      background = COLORS.soulmateBG;
      primary = COLORS.soulmatePrimary;
      shade = COLORS.soulmateSecondary;
      break;
    default:
      background = COLORS.white;
      primary = COLORS.preFriendPrimary;
      shade = COLORS.preFriendSecondary;
      break;
  }

  return { background, primary, shade };
};
