import React from "react";
import styled from "styled-components";
import LogoBom from "./LogoBom.jsx";
import { COLORS } from "../../css/Consts.js";

const Logo = ({ color = COLORS.black }) => {
  return (
    <StyledLogo>
      <div className="botmLogoLink">
        <LogoBom color={color} />
      </div>
    </StyledLogo>
  );
};

const StyledLogo = styled.div`
  .botmLogoLink {
    width: 100%;
    min-width: 110px;
    max-width: 210px;
    display: flex;
    align-items: center;
  }
`;

export default Logo;
