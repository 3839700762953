import * as actions from "./actions";

export function pureJoinStep(step) {
  return {
    type: actions.SET_JOIN_STEP,
    payload: step,
  };
}

export function setStepJoinStep(step) {
  return {
    type: actions.SET_STEP_JOIN_STEP,
    payload: step,
  };
}

export function resetJoinStep(store_id) {
  return { type: actions.SET_JOIN_STEP_DEFAULT, payload: store_id };
}

export function addJoinGenre(genre) {
  return {
    type: actions.ADD_JOIN_GENRE,
    payload: genre,
  };
}

export function removeJoinGenre(genre) {
  return {
    type: actions.REMOVE_JOIN_GENRE,
    payload: genre,
  };
}

export function setRejoinFrequency(frequency) {
  return {
    type: actions.SET_JOIN_FREQUENCY,
    payload: frequency,
  };
}

export function setJoinTaxRate(tax_rate) {
  return {
    type: actions.SET_JOIN_TAX_RATE,
    payload: tax_rate,
  };
}

export function setJoinFreeBook(product_id) {
  return {
    type: actions.SET_JOIN_FREE_BOOK,
    payload: product_id,
  };
}

export function setJoinPlan(plan) {
  return {
    type: actions.SET_JOIN_PLAN,
    payload: plan,
  };
}

export function setJoinRenewalPlan(plan) {
  return {
    type: actions.SET_JOIN_RENEWAL_PLAN,
    payload: plan,
  };
}

export function setJoinProduct(product) {
  return {
    type: actions.SET_JOIN_PRODUCT,
    payload: product,
  };
}

export function setJoinReferral(code) {
  return {
    type: actions.SET_JOIN_REFERRAL,
    payload: code,
  };
}

export function setJoinHolidayCode(code) {
  return {
    type: actions.SET_JOIN_HOLIDAY_CODE,
    payload: code,
  };
}

export function setLoginViaFB() {
  return {
    type: actions.SET_LOGIN_VIA_FB,
  };
}

export function setJoinPromo(promo) {
  return {
    type: actions.SET_JOIN_PROMO,
    payload: promo,
  };
}

export function setJoinStatusPending() {
  return {
    type: actions.SET_JOIN_STATUS,
    payload: actions.JOIN_STATUS_PENDING,
  };
}

export function clearJoinStatus() {
  return { type: actions.CLEAR_JOIN_STATUS };
}

export function setJoinStatusFail() {
  return {
    type: actions.SET_JOIN_STATUS,
    payload: actions.JOIN_STATUS_FAIL,
  };
}

export function setJoinError(error) {
  return {
    type: actions.SET_JOIN_ERROR,
    payload: error,
  };
}

export function setGiftCode(code) {
  return {
    type: actions.SET_GIFT_CODE,
    payload: code,
  };
}

export function setPlanOpenRedirect() {
  return { type: actions.SET_PLAN_OPEN_REDIRECT };
}

export function setGiftPurchaseRedirect() {
  return { type: actions.SET_GIFT_PURCHASE_REDIRECT };
}

export function setJoinEmail(email) {
  return {
    type: actions.SET_JOIN_EMAIL,
    payload: email,
  };
}

export function setJoinShipping(shipping) {
  return {
    type: actions.SET_JOIN_SHIPPING,
    payload: shipping,
  };
}
export function setJoinPassword(password) {
  return {
    type: actions.SET_JOIN_PASSWORD,
    payload: password,
  };
}
export function setJoinAccount(account) {
  return {
    type: actions.SET_JOIN_ACCOUNT,
    payload: account,
  };
}

export function setNewMember() {
  return { type: actions.SET_NEW_MEMBER };
}

export function clearNewMember() {
  return { type: actions.CLEAR_NEW_MEMBER };
}

export function clearJoinError() {
  return { type: actions.CLEAR_JOIN_ERROR };
}

export function clearJoinData() {
  return { type: actions.CLEAR_JOIN_DATA };
}

export function setJoinStepSkipPlan() {
  return { type: actions.SET_JOIN_STEP_SKIPPED_PLAN };
}

export function clearJoinStepSkipPlan() {
  return { type: actions.CLEAR_JOIN_STEP_SKIPPED_PLAN };
}

export function setOrderId(orderId) {
  return { type: actions.SET_ORDER_ID, payload: orderId };
}

export function setJoinChildsInfo(info) {
  return {
    type: actions.SET_JOIN_CHILDS_INFO,
    payload: info,
  };
}

export function setJoinPromoError(error) {
  return { type: actions.SET_JOIN_PROMO_ERROR, payload: error };
}

export function clearJoinPromoError() {
  return { type: actions.CLEAR_JOIN_PROMO_ERROR };
}

export function setGrouponCode(code) {
  return {
    type: actions.SET_GROUPON_CODE,
    payload: code,
  };
}

export function setGiftOffer(giftOffer) {
  return {
    type: actions.SET_GIFT_OFFER,
    payload: giftOffer,
  };
}

export function setSurveyAuth(surveyAuth) {
  return {
    type: actions.SET_SURVEY_AUTH,
    payload: surveyAuth,
  };
}

export function setGrifterCode(code) {
  return { type: actions.SET_GRIFTER_PROMO_CODE, payload: code };
}

export function setGiftRedirect() {
  return { type: actions.SET_GIFT_REDIRECT };
}

export function setJoinType(type) {
  return { type: actions.SET_JOIN_TYPE, payload: type };
}

export function setGiftRejoin() {
  return { type: actions.SET_GIFT_REJOIN };
}

export function setRejoinBasePath(rejoinBase) {
  return { type: actions.SET_REJOIN_BASE_PATH, payload: rejoinBase };
}

export function setFauxBox(fauxBox) {
  return { type: actions.SET_FAUX_BOX, payload: fauxBox };
}

export function setAutoPromoCode(promoCode) {
  return {
    type: actions.SET_AUTO_PROMO_CODE,
    payload: promoCode,
  };
}
