import { IPlan } from "../../../types/plan";

export const getPlans = (
  planSet: Record<string, Record<string, IPlan[]>>,
  trackId?: number,
): IPlan[] => {
  if (!(planSet && planSet.tracks)) {
    return [];
  }
  if (trackId && planSet.tracks[trackId]) {
    return planSet.tracks[trackId];
  } else {
    if (planSet.tracks.all) {
      return planSet.tracks.all;
    } else {
      return Object.values(planSet.tracks)
        .flatMap((p) => p)
        .sort((a, b) => a.cycles - b.cycles);
    }
  }
};
