// gift redemption steps
export const SET_GIFT_REDEEM_STEP = "SET_GIFT_STEP";
export const SET_GIFT_REDEEM_STEP_DEFAULT = "SET_GIFT_REDEEM_STEP_DEFAULT";
export const GIFT_REDEEM_REDEEM = "redeem";
export const GIFT_REDEEM_ACCOUNT = "account";
export const GIFT_REDEEM_CONFIRMATION = "confirmation";
export const GIFT_REDEEM_CHILD = "childs-info";
export const GIFT_REDEEM_SHIPPING = "shipping";
export const GIFT_REDEEM_WELCOME = "welcome";

// gift purchase steps
export const SET_GIFT_STEP = "SET_GIFT_STEP";
export const SET_GIFT_STEP_DEFAULT = "SET_GIFT_STEP_DEFAULT";
export const GIFT_PLAN = "give";
export const GIFT_DELIVERY_METHOD = "gift-delivery-method";
export const GIFT_ADDRESS = "gift-address";
export const GIFT_INFO = "gift-info";
export const GIFT_BILLING = "gift-billing";
export const GIFT_CONFIRMATION = "purchase-confirmation";
export const GIFT_ACCOUNT = "gift-account";

//gift purchase data
export const SET_GIFT_PLAN = "SET_GIFT_PLAN";
export const SET_DELIVERY_METHOD = "SET_DELIVERY_METHOD ";
export const SET_GIFT_INFO = "SET_GIFT_INFO";
export const CLEAR_GIFT_INFO = "CLEAR_GIFT_INFO";
export const SET_GIFT_TAX_RATE = "SET_GIFT_TAX_RATE";
export const SET_GIFT_PERSONALIZATIONS = "SET_GIFT_PERSONALIZATIONS";
export const SET_GIFT_IS_LEAD = "SET_GIFT_IS_LEAD";
export const SET_GIFT_IS_MEMBER = "SET_GIFT_IS_MEMBER";
export const SET_GIFT_PURCHASE_PROMO_ERROR = "SET_GIFT_PURCHASE_PROMO_ERROR";
export const CLEAR_GIFT_PURCHASE_PROMO_ERROR =
  "CLEAR_GIFT_PURCHASE_PROMO_ERROR";
export const SET_GIFT_PURCHASE_PROMO = "SET_GIFT_PURCHASE_PROMO";
export const SET_GIFT_PURCHASE_ADDRESS = "SET_GIFT_PURCHASE_ADDRESS";
export const SET_GIFT_PURCHASE_CORPORATE_CODE =
  "SET_GIFT_PURCHASE_CORPORATE_CODE";

//CORPORATE GIFT INQUIRY FORM SUBMISSION
export const CORP_INQUIRY_STATUS_SUCCESS = "CORP_INQUIRY_STATUS_SUCCESS";
export const CORP_INQUIRY_STATUS_FAILURE = "CORP_INQUIRY_STATUS_FAILURE";
export const SET_CORP_INQUIRY_ERROR = "SET_CORP_INQUIRY_ERROR";

//Canada
export const SET_CURRENCY_CODE = "SET_CURRENCY_CODE";
