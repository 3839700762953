import React from "react";
import SkeletonWrapper, { H2, P } from "./SkeletonWrapper.jsx";
import { FormSkeleton } from "./FormPageSkeleton.jsx";

const EmailCaptureSkeleton = ({ numInput, withButton }) => (
  <SkeletonWrapper className="emailGate">
    <div className="content -narrow">
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <H2 />
        <P />
      </div>
      <FormSkeleton
        numInput={numInput || 3}
        withButton={withButton || true}
        buttonStyle="fullWidth"
      />
    </div>
  </SkeletonWrapper>
);

export default EmailCaptureSkeleton;
