import { IAppFeature, PopulationType } from "../../../types/app_feature";

const showAppFeature = (
  appFeature?: IAppFeature,
  countryCode?: PopulationType,
): boolean => {
  if (!(appFeature && countryCode)) {
    return false;
  }
  const { status, web, population } = appFeature;

  const platformCheck = Boolean(web);
  const regionCheck = population == "All" || population == countryCode;
  const statusCheck =
    status == "Active" || (status == "Beta" && !!appFeature.isBetaEligible);

  return platformCheck && regionCheck && statusCheck;
};

export default showAppFeature;
