/**
 * HowItWorks.jsx
 * Child of Landing.jsx
 * Returns section containing track specific text and images explaining membership
 */
import React from "react";
import SkeletonWrapper, { InvisibleBlock, P } from "./SkeletonWrapper.jsx";

const HowItWorksSkel = () => {
  return (
    <SkeletonWrapper>
      <div className="content -narrow center">
        <InvisibleBlock width="80px" height="80px" />
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
        <InvisibleBlock width="80px" height="80px" />
      </div>
    </SkeletonWrapper>
  );
};

export default HowItWorksSkel;
