/**
 * Displays dashboard links in header.
 * Child of Header.jsx
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { withStoreData } from "UI/hoc/withStoreData";
import { canJoinAndShip } from "State/account/selectors";
import { boxCount } from "State/box/selectors";
import { setOverlay } from "State/ui/creators";
import { logout } from "State/account/flows";
import { experimentFlowTracking } from "Utils/analytics";
import CreditsNav from "./CreditsNav.jsx";
import BoxNav from "UI/skeleton/BoxNav";
import { Avatar } from "UI/components/account/Avatar";
import ClickTracker from "UI/elements/ClickTracker";
import { useWindowSize } from "UI/hooks";
import { withRegion } from "UI/hoc";
import { Button } from "UI/elements/form/Button";
import { snesRedirect } from "Utils/snesRedirect";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountNav: "-1",
      accountClassName: "subNav -rightAligned",
    };

    this.gaTracking = this.gaTracking.bind(this);
    this.adaSubNav = this.adaSubNav.bind(this);
    this.signUpModal = this.signUpModal.bind(this);
  }

  logOut() {
    this.props.logout();
    if (this.props.setHideMobileNav) {
      this.props.setHideMobileNav();
    }
    this.props.push("/");
    return false;
  }

  navigate(path) {
    this.props.push(path);
    if (typeof document !== "undefined") {
      document.getElementById("bodyWrapper").className = "modalClosed";
    }
    return true;
  }

  gaTracking() {
    experimentFlowTracking("Click Signup");
  }
  adaSubNav() {
    if (this.state.showAccountNav == "-1") {
      this.setState({
        showAccountNav: "0",
        accountClassName: "subNav -rightAligned ada",
      });
    } else {
      this.setState({
        showAccountNav: "-1",
        accountClassName: "subNav -rightAligned",
      });
    }
  }
  signUpModal() {
    if (this.props.entryPoint !== "leadEmail") {
      this.props.setOverlay("signUpModal");
    } else {
      this.props.push("/step-join");
    }
  }

  render() {
    let {
      isMember,
      account,
      isCourtesy,
      appFeatures,
      trueNorthFeature,
      canadaMode,
      push,
      isMobile,
      isDarkMode,
    } = this.props;

    const logOutIn = account ? (
      <li aria-hidden="true" className="mobileButton nav">
        <a
          tabIndex={this.state.showAccountNav}
          href="#"
          onClick={this.logOut.bind(this)}
          onBlur={this.adaSubNav}
        >
          Log out
        </a>
      </li>
    ) : (
      <Button
        size={14}
        tabIndex="-1"
        to="/login"
        handleClick={() => snesRedirect("snes", "/login")}
        onKeyPress={() => snesRedirect("snes", "/login")}
        style={`-headerNav login ${isDarkMode ? "darkMode" : ""}`}
        title={"Log in"}
        logClickData={"Clicked-header-log-in"}
      ></Button>
    );

    const showTrueNorthFeature =
      (trueNorthFeature?.status == "Beta" &&
        trueNorthFeature?.isBetaEligible) ||
      trueNorthFeature?.status == "Active";
    const shouldShowSignUpButton =
      !this.props.isMobile &&
      (!canadaMode || (canadaMode && showTrueNorthFeature));

    const signUpButton = shouldShowSignUpButton ? (
      <ClickTracker
        ctaType="button"
        size={14}
        tabIndex="0"
        handleClick={this.signUpModal}
        onKeyPress={this.signUpModal}
        style={"button -headerNav darkMode link-p2 signUp"}
        title={"Sign up"}
        logClickData={"Clicked-header-sign-up"}
      />
    ) : null;

    let action = account ? (
      <BoxNav
        aria-hidden="true"
        setHideMobileNav={this.props.setHideMobileNav}
        isDarkMode={isDarkMode}
      />
    ) : (
      signUpButton
    );

    const connections = appFeatures?.find((f) => f?.feature == "Connections");
    const socialUser =
      (connections?.status == "Beta" && this.props?.account?.isBeta) ||
      connections?.status == "Active";
    let accountAvatar;
    if (socialUser) {
      accountAvatar = account?.profileImg ? account?.profileImg : "";
    } else {
      accountAvatar = account?.pictureUrl ? account?.pictureUrl : "";
    }

    return (
      <ul className={`nav dashboard ${isDarkMode ? "darkMode" : ""}`}>
        {!isMember && !isCourtesy && !isMobile && logOutIn}
        {(isMember || isCourtesy) && [
          <li
            tabIndex="0"
            className="forDesktop"
            key={"account"}
            onKeyPress={this.adaSubNav}
          >
            <span>Account</span>
            <Avatar
              showEdit={false}
              size={24}
              left={6}
              avatarImage={accountAvatar}
              isSocial={socialUser}
            />
            <div className={this.state.accountClassName}>
              <div className="navPointer" />
              <ul className="accountDropdownMenu">
                <li>
                  <ClickTracker
                    aria-hidden="true"
                    tabIndex={this.state.showAccountNav}
                    to="/my-account/account"
                    ctaType={"navLink"}
                    logClickData={"Clicked-header-account-details"}
                    title={"Account details"}
                    customClass="p3 doNotStyle"
                  />
                </li>
                <li>
                  <ClickTracker
                    aria-hidden="true"
                    tabIndex={this.state.showAccountNav}
                    to="/my-account/orders"
                    ctaType={"navLink"}
                    logClickData={"Clicked-header-my-orders"}
                    title={"My orders"}
                    customClass="p3 doNotStyle"
                  />
                </li>
                {account?.policy?.type == "Member" &&
                account?.policy.subType === "Gift" &&
                !account?.renewalPlan ? (
                  <li>
                    <ClickTracker
                      aria-hidden="true"
                      tabIndex={this.state.showAccountNav}
                      to="/gift-offer"
                      ctaType={"navLink"}
                      logClickData={"Clicked-header-gift-offer"}
                      title={"Choose a renewal plan"}
                      customClass="p3 doNotStyle"
                    />
                  </li>
                ) : (
                  <li>
                    <ClickTracker
                      aria-hidden="true"
                      tabIndex={this.state.showAccountNav}
                      to="/my-account/membership"
                      ctaType={"navLink"}
                      logClickData={"Clicked-header-my-membership"}
                      title={"My plan"}
                      customClass="p3 doNotStyle"
                    />
                  </li>
                )}
                {(isMember || isCourtesy) && (
                  <li>
                    <ClickTracker
                      aria-hidden="true"
                      tabIndex={this.state.showAccountNav}
                      to={"/to-be-read"}
                      ctaType={"navLink"}
                      logClickData={"Clicked-header-to-be-read"}
                      title={"To be read"}
                      customClass="p3 doNotStyle"
                    />
                  </li>
                )}
                <li>
                  <ClickTracker
                    aria-hidden="true"
                    tabIndex={this.state.showAccountNav}
                    to={"/help-center"}
                    ctaType={"navLink"}
                    logClickData={"Clicked-header-help-center"}
                    title={"Help center"}
                    customClass="p3 doNotStyle"
                  />
                </li>
                {logOutIn}
              </ul>
            </div>
          </li>,
          <CreditsNav
            role="group"
            key={"credits"}
            navigate={this.navigate}
            account={this.props.account}
            path={this.props.path}
            overlay={this.props.overlay}
            setHideMobileNav={this.props.setHideMobileNav}
          />,
        ]}
        {action}
      </ul>
    );
  }
}

const DashBoardFC = (props) => {
  const [width] = useWindowSize();
  const isMobile = width < 960;
  return <Dashboard {...props} isMobile={isMobile} />;
};

function mapStateToProps(state) {
  const { appFeatures } = state;
  const swagAppFeature = appFeatures?.find((f) => f?.feature == "Swag shop");
  const isSwagShopActive = swagAppFeature?.status == "Active";
  const trueNorthFeature = state?.appFeatures?.find(
    (f) => f?.feature == "True North",
  );

  return {
    store: state,
    account: state.account,
    policy: state.account ? state.account.policy : state.policy,
    canJoinAndShip: canJoinAndShip(state),
    overlay: state.ui ? state.ui.overlay : null,
    boxCount: boxCount(state),
    path: state.analytics.location,
    isMember: state.policy.type === "Member" || state.policy.type === "Rejoin",
    isCourtesy: state?.policy?.type === "Courtesy",
    isSwagShopActive,
    appFeatures: state.appFeatures || [],
    isMD22: state.promo.md22,
    entryPoint: state.entryPoint,
    trueNorthFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, setOverlay, push }, dispatch);
}

export default withRegion(
  withStoreData(connect(mapStateToProps, mapDispatchToProps)(DashBoardFC)),
);
