import * as actions from "./actions";

export function setStoreLoaded() {
  return {
    type: actions.SET_STORE_LOADED,
  };
}
export function setLoginAttempt() {
  return {
    type: actions.SET_LOGIN_ATTEMPT,
  };
}
export function setReadyToRender() {
  return {
    type: actions.SET_READY_TO_RENDER,
  };
}
export function setLandingTime() {
  return (dispatch) => {
    dispatch({
      type: actions.SET_LANDING_TIME,
    });
    if (setTimeout) {
      setTimeout(() => dispatch(setReadyToRender()), 750);
    }
  };
}

export function setCourtesyPassword(payload) {
  return {
    type: actions.SET_COURTESY_PASSWORD,
    payload,
  };
}
