import globalScope from "../../global"; // For tests, ugh

export async function all() {
  const response = await fetch(`${global.apiHost}/api/v0/track`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const tracks = await response.json();
  return tracks;
}

export async function get(id: number) {
  const response = await fetch(`${global.apiHost}/api/v0/track/${id}`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const track = await response.json();
  return track;
}
