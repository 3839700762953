import * as actions from "./actions";

export default function reducer(state = null, action) {
  switch (action.type) {
    case actions.SET_REJOIN_STATUS:
      return {
        ...state,
        didRejoinWithBook: action.payload,
      };
    default:
      return state;
  }
}
