import { useContext } from "react";
import { GiftFormContext } from "../contexts/GiftFormContext";

const useGiftForm = () => {
  const {
    isFormValid,
    setIsFirstFormValid,
    setIsSecondFormValid,
    firstFormState,
    secondFormState,
    setFormValue,
    clearForm,
    mapToReduxState,
    setNameAndEmailForMembers,
  } = useContext(GiftFormContext);

  return {
    isFormValid,
    setIsFirstFormValid,
    setIsSecondFormValid,
    firstFormState,
    secondFormState,
    setFormValue,
    clearForm,
    mapToReduxState,
    setNameAndEmailForMembers,
  };
};

export { useGiftForm };
