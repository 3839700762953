import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import FieldsetBirthday from "./forms/FieldsetBirthday";
import { AppState, Dispatch } from "../../types";
import { setOverlay } from "../../state/ui/creators";

type BirthdayModalProps = {
  setOverlay: (name: string) => void;
} & MappedBirthdayProps;

const Birthday: React.FC<BirthdayModalProps> = ({ account, setOverlay }) => {
  const isBFF = account?.loyaltyStats?.loyaltyTier == "BFF";
  const exit = () => {
    setOverlay("");
  };

  const img = isBFF
    ? "Relationship/BFF/BFF_Bday_Book.png"
    : "Relationship/birthday_img.png";
  const hed = isBFF
    ? "Ain’t no birthday like a BFF birthday."
    : "We’re big on birthdays here.";
  const dek = isBFF
    ? "Tell us your birthday and we’ll gift you a free add-on during your birthday month."
    : `Share your birthday and we’ll send you a special gift once you become a
  BFF.`;

  return (
    <StyledBirthday className="-content">
      <picture className={isBFF ? "-bff" : "-friend"}>
        <source
          srcSet={`///static.bookofthemonth.com/${img}`}
          type="image/jpg"
        />
        <img src={`//static.bookofthemonth.com/${img}`} className="icons" />
      </picture>
      <h4>{hed}</h4>
      <p className="smallText">{dek}</p>
      <StyledFormWrapper>
        <FieldsetBirthday
          trackLinkLabel={"postCycle-birthday-popup"}
          isFullWidthButton={true}
          nextAction={() => exit()}
          cta={"Next"}
          optional={true}
          postCycleModal
        />
      </StyledFormWrapper>
    </StyledBirthday>
  );
};

export const StyledBirthday = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  .-friend {
    img {
      width: 176px;
      height: 115px;
    }
  }
  .-bff {
    img {
      width: 135px;
      height: 115px;
    }
  }
  h4 {
    padding-top: 40px;
    text-align: center;
  }
  .smallText {
    margin-top: 20px;
    text-align: center;
  }
  @media screen and (max-width: 680px) {
    padding-top: 40px;
  }
`;

export const StyledFormWrapper = styled.div.attrs({
  className: "sc-StyledFormWrapper",
})`
  padding-top: 40px;
  @media screen and (max-width: 680px) {
    padding-top: 42px;
    width: 100%;
  }
`;
type MappedBirthdayProps = {} & Pick<AppState, "account">;
function mapStateToProps(state: AppState): MappedBirthdayProps {
  return {
    account: state.account,
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ setOverlay }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
