import { createSelector } from "reselect";

const getStoreData = (state) => state.store.data;
const getStore = (state) => state.store;
const getAccount = (state) => state.acount;
const MODULUS = 65537;
const modPow = (base, exponent) => {
  let result = 1;
  base = base % MODULUS;
  while (exponent > 0) {
    if (exponent % 2 === 1) {
      result = (result * base) % MODULUS;
    }
    exponent = exponent >> 1;
    base = (base * base) % MODULUS;
  }
  return result;
};
export const getTheme = createSelector(getStoreData, (data) => data.theme);

export const getTrack = createSelector(getStore, (store) => store.track);

export const phoneFlexForcePhone = createSelector(
  getStoreData,
  getAccount,
  (data, account) => {
    const cancelExpKey = 12345;
    const bucket = modPow(cancelExpKey, account && account.id);
    return (
      typeof data.flexPercent !== "undefined" &&
      data.flexPercent !== null &&
      bucket <= Math.floor(65537 * (data.flexPercent / 100))
    );
  },
);
