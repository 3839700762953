/**
 * Shared comnponent used to display and utilize input field for promo codes.
 * Child of Giftbilling.jsx and Billing.jsx
 */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { useStoreData } from "UI/hooks/useStoreData";
import styled from "styled-components";
import { getPromo } from "State/promo/flows";
import { addToBox, removeFromBox, makeSelection } from "State/box/flows";
import {
  setJoinPlan,
  setJoinPromo,
  setJoinFreeBook,
  setJoinPromoError,
  clearJoinPromoError,
} from "State/joinData/creators";
import {
  setGiftPurchasePlan,
  clearGiftPurchasePromoError,
  setGiftPurchasePromo,
} from "State/gift/creators";
import { setJoinFlow } from "State/joinFlow/creators";
import ClickTracker from "UI/elements/ClickTracker";
import DynamicInput from "UI/elements/form/DynamicInput";
import { RegionContext } from "UI/contexts/RegionContext";
import { COLORS } from "CSS/Consts.js";

const PromoCodeInput = (props) => {
  const { locationHeader } = useContext(RegionContext);
  const { track } = useStoreData();
  let {
    promoCode,
    promoError,
    joinData,
    giftPurchaseData,
    giftFlow,
    getPromo,
    renewalFlow,
    account,
    box,
    setGiftPurchasePromo,
    clearGiftPurchasePromoError,
    clearJoinPromoError,
    setJoinPromo,
    isReferral,
    currencyCode,
  } = props;

  const [promo, setPromo] = useState(
    props.promo ||
      (!!props.promoError && !!props.promoError.code
        ? props.promoError.code
        : null),
  );
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [activeField, setActiveField] = useState("");
  // const [originalGiftPlan] = useState(giftPurchaseData.plan);

  let giftPurchasePromoCode = giftPurchaseData?.promo?.code,
    joinDataPromoCode = joinData?.promo?.code;

  useEffect(() => {
    setFormSubmitting(false);
  }, [props.promo]);

  useEffect(() => {
    if (joinData.plan && !joinData.plan.promo) {
      setFormSubmitting(false);
    } else if (joinData.plan && joinData.plan.promo) {
      setPromo(joinData.promo.code);
      setFormSubmitting(false);
    }
  }, [joinData]);

  useEffect(() => {
    if (giftPurchaseData.plan && !giftPurchaseData.promo) {
      setPromo(null);
      setFormSubmitting(false);
    } else if (giftPurchaseData && giftPurchaseData.promo) {
      setPromo(giftPurchaseData.promo.code);
      setFormSubmitting(false);
    }
  }, [giftPurchaseData]);

  useEffect(() => {
    if (promoError) {
      // setPromo(promoError.code);
      setFormSubmitting(false);
    }
  }, [promoError]);

  const getPlan = useCallback(
    async (evt) => {
      evt?.preventDefault?.();
      let planId = giftFlow ? giftPurchaseData?.plan?.id : joinData?.plan?.id;

      if (promo && planId) {
        setFormSubmitting(true);
        // (async () => {
        // await getPromo({
        await getPromo({
          promo: promo,
          promoType: giftFlow ? "gift" : renewalFlow ? "rejoin" : "enroll",
          planId,
          trackId: track.id,
          countryCode:
            giftFlow && currencyCode === "CAD"
              ? "CA"
              : locationHeader?.countryHeader,
        });
        if (joinData?.promo?.promoProducts && account) {
          if (joinData.promo.productConsumesCredit) {
            joinData.promo.promoProducts
              .filter(
                (v) => !box || !box.items.some((bi) => bi.product.id === v.id),
              )
              .forEach((v) => makeSelection(v.id));
          } else {
            joinData.promo.promoProducts
              .filter(
                (v) => !box || !box.items.some((bi) => bi.product.id === v.id),
              )
              .forEach((v) => addToBox(v.id));
          }
        }
        setFormSubmitting(false);
        setPromo(promo);
      }
    },
    [
      account,
      box,
      getPromo,
      giftFlow,
      giftPurchaseData,
      joinData,
      promo,
      renewalFlow,
    ],
  );

  const handleChange = (promoCode) => {
    setPromo(promoCode);
    if (joinData && joinData.promoError) {
      clearJoinPromoError();
    }
  };

  const undoPromo = useCallback(
    (evt) => {
      evt?.preventDefault?.();
      setPromo("");
      if (giftFlow) {
        setGiftPurchasePromo(null);
        clearGiftPurchasePromoError();
      } else {
        setJoinPromo(null);
        clearJoinPromoError();
      }
    },
    [giftFlow],
  );

  let promoCodeInput = <div />;
  let displayedCode = null;
  let buttonText = "Apply";
  let buttonAction = getPlan;

  const minMonthPromos = ["BESTOF2018", "NEVERTOOLATE", "BTBF"];

  if (giftFlow && giftPurchasePromoCode) {
    return (
      <StyledGiftPromo>
        <p>{giftPurchasePromoCode} promo applied!</p>
        <a onClick={undoPromo}>Remove</a>
      </StyledGiftPromo>
    );
  } else if (!giftFlow && !renewalFlow && joinDataPromoCode) {
    buttonText = "Remove";
    buttonAction = undoPromo;
  }

  if (promoError !== null && typeof promoError === "object") {
    promoError = promoError.message + " ";
    if (
      minMonthPromos.indexOf(promo) > -1 &&
      giftPurchaseData &&
      giftPurchaseData.plan &&
      giftPurchaseData.plan.credits <= 3
    ) {
      promoError = "Sorry, this code is not valid for a 3-month membership.";
    }
  }

  if (promo && promoError) {
    buttonText = "Remove";
    buttonAction = undoPromo;
  }
  let discountText = null;

  if ((!promoCode || promo || promoError) && !isReferral) {
    promoCodeInput = (
      <>
        <StyledPromoInputWrapper>
          <DynamicInput
            placeholder={activeField == "promoCode" ? "Code" : "Promo code"}
            label="Promo code"
            value={displayedCode ? displayedCode : promo ? promo : ""}
            accessibilityLabel={`Promo code`}
            onChangeText={(promoCode) => {
              handleChange(promoCode);
            }}
            onFocus={() => setActiveField("promoCode")}
            onBlur={() => setActiveField("")}
            isInFocus={activeField == "promoCode"}
            clearIt={() => handleChange("")}
            error={promoError ? promoError : null}
            name={"promo-code"}
            inputId={"promo-code"}
            autoComplete={"off"}
          />
          <ClickTracker
            ctaType={"button"}
            id="promo-apply-button"
            type="submit"
            style={promo ? "primary" : "secondary"}
            handleClick={() => buttonAction()}
            isPending={formSubmitting && promo}
            title={buttonText}
            isDisabled={!promo}
            logClickData={`Clicked- Apply promo - ${promo?.id}`}
            maxWidth={80}
            minWidth={80}
            customStyles={{
              padding: 12,
              borderRadius: " 0 4px 4px 0",
              fontSize: 14,
              height: 48,
            }}
          />
        </StyledPromoInputWrapper>
        {!promoError && discountText}
      </>
    );
  }

  return promoCodeInput;
};

function mapStateToProps(state, ownProps) {
  return {
    promoError: state.joinData.promoError || state.gift.giftPurchase.promoError,
    showContent:
      ownProps.showContent ||
      state.joinData.promoError ||
      state.gift.giftPurchase.promoError,
    joinData: state.joinData,
    giftPurchaseData: state.gift.giftPurchase,
    currencyCode: state.gift.currencyCode,
    box: state.box,
    path: state.analytics.location,
    entryPoint: state.entryPoint,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setJoinPlan,
      setJoinPromo,
      setJoinFreeBook,
      push,
      setJoinPromoError,
      clearJoinPromoError,
      setGiftPurchasePlan,
      clearGiftPurchasePromoError,
      getPromo,
      setJoinFlow,
      setGiftPurchasePromo,
      addToBox,
      removeFromBox,
      makeSelection,
    },
    dispatch,
  );
}

export const StyledPromoInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const StyledGiftPromo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  p {
    color: ${COLORS.gray4};
    font-style: italic;
    font-size: 14px;
  }
  a {
    font-size: 14px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeInput);
