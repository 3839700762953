import React from "react";
import { Jacket, IMG } from "./SkeletonWrapper.jsx";

const ShortBlock = ({ children }) => (
  <div className="pdShortBlock">
    <div className="jacketWrapper">
      <Jacket>
        <IMG />
      </Jacket>
    </div>
    {children}
  </div>
);

export default ShortBlock;
