import React from "react";

import { useStoreData } from "UI/hooks/useStoreData";

const OnlyTrack = ({ trackId, children }) => {
  const { track } = useStoreData();
  return trackId && trackId === (track?.id || 1) && <>{children}</>;
};

export default OnlyTrack;
