import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types";
import { logClick } from "../../state/analytics/flows";

type PointerLeftProps = {
  size?: "default" | "large";
  fill: string;
  handleClick: () => void;
  height: number;
  id?: string;
  logClickData?: () => void;
  pointerPrevActive: boolean;
  state: AppState;
  styles?: any; // tests fail with React.CSSProperties | undefined
  width: number;
};

const PointerLeft: React.FC<PointerLeftProps> = ({
  size = "default",
  pointerPrevActive,
  handleClick,
  styles,
  width = 18.25,
  height = 34,
  fill = "var(--color-primary5)",
  logClickData,
  id,
  state,
}) => {
  const clickAction = () => {
    if (logClickData) {
      logClick(logClickData, { getState: () => state });
    }
    handleClick();
  };

  const defaultPointer = (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 18.25 34"
      enableBackground="new 0 0 18.25 34"
    >
      <polygon
        fill={fill}
        points="17.157,0.343 0.257,17.244 17.014,34 18.029,32.985 2.288,17.243 18.172,1.357 "
      />
    </svg>
  );
  const largePointer = (
    <svg width="52" height="118" viewBox="0 0 52 118" fill="none">
      <g opacity="0.6">
        <path
          d="M50 1L1.99999 59L50 117"
          stroke="var(--color-gray2)"
          strokeWidth="3"
        />
      </g>
    </svg>
  );

  const pointer = {
    default: defaultPointer,
    large: largePointer,
  };

  return (
    <div
      className={"pointer left" + (pointerPrevActive ? "" : " hide")}
      onClick={clickAction}
      style={styles}
      id={id}
    >
      {pointer[size]}
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(PointerLeft);
