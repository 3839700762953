import { IPlan } from "../../../../types/plan";
import { IPaymentRequestWeb } from "../../../../types/apple_pay";
import { CountryCode } from "../../../../types/address";
import { IPromo } from "../../../../types/promo";
import { AdditionalFees } from "../../ui/hooks/useQuote";

export const createApplePayRequest = (
  subtotal: number,
  tax: number,
  plan?: IPlan | null,
  countryCode: CountryCode = "US",
  addOnsTotal?: number,
  promo?: IPromo,
  additionalFees?: AdditionalFees,
) => {
  const paymentRequest: IPaymentRequestWeb = {
    total: {
      label: "Book of the Month",
      amount: setTotal(
        subtotal,
        tax,
        addOnsTotal,
        promo,
        additionalFees?.additionalFeesTotal,
      ),
    },
    lineItems: [
      {
        label: `${plan ? "Subscription" : "Subtotal"}`,
        amount: subtotal,
      },
    ],
    countryCode: countryCode,
    currencyCode: currencyCodes[countryCode],
  };

  if (addOnsTotal && addOnsTotal != 0) {
    paymentRequest.lineItems.push({
      label: "Add-ons",
      amount: addOnsTotal,
    });
  }

  if (promo && promo.value) {
    addPromoLine(paymentRequest, promo);
  }

  if (additionalFees && additionalFees.additionalFeesTotal > 0) {
    additionalFees.additionalFees.map((fee) => {
      paymentRequest.lineItems.push({
        label: fee.feeName,
        amount: fee.amount,
      });
    });
  }

  if (tax) {
    paymentRequest.lineItems.push({
      label: "Estimated tax",
      amount: tax,
    });
  }

  if (plan) {
    addSubscription(paymentRequest, plan);
  }

  return paymentRequest;
};

function setTotal(
  subtotal: number,
  tax: number,
  addOnsTotal = 0,
  promo?: IPromo,
  additionalFeesTotal?: number,
) {
  let sheetTotal = subtotal;
  if (promo && promo.value) {
    switch (promo.adjustmentType) {
      case "Fixed Price":
        sheetTotal = promo.value;
        break;
      case "Fixed Off":
        sheetTotal = subtotal - promo.value;
        break;
      default:
        sheetTotal = promo.value;
    }
  }
  return (
    sheetTotal + (addOnsTotal || 0) + (tax || 0) + (additionalFeesTotal || 0)
  );
}

function addSubscription(paymentRequest: IPaymentRequestWeb, plan: IPlan) {
  paymentRequest["recurringPaymentRequest"] = {
    paymentDescription: "Book of the Month Subscription",
    managementURL: "https://www.bookofthemonth.com/my-account/account",
    regularBilling: {
      label: plan.description,
      amount: plan.price,
      paymentTiming: "recurring",
      recurringPaymentIntervalUnit: plan.cycles === 12 ? "year" : "month",
    },
  };
}

function addPromoLine(paymentRequest: IPaymentRequestWeb, promo: IPromo) {
  let promoAmount = promo.value;
  switch (promo.adjustmentType) {
    case "Fixed Price":
      promoAmount = promo.value;
      break;
    case "Percent Off":
      // NOTE: We do not currently do promos with % off and all existing code to handle is broken.
      break;
    case "Fixed Off":
      promoAmount = -promo.value;
      break;
    default:
      promoAmount;
  }
  paymentRequest.lineItems.push({
    label: `Promo: ${promo.code}`,
    amount: promoAmount,
  });
}

const currencyCodes: Record<CountryCode, string> = {
  US: "USD",
  CA: "CAD",
};

export function getCountryCodeFromCurrency(
  currencyCode: string,
): CountryCode | undefined {
  const countryCodes = Object.keys(currencyCodes) as CountryCode[];
  return countryCodes.find((code) => currencyCodes[code] === currencyCode);
}
