import { useContext, useEffect } from "react";
import { RequestQueueContext } from "../contexts/RequestQueue";
import { SwagPdp, ProductDetailAPIResponse } from "../../../../types/pdp";
import globalScope from "../../global"; // For tests, ugh

const getProductDetail = async (
  productId: string | null | undefined,
  isProdId: boolean,
) => {
  const res = await fetch(
    `${global.apiHost}/api/v2/botm/product/${productId}/detail?prodId=${isProdId}`,
  );
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return await res.json();
};

const getSwagProductDetail = async (productId: string | null) => {
  const res = await fetch(`${global.apiHost}/api/v1/pdp/${productId}`);
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return await res.json();
};

const useProductDetailLoader = () => {
  // TODO need to convert RequestQueueContext to TS
  const { getData, sync } = useContext(RequestQueueContext);
  useEffect(() => {
    sync();
  });

  return (productId: number | string | null | undefined, isProdId = false) =>
    getData("productDetail", productId, async (productIds: string[]) => {
      const responses = [];
      for (let productId of productIds) {
        try {
          if (!productId) return null;
          const productDetail = await getProductDetail(productId, isProdId);
          responses.push({ pending: false, error: null, data: productDetail });
        } catch (err) {
          responses.push({ pending: false, error: err, data: null });
        }
      }
      return responses;
    });
};

const useProductDetail = (
  productId: number | string | null | undefined,
  isProdId = false,
): ProductDetailAPIResponse => {
  const getProductDetails = useProductDetailLoader();
  return getProductDetails(productId, isProdId);
};

const useSwagProductDetailLoader = () => {
  // TODO need to convert RequestQueueContext to TS
  const { getData, sync } = useContext(RequestQueueContext);
  useEffect(() => {
    sync();
  });

  return (productId: string | null) =>
    getData("swagProductDetail", productId, async (productIds: string[]) => {
      const responses = [];
      for (let productId of productIds) {
        try {
          const productDetail = await getSwagProductDetail(productId);
          responses.push({ pending: false, error: null, data: productDetail });
        } catch (err) {
          responses.push({ pending: false, error: err, data: null });
        }
      }
      return responses;
    });
};

const useSwagProductDetail = (
  productId: string | null,
): { pending: boolean; error?: Error; data?: SwagPdp } => {
  const getSwagProductDetails = useSwagProductDetailLoader();
  return getSwagProductDetails(productId);
};

export {
  useProductDetail,
  useProductDetailLoader,
  useSwagProductDetail,
  useSwagProductDetailLoader,
};
