import * as React from "react";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../css/Consts";
import LogoBom from "./LogoBom";

export const StyledLogoSet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    width: 187px;
  }
`;

export const BOTMLogoSet: React.FC<{ title?: any; width?: number }> = ({
  title,
  width = 187,
}) => (
  <StyledLogoSet>
    <LogoBom width={width} />
    {title}
  </StyledLogoSet>
);
