import globalScope from "../../global"; // For tests, ugh

export async function all() {
  const response = await fetch(`${global.apiHost}/api/v0/experiment`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const experiments = await response.json();
  return experiments;
}
