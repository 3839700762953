import React from "react";
import SkeletonWrapper, {
  P,
  InvisibleBlock,
  Jacket,
} from "./SkeletonWrapper.jsx";
import HeaderBlockSkeleton from "./HeaderBlockSkeleton.jsx";
import HowItWorksSkeleton from "./HowItWorksSkeleton.jsx";

const MothersdaySkeleton = () => (
  <SkeletonWrapper className="visitor bookofyear">
    <HeaderBlockSkeleton className="-headerWrapper" tagType="section">
      <div className="innerContent">
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </HeaderBlockSkeleton>

    <section>
      <HowItWorksSkeleton />
    </section>
  </SkeletonWrapper>
);

export default MothersdaySkeleton;
