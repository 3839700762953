import React from "react";
import SkeletonWrapper, { P, H2, H6 } from "./SkeletonWrapper.jsx";
import Meta from "UI/skeleton/Meta.jsx";

const LoyaltyStatsSkeleton = () => (
  <section className="-stats">
    <div className="innerContent">
      <H2 />
      <div className="statsWrapper">
        <ul>
          <li>
            <H6 />
          </li>
          <li>
            <H6 />
          </li>
          <li>
            <H6 />
          </li>
          <li>
            <H6 />
          </li>
        </ul>
      </div>
    </div>
  </section>
);

const LoyaltyIndicatorSkeleton = () => (
  <div className="indicator">
    <div className="punchCard">
      <div />
      <ul className={"boxes toAnimate"}></ul>
      <div className="statusBar"></div>
    </div>
  </div>
);

const LoyaltyOverviewSkeleton = () => (
  <SkeletonWrapper className="visitor loyalty">
    <Meta
      title="Book of the Month BFF | Read books, get rewards | BOTM reader rewards"
      description="Ship 12 boxes in a row and earn BFF status, which means you'll get a free book tote and add-ons to show our appreciation."
      image="https://static.bookofthemonth.com/loyalty/logos/bff_badge.png"
      keywords={[
        "become a BFF",
        "get rewards",
        "get a tote",
        "free books",
        "best friends forever",
      ]}
      canonicalUrl="https://www.bookofthemonth.com/bff-rewards"
    />
    <section className="content -narrow center">
      <div className="innerContent">
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </section>
    <LoyaltyIndicatorSkeleton />
    <LoyaltyStatsSkeleton />
    <section>
      <div className="content -narrow center">
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </section>
  </SkeletonWrapper>
);

export default LoyaltyOverviewSkeleton;
