import * as joinDataCreators from "State/joinData/creators";
import * as giftPurchaseCreators from "State/gift/creators";
import * as promoCreators from "./creators";
import { removeFromBox, makeSelection } from "State/box/flows";

import * as promoApi from "./api";

export const getPromo =
  ({
    promo,
    promoType = "enroll",
    planId,
    trackId = 1,
    overridePlanId = null,
    countryCode,
  }) =>
  async (dispatch, getState) => {
    let clearError,
      setError,
      setPromo,
      persistPromoError = false;
    promo = promo.toUpperCase();
    if (promoType === "gift") {
      clearError = giftPurchaseCreators.clearGiftPurchasePromoError;
      setError = giftPurchaseCreators.setGiftPurchasePromoError;
      setPromo = giftPurchaseCreators.setGiftPurchasePromo;
    } else {
      clearError = joinDataCreators.clearJoinPromoError;
      setError = joinDataCreators.setJoinPromoError;
      setPromo = joinDataCreators.setJoinPromo;
    }
    try {
      const response = await promoApi.getPromoToApply(
        promo,
        overridePlanId || planId,
        trackId,
        countryCode,
      );
      const { account } = getState();
      if (response) {
        if (
          account &&
          response.productConsumesCredit &&
          response?.promoProducts?.[0]?.id
        ) {
          try {
            if (
              getState().box.items.find(
                (b) =>
                  b.product.id === response.promoProducts[0].id &&
                  !b.isSelection,
              )
            ) {
              // if its already in the box and not bom, remove it so we can make it the selection
              await dispatch(removeFromBox(response.promoProducts[0].id));
            }
            await dispatch(
              makeSelection(response.promoProducts[0].id),
              null,
              countryCode == "CA",
            );
          } catch (e) {
            //ignore
          }
        }
        dispatch(setPromo(response));
        if (!persistPromoError) {
          dispatch(clearError());
        }
      } else if (!response || response === null) {
        dispatch(
          setError({
            code: response,
            message: "That promo code is invalid. Please try again.",
          }),
        );
      }
    } catch (err) {
      if (err.serverError) {
        dispatch(setError({ code: promo, message: err.message }));
      } else {
        dispatch(
          setError(
            "Oops! Something went wrong. Please re-enter the code and try again.",
          ),
        );
      }
    }
  };

export const getAllPromoBanners =
  (countryCode) => async (dispatch, getState) => {
    const response = await promoApi.getAllPromoBanners(countryCode);
    const promoBanners = await response;
    dispatch(promoCreators.setPromoBanners(promoBanners));
    return await promoBanners;
  };

export const getPromoBanner = (id, countryCode) => async (dispatch) => {
  const response = await promoApi.getPromoBanner(id, countryCode);
  const promoBanner = await response;
  dispatch(promoCreators.setPromoBanners([promoBanner]));
  return await promoBanner;
};
