import React, { ReactNode, ReactElement } from "react"; // to match type in shared button
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button } from "../../ui/elements/form/Button";
import { Link as SCLink } from "../../ui/elements/navigation/Link";
import { logClick } from "../../state/analytics/flows";
import { AppState, Dispatch } from "../../types";

type ClickTrackerProps = {
  bottom?: number;
  buttonType?: "button" | "submit" | "reset";
  ctaType: "button" | "buttonLink" | "link" | "navLink";
  children?: ReactNode;
  color?: string;
  customStyles?: Record<string, unknown>;
  dataTest?: string;
  handleClick?: () => void;
  id: string;
  isCentered?: boolean;
  isBold?: boolean;
  isDisabled?: boolean;
  isPending?: boolean;
  left?: number;
  linkTo?: string;
  logClickData?: string;
  maxWidth?: number;
  minWidth?: number;
  pendingTitle?: string;
  push?: (path: string) => void;
  right?: number;
  style?: string;
  shouldValidate?: boolean;
  size?: number;
  state: AppState;
  tabIndex?: string | number;
  title?: string | ReactNode | ReactElement; // to match type in shared button
  to?: string;
  top?: number;
  underlined?: boolean;
  customClass?: string;
};

const ClickTracker: React.FC<ClickTrackerProps> = (props) => {
  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (props.logClickData) {
      logClick(props.logClickData, { getState: () => props.state });
    }
    if (props.handleClick) {
      props.handleClick();
    }
    if (props.linkTo) {
      props.push?.(props.linkTo);
    }
  };

  const location = useLocation();

  const setCTA = () => {
    let cta = null;
    const isLinkWithNoPath = props.ctaType == "buttonLink" && !props.linkTo;
    if (props.ctaType == "button" || isLinkWithNoPath) {
      cta = (
        <Button
          id={props.id}
          style={props.style}
          onClick={handleClick}
          isPending={props.isPending}
          shouldValidate={props.shouldValidate}
          isDisabled={props.isDisabled}
          title={props.title}
          type={props.buttonType} //'button' | 'submit' | 'reset';
          maxWidth={props.maxWidth}
          minWidth={props.minWidth}
          top={props.top}
          left={props.left}
          right={props.right}
          bottom={props.bottom}
          pendingTitle={props.pendingTitle}
          customStyles={props.customStyles}
          data-test={props.dataTest}
          size={props.size}
          customClass={props.customClass}
        >
          {props.children}
        </Button>
      );
    } else if (props.ctaType == "buttonLink" && props.linkTo) {
      cta = (
        <Link
          to={props.linkTo}
          className={`button ${props.style} ${props.customClass}`}
          id={props.id}
          onClick={(ev) => handleClick(ev)}
          style={{ whiteSpace: "nowrap", ...props?.customStyles }}
          data-test={props.dataTest}
        >
          {props.title}
        </Link>
      );
    } else if (props.ctaType == "link") {
      const text = typeof props.title === "string" ? props.title : "";
      const component = typeof props.title === "object" ? props.title : null;
      cta = (
        <SCLink
          top={props.top}
          bottom={props.bottom}
          text={text}
          underlined={props.underlined}
          isBold={props.isBold}
          size={props.size}
          linkTo={props.linkTo}
          navigate={(ev) => handleClick(ev)}
          color={props.color}
          customStyles={props.customStyles}
          data-test={props.dataTest}
          customClass={props.customClass}
        >
          {component}
        </SCLink>
      );
    } else if (props.ctaType == "navLink") {
      cta = (
        <NavLink
          to={props.to || location.pathname}
          onClick={(ev) => handleClick(ev)}
          activeClassName={props.handleClick ? "" : "active"}
          className={props.customClass}
        >
          {props.title}
        </NavLink>
      );
    }

    return cta;
  };

  return setCTA();
};

function mapStateToProps(state: AppState) {
  return {
    state,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickTracker);
