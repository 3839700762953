export enum Occupation {
  TEACHING = "Teaching and education",
  NURSING = "Nursing and healthcare",
  ADMINISTRATIVE = "Office and administrative support",
  FINANCIAL_SERVICES = "Financial services",
  TECHNOLOGY = "Technology and computer software/services",
  GOVERNMENT = "Government employee",
  UNEMPLOYED = "Not currently in the workforce",
  RETIRED = "Retired",
  OTHER = "Other",
}
export enum OccupationLabel {
  "Teaching and education" = "TEACHING",
  "Nursing and healthcare" = "NURSING",
  "Office and administrative support" = "ADMINISTRATIVE",
  "Financial services" = "FINANCIAL_SERVICES",
  "Technology and computer software/services" = "TECHNOLOGY",
  "Government employee" = "GOVERNMENT",
  "Not currently in the workforce" = "UNEMPLOYED",
  "Retired" = "RETIRED",
  "Other" = "OTHER",
}
