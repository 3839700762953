import { combineReducers } from "redux";
import * as actions from "./actions";

const list = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_JUDGES:
      return action.payload;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case actions.REQUEST_JUDGES:
      return false;
    case actions.REQUEST_JUDGES_FAILURE:
    case actions.REQUEST_JUDGES_SUCCESS:
      return true;
  }
  return state;
};

export default combineReducers({
  list,
  loaded,
});
