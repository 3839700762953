export default function urlify(title, id = undefined) {
  const suffix = id ? `-${id}` : "";
  return (
    title
      .toLowerCase()
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/&.*?;/g, "")
      .replace(/'|\&|\./g, "")
      .split(/[^A-Za-z0-9]+/i)
      .filter((t) => t && t.length > 0)
      .join("-")
      .substr(0, 80) + suffix
  );
}
