/**
 * Display user action responses - ie. success/fail
 * Child of Header.jsx
 */

import React, { Component } from "react";

export default class SiteResponse extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { siteResponse } = this.props,
      message = "",
      type = "",
      show = false,
      action = null;

    if (siteResponse && siteResponse.response) {
      message = siteResponse.response.message;
      type = siteResponse.response.type;
      show = siteResponse.showResponse;
      action = siteResponse.response.action;
    }

    return (
      <div
        className={
          "siteMessageBar -response " + type + (show ? " showing" : " hidden")
        }
      >
        <span className="smallText">{message}</span>
        <div style={{ marginLeft: 12 }}>{action}</div>
      </div>
    );
  }
}
