export const getBusinessDays = (n: number) => {
  let today = new Date(),
    d = new Date(today.getTime()),
    day = d.getDay();
  d.setDate(
    d.getDate() +
      n +
      (day === 6 ? 2 : +!day) +
      Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2,
  );
  return d;
};
