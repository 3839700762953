import React from "react";
import styled from "styled-components";
import { COLORS } from "CSS/Consts.js";

const DynamicTextArea = ({ ...props }) => {
  let {
    label,
    value,
    onChangeText,
    placeholder,
    helper,
    error,
    size = "full",
    isInFocus,
    onBlur,
    onFocus,
    top = 0,
    bottom = 0,
    maxLength = 1000,
    inputId = undefined,
  } = props;

  const hasLabel = label ? true : false;
  let inputSize;

  switch (size) {
    case "full":
      inputSize = "100%";
      break;
    case "half":
      inputSize = "49%";
      break;
    case "third":
      inputSize = "32%";
      break;
    case "halfPlus":
      inputSize = "60%";
      break;
    case "halfMinus":
      inputSize = "38%";
      break;
  }

  return (
    <StyledDynamicTextArea
      size={inputSize}
      onFocus={onFocus}
      top={top}
      bottom={bottom}
      isInFocus={isInFocus}
    >
      <StyledInputWrapper error={error}>
        <textarea
          placeholder={placeholder}
          maxLength={maxLength}
          rows="5"
          cols="50"
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          draggable="false"
          onChange={(ev) => onChangeText(ev.target.value)}
          id={inputId}
        />
        {hasLabel && (value?.length || isInFocus) ? (
          <StyledLabelWrapper>
            <StyledLabel error={error}>{label}</StyledLabel>
            {helper && helper}
          </StyledLabelWrapper>
        ) : null}
        {error ? (
          <StyledErrorWrapper>
            <p>{error}</p>
          </StyledErrorWrapper>
        ) : null}
      </StyledInputWrapper>
    </StyledDynamicTextArea>
  );
};

export const StyledDynamicTextArea = styled.div.attrs({
  className: "sc-StyledDynamicTextArea",
})`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  width: ${(props) => (props.size ? `${props.size}` : `100%`)};
  textarea {
    resize: none;
    height: ${(props) => (props.isInFocus ? "100%" : "52px")};
    padding: 16px;
    font-family: "Helvetica";
  }

  textarea::placeholder {
    color: ${COLORS.gray4};
  }
`;

export const StyledInputWrapper = styled.div.attrs({
  className: "sc-StyledInputWrapper",
})`
  position: relative;
  input {
    border-color: ${(props) =>
      props.error ? COLORS.error6 : "var(--color-gray2)"};
  }
`;

export const StyledLabelWrapper = styled.div.attrs({
  className: "sc-StyledLabelWrapper",
})`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${COLORS.white};
  padding-left: 6px;
  padding-right: 6px;
  top: -8px;
  margin-left: 6px;
  border-radius: 3px;
`;

export const StyledLabel = styled.div.attrs({ className: "sc-StyledLabel" })`
  font-size: 12px;
  color: ${(props) => (props.error ? COLORS.error6 : COLORS.black)};
`;

export const StyledClearIt = styled.div.attrs({
  className: "sc-StyledClearIt",
})`
  position: absolute;
  top: 6px;
  right: 8px;
`;

export const StyledSecureField = styled.p.attrs({
  className: "sc-StyledSecureField",
})`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 8px;
  font-size: 1.2rem;
`;

export const StyledErrorWrapper = styled.div.attrs({
  className: "sc-StyledErrorWrapper",
})`
  padding-left: 16px;
  text-align: left;
  margin-top: 6px;
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${COLORS.error6};
  }
`;

export default DynamicTextArea;
