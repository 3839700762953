import { post } from "../../utils/net.js";
import { getCookies, getQueryString } from "../../utils/cookie";
import { getAllVariants } from "../../ui/skeleton/Experiments";
import { getVisitorId } from "../../utils/visitorId";
import { matchedKeys } from "../../utils/index";
import { AppState } from "../../types";

type LogVisitParams = {
  store: { getState: () => AppState };
  location?: string;
  visitData?: { fromPlan: boolean; toPlan: string; visitPath: string };
  countryCode?: "US" | "CA";
};

export async function logVisit({
  store,
  location,
  visitData,
  countryCode,
}: LogVisitParams) {
  // Should only log in browser
  if (typeof document !== "undefined") {
    const { analytics, account, trackClick, joinData } = store.getState();
    if (trackClick) {
      console.log("logged- logVisit:", trackClick);
    }
    const { fromPlan, toPlan, visitPath } = visitData || {};
    // DATA-LOAD TODO fix this reference to store data
    const storeData = store?.getState()?.store?.data;
    const sanitizedStore = store.getState();
    delete sanitizedStore?.joinData?.password;
    const cookies = getCookies();
    const query = getQueryString();

    const utm = matchedKeys(/^utm/, query);
    const ab = matchedKeys(/^ab_\d+$/, cookies);
    const visitorId = getVisitorId();
    const enrollPlan =
      joinData && joinData["plan"] ? joinData["plan"]["id"] : null;
    const debugQueries = {
      ...matchedKeys(
        /holidayCode|promo|renewalCode|referCode|freePromoCode/,
        query,
      ),
      cookies,
    };
    const accountId = account && account.id ? account.id : null;
    const experiment = getAllVariants(accountId as null | undefined);
    post("https://v5syh12pu6.execute-api.us-east-1.amazonaws.com/default/", {
      eventType: "view",
      utm,
      ab,
      visitorId,
      enrollPlan,
      debugQueries,
      accountId: account ? account.id : null,
      countryCode: account ? account.countryCode : countryCode,
      cycleId: storeData && storeData.currentCycle && storeData.currentCycle.id,
      path: location,
      storeId: storeData && storeData.id,
      production: globalThis.xavier_env === "production",
      prev_path:
        location !== (analytics?.basename || "" + analytics?.location)
          ? (analytics?.basename || "" + analytics?.location).replace("//", "/")
          : null,
      referrer: document.referrer,
      trackClick,
      fromPlan,
      toPlan,
      visitPath,
      experiment,
    });
  }
}
