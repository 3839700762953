import React, { useEffect } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { markMessageAsSeen } from "State/accountMessage/flows";
import { setOverlay } from "State/ui/creators";
import { Button } from "UI/elements/form/Button";

const WishlistHighlightModal = ({ setOverlay, markMessageAsSeen }) => {
  useEffect(() => {
    return () => {
      setOverlay(null);
      markMessageAsSeen(24);
    };
  }, []);

  const closeAction = () => {
    setOverlay(null);
    markMessageAsSeen(24);
  };

  return (
    <StyledWishlistHighlightModal id={"StyledWishlistHighlightModal"}>
      <StyledImageWrapper>
        <img src="//static.bookofthemonth.com/svgs/wishlist-popup-icon.svg" />
      </StyledImageWrapper>
      <StyledContentWrapper>
        <h1>Introducing TBR!</h1>
        <p className={"dek"}>
          Add books to your To be read list to save them for future boxes.
        </p>
        <Button
          id="Got it"
          style="primary fullWidth"
          handleClick={() => closeAction()}
          isPending={false}
          title={"Got it!"}
        />
      </StyledContentWrapper>
    </StyledWishlistHighlightModal>
  );
};

export const StyledWishlistHighlightModal = styled.div`
  width: 100%;
  max-width: 594px;
`;

export const StyledImageWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
  img {
    width: 76px;
  }
`;

export const StyledContentWrapper = styled.div`
  padding: 0 20px;
  margin: 30px auto 30px;
  text-align: center;
  max-width: 520px;
  h2 {
    padding: 0 20px;
  }
  .dek {
    margin: 20px auto 30px;
    max-width: 350px;
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, markMessageAsSeen }, dispatch);
}

export default connect(null, mapDispatchToProps)(WishlistHighlightModal);
