import * as React from "react";
import { ReactNode, ReactElement } from "react";
import styled from "styled-components";
import { COLORS, MEDIAQUERIES } from "../../../css/Consts";
import Spinner from "../Spinner";

interface IStyledButtonWrapper {
  top?: number;
  bottom?: number;
  tabIndex?: number;
  ariaHidden?: boolean;
}

export const StyledButtonWrapper = styled.div<IStyledButtonWrapper>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => `${props.top}px` || 0};
  margin-bottom: ${(props) => `${props.bottom}px` || 0};
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    flex-direction: column;
    width: 100%;
    & > button {
      width: 100%;
    }
  }
`;

export enum ButtonType {
  "button",
  "submit",
  "reset",
}
export interface ButtonProps {
  id?: string;
  ariaHidden?: boolean;
  style?: string;
  handleClick?: (...args: any) => void; // eventually deprecate this
  onClick?: (...args: any) => void;
  isPending?: boolean;
  shouldValidate?: boolean;
  disabled?: boolean; // eventually deprecate this
  isDisabled?: boolean;
  title?: string | ReactNode | ReactElement;
  type?: "button" | "submit" | "reset";
  maxWidth?: number;
  minWidth?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  pendingTitle?: string;
  customStyles?: any;
  size?: number;
  customClass?: string;
}

export const Button: React.FC<ButtonProps> = ({
  id = "",
  ariaHidden,
  style,
  top,
  bottom,
  left,
  right,
  maxWidth,
  minWidth,
  handleClick,
  onClick,
  isPending,
  isDisabled,
  disabled,
  title = "",
  children,
  type = "button",
  pendingTitle,
  customStyles,
  customClass = "",
  size = 18,
}) => {
  if (!title) return null;
  const text =
    isPending && !pendingTitle ? (
      <div>&nbsp;</div>
    ) : isPending && pendingTitle ? (
      <div style={{ fontSize: size }} className={customClass}>
        {pendingTitle}
      </div>
    ) : (
      <div style={{ fontSize: size }} className={customClass}>
        {title}
      </div>
    );

  const handleAction = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    } else if (handleClick) {
      handleClick(e);
    }
  };
  return (
    <StyledButton
      tabIndex={0}
      id={id}
      ariaHidden={ariaHidden}
      className={`${style}${isPending && !pendingTitle ? " -pending" : ""}`}
      onClick={handleAction}
      type={type}
      disabled={isDisabled ?? disabled}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      maxWidth={maxWidth}
      minWidth={minWidth}
      style={customStyles}
    >
      {!isPending && (
        <>
          {children}
          {text}
        </>
      )}
      {isPending && <Spinner size="medium" />}
    </StyledButton>
  );
};

interface IStyledButton {
  ariaHidden?: boolean;
  maxWidth?: number;
  minWidth?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const StyledButton = styled.button.attrs({
  className: "sc-StyledButton" as string,
})<IStyledButton>`
  aria-hidden: ${(props) => (props.ariaHidden ? `true` : `false`)};
  box-shadow: none;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "160px")};
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  margin-left: ${(props) => (props.left ? `${props.left}px` : 0)};
  margin-right: ${(props) => (props.right ? `${props.right}px` : 0)};
  min-height: 48px;
  white-space: nowrap;

  &.fullWidth {
    width: 100%;
  }

  &.-pending {
    pointer-events: none;
    cursor: default;
    position: relative;
  }
  &.forNav {
    min-width: 30px;
    min-height: 34px;
    border: 1px solid ${COLORS.gray2};
    padding: 6px 20px;
    background-color: ${COLORS.white};
    div {
      font-size: 1.4rem;
    }
    &:hover {
      border: 1px solid ${COLORS.primary5};
      color: ${COLORS.primary5};
      svg path {
        fill: ${COLORS.primary5};
      }
    }
  }

  &.specialHeaderNav {
    min-width: 30px;
    min-height: 34px;
    border: none;
    padding: 6px 0 6px 20px;
    background-color: ${COLORS.white};
    flex-direction: row-reverse;
    margin-left: 0;
    div {
      font-size: 1.4rem;
    }
    &:hover {
      border: none;
      color: ${COLORS.primary5};
      svg path {
        fill: ${COLORS.primary5};
      }
      div {
        border-color: ${COLORS.primary5};
        p {
          color: ${COLORS.primary5};
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.primary:focus {
    border: none;
    color: ${COLORS.primary5};
    svg path {
      fill: ${COLORS.primary5};
    }
    div {
      border-color: ${COLORS.primary5};
      p {
        color: ${COLORS.primary5};
      }
    }
  }

  &.primary {
    color: ${COLORS.white};
    background: ${COLORS.primary5};
    border-color: ${COLORS.primary5};
    &:hover {
      color: ${COLORS.white};
      border-color: ${COLORS.primary5};
      background-color: ${COLORS.buttonHoverColor};
    }
    div {
      color: ${COLORS.white};
    }
    &:disabled,
    &:disabled:hover,
    &.-disabled {
      cursor: default;
      color: ${COLORS.white};
      background-color: ${COLORS.gray3};
      border-color: transparent;
    }
  }
  &.secondary {
    border-color: ${COLORS.primary5};
    color: ${COLORS.primary5};
    background: ${COLORS.white};
    div {
      color: ${COLORS.primary5};
    }
    &:disabled,
    &:disabled:hover {
      cursor: default;
      border-color: ${COLORS.gray2};
      color: ${COLORS.gray5};
      background: ${COLORS.white};
      div {
        color: ${COLORS.gray5};
      }
    }
  }

  &.dangerous {
    border-color: ${COLORS.error6};
    color: ${COLORS.error6};
    background: ${COLORS.white};
    &:hover {
      background: ${COLORS.white};
      border-color: ${COLORS.error6};
    }
    div {
      color: ${COLORS.error6};
    }
  }

  &.forPromoBanner {
    background: ${COLORS.white};
    color: ${COLORS.gray4};
    border-color: ${COLORS.gray2};
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    min-height: 42px;
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    &.mobileFullWidth {
      width: 100%;
    }
    &.vbtButtons {
      width: fit-content !important;
    }
  }
`;
