import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { ListSortModal } from "UI/components/product/ListSortModal";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import { setOverlay, setSort } from "State/ui/creators";

const ListSortModalWrapper = (props) => {
  const sortIt = (type, sortBy) => {
    props.setSort(type, sortBy);
    props.setOverlay("");
  };

  const sortBy = props.sortBy?.data;

  const defaultList = [
    { name: "BOTM" },
    { name: "A to Z by author" },
    { name: "A to Z by title" },
    { name: "Recently added" },
  ];

  const sortItems = props.sortByList || defaultList;

  return (
    <ModalEditWrapper header={`Sort by`}>
      <ListSortModal
        sortAction={sortIt}
        activeSort={sortBy}
        sortItems={sortItems}
      />
    </ModalEditWrapper>
  );
};

export const StyledSortWrapper = styled.div`
  li {
    font-size: 1.8rem;
    padding: 20px 0;
    border-bottom: 1px solid var(--color-gray2);
    &.active {
      color: var(--color-primary5);
    }
  }
`;

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.ui ? state.ui.sort : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, setSort }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListSortModalWrapper);
