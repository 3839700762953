import React from "react";
import SkeletonWrapper, {
  Button,
  InvisibleBlock,
  H2,
  H6,
  P,
} from "./SkeletonWrapper.jsx";
import HeaderBlockSkeleton from "./HeaderBlockSkeleton.jsx";
import LifestyleHeaderSkeleton from "./LifestyleHeaderSkeleton.jsx";

export const GiftPlansSkeleton = () => (
  <div className="SelectPlanItems">
    <ul className="planMonthsWrapper">
      {new Array(3).fill(0).map((ignore, i) => (
        <li key={i}>
          <div className="offer">
            <div className="wrap">
              <InvisibleBlock width="20px" height="20px" />
              <div>
                <H2 className="-p -center" />
                <p className="label2 -p -center knockout" />
              </div>
            </div>
          </div>
          <div style={{ padding: "0 20px 20px 20px" }}>
            <Button className="primary" />
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export const GiftHeaderSkeleton = () => (
  <div className="giftHeader">
    <HeaderBlockSkeleton className="-headerWrapper" tagType="section">
      <div className="innerContent">
        <P className="-center" />
        <P className="-center" />
        <P className="-center" />
      </div>
    </HeaderBlockSkeleton>

    <LifestyleHeaderSkeleton style={{ backgroundColor: "#C9CCD1" }}>
      <section className="selectPlan center">
        <GiftPlansSkeleton />
      </section>
    </LifestyleHeaderSkeleton>
  </div>
);

const GiftLandingSkeleton = () => (
  <SkeletonWrapper className="visitor gift">
    <GiftHeaderSkeleton />
    {/* <section className="center" style={{ padding: "20px 20px 100px" }}>
    </section> */}
    <section className="content center">
      <InvisibleBlock width="80px" height="80px" />
      <P className="-center" />
      <P className="-center" />
      <P className="-center" />
    </section>
  </SkeletonWrapper>
);

export default GiftLandingSkeleton;
