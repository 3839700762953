import FeaturedProductListSkeleton from "./FeaturedProductListSkeleton.jsx";
import ExtrasSkeleton, { ExtrasPageSkeleton } from "./ExtrasSkeleton.jsx";
import FormPageSkeleton, { FormSkeleton } from "./FormPageSkeleton.jsx";
import GiftLandingSkeleton from "./GiftLandingSkeleton.jsx";
import HeaderBlockSkeleton from "./HeaderBlockSkeleton.jsx";
import JacketListSkeleton from "./JacketListSkeleton.jsx";
import BlogLandingSkeleton from "./BlogLandingSkeleton.jsx";
import MyBoxSkeleton from "./MyBoxSkeleton.jsx";
import PDPageSkeleton from "./PDPageSkeleton.jsx";
import ReviewsListSkeleton from "./ReviewsListSkeleton.jsx";
import SearchResultsSkeleton from "./SearchResultsSkeleton.jsx";
import ShortBlockSkeleton from "./ShortBlockSkeleton.jsx";
import SkeletonWrapper from "./SkeletonWrapper.jsx";
import SurveyQuestionsSkeleton from "./SurveyQuestionsSkeleton.jsx";
import EmailCaptureSkeleton from "./EmailCaptureSkeleton.jsx";
import LoadingCoinSkeleton from "./LoadingCoinSkeleton.jsx";
import UserFlowsSkeleton from "./UserFlowsSkeleton.jsx";
import BOTYSkeleton from "./BOTYSkeleton.jsx";
import LifestyleHeaderSkeleton from "./LifestyleHeaderSkeleton.jsx";
import LandingVisitorSkeleton from "./LandingVisitorSkeleton.jsx";
import MothersdaySkeleton from "./MothersdaySkeleton.jsx";
import LoyaltyOverviewSkeleton from "./LoyaltyOverviewSkeleton.jsx";
import RafLandingSkeleton from "./RafLandingSkeleton.jsx";
import CheckerboardSkeleton from "./CheckerboardSkeleton.jsx";
import GiftFlowSkeleton from "./GiftFlowSkeleton.jsx";

export default {
  FeaturedProductListSkeleton,
  ExtrasSkeleton,
  ExtrasPageSkeleton,
  FormPageSkeleton,
  GiftLandingSkeleton,
  HeaderBlockSkeleton,
  JacketListSkeleton,
  BlogLandingSkeleton,
  MyBoxSkeleton,
  PDPageSkeleton,
  ReviewsListSkeleton,
  SearchResultsSkeleton,
  ShortBlockSkeleton,
  SurveyQuestionsSkeleton,
  EmailCaptureSkeleton,
  LoadingCoinSkeleton,
  UserFlowsSkeleton,
  BOTYSkeleton,
  LifestyleHeaderSkeleton,
  LandingVisitorSkeleton,
  SkeletonWrapper,
  MothersdaySkeleton,
  LoyaltyOverviewSkeleton,
  RafLandingSkeleton,
  FormSkeleton,
  CheckerboardSkeleton,
  GiftFlowSkeleton,
};

export {
  FeaturedProductListSkeleton,
  ExtrasSkeleton,
  ExtrasPageSkeleton,
  FormPageSkeleton,
  GiftLandingSkeleton,
  HeaderBlockSkeleton,
  JacketListSkeleton,
  BlogLandingSkeleton,
  MyBoxSkeleton,
  PDPageSkeleton,
  ReviewsListSkeleton,
  SearchResultsSkeleton,
  ShortBlockSkeleton,
  SurveyQuestionsSkeleton,
  EmailCaptureSkeleton,
  LoadingCoinSkeleton,
  UserFlowsSkeleton,
  BOTYSkeleton,
  LifestyleHeaderSkeleton,
  LandingVisitorSkeleton,
  SkeletonWrapper,
  MothersdaySkeleton,
  LoyaltyOverviewSkeleton,
  RafLandingSkeleton,
  FormSkeleton,
  CheckerboardSkeleton,
  GiftFlowSkeleton,
};
