export const Months = [
  { label: "", value: null },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const MonthsV2 = [
  { label: "", value: null },
  { label: "01 - Jan", value: "1" },
  { label: "02 - Feb", value: "2" },
  { label: "03 - Mar", value: "3" },
  { label: "04 - Apr", value: "4" },
  { label: "05 - May", value: "5" },
  { label: "06 - June", value: "6" },
  { label: "07 - July", value: "7" },
  { label: "08 - Aug", value: "8" },
  { label: "09 - Sept", value: "9" },
  { label: "10 - Oct", value: "10" },
  { label: "11 - Nov", value: "11" },
  { label: "12 - Dec", value: "12" },
];

export const MonthsV3 = [
  { label: "Jan", value: 1, textValue: "January" },
  { label: "Feb", value: 2, textValue: "February" },
  { label: "Mar", value: 3, textValue: "March" },
  { label: "Apr", value: 4, textValue: "April" },
  { label: "May", value: 5, textValue: "May" },
  { label: "June", value: 6, textValue: "June" },
  { label: "July", value: 7, textValue: "July" },
  { label: "Aug", value: 8, textValue: "August" },
  { label: "Sept", value: 9, textValue: "September" },
  { label: "Oct", value: 10, textValue: "October" },
  { label: "Nov", value: 11, textValue: "November" },
  { label: "Dec", value: 12, textValue: "December" },
];

export const MonthsV4 = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
];

export const MonthString = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Years = [
  { label: "", value: null },
  { label: "2018", value: 2018 },
  { label: "2017", value: 2017 },
  { label: "2016", value: 2016 },
  { label: "2015", value: 2015 },
  { label: "2014", value: 2014 },
  { label: "2013", value: 2013 },
  { label: "2012", value: 2012 },
  { label: "2011", value: 2011 },
  { label: "2010", value: 2010 },
  { label: "2009", value: 2009 },
  { label: "2008", value: 2008 },
  { label: "2007", value: 2007 },
  { label: "2006", value: 2006 },
  { label: "2005", value: 2005 },
  { label: "2004", value: 2004 },
  { label: "2003", value: 2003 },
  { label: "2002", value: 2002 },
  { label: "2001", value: 2001 },
  { label: "2000", value: 2000 },
  { label: "1999", value: 1999 },
  { label: "1998", value: 1998 },
  { label: "1997", value: 1997 },
  { label: "1996", value: 1996 },
  { label: "1995", value: 1995 },
  { label: "1994", value: 1994 },
  { label: "1993", value: 1993 },
  { label: "1992", value: 1992 },
  { label: "1991", value: 1991 },
  { label: "1990", value: 1990 },
  { label: "1989", value: 1989 },
  { label: "1988", value: 1988 },
  { label: "1987", value: 1987 },
  { label: "1986", value: 1986 },
  { label: "1985", value: 1985 },
  { label: "1984", value: 1984 },
  { label: "1983", value: 1983 },
  { label: "1982", value: 1982 },
  { label: "1981", value: 1981 },
  { label: "1980", value: 1980 },
  { label: "1979", value: 1979 },
  { label: "1978", value: 1978 },
  { label: "1977", value: 1977 },
  { label: "1976", value: 1976 },
  { label: "1975", value: 1975 },
  { label: "1974", value: 1974 },
  { label: "1973", value: 1973 },
  { label: "1972", value: 1972 },
  { label: "1971", value: 1971 },
  { label: "1970", value: 1970 },
  { label: "1969", value: 1969 },
  { label: "1968", value: 1968 },
  { label: "1967", value: 1967 },
  { label: "1966", value: 1966 },
  { label: "1965", value: 1965 },
  { label: "1964", value: 1964 },
  { label: "1963", value: 1963 },
  { label: "1962", value: 1962 },
  { label: "1961", value: 1961 },
  { label: "1960", value: 1960 },
  { label: "1959", value: 1959 },
  { label: "1958", value: 1958 },
  { label: "1957", value: 1957 },
  { label: "1956", value: 1956 },
  { label: "1955", value: 1955 },
  { label: "1954", value: 1954 },
  { label: "1953", value: 1953 },
  { label: "1952", value: 1952 },
  { label: "1951", value: 1951 },
  { label: "1950", value: 1950 },
  { label: "1949", value: 1949 },
  { label: "1948", value: 1948 },
  { label: "1947", value: 1947 },
  { label: "1946", value: 1946 },
  { label: "1945", value: 1945 },
  { label: "1944", value: 1944 },
  { label: "1943", value: 1943 },
  { label: "1942", value: 1942 },
  { label: "1941", value: 1941 },
  { label: "1940", value: 1940 },
  { label: "1939", value: 1939 },
  { label: "1938", value: 1938 },
  { label: "1937", value: 1937 },
  { label: "1936", value: 1936 },
  { label: "1935", value: 1935 },
  { label: "1934", value: 1934 },
  { label: "1933", value: 1933 },
  { label: "1932", value: 1932 },
  { label: "1931", value: 1931 },
  { label: "1930", value: 1930 },
  { label: "1929", value: 1929 },
  { label: "1928", value: 1928 },
  { label: "1927", value: 1927 },
  { label: "1926", value: 1926 },
  { label: "1925", value: 1925 },
  { label: "1924", value: 1924 },
  { label: "1923", value: 1923 },
  { label: "1922", value: 1922 },
  { label: "1921", value: 1921 },
  { label: "1920", value: 1920 },
  { label: "1919", value: 1919 },
  { label: "1918", value: 1918 },
  { label: "1917", value: 1917 },
  { label: "1916", value: 1916 },
  { label: "1915", value: 1915 },
  { label: "1914", value: 1914 },
  { label: "1913", value: 1913 },
  { label: "1912", value: 1912 },
  { label: "1911", value: 1911 },
  { label: "1910", value: 1910 },
  { label: "1909", value: 1909 },
  { label: "1908", value: 1908 },
  { label: "1907", value: 1907 },
  { label: "1906", value: 1906 },
  { label: "1905", value: 1905 },
];

export const FutureYears = [
  { label: "", value: null },
  { label: "2020", value: 2020 },
  { label: "2021", value: 2021 },
  { label: "2022", value: 2022 },
  { label: "2023", value: 2023 },
  { label: "2024", value: 2024 },
  { label: "2025", value: 2025 },
  { label: "2026", value: 2026 },
  { label: "2027", value: 2027 },
  { label: "2028", value: 2028 },
  { label: "2029", value: 2029 },
  { label: "2030", value: 2030 },
  { label: "2031", value: 2031 },
  { label: "2032", value: 2032 },
  { label: "2033", value: 2033 },
];

export const Days = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const Countries = [
  { label: "US", value: "US" },
  { label: "Canada", value: "CA" },
];

export const Provinces = [
  { label: "AB", value: "AB" },
  { label: "NB", value: "NB" },
  { label: "BC", value: "BC" },
  { label: "NS", value: "NS" },
  { label: "NT", value: "NT" },
  { label: "PE", value: "PE" },
  { label: "SK", value: "SK" },
  { label: "MB", value: "MB" },
  { label: "QC", value: "QC" },
  { label: "NL", value: "NL" },
  { label: "YT", value: "YT" },
  { label: "NU", value: "NU" },
  { label: "ON", value: "ON" },
];

export const StatesWithoutNull = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
  { label: "AA", value: "AA" },
  { label: "AE", value: "AE" },
  { label: "AP", value: "AP" },
];

export const States = [{ label: "", value: null }, ...StatesWithoutNull];
