import React from "react";
import { Dispatch, GetState } from "../../types";
import { IAccount } from "../../../../types/account";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";

import { setOverlay, setSiteResponse } from "../ui/creators";

import { setPolicy } from "../policy/creators";
import {
  addToAccountHistory,
  cancelAccountHistoryRecord,
} from "../accountHistory/creators";
import { setError } from "../error/creators";

import { setBox, setCreditNavStatus } from "../box/creators";

import { showBoxCoupon } from "../ui/creators";

import {
  setAccount,
  setAccountRenewalDate,
  setAccountAnchorResponse,
  setAccountRenewalPlan,
} from "../account/creators";
import { setWishlist } from "../accountWishlist/creators";

import { replaceRejoinPlans } from "../store/creators";
import { dataLayerEventTracking } from "../../utils/analytics";
import { getPlans } from "../../utils/getPlans";

import { setAppFeatures } from "../appFeatures/creators";
import { setMessage } from "../accountMessage/creators";
import { cleanupMessages } from "../accountMessage/flows";
import * as accountApi from "../account/api";
import * as accountHistoryApi from "../accountHistory/api";
import * as appFeatureApi from "../appFeatures/api";
import * as boxApi from "./api";
import * as trancheApi from "../tranche/api";
import * as wishlistApi from "../accountWishlist/api";
import * as accountMessageApi from "../accountMessage/api";
import { snesRedirect } from "../../utils/snesRedirect";

const DEFAULT_CA_PRICE = 19.99;
const DEFAULT_US_PRICE = 9.99;

export const clearBox =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (state.account && state.account.id) {
      const response = await boxApi.clear();
      dispatch(setBox(response.box));
      dispatch(setAccount(response.account));
    }
  };

export const removeFromBox =
  (productId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const newBox = await boxApi.removeFromBox(productId);

    if (state.account?.coupons?.length) {
      dispatch(
        showBoxCoupon({
          type: "addOnCoupon",
          showPlace: { giftSuccess: false, boxMessaging: true },
        }),
      );
    }

    dispatch(setBox(newBox));
  };

export const makeSelection =
  (productId: number, showConf = true, canadaMode = false) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const newBox = await boxApi.makeSelection(productId);
    dispatch(setBox(newBox));
    if (showConf) {
      setTimeout(() => {
        const { ui } = getState();
        if (
          ui?.overlay?.name == "miniCart" &&
          ui.overlay.data?.type == "PickedSelection"
        ) {
          dispatch(setOverlay(""));
        }
      }, 10000);
    }
    const price = canadaMode ? DEFAULT_CA_PRICE : DEFAULT_US_PRICE;
    const currency = canadaMode ? "CAD" : "USD";
    dataLayerEventTracking("FBAddToCart", undefined, {
      contentIds: `${productId}`,
      contentType: "product",
      price: price,
      currency: currency,
    });
  };

export const addToBox =
  (
    productId: number,
    showConf: boolean = true,
    canadaMode: boolean = false,
    reroute?: string,

    successFn?: () => null,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const [newBox, isFreeBoty, isSwagItem] = await boxApi.addToBox(productId);
      //Passing array back for freeBoty
      dispatch(setBox(newBox));
      successFn?.();
      if (showConf) {
        setTimeout(() => dispatch(setOverlay("")), 10000);
      }
      const price = canadaMode ? DEFAULT_CA_PRICE : DEFAULT_US_PRICE;
      const currency = canadaMode ? "CAD" : "USD";
      dataLayerEventTracking("FBAddToCart", undefined, {
        contentIds: `${productId}`,
        contentType: "product",
        price: price,
        currency: currency,
      });
      if (reroute) {
        if (reroute === "/my-box" || reroute === "/box") {
          snesRedirect("snes", "/box");
        } else {
          dispatch(push(reroute));
        }
      }
    } catch (err) {
      dispatch(setError((err as Error).message, "Error"));
    }
  };

// FIXME: Unused?
// export const addToBoxWithPromo = (productId: number, promoData) => async (dispatch: Dispatch) => {
//   let newBox = [];
//   try {
//     newBox = await boxApi.addToBox(productId, [], promoData);
//     dispatch(setBox(newBox));

//     dataLayerEventTracking('FBAddToCart', undefined, {
//       contentIds: `${productId}`,
//       contentType: 'product',
//     });
//   } catch (e) {
//     dispatch(setOverlay('miniCart', 'special', { type: 'Error', message: e.message }));
//   }
// };

export const skipThisMonth =
  (acceptTos = false) =>
  async (dispatch: Dispatch) => {
    const response = await boxApi.skip(acceptTos);
    const messages = await accountMessageApi.getMessagesByMember();
    dispatch(addToAccountHistory(response.box));
    dispatch(setBox(await boxApi.get()));
    dispatch(setAccount(response.account));
    dispatch(setCreditNavStatus({ type: "skip", status: "skipped" }));
    dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
    dispatch(
      setAccountAnchorResponse(await accountHistoryApi.getAnchorResponse()),
    );
    dispatch(setMessage(cleanupMessages(messages)));
  };

export const unSkipThisMonth =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const account = getState().account;
    const res = await boxApi.unskip();
    dispatch(setBox(await boxApi.get()));
    dispatch(setAccount(res.account));
    dispatch(setCreditNavStatus({ type: "skip", status: "unskipped" }));
    dispatch(
      cancelAccountHistoryRecord({
        id: account?.cycleActions?.shipmentTransactionDetails?.id,
      }),
    );
    dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
    dispatch(
      setAccountAnchorResponse(await accountHistoryApi.getAnchorResponse()),
    );
  };

export const ship =
  (
    successCallback: (acct: IAccount) => void,
    failCallback: () => void,
    acceptTos: boolean = false,
    applePayNonce = null,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const trackId = 1;
      const response = await boxApi.ship(acceptTos, applePayNonce);
      const messages = await accountMessageApi.getMessagesByMember();

      // timeout here ensures fade out/in transition for the box and confetti timing
      setTimeout(async () => {
        dispatch(setBox(await boxApi.get()));
        dispatch(addToAccountHistory(response.box));
        response.account.policy.planSet.plans = getPlans(
          response.account.policy.planSet,
          trackId,
        );
        dispatch(setPolicy(response.account.policy));
        if (response.account.policy.planSet) {
          dispatch(replaceRejoinPlans(response.account.policy.planSet));
        }

        dispatch(setAccount(response.account));
        dispatch(setAccountRenewalDate(await trancheApi.getRenewalDate()));
        dispatch(setAccountRenewalPlan(response.account.renewalPlan));
        dispatch(
          setAccountAnchorResponse(await accountHistoryApi.getAnchorResponse()),
        );
        dispatch(setWishlist(await wishlistApi.getWishlist()));
        dispatch(setAppFeatures(await appFeatureApi.all()));
        dispatch(setMessage(cleanupMessages(messages)));
      }, 100);

      if (successCallback) {
        successCallback(response.account);
      }
    } catch (e) {
      console.error("error -> ", e);
      if (failCallback) {
        failCallback();
      }
      dispatch(setError((e as Error).message, "ship"));
    }
  };

export const updatePaymentType =
  (productId: number, paymentType: string /*FIXME:*/) =>
  async (dispatch: Dispatch) => {
    try {
      if (!productId && paymentType === "coupon") {
        window.scrollTo(0, 0);
        dispatch(
          setSiteResponse({
            message: (
              <span>
                Choose an add-on to redeem this offer!
                <a onClick={() => snesRedirect("snes", "/all-hardcovers")}>
                  See the books
                </a>
              </span>
            ),
            type: "failure",
          }),
        );
      } else {
        const newBox = await boxApi.updatePaymentType(productId, paymentType);
        dispatch(setBox(newBox));
      }
    } catch (e) {
      dispatch(setError((e as Error).message, "updatePaymentType"));
    }
  };

export const addPromoAndProduct =
  (promoId: number, memo: string, successFn: () => void, failFn: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      await boxApi.addPromoAndProduct(promoId, memo);
      dispatch(setBox(await boxApi.get()));
      successFn?.();
      dispatch(setOverlay("miniCart", "special"));
    } catch (e) {
      failFn?.();
      console.error("TCL: addPromoAndProduct -> e", e);
    }
  };
