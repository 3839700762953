import React from "react";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { useWindowSize } from "../../../ui/hooks";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import PageLayout from "../../../ui/components/structure/PageLayout";
import { IAccount } from "../../../../../types/account";
import { connect } from "react-redux";
import { canRefundNewMemberShipment } from "../../../utils/getIsNewMemberHold";
import { IAppFeature } from "../../../../../types/app_feature";
import showAppFeature from "../../../utils/showAppFeature";
import { useRegion } from "../../../ui/hooks/useRegion";
import { Variant } from "../../../ui/skeleton/Experiments.jsx";

type LandingProps = {
  canRefundNewMemberShipment?: boolean;
  appFeatures?: IAppFeature[];
  account?: IAccount;
  exp160: number;
};

const Landing: React.FC<LandingProps> = ({
  account,
  canRefundNewMemberShipment,
  appFeatures,
  exp160,
}) => {
  const { countryCode } = useRegion();
  const creditCommitSaveOfferFeature = appFeatures?.find(
    (f) => f?.feature == "Credit Commit Save Offer",
  );
  const isEligibleForSaveOffer =
    account?.mustRenewSaveOfferStatus == "Eligible";
  const showSaveOfferExp160 = exp160 == 1;
  const showCreditCommitSaveOfferFeature =
    showSaveOfferExp160 &&
    isEligibleForSaveOffer &&
    showAppFeature(
      creditCommitSaveOfferFeature,
      account?.countryCode || countryCode,
    );
  const isCreditCommitSaveOfferClaimed =
    account?.mustRenewSaveOfferStatus == "Claimed";

  const [width] = useWindowSize();
  const isMobile = width < 680;
  const NORExpId = "NORPauseCancelExp134-CancelLanding";

  const DATA = getData(
    showCreditCommitSaveOfferFeature,
    isCreditCommitSaveOfferClaimed,
  );
  const { hed, dek, cta1, action1, cta2, action2, cta3, action3 } =
    canRefundNewMemberShipment ? DATA__NEW_MEMBER_REFUND_ELIGIBLE : DATA;

  return (
    <PageLayout isMobile={isMobile}>
      <StyledFlexWrapper maxWidth={80}>
        <img
          alt="Cancel membership"
          src={
            "//static.bookofthemonth.com/svgs/VeryChallenging_Indicator_Icon.svg"
          }
        />
      </StyledFlexWrapper>
      <StyledFlexWrapper
        resetSpacing={true}
        maxWidth={600}
        marginTop={isMobile ? 12 : 20}
        gap={isMobile ? 12 : 20}
        textAlign="center"
      >
        {hed && <h4>{hed}</h4>}
        {dek && <p>{dek}</p>}
      </StyledFlexWrapper>
      <StyledFlexWrapper
        maxWidth={392}
        gap={20}
        marginTop={40}
        textAlign="center"
      >
        <ClickTracker
          ctaType={"button"}
          id={`show-me-save-link-${NORExpId}`}
          style={"primary -fullWidth"}
          linkTo={action1}
          title={cta1}
          logClickData={
            showCreditCommitSaveOfferFeature
              ? `Clicked - Show me an offer I can’t refuse`
              : `Clicked - Show me what I’ll be losing - ${NORExpId}`
          }
        />
        <ClickTracker
          ctaType={"button"}
          id={`pause-link-${NORExpId}`}
          style={"secondary -fullWidth"}
          linkTo={action2}
          title={cta2}
          logClickData={`Clicked - Pause membership - ${NORExpId}`}
        />
        <ClickTracker
          ctaType={"link"}
          id={`cancel-link-${NORExpId}`}
          style={"secondary -fullWidth"}
          handleClick={() => null}
          linkTo={action3}
          title={cta3}
          logClickData={`Clicked - Cancel my membership - ${NORExpId}`}
          size={16}
          customStyles={{ whiteSpace: "normal" }}
        />
      </StyledFlexWrapper>
    </PageLayout>
  );
};

const getData = (
  showCreditCommitSaveOfferFeature: boolean,
  isCreditCommitSaveOfferClaimed: boolean,
) => {
  return {
    hed: `We’d hate to see you go.`,
    dek: `You’re losing some sweet perks. Plus, you can always pause instead!`,
    cta1: showCreditCommitSaveOfferFeature
      ? `Show me an offer I can’t refuse`
      : `Show me what I'll be losing`,
    action1: showCreditCommitSaveOfferFeature
      ? `cancel/save-offer`
      : `cancel/perks`,
    cta2: `Pause membership`,
    action2: `cancel/pause`,
    cta3: `Cancel my membership`,
    action3: isCreditCommitSaveOfferClaimed
      ? `/cancel/cancel-with-save-offer`
      : `/cancel/continue?save=true`,
  };
};

const DATA__NEW_MEMBER_REFUND_ELIGIBLE = {
  hed: `What changed?`,
  dek: `You were all about us earlier. If you still want the book you ordered a few minutes ago, we can still make it happen.`,
  cta1: `I’m in — ship my book!`,
  action1: `/pause-membership/thanks`,
  cta2: `Pause membership`,
  action2: `/pause-membership`,
  cta3: `I don’t try new things. Cancel my membership.`,
  action3: `/cancel/continue?save=true`,
};

type mapStateToProps = {
  account: IAccount;
  appFeatures: IAppFeature[];
};
const mapStateToProps = ({ account, appFeatures }: mapStateToProps) => {
  return {
    account: account,
    canRefundNewMemberShipment: canRefundNewMemberShipment(account),
    appFeatures: appFeatures || [],
  };
};

const LandingExp160 = (props: any) => {
  return (
    <Variant experimentId={160}>
      {({ variant }: { variant: number }) => (
        <Landing {...props} exp160={variant} />
      )}
    </Variant>
  );
};

export default connect(mapStateToProps, null)(LandingExp160);
