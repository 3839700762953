import React, { useContext, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SurveyQuestions from "../../components/SurveyQuestions.jsx";
import {
  getSurveyByType,
  setSurveyResponse,
  getAccountSurveyResponse,
  getSurveyByName,
} from "../../../state/survey/flows";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts.js";
import { AppState, Dispatch } from "../../../types";
import { RegionContext } from "../../contexts/RegionContext";
import { PopulationType } from "../../../../../types/survey";
import Authenticate from "../../../ui/skeleton/Authenticate.jsx";
import { Variant } from "../../../ui/skeleton/Experiments.jsx";
import * as SurveyNames from "../../../utils/surveyNames";

type ISurveyResponse = {
  surveyId: number;
  response: number[];
  source: string;
  storeId: number;
  surveyName: string;
  klaviyoResponse: any;
};

type CancelLandingProps = {
  getSurveyByName: (name: string, population?: PopulationType[]) => void;
  setSurveyResponse: (arg0: ISurveyResponse) => void;
  getAccountSurveyResponse: (surveyId: number) => void;
  exp134: number;
} & MappedCancelLandingProps;

type PauseLandingParams = {
  step?: string;
};

const CancelLanding: React.FC<CancelLandingProps> = (props) => {
  const {
    getSurveyByName,
    surveyList,
    setSurveyResponse,
    getAccountSurveyResponse,
    exp134,
  } = props;
  const { push } = useHistory();
  const { step } = useParams<PauseLandingParams>() || {};
  const { canadaMode, locationHeader } = useContext(RegionContext);
  const isCanadianResident = props?.account?.countryCode == "CA" || canadaMode;
  const isUSResident =
    props?.account?.countryCode == "US" || locationHeader.countryHeader == "US";
  const showNORPauseCancelExp134 = exp134 == 1;

  useEffect(() => {
    const population: PopulationType[] = isCanadianResident
      ? ["All", "CA"]
      : isUSResident
      ? ["All", "US"]
      : ["All"];

    getSurveyByName(SurveyNames.TOP_CANCEL_REASON, population);
    getSurveyByName(SurveyNames.PREFER_TO_GET_BOOKS, population);
    getSurveyByName(SurveyNames.SUBSCRIPTION_MODEL_FEELINGS, population);
    getSurveyByName(SurveyNames.WHY_DISLIKE_BOOKS, population);
    getSurveyByName(SurveyNames.PREFERRED_GENRES, population);
    getSurveyByName(SurveyNames.PREFERRED_BOOKS, population);
    getSurveyByName(SurveyNames.PREFERRED_BOOK_FORMAT, population);
  }, [isCanadianResident]);

  const [topReasonForCancelSurvey, settopReasonForCancelSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.TOP_CANCEL_REASON) || null,
  );
  const [whereGetBooksSurvey, setWhereGetBooksSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.PREFER_TO_GET_BOOKS) || null,
  );
  const [subscriptionModalSurvey, setSubscriptionModalSurvey] = useState(
    surveyList.find(
      (s) => s?.name == SurveyNames.SUBSCRIPTION_MODEL_FEELINGS,
    ) || null,
  );
  const [bookSelectionSurvey, setBookSelectionSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.WHY_DISLIKE_BOOKS) || null,
  );
  const [preferedGenreSurvey, setPreferedGenreSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.PREFERRED_GENRES) || null,
  );
  const [preferedBookFormatSurvey, setPreferedBookFormatSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.PREFERRED_BOOK_FORMAT) ||
      null,
  );
  const [preferedBooksSurvey, setPreferedBooksSurvey] = useState(
    surveyList.find((s) => s?.name == SurveyNames.PREFERRED_BOOKS) || null,
  );

  useEffect(() => {
    if (!topReasonForCancelSurvey) {
      settopReasonForCancelSurvey(
        surveyList.find((s) => s?.name == SurveyNames.TOP_CANCEL_REASON),
      );
    }
    if (!whereGetBooksSurvey) {
      setWhereGetBooksSurvey(
        surveyList.find((s) => s?.name == SurveyNames.PREFER_TO_GET_BOOKS),
      );
    }
    if (!subscriptionModalSurvey) {
      setSubscriptionModalSurvey(
        surveyList.find(
          (s) => s?.name == SurveyNames.SUBSCRIPTION_MODEL_FEELINGS,
        ),
      );
    }
    if (!bookSelectionSurvey) {
      setBookSelectionSurvey(
        surveyList.find((s) => s?.name == SurveyNames.WHY_DISLIKE_BOOKS),
      );
    }
    if (!preferedGenreSurvey) {
      setPreferedGenreSurvey(
        surveyList.find((s) => s?.name == SurveyNames.PREFERRED_GENRES),
      );
    }
    if (!preferedBookFormatSurvey) {
      setPreferedBookFormatSurvey(
        surveyList.find((s) => s?.name == SurveyNames.PREFERRED_BOOK_FORMAT),
      );
    }
    if (!preferedBooksSurvey) {
      setPreferedBooksSurvey(
        surveyList.find((s) => s?.name == SurveyNames.PREFERRED_BOOKS),
      );
    }
  }, [surveyList]);

  const nextStep = async (nextStep: string) => {
    push(`/cancel-membership/${nextStep}`);
  };

  const handleResponse = (r: {
    surveyId: number;
    selectedQuestions: number[];
  }) => {
    let pathname = `/cancel-membership/relationship`;
    if (showNORPauseCancelExp134) {
      pathname = `/cancel/resubscribe`;
    }
    if (r?.surveyId == 33) {
      if (r?.selectedQuestions?.includes(6497)) {
        nextStep("34");
      } else if (r?.selectedQuestions?.includes(6499)) {
        nextStep("35");
      } else if (r?.selectedQuestions?.includes(6494)) {
        nextStep("36");
      } else if (r?.selectedQuestions?.includes(6496)) {
        nextStep("40");
      } else {
        push(pathname);
      }
    } else if (r?.surveyId == 36) {
      if (r?.selectedQuestions?.includes(6511)) {
        nextStep("37");
      } else if (r?.selectedQuestions?.includes(6512)) {
        nextStep("38");
      } else {
        push(pathname);
      }
    } else {
      push(pathname);
    }
  };

  const setContent = () => {
    let id;
    let hed;
    let SurveyQs;
    let mustComplete;

    if (!step) {
      id = 33;
      hed = `What is your main reason for canceling?`;
      SurveyQs = topReasonForCancelSurvey?.surveyQuestions;
      mustComplete = true;
    } else if (step == "34") {
      id = 34;
      hed = `Where will you get your books?`;
      SurveyQs = whereGetBooksSurvey?.surveyQuestions;
      mustComplete = true;
    } else if (step == "35") {
      id = 35;
      hed = `Which of the following do you agree with most regarding the subscription model?`;
      SurveyQs = subscriptionModalSurvey?.surveyQuestions;
      mustComplete = true;
    } else if (step == "36") {
      id = 36;
      hed = `Can you tell us more about why you dislike our books?`;
      SurveyQs = bookSelectionSurvey?.surveyQuestions;
      mustComplete = true;
    } else if (step == "37") {
      id = 37;
      hed = `Which genres would you like to see more of?`;
      SurveyQs = preferedGenreSurvey?.surveyQuestions;
      mustComplete = false;
    } else if (step == "38") {
      id = 38;
      hed = `What are some books you’d like to see us carry?`;
      SurveyQs = preferedBooksSurvey?.surveyQuestions;
      mustComplete = false;
    } else if (step == "40") {
      id = 40;
      hed = `In which format do you prefer to read your books?`;
      SurveyQs = preferedBookFormatSurvey?.surveyQuestions;
      mustComplete = true;
    }

    return (
      <SurveyQuestions
        surveyId={id}
        surveyQuestions={SurveyQs || { parents: [], children: [] }}
        headline={hed}
        buttonText={"Next"}
        multipleChoice={false}
        setSurveyResponse={setSurveyResponse}
        getAccountSurveyResponse={getAccountSurveyResponse}
        account={props.account}
        responseOnClick={(r: {
          surveyId: number;
          selectedQuestions: number[];
        }) => handleResponse(r)}
        isForCancelFlow={!showNORPauseCancelExp134}
        hideDek={true}
        mustComplete={mustComplete}
        showNORPauseCancelExp134={showNORPauseCancelExp134}
      />
    );
  };

  return (
    <Authenticate>
      <StyledStepWrapper id={"StyledStepWrapper"}>
        <div
          className="survey postEnrollSurvey"
          style={{
            maxWidth: 520,
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {setContent()}
        </div>
      </StyledStepWrapper>
    </Authenticate>
  );
};

export const StyledStepWrapper = styled.div`
  position: relative;
  padding: 80px 0;
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    padding: 40px 0;
  }
`;
const CancelLandingExp = (props: any) => (
  <Variant experimentId={134}>
    {({ variant }: { variant: number }) => (
      <CancelLanding {...props} exp134={variant} />
    )}
  </Variant>
);

type MappedCancelLandingProps = {
  surveyList: any[];
} & Pick<AppState, "account">;

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getSurveyByName,
      getSurveyByType,
      setSurveyResponse,
      getAccountSurveyResponse,
    },
    dispatch,
  );
};

const mapStateToProps = (state: AppState): MappedCancelLandingProps => {
  return {
    account: state.account,
    surveyList: state.store.survey.list,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelLandingExp);
