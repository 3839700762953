import React from "react";
import styled from "styled-components";
import { RadioButtonList } from "../../elements/form/RadioButton";

export type PaymentMethodSelectTypes = "Subscribe" | "Buy";

interface IPaymentMethodSelectProps {
  top?: number;
  bottom?: number;
  selected?: string;
  changePaymentType?: (a: "ccForm" | "applePay") => void;
}
const PaymentMethodSelect: React.FC<IPaymentMethodSelectProps> = ({
  top,
  bottom,
  selected,
  changePaymentType = () => null,
}) => {
  return (
    <StyledPaymentMethodSelectWrapper top={top} bottom={bottom}>
      <RadioButtonList
        radioButtonGroupName="PaymentMethodSelect"
        radioButtonValues={[
          {
            text: "Credit/debit card",
            value: "ccForm",
            isChecked: selected == "ccForm",
          },
          {
            text: "Apple Pay",
            image: `//static.bookofthemonth.com/elements/apple-pay-logo.svg`,
            value: "applePay",
            isChecked: selected == "applePay",
          },
        ]}
        handleUpdate={
          changePaymentType as (pt?: string | boolean | undefined) => void
        }
      />
    </StyledPaymentMethodSelectWrapper>
  );
};

interface IStyledPaymentMethodSelectWrapper {
  top?: number;
  bottom?: number;
}

export const StyledPaymentMethodSelectWrapper = styled.div.attrs({
  className: "sc-StyledPaymentMethodSelectWrapper" as string,
})<IStyledPaymentMethodSelectWrapper>`
  display: flex;
  padding-top: ${(props) => `${props.top}px`};
  padding-bottom: ${(props) => `${props.bottom}px`};
`;

export default PaymentMethodSelect;
