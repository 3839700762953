/**
 * duplicated PaymentInfoEdit.jsx for Unsupported Apple Pay browsers
 * ApplePaymentInfoEdit.tsx
 * This is a wrapper for Braintree so that a user can update their payment info if they have vaulted Apple Pay but are on an unsupported browser.
 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppState, Dispatch } from "../../../types";
import { setOverlay } from "../../../state/ui/creators";
import { clearError } from "../../../state/error/creators";
import {
  getPaymentMethod,
  getClientToken,
} from "../../../state/memberPaymentMethod";
import { useWindowSize } from "../../../ui/hooks";
import Braintree from "../Braintree.jsx";
import TroubleShootBlock from "./TroubleShootBlock";

type ApplePaymentInfoEditProps = {
  paymentMethod: any;
  braintreeError: any;
  overlay: any;
  setOverlay: (overlay: string) => void;
  getPaymentMethod: () => void;
  getClientToken: () => void;
  clearError: () => void;
};

const ApplePaymentInfoEdit: React.FC<ApplePaymentInfoEditProps> = ({
  overlay,
  setOverlay,
  ...props
}) => {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const finePrint = overlay?.data?.finePrint;

  useEffect(() => {
    setPaymentMethod(props.paymentMethod);
    if (paymentMethod?.updatedAt != props?.paymentMethod?.updatedAt) {
      setOverlay("");
    }
  });

  return (
    <StyledApplePaymentInfoEdit isMobile={isMobile}>
      <form id="hosted-fields-form">
        <Braintree
          useSubmitButton={true}
          submitButtonText={"Update"}
          error={props.braintreeError}
        />
      </form>
      {finePrint ? <p className={`P3`}>{finePrint}</p> : null}
      <TroubleShootBlock />
    </StyledApplePaymentInfoEdit>
  );
};

type StyledApplePaymentInfoEditProps = {
  isMobile: boolean;
};
const StyledApplePaymentInfoEdit = styled.div.attrs({
  className: "sc-apple-payment-info-edit",
})<StyledApplePaymentInfoEditProps>`
  p.P3 {
    font-size: ${({ isMobile }) => (isMobile ? 12 : 14)}px;
    margin-top: ${({ isMobile }) => (isMobile ? 10 : 20)}px;
    text-align: center;
    color: var(--color-gray5);
  }
  #expander-pointer svg {
    height: ${({ isMobile }) => (isMobile ? 12 : 20)}px;
    width: ${({ isMobile }) => (isMobile ? 16 : 24)}px;
  }
`;

function mapStatetoProps(state: AppState) {
  return {
    paymentMethod: state.account?.paymentMethod,
    overlay: state?.ui?.overlay,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    { getPaymentMethod, getClientToken, clearError, setOverlay },
    dispatch,
  );
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps,
)(ApplePaymentInfoEdit);
