import * as React from "react";

interface ILockIconProps {
  handleClick?: (...args: any) => void;
  color?: string;
}
export const LockIcon: React.FC<ILockIconProps> = ({
  color = "var(--color-gray4)",
}) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
    <path
      d="M6.00007 1C3.82984 1 2.07143 2.72317 2.07143 4.85001V7.29998H1.71432C1.31862 7.29998 1 7.61213 1 8V14.3C1 14.6878 1.31853 15 1.71432 15H10.2857C10.6814 15 11 14.6878 11 14.3V8C11 7.61222 10.6815 7.29998 10.2857 7.29998H9.92857V4.85001C9.92857 2.72322 8.17044 1 6.00007 1ZM6.00007 1.70002C7.78696 1.70002 9.21439 3.09882 9.21439 4.85001V7.29998H2.78575V4.85001C2.78575 3.09889 4.21311 1.70002 6.00007 1.70002Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);
