import globalScope from "../../global"; // For tests, ugh

export async function getAccountBookshelf() {
  const response = await fetch(`${global.apiHost}/api/v2/botm/bookshelf`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const bookshelf = await response.json();
  return await bookshelf;
}

export async function saveBookRating(
  accountId: number,
  pdpId: number,
  origin: string,
  rating: any,
  review: any,
  emoji: any,
  isNewRating: boolean,
) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/bookshelf/${pdpId}`,
    {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify({
        accountId,
        origin,
        rating,
        review,
        emoji,
        isNewRating,
      }),
      headers: {
        "Content-type": "application/json",
      },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const updatedReview = await response.json();
  return await updatedReview;
}

export async function getRatingsByPdp(pdpId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/bookshelf/${pdpId}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}
