import React, { useState, useEffect, useRef } from "react";
import { useWindowSize } from "UI/hooks";
import { COLORS } from "CSS/Consts";

const Confetti = ({ showConfetti }) => {
  const confettiCanvas = useRef(null);
  const animationHandler = useRef(null);
  const confettiActive = useRef(false);
  const [width, height] = useWindowSize();

  let particles = [],
    maxParticles = 80,
    angle = 0,
    tiltAngle = 0,
    confettiColors = {
      colorOptions: [COLORS.primary5, "#dbdbdf", "#182747"],
      colorIndex: 0,
      colorIncrementer: 0,
      colorThreshold: 10,
      getColor: function () {
        if (this.colorIncrementer >= 10) {
          this.colorIncrementer = 0;
          this.colorIndex++;
          if (this.colorIndex >= this.colorOptions.length) {
            this.colorIndex = 0;
          }
        }
        this.colorIncrementer++;
        return this.colorOptions[this.colorIndex];
      },
    };

  useEffect(() => {
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (f) {
        return setTimeout(f, 10000);
      }; // simulate calling code 60

    window.cancelAnimationFrame =
      window.cancelAnimationFrame ||
      window.mozCancelAnimationFrame ||
      function (requestID) {
        clearTimeout(requestID);
      }; // fall back

    return () => {
      cancelAnimationFrame(animationHandler.current);
    };
  }, []);

  // this ensures that canvas width and height are not 0.
  useEffect(() => {
    confettiCanvas.current.width = width;
  }, [width]);

  useEffect(() => {
    confettiCanvas.current.height = height;
  }, [height]);

  useEffect(() => {
    if (typeof document !== "undefined" && height > 0 && showConfetti) {
      if (!confettiActive.current) confettiActive.current = true;
      for (let i = 0; i < maxParticles; i++) {
        let particleColor = confettiColors.getColor();
        let newParticle = new ConfettiParticle(
          particleColor,
          width,
          height,
          maxParticles,
        );
        particles.push(newParticle);
      }
      animationHandler.current = requestAnimationFrame(startConfetti);
    } else {
      deactivateConfetti();
    }
  }, [width, height, showConfetti]);

  const startConfetti = () => {
    animationHandler.current = requestAnimationFrame(startConfetti);
    drawConfetti();
  };

  const drawConfetti = () => {
    let results = [];
    let ctx = confettiCanvas?.current?.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, width, height);
      for (let i = 0; i < maxParticles; i++) {
        (function (j) {
          results.push(particles[j].draw(ctx));
        })(i);
      }
      updateConfetti();
    }
    return results;
  };

  const updateConfetti = () => {
    let remainingFlakes = 0;
    let particle;
    angle += 0.01;
    tiltAngle += 0.1;
    for (let i = 0; i < maxParticles; i++) {
      particle = particles[i];
      if (!confettiActive.current && particle.y > height) {
        const visibleParticleIndex = particles.findIndex((p) => p.y <= height);

        // if no visible particles remain cancel animation
        if (visibleParticleIndex === -1)
          cancelAnimationFrame(animationHandler.current);

        continue;
      }
      stepParticle(particle, i);
      if (particle.y <= height) {
        remainingFlakes++;
      }
      checkForReposition(particle, i);
    }
  };

  const checkForReposition = (particle, index) => {
    if (
      (particle.x > width + 20 || particle.x < -20 || particle.y > height) &&
      confettiActive.current
    ) {
      if (index % 5 > 0 || index % 2 == 0) {
        //66.67% of the flakes
        repositionParticle(
          particle,
          Math.random() * width,
          -10,
          Math.floor(Math.random() * 10) - 10,
        );
      } else {
        if (Math.sin(angle) > 0) {
          //Enter from the left
          repositionParticle(
            particle,
            -5,
            Math.random() * height,
            Math.floor(Math.random() * 10) - 10,
          );
        } else {
          //Enter from the right
          repositionParticle(
            particle,
            width + 5,
            Math.random() * height,
            Math.floor(Math.random() * 10) - 10,
          );
        }
      }
    }
  };

  const stepParticle = (particle, particleIndex) => {
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 8; // confetti speed
    // particle.x += Math.sin(angle);
    particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 10;
  };

  const repositionParticle = (particle, xCoordinate, yCoordinate, tilt) => {
    particle.x = xCoordinate;
    particle.y = yCoordinate;
    particle.tilt = tilt;
  };

  const deactivateConfetti = () => {
    confettiActive.current = false;
  };

  return <canvas ref={confettiCanvas} id="confetti" />;
};

class ConfettiParticle {
  constructor(color, width, height, maxParticles) {
    this.color = color;
    this.x = Math.random() * width; // x-coordinate
    this.y = Math.random() * height - height; //y-coordinate
    this.r = this.randomFromTo(10, 50); //radius;
    this.d = Math.random() * maxParticles + 10; //density;
    this.tilt = Math.floor(Math.random() * 10) - 10;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.lineWidth = this.r / 5;
    ctx.strokeStyle = this.color;
    ctx.moveTo(this.x + this.tilt + this.r / 5, this.y);
    ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
    return ctx.stroke();
  }

  randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }
}

export default Confetti;
