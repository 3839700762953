import React from "react";
import styled from "styled-components";
import { BORDER } from "../../../css/Consts";

export type ApplePayButtonTypes = "Subscribe" | "Buy" | "Continue" | "Pay";

interface IApplePayButtonProps {
  top?: number;
  bottom?: number;
  buttonStyle?: "default" | "fat";
  applePayInstance?: any;
  cta?: ApplePayButtonTypes; // See 'https://developer.apple.com/documentation/apple_pay_on_the_web/applepaybuttontype' for  button types;
  ctaAction: (applePayInstance: any) => void;
}
const ApplePayButton: React.FC<IApplePayButtonProps> = ({
  top = 0,
  bottom = 0,
  buttonStyle = "default",
  applePayInstance,
  cta = "Subscribe",
  ctaAction = () => null,
}) => {
  return (
    <StyledApplePayButtonWrapper top={top} bottom={bottom}>
      {/* <div 
        id={`button-apple-pay`}
        className={'apple-pay-button'} 
        onClick={()=>ctaAction()}
        data-cy='apple-pay-button'
      /> --If we have to use apples default as is comes in! - Note: if so font sizing would nnot match comp! */}
      <StyledApplePayButtonCustom
        id={`button-apple-pay`}
        buttonStyle={buttonStyle}
        onClick={() => ctaAction(applePayInstance)}
        data-cy="apple-pay-button"
      >
        <span className={"applePaytext"}>{`${cta} with`}</span>
        <span className={"applePayLogo"}></span>
      </StyledApplePayButtonCustom>
    </StyledApplePayButtonWrapper>
  );
};

interface IStyledApplePayButtonWrapper {
  top?: number;
  bottom?: number;
}

interface IStyledApplePayButton {
  buttonStyle?: string;
}

export const StyledApplePayButtonWrapper = styled.div.attrs({
  className: "sc-StyledApplePayButtonWrapper" as string,
})<IStyledApplePayButtonWrapper>`
  display: flex;
  width: 100%;
  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
  @supports not (-webkit-appearance: -apple-pay-button) {
    display: none;
  }
`;

export const StyledApplePayButtonCustom = styled.div.attrs({
  className: "sc-StyledApplePayButtonCustom" as string,
})<IStyledApplePayButton>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${BORDER.defaultBorderRadius};
  background-color: black;
  width: 100%;
  padding: 14px 10px;
  min-height: ${(props) => (props.buttonStyle == "fat" ? "64px" : "46px")};
  .applePaytext {
    color: white;
    font-family: -apple-system;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .applePayLogo {
    width: 50px;
    height: 20px;
    margin-left: 6px;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-repeat: no-repeat;
  }
  /* .apple-pay-button {// If need to use non custom apple pay button
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: subscribe;
    -apple-pay-button-style: black;
    -apple-pay-button-padding:40px 0;
    border-radius: var(--border-defaultBorderRadius);
    font-size: 1.8rem;
    line-height: 1.8rem;
    width: 100%;
    height: 48px;
    border-style: solid;
    padding: 14px 10px;
    cursor: pointer;
  } */
`;

export default ApplePayButton;
