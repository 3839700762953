import dateformat from "dateformat";

import { FieldSet } from "../../../elements/form";
import {
  Provinces,
  MonthsV4,
  Days,
  StatesWithoutNull,
} from "../../../elements/form/SelectData";
import { generateYearsList } from "./util/generateYearsList";
import { getBusinessDays } from "./util/getBusinessDays";

export const threeBookImage = `https://static.bookofthemonth.com/gift/gift-email/3_Hero_GiftingEmail.jpg`;
export const sixBookImage = `https://static.bookofthemonth.com/gift/gift-email/6_Hero_GiftingEmail.jpg`;
export const twelveBookImage = `https://static.bookofthemonth.com/gift/gift-email/12_Hero_GiftingEmail.jpg`;

export const getEstimatedDeliveryWarning = () => {
  const day3 = getBusinessDays(3);
  const day5 = getBusinessDays(5);
  return `Estimated delivery between ${dateformat(
    day3,
    "UTC:mmm d",
  )} and ${dateformat(day5, "UTC:mmm d")}.`;
};

export const defaultForm: FieldSet = {
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title1",
    value: "Let’s get the details.",
    miscData: { forSinglePageOnly: true },
  },
  subtitle1: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title2",
    value: "Send to",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  recipientName: {
    name: "recipientName",
    type: "text",
    placeholder: "Name",
    label: "To",
    value: "",
    required: true,
    miscData: { desktopBottom: 32, mobileBottom: 24 },
    labelForErrorMessage: "name",
  },
  recipientEmail: {
    name: "recipientEmail",
    type: "text",
    placeholder: "Recipient email",
    label: "Recipient email",
    value: "",
    required: true,
    miscData: { desktopBottom: 40, mobileBottom: 32 },
    labelForErrorMessage: "email",
  },
  subtitle2: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title3",
    value: "From",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  senderName: {
    name: "senderName",
    type: "text",
    placeholder: "Name",
    label: "From",
    value: "",
    required: true,
    miscData: { desktopBottom: 32, mobileBottom: 24 },
    labelForErrorMessage: "name",
  },
  senderEmail: {
    name: "senderEmail",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
    miscData: { desktopBottom: 40, mobileBottom: 32 },
    labelForErrorMessage: "email",
  },
  message: {
    name: "message",
    type: "textArea",
    placeholder: "Personal message (optional)",
    label: "Personal message (optional)",
    value: "",
    miscData: { forSinglePageOnly: true },
  },
};

export const dateForm: FieldSet = {
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title4",
    value: "When should we send it?",
    miscData: { desktopBottom: 20, mobileBottom: 20 },
  },
  month: {
    name: "month",
    type: "select",
    placeholder: "MM",
    label: "Month",
    value: "",
    options: MonthsV4,
    mobileSize: "third",
    desktopSize: "third",
    required: true,
    visible: true,
  },
  day: {
    name: "day",
    type: "select",
    placeholder: "DD",
    label: "Day",
    value: "",
    options: Days,
    mobileSize: "third",
    desktopSize: "third",
    required: true,
    visible: true,
  },
  year: {
    name: "year",
    type: "select",
    placeholder: "YYYY",
    label: "Year",
    value: "",
    options: generateYearsList(),
    mobileSize: "third",
    desktopSize: "third",
    required: true,
    visible: true,
    miscData: { desktopBottom: 40, mobileBottom: 32 },
  },
  dateError: {
    name: "dateError",
    type: "formError",
    placeholder: "",
    label: "",
    value: "Please select a delivery date of today or in the future.",
    visible: false,
  },
  subtitle: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title5",
    value: "Say something special",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  message: {
    name: "message",
    type: "textArea",
    placeholder: "Personal message (optional)",
    label: "Personal message (optional)",
    value: "",
    miscData: { forMultiStepOnly: true, desktopBottom: 40, mobileBottom: 32 },
  },
};

export const printAtHome: FieldSet = {
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title6",
    value: "Let’s get the details.",
    miscData: { forSinglePageOnly: true },
  },
  memo: {
    name: "memo",
    type: "memo",
    placeholder: "",
    label: "title7",
    value: "We’ll email you a printable PDF of your customized card.",
    miscData: { forSinglePageOnly: true },
  },
  subtitle1: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title8",
    value: "Send to",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  recipientName: {
    name: "recipientName",
    type: "text",
    placeholder: "Name",
    label: "To",
    value: "",
    mobileSize: "full",
    desktopSize: "full",
    required: true,
    miscData: {
      desktopBottom: 40,
      mobileBottom: 32,
    },
    labelForErrorMessage: "name",
  },
  subtitle2: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title9",
    value: "From",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  senderName: {
    name: "senderName",
    type: "text",
    placeholder: "Name",
    label: "From",
    value: "",
    mobileSize: "full",
    desktopSize: "full",
    required: true,
    miscData: {
      desktopBottom: 32,
      mobileBottom: 24,
    },
    labelForErrorMessage: "name",
  },
  senderEmail: {
    name: "senderEmail",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
    miscData: {
      desktopBottom: 40,
      mobileBottom: 32,
    },
    labelForErrorMessage: "email",
  },
  subtitle3: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title10",
    value: "Say something special",
    miscData: { forMultiStepOnly: true, desktopBottom: 20, mobileBottom: 20 },
  },
  message: {
    name: "message",
    type: "textArea",
    placeholder: "Personal message (optional)",
    label: "Personal message (optional)",
    value: "",
  },
};

export const cardAddressForm: FieldSet = {
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title11",
    value: "Where should we send it?",
    miscData: { desktopBottom: 20, mobileBottom: 20 },
  },
  memo: {
    name: "memo",
    type: "memo",
    placeholder: "",
    label: "title12",
    value: getEstimatedDeliveryWarning(),
    miscData: { forSinglePageOnly: true },
  },
  firstName: {
    name: "firstName",
    type: "text",
    placeholder: "First name",
    label: "First name",
    value: "",
    mobileSize: "half",
    desktopSize: "half",
    required: true,
    miscData: {
      mobileSizeMultiStep: "half",
      desktopSizeMultiStep: "half",
      desktopBottom: 32,
      mobileBottom: 24,
    },
    labelForErrorMessage: "name",
  },
  lastName: {
    name: "lastName",
    type: "text",
    placeholder: "Last name",
    label: "Last name",
    value: "",
    mobileSize: "half",
    desktopSize: "half",
    required: true,
    miscData: {
      mobileSizeMultiStep: "half",
      desktopSizeMultiStep: "half",
    },
    labelForErrorMessage: "name",
  },
  address: {
    name: "address",
    type: "text",
    placeholder: "Address",
    label: "Address",
    value: "",
    mobileSize: "full",
    desktopSize: "halfPlus",
    required: true,
    miscData: { desktopBottom: 32, mobileBottom: 24 },
  },
  apt: {
    name: "apt",
    type: "text",
    placeholder: "Apt.",
    label: "Apt.",
    value: "",
    mobileSize: "half",
    desktopSize: "halfMinus",
  },
  city: {
    name: "city",
    type: "text",
    placeholder: "City",
    label: "City",
    value: "",
    mobileSize: "half",
    desktopSize: "third",
    required: true,
    miscData: { desktopBottom: 40, mobileBottom: 32 },
  },
  state: {
    name: "state",
    type: "select",
    placeholder: "State",
    label: "State",
    value: "",
    options: StatesWithoutNull,
    mobileSize: "half",
    desktopSize: "third",
    required: true,
    visible: true,
  },
  province: {
    name: "province",
    type: "select",
    placeholder: "Province",
    label: "Province",
    value: "",
    options: Provinces,
    mobileSize: "half",
    desktopSize: "third",
    required: false,
    visible: false,
  },
  zip: {
    name: "zip",
    type: "number",
    placeholder: "Zip",
    label: "Zip",
    value: "",
    mobileSize: "half",
    desktopSize: "third",
    required: true,
  },
};

export const giftCardDetails: FieldSet = {
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "title13",
    value: "What should it say?",
    miscData: { desktopBottom: 20, mobileBottom: 20, mobileTop: 32 },
  },
  recipientName: {
    name: "recipientName",
    type: "text",
    placeholder: "Name",
    label: "To",
    value: "",
    mobileSize: "full",
    desktopSize: "full",
    required: true,
    miscData: { desktopBottom: 32, mobileBottom: 24 },
    labelForErrorMessage: "name",
  },
  senderName: {
    name: "senderName",
    type: "text",
    placeholder: "Name",
    label: "From",
    value: "",
    mobileSize: "full",
    desktopSize: "full",
    miscData: { desktopBottom: 32, mobileBottom: 24 },
    required: true,
    labelForErrorMessage: "name",
  },
  senderEmail: {
    name: "senderEmail",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
    miscData: { desktopBottom: 32, mobileBottom: 24 },
    labelForErrorMessage: "name",
  },
  message: {
    name: "message",
    type: "textArea",
    placeholder: "Personal message (optional)",
    label: "Personal message (optional)",
    value: "",
  },
};
