import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";

const AppFeatureGate = ({
  appFeatures,
  featureName,
  pageClassName,
  children,
  redirectPath,
  hideIfActive,
  activeComponent,
  inActiveComponent,
}) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const feature = appFeatures?.find((f) => f?.feature == featureName);
  const showAppFeature =
    (feature?.status == "Beta" && feature?.isBetaEligible) ||
    feature?.status == "Active";

  if (appFeatures?.length > 0) {
    if (activeComponent && inActiveComponent) {
      if (showAppFeature) {
        return activeComponent;
      } else {
        return inActiveComponent;
      }
    } else {
      if (
        (showAppFeature && !hideIfActive) ||
        (!showAppFeature && hideIfActive)
      ) {
        if (pageClassName) {
          return <div className={pageClassName}>{children}</div>;
        } else {
          return children;
        }
      } else if (showAppFeature && hideIfActive) {
        replace(redirectPath && pathname !== redirectPath ? redirectPath : "/");
        return <LoadingCoinSkeleton />;
      } else {
        replace(redirectPath && pathname !== redirectPath ? redirectPath : "/");
        return <LoadingCoinSkeleton />;
      }
    }
  } else {
    return <LoadingCoinSkeleton />;
  }
};

function mapStateToProps(state) {
  return {
    appFeatures: state.appFeatures || [],
  };
}
export default connect(mapStateToProps)(AppFeatureGate);
