/**
 * Displays current credit count in member nav.
 * On hover, shows credit dashboard.
 * Child of MemberNav.jsx
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

import { setOverlay } from "State/ui/creators";

import CreditsDashboard from "UI/components/CreditsDashboard.jsx";

class CreditsNav extends Component {
  constructor() {
    super();
    this.state = {
      showCreditNav: false,
    };
    this.toggleShowCreditDash = this.toggleShowCreditDash.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ showDropdown: false });
    }
  }

  showCreditDash() {
    this.props.setOverlay("creditsDashboard", "special");
  }

  hideCreditDash() {
    if (
      this.props.overlay?.name !== "SkipSurveyModal" &&
      this.props.overlay?.name !== "ShipBillingModal"
    ) {
      this.props.setOverlay("");
    }
  }

  async toggleShowCreditDash() {
    await this.props.setHideMobileNav();
    this.setState((prevState) => ({
      showCreditNav: !prevState.showCreditNav,
    }));
    if (this.props.overlay && this.props.overlay.name !== "creditsDashboard") {
      this.props.setOverlay("creditsDashboard", "special");
    } else {
      this.props.setOverlay("");
    }
  }

  render() {
    let { overlay } = this.props;
    let creditsActive =
      this.props.path === "/my-book-credits" ||
      (overlay && overlay.name === "creditsDashboard");

    const creditNavLink = (
      <Fragment>
        <a
          className="desktopLink"
          tabIndex="0"
          onKeyPress={() => this.toggleShowCreditDash()}
        >
          <div
            className={
              "creditNav " +
              (overlay && overlay.name === "creditsDashboard" ? "active" : "")
            }
          >
            <div className="creditLabel forDesktop">
              <span>Credits</span>
            </div>
            <div className="creditContainer forDesktop">
              <div className="itemCount p3">
                <span>{this.props.account.credits}</span>
              </div>
            </div>
          </div>
        </a>
        <CreditsDashboard showCreditNav={this.state.showCreditNav} />
      </Fragment>
    );

    return (
      <Fragment>
        <li
          style={{ paddingTop: "0px" }}
          className={
            "iconLink creditsNav -forDesktop" +
            (!this.props.account ? " hide" : "")
          }
          onMouseEnter={
            overlay && overlay.name === "creditsDashboard"
              ? null
              : this.showCreditDash.bind(this)
          }
          onMouseLeave={this.hideCreditDash.bind(this)}
        >
          {creditNavLink}
        </li>

        <li
          style={{ paddingTop: "0px" }}
          className={
            "iconLink creditsNav -forMobile" +
            (!this.props.account ? " hide" : "")
          }
        >
          {creditNavLink}
        </li>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    creditNavStatus: state.creditNavStatus.status,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(CreditsNav);
