import React from "react";
import SkeletonWrapper, { Word, P } from "./SkeletonWrapper.jsx";
import ShortBlockSkeleton from "./ShortBlockSkeleton.jsx";

const FeaturedProductListSkeleton = (props) => (
  <SkeletonWrapper className="botmProductList">
    <ul>
      {new Array(props.count || 6).fill(0).map((ignore, i) => (
        <li key={i}>
          <ShortBlockSkeleton>
            <div className="content">
              <h5>
                <Word />
              </h5>
              <h4>
                <Word />
              </h4>
              <P />
              <P />
              <P />
              <P />
            </div>
          </ShortBlockSkeleton>
        </li>
      ))}
    </ul>
  </SkeletonWrapper>
);

export default FeaturedProductListSkeleton;
