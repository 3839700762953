import React from "react";
import ClickTracker from "../../elements/ClickTracker";
import { AlarmClockIcon } from "../../elements/icons/AlarmClockIcon";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import { useWindowSize } from "../../../ui/hooks";
import { Variant } from "../../../ui/skeleton/Experiments";
import { AppState } from "../../../types";

type PauseSuccessProps = {
  hasCreditLeft?: boolean;
  pausedMonths: number;
  confirmedRenewalDate?: string;
  exp134: number;
  account: AppState["account"];
};

const PauseSuccess: React.FC<PauseSuccessProps> = ({
  hasCreditLeft,
  pausedMonths,
  confirmedRenewalDate,
  account,
  exp134,
}) => {
  let hed;
  let dek;
  const months = ["zero", "one", "two", "three"];
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const isCanadianResident = account?.countryCode === "CA";
  const showNORPauseCancelExp134 = exp134 == 1;
  const NORExpId = showNORPauseCancelExp134 ? "-NORPauseCancelExp134" : "";

  if (hasCreditLeft) {
    hed =
      pausedMonths == 1 ? (
        "You paused for one month."
      ) : (
        <>
          You paused for{" "}
          <span className={"nowrap"}>{pausedMonths} months.</span>
        </>
      );
    dek =
      pausedMonths == 1
        ? `Your account is now paused for the next month, meaning you won't be renewed until ${confirmedRenewalDate}. See you then!`
        : `Your account is now paused for the next ${months[pausedMonths]} months, meaning you won't be renewed until ${confirmedRenewalDate}. See you then!`;
  } else {
    hed =
      pausedMonths == 1 ? (
        "You paused for one month."
      ) : (
        <>
          You paused for{" "}
          <span className={"nowrap"}>{pausedMonths} months.</span>
        </>
      );
    dek =
      pausedMonths == 1
        ? `Your account is now paused for the next month, meaning you won't be renewed until ${confirmedRenewalDate}. See you then!`
        : `Your account is now paused for the next ${months[pausedMonths]} months, meaning you won't be renewed until ${confirmedRenewalDate}. See you then!`;
  }

  return (
    <StyledFlexWrapper
      paddingLeft={isMobile ? 20 : 0}
      paddingRight={isMobile ? 20 : 0}
      paddingTop={isMobile ? 40 : 56}
      paddingBottom={isMobile ? 238 : 120}
    >
      <StyledFlexWrapper maxWidth={80}>
        <AlarmClockIcon showBackground={true} height={80} width={80} />
      </StyledFlexWrapper>
      <StyledFlexWrapper
        resetSpacing
        maxWidth={500}
        textAlign="center"
        marginTop={isMobile ? 12 : 20}
        gap={isMobile ? 12 : 20}
      >
        <h4>{hed}</h4>
        <p>{dek}</p>
      </StyledFlexWrapper>
      <StyledFlexWrapper
        gap={isMobile ? 12 : 20}
        marginTop={40}
        maxWidth={isMobile ? undefined : 259}
      >
        <ClickTracker
          ctaType={"button"}
          id={`pause-success${NORExpId}`}
          style={"primary -fullWidth"}
          linkTo={"/my-account/account"}
          title={"Return to my account"}
          logClickData={`Pause success - return to account${NORExpId}`}
        />
      </StyledFlexWrapper>
    </StyledFlexWrapper>
  );
};

const PauseSuccessExp = (props: any) => (
  <Variant experimentId={134}>
    {({ variant }: { variant: number }) => (
      <PauseSuccess {...props} exp134={variant} />
    )}
  </Variant>
);

export default PauseSuccessExp;
