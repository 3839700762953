import React from "react";

export const _PRE_FRIEND_DATA = [
  {
    hed: `Lower Priced Add-Ons`,
    dek: `Save $5 on every add-on, in every box.`,
  },
  {
    hed: "A Roomier Box",
    dek: "Ship up to 5 books per month!",
  },
  {
    hed: `Even More Choices`,
    dek: `Shhh, this one is our best-kept secret: choose from 5 member faves in addition to our monthly selections each month.`,
  },
  {
    hed: `Vote for Book of the Year`,
    dek: `You’ve got a say in who gets the Lolly.`,
  },
];

export const _FRIEND_DATA = [
  {
    hed: `Lower Priced Add-Ons`,
    dek: `You’re now saving $5 on every add-on. You’re welcome!`,
  },
  {
    hed: "A Roomier Box",
    dek: "Ship up to 5 books per month!",
  },
  {
    hed: `Even More Choices`,
    dek: `You can choose from 5 member faves in addition to our monthly selection.`,
  },
  {
    hed: `Vote for Book of the Year`,
    dek: `You’ve got a say in who gets the Lolly.`,
  },
];

export const _PRE_BFF_DATA = [
  {
    hed: `Free BOTM Tote Bag`,
    dek: `BFFs score our exclusive book-pocket tote! Prepare for compliments.`,
  },
  {
    hed: `Free Birthday Book`,
    dek: `BFFs get a free book credit during their birthday month!`,
  },
  {
    hed: `Free Book of the Year`,
    dek: `During BOTY season, BFFs get the finalist of their choice for free.`,
  },
  {
    image: `https://static.bookofthemonth.com/Relationship/BFF/Phone.png`,
    hed: `Private Member Services Line`,
    dek: `BFFs get access to a private email and phone number for premier support.`,
  },
];

export const _BFF_DATA = [
  {
    hed: `Free Birthday Book`,
    dek: `BFFs get a free book credit during their birthday month!`,
  },
  {
    hed: `Free Book of the Year`,
    dek: `During BOTY season, BFFs get the finalist of their choice for free.`,
  },
  {
    hed: `Private Member Services Line`,
    dek: `BFFs get access to a private email and phone number for premier support.`,
  },
];
