import * as actions from "./actions";

export default function reducer(state = "default", action) {
  switch (action.type) {
    case actions.SET_ENTRY_POINT:
      return action.payload;
    default:
      return state;
  }
}
