import { useState, useEffect } from "react";

const useFlickerLights = () => {
  const [isLightsOn, setLightsOn] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLightsOn(!isLightsOn);
    }, 1500);

    // clean up to avoid console warnings
    return () => {
      clearTimeout(timer);
    };
  }, [isLightsOn]);

  return isLightsOn;
};

export { useFlickerLights };
