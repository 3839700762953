import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { setOverlay } from "State/ui/creators";
import { ProductImage } from "UI/elements/product/ProductImage";

import styled from "styled-components";
import ReferFriendForm from "./ReferFriendForm.jsx";
import { urlify } from "Utils";

const RecommendBookModal = (props) => {
  let { overlay } = props;
  if (
    !overlay ||
    (overlay && overlay.name !== "recommendBookModal") ||
    !overlay?.data?.pdpTitle
  ) {
    return null;
  }
  const bookPath = urlify(overlay?.data?.pdpTitle, overlay?.data?.pdpId);

  return (
    <div style={{ minHeight: "470px", maxHeight: "470px" }}>
      <StyledTopBlock>
        <ProductImage
          img={overlay?.data?.img}
          size={"small"}
          title={`${overlay?.data?.pdpTitle}`}
        />
        <p>
          Recommend this book to a friend and get a free book on us when they
          join.
        </p>
      </StyledTopBlock>
      <ReferFriendForm
        location="recommendRead"
        account={props.account}
        recommendedBook={{
          path: bookPath,
          imgs: overlay?.data?.images,
          review: overlay?.data?.review,
        }}
        scrollUp={props.scrollUp}
        entryPoint={
          overlay?.data?.origin ? overlay?.data?.origin : "no-entryPoint"
        }
      />
    </div>
  );
};

export const StyledTopBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 30px;
    max-width: 350px;
    text-align: center;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendBookModal);
