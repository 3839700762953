import React from "react";
import styled from "styled-components";

export interface StyledCancelXProps {
  top?: number;
  bottom?: number;
}

export const CancelX: React.FC<{
  handleClose: (...args: any) => void;
  size?: number;
  color?: string;
  top?: number;
  bottom?: number;
}> = ({ handleClose, size, color, top, bottom }) => {
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleClose();
    }
  };

  return (
    <StyledCancelX
      className="cancelX"
      id={"StyledCancelX"}
      onClick={handleClose}
      top={top}
      bottom={bottom}
      tabIndex={0}
      onKeyPress={handleKeyPress}
    >
      <svg
        version="1.1"
        width={size ? `${size}px` : "16px"}
        height={size ? `${size}px` : "16px"}
        viewBox="0 0 241.171 241.171"
      >
        <path
          id="Close"
          fill={color ? color : "var(--color-gray5)"}
          d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0
          l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497
          c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563
          c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
        />
      </svg>
    </StyledCancelX>
  );
};

export const StyledCancelX = styled.div<StyledCancelXProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      transition: fill 200ms ease-in-out;
    }
  }
  svg:hover {
    path {
      fill: var(--color-primary5);
    }
    cursor: hand;
    cursor: pointer;
  }
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
`;

export default CancelX;
