import React from "react";
import SkeletonWrapper, { Jacket, IMG, Word, H2 } from "./SkeletonWrapper.jsx";
import HeaderBlockSkeleton from "./HeaderBlockSkeleton.jsx";

export const ListItemSkeleton = () => (
  <li className="sliderItem">
    <Jacket>
      <IMG />
    </Jacket>
  </li>
);

export const bookList = (key) => (
  <section className="content -wide booklistCarousel" key={key}>
    <h5>
      <Word />
    </h5>
    <div className="bookListCarouselList">
      <div className="sliderWrapper">
        <ul className="sliderItemsContainer">
          {new Array(5).fill(0).map((ignore, i) => (
            <ListItemSkeleton key={i} />
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export const ExtrasSkeletonList = ({ withHeader = false }) => (
  <div className="extrasLanding">
    {withHeader && (
      <HeaderBlockSkeleton className="headerBanner">
        <H2 />
      </HeaderBlockSkeleton>
    )}
    <SkeletonWrapper>
      {new Array(2).fill(0).map((ignore, i) => bookList(i))}
      {bookList()}
    </SkeletonWrapper>
  </div>
);

export const ExtrasPageSkeleton = ({
  className,
  header,
  subHead,
  isReallyLoggedIn,
}) => {
  header = header ? <h1>{header}</h1> : <H2 className="-p -center" />;
  return (
    <SkeletonWrapper className={`extrasGenres ${className || ""}`}>
      <div className="pageHeader">
        {header}
        {subHead}
      </div>
      <section>
        <ul className="booksGrid">
          {new Array(12).fill(0).map((ignore, i) => (
            <li key={i}>
              <div className="bookListItem">
                <Jacket>
                  <IMG />
                </Jacket>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </SkeletonWrapper>
  );
};

const ExtrasSkeleton = ({ pathname = null, metaData = null }) => {
  if (pathname) {
    let locationSplit =
      pathname[0] === "/" ? pathname.split("/").slice(1) : pathname.split("/");
    if (
      locationSplit.length > 2 ||
      locationSplit[1] === "nonfiction" ||
      locationSplit[1] === "ya"
    ) {
      return <ExtrasPageSkeleton metaData={metaData} />;
    }
  }
  return (
    <div className="loggedIn">
      <ExtrasSkeletonList withHeader={true} metaData={metaData} />
    </div>
  );
};

export default ExtrasSkeleton;
