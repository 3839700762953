import * as actions from "./actions";

export function setBookshelf(bookshelf) {
  return {
    type: actions.SET_BOOKSHELF,
    payload: bookshelf,
  };
}

export function addReview(review) {
  return {
    type: actions.ADD_REVIEW,
    payload: review,
  };
}
