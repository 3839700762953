/* eslint-disable react/jsx-key */
import React, { lazy, useContext, ReactElement } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";

import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import Authenticate from "UI/skeleton/Authenticate.jsx";
import UserFlowsSkeleton from "UI/loadingSkeletons/UserFlowsSkeleton.jsx";
import GiftFlowSkeleton from "UI/loadingSkeletons/GiftFlowSkeleton.jsx";

const GiftRedeemLanding = loadable(
  () => import("UI/pages/gift/redeem/GiftRedeemLanding.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const SinglePageGift = loadable(
  () => import("UI/pages/gift/purchase/exp135/SinglePageGift"),
  { fallback: <GiftFlowSkeleton /> },
);
const GiftPurchaseWrapper = loadable(
  () => import("UI/pages/gift/purchase/GiftPurchaseWrapper.jsx"),
  { fallback: <GiftFlowSkeleton /> },
);
const Billing = loadable(() => import("UI/pages/join/Billing.jsx"), {
  fallback: <UserFlowsSkeleton />,
});
const GiftPreenroll = loadable(
  () => import("UI/pages/gift/GiftPreenroll.jsx"),
  {
    fallback: <UserFlowsSkeleton />,
  },
);
const GiftRejoinLanding = loadable(
  () => import("UI/pages/gift/rejoin/GiftRejoinLanding.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const CorporateGift = loadable(
  () => import("UI/pages/gift/corporate/CorporateGift.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const GiftPreenrollSuccess = loadable(
  () => import("UI/pages/gift/GiftPreenrollSuccess.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);

const Gift = (() => [
  <Route exact path="/gift/gift-botm" component={SinglePageGift} />,
  <Route exact path="/gift/preenroll" component={GiftPreenroll} />,
  <Route
    exact
    path="/gift/preenroll-success"
    component={GiftPreenrollSuccess}
  />,

  <Route
    exact
    path={["/gift/redeem/:step?", "/gift/redeem"]}
    component={GiftRedeemLanding}
  />,
  <Route
    exact
    path={[
      "/gift/purchase/:step?",
      "/gift/member/purchase/:step?",
      "/gift/purchase",
      "/gift/member/purchase",
    ]}
    component={GiftPurchaseWrapper}
  />,
  <Route exact path="/redeem">
    <Redirect to="/gift/redeem" />
  </Route>,
  <Route exact path="/gift/rejoin">
    <Authenticate>
      <Billing noExperiment={true} />
    </Authenticate>
  </Route>,
  <Route
    exact
    path={["/gift-offer/confirmation", "/gift-offer"]}
    component={GiftRejoinLanding}
  />,
  <Route exact path="/corporate" component={CorporateGift} />,
])();

export default Gift;
