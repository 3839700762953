import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "State/account/flows";
import styled from "styled-components";
import { setOverlay } from "State/ui/creators";
import { logVisit } from "State/analytics/flows";
import { markUnderThirteenAccount } from "State/account/flows";
import ClickTracker from "UI/elements/ClickTracker";
import { useRegion } from "UI/hooks/useRegion";
import { useWindowSize } from "UI/hooks";

const AgeVerificationModal = (props) => {
  let { setOverlay, overlay } = props;
  const { countryCode } = useRegion();
  const [width] = useWindowSize();
  const isMobile = width <= 680;

  useEffect(() => {
    logVisit({
      store: { getState: () => props.store },
      countryCode: props.account?.countryCode || countryCode,
    });
  }, []);

  const logOut = async () => {
    await markUnderThirteenAccount();
    props.logout("/age-requirement");
    return false;
  };

  const closeAction = () => {
    setOverlay(null);
  };

  return (
    <StyledAgeVerificationModal>
      {isMobile ? (
        <h2>Please confirm your age.</h2>
      ) : (
        <h3>Please confirm your age.</h3>
      )}
      <p className={"dek p1"}>Are you {overlay?.data?.age} years old?</p>

      <StyledButtonWrapper>
        <ClickTracker
          ctaType={"button"}
          id="Age Confirm Yes"
          style="secondary"
          handleClick={() => {
            logOut();
          }}
          isPending={false}
          title={"Yes"}
          logClickData={"Clicked age conf yes"}
        />
        <ClickTracker
          ctaType={"button"}
          id="Age Confirm no"
          style="secondary"
          handleClick={() => closeAction()}
          isPending={false}
          title={"No"}
          logClickData={"Clicked age conf no"}
        />
      </StyledButtonWrapper>
    </StyledAgeVerificationModal>
  );
};

export const StyledAgeVerificationModal = styled.div.attrs({
  className: "sc-StyledAgeVerificationModal",
})`
  width: 100%;
  text-align: center;
  max-width: 480px;
  padding: 40px;
  @media screen and (max-width: 640px) {
    max-width: 100%;
  }
`;

export const StyledButtonWrapper = styled.div.attrs({
  className: "sc-StyledButtonWrapper",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  button {
    min-width: 48% !important;
    max-width: 48% !important;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
    store: state,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, logout }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgeVerificationModal);
