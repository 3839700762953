import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setJoinPlan } from "State/joinData/creators";
import { setLoginFlow } from "State/loginFlow/creators";
import { useStoreData } from "UI/hooks/useStoreData";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import { queryparser } from "Utils/queryparser";
import { snesRedirect } from "Utils/snesRedirect";

const LeadAuthenticate = (props) => {
  const { loginAttempted, account, joinData, fallback } = props,
    { pathname, search } = useLocation();
  const { store } = useStoreData(),
    { referralPlan } = store;
  let queries = queryparser(search);
  const loadingState = fallback || <LoadingCoinSkeleton />;
  if (loginAttempted) {
    const policy = account && account.policy ? account.policy : props.policy;
    const isSoftLoggedIn = policy?.type === "Lead" || policy?.type === "User";
    const isVisitor =
      !policy || !Object.keys(policy).length || policy.type === "Visitor";
    const isLoggedIn = !isSoftLoggedIn && !isVisitor;
    const isReferral = !!joinData.referralCode || queries?.referCode;

    if (isSoftLoggedIn || isVisitor) {
      if (
        // skip step plan
        pathname.match(/select-plan/) &&
        (!!joinData.grifterCode || // isHolidayOffer
          isReferral ||
          policy?.planSet?.plans.length <= 1) // not multiPlan
      ) {
        if (!joinData.plan) {
          props.setJoinPlan(
            isReferral ? referralPlan : policy.planSet.plans[0],
          );
        }

        props.push(
          joinData.type === "rejoin"
            ? "/credit-rejoin/join-billing"
            : "/join-billing",
        );
        return loadingState;
      }
      if (
        isSoftLoggedIn &&
        (pathname.match(/^\/*email-capture\/botm/) ||
          pathname.match(/^\/*email-capture/) ||
          pathname.match(/^\/*enroll/))
      ) {
        snesRedirect("snes", "/the-best-new-books");
        return loadingState;
      }
      if (!isLoggedIn && pathname.match(/^\/*all-selections/)) {
        props.push("/email-capture/all-selections");
        return loadingState;
      }
      if (!isLoggedIn && pathname.match(/^\/*enroll\/confirmation/)) {
        snesRedirect("snes", "/");
        return loadingState;
      }

      // if not redirected just return children;
      return props.children;
    } else {
      if (props.renderIfAuthFail || pathname.match("/the-best-new-books"))
        return props.children;
      else {
        snesRedirect("snes", "/the-best-new-books");
        return loadingState;
      }
    }
  } else {
    return loadingState;
  }
};

function mapStateToProps(state, ownProps) {
  return {
    policy: state.policy,
    account: state.account,
    loginAttempted: state.loading.loginAttempted,
    joinData: state.joinData,
    location: ownProps.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLoginFlow, push, setJoinPlan }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeadAuthenticate),
);
