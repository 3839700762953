import * as actions from "./actions";

export default function reducer(state = { error: null, type: null }, action) {
  switch (action.type) {
    case actions.SET_ERROR:
      return { error: action.payload.error, type: action.payload.type };
    case actions.CLEAR_ERROR:
      return { error: null, type: null };
    default:
      return state;
  }
}
