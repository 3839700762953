/**
 * Billing.jsx
 * Hold billing form as well as braintree logic
 */
import React, { Component, useContext } from "react";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getClientToken, getPaymentMethod } from "State/memberPaymentMethod";
import { createAccount, join, updateAccount } from "State/account/flows";
import { setJoinPlan, clearJoinStatus } from "State/joinData/creators";
import { getJoinTaxRate, getPlan, setJoinStepPlan } from "State/joinData/flows";
import { experimentFlowTracking } from "Utils/analytics";
import { queryparser } from "Utils/queryparser";
import { getPlans } from "Utils/getPlans";

import { isReferred } from "State/joinData/selectors";
import { scrollToError } from "UI/components/forms/Form.jsx";
import { mustRenew, canJoinAndShip } from "State/account/selectors";
import { getPromo } from "State/promo/flows";

import CurrentPaymentMethodBlock from "UI/components/account/CurrentPaymentMethodBlock";
import Logo from "UI/elements/Logo.jsx";
import QuoteOrderSummary from "UI/pages/join/QuoteOrderSummary.jsx";
import FieldsetShippingInfo from "UI/components/forms/FieldsetShippingInfo.jsx";
import FieldsetAccountInfo from "UI/components/forms/FieldsetAccountInfo";
import PromoCodeInput from "UI/components/PromoCodeInput.jsx";
import Braintree from "UI/components/Braintree.jsx";
import { setEntryPoint } from "State/entryPoint/creators";
import { withStoreData } from "UI/hoc/withStoreData";
import { RegionContext } from "UI/contexts/RegionContext";
import { getCountryCode } from "Utils/countryPricing";
import PurchaseConsentBlock from "UI/components/forms/PurchaseConsentBlock";
import { useWindowSize } from "UI/hooks";
import { useCreditCommit } from "UI/contexts/CreditCommitContext";
import { snesRedirect } from "Utils/snesRedirect";

const PaymentMethodAttempt = {
  "Haven't tried": -2,
  Attempting: -1,
  "No payment method": 1,
  "Payment method added": 2,
};

class ShipBilling extends Component {
  constructor(props) {
    super(props);
    const accountInfo =
      props.joinData.shipping || props.joinData.account || props.account;
    this.state = {
      status: "billing",
      braintreeError: null,
      pending: false,
      promo:
        props.joinData?.promo &&
        props.joinData.promo === props.joinData?.plan?.promo
          ? props.joinData.promo
          : null,
      promoError: props.joinData.promoError,
      showContent: false,
      account: {
        firstName: (accountInfo && accountInfo.firstName) || null,
        lastName: (accountInfo && accountInfo.lastName) || null,
        email: (accountInfo && accountInfo.email) || null,
        displayName: null,
        password: null,
        trackId: props.storeTrack.id,
        countryCode: props.countryCode,
      },
      address: {
        firstName: (accountInfo && accountInfo.firstName) || null,
        lastName: (accountInfo && accountInfo.lastName) || null,
        street1: (accountInfo && accountInfo.street1) || null,
        street2: (accountInfo && accountInfo.street2) || null,
        city: (accountInfo && accountInfo.city) || null,
        state: (accountInfo && accountInfo.state) || null,
        zip: (accountInfo && accountInfo.zip) || null,
        countryCode: props.countryCode,
      },
      addressIsValid: false,
      accountIsValid:
        Boolean(props?.account?.hasPassword && props?.account?.email) || false,
      formSubmitting: false,
      badCard:
        (props.account &&
          props.account.policy &&
          props.account.policy.type === "Rejoin" &&
          props.account.policy.subType === "CC Fail") ||
        false,
      showPlans: false,
      showShippingEdit: true,
      hasAddress: false,
      addPayment: false,
      paymentMethodAttempt: PaymentMethodAttempt["Haven't tried"],
      formIsValid: false,
    };

    this.handleChangePromo = this.handleChangePromo.bind(this);
    this.toggleFormSubmitting = this.toggleFormSubmitting.bind(this);
    this.toggleAddPayment = this.toggleAddPayment.bind(this);
    this.toggleShowPlans = this.toggleShowPlans.bind(this);
    this.toggleShippingEdit = this.toggleShippingEdit.bind(this);
    this.submitCallback = this.submitCallback.bind(this);
    this.failFunction = this.failFunction.bind(this);

    // Comment out for testing only!!!!!
    if (!props.account.id && !props.directLanding) {
      props.push(`/`);
    }
    if (this.props.siteEntry) {
      this.props.setEntryPoint(this.props.siteEntry);
    }
  }

  componentDidMount() {
    this.props?.didMountCB?.();
    let { address } = this.state;
    const isLead =
      this.props.account?.policy?.type === "Lead" ||
      this.props.account?.policy?.type === "Visitor";
    let plan = this.props.plan;
    if (!plan) {
      plan = this.props.defaultPlan;
      this.props.setJoinPlan(this.props.defaultPlan);
    }
    if (this.props.joinData.grifterCode && plan?.id) {
      // this is what uses grifter code
      this.props.getPromo({
        promo: this.props.joinData.grifterCode,
        planId: plan.id,
        trackId: this.props.storeData.track.id,
        countryCode: this.props.countryCode,
      });
    }
    if (
      !isLead &&
      !PaymentMethodAttempt[this.state.paymentMethodAttempt] &&
      this.props.account
    ) {
      this.setState({
        paymentMethodAttempt: PaymentMethodAttempt["Attempting"],
      });
      const failFunction = () =>
          this.setState({
            paymentMethodAttempt: PaymentMethodAttempt["No payment method"],
          }),
        successFunction = () =>
          this.setState({
            paymentMethodAttempt: PaymentMethodAttempt["Payment method added"],
          });
      this.props.getPaymentMethod(successFunction, failFunction);
    }
    const gift = this.props.gift;

    if (
      !this.state.account.email &&
      gift &&
      gift.giftPurchase &&
      gift.giftPurchase.giver
    ) {
      this.setState({
        account: {
          ...this.state.account,
          email: gift.giftPurchase.giver.email,
        },
      });
    }
    if (
      address &&
      address?.street1?.length &&
      address?.city?.length &&
      address?.state?.length &&
      address?.zip?.length &&
      address?.countryCode?.length
    ) {
      this.setState({
        showShippingEdit: false,
        hasAddress: true,
      });
      this.props.getJoinTaxRate(address.zip);
    }
    const query = queryparser(this.props.location.search);
    let loyaltyCode = null;
    const { theme: themeUS, themeCA } = this.props.storeData?.store || {};
    const theme = this.props.account?.countryCode == "CA" ? themeCA : themeUS;

    if (this.props.isLoyaltyOffer) {
      loyaltyCode = this.props?.location?.pathname?.split?.("/").pop();
    }
    if (loyaltyCode) {
      this.props.getPromo({
        promo: loyaltyCode,
        planId: plan?.id || 200001,
        trackId: this.props.storeData.track.id,
      });
    } else if (query.code) {
      this.props.getPromo({
        promo: query.code,
        planId: plan?.id || 200001,
        trackId: this.props.storeData.track.id,
      });
    } else if (
      [5, 6].includes(theme?.id) &&
      this.props.joinData?.type === "rejoin" &&
      !this.props.joinData.grifterCode &&
      this.props.account?.policy?.type !== "Rejoin" &&
      this.props.account.policy.subType === "Gift"
    ) {
      let code;
      if (this.props.account?.policy?.subType === "Rejoin A") {
        code = "BFF";
      } else if (
        theme.id === 5 &&
        !["CC Fail"].includes(this.props.account?.policy?.subType)
      ) {
        code = "READUP";
      } else if (
        theme.id === 6 &&
        !["CC Fail"].includes(this.props.account?.policy?.subType)
      ) {
        code = "READUP";
      }
      this.props.getPromo({
        promo: code,
        planId: plan.id,
        trackId: this.props.storeData.track.id,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.props?.didUpdateCB?.();
    if (
      (prevProps?.error?.error !== this.props?.error?.error &&
        this.props?.error?.error) ||
      (prevProps.joinData.status !== this.props.joinData.status &&
        this.props.joinData.status === "fail")
    ) {
      let error = this.props.joinData.error || this.props.error;
      this.setState({
        pending: false,
        formSubmitting: false,
        braintreeError: error,
      });
      scrollToError();
    }

    if (
      prevProps.joinData.grifterCode !== this.props.joinData.grifterCode &&
      this.props.joinData.grifterCode
    ) {
      let plan = this.props.plan;
      if (!plan) {
        plan = this.props.defaultPlan;
        this.props.setJoinPlan(this.props.defaultPlan);
      }
      if (this.props.joinData.grifterCode && plan?.id) {
        this.props.getPromo({
          promo: this.props.joinData.grifterCode,
          planId: plan.id,
          trackId: this.props.storeData.track.id,
        });
      }
    }

    if (this.state.address.zip !== prevState.address.zip) {
      this.props.getJoinTaxRate(this.state.address.zip);
    }
    if (
      prevProps?.account?.paymentMethod !==
        this.props?.account?.paymentMethod &&
      this.props.account.paymentMethod &&
      this.state.paymentMethod !== PaymentMethodAttempt["Payment method added"]
    ) {
      this.setState({
        paymentMethodAttempt: PaymentMethodAttempt["Payment method added"],
      });
    }

    if (this.props.account !== prevProps.account) {
      const { account, ccFailurePolicy } = this.props;
      if (
        account.policy &&
        account.policy.type === "Rejoin" &&
        account.policy.subType === "CC Fail"
      ) {
        this.setState({ badCard: true });
      }
      if (
        account &&
        account.policy &&
        account.policy.type !== "Rejoin" &&
        account.policy.subType !== "Pause" &&
        account.policy.type !== "Lead" &&
        account.policy.type !== "User"
      ) {
        snesRedirect("snes", `/the-best-new-books`);
      }
    }
  }

  handleChangePromo(event) {
    this.setState({ promo: event.target.value });
  }

  async joinFunction(paymentObj) {
    const { joinData, join, account, confLink, creditCommitCCFailData } =
      this.props;
    const { address } = this.state;
    const isCCFail =
      account?.policy?.type == "Rejoin" &&
      account?.policy?.subType === "CC Fail";
    const successFunction = () => this.props.setStatus("success");
    try {
      const promoCode =
        (this.state.promo && this.state.promo.code) ||
        (joinData.promo && joinData.promo.code);

      if (joinData.type === "rejoin") {
        let type =
          account?.policy?.type === "Rejoin" &&
          account?.policy?.subType === "Pause"
            ? "unpause"
            : account?.policy?.type === "Rejoin" &&
              account?.policy?.subType === "Gift"
            ? "gift rejoin"
            : "rejoin";
        let joinPlan = joinData.plan;
        if (isCCFail) {
          if (creditCommitCCFailData.creditCommitPolicyCycles > 1) {
            joinPlan = creditCommitCCFailData.ccFailCreditCommitPlan;
          } else {
            joinPlan =
              account.renewalPlan ||
              this.props.plan ||
              account?.policy?.planSet?.plans?.[0];
          }
        }

        return await join({
          type, // account
          address: {
            ...address,
            accountId: account.id,
            name: address.firstName + " " + address.lastName,
          }, // address
          plan: joinPlan, // plan
          nonce: paymentObj?.nonce, // nonce
          promoCode, // promoCode
          confLink,
          renewalPlan: joinData.renewalPlan,
          planUpgrade: null,
          successFn: successFunction,
        });
      } else {
        experimentFlowTracking("Purchase Membership");
        return await join({
          type: "enroll",
          address: {
            ...address,
            accountId: account.id,
            name: address.firstName + " " + address.lastName,
          },
          plan: joinData.plan,
          nonce: paymentObj?.nonce,
          promoCode,
          confLink,
          holidayCode: joinData.holidayCode,
          renewalPlan: joinData.renewalPlan,
        });
      }
    } catch (error) {
      let e = error;
      if (error.name === "Error") {
        e = { error: error.message, type: "braintree" };
      }
      this.setState(
        {
          pending: false,
          braintreeError: e,
        },
        scrollToError(),
      );
    }
  }

  toggleFormSubmitting(evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    this.setState({ formSubmitting: !this.state.formSubmitting });
  }

  toggleShippingEdit() {
    if (this.state.showShippingEdit) {
      const accountInfo =
        this.props.joinData.shipping ||
        this.props.joinData.account ||
        this.props.account;
      this.setState({
        address: {
          firstName: (accountInfo && accountInfo.firstName) || "",
          lastName: (accountInfo && accountInfo.lastName) || "",
          street1: (accountInfo && accountInfo.street1) || "",
          street2: (accountInfo && accountInfo.street2) || "",
          city: (accountInfo && accountInfo.city) || "",
          state: (accountInfo && accountInfo.state) || "AL",
          zip: (accountInfo && accountInfo.zip) || "",
          countryCode: this.props.countryCode,
        },
      });
    }
    this.setState({
      showShippingEdit: !this.state.showShippingEdit,
    });
  }

  toggleAddPayment(evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    this.setState({
      addPayment: !this.state.addPayment,
      editPayment: false,
      braintreeError: null,
    });
  }

  toggleShowPlans(e) {
    //used for rejoin
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let joinData = this.props.joinData;
    if (joinData.type === "rejoin") {
      this.setState({ showPlans: !this.state.showPlans });
    }
  }

  goBackToGiftPlan() {
    this.props.push("/gift/purchase");
  }

  failFunction(error) {
    this.setState(
      {
        formSubmitting: false,
        pending: false,
        braintreeError: {
          error: error?.message || error?.error || error,
          type: error?.type || "braintree",
        },
        error: {
          error: error?.message || error?.error || error,
          type: error?.type || "account",
        },
      },
      scrollToError(),
    );
  }

  async submitCallback(paymentObj = null) {
    this.setState({ pending: true });

    if (!this.props?.account?.id) {
      try {
        await this.props.createAccount(
          { ...this.state.account },
          async () => await this.joinFunction(paymentObj),
          this.failFunction,
          true,
        );
      } catch (error) {
        let e = { error: error.message, type: "account" };
        this.setState(
          {
            pending: false,
            braintreeError: e,
          },
          scrollToError(),
        );
      }
    } else {
      const {
        storeData: { store },
      } = this.props;
      await this.props.updateAccount(
        { ...this.state.account },
        store,
        async () => await this.joinFunction(paymentObj),
        this.failFunction,
        true,
      );
    }
  }

  render() {
    let { formSubmitting, address, badCard, formIsValid } = this.state,
      {
        box,
        plan,
        joinData,
        account,
        canJoinAndShip,
        storeTrack,
        isReferral,
        confLink,
        storeData,
        isMobile,
      } = this.props;

    const accountPolicy = account && account.policy;
    const isCCFail =
      account?.policy?.type == "Rejoin" &&
      account?.policy?.subType === "CC Fail";

    let bookText,
      buttonText = "Join now";

    const isPaused =
      accountPolicy &&
      accountPolicy.type === "Rejoin" &&
      accountPolicy.subType === "Pause";

    let headline = this.props.headline
      ? "You’re almost in!"
      : "You’re almost in.";
    let subHead = this.props.subHead || "All we need is a few more details.";

    if (box?.books?.length && canJoinAndShip) {
      bookText = box?.books?.length > 1 ? "books" : "book";
      headline = `We’re ready to send your ${bookText}!`;
    }

    if (joinData.type === "rejoin") {
      headline = badCard
        ? "Time for an update."
        : isPaused
        ? "Resume today"
        : "Rejoin today";
      if (box?.books?.length && canJoinAndShip && isPaused) {
        bookText = box?.books?.length > 1 ? "books" : "book";
        headline = `We’re ready to send your ${bookText}!`;
      }
      subHead = badCard
        ? "Update your information to rejoin today."
        : "Review your information below and complete your order.";
      buttonText = isPaused
        ? "Resume subscription"
        : isCCFail
        ? "Renew now"
        : "Rejoin";
    }

    let isLead =
      !account ||
      account?.policy?.type === "Lead" ||
      account?.policy?.type === "Visitor";
    let showCurrentPaymentMethod =
      !isLead &&
      account?.paymentMethod &&
      !badCard &&
      !this.state.addPayment &&
      !this.state.braintreeError;

    let currentPaymentMethod = account?.paymentMethod ? (
      <CurrentPaymentMethodBlock
        cardType={account.paymentMethod.cardType}
        last4={account.paymentMethod.last4}
      />
    ) : null;

    let addPaymentMethod = (
      <div>
        <form id="hosted-fields-form">
          <Braintree
            top={40}
            paymentMethodAttempt={this.state.paymentMethodAttempt}
            submitting={this.state.formSubmitting}
            toggleFormSubmitting={this.toggleFormSubmitting}
            submitFunction={!showCurrentPaymentMethod && this.submitCallback}
            callbackOnFormValidated={(hasInvalidFields) => {
              this.setState({ formIsValid: !hasInvalidFields });
            }}
          />
        </form>
      </div>
    );

    let source = joinData.type === "rejoin" ? "rejoin" : "enroll";
    let rootPath = this.props.path.split("/");
    let isGiftOffer =
        source === "enroll" && rootPath[2] === "purchase-confirmation",
      isGiftRejoin = source === "rejoin" && rootPath[1] === "gift-offer",
      //isLoyaltyOffer = rootPath[1] === 'loyalty-offer',
      isGrifterOffer = !!joinData.grifterCode,
      isMothersDayOffer = !!joinData.holidayCode;

    let planSetPlans = getPlans(accountPolicy?.planSet, storeTrack.id);
    let multiPlan = planSetPlans && planSetPlans.length > 1;
    let changePlanInline =
      (multiPlan && source === "enroll") ||
      joinData.promo?.code !== "SOHOHOUSE" ? (
        <Link to={"/select-plan"} className="miniText">
          Edit
        </Link>
      ) : null;

    // add space above logo for the banner
    // let noBanner = (isMothersDayOffer || isPaused || isLoyaltyOffer || isGrifterOffer || joinData.type == 'rejoin')
    //   ? { marginTop: '0' } : null;

    if (this.props.isLoyaltyOffer) {
      changePlanInline = null;
    } //<Link className="miniText" to={'/bff/select-plan'}>Edit</Link>;
    else if (
      multiPlan &&
      source === "rejoin" &&
      !isMothersDayOffer &&
      !isGrifterOffer
    ) {
      changePlanInline = (
        <Link className="miniText" to={"/credit-rejoin/select-plan"}>
          Edit
        </Link>
      );
    } else if (source === "gift" && !isGiftOffer && !isMothersDayOffer) {
      changePlanInline = (
        <a className="miniText" onClick={() => this.goBackToGiftPlan()}>
          Edit
        </a>
      );
    } else if (
      isGiftOffer ||
      this.props.isGroupon ||
      isGiftRejoin ||
      isReferral ||
      isMothersDayOffer ||
      isGrifterOffer
    ) {
      changePlanInline = <div />;
    }

    const showPromo =
      (!joinData.account ||
        (joinData.type != "rejoin" &&
          accountPolicy &&
          accountPolicy.type !== "Rejoin" &&
          accountPolicy &&
          accountPolicy.subType !== "Pause" &&
          !this.props.isLoyaltyOffer &&
          !(
            joinData.holidayCode &&
            joinData.holidayCode &&
            joinData.holidayCode.length
          ))) &&
      joinData.promo?.code !== "SOHOHOUSE" &&
      !this.props.isLoyaltyOffer;

    let promoCodeInput =
      showPromo && account?.policy?.subType !== "CC Fail" ? (
        <PromoCodeInput
          handleChange={this.handleChangePromo}
          promo={this.state.promo}
          isReferral={this.props.isReferral}
        />
      ) : null;

    const submitFunction = () => {
      if (showCurrentPaymentMethod) {
        // if we're not using BT form
        this.toggleFormSubmitting();
        this.submitCallback();
      } else {
        this.toggleFormSubmitting();
      }
    };
    const { theme: themeUS, themeCA } = this.props.storeData?.store || {};
    const theme = this.props.account?.countryCode == "CA" ? themeCA : themeUS;
    if (
      [5, 6].includes(theme?.id) &&
      (this.props.isLoyaltyOffer || accountPolicy?.subType === "Rejoin A") &&
      !joinData.grifterCode
    ) {
      headline = (
        <>
          {" "}
          Almost there, <span className="nowrap">almost-BFF.</span>
        </>
      );
      subHead = (
        <> Double - check that everything looks right before you rejoin.</>
      );
      confLink = "loyalty";
    }

    if (isCCFail) {
      const plan =
        account?.renewalPlan ||
        this.props.plan ||
        account?.policy?.planSet?.plans?.[0];
      return (
        <form>
          {!this.state.hasAddress && (
            <FieldsetShippingInfo
              address={address}
              noButton
              onChangeTextCb={(a) => {
                this.setState(
                  {
                    formSubmitting: false,
                    address: a,
                  },
                  () => {
                    if (!this.state.firstName && this.state.address.firstName) {
                      // no account first name
                      let firstName = this.state.address.firstName;
                      this.setState({
                        account: {
                          ...this.state.account,
                          firstName: firstName,
                        },
                      });
                    }
                    if (!this.state.lastName && this.state.address.lastName) {
                      // no account last name
                      let lastName = this.state.address.lastName;
                      this.setState({
                        account: { ...this.state.account, lastName: lastName },
                      });
                    }
                    if (address.zip) {
                      this.props.getJoinTaxRate(address.zip);
                    }
                  },
                );
              }}
              validFormCheckCB={(v) => this.setState({ addressIsValid: v })}
            />
          )}
          {showCurrentPaymentMethod ? currentPaymentMethod : addPaymentMethod}
          <PurchaseConsentBlock
            idAmend={"ship-billing"}
            cta={buttonText}
            buttonId={`btn-ship-billing-submit`}
            btnIsPending={this.state.formSubmitting}
            btnIsDisabled={!formIsValid}
            customPlan={plan}
            btnAction={formIsValid ? submitFunction : null}
            marginTop={isMobile ? 0 : 20}
          />
        </form>
      );
    }

    return (
      <div className="shippingBilling">
        <Link className="masthead" to="/">
          <Logo />
        </Link>
        <div className="pageHeader">
          {confLink && (
            <img
              src={`${storeTrack.images}loyalty/logos/bff_badge.png`}
              style={{
                width: 165,
                height: "auto",
                marginBottom: 20,
              }}
              alt="Book of the Month BFF Badge"
            />
          )}
          <h1 style={{ maxWidth: "520px", margin: "0 auto" }}>{headline}</h1>
          <p>{subHead}</p>
        </div>

        <div className="cardBlock">
          {(!(account && account.email) ||
            !(account && account.hasPassword)) && (
            <>
              <h4>Account details</h4>
              <FieldsetAccountInfo
                fieldsToShow={["email", "password"]}
                noButton
                onChangeTextCb={(a) =>
                  this.setState({
                    formSubmitting: false,
                    account: a,
                  })
                }
                validFormCheckCB={(v) => this.setState({ accountIsValid: v })}
                bottom={20}
              />
            </>
          )}

          <div className="editHeader">
            <h4>Shipping details</h4>
            {this.state.hasAddress && (
              <a
                onClick={() => this.toggleShippingEdit()}
                className="smallText"
              >
                {this.state.showShippingEdit ? "Cancel" : "Edit"}
              </a>
            )}
          </div>

          <FieldsetShippingInfo
            address={address}
            noButton
            onChangeTextCb={(a) => {
              this.setState(
                {
                  formSubmitting: false,
                  address: a,
                },
                () => {
                  if (!this.state.firstName && this.state.address.firstName) {
                    // no account first name
                    let firstName = this.state.address.firstName;
                    this.setState({
                      account: { ...this.state.account, firstName: firstName },
                    });
                  }
                  if (!this.state.lastName && this.state.address.lastName) {
                    // no account last name
                    let lastName = this.state.address.lastName;
                    this.setState({
                      account: { ...this.state.account, lastName: lastName },
                    });
                  }
                  if (address.zip) {
                    this.props.getJoinTaxRate(address.zip);
                  }
                },
              );
            }}
            validFormCheckCB={(v) => this.setState({ addressIsValid: v })}
          />

          <form id="hosted-fields-form">
            <div className="editHeader">
              <h4 className="-paymentInfo">Payment method</h4>
              {account.paymentMethod && !badCard && (
                <a
                  onClick={() => this.toggleAddPayment()}
                  className="smallText"
                >
                  {this.state.addPayment ? "Cancel" : "Edit"}
                </a>
              )}
            </div>
            {showCurrentPaymentMethod ? currentPaymentMethod : addPaymentMethod}
          </form>
        </div>
        <div
          className="cardBlock -topHighlite"
          style={{ marginBottom: "20px" }}
        >
          <form>
            <div style={{ marginBottom: 30 }}>{promoCodeInput}</div>
            <QuoteOrderSummary
              account={account}
              box={box}
              plan={this.props.plan}
              promo={this.props.planPromo}
              taxRate={this.props.taxRate || 0}
              authors={this.props.authors}
              mustRenew={this.props.mustRenew}
              changePlanInline={changePlanInline}
              storeData={
                this.props.storeData?.store
                  ? this.props.storeData.store
                  : this.props.storeData
              }
              isReferral={this.props.isReferral}
            />
            <PurchaseConsentBlock
              idAmend={"ship-billing"}
              cta={buttonText}
              buttonId={`btn-ship-billing-submit`}
              btnIsPending={this.state.formSubmitting}
              btnIsDisabled={!formIsValid}
              customPlan={this.props.plan}
              btnAction={formIsValid ? submitFunction : null}
              showNegativeOptionRuleFinePrint={true}
            />
          </form>
        </div>
      </div>
    );
  }
}

const ShipBillingWithRegionContext = (props) => {
  const { locationHeader } = useContext(RegionContext);
  const accountInfo =
    props.joinData.shipping || props.joinData.account || props.account;
  const canadaAppFeature = props.appFeatures?.find(
    (f) => f?.feature == "True North",
  );
  const showProjectNorth =
    canadaAppFeature?.status === "Active" ||
    canadaAppFeature?.status === "Beta";
  const countryCode = getCountryCode(
    showProjectNorth,
    accountInfo?.countryCode,
    locationHeader.countryHeader,
  );
  const isMobile = useWindowSize()?.[0] < 680;

  const creditCommitCCFailData = useCreditCommit();

  return (
    <ShipBilling
      {...props}
      countryCode={countryCode}
      isMobile={isMobile}
      creditCommitCCFailData={creditCommitCCFailData}
    />
  );
};

function mapStateToProps(state, ownProps) {
  const { store } = ownProps.storeData;
  return {
    account: { ...state.account, ...state.address },
    token: state.clientToken.token,
    joinData: state.joinData,
    plan: state.joinData.plan,
    defaultPlan: state.account?.planSet?.plans?.filter?.(
      (p) => p.cycles < 12,
    )?.[0],
    box: state.box,
    planPromo: state.joinData.promo,
    mustRenew: mustRenew({
      ...state,
      store: { data: { ...state.store.data, ...store } },
    }),
    gift: state.gift,
    canJoinAndShip: canJoinAndShip({ ...state, store: { data: { ...store } } }),
    storeTrack: ownProps.storeData.track,
    ccFailurePolicy: store.ccFailurePolicy,
    error: state.error,
    path: state.analytics.location,
    isReferral: isReferred({
      ...state,
      store: { data: { ...state.store.data, ...store } },
    }),
    taxRate: state.joinData.tax_rate,
    authors: state.store.authors,
    storeData: ownProps.storeData,
    promos: state.promo.promos,
    referrer: state.analytics.referrer,
    entrypoint: state.entryPoint,
    appFeatures: state.appFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientToken,
      setJoinPlan,
      clearJoinStatus,
      getJoinTaxRate,
      createAccount,
      updateAccount,
      join,
      getPaymentMethod,
      push,
      getPlan,
      setJoinStepPlan,
      getPromo,
      setEntryPoint,
    },
    dispatch,
  );
}
export default withRouter(
  withStoreData(
    connect(mapStateToProps, mapDispatchToProps)(ShipBillingWithRegionContext),
  ),
);
