import { join } from "State/account/flows";
import { setAccountPaymentMethod } from "State/account/creators";
import { setJoinStatusFail } from "State/joinData/creators";
import { setClientToken, gettingClientToken } from "State/clientToken/creators";

import { getJoinTaxRate } from "State/joinData/flows";
import { setError } from "State/error/creators";

import * as svcApi from "../svc/api";

export function addPaymentMethod(addObj, successFunction, failFunction) {
  return async (dispatch, getState) => {
    try {
      const { headers } = getState();
      await svcApi.addPaymentMethod(addObj, headers);
      successFunction?.();
      dispatch(getPaymentMethod());
    } catch (err) {
      failFunction?.();
      dispatch(setError(err.message, "braintree"));
    }
  };
}

export function editPaymentMethod(updateObj, successFunction, failFunction) {
  return async (dispatch, getState) => {
    try {
      const { headers } = getState();
      await svcApi.editPaymentMethod(updateObj, headers);
      successFunction?.();
      dispatch(getPaymentMethod());
    } catch (err) {
      failFunction?.();
      dispatch(setError(err.message, "braintree"));
    }
  };
}

export function getPaymentMethod(successFunction) {
  return async (dispatch, getState) => {
    if (getState().account) {
      try {
        const res = await svcApi.getPaymentMethod();
        dispatch(setAccountPaymentMethod(res));
        dispatch(getJoinTaxRate(res.billingAddress.postalCode));
        successFunction?.();
      } catch (err) {
        console.error("failed to get payment methods", err);
      }
    }
  };
}

export function getClientToken(callback) {
  return async (dispatch, getState) => {
    if (!getState().clientToken.loading) {
      dispatch(gettingClientToken(true));
      if (!(globalThis && globalThis.serving)) {
        // only run if its on the client
        try {
          const res = await svcApi.getClientToken();
          dispatch(setClientToken(res));
          callback?.();
        } catch (err) {
          dispatch(gettingClientToken(false));
          console.error("failed to get client token", err);
        }
      }
    }
  };
}

export async function getBTToken() {
  if (!(globalThis && globalThis.serving)) {
    // only run if its on the client
    try {
      const res = await svcApi.getClientToken();
      return res;
    } catch (err) {
      console.error("failed to get client token", err);
    }
  }
}

export function addPaymentJoin(paymentObj, joinObj, joinType = "rejoin") {
  return async (dispatch, getState) => {
    try {
      const res = await svcApi.addPaymentMethod(paymentObj);
      dispatch(getPaymentMethod());
      joinObj.token = res;
      dispatch(
        join({
          type: joinType,
          plan: joinObj.plan,
          address: joinObj.address,
          promoCode: joinObj.promoCode,
        }),
      );
    } catch (err) {
      dispatch(setError(err.message, "braintree"));
      dispatch(setJoinStatusFail());
    }
  };
}
