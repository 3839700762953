// Utility functions for determing price to use based on CountryCode
import { LoyaltyTier } from "../../../types/account";
import { IBoxProduct } from "../../../types/box";
import { SwagProduct } from "../../../types/product";
import { IPlan } from "../../../types/plan";
import { IPolicy } from "../../../types/policy";

const US_PRICE_PER_BOOK_WITH_ANNUAL_PLAN = "13.99";
const CA_PRICE_PER_BOOK_WITH_ANNUAL_PLAN = "24.17";

export const countryProductPrice = (
  product: IBoxProduct | SwagProduct,
  countryCode: string,
): number | undefined => {
  return countryCode === "CA" ? product?.cadPrice : product?.price;
};

export const countryPlanPrice = (
  plan?: IPlan,
  countryCode?: string,
): number | undefined => {
  return countryCode === "CA" ? plan?.cadPrice : plan?.price;
};

export const countryFuturePlanPrice = (
  plan: IPlan,
  countryCode: string,
): number | undefined => {
  return countryCode === CountryCode.CA
    ? plan?.cadFutureRenewalPlanPrice
    : plan?.futureRenewalPlanPrice;
};

export const getCountryAnnualPricePerBook = (countryCode: string) => {
  return countryCode === CountryCode.CA
    ? CA_PRICE_PER_BOOK_WITH_ANNUAL_PLAN
    : US_PRICE_PER_BOOK_WITH_ANNUAL_PLAN;
};

export const countryPolicyAddonPrice = (
  policy: IPolicy,
  countryCode: string,
  loyaltyTier: LoyaltyTier,
): number | undefined => {
  const useDiscountAddOnPrice =
    loyaltyTier === "BFF" ||
    loyaltyTier === "Friend" ||
    loyaltyTier === "Soulmate";
  if (countryCode === "CA") {
    // discountAddOnPrice shouldn't be null but just in case it is, use the addOnPrice for fallback value
    return useDiscountAddOnPrice
      ? policy?.caDiscountAddOnPrice || policy.cadAddOnPrice
      : policy.cadAddOnPrice;
  } else {
    // discountAddOnPrice shouldn't be null but just in case it is, use the addOnPrice for fallback value
    return useDiscountAddOnPrice
      ? policy?.discountAddOnPrice || policy?.addOnPrice
      : policy?.addOnPrice;
  }
};

export const countryProductBffPrice = (
  product: IBoxProduct | SwagProduct,
  countryCode: string,
): number | undefined => {
  if (!product?.cadBffPrice || !product?.bffPrice) {
    return;
  }
  return countryCode === "CA" ? product?.cadBffPrice : product?.bffPrice;
};

export const getCountryCode = (
  showProjectNorth: boolean,
  countryCode: string,
  countryHeader: string,
) => {
  let result = "US";
  if (showProjectNorth) {
    result = countryCode ? countryCode : countryHeader;
  }

  return result;
};

export const CountryCode = {
  USA: "US",
  CA: "CA",
};

export const priceStringToFloat = (price: string) => {
  if (typeof price !== "string") return price;
  return parseFloat(price.replace(/[^0-9-.]/g, ""));
};
