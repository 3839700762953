import React, { useEffect, useState } from "react";
import FormCheckbox from "../../../ui/elements/form/FormCheckbox";
import NegativeOptionCheckbox from "./NegativeOptionCheckbox";
import TermsAgreementBlock from "../TermsAgreementBlock";
import { useWindowSize } from "../../hooks/useWindowSize";
import { ApplePayButtonTypes } from "./ApplePayButton";
import { FormEvent } from "react";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { connect } from "react-redux";
import { IPlan } from "../../../../../types/plan";
import styled from "styled-components";
import { Error } from "../../../ui/elements/form/Error";
import ApplePayBlock, { ApplePayBlockProps } from "./ApplePayBlock";

type primaryBtnProps = {
  cta: string;
  buttonId: string;
  btnIsPending: boolean;
  btnIsDisabled: boolean;
  isReallyDisabled?: boolean; //applies disabled attribute to the <button/> tag and ignores checkboxCB
  btnAction: (evt?: FormEvent<HTMLButtonElement>) => void;
  logClickData?: any;
};

type secondaryBtnProps = {
  buttonLinkId?: string;
  buttonLinkCta?: string;
  btnLinkTo?: string;
  btnLinkHandleClick?: (evt?: FormEvent<HTMLButtonElement>) => void;
};

type applePayBtnProps = {
  isForApplePay?: boolean;
  applePayCta?: ApplePayButtonTypes;
} & Pick<
  ApplePayBlockProps,
  | "nonSupportedButtonCta"
  | "buttonStyle"
  | "paymentRequestObj"
  | "paymentForFn"
  | "showingFallBackCB"
>;

type PurchaseConsentBlockProps = {
  idAmend: string;
  errorCB?: (error: string) => void; // callback to set error message of a parent component
  customPlan?: IPlan;
  marginTop?: number; // if not set, fallback isMobile ? 20 : 40
  marginBottom?: number;
} & primaryBtnProps &
  secondaryBtnProps &
  applePayBtnProps &
  MappedStateProps;

const PurchaseConsentBlock: React.FC<PurchaseConsentBlockProps> = ({
  //primaryBtnProps
  idAmend,
  errorCB,
  customPlan,
  marginTop,
  marginBottom = 0,
  cta,
  buttonId = "btn-primary",
  btnIsPending,
  btnIsDisabled,
  isReallyDisabled,
  btnAction,
  logClickData,
  //secondaryBtnProps
  buttonLinkId = "btn-link",
  buttonLinkCta,
  btnLinkTo = "",
  btnLinkHandleClick,
  //ApplePayProps
  isForApplePay = false,
  applePayCta = "Subscribe",
  buttonStyle,
  paymentRequestObj,
  paymentForFn,
  nonSupportedButtonCta,
  showingFallBackCB,
  //MappedStateProps
  showNegativeOptionRuleFinePrint,
  joinData,
}) => {
  const [negativeOptionCheckboxStatus, setNegativeOptionCheckboxStatus] =
    useState<"checked" | "unchecked" | "error">("unchecked");
  const [hasError, setHasError] = useState<boolean>();
  const [checkboxError, setCheckboxError] = useState<string>("");
  const canSubmit = negativeOptionCheckboxStatus === "checked";
  const isMobile = useWindowSize()?.[0] < 680;
  const isNORdisabled = showNegativeOptionRuleFinePrint && !canSubmit;

  useEffect(() => {
    setNegativeOptionCheckboxStatus("unchecked");
  }, [joinData]);

  const onFormCheckboxCB = (status: "checked" | "unchecked" | "error") => {
    setNegativeOptionCheckboxStatus(status);
    setHasError(false);
    errorCB ? errorCB("") : setCheckboxError("");
  };

  const setErrorMessage = () => {
    setHasError(true);
    const CHECKBOX_ERROR_MSG = `Please agree to the terms below to confirm your purchase.`;
    errorCB
      ? errorCB(CHECKBOX_ERROR_MSG)
      : setCheckboxError(CHECKBOX_ERROR_MSG);
    setNegativeOptionCheckboxStatus("error");
  };

  const errorBlock = checkboxError ? (
    <Error message={checkboxError} bottom={isMobile ? 20 : 40} />
  ) : null;

  const checkboxBlock = showNegativeOptionRuleFinePrint ? (
    <div>
      {errorBlock}
      <FormCheckbox
        id={"negativeOptionRuleCheckbox"}
        top={0}
        label={
          <NegativeOptionCheckbox
            style="termsAgreementBlock"
            isMobile={isMobile}
            id="negativeOptionRuleCheckbox"
          />
        }
        error={Boolean(hasError)}
        checkboxCB={onFormCheckboxCB}
        isDisabled={isReallyDisabled}
        checkboxStatus={negativeOptionCheckboxStatus}
      />
    </div>
  ) : null;

  const handleClick = () => {
    if (showNegativeOptionRuleFinePrint) {
      if (!canSubmit) {
        setErrorMessage();
      } else if (canSubmit && !btnIsDisabled) {
        btnAction();
      }
    } else {
      btnAction();
    }
  };

  const setBtnClickData = () => {
    let data;
    if (typeof logClickData === "object") {
      data = {
        eventName: `Clicked - ${buttonId}${
          isNORdisabled ? "-NOR-disabled" : ""
        }`,
        ...logClickData,
      };
    } else if (typeof logClickData === "string") {
      data = logClickData;
    } else {
      data = `Clicked - ${buttonId}${isNORdisabled ? "-NOR-disabled" : ""}`;
    }
    return data;
  };

  const setButton = () => {
    if (isForApplePay) {
      return (
        <ApplePayBlock
          buttonStyle={buttonStyle}
          cta={applePayCta}
          paymentRequestObj={paymentRequestObj}
          paymentForFn={paymentForFn}
          nonSupportedButtonCta={nonSupportedButtonCta}
          showingFallBackCB={showingFallBackCB}
          norCheckboxAction={isNORdisabled ? setErrorMessage : undefined}
          btnIsDisabled={btnIsDisabled}
        />
      );
    } else {
      return (
        <ClickTracker
          buttonType={"submit"}
          ctaType={"button"}
          id={buttonId}
          style={`primary fullWidth link-p1 ${
            btnIsDisabled || isNORdisabled ? "-disabled" : ""
          }`}
          handleClick={handleClick as () => void}
          title={cta}
          logClickData={setBtnClickData()}
          isPending={btnIsPending}
          isDisabled={
            isReallyDisabled
              ? true
              : showNegativeOptionRuleFinePrint
              ? false
              : btnIsDisabled
          }
          size={isMobile ? 16 : 18}
        />
      );
    }
  };

  const secondaryBtnLink = buttonLinkCta ? (
    <ClickTracker
      ctaType={"link"}
      id={buttonLinkId}
      style={"secondary link"}
      linkTo={btnLinkTo}
      handleClick={btnLinkHandleClick}
      title={buttonLinkCta}
      logClickData={`Clicked - ${buttonLinkId}`}
    />
  ) : null;

  return (
    <StyledPurchaseConsentBlock
      isMobile={isMobile}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {checkboxBlock}
      <TermsAgreementBlock
        cta={cta}
        copy={showNegativeOptionRuleFinePrint ? "the checkbox above" : cta}
        isCentered={false}
        top={
          showNegativeOptionRuleFinePrint
            ? 12
            : marginTop || marginTop == 0
            ? marginTop
            : isMobile
            ? 20
            : 40
        }
        bottom={40}
        mustShowTerms={true}
        showAutoRenewFinePrint={true}
        idAmend={`purcahse-consent-block-${idAmend}`}
        showNegativeOptionRuleFinePrint={showNegativeOptionRuleFinePrint}
        showApplePayFinePrint={isForApplePay}
        customPlan={customPlan}
      />
      <StyledActionBlock>
        {setButton()}
        {secondaryBtnLink}
      </StyledActionBlock>
    </StyledPurchaseConsentBlock>
  );
};

type StyledPurchaseConsentBlockProps = {
  isMobile?: boolean;
  marginTop?: number;
  marginBottom?: number;
};
const StyledPurchaseConsentBlock = styled.div.attrs({
  className: "sc-PurchaseConsentBlock",
})<StyledPurchaseConsentBlockProps>`
  ${({ isMobile, marginTop, marginBottom }) =>
    `margin-top: ${
      marginTop || marginTop == 0 ? marginTop : isMobile ? 20 : 40
    }px;
    margin-bottom: ${marginBottom}px;
    }
  `}
`;
const StyledActionBlock = styled.div.attrs({
  className: "sc-ActionBlock",
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

type MappedStateProps = {
  showNegativeOptionRuleFinePrint?: boolean;
  joinData?: any;
};
const mapStateToProps = (state: any) => {
  const showNegativeOptionRuleFinePrint = state?.account?.countryCode == "US";
  return {
    showNegativeOptionRuleFinePrint: showNegativeOptionRuleFinePrint,
    joinData: state?.joinData,
  };
};
export default connect(mapStateToProps, null)(PurchaseConsentBlock);
