import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import Authenticate from "../../../ui/skeleton/Authenticate";
import { useWindowSize } from "../../../ui/hooks";
import { MEDIAQUERIES } from "../../../css/Consts";
import ClickTracker from "../../../ui/elements/ClickTracker";
import * as backend from "../../../state/account/api";
import { updateAccount } from "../../../state/account/flows";
import { connect } from "react-redux";
import { Dispatch } from "../../../types";
import { bindActionCreators } from "redux";
import {
  AccountWithLoyaltyAndPayment,
  IAccount,
} from "../../../../../types/account";
import { Variant } from "../../../ui/skeleton/Experiments.jsx";
import { IAppFeature } from "../../../../../types/app_feature";
import { useRegion } from "../../../ui/hooks/useRegion";
import showAppFeature from "../../../utils/showAppFeature";

type SaveOfferProps = {
  updateAccount: (account: AccountWithLoyaltyAndPayment) => {};
  exp160: number;
} & SaveOfferMappedProps;

const SaveOffer: React.FC<SaveOfferProps> = ({
  account,
  updateAccount,
  exp160,
  appFeatures,
}) => {
  const { push } = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width <= 680;
  const { countryCode } = useRegion();

  const claimOffer = async () => {
    const creditCommitSaveOfferFeature = appFeatures?.find(
      (f) => f?.feature == "Credit Commit Save Offer",
    );
    const isEligibleForSaveOffer =
      account?.mustRenewSaveOfferStatus == "Eligible";
    const showSaveOfferExp160 = exp160 == 1;
    const showCreditCommitSaveOfferFeature =
      showSaveOfferExp160 &&
      isEligibleForSaveOffer &&
      showAppFeature(
        creditCommitSaveOfferFeature,
        account?.countryCode || countryCode,
      );

    if (showCreditCommitSaveOfferFeature) {
      setIsSubmitting(true);
      try {
        const account = await backend.claimMustRenewSaveOffer();
        updateAccount(account);
        setIsSubmitting(false);
        push("/cancel/saved-confirmation");
      } catch (e) {
        console.error("Cannot claim credit commit offer -> ", e);
      }
    }
  };

  return (
    <StyledSaveOffer>
      <Authenticate>
        <StyledFlexWrapper
          id={`SaveOffer`}
          paddingTop={isMobile ? 40 : 80}
          paddingLeft={isMobile ? 20 : 0}
          paddingRight={isMobile ? 20 : 0}
          paddingBottom={isMobile ? 80 : 120}
          justifyContent="center"
          alignItems="center"
        >
          <div className="iconContainer">
            <img
              src={`//static.bookofthemonth.com/elements/tag-icon.svg`}
              className="icon"
            />
          </div>
          <h4 className="header">Stay for only $9.99!</h4>
          <div className="copy">
            Get your next credit for $9.99 if you choose to stay.
          </div>
          <ClickTracker
            ctaType={"button"}
            id="claim-offer"
            style={`primary ${isMobile ? "-fullWidth" : ""}`}
            handleClick={() => claimOffer()}
            isPending={isSubmitting}
            title={"Claim offer"}
            logClickData={"claim_credit_commit_offer"}
            top={40}
            minWidth={isMobile ? 0 : 360}
          />
          <ClickTracker
            ctaType={"link"}
            id="refuse-credit-commit-offer"
            title={"No thanks"}
            logClickData={"refuse_credit_commit_offer"}
            linkTo={"/cancel"}
            top={20}
          />
        </StyledFlexWrapper>
      </Authenticate>
    </StyledSaveOffer>
  );
};

export const StyledSaveOffer = styled.div`
  .iconContainer {
    width: 72px;
    height: 72px;
  }

  .icon {
    width: 100%;
  }

  .header,
  .copy {
    padding-top: 20px;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    .header,
    .copy {
      padding-top: 12px;
    }
  }
`;

type SaveOfferMappedProps = {
  appFeatures?: IAppFeature[];
  account?: IAccount;
};

const mapStateToProps = ({ account, appFeatures }: SaveOfferMappedProps) => {
  return {
    account: account,
    appFeatures: appFeatures || [],
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ updateAccount }, dispatch);
}

const SaveOfferExp160 = (props: any) => {
  return (
    <Variant experimentId={160}>
      {({ variant }: { variant: number }) => (
        <SaveOffer {...props} exp160={variant} />
      )}
    </Variant>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveOfferExp160);
