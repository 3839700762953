import React from "react";
import { COLORS } from "../../css/Consts";

interface IInstagramIcon {
  height?: number;
  width?: number;
  color?: string;
}

const InstagramIcon: React.FC<IInstagramIcon> = ({
  height = 27,
  width = 26,
  color = COLORS.black,
}) => (
  <svg
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 26 27"
    className="icon -instagram"
    focusable="true"
    aria-labelledby="instagram"
  >
    <title id="instagram">Instagram</title>
    <path
      fill={color}
      d="M17.429,13.286c0-2.36-1.925-4.286-4.286-4.286c-2.36,0-4.285,1.925-4.285,4.286
      c0,2.361,1.925,4.286,4.285,4.286C15.504,17.571,17.429,15.646,17.429,13.286z M19.738,13.286c0,3.65-2.945,6.596-6.596,6.596
      c-3.649,0-6.596-2.946-6.596-6.596c0-3.649,2.946-6.596,6.596-6.596C16.793,6.689,19.738,9.636,19.738,13.286z M21.547,6.422
      c0,0.854-0.687,1.54-1.54,1.54c-0.854,0-1.54-0.687-1.54-1.54c0-0.854,0.686-1.54,1.54-1.54C20.86,4.882,21.547,5.568,21.547,6.422z
      M13.143,2.739c-1.875,0-5.893-0.151-7.583,0.519C4.973,3.492,4.538,3.777,4.086,4.229C3.634,4.681,3.35,5.116,3.115,5.702
      c-0.67,1.69-0.52,5.708-0.52,7.583c0,1.875-0.15,5.893,0.52,7.583c0.234,0.586,0.519,1.021,0.971,1.474s0.887,0.736,1.473,0.971
      c1.691,0.67,5.708,0.519,7.583,0.519s5.893,0.151,7.584-0.519c0.586-0.234,1.021-0.519,1.473-0.971
      c0.453-0.452,0.737-0.888,0.972-1.474c0.669-1.69,0.519-5.709,0.519-7.583c0-1.875,0.15-5.893-0.519-7.583
      c-0.234-0.586-0.519-1.021-0.972-1.474c-0.451-0.452-0.887-0.736-1.473-0.971C19.035,2.588,15.018,2.739,13.143,2.739z M26,13.286
      c0,1.775,0.017,3.533-0.084,5.307c-0.1,2.06-0.568,3.884-2.076,5.391c-1.506,1.507-3.331,1.976-5.391,2.075
      c-1.773,0.102-3.531,0.084-5.307,0.084c-1.774,0-3.532,0.018-5.307-0.084c-2.059-0.1-3.884-0.568-5.391-2.075
      s-1.976-3.331-2.076-5.391c-0.1-1.774-0.083-3.532-0.083-5.307c0-1.774-0.017-3.532,0.083-5.307C0.47,5.919,0.938,4.095,2.445,2.588
      c1.507-1.506,3.332-1.975,5.391-2.076c1.774-0.101,3.532-0.083,5.307-0.083c1.775,0,3.533-0.017,5.307,0.083
      c2.06,0.101,3.885,0.569,5.391,2.076c1.508,1.507,1.977,3.332,2.076,5.391C26.017,9.753,26,11.511,26,13.286z"
    />
  </svg>
);

export default InstagramIcon;
