import globalScope from "../../global"; // For tests, ugh
import { PopulationType } from "../../../../types/survey";
import { jsonReviver } from "../../utils/queryparser";

export async function all() {
  const response = await fetch(`${global.apiHost}/api/v0/survey?questions`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function list() {
  const response = await fetch(`${global.apiHost}/api/v0/survey`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getSurveyByName(
  surveyName: string,
  population?: PopulationType[],
  previewDate?: string,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/getSurveyByName/${surveyName}${
      population ? `?population=${population.join(",")}` : ``
    }${previewDate ? `?previewDate=${previewDate}` : ``}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getByType(surveyType: string, trackId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/${surveyType.replace(
      " ",
      "",
    )}?trackId=${trackId}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function setSurveyResponse(r: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/${r.surveyId}`,
    {
      method: "post",
      body: JSON.stringify(r), // JSON.stringify need for body to send
      credentials: "include", // need for httpOnly cookies to get set
      headers: { "Content-Type": "application/json" }, // needed body to send
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function updateSurveyResponse(r: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/${r.surveyId}`,
    {
      method: "put",
      body: JSON.stringify(r), // JSON.stringify need for body to send
      credentials: "include", // need for httpOnly cookies to get set
      headers: { "Content-Type": "application/json" }, // needed body to send
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getAccountSurveyResponse(surveyId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/${surveyId}/account`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const vote = await response.json();
  return await vote;
}

export async function getInfluencerSurveyIndex() {
  // for now, the surveyId is limited to === 1
  const SURVEY_ID = 1;
  const response = await fetch(
    `${global.apiHost}/api/v0/survey/${SURVEY_ID}/searchIndex/pesInfluencers`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await JSON.parse(await response.text(), jsonReviver);
}
