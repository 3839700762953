import React, { useEffect, useState } from "react";
import { connect, useStore } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { BORDER } from "../../../css/Consts.js";
import styled from "styled-components";
import dateformat from "dateformat";
import ClickTracker from "../../elements/ClickTracker";
import { Error } from "../../elements/form/Error";
import { skipRenewal } from "../../../state/account/flows";
import LoadingCoinSkeleton from "../../loadingSkeletons/LoadingCoinSkeleton.jsx";
import { cancelFlowTracking } from "../../../utils/analytics";
import makeDate from "../../../utils/makeDate";
import PauseSuccess from "./PauseSuccess";
import { useStoreData } from "../../hooks/useStoreData";
import { AppState, Dispatch } from "../../../types";
import { getIsNewMemberHold } from "../../../utils/getIsNewMemberHold";
import { phoneFlexForcePhone } from "../../../state/store/selectors.js";
import Authenticate from "../../../ui/skeleton/Authenticate.jsx";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import { useWindowSize } from "../../../ui/hooks/useWindowSize";
import { Variant } from "../../../ui/skeleton/Experiments.jsx";
import { logClick } from "../../../state/analytics/flows.js";
import { snesRedirect } from "../../../utils/snesRedirect";

type PauselandingProps = {
  skipRenewal: (
    adjustment?: number,
    pause?: boolean,
    successFn?: () => void,
    failFn?: () => void,
  ) => void;
  exp134: number;
} & MappedPauseLandingProps;

type PauseLandingParams = {
  view?: string;
};

const PauseLanding: React.FC<PauselandingProps> = ({
  skipRenewal,
  account,
  address,
  forcePhone,
  isNewMemberHold,
  exp134,
  ...props
}) => {
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const { push, replace } = useHistory();
  const { getCycleRelative, store: storeData } = useStoreData();
  const params = useParams<PauseLandingParams>();
  const [pausedMonths, setPauseMonths] = useState<number>(0);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [renewalDate, setRenewalDate] = useState<string | null>(null);
  const [showPausedConf, setShowPausedConf] = useState<boolean>(false);
  const hasCreditLeft = Boolean(account?.credits && account?.credits > 0);
  const isForKeepMembership = params?.view == "thanks";
  const isForCancelFlow = params?.view == "cancel";
  const isCanadianResident = account?.countryCode === "CA";
  const showNORPauseCancelExp134 = exp134 == 1;
  const NORExpId = showNORPauseCancelExp134 ? "-NORPauseCancelExp134" : "";
  const store = useStore();

  useEffect(() => {
    if (isForCancelFlow && showNORPauseCancelExp134) {
      replace(`/cancel`);
    }
  }, [isForCancelFlow, showNORPauseCancelExp134]);

  const confirmedRenewalDate = account
    ? dateformat(account?.renewalDate, "UTC:mmmm dd, yyyy")
    : "";

  let pauseDetails;
  let hed;
  let dek;
  let icon;

  const setPauseDetails = () => {
    if (pausedMonths) {
      if (hasCreditLeft) {
        return `You won’t be renewed until ${renewalDate} (or when you run out of credits, whichever comes first).`;
      } else {
        return `We won’t renew you for another credit until ${renewalDate}.`;
      }
    }
  };

  if (isForKeepMembership) {
    hed = `Thanks for sticking around!`;
    dek = `We are so happy to have you as a member.`;
    icon = `//static.bookofthemonth.com/elements/raf-high-five.svg`;
  } else {
    hed = `Let’s take a break.`;
    dek = hasCreditLeft
      ? `Keep your credits but pause your subscription. `
      : `Take a break from being renewed.`;
    icon = `//static.bookofthemonth.com/svgs/Pause_blue.svg`;
    pauseDetails = setPauseDetails();
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    const bodyWrapper = document.getElementById("bodyWrapper");
    if (bodyWrapper) {
      bodyWrapper.scrollTop = 0;
    }
  };

  const setCurrentRenewalDate = () => {
    const relativeCycleToGet = account?.cycles
      ? account?.cycles -
        (storeData?.pickingPeriodOpen &&
        !account?.cycleActions?.hasCycleResponse
          ? 1
          : 0)
      : 0;
    const expirationCycle = getCycleRelative?.(relativeCycleToGet);
    const renewalDate = makeDate(expirationCycle?.startDate);
    if (renewalDate && account?.tranche) {
      renewalDate.setDate(renewalDate.getDate() + account?.tranche);
    }
    const currentRenewalDate = makeDate(renewalDate);
    return currentRenewalDate;
  };

  const setNewRenewalDate = (adjustment: number) => {
    const currentRenewalDate = setCurrentRenewalDate();
    const nextRenewalDate = currentRenewalDate
      ? new Date(
          currentRenewalDate.setMonth(
            currentRenewalDate.getMonth() + adjustment,
          ),
        )
      : null;
    const formattedDate = nextRenewalDate
      ? dateformat(nextRenewalDate, "mmmm dd, yyyy")
      : null;
    return formattedDate;
  };

  const chooseOption = (adjustment: number) => {
    let newRenewalDate = null;
    logClick(`Clicked pause option ${adjustment} month${NORExpId}`, store);
    if (adjustment > 0) {
      newRenewalDate = setNewRenewalDate(adjustment);
    }
    setError(null);
    setPauseMonths(adjustment);
    setRenewalDate(newRenewalDate);
  };

  useEffect(() => {
    if (pausedMonths) {
      chooseOption(pausedMonths);
    }
  }, []);

  useEffect(() => {
    const accountPolicySubtype = account?.policy?.subType || "";
    const mustRenew = account?.policy?.mustRenew;
    const isPauseInelegible =
      account?.policy?.type === "Rejoin" &&
      ["Rejoin A", "Rejoin C", "Rejoin F", "Gift"].includes(
        accountPolicySubtype,
      );
    const isPausedMemeber =
      account?.policy?.type === "Rejoin" &&
      account?.policy?.subType === "Pause";
    const isCaliforniaResident = address?.state === "CA";

    if (isPauseInelegible) {
      replace(`/credit-rejoin/step-join`);
    }
    if (
      (isNewMemberHold || forcePhone) &&
      address.state &&
      !isCaliforniaResident &&
      account?.countryCode == "CA"
    ) {
      replace("/cancel-membership/call");
    } else if ((isPausedMemeber || mustRenew) && !formSubmitting) {
      replace("/my-account/membership");
    }
  }, [account]);

  if (!account) {
    return <LoadingCoinSkeleton />;
  }

  const successFn = () => {
    // setFormSubmitting(false);
    push(`/pause-membership/success`);
    setShowPausedConf(true);
    scrollToTop();
  };

  const failFn = () => {
    setError("Opps, something went wrong");
    setFormSubmitting(false);
    setPauseMonths(0);
    scrollToTop();
  };

  const skip = () => {
    setFormSubmitting(true);
    cancelFlowTracking("Cancel Flow", "Click Skip Renewal");
    skipRenewal(pausedMonths, !hasCreditLeft, successFn, failFn);
  };

  if (showPausedConf) {
    return (
      <PauseSuccess
        hasCreditLeft={hasCreditLeft}
        pausedMonths={pausedMonths}
        confirmedRenewalDate={confirmedRenewalDate}
        account={account}
      />
    );
  }

  const pauseButtonSet = (
    <>
      <ClickTracker
        ctaType={"button"}
        id={`pause-submit${NORExpId}`}
        style={"primary fullWidth"}
        handleClick={() => skip()}
        isPending={formSubmitting}
        title={"Pause membership"}
        isDisabled={!pausedMonths}
        logClickData={`Cancel - pause submit${NORExpId}`}
      />
      <ClickTracker
        ctaType={"button"}
        id={`pause-keep${NORExpId}`}
        style={"secondary fullWidth"}
        handleClick={() => replace("/pause-membership/thanks")}
        isPending={false}
        title={"I want to keep my membership"}
        isDisabled={false}
        logClickData={`Cancel - pause keep membership${NORExpId}`}
      />
    </>
  );

  const setButtons = () => {
    if (isForKeepMembership) {
      return (
        <ClickTracker
          ctaType={"button"}
          id={`browse-books${NORExpId}`}
          style={`primary`}
          customStyles={{ width: "100%", margin: "auto" }}
          handleClick={() => snesRedirect("snes", "/the-best-new-books")}
          title={"Browse the books"}
          logClickData={`Cancel - pause browse books${NORExpId}`}
          maxWidth={isMobile ? undefined : 228}
        />
      );
    } else if (isForCancelFlow) {
      return (
        <>
          {pauseButtonSet}
          <ClickTracker
            ctaType={"link"}
            id={`pause-cancel${NORExpId}`}
            linkTo={"/cancel-membership"}
            title={"No thanks, I want to cancel"}
            isDisabled={formSubmitting}
            logClickData={`Cancel - pause no thanks - cancel${NORExpId}`}
          />
        </>
      );
    } else {
      return pauseButtonSet;
    }
  };

  return (
    <Authenticate>
      <StyledFlexWrapper
        paddingLeft={isMobile ? 20 : 0}
        paddingRight={isMobile ? 20 : 0}
        paddingTop={isMobile ? 40 : 56}
        paddingBottom={isMobile ? 80 : 120}
      >
        <StyledFlexWrapper maxWidth={80}>
          <img src={icon} alt="Pause membership" />
        </StyledFlexWrapper>
        <StyledFlexWrapper
          resetSpacing
          gap={isMobile ? 12 : 20}
          maxWidth={500}
          marginTop={isMobile ? 12 : 20}
          textAlign="center"
        >
          <h4>{hed}</h4>
          <p>{dek}</p>
        </StyledFlexWrapper>

        {(!isForKeepMembership || error) && (
          <StyledFlexWrapper
            gap={20}
            maxWidth={480}
            marginTop={40}
            textAlign="center"
          >
            {error && <Error message={error} bottom={0} />}
            {!isForKeepMembership && (
              <>
                <p>I want to pause for:</p>
                <StyledChoiceBlockWrapper>
                  <StyledChoiceBlock
                    onClick={() => chooseOption(1)}
                    className={pausedMonths == 1 ? "active" : ""}
                  >
                    <h5> 1 month</h5>
                  </StyledChoiceBlock>
                  <StyledChoiceBlock
                    onClick={() => chooseOption(2)}
                    className={pausedMonths == 2 ? "active" : ""}
                  >
                    <h5> 2 months</h5>
                  </StyledChoiceBlock>
                  <StyledChoiceBlock
                    onClick={() => chooseOption(3)}
                    className={pausedMonths == 3 ? "active" : ""}
                  >
                    <h5> 3 months</h5>
                  </StyledChoiceBlock>
                </StyledChoiceBlockWrapper>
                {pauseDetails && (
                  <div className={"active exp67-two-step miniText"}>
                    {pauseDetails}
                  </div>
                )}
              </>
            )}
          </StyledFlexWrapper>
        )}

        <StyledFlexWrapper
          gap={isMobile ? 12 : 20}
          marginTop={40}
          maxWidth={isMobile ? undefined : 334}
          textAlign="center"
        >
          {setButtons()}
        </StyledFlexWrapper>
      </StyledFlexWrapper>
    </Authenticate>
  );
};

export const StyledChoiceBlockWrapper = styled.div.attrs({
  className: "sc-StyledChoiceBlockWrapper" as string,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
`;

export const StyledChoiceBlock = styled.a.attrs({
  className: "sc-StyledChoiceBlock" as string,
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 16px;
  border: ${BORDER.defaultBorder};
  border-radius: ${BORDER.defaultBorderRadius};
  box-shadow: ${BORDER.defaultBoxShadow};
  transition:
    border-color 0.2s ease-in-out,
    background 0.2s ease-in-out;
  h5 {
    color: var(--color-gray5);
    transition: color 0.2s ease-in-out;
  }
  &.active,
  :active,
  :hover:not(.button) {
    border-color: var(--color-primary5);
    background: var(--color-primary1);
    h5 {
      color: var(--color-primary5);
    }
  }
  @media screen and (max-width: 680px) {
    flex-direction: column;
    padding: 24px 16px;
  }
`;

export const StyledButtonWrapper = styled.div.attrs({
  className: "sc-StyledButtonWrapper" as string,
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 40px auto;
  @media screen and (max-width: 680px) {
    width: 100%;
  }
`;

export const StyledPauseDetailsWrapper = styled.p.attrs({
  className: "sc-StyledPauseDetailsWrapper" as string,
})`
  max-width: 480px;
  margin: 20px auto;
`;

export const StyledIconWrapper = styled.div.attrs({
  className: "sc-StyledIconWrapper" as string,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  margin: 20px auto;
  max-width: 640px;
`;

const PauseLandingExp = (props: any) => (
  <Variant experimentId={134}>
    {({ variant }: { variant: number }) => (
      <PauseLanding {...props} exp134={variant} />
    )}
  </Variant>
);

type MappedPauseLandingProps = {
  forcePhone: boolean;
  isNewMemberHold: boolean;
} & Pick<AppState, "account" | "address">;

const mapStateToProps = (state: AppState): MappedPauseLandingProps => {
  return {
    account: state.account,
    address: state.address || {},
    forcePhone: phoneFlexForcePhone(state),
    isNewMemberHold: getIsNewMemberHold(state?.account),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      skipRenewal,
      push,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PauseLandingExp);
