/**
 * Shared componet to close modals.
 */

//TODO: Replace all other instances of the modal close icon with this.

import React from "react";

const ModalClose = ({
  action,
  color = "var(--color-black)",
  size = 16,
  aboutUs = false,
}) => {
  return (
    <div className={aboutUs ? "aboutUsClose" : "modalClose"} onClick={action}>
      <svg
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 16.414 16.414"
        enableBackground="new 0 0 16.414 16.414"
      >
        <g id="x_icon" transform="translate(-339.308 -16.308)">
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            x1="355.015"
            y1="17.015"
            x2="340.015"
            y2="32.015"
          />
          <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            x1="340.015"
            y1="17.015"
            x2="355.015"
            y2="32.015"
          />
        </g>
      </svg>
    </div>
  );
};

export default ModalClose;
