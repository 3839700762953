import * as React from "react";
import styled from "styled-components";

interface IBulletList {
  top?: number;
  bottom?: number;
  fontSize?: number;
  bulletSize?: number;
  bullets?: Array<string>;
  indent?: number;
}

export const BulletList: React.FC<IBulletList> = ({
  top,
  bottom,
  bullets,
  fontSize,
  bulletSize,
  indent,
}) => {
  return (
    <StyledBulletListWrapper indent={indent}>
      <StyledBulletList
        id={"StyledBulletList"}
        top={top}
        bottom={bottom}
        fontSize={fontSize}
        bulletSize={bulletSize}
        bullets={bullets}
      >
        {bullets?.map((item: string, index: number) => (
          <li key={index}>
            <span>{item}</span>
          </li>
        ))}
      </StyledBulletList>
    </StyledBulletListWrapper>
  );
};

export const StyledBulletList = styled.ul<IBulletList>`
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  margin-left: 20px;
  list-style-type: disc;
  font-size: ${(props) =>
    props.bulletSize ? `${props.bulletSize / 10}rem` : "1.8rem"};
  span {
    font-size: ${(props) =>
      props.fontSize ? `${props.fontSize / 10}rem` : "1.8rem"};
    line-height: ${(props) =>
      props.fontSize ? `${(props.fontSize / 10) * 1.5}rem` : "2.2rem"};
  }
  li {
    text-align: left;
  }
  li:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const StyledBulletListWrapper = styled.div<IBulletList>`
  margin-left: ${(props) => (props.indent ? `${props.indent}px` : 0)};
`;
