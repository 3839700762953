import * as actions from "./actions";
const initialState = {
  css: "bom",
  images: "//static.bookofthemonth.com/",
  js: "bom",
}; //defaults that help our new loading
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_TRACK:
      return action.payload;
    default:
      return state;
  }
}
