import React, { useState, useEffect, useRef, useReducer } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import btClient from "braintree-web/client";
import btHostedFields from "braintree-web/hosted-fields";
import {
  getClientToken,
  getPaymentMethod,
  addPaymentMethod,
} from "State/memberPaymentMethod";
import { setError } from "State/error/creators";
import { Button } from "UI/elements/form/Button";
import { Error } from "UI/elements/form/Error";
import { COLORS } from "CSS/Consts.js";
import styled from "styled-components";
import { scrollToError } from "UI/components/forms/Form.jsx";
import SecurePaymentBlock from "UI/components/forms/SecurePaymentBlock";
import PurchaseConsentBlock from "./forms/PurchaseConsentBlock";

const braintreeErrorReducer = (state, action) => {
  console.error("braintreeErrorReducer", action.code);
  switch (action.code) {
    case "HOSTED_FIELDS_FIELDS_EMPTY":
    case "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED":
    case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
    default:
      return "Oops! Please double-check your credit card info and try again.";
    // occurs when the Braintree gateway cannot be contacted
    case null:
    case "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME":
      return null;
  }
};

const Braintree = ({ id = "", ...props }) => {
  const cardNumber = useRef(null);
  const cvv = useRef(null);
  const expirationDate = useRef(null);
  const postalCode = useRef(null);
  const [account, setAccount] = useState(props.account);
  const [gettingPaymentMethod, setGettingPaymentMethod] = useState(false);
  const [error, setError] = useState(null);
  const [hostedFieldsObj, setHostedFieldsObj] = useState(null);
  const [clientInstance, setClientInstance] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const isCanadaAddress = props.address?.countryCode === "CA";
  const [braintreeError, dispatchBraintreeError] = useReducer(
    braintreeErrorReducer,
    null,
  );

  useEffect(() => {
    if (props.account !== account) {
      if (
        ["Member", "Rejoin"].includes(account?.policy?.type) &&
        !account?.paymentMethod &&
        !props?.account?.paymentMethod &&
        !gettingPaymentMethod &&
        typeof props.paymentMethodAttempt === "undefined"
      ) {
        setGettingPaymentMethod(true);
      }
      setAccount(props.account);
    }
    if (props.braintreeError && props.braintreeError !== braintreeError) {
      dispatchBraintreeError({ code: props.braintreeError });
      scrollToError();
    }
  }, [props, account, braintreeError, gettingPaymentMethod]);

  /** BRAINTREE SETUP */
  // get client token if not yet retrieved
  useEffect(() => {
    if (!props.token && !props.gettingToken) {
      props.getClientToken();
    }
  }, [props]);

  useEffect(() => {
    setSubmitting(props.submitting);
  }, [props.submitting]);

  useEffect(() => {
    if (submitting) {
      submitPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  useEffect(() => {
    setSubmitting(false);
  }, [braintreeError]);

  // create braintree client using the client token
  useEffect(() => {
    if (
      cardNumber.current &&
      cvv.current &&
      expirationDate.current &&
      postalCode.current &&
      props.token
    ) {
      btClient
        .create({ authorization: props.token })
        .then(setClientInstance)
        .catch(setError);
    }
  }, [cardNumber, cvv, expirationDate, postalCode, props.token]);

  // instantiate the hosted fields
  useEffect(() => {
    if (clientInstance) {
      btHostedFields
        .create({
          client: clientInstance,
          styles: {
            input: { "font-size": "14pt" },
            "input:focus": { color: "#333" },
            "input.invalid": {
              color: "tomato",
              background: "rgba(255, 0, 0, 0.11)",
            },
            "input.valid": { color: "limegreen" },
          },
          fields: {
            number: {
              container: `#${cardNumber.current.id}`,
              prefill: global.xavier_env === "local" && "4111111111111111",
              supportedCardBrands: {
                "american-express": isCanadaAddress ? false : true,
                discover: isCanadaAddress ? false : true,
              },
            },
            cvv: {
              container: `#${cvv.current.id}`,
              prefill: global.xavier_env === "local" && "111",
            },
            expirationDate: {
              container: `#${expirationDate.current.id}`,
              prefill: global.xavier_env === "local" && "02/35",
            },
            postalCode: {
              container: `#${postalCode.current.id}`,
              prefill: global.xavier_env === "local" && "10001",
            },
          },
          function(err, hostedFieldsInstance) {
            function findLabel(field) {
              return '.hosted-field--label[for="' + field.container.id + '"]';
            }
            hostedFieldsInstance.on("focus", function (event) {
              var field = event.fields[event.emittedBy];
              findLabel(field).addClass("label-float").removeClass("filled");
            });
          },
        })
        .then(setHostedFieldsObj)
        .catch((error) => {
          dispatchBraintreeError(error);
          scrollToError();
        });
      return () => {
        // cleanup braintree
        clientInstance.teardown();
        setClientInstance(null);
      };
    }
  }, [clientInstance]);

  useEffect(() => {
    const callback = (event) => {
      const hasInvalidFields = Object.values(event.fields).some(
        (v) => !v.isValid,
      );
      setFormIsValid(!hasInvalidFields);
      if (props.callbackOnFormValidated) {
        props.callbackOnFormValidated(hasInvalidFields);
      }
    };
    if (hostedFieldsObj) {
      hostedFieldsObj.on("validityChange", callback);
    }

    return () => {
      if (hostedFieldsObj) {
        hostedFieldsObj.off("validityChange", callback);
      }
    };
  }, [hostedFieldsObj]);

  // tokenize card info then submit
  const submitPayment = () => {
    if (!props.submitting) {
      props?.toggleFormSubmitting?.();
    }
    if (hostedFieldsObj?.tokenize) {
      hostedFieldsObj
        .tokenize()
        .then(async (paymentObj) => {
          if (props.useSubmitButton) {
            props.addPaymentMethod(
              // successFunction
              { nonce: paymentObj.nonce },
              () => {
                props?.submitFunction?.(paymentObj);
                dispatchBraintreeError({ code: null });
                if (braintreeError) {
                  props.setError(null, null);
                }
              },
              (error) => {
                // failFunction
                setSubmitting(false);
                setGettingPaymentMethod(true);
                setError(error);
                scrollToError();
              },
            );
          } else {
            props?.submitFunction?.(paymentObj);
            setSubmitting(false);
            // props ?.toggleFormSubmitting ?.();
          }
        })
        .catch((error) => {
          dispatchBraintreeError(error);
          props?.toggleFormSubmitting?.();
          props?.failFunction?.(error);
          scrollToError();
        });
    }
  };

  /** END BRAINTREE SETUP */
  const setSubmitButtonBlock = () => {
    if (!props?.useSubmitButton) return null;

    let content;
    if (props?.useSubmitButton) {
      if (props.showPurchaseConsentBlock) {
        content = (
          <PurchaseConsentBlock
            marginTop={20}
            cta={
              submitting && !braintreeError
                ? "Updating..."
                : props?.submitButtonText
                ? props.submitButtonText
                : "Submit"
            }
            buttonId={`btn-${id}-braintree-submit`}
            btnIsPending={submitting && !braintreeError}
            btnIsDisabled={!formIsValid || submitting}
            btnAction={(evt) => {
              evt?.preventDefault();
              setSubmitting(true);
              dispatchBraintreeError({ code: null });
            }}
          />
        );
      } else {
        content = (
          <Button
            style="primary fullWidth"
            handleClick={(evt) => {
              evt.preventDefault();
              setSubmitting(true);
              dispatchBraintreeError({ code: null });
            }}
            isPending={submitting && !braintreeError}
            title={
              submitting && !braintreeError
                ? "Updating..."
                : props?.submitButtonText
                ? props.submitButtonText
                : "Submit"
            }
            disabled={!formIsValid || submitting}
          />
        );
      }
      return content;
    }
  };

  return (
    <StyledFormWrapper top={props.top}>
      {braintreeError && <Error message={braintreeError} top={0} bottom={30} />}
      <SecurePaymentBlock
        top={0}
        bottom={25}
        cta={props.hedCta}
        ctaAction={props.hedCtaAction}
      />
      <fieldset>
        <StyledInputWrapper>
          <div ref={cardNumber} id="card-number" className="hostedFieldInput" />
          <StyledLabel htmlFor={"card-number"}>Credit card no.</StyledLabel>
          {isCanadaAddress && (
            <StyledCardIconsWrapper>
              <img src="//static.bookofthemonth.com/elements/Mastercard-logo.svg" />
              <img src="//static.bookofthemonth.com/elements/Visa_Inc._logo.svg" />
            </StyledCardIconsWrapper>
          )}
          <StyledErrorWrapper>
            {!isCanadaAddress
              ? "Please enter a valid Mastercard or Visa credit card number."
              : "Please enter a valid credit card number."}
          </StyledErrorWrapper>
        </StyledInputWrapper>

        <StyledSmallFieldsWrapper>
          <StyledInputWrapper>
            <div ref={cvv} id="cvv" className="hostedFieldInput" />
            <StyledLabel htmlFor={"cvv"}>CVV</StyledLabel>
            <StyledErrorWrapper>Please enter a valid CVV.</StyledErrorWrapper>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div
              ref={expirationDate}
              id="expiration-date"
              className="hostedFieldInput"
            />
            <StyledLabel htmlFor={"expiration-date"}>MM/YY</StyledLabel>
            <StyledErrorWrapper>
              Please enter a valid expiration date.
            </StyledErrorWrapper>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div
              ref={postalCode}
              id="postalCode"
              className="hostedFieldInput"
            />
            <StyledLabel htmlFor={"postalCode"}>Zip</StyledLabel>
            <StyledErrorWrapper>
              Please enter a valid zipcode.
            </StyledErrorWrapper>
          </StyledInputWrapper>
        </StyledSmallFieldsWrapper>

        {account?.paymentMethod && props.showReplaceDefaultMessage && (
          <p className={"miniText"} style={{ textAlign: "center" }}>
            This card will replace your current default payment method.
          </p>
        )}
        {setSubmitButtonBlock()}
      </fieldset>
    </StyledFormWrapper>
  );
};

export const StyledFormWrapper = styled.div.attrs({
  className: "sc-StyledFormWrapper",
})`
  margin-top: ${(props) => (isNaN(props.top) ? 20 : props.top)}px;
`;

export const StyledErrorWrapper = styled.p.attrs({
  className: "sc-StyledErrorWrapper",
})`
  display: none;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${COLORS.error6};
  margin-top: 6px;
`;

export const StyledSmallFieldsWrapper = styled.div.attrs({
  className: "sc-StyledSmallFieldsWrapper",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .sc-StyledInputWrapper {
    width: 32%;
  }
`;

export const StyledInputWrapper = styled.div.attrs({
  className: "sc-StyledInputWrapper",
})`
  position: relative;
  margin-bottom: 20px;
  div {
    height: 48px;
  }
  input {
    border: 10px solid "var(--color-gray2)";
  }

  .braintree-hosted-fields-focused + label {
    top: -8px !important;
    font-size: 12px;
  }

  .hostedFieldInput:not(:empty) {
    padding: 3px 18px;
    & + label {
      top: -8px !important;
      font-size: 12px;
    }
  }

  .hostedFieldInput.bt-error {
    background: rgba(255, 0, 0, 0.11);
  }

  .hostedFieldInput iframe {
    height: 48px !important;
    margin-top: -3px;
  }

  .braintree-hosted-fields-invalid {
    border-color: #ef2929;
    & + label {
      color: ${COLORS.error6};
    }
  }

  .braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    & ~ p {
      display: block !important;
    }
  }
  .bt-paypal-button {
    border-radius: 25px;
    margin: 20px 0;
    background: #009cde;
    #braintree-paypal-button {
      margin: 0 auto;
    }
  }
`;

export const StyledCardIconsWrapper = styled.div.attrs({
  className: "sc-StyledCardIconsWrapper",
})`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 10px;
  img {
    width: 24px;
    margin: 0 3px;
  }
`;

export const StyledLabel = styled.label.attrs({ className: "sc-StyledLabel" })`
  font-size: 18px;
  color: ${COLORS.black};
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${COLORS.white};
  padding-left: 6px;
  padding-right: 6px;
  top: 13px;
  margin-left: 6px;
  transition: all 250ms ease-out;
  border-radius: 3px;
`;

function mapStateToProps(state) {
  return {
    token: state.clientToken.token,
    gettingToken: state.clientToken.loading,
    account: { ...state.account, ...state.address },
    // ccFailurePolicyId: state.store?.data?.ccFailurePolicy?.id, // this is not being used
    braintreeError:
      state?.error?.type === "braintree" ? state.error.error : null,
    address: state.address || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientToken,
      getPaymentMethod,
      addPaymentMethod,
      setError,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Braintree);
