import React from "react";
import styled from "styled-components";
import {
  _ORIG_BBF_LOGIN_DATA,
  _TIER_LOGIN_DATA,
} from "../join_steps/TierRejoinData";

const LoginTierRejoinHeader = ({
  tierEntryPoint,
  showLoyaltyTierFeature,
}: {
  tierEntryPoint: string;
  showLoyaltyTierFeature: boolean;
}) => {
  const setHeader = () => {
    const origData = showLoyaltyTierFeature
      ? _TIER_LOGIN_DATA
      : _ORIG_BBF_LOGIN_DATA;
    const dataPath = tierEntryPoint?.split("/")[1];
    const image = origData[dataPath as keyof typeof origData].image;
    const hed = origData[dataPath as keyof typeof origData].hed;
    const dek = origData[dataPath as keyof typeof origData].dek;

    return (
      <RejoinLoginHeaderWrapper>
        {image && <img src={image} alt="Book of the Month Badge" />}
        {hed && <h2>{hed}</h2>}
        {dek && <p>{dek}</p>}
      </RejoinLoginHeaderWrapper>
    );
  };

  return <>{setHeader()}</>;
};

const RejoinLoginHeaderWrapper = styled.div.attrs({
  className: "sc-RejoinLoginHeaderWrapper" as string,
})`
  text-align: center;
  padding: 80px 0 10px;
  P {
    margin: 12px auto 0;
    max-width: 680px;
  }
  @media screen and (max-width: 640px) {
    padding: 40px 20px 10px;
  }
`;

export default LoginTierRejoinHeader;
