import * as actions from "./actions";

export function setBox(box) {
  return {
    type: actions.SET_BOX,
    payload: box,
  };
}

export function setCreditNavStatus(status) {
  return {
    type: actions.SET_CREDIT_NAV_STATUS,
    payload: status,
  };
}
