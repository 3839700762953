import * as actions from "./actions";

export default function reducer(
  state = { token: null, loading: false },
  action,
) {
  switch (action.type) {
    case actions.SET_CLIENT_TOKEN:
      return { token: action.payload, loading: false };
    case actions.GETTING_CLIENT_TOKEN:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
