import * as actions from "./actions";

export function setPromoBanners(promoBanners) {
  return {
    type: actions.SET_PROMO_BANNERS,
    payload: promoBanners,
  };
}

//bluebox: delete this when the campaign is over
export function setBlueBox(status) {
  return {
    type: actions.SET_BLUEBOX,
    payload: status,
  };
}

export function setBFFRejoin(entryPoint) {
  return {
    type: actions.SET_BFF_REJOIN,
    payload: entryPoint,
  };
}

//MD22: delete this when the campaign is over
export function setMD22(status) {
  return {
    type: actions.SET_MD22,
    payload: status,
  };
}
