import React from "react";
import styled, { css, keyframes } from "styled-components";

import {
  COLORS,
  BORDER,
  FONTS,
  MEDIAQUERIES,
  HEADERHEIGHT,
} from "CSS/Consts.js";

const SkeletonWrapper = ({ tagType = "div", children, className }) => (
  <StyledSkeletonWrapper
    as={tagType}
    className={`-skeleton ${className || ""}`}
  >
    {children}
  </StyledSkeletonWrapper>
);

export const IMG = ({ className, width, height }) => {
  let style = {};
  if (width || height) {
    style = { height, width };
  }
  return <div className={`-img ${className || ""}`} style={style} />;
};

export const Jacket = ({ children, className }) => {
  return <div className={`-jacket ${className || ""}`}>{children}</div>;
};

export const P = ({ className }) => {
  return <div className={`-p ${className || ""}`} />;
};

export const Button = ({ className }) => {
  return <div className={`-button ${className || ""}`} />;
};

export const RadioButton = ({ className }) => {
  return <div className={`-radioButton ${className || ""}`} />;
};

export const Word = ({ className }) => {
  return <div className={`-word ${className || ""}`} />;
};

export const Input = ({ className }) => {
  return <div className={`-input ${className || ""}`} />;
};

export const H1 = ({ className }) => {
  return <div className={`-h1 ${className || ""}`} />;
};

export const H2 = ({ className }) => {
  return <div className={`-h2 ${className || ""}`} />;
};

export const H3 = ({ className }) => {
  return <div className={`-h3 ${className || ""}`} />;
};

export const H4 = ({ className }) => {
  return <div className={`-h4 ${className || ""}`} />;
};

export const H5 = ({ className }) => {
  return <div className={`-h5 ${className || ""}`} />;
};

export const H6 = ({ className }) => {
  return <div className={`-h6 ${className || ""}`} />;
};

export const InvisibleBlock = ({ className, width, height }) => {
  let style = {};
  if (width && height) {
    style = { height, width };
  }
  return <div className={`${className || ""}`} style={style} />;
};

const PulseBackground = keyframes`
  0% { background-color: lighten(${COLORS.gray2}, 6%); }
  50% { background-color: ${COLORS.gray2}; }
  100% { background-color: lighten(${COLORS.gray2}, 6%); }
`;

const PulsingBackground = css`
  background-color: ${COLORS.gray2};
  animation: ${PulseBackground} 3.5s ease infinite;
`;

const PulseBorder = keyframes`
  0% { border-color: lighten(${COLORS.gray2}, 6%); }
  50% { border-color: ${COLORS.gray2}; }
  100% { border-color: lighten(${COLORS.gray2}, 6%); }
`;

const PulsingBorder = css`
  border-color: ${COLORS.gray2};
  animation: ${PulseBorder} 3.5s ease infinite;
`;

const text = css`
  width: 100%;
  height: 16px;
  margin-bottom: 12px;
  border-radius: 3px;
  ${PulsingBackground};
`;

const h1 = css`
  height: 107px;
`;

const h2 = css`
  height: 60px;
`;

const h3 = css`
  height: 42px;
`;

const h4 = css`
  height: 32px;
`;

const h5 = css`
  height: 28px;
`;

const h6 = css`
  height: 16px;
`;

export const StyledSkeletonWrapper = styled.div`
  .-rounded { border-radius: 6px; }
  .-center { 
    margin-left: auto;
    margin-right: auto;
  }
  .-p { 
    ${text};
    &.-h1 { ${h1}; }
    &.-h2 { ${h2}; }
    &.-h3 { ${h3}; }
    &.-h4 { ${h4}; }
    &.-h5 { ${h5}; }
    &.-h6 { ${h6}; }
  }
  
  .-word {
    ${text};
    max-width: 180px;
    min-width: 100px;
    &.-h1 { ${h1}; }
    &.-h2 { ${h2}; }
    &.-h3 { ${h3}; }
    &.-h4 { ${h4}; }
    &.-h5 { ${h5}; }
    &.-h6 { ${h6}; }
  }
  .-smallFont { height: 16px; }
  .-bigFont { height: 48px; }
  .-h4 { height: 32px; }
  .-img { ${PulsingBackground}; }
  .-img.-round {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  // form
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  .-input { 
    ${text};
    height: 48px
  }
  .-radioButton {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    padding: 3px;
    border: ${BORDER.defaultBorder};
    border: 1px solid ${COLORS.gray2};
    border-radius: 50%;
  }
  .-button {
    border-radius: 3px;
    ${PulsingBackground};
    display: inline-block;
    width: 140px;
    height: 48px;
    &.-fullWidth {
      width: 100%;
    }
  }
  div .-button + .-button {
    margin-left: 20px;
  }
  
  .-input + .-button.fullWidth{
    margin-top: 40px;
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$

  &.-searchResults {
    .-bookListItem {
      width: 140px;
      height: 180px;
      padding: 0 10px 10px;
      text-align: center;
      cursor: pointer;
      .-jacket {
        ${PulsingBackground};
        width: 90%;
        height: 100%;
      }
    }
    .-p { width: 140px; }
  }

  .-staggered {
    li:nth-of-type(2n+1) .-p, li:nth-of-type(2n+1) .-word, > .-p:nth-of-type(2n+1), > .-word:nth-of-type(2n+1) {
      width: 85%;
    }
    li:nth-of-type(3n+1) .-p, li:nth-of-type(3n+1) .-word, > .-p:nth-of-type(3n+1), > .-word:nth-of-type(3n+1) {
      width: 75%;
    }
  }

  // sitewide header
  header {
    background-color: ${COLORS.white};
    border-bottom: ${BORDER.defaultBorder};
    position: fixed;
    width: 100%;
    z-index: 1003;
    height: ${HEADERHEIGHT.headerHeightMain};
    .logo {
      text-align: left;
      display: flex;
      align-items: center;
      height: 16px;
      margin-top: 20px;
      width: 100%;
      width: 210px;
      ${PulsingBackground};
    }
  }

  // sitewide footer
  footer {
    width: 100%;
    position: relative;
    z-index: 10;
    background-color: #EDEFF2;
    height: 375px;
  }

  .-headerWrapper .-p { max-width: 400px; }


  &.visitor .-lifestyleImage {
    background: var(--color-white);
    background: linear-gradient(to bottom, var(--color-white) 0%,var(--color-white) 50%,#C9CCD1 50%,#C9CCD1 100%);
    &.-noBackground {
      background: transparent;
    }
  }

  .headerBanner {
    background: #C9CCD1;
  }

  // mybotm
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  .pdShortBlock, &.-placeholder {
    // this applies only to SkeletonWrapper
    .-jacket {
      background: #fafafa;
      background: linear-gradient(135deg, #fafafa 27%,#ededed 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ededed',GradientType=1 );
      text-align: center;
      padding: 30px 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .-img { 
        width: 200px; 
        height: 300px;
      }
    }
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$


  // gift
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  &.gift {
    .giftHeader {
      .-h1 { width: 50%; }
      .-p, .-h2, .-h6 { width: 75%; }

      .-h1.-center {
        width: 45%; 
      }
      .-h1.-center + .-h1.-center {
        width: 35%; 
      }

      .-img.-heroImage {
        width: 100%;
        height: auto;
        margin: 0 auto;
        z-index: 2;
        display: block;
        height: 660px;
        max-height: 100%;
      }
      .-p {
        max-width: 400px;
      }
    }

    .selectPlan {
      background: #C9CCD1;
      background-color: #C9CCD1;
    }
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$

  // blog
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  .magazine &.landing {
    .postsListItem .-img {
      max-width: 100%;
      width: 190px;
      height: 127px;
      margin-bottom: 10px;
      vertical-align: middle;
    }
    .postDescription {
      vertical-align: top;
      text-align: left;
    }
    .hero .postsListItem .-img {
      display: inline-block;
      max-width: 65%;
      width: 598px;
      height: 361px;
    }
    .featured {
      .-p { width: 190px; }
      .-word { max-width: 100px }
    }
    .-p, .-word { border-radius: 3px; }
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$

  // extras
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  .extrasLanding & {
    .sliderItem .-jacket .-img {
      width: 147px;
      height: 221px;
    }
  }

  &.extrasGenres {
    .-jacket .-img {
      width: 136px;
      height: 208px;
    }
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  

  // boty
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  &.bookofyear {
    .-p {
      max-width: 400px;
    }

    .-bookListItem {
      width: 155px;
      height: 205px;
      padding: 0 10px 10px;
      text-align: center;
      cursor: pointer;
      .-jacket {
        ${PulsingBackground};
        width: 90%;
        height: 100%;
      }
    }
    
    .-winnerCopy, .-theLolly {
      background: #C9CCD1;
      background-color: #C9CCD1;
    }

  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$

  &.-jacketList, .-jacketList {
    .-jacket .-img {
      width: 54px;
      height: 82px;
    }
  }

  // pdpage
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  &.pdPage {
    .categoryList {
      .li:first-of-type { margin-left: 0; }
    }
    // related to SkeletonWrapper
    .informers {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          justify-content: left;
          margin: 0 0 20px 0;
          width: 50%;

          &:last-of-type { margin-right: 0; }
          .-word { 
            margin-left: 20px; 
            width: 120px;
            margin-top: auto;
            margin-bottom: auto;
            display: inline-block;
            margin-left: 16px;
          }
          .-img {
            width: 36px;
            height: 36px;
            display: inline-block;
          }
        }
      }
    }
  }
  // #$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$
  
  &.myBox {
    .-jacket .-img {
      width: 119px;
      height: 182px;
    }
    .miniText .-word { width: 160px;}
  }
  &.emailGate {
    .-p {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.-loggingIn {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  &.loyalty {
    .-stats li {
      border: 2px solid ${COLORS.gray2};
      min-height: 140px;
      ${PulsingBackground};
      ${PulsingBorder};
    }
    .indicator .punchCard{
      ${PulsingBackground};
      ${PulsingBorder};
      .boxes{
        min-height: 180px;
        ${PulsingBackground};
        ${PulsingBorder};
      }
      .statusBar{
        ${PulsingBackground};
        ${PulsingBorder};
      }
    }
  }
}

@media screen and (max-width: ${MEDIAQUERIES.mobile}) {
  &.emailGate{
    .-word{
      width: 295px;
    }
  }

  .pdShortBlock .-jacket .-img, &.-placeholder .-jacket .-img{ 
    width: 140px; 
    height: 210px; 
  }
}

@media screen and (max-width: ${MEDIAQUERIES.tablet}) {
  header {
    height: ${HEADERHEIGHT.mobile}; 
    .logoWrapper {
      flex-grow: 2;
      .logo {
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;
        margin: 10px auto 0;
      }
    }
  }
}
`;

export default SkeletonWrapper;
