/**
 * Login.jsx
 * This is a wrapper for the LoginForm
 */
import React, { useEffect, useRef, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { setLoginFlow } from "State/loginFlow/creators";
import { setPlanOpenRedirect } from "State/joinData/creators";
import { clearError } from "State/error/creators";
import LoginForm from "UI/components/forms/LoginForm.jsx";
import Meta from "UI/skeleton/Meta.jsx";
import Logo from "UI/elements/Logo.jsx";
import LoginTierRejoinHeader from "./LoginTierRejoinHeader";
import { useStoreData } from "UI/hooks/useStoreData";
import { RegionContext } from "UI/contexts/RegionContext";
import { snesRedirect } from "Utils/snesRedirect";

const Login = (props) => {
  const { canadaMode } = useContext(RegionContext);
  const { loginFlow, account, tierEntryPoint, appFeatures, trueNorthFeature } =
    props;
  const { store } = useStoreData() || {};
  const { push } = useHistory();
  const { pathname = "", search = "" } = useLocation();
  const { from } = useParams();
  const originalPathname = useRef(pathname);
  const isRejoin = pathname.includes("credit-rejoin/holiday-rewards");
  const loyaltyTierFeature = appFeatures?.find(
    (f) => f?.feature == "Loyalty Tier",
  );
  const showLoyaltyTierFeature =
    (loyaltyTierFeature?.status == "Beta" &&
      loyaltyTierFeature?.isBetaEligible) ||
    loyaltyTierFeature?.status == "Active";
  const showTrueNorthFeature =
    (trueNorthFeature?.status == "Beta" && trueNorthFeature?.isBetaEligible) ||
    trueNorthFeature?.status == "Active";
  const shouldShowJoinLink =
    !canadaMode || (canadaMode && showTrueNorthFeature);

  useEffect(() => {
    if (from) {
      if (from === "plan") {
        props.setPlanOpenRedirect();
      } else if (from === "bookshelf") {
        props.setLoginFlow("/bookshelf");
      } else if (from === "turtles-all-the-way-down-295") {
        props.setLoginFlow("/add-ons/turtles-all-the-way-down-295");
      } else if (from === "/into-the-water-offer") {
        props.setLoginFlow("/into-the-water-229");
      } else if (from === "redeem") {
        props.setLoginFlow("/gift/redeem");
      } else {
        props.setLoginFlow(from);
      }
    }
  }, [from]);

  useEffect(() => {
    if (account) {
      const { pickingPeriodOpen } = store || {};
      let nextPath = from || originalPathname.current || loginFlow;
      nextPath = nextPath
        ? nextPath
        : pickingPeriodOpen
        ? "/my-box"
        : "/bookshelf";
      if (nextPath === "/my-box") {
        snesRedirect("snes", nextPath);
      } else {
        push(`${nextPath}${search}`);
      }
    }
    return () => props.clearError();
  }, [account]);

  const setHeader = () => {
    if (!tierEntryPoint) {
      return (
        <div className="pageHeader">
          <h1 className="center">Hey, you.</h1>
        </div>
      );
    } else {
      return (
        <LoginTierRejoinHeader
          tierEntryPoint={tierEntryPoint}
          showLoyaltyTierFeature={showLoyaltyTierFeature}
        />
      );
    }
  };

  return (
    <>
      {isRejoin && (
        <Link className="masthead -withBorder -bannerless" to="/">
          <Logo />
        </Link>
      )}
      <div className="content -narrow login">
        <Meta
          title="Log in to your account | Book of the Month"
          description="Log in to your Book of the Month account to choose your book and ship your box. Not a member? Join today and get your choice from this month’s picks."
          canonicalUrl="https://www.bookofthemonth.com/login"
        />
        {setHeader()}
        <LoginForm />
        {shouldShowJoinLink && (
          <div style={{ textAlign: "center", marginBottom: 40 }}>
            <p>
              New around here?{" "}
              <a
                onClick={() => snesRedirect("snes", "/the-best-new-books")}
                tabIndex="0"
              >
                Join now.
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const trueNorthFeature = state?.appFeatures?.find(
    (f) => f?.feature == "True North",
  );
  return {
    account: state.account,
    loginFlow: state.loginFlow,
    appFeatures: state.appFeatures,
    trueNorthFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setPlanOpenRedirect, setLoginFlow, clearError, push },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
