export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ACCOUNT_PAYMENT_METHOD = "SET_ACCOUNT_PAYMENT_METHOD";
export const SET_ACCOUNT_RENEWAL_PLAN = "SET_ACCOUNT_RENEWAL_PLAN";
export const SET_ACCOUNT_RENEWAL_DATE = "SET_ACCOUNT_RENEWAL_DATE";
export const SET_ACCOUNT_POLICY_EFFECTIVE_DATE =
  "SET_ACCOUNT_POLICY_EFFECTIVE_DATE";
export const SET_ACCOUNT_REFERRALS = "SET_ACCOUNT_REFERRALS";
export const SET_ACCOUNT_ANCHOR_RESPONSE = "SET_ACCOUNT_ANCHOR_RESPONSE";
export const SET_CHANGE_PASSWORD_STATUS = "SET_CHANGE_PASSWORD_STATUS";
export const CLEAR_CHANGE_PASSWORD_STATUS = "CLEAR_CHANGE_PASSWORD_STATUS";
export const SET_VISITOR_ID = "SET_VISITOR_ID";
export const SET_EXPERIMENT_VARIANT = "SET_EXPERIMENT_VARIANT";
export const SET_ACCOUNT_CREDIT_HISTORY = "SET_ACCOUNT_CREDIT_HISTORY";
export const SET_LOYALTY_STATS = "SET_LOYALTY_STATS";
export const UPDATE_EMAIL_PREF_SUCCESS = "UPDATE_EMAIL_PREF_SUCCESS";
export const UPDATE_EMAIL_PREF_FAILURE = "UPDATE_EMAIL_PREF_FAILURE";
export const UPDATE_EMAIL_PREF_ERROR = "UPDATE_EMAIL_PREF_ERROR";
