import globalScope from "../../global"; // For tests, ugh

export async function getMessagesByMember() {
  const response = await fetch(`${global.apiHost}/api/v0/account_message`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const message = await response.json();
  return await message;
}

export async function markMessageAsSeen(messageId: number) {
  const response = await fetch(`${global.apiHost}/api/v0/account_message`, {
    method: "put",
    body: JSON.stringify({ messageId }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function updateSeenCycleId(messageId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_message/updateSeenCycleId`,
    {
      method: "put",
      body: JSON.stringify({ messageId }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function createMessage(messageId: number, active: boolean) {
  console.warn("Deprecated: createMessage api use!"); // TODO Delete this when no longer used!
  const response = await fetch(`${global.apiHost}/api/v0/account_message`, {
    method: "post",
    body: JSON.stringify({ messageId, active }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}
