import React, { useState, useReducer, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setOverlay } from "State/ui/creators";
import { experimentFlowTracking } from "Utils/analytics";
import { logVisit, logClick } from "State/analytics/flows";
import ClickTracker from "UI/elements/ClickTracker";
import { CountryCode } from "Utils/countryPricing";

const AddressValidationModal = ({
  overlay: {
    data: { recommendedAddresses, oAddress, cb, skipValidation },
  },
  removeOverlay,
  cancelSubmit,
  ...props
}) => {
  const formattedAddresses = recommendedAddresses?.map((recoAddress, i) => ({
    street1: recoAddress.street,
    city: recoAddress.city,
    state: recoAddress.state,
    countryCode: recoAddress.country,
    // zip: recoAddress.zip.replace(/-.*$/, ''),
    zip: recoAddress.zip,
  }));
  const [selectedAddress, setAddress] = useState(formattedAddresses[0]);
  const [submitting, toggleSubmitting] = useReducer((state) => !state, false);

  useEffect(() => {
    experimentFlowTracking("Address Validation", "Validation modal fired");
    logVisit({
      store: {
        getState: () => ({
          ...props.store,
          trackClick: `Address Validation - Validation modal fired`,
        }),
      },
      // we only validate US addresses
      countryCode: CountryCode.USA,
    });
  }, []);

  const submitAction = async (evt) => {
    selectedAddress.zip = selectedAddress.zip.replace(/-.*$/, "");
    // evt.preventDefault();
    toggleSubmitting();
    try {
      let originalChosen = isActive(oAddress);
      if (!originalChosen) {
        experimentFlowTracking(
          "Address Validation",
          "User picks the recommended address",
        );
        logClick("Address Validation - User picks the recommended address", {
          getState: () => props.store,
        });
      }
      await cb(selectedAddress, removeOverlay, originalChosen);
    } catch (e) {
      console.error("TCL:: AddressValidationModal -> e", e);
    }
  };

  const isActive = (address) => {
    for (let key in address) {
      if (key !== "street2" && address?.[key] !== selectedAddress?.[key]) {
        return false;
      }
    }
    return true;
  };

  const shouldBold = (testAddress, field) =>
    testAddress[field].trim().toLowerCase() !==
    oAddress[field].trim().toLowerCase()
      ? " -bold"
      : "";

  return (
    <div className="addressValidationModal">
      <h4>Hm, is this right?</h4>
      <p className="smallText">
        Your address doesn’t match up with USPS, so we recommended one that
        does. Let us know which one to use!
      </p>
      {formattedAddresses.map((formattedAddress, i) => (
        <div
          key={i}
          className={
            "cardBlock" + (isActive(formattedAddress) ? "  active" : "")
          }
          onClick={() => setAddress(formattedAddress)}
        >
          <p className="label2">Recommended</p>
          <p className={`smallText ${shouldBold(formattedAddress, "street1")}`}>
            {formattedAddress?.street1}
          </p>
          <p className="smallText">
            <span
              className={`smallText ${shouldBold(formattedAddress, "city")}`}
            >
              {formattedAddress?.city}
            </span>
            ,{" "}
            <span
              className={`smallText ${shouldBold(formattedAddress, "state")}`}
            >
              {formattedAddress?.state}
            </span>{" "}
            <span
              className={`smallText ${shouldBold(formattedAddress, "zip")}`}
            >
              {formattedAddress?.zip}
            </span>
          </p>
        </div>
      ))}
      <div
        className={"cardBlock" + (isActive(oAddress) ? "  active" : "")}
        onClick={() => setAddress(oAddress)}
      >
        <p className="label2">You entered</p>
        <p className="smallText">
          {oAddress?.street1} {oAddress?.street2}
        </p>{" "}
        <p className="smallText">
          {oAddress?.city}, {oAddress?.state} {oAddress?.zip}
        </p>
        <ClickTracker
          ctaType={"link"}
          id="address-validation-edit-link"
          handleClick={() => {
            experimentFlowTracking(
              "Address Validation",
              "User edits their address",
            );
            skipValidation();
            cancelSubmit();
            removeOverlay();
          }}
          title={"Edit"}
          logClickData={
            "Clicked - Address Validation - User edits their address"
          }
          size={14}
        />
      </div>
      <div className="-buttonwrapper">
        <ClickTracker
          ctaType={"button"}
          id="address-validation-continue"
          style={`primary -fullWidth`}
          handleClick={submitAction}
          isPending={submitting}
          title={"Continue"}
          type={"submit"}
          logClickData={"Clicked- Continue-address validation"}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    store: state,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressValidationModal);
