import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts";

interface IPageSectionHeaderProps {
  top?: number;
  bottom?: number;
  maxWidth?: number;
  withPadding?: boolean;
  centered?: boolean;
}

export const StyledPageSectionHeader = styled.div<IPageSectionHeaderProps>`
  text-align: ${(props) => (props.centered ? "center" : "left")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "800px")};
  margin-left: ${(props) => (props.centered ? "auto" : 0)};
  margin-right: ${(props) => (props.centered ? "auto" : 0)};
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "40px")};
  padding-left: ${(props) => (props.withPadding ? "20px" : 0)};
  padding-right: ${(props) => (props.withPadding ? "20px" : 0)};
  h4 {
    margin: 0 auto 20px;
    + p {
      max-width: 600px;
      margin: ${(props) => (props.centered ? "20px auto 0" : "20px 0 0")};
    }
  }
  h3 {
    margin: 0 auto;
    + p {
      max-width: 600px;
      margin: ${(props) => (props.centered ? "20px auto 0" : "20px 0 0")};
    }
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: ${(props) =>
      props.bottom && props.bottom > 0 ? `${props.bottom - 20}px` : 0};
    h3 + p {
      margin-top: 15px;
    }
    .actionWrapper {
      margin: 20px auto 0;
    }
  }
`;
StyledPageSectionHeader.displayName = "StyledPageSectionHeader";
