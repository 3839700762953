import React from "react";
import { useProductLoader } from "../hooks/useProduct";

const withProduct = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>,
) => {
  const WithProduct = (props: P) => {
    const getProduct = useProductLoader();
    return <Component {...props} getProduct={getProduct} />;
  };
  return WithProduct;
};

export { withProduct };
