import React from "react";
import SkeletonWrapper, { IMG, Word, P } from "./SkeletonWrapper.jsx";

const BlogLandingSkeleton = ({ noHeader }) => (
  <SkeletonWrapper className="landing">
    <div className="pageHeader">{noHeader ? <Word /> : <h1>Our Blog</h1>}</div>

    <section>
      <div className="content -wide">
        <ul className="hero">
          <li className="sliderItem postsListItem">
            <IMG />
            <div className="postDescription">
              <h5>
                <P />
              </h5>
              <Word />
              <P />
              <P />
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div className="content -wide">
        <div className="featured">
          <ul>
            {new Array(4).fill(0).map((item, i) => (
              <li className="sliderItem postsListItem" key={i}>
                <IMG />
                <div className="postDescription -staggered">
                  <h5>
                    <P />
                  </h5>
                  <Word />
                  <P />
                  <P />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  </SkeletonWrapper>
);

export default BlogLandingSkeleton;
