import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import Thunk from "redux-thunk";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createLogger } from "redux-logger";
import { loadableReady } from "@loadable/component";
import reducers from "State/rootReducer";
import { setLocation } from "State/analytics/flows";
import BOMApp from "UI/skeleton/BOM.jsx";
import Providers from "UI/contexts/Providers";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { getPlans } from "./utils/getPlans";

import * as experimentsApi from "./state/experiments/api";
import * as tracksApi from "./state/store/tracksApi";
import * as trancheApi from "./state/tranche/api";
import * as storeApi from "./state/store/api";

const trackId = 1;

const browserHistory = createBrowserHistory({ basename: "/" });
const middleWareToApply = [Thunk, routerMiddleware(browserHistory)];
if (global.xavier_env !== "production") {
  const logger = createLogger(); // only for debugging and testing purposes
  middleWareToApply.push(logger);
}
const store = applyMiddleware(...middleWareToApply)(createStore)(
  reducers(browserHistory),
  window.INITIAL_STATE ? window.INITIAL_STATE : undefined,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

if (global.xavier_env === "local") {
  (async () => {
    const queryparams = window?.location?.search
      ?.replace(/^\?/, "")
      .split("&")
      .map((q) => q.split("="))
      .reduce((o, [k, v]) => (o[k] = v) && o, {});
    const country = queryparams?.["country"] || "US";
    const region = queryparams?.["region"] || "NY";
    const locationHeader = {
      countryHeader: country,
      regionHeader: region,
    };
    const experiments = await experimentsApi.all();
    const storeData = await storeApi.get();
    const track = await tracksApi.get(trackId);
    if (storeData.giftPlans) {
      storeData.giftPlans.plans = getPlans(storeData.giftPlans, trackId);
    }
    if (storeData.giftConvertPlans) {
      storeData.giftConvertPlans.plans = getPlans(
        storeData.giftConvertPlans,
        trackId,
      );
    }
    for (const key in storeData) {
      if (storeData[key]?.planSet) {
        storeData[key].planSet.plans = getPlans(
          storeData[key].planSet,
          trackId,
        );
      }
    }
    storeData["enrollmentPlans"] = storeData.leadPolicy.planSet;
    storeData["tranche"] = await trancheApi.get(storeData.trancheId);
    ReactDOM.render(
      <Providers
        storeData={storeData}
        store={store}
        track={track}
        experiments={experiments}
        locationHeader={locationHeader}
      >
        <ConnectedRouter history={browserHistory}>
          <BOMApp setLocation={(...args) => setLocation(store, ...args)} />
        </ConnectedRouter>
      </Providers>,
      document.getElementById("app"),
    );
  })();
} else {
  loadableReady(() => {
    const experiments = window.EXPERIMENTS || [];
    const variants = window.EXPERIMENT_VARIANTS || {};
    const storeData = window.STORE_DATA;
    const track = window.TRACK_DATA;
    const countryHeader = window.COUNTRY;
    const regionHeader = window.REGION;
    const locationHeader = { countryHeader, regionHeader };
    if (storeData.giftPlans) {
      storeData.giftPlans.plans = getPlans(storeData.giftPlans, trackId);
    }
    if (storeData.giftConvertPlans) {
      storeData.giftConvertPlans.plans = getPlans(
        storeData.giftConvertPlans,
        trackId,
      );
    }
    for (const key in storeData) {
      if (storeData[key]?.planSet) {
        storeData[key].planSet.plans = getPlans(
          storeData[key].planSet,
          trackId,
        );
      }
    }
    const initialData = window.SERVER_DATA;
    datadogLogs.init({
      clientToken: global.datadogKey,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });

    datadogRum.init({
      applicationId: "39d95254-9112-4f92-99bf-52e498150381",
      clientToken: "pub0d7b213f5f98404fa3e70b222c8d1b7f",
      site: "datadoghq.com",
      service: "xavier-webstore",
      env: global.xavier_env === "production" ? "prod" : "staging-1",
      // Need to specify a version number to identify the deployed version of  application in Datadog.
      // How are we handling?
      version: global.xavier_version,
      sampleRate: global.xavier_env === "production" ? 5 : 0,
      trackInteractions: true,
      trackFrustrations: true,
      // sessionReplaySampleRate: global.xavier_env=== 'production' ? 10 : 0,
    });

    datadogRum.startSessionReplayRecording();

    ReactDOM.hydrate(
      <Providers
        initialData={initialData}
        storeData={storeData}
        track={track}
        store={store}
        experiments={experiments}
        variants={variants}
        locationHeader={locationHeader}
      >
        <ConnectedRouter history={browserHistory}>
          <BOMApp setLocation={(...args) => setLocation(store, ...args)} />
        </ConnectedRouter>
      </Providers>,
      document.getElementById("app"),
    );
  });
}
