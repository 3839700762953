import React from "react";
import { COLORS } from "CSS/Consts.js";
import styled from "styled-components";

const FieldClearButton = ({ action }) => {
  return (
    <StyledClearButton onClick={() => action()}>
      <StyledClearButtonCircle>
        <svg
          version="1.1"
          width="6px"
          height="6px"
          viewBox="0 0 241.171 241.171"
        >
          <path
            id="Close"
            fill="var(--color-white)"
            d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0
          l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497
          c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563
          c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
          ></path>
        </svg>
      </StyledClearButtonCircle>
    </StyledClearButton>
  );
};

export const StyledClearButton = styled.div.attrs({
  className: "sc-StyledClearButton",
})`
  position: relative;
  padding: 12px 0 10px 10px;
  cursor: pointer;
`;

export const StyledClearButtonCircle = styled.div.attrs({
  className: "sc-StyledClearButtonCircle",
})`
  display: flex;
  height: 16px;
  width: 16px;
  background-color: ${COLORS.gray4};
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default FieldClearButton;
