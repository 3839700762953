export function scrollAnimate() {
  // CHECK FOR SUPPORT
  if (window.requestAnimationFrame && document.documentElement.classList) {
    // Passes the test so add class to HTML tag
    document.documentElement.classList.add("animations");

    // Throttle https://underscorejs.org/#throttle
    let throttle = function (func, wait, options) {
      let _ = {
        now:
          Date.now ||
          function () {
            return new Date().getTime();
          },
      };
      let context,
        args,
        result,
        timeout = null,
        previous = 0;
      if (!options) {
        options = {};
      }
      let later = function () {
        previous = options.leading === false ? 0 : _.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) {
          context = args = null;
        }
      };
      return function () {
        let now = _.now();
        if (!previous && options.leading === false) {
          previous = now;
        }
        let remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          previous = now;
          result = func.apply(context, args);
          if (!timeout) {
            context = args = null;
          }
        } else if (!timeout && options.trailing !== false) {
          timeout = setTimeout(later, remaining);
        }
        return result;
      };
    };

    // requestAnimationFrame
    let _requestAnimationFrame =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    // Global class for revealing element
    let toAnimate = document.getElementsByClassName("toAnimate");

    // Get the viewport (window) dimensions
    let getViewportSize = function () {
      return {
        width: window.document.documentElement.clientWidth,
        height: window.innerHeight,
      };
    };

    // Get the current scoll position
    let getCurrentScroll = function () {
      return {
        x: window.pageXOffset,
        y: window.pageYOffset,
      };
    };

    // Get element dimensions and position
    let getElemInfo = function (elem) {
      let offsetTop = 0,
        offsetLeft = 0,
        offsetHeight = elem.offsetHeight,
        offsetWidth = elem.offsetWidth;

      do {
        if (!isNaN(elem.offsetTop)) offsetTop += elem.offsetTop;
        if (!isNaN(elem.offsetLeft)) offsetLeft += elem.offsetLeft;
      } while ((elem = elem.offsetParent) !== null);

      return {
        top: offsetTop,
        left: offsetLeft,
        height: offsetHeight,
        width: offsetWidth,
      };
    };

    // Check visibility of the element in the viewport
    let checkVisibility = function (elem) {
      let viewportSize = getViewportSize(),
        currentScroll = getCurrentScroll(),
        elemInfo = getElemInfo(elem),
        spaceOffset = 0.1,
        elemHeight = elemInfo.height,
        elemWidth = elemInfo.width,
        elemTop = elemInfo.top,
        elemLeft = elemInfo.left,
        elemBottom = elemTop + elemHeight,
        elemRight = elemLeft + elemWidth;

      let checkBoundaries = function () {
        // Defining the element boundaries and extra space offset
        let top = elemTop + elemHeight * spaceOffset,
          left = elemLeft + elemWidth * spaceOffset,
          bottom = elemBottom - elemHeight * spaceOffset,
          right = elemRight - elemWidth * spaceOffset,
          // Defining the window boundaries and window offset
          wTop = currentScroll.y + 0,
          wBottom = currentScroll.y - 0 + viewportSize.height;
        // Check if the element is within boundary
        return top < wBottom && bottom > wTop;
      };

      return checkBoundaries();
    };

    // Run a loop with checkVisibility() and add / remove classes to the elements
    let toggleElement = function () {
      for (let i = 0; i < toAnimate.length; i++) {
        if (checkVisibility(toAnimate[i])) {
          toAnimate[i].classList.add("animate");
        }
        // else { //Commented out to stop repeated animation. May add back.
        //   toAnimate[i].classList.remove('animate');
        // }
      }
    };

    // Throttle events and requestAnimationFrame
    let scrollHandler = throttle(function () {
      _requestAnimationFrame(toggleElement);
    }, 300);
    let resizeHandler = throttle(function () {
      _requestAnimationFrame(toggleElement);
    }, 300);

    scrollHandler();

    // Listening for events
    if (window.addEventListener) {
      addEventListener("scroll", scrollHandler, false);
      addEventListener("resize", resizeHandler, false);
    } else if (window.attachEvent) {
      window.attachEvent("onscroll", scrollHandler);
      window.attachEvent("onresize", resizeHandler);
    } else {
      window.onscroll = scrollHandler;
      window.onresize = resizeHandler;
    }
  }

  return;
}
