import { setMessage } from "./creators";

import * as accountMessageApi from "./api";

export const cleanupMessages = (messages) =>
  messages.reduce(
    (messageObj, message) => {
      if (message) {
        const messageList = message.messageList;
        if (messageList?.some?.((m) => m?.active)) {
          messageObj.activeMessages.push({
            id: message.id,
            message: message.message,
          });
        }
        if (messageList?.some?.((m) => !m?.active)) {
          messageObj.inactiveMessages.push({
            id: message.id,
            message: message.message,
          });
        }
      }
      return messageObj;
    },
    {
      activeMessages: [],
      inactiveMessages: [],
    },
  );

export const markMessageAsSeen = (messageId) => async (dispatch, getState) => {
  const account = getState().account;
  if (account) {
    try {
      const messagesByAccount = await accountMessageApi.markMessageAsSeen(
        messageId,
      );
      if (messagesByAccount) {
        dispatch(setMessage(cleanupMessages(messagesByAccount)));
      }
    } catch (e) {
      console.error("Error marking message as seen: ", e);
    }
  }
};

export const createMessage = (messageId, active) => async (dispatch) => {
  try {
    const messagesByAccount = await accountMessageApi.createMessage(
      messageId,
      active,
    );
    if (messagesByAccount) {
      dispatch(setMessage(cleanupMessages(messagesByAccount)));
    }
  } catch (e) {
    console.error("Error marking message as seen: ", e);
  }
};

export const updateSeenCycleId = async (messageId) => {
  try {
    return await accountMessageApi.updateSeenCycleId(messageId);
  } catch (e) {
    console.error("Error updating message seenCycleId: ", e);
  }
};
