/**
 * Modal for Gift Member Renewal Plan.
 *
 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { setOverlay } from "State/ui/creators";
import ClickTracker from "UI/elements/ClickTracker";
import { StyledButtonWrapper } from "UI/elements/form/Button";

const GiftRenewalModal = ({ overlay, setOverlay, showG2Moffer }) => {
  const { push } = useHistory();
  const hed = "Never want this to end?";
  const dek = showG2Moffer
    ? `It doesn’t have to! Choose your renewal plan now and get your first month for $5 when your current membership ends.`
    : `It doesn’t have to! Choose your renewal plan now and get a free book credit when your current membership ends.`;
  const image = "Hero_Pop-up_Gift2Member";

  if (!overlay || (overlay && overlay.name !== "GiftRenewalModal")) {
    return null;
  }

  const closeNGo = () => {
    window.sessionStorage.setItem("GiftRenewalModal", true);
    setOverlay(null);
    push("/gift-offer");
  };

  const handleClose = () => {
    window.sessionStorage.setItem("GiftRenewalModal", true);
    setOverlay(null);
  };
  return (
    <StyledGiftRenewalModal>
      <StyledImageWrapper>
        <picture>
          <source
            srcSet={`//static.bookofthemonth.com/gift/${image}.webp`}
            type="image/webp"
          />
          <source srcSet={`//static.bookofthemonth.com/gift/${image}.jpg`} />
          <img
            src={`//static.bookofthemonth.com/gift/${image}.jpg`}
            alt="Gift renewal"
          />
        </picture>
      </StyledImageWrapper>
      <StyledContentWrapper>
        <h1>{hed}</h1>
        <p className={"dek"}>{dek}</p>
        <StyledButtonWrapper>
          <ClickTracker
            ctaType={"button"}
            id="Got it"
            style={"primary fullWidth"}
            handleClick={() => closeNGo()}
            title={"Choose a renewal plan"}
            logClickData={"click_renew_newpopupweb"}
          />
        </StyledButtonWrapper>
        <Link
          style={{
            textAlign: "center",
            margin: "20px auto 0",
            display: "block",
            fontSize: "18px",
          }}
          onClick={() => handleClose()}
        >
          No thanks, not now
        </Link>
      </StyledContentWrapper>
    </StyledGiftRenewalModal>
  );
};

export const StyledGiftRenewalModal = styled.div`
  width: 100%;
  max-width: 620px;
`;

export const StyledImageWrapper = styled.div`
  text-align: center;
  img {
    width: 100%;
  }
  @media screen and (max-width: 680px) {
    img {
      max-height: 320px;
      width: auto;
    }
  }
`;
export const StyledContentWrapper = styled.div`
  padding: 40px;
  text-align: center;
  .dek {
    margin: 12px auto 40px;
  }
  @media screen and (max-width: 680px) {
    padding: 40px 20px;
    h2 {
      max-width: 220px;
      margin: auto;
    }
  }
`;

function mapStateToProps(state) {
  const appFeatureActive_G2M =
    state.appFeatures?.find((f) => f?.feature == "G2M Offer")?.status ==
    "Active";
  const isG2MElegible =
    state.account?.policy?.type === "Member" &&
    state.account?.policy?.subType === "Gift" &&
    !state.renewalPlanId;
  const showG2Moffer = appFeatureActive_G2M && isG2MElegible;
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
    appFeatures: state.appFeatures || [],
    showG2Moffer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftRenewalModal);
