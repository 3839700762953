import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setSurveyResponse } from "../../../state/survey/flows";
import ClickTracker from "../../elements/ClickTracker";
import { BORDER, COLORS, FONTS } from "../../../css/Consts.js";
import { Dispatch } from "../../../types";
import { StyledFlexWrapper } from "../../components/structure/StyledFlexWrapper";
import { useWindowSize } from "../../hooks";
import { logClick } from "../../../state/analytics/flows";

type surveyResponse = {
  surveyId?: number;
  response?: number;
  source?: string;
  storeId?: number;
};

type ResubscribeSurveyProps = {
  setSurveyResponse: (arg0: surveyResponse) => void;
  borderTop?: boolean;
};

const ResubscribeSurvey: React.FC<ResubscribeSurveyProps> = ({
  setSurveyResponse,
  borderTop = true,
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const store = useStore();
  const { push } = useHistory();
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const NORExpId = "NORPauseCancelExp134-ResubscribeSurvey";

  const submitSurvey = async () => {
    if (selectedOption) {
      setIsSubmitting(true);
      await setSurveyResponse({
        surveyId: 39,
        response: selectedOption,
        source: "members",
        storeId: 1,
      });
    }
    push(`/cancel/thanks`);
  };

  const handleSelectedOptionClick = (option: number) => {
    logClick(
      `Clicked Resubscribe survery - select option ${option} - ${NORExpId}`,
      store,
    );
    setSelectedOption(option);
  };

  return (
    <StyledFlexWrapper
      id={`${NORExpId}-ResubscribeSurvey-Wrapper`}
      paddingTop={isMobile ? 40 : 56}
      paddingBottom={isMobile ? 80 : 120}
      paddingLeft={isMobile ? 20 : 0}
      paddingRight={isMobile ? 20 : 0}
      gap={40}
      maxWidth={480}
    >
      <h4 style={{ textAlign: "center" }}>
        On a scale of 1 to 5, how likely would you be to resubscribe to BOTM in
        the future?
      </h4>
      <StyledFlexWrapper gap={12}>
        <StyledChoiceBlockWrapper>
          <StyledChoiceBlock
            onClick={() => handleSelectedOptionClick(1)}
            className={selectedOption == 1 ? "active" : ""}
          >
            1
          </StyledChoiceBlock>
          <StyledChoiceBlock
            onClick={() => handleSelectedOptionClick(2)}
            className={selectedOption == 2 ? "active" : ""}
          >
            2
          </StyledChoiceBlock>
          <StyledChoiceBlock
            onClick={() => handleSelectedOptionClick(3)}
            className={selectedOption == 3 ? "active" : ""}
          >
            3
          </StyledChoiceBlock>
          <StyledChoiceBlock
            onClick={() => handleSelectedOptionClick(4)}
            className={selectedOption == 4 ? "active" : ""}
          >
            4
          </StyledChoiceBlock>
          <StyledChoiceBlock
            onClick={() => handleSelectedOptionClick(5)}
            className={selectedOption == 5 ? "active" : ""}
          >
            5
          </StyledChoiceBlock>
        </StyledChoiceBlockWrapper>
        <StyledFlexWrapper
          id={`${NORExpId}-scaleLabel`}
          flexDirection="row"
          justifyContent="space-between"
          customStyles={scaleLabelStyles}
        >
          <p className="label2">Unlikely</p>
          <p className="label2">Likely</p>
        </StyledFlexWrapper>
      </StyledFlexWrapper>
      <StyledFlexWrapper>
        <ClickTracker
          ctaType={"button"}
          id={`submit-feedback-${NORExpId}`}
          style={"primary -fullWidth"}
          handleClick={() => submitSurvey()}
          isPending={isSubmitting}
          title={"Submit feedback"}
          logClickData={`Resubscribe survery - submit feedback - ${NORExpId}`}
        />
      </StyledFlexWrapper>
    </StyledFlexWrapper>
  );
};

const scaleLabelStyles = `
  h6{color: ${COLORS.gray5};}
`;

const StyledChoiceBlockWrapper = styled.div.attrs({
  className: "sc-StyledChoiceBlockWrapper" as string,
})`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 12px;
`;

export const StyledChoiceBlock = styled.a.attrs({
  className: "sc-StyledChoiceBlock" as string,
})`
  text-align: center;
  color: ${COLORS.gray5};
  padding: 24px 16px;
  border: ${BORDER.defaultBorder};
  font-family: ${FONTS.sansSerifStackBold};
  border-radius: ${BORDER.defaultBorderRadius};
  box-shadow: ${BORDER.defaultBoxShadow};
  transition:
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  &.active,
  :active,
  :hover:not(.button) {
    color: ${COLORS.primary5};
    border-color: ${COLORS.primary5};
    background-color: ${COLORS.primary1};
  }
`;

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setSurveyResponse }, dispatch);
}

export default connect(null, mapDispatchToProps)(ResubscribeSurvey);
