import * as actions from "./actions";

export default function reducer(
  state = {
    promoBanners: [],
    promos: [],
    bluebox: false, //bluebox property: delete this when the campaign is over
    bffRejoin: false, //bff rejoin property
    md22: false, //md22 rejoin property
  },
  action,
) {
  switch (action.type) {
    case actions.SET_PROMO_BANNERS:
      return { ...state, promoBanners: action.payload };
    case actions.SET_PROMOS:
      return { ...state, promos: action.payload };
    case actions.SET_BLUEBOX: //bluebox property: delete this when the campaign is over
      return { ...state, bluebox: action.payload };
    case actions.SET_BFF_REJOIN: //bff rejoin property property: delete this when the campaign is over
      return { ...state, bffRejoin: action.payload };
    case actions.SET_MD22: //md22 property property: delete this when the campaign is over
      return { ...state, md22: action.payload };
    default:
      return state;
  }
}
