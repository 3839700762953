import dateformat from "dateformat";
import {
  defaultForm,
  printAtHome,
  cardAddressForm,
  dateForm,
  giftCardDetails,
} from "../pages/gift/purchase/GiftFormData";
import { FormAction } from "../pages/gift/purchase/util/formAction";
import { SelectOption, FieldSet, FieldType } from "../elements/form";
import { generateDates } from "../pages/gift/purchase/utils";
import { convertStringMonthToNumber } from "../../utils/index";

// An interface for our actions
type FormReducerType = {
  type: FormAction;
  payload: {
    fieldName?: string;
    value: string;
    options?: SelectOption[];
    canadaMode?: boolean;
  };
};

const getFieldsWithValues = (state: FieldSet) => {
  let result: FieldSet = {};
  const fieldsToCheck = [
    "name",
    "email",
    "recipientName",
    "recipientEmail",
    "senderName",
    "senderEmail",
    "message",
    "month",
    "day",
    "year",
    "firstName",
    "lastName",
    "address",
    "apt",
    "city",
    "state",
    "zip",
  ];

  fieldsToCheck.forEach((k) => {
    const f = state[k];
    if (f) {
      const fieldValue = f.value as string;
      if (fieldValue?.length > 0) {
        result[k] = f;
      }
    }
  });
  return result;
};

const validateDate = (formState: FieldSet) => {
  const year = formState?.year?.value;
  const month =
    convertStringMonthToNumber(formState?.month?.value as string) - 1;
  const day = formState?.day?.value;

  const deliveryDate = new Date(
    Number(year),
    Number(month),
    Number(day),
  ).setHours(0, 0, 0, 0);
  const todayValue = new Date().setHours(0, 0, 0, 0);
  if (deliveryDate < todayValue) {
    return false;
  } else {
    return true;
  }
};

const filterApplicableFields = (
  fieldsWithValues: FieldSet,
  newForm: FieldSet,
) => {
  let result: FieldSet = {};

  Object.keys(fieldsWithValues).forEach((k) => {
    if (newForm.hasOwnProperty(k)) {
      result[k] = fieldsWithValues[k];
    }
  });

  return result;
};

export const formReducer = (
  state: FieldSet,
  action: FormReducerType,
): FieldSet => {
  const fieldsWithValues = getFieldsWithValues(state);
  const { fieldName = "", value } = action.payload || {};
  const type: FieldType = "number";

  switch (action.type) {
    case FormAction.CLEAR_FORM:
      return {
        ...defaultForm,
      };
    case FormAction.SET_TO_NULL:
      return {};
    case FormAction.DEFAULT_FORM:
      return {
        ...defaultForm,
        ...filterApplicableFields(fieldsWithValues, defaultForm),
      };
    case FormAction.PRINT_AT_HOME:
      return {
        ...printAtHome,
        ...filterApplicableFields(fieldsWithValues, printAtHome),
      };
    case FormAction.GIFT_CARD_DETAILS:
      return {
        ...giftCardDetails,
        ...filterApplicableFields(fieldsWithValues, giftCardDetails),
      };
    case FormAction.DATE_FORM:
      const today = new Date();
      const day = dateformat(today, "dd");
      const month = dateformat(today, "mm");
      const year = dateformat(today, "yyyy");
      return {
        ...dateForm,
        month: {
          ...dateForm["month"],
          value: month,
        },
        day: {
          ...dateForm["day"],
          value: day,
        },
        year: {
          ...dateForm["year"],
          value: year,
        },
        ...filterApplicableFields(fieldsWithValues, dateForm),
      };
    case FormAction.CARD_ADDRESS_FORM:
      return {
        ...cardAddressForm,
        state: {
          ...cardAddressForm["state"],
          required: true,
          visible: true,
        },
        zip: { ...cardAddressForm["zip"], type },
        ...filterApplicableFields(fieldsWithValues, cardAddressForm),
      };
    case FormAction.SET_FIELD_VALUE:
      let currentState = state;
      const monthNumber = convertStringMonthToNumber(value);
      const daysArr = generateDates(monthNumber);

      if (fieldName == "month" && currentState.hasOwnProperty(fieldName)) {
        const isDayExist = daysArr.some(
          (i) => i.value === currentState["day"].value,
        );
        currentState = {
          ...currentState,
          day: {
            ...currentState["day"],
            value: isDayExist ? currentState["day"].value : "",
            options: daysArr,
          },
        };
      }

      if (
        (fieldName == "month" || fieldName == "day" || fieldName == "year") &&
        currentState.hasOwnProperty(fieldName)
      ) {
        const newState = {
          ...currentState,
          [fieldName]: { ...state[fieldName], value },
        };
        const isDateFormValid = validateDate(newState);
        if (!isDateFormValid) {
          return {
            ...currentState,
            month: {
              ...currentState["month"],
              isInErrorState: true,
            },
            day: {
              ...currentState["day"],
              isInErrorState: true,
            },
            year: {
              ...currentState["year"],
              isInErrorState: true,
            },
            dateError: {
              ...currentState["dateError"],
              visible: true,
            },
            [fieldName]: { ...state[fieldName], isInErrorState: true, value },
          };
        } else {
          return {
            ...currentState,
            month: {
              ...currentState["month"],
              isInErrorState: false,
            },
            day: {
              ...currentState["day"],
              isInErrorState: false,
            },
            year: {
              ...currentState["year"],
              isInErrorState: false,
            },
            dateError: {
              ...currentState["dateError"],
              visible: false,
            },
            [fieldName]: { ...state[fieldName], isInErrorState: false, value },
          };
        }
      }
      if (currentState.hasOwnProperty(fieldName)) {
        return {
          ...currentState,
          [fieldName]: { ...state[fieldName], value },
        };
      }

      return { ...currentState };
    default:
      return state;
  }
};
