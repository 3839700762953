import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClickTracker from "../../elements/ClickTracker";
import { cancelAccount } from "../../../state/account/flows";
import { AppState, Dispatch } from "../../../types";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import { Variant } from "../../../ui/skeleton/Experiments";
import Authenticate from "../../../ui/skeleton/Authenticate";
import { useWindowSize } from "../../../ui/hooks";
import { useHistory, useLocation } from "react-router";
import {
  canRefundNewMemberShipment,
  getIsNewMemberHold,
} from "../../../utils/getIsNewMemberHold";
import { STANDARD_ONE_CREDIT_COMMIT_PLAN_ID } from "../../../state/hardcoded/plans";
import { setOverlay } from "../../../state/ui/creators";
import { pluralize } from "../../../utils/pluralize";
import dateformat from "dateformat";
import getGracePeriodCancelDate from "../../../utils/getGracePeriodCancelDate";

type CancelConfirmationProps = {
  creditsLeft: number;
  cancelAccount: (cancelConfirmed?: boolean) => void;
  exp134: number;
  exp146: number;
  setOverlay: (overlay: string, type: string, data: {}) => void;
} & MappedCancelConfirmationProps;

const Confirmation: React.FC<CancelConfirmationProps> = ({
  account,
  cancelAccount,
  exp134,
  exp146,
  setOverlay,
  mustRenew,
  canRefundNewMemberShipment,
  creditsLeft,
}) => {
  const [isPending, setIsPending] = React.useState(false);
  const isCanadianResident = account?.countryCode === "CA";
  const showNORPauseCancelExp134 = exp134 == 1;
  const showCreditExpirationDateExp146 = exp146 == 1;
  const NORExpId = showNORPauseCancelExp134 ? "-NORPauseCancelExp134" : "";
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showSave = searchParams.get("save") === "true";
  const expirationDate = getGracePeriodCancelDate(account?.policyEffectiveDate);

  const { hed, dek } = DATA({
    creditsLeft,
    canRefundNewMemberShipment,
    showCreditExpirationDateExp146,
    expirationDate,
  });

  const cancelMe = async () => {
    if (mustRenew) {
      setOverlay("nonCancelModal", "", {});
    } else {
      setIsPending(true);
      await cancelAccount();
      setIsPending(false);
      const NORPauseCancelExp134Link = showSave
        ? `/cancel/confirmation-perks`
        : `/cancel/confirmation`;
      showNORPauseCancelExp134
        ? history.replace(NORPauseCancelExp134Link)
        : history.replace(`/cancel/confirmation`);
    }
  };

  const cancelButton = () => {
    if (showNORPauseCancelExp134) {
      return (
        <ClickTracker
          ctaType={"button"}
          id={`cancel-confirm-submit${NORExpId}`}
          style={"primary -fullWidth"}
          isPending={isPending}
          isDisabled={isPending}
          handleClick={cancelMe}
          title={"Submit and cancel"}
          logClickData={`Cancel - submit${NORExpId}`}
        />
      );
    } else {
      return (
        <ClickTracker
          ctaType={"button"}
          id={`cancel-confirm-submit`}
          style={"primary -fullWidth"}
          isPending={isPending}
          isDisabled={isPending}
          handleClick={cancelMe}
          title={"Submit and cancel"}
          logClickData={"Cancel - submit"}
        />
      );
    }
  };

  return (
    <Authenticate>
      <StyledFlexWrapper
        paddingTop={isMobile ? 40 : 80}
        paddingBottom={isMobile ? 186 : 120}
        paddingLeft={isMobile ? 20 : 0}
        paddingRight={isMobile ? 20 : 0}
      >
        <StyledFlexWrapper maxWidth={80}>
          <img
            src={
              "//static.bookofthemonth.com/svgs/VeryChallenging_Indicator_Icon.svg"
            }
          />
        </StyledFlexWrapper>
        <StyledFlexWrapper
          resetSpacing
          gap={isMobile ? 12 : 20}
          maxWidth={600}
          marginTop={isMobile ? 12 : 20}
          textAlign="center"
        >
          {hed && <h4>{hed}</h4>}
          {dek && <p>{dek}</p>}
        </StyledFlexWrapper>
        <StyledFlexWrapper
          maxWidth={isMobile ? undefined : 392}
          gap={20}
          marginTop={40}
          textAlign="center"
        >
          {cancelButton()}
          <ClickTracker
            ctaType={"button"}
            id={`cancel-confirm-keep-membership${NORExpId}`}
            style={"secondary -fullWidth"}
            linkTo={"/pause-membership/thanks"}
            title={"Keep my membership"}
            logClickData={`Cancel - keep membership${NORExpId}`}
          />
          {!showNORPauseCancelExp134 && (
            <ClickTracker
              ctaType={"link"}
              id="cancel-confirm-pause"
              style={"primary -fullWidth"}
              linkTo={"/pause-membership"}
              title={"Pause my membership"}
              logClickData={"Cancel - pause membership"}
            />
          )}
        </StyledFlexWrapper>
      </StyledFlexWrapper>
    </Authenticate>
  );
};

const ConfirmationExp: React.FC = (props) => (
  <Variant experimentId={134}>
    {({ variant: variant134 }: { variant: number }) => (
      <Variant experimentId={146}>
        {({ variant: variant146 }: { variant: number }) => (
          <Confirmation
            {...(props as CancelConfirmationProps)}
            exp134={variant134}
            exp146={variant146}
          />
        )}
      </Variant>
    )}
  </Variant>
);

const DATA = ({
  creditsLeft = 0,
  canRefundNewMemberShipment = false,
  showCreditExpirationDateExp146 = false,
  expirationDate = null,
}: {
  creditsLeft: number;
  canRefundNewMemberShipment: boolean;
  showCreditExpirationDateExp146: boolean;
  expirationDate: Date | null;
}) => ({
  hed: hed(
    creditsLeft,
    canRefundNewMemberShipment,
    showCreditExpirationDateExp146,
    expirationDate,
  ),
  dek: dek(creditsLeft, canRefundNewMemberShipment),
});

function hed(
  creditsLeft: number,
  canRefundNewMemberShipment: boolean,
  showCreditExpirationDateExp146: boolean,
  expirationDate: Date | null,
) {
  let hed = `Are you absolutely sure?`;

  if (creditsLeft && showCreditExpirationDateExp146) {
    hed = `You will lose your remaining ${creditsLeft} ${pluralize(
      "credit",
      creditsLeft,
    )} ${
      expirationDate
        ? ` on ${dateformat(expirationDate, "UTC:mmmm dd, yyyy")}`
        : ""
    } when you cancel.`;
  } else if (creditsLeft) {
    hed = `You will lose your remaining ${creditsLeft} ${pluralize(
      "credit",
      creditsLeft,
    )} when you cancel.`;
  }
  if (canRefundNewMemberShipment) {
    hed = `Are you absolutely sure?`;
  }
  return hed;
}

function dek(creditsLeft: number, canRefundNewMemberShipment: boolean) {
  let dek = ``;
  if (creditsLeft) {
    dek = `Are you absolutely sure?`;
    if (canRefundNewMemberShipment) {
      dek = `If you cancel you will not get your box and your order will be refunded. You will also lose your remaining ${creditsLeft} ${pluralize(
        "credit",
        creditsLeft,
      )}.`;
    }
  } else {
    if (canRefundNewMemberShipment) {
      dek = `If you cancel you will not get your box and your order will be refunded.`;
    }
  }
  return dek;
}

type MappedCancelConfirmationProps = {
  mustRenew: boolean;
  canRefundNewMemberShipment: boolean;
  creditsLeft: number;
} & Pick<AppState, "account">;

const mapStateToProps = (state: AppState): MappedCancelConfirmationProps => {
  const mustRenew = !!state?.account?.policy?.mustRenew;
  return {
    account: state.account,
    mustRenew: mustRenew,
    canRefundNewMemberShipment: canRefundNewMemberShipment(state.account),
    creditsLeft: state.account?.credits || 0,
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ cancelAccount, setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationExp);
