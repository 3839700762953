import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { COLORS, BORDER } from "../../../css/Consts";
import { Button } from "../../elements/form/Button";
import { Error } from "../../elements/form/Error";
import { StyledPageHeader } from "../structure/PageHeader";
// import ImageUploading, { ImageListType } from 'react-images-uploading';
import { ModalEditWrapper } from "../structure/ModalEditWrapper";
import PhotoSelect from "./PhotoSelect";

// @TODO: Need account props and save function passed in as props.
interface ISelectAvatar {
  updateAvatar?: (av?: string) => void;
  account?: any;
  error?: string;
  hideHeader?: boolean;
  showPreview?: boolean;
  buttonText?: string;
  isMobile?: boolean;
}

interface IStyledButton {
  isActive?: boolean;
}

export const SelectAvatar: React.FC<ISelectAvatar> = ({
  updateAvatar,
  account,
  error = null,
  hideHeader,
  buttonText,
  isMobile = false,
}) => {
  const avatarInitials =
    account?.firstName?.split("").shift().toUpperCase() +
    account?.lastName?.split("").shift().toUpperCase();
  const defaultAvatarsColors = ["blue", "red", "green"];

  //Sets default avatar if none on account.
  const [selectedAvatar, setSelectedAvatar] = useState(account?.profileImg);
  //Sets default showing avatar if none on account.
  const [showingAvatar, setShowingAvatar] = useState(
    account?.profileImg
      ? `https://static.bookofthemonth.com/Bookmojis/${account?.profileImg}`
      : null,
  );
  const [showModal, setShowModal] = useState(false);
  const [pending, setPending] = useState(false);

  const hasPhotoAvatar = showingAvatar?.split("_").shift() !== avatarInitials;
  let AvatarbuttonState = false;
  const selectNewAvatar = (newAvatar?: string) => {
    if (hasPhotoAvatar && newAvatar?.split("_").shift() == avatarInitials) {
      setSelectedAvatar(newAvatar);
    } else {
      setShowingAvatar(newAvatar || null);
      setSelectedAvatar(newAvatar);
    }
  };

  const avatarColorPicker = (
    <StyledPickColorWrapper>
      {error}
      <StyledButtonsWrapper>
        {defaultAvatarsColors.map((color, index) => {
          if (`${avatarInitials}_${color}.png` == selectedAvatar) {
            AvatarbuttonState = true;
          } else {
            AvatarbuttonState = false;
          }
          return (
            <StyledAvatarButton
              key={index}
              onClick={() => selectNewAvatar(`${avatarInitials}_${color}.png`)}
              className={AvatarbuttonState ? "active" : "inActive"}
              data-test="color-choice-button"
            >
              <div>
                <img
                  width={"90px"}
                  height={"90px"}
                  src={`https://static.bookofthemonth.com/avatars/${avatarInitials}_${color}.png`}
                />
              </div>
            </StyledAvatarButton>
          );
        })}
      </StyledButtonsWrapper>
    </StyledPickColorWrapper>
  );

  // const onChange = (imageList?:ImageListType) => {
  //   const uploadedImageURL = imageList[0].data_url;
  //   setSelectedAvatar(uploadedImageURL);
  //   setShowingAvatar(uploadedImageURL);
  // };

  const imageUpload = (
    <StyledImageUploadButton>
      {/* <ImageUploading
        multiple
        value={[]}
        onChange={(onChange)}
        maxNumber={68}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          dragProps,
        }) => ( */}
      <div className="upload__image-wrapper">
        {hasPhotoAvatar ? (
          <img
            width={130}
            // onClick={isMobile? ()=>setShowModal(true): onImageUpload}
            height={130}
            src={showingAvatar || ""}
          />
        ) : (
          <AddPhotoButton onClick={() => null}>
            <img
              width={60}
              height={60}
              src={`https://static.bookofthemonth.com/svgs/Icon_AddPhoto.svg`}
            />
          </AddPhotoButton>
        )}
      </div>
      {/* )}
      </ImageUploading> */}
    </StyledImageUploadButton>
  );
  const button = (
    <Button
      id="edit avatar"
      style="primary fullWidth"
      handleClick={() => {
        setPending(true);
        updateAvatar?.(selectedAvatar);
      }}
      isPending={pending}
      title={pending ? "Updating..." : buttonText ? buttonText : "Save"}
    />
  );
  const width = isMobile ? "90%" : "100%";
  const content = (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: `${BORDER.defaultBorder}`,
          }}
        >
          <h5 style={{ marginBottom: "15px" }}>Add a photo</h5>
          {imageUpload}
          {/* {hasPhotoAvatar && <ImageUploading
              multiple
              value={[]}
              onChange={(onChange)}
              maxNumber={68}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                dragProps,
              }) => (
                <div className="upload__image-wrapper"
                style={{margin:'15px'}}
                onClick={isMobile ? () => setShowModal(true) : onImageUpload}>
                    <a style={{fontSize:'16px', marginTop:'10px'}}>Edit photo</a>
                </div>
              )}
            </ImageUploading>} */}
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          paddingTop: 10,
          borderTop: "1px solid var(--color-gray2)",
        }}
      >
        <h5 style={{ display: "flex", justifyContent: "center" }}>
          Or pick a color
        </h5>
        {avatarColorPicker}
        <div style={{ margin: "auto", width: width }}>{button}</div>
      </div>
    </>
  );

  if (isMobile) {
    return (
      <>
        <StyledPageHeader maxWidth={900} bottom={40}>
          <h1 style={{ marginTop: "35px" }}>
            {hideHeader ? "" : "Customize avatar"}
          </h1>
        </StyledPageHeader>
        {error && <Error message={error} />}
        {content}
        <PhotoSelect
          showing={showModal}
          closeModal={setShowModal}
          // onChange={()=>null}
        />
      </>
    );
  } else {
    return (
      <ModalEditWrapper header={hideHeader ? "" : "Customize avatar"}>
        {error && <Error message={error} />}
        {content}
      </ModalEditWrapper>
    );
  }
};

export const AddPhotoButton = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${COLORS.gray1};
`;

export const StyledPickColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledImageUploadButton = styled.div`
  margin-bottom: 20px;
  img {
    border-radius: 100px;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 25px;
  border-radius: 50%;
`;

export const StyledAvatarButton = styled.div<IStyledButton>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.active {
    border-style: solid;
    border-width: thin;
    border-color: ${COLORS.primary5};
  }
`;
