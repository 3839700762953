import React, { useState } from "react";
import { COLORS } from "../../../css/Consts";
import styled from "styled-components";
import Checkmark from "../../../ui/elements/Check";
import Expander from "../../elements/Expander";
import { useWindowSize } from "../../../ui/hooks";

const TroubleShootBlock: React.FC<{ top?: number }> = ({ top = 40 }) => {
  const DATA_ = [
    `Switch to a supported device/browser (iOS 11+ or macOS 10.13+)`,
    `Make sure you are logged into your iCloud`,
    `Check you have a valid card in your Apple wallet`,
    `Verify “Wallet & Apple Pay” settings are correct`,
  ];

  const [expanded, setExpanded] = useState(false);
  const [width] = useWindowSize();
  const isMobile = width < 680;

  const ListItems = () =>
    DATA_.map((listItem, idx) => (
      <div
        key={idx}
        id={`trouble-shoot-list-item-${idx}`}
        className={`list-item`}
      >
        <Checkmark color={COLORS.black} />
        <p className={`P2`}>{listItem}</p>
      </div>
    ));

  return (
    <div style={{ marginTop: `${top}px` }}>
      <Expander
        border={true}
        top={20}
        color={COLORS.black}
        isBold={true}
        size={isMobile ? 1.6 : 1.8}
        expandedText={"Troubleshoot"}
        defaultText={"Troubleshoot"}
        isExpanded={expanded}
        openExpander={() => setExpanded((prev) => !prev)}
        closeExpander={() => setExpanded((prev) => !prev)}
        trackLabel={"clicks_unsupported_applepay_cta_expander"}
      >
        {expanded && (
          <StyledTroubeShootBlock isMobile={isMobile}>
            {ListItems()}
          </StyledTroubeShootBlock>
        )}
      </Expander>
    </div>
  );
};

type TroubleShootBlockProps = {
  isMobile?: boolean;
};

const StyledTroubeShootBlock = styled.div.attrs({
  className: "sc-troube-shoot-block",
})<TroubleShootBlockProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  .list-item {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  p.P2 {
    font-size: ${({ isMobile }) => (isMobile ? 14 : 16)}px;
  }
`;

export default TroubleShootBlock;
