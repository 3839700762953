import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { AppState } from "../types";

import account from "./account/reducers";
import accountHistory from "./accountHistory/reducers";
import activeModal from "./activeModal/reducers";
import address from "./address/reducers";
import analytics from "./analytics/reducers";
import box, { creditNavReducer as creditNavStatus } from "./box/reducers";
import bookshelf from "./bookshelf/reducers";
import clientToken from "./clientToken/reducers";
import joinData from "./joinData/reducers";
import error from "./error/reducers";
import gift from "./gift/reducers";
import joinFlow from "./joinFlow/reducers";
import judges from "./judges/reducers";
import loginFlow from "./loginFlow/reducers";
import message from "./accountMessage/reducers";
import origin from "./origin/reducers";
import policy from "./policy/reducers";
import faves from "./faves/reducers";
import tranche from "./tranche/reducers";
import cancel from "./cancel/reducers";
import reviews from "./reviews/reducers";
import ui from "./ui/reducers";
import entryPoint from "./entryPoint/reducers";
import store from "./store/reducers";
import promo from "./promo/reducers";
import loading from "./loading/reducers";
import displayedOrderHistory from "./orderHistory/reducers";
import wishlist from "./accountWishlist/reducers";
import appFeatures from "./appFeatures/reducers";
import rejoinStatus from "./rejoinStatus/reducers";

export default (history: History) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    account,
    accountHistory,
    activeModal,
    address,
    analytics,
    bookshelf,
    box,
    clientToken,
    joinData,
    error,
    gift,
    joinFlow,
    judges,
    loginFlow,
    message,
    origin,
    policy,
    store,
    tranche,
    faves,
    cancel,
    creditNavStatus,
    reviews,
    ui,
    entryPoint,
    promo,
    loading,
    displayedOrderHistory,
    wishlist,
    appFeatures,
    rejoinStatus,
  });
