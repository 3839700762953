/**
 * ReferFriendForm.jsx
 * Wrapper for switching which referral you'll be doing
 * Child of ReferFriend.jsx
 * */
import React, { useState } from "react";

import FriendInviteEmail from "UI/pages/raf/FriendInviteEmail.jsx";
import FriendInviteLink from "UI/pages/raf/FriendInviteLink.jsx";
import styled from "styled-components";
import LinkBar from "UI/elements/LinkBar";

const data = [
  { name: "By url", value: "link" },
  { name: "By email", value: "email" },
];

const ReferFriendForm = (props) => {
  const [activeTab, changeTab] = useState("link");
  const [showReview, toggleShowReview] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  let { account, isCreditMember, location, recommendedBook, entryPoint } =
    props;
  let referralLink = `https://www.mybotm.com/${account?.referCode}`;

  if (location == "recommendRead") {
    referralLink = `https://www.bookofthemonth.com/all-hardcovers/${
      recommendedBook.path
    }?referCode=${account?.referCode}${
      showReview && recommendedBook?.review ? "&show_review=true" : ""
    }`;
  }

  const formElmnt = document.getElementById("inviteEmail");
  const formHeight = formElmnt?.offsetHeight;
  const recentBox = document.getElementById("recentBox");
  const recentBoxHeight = recentBox?.offsetHeight || 0;
  const myReview = document.getElementById("myReview");
  const myReviewHeight = myReview?.offsetHeight || 0;
  const personalMessage = document.getElementById("personalMessage");
  const personalMessageHeight = personalMessage?.offsetHeight || 0;
  const totalHeight =
    formHeight + myReviewHeight + recentBoxHeight + personalMessageHeight;

  const reviewForm = (
    <StyledMiniForm className="miniText" id={"myReview"}>
      <input
        checked={showReview}
        type="checkbox"
        onChange={() => toggleShowReview(!showReview)}
      />
      Share my review with my friends.
    </StyledMiniForm>
  );

  return (
    <div style={{ width: "100%" }}>
      <LinkBar
        links={data}
        activeLink={activeTab}
        action={(tab) => {
          if (tab !== "email") setShowPreview(false);
          changeTab(tab);
        }}
        top={30}
        bottom={30}
      />

      <StyledFormsOuterWrapper totalHeight={totalHeight}>
        <StyledFormWrapper
          className={
            "inviteEmail" + (activeTab === "email" ? " showIt" : " hideIt")
          }
          id={"inviteEmail"}
        >
          <FriendInviteEmail
            showBox={false}
            account={account}
            isCreditMember={isCreditMember}
            location={location}
            recommendedBook={recommendedBook}
            scrollUp={props.scrollUp}
            entryPoint={entryPoint}
            memberReview={recommendedBook?.review}
            showMemberReview={showReview}
            setShowPreview={setShowPreview}
            showPreview={showPreview}
          />
          {location == "recommendRead" && recommendedBook?.review && reviewForm}
        </StyledFormWrapper>
        <StyledFormWrapper
          className={
            "inviteLink" + (activeTab === "link" ? " showIt" : " hideIt")
          }
        >
          <div className={"inviteLink"}>
            <FriendInviteLink
              account={account}
              location={location}
              referralLink={referralLink}
              entryPoint={entryPoint}
            />
          </div>
          {location == "recommendRead" && recommendedBook?.review && reviewForm}
        </StyledFormWrapper>
      </StyledFormsOuterWrapper>
    </div>
  );
};

export const StyledFormsOuterWrapper = styled.div.attrs({
  className: "sc-StyledFormsOuterWrapper",
})`
  position: relative;
  @media screen and (min-width: 960px) {
    min-height: ${(props) =>
      props.totalHeight ? props.totalHeight + "px" : 0};
  }
`;

export const StyledFormWrapper = styled.div.attrs({
  className: "sc-StyledFormWrapper",
})`
  width: 100%;
  @media screen and (max-width: 959px) {
    &.hideIt {
      display: none;
    }
    &.showIt {
      display: block;
    }
  }
  @media screen and (min-width: 960px) {
    position: absolute;
    top: -1000px;
    left: 0;
    &.showIt {
      top: 0;
      left: 0;
    }
  }
`;

export const StyledMiniForm = styled.div.attrs({
  className: "sc-StyledMiniForm",
})`
  margin-top: 10px;
  text-align: left;
`;

export default ReferFriendForm;
