import React from "react";
import SkeletonWrapper, { P, Word, Button, IMG } from "./SkeletonWrapper.jsx";
import ShortBlock from "./ShortBlockSkeleton.jsx";
import JacketList from "./JacketListSkeleton.jsx";

const PDPageSkeleton = ({ header = null }) => (
  <SkeletonWrapper className="content -wide pdPage">
    <div className="contentWrapper">
      <div className="mainContainer">
        <ShortBlock>
          <div className="content">
            <p className="label2">
              <Word className=" -center" />
            </p>
            <h4>
              <Word className="-h4  -center" />
            </h4>
            <Word className="-smallFont  -center" />
            <Word className="-smallFont  -center" />
            <Button />
          </div>
        </ShortBlock>
        <div className="fullDetails">
          <section>
            <Word className="-h4" />
            <div className="-staggered">
              <P />
              <P />
              <P />
              <P />
            </div>
          </section>
          <section className="informers">
            <Word className="-h4" />
            <ul>
              <li>
                <IMG className="-round" />
                <Word className="-smallFont" />
              </li>
              <li>
                <IMG className="-round" />
                <Word className="-smallFont" />
              </li>
            </ul>
          </section>
          <section>
            <Word className="-h4" />
            <div className="-staggered">
              <P />
              <P />
              <P />
              <P />
            </div>
          </section>
          <section>
            <Word className="-h4" />
            <div className="-staggered">
              <P />
              <P />
              <P />
              <P />
            </div>
          </section>
        </div>
      </div>
      <div className="sideContainer">
        <div id="PDSideContent">
          <div className="categoryList">
            {(header && <h5 className="miniText">{header}</h5>) || <Word />}
            <JacketList />
          </div>
        </div>
      </div>
    </div>
  </SkeletonWrapper>
);

export default PDPageSkeleton;
