import React, { lazy, useContext, ReactElement } from "react";
import { Route, Redirect } from "react-router-dom";

const pdpRegEx = "/:book([A-Za-z0-9-]+-[0-9]+)";

const Redirects = (() => [
  <Route exact path={["/perfectpick", "/newfavorite"]}>
    <Redirect to="/credit-rejoin/step-join" />
  </Route>,
  <Redirect exact from="/gift-purchase" to="/gift/purchase" />,
  <Redirect exact from="/sohohouse" to="/" />,
  <Redirect
    exact
    from="/best-books-for-mom"
    to="/blog/the-7-best-books-for-mom-208"
  />,
  ...["/my-account/refer-a-friend", "/refer", "/friends"].map((p) => (
    <Redirect exact key={p} from={p} to="/refer-a-friend" />
  )),
  <Redirect exact from="/hannah5" to="/referCode?referCode=7y2ggt7vr6" />, // Hannah Morrissey RAF campaign
  <Redirect from="/refer/:referCode" to="/referCode?referCode=:referCode" />,
  <Redirect from="/referral/:referCode" to="/referCode?referCode=:referCode" />,
  <Redirect exact from="/terms-of-service" to="/terms-of-membership" />,
  <Redirect exact from="/contact" to="/help-center" />,
  <Redirect exact from="/contact-us" to="/help-center" />,
  <Route exact path={["/jobs", "/careers"]}>
    <Redirect to="/join-our-team" />
  </Route>,
  <Redirect from={`/more-books/all-selections${pdpRegEx}`} to={pdpRegEx} />,
  <Route exact path={["/virtual-book-tour", "/virtualbooktour"]}>
    <Redirect to="/vbt" />
  </Route>,
  <Route exact path={[`/rejoin-a${pdpRegEx}`, `/plan-rejoin-a${pdpRegEx}`]}>
    <Redirect exact to={`/credit-rejoin-a${pdpRegEx}`} />
  </Route>,
  <Route exact path={["/rejoin-a", "/plan-rejoin-a"]}>
    <Redirect exact to="/credit-rejoin-a" />
  </Route>,
  <Route exact path={[`/rejoin-c${pdpRegEx}`, `/plan-rejoin-c${pdpRegEx}`]}>
    <Redirect exact to={`/credit-rejoin-c${pdpRegEx}`} />
  </Route>,
  <Route exact path={["/rejoin-c", "/plan-rejoin-c"]}>
    <Redirect exact to="/credit-rejoin-c" />
  </Route>,
  <Route exact path={[`/rejoin-f${pdpRegEx}`, `/plan-rejoin-f${pdpRegEx}`]}>
    <Redirect exact to={`/credit-rejoin-f${pdpRegEx}`} />
  </Route>,
  <Route exact path={["/rejoin-f", "/plan-rejoin-f"]}>
    <Redirect exact to="/credit-rejoin-f" />
  </Route>,
  <Route exact path={["/plan-rejoin", "/rejoin", "/renewal"]}>
    <Redirect exact to="/credit-rejoin/step-join" />
  </Route>,
  <Route
    exact
    path={[
      "/cancel/skip-month",
      "/cancel/skip-renewal",
      "/cancel/email-request",
      "/cancel/email-confirmation",
      "/cancel/link",
      "/cancel/email-skip-check",
    ]}
  >
    <Redirect exact to="/cancel-pause" />
  </Route>,
])();

export default Redirects;
