import React from "react";

type DataProps = {
  image?: string;
  hed?: string | React.ReactFragment;
  dek?: string | React.ReactFragment;
};

type OldDataObjProps = {
  bff1: DataProps;
  bff2: DataProps;
  bff3: DataProps;
  bffholiday: DataProps;
};

type TierDataObjProps = {
  friend: DataProps;
  bff1: DataProps;
  bff2: DataProps;
  bffholiday: DataProps;
};

export const _ORIG_BBF_ENTRY_DATA: OldDataObjProps = {
  bff1: {
    image: `//static.bookofthemonth.com/loyalty/logos/bff_badge.png`,
    hed: "Become a BFF in no time.",
    dek: "Rejoin today to get that much closer to BFF rewards and perks.",
  },
  bff2: {
    image: `//static.bookofthemonth.com/loyalty/logos/bff_badge.png`,
    hed: "Rejoin as a BFF.",
    dek: "Come back for your free birthday credit, our BFF customer service line, and more!",
  },
  bff3: {
    image: `//static.bookofthemonth.com/loyalty/logos/bff_badge.png`,
    hed: "Rejoin to get your BFF birthday credit.",
    dek: "Come back for your free birthday credit, our BFF customer service line, and more!",
  },
  bffholiday: {
    image: `//static.bookofthemonth.com/loyalty/logos/bff_badge.png`,
    hed: (
      <>
        Become a BFF in <span className={"nowrap"}>no time.</span>
      </>
    ),
    dek: (
      <>
        Rejoin for free today and get that much closer to BFF rewards{" "}
        <span className={"nowrap"}>and perks.</span>
      </>
    ),
  },
};

export const _TIER_ENTRY_DATA: TierDataObjProps = {
  friend: {
    image: `https://static.bookofthemonth.com/Relationship/Icons/Badge/Friend_Icon.svg`,
    hed: "Let’s stay Friends.",
  },
  bff1: {
    image: `https://static.bookofthemonth.com/Relationship/Icons/Badge/BFF_Icon.svg`,
    hed: "You’re still our BFF.",
  },
  bff2: {
    image: `https://static.bookofthemonth.com/Relationship/Icons/Badge/BFF_Icon.svg`,
    hed: "You’re still our BFF.",
  },
  bffholiday: {
    image: `//static.bookofthemonth.com/loyalty/logos/bff_badge.png`,
    hed: (
      <>
        Become a BFF in <span className={"nowrap"}>no time.</span>
      </>
    ),
    dek: (
      <>
        Rejoin for free today and get that much closer to BFF rewards{" "}
        <span className={"nowrap"}>and perks.</span>
      </>
    ),
  },
};

export const _ORIG_BBF_LOGIN_DATA: OldDataObjProps = {
  bff1: {
    hed: "We’ve missed you.",
    dek: "Ready to rejoin and continue your progress towards BFF status and perks?",
  },
  bff2: {
    hed: "We’ve missed you.",
    dek: "Rejoin today to reclaim your BFF status and perks.",
  },
  bff3: {
    hed: "We’ve missed you.",
    dek: "Rejoin today to reclaim your BFF perks, including your free birthday book.",
  },
  bffholiday: {
    hed: (
      <>
        Holiday deals <span className={"nowrap"}>are here.</span>
      </>
    ),
    dek: (
      <>
        Ready to rejoin and continue your progress toward BFF{" "}
        <span className={"nowrap"}>status and perks?</span>
      </>
    ),
  },
};

export const _TIER_LOGIN_DATA: TierDataObjProps = {
  friend: {
    hed: "We’ve missed you.",
    dek: "Rejoin today to reclaim your Friend status and perks.",
  },
  bff1: {
    hed: "We’ve missed you.",
    dek: "Rejoin today to reclaim your BFF status and perks.",
  },
  bff2: {
    hed: "We’ve missed you.",
    dek: "Rejoin today to reclaim your BFF status and get your free birthday book!",
  },
  bffholiday: {
    hed: (
      <>
        Holiday deals <span className={"nowrap"}>are here.</span>
      </>
    ),
    dek: (
      <>
        Ready to rejoin and continue your progress toward BFF{" "}
        <span className={"nowrap"}>status and perks?</span>
      </>
    ),
  },
};

export const _TIER_REJOIN_CONF_DATA = {
  Friend: {
    hed: (name?: string) => `Great to have you back, ${name}!`,
  },
  BFF: {
    hed: (name?: string) => `Welcome back to the BFF life, ${name}!`,
  },
};
