import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouterContext = React.createContext();

const RouterProvider = ({ children }) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    //--> it can be replaced with useRef or localStorage
    to: location?.pathname,
    from: location?.pathname,
  });
  const [categoryKey, setCategoryKey] = useState(null);

  useEffect(() => {
    setRoute((prev) => ({ to: location?.pathname, from: prev?.to }));
  }, [location]);

  return (
    <RouterContext.Provider value={{ ...route, categoryKey, setCategoryKey }}>
      {children}
    </RouterContext.Provider>
  );
};

export { RouterContext, RouterProvider };
