/**
 * Displays search result placeholders until search finishs.
 * Child of Search.jsx
 */
import React from "react";
import SkeletonWrapper, { P, Jacket } from "./SkeletonWrapper.jsx";

const SearchResultsSkeleton = ({ sectionTitle, className }) => (
  <SkeletonWrapper className="-searchResults">
    <h5 className="miniText -bold">{sectionTitle}</h5>
    <ul className={className}>
      {new Array(5).fill(0).map((item, i) => (
        <li className={sectionTitle === "Books" ? "-bookListItem" : ""} key={i}>
          {sectionTitle === "Books" ? <Jacket /> : <P />}
        </li>
      ))}
    </ul>
  </SkeletonWrapper>
);

export default SearchResultsSkeleton;
