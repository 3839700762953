/**
 * SiteMessageRejoin.jsx
 * This shows a banner for gift members to rejoin
 * Child of BOM.jsx
 */
import React from "react";

const SiteMessageRejoin = (props) => {
  //TODO: see whats up with this file in general, seems useless
  let { account, location } = props,
    messageContainer = <div />,
    showWhen = ["/rejoin"];
  if (
    showWhen.indexOf(location) < 0 ||
    !account ||
    !account.policy.isGift ||
    account.cycles === 0 ||
    account.cycles > 2
  ) {
    return null;
  } else if (account.cycles === 1) {
    //TODO: see if we can merge these two
    messageContainer = (
      <h5 className="col -w75 left knockout">
        It’s your last month! Extend your membership today and get 3 months for
        $9.99/mo + a free tote.
      </h5>
    );
  } else {
    messageContainer = (
      <h5 className="col -w75 left knockout">
        Hi {account.firstName}! Extend your membership today and get 3 months
        for $9.99/mo + a free tote.
      </h5>
    );
  }
  return (
    <div
      className={
        "siteMessage show" + (account.cycles > 0 ? " giftRejoinBanner" : "")
      }
    >
      <div
        className={
          "innerWrapper center" + (account.cycles <= 0 ? " special" : "")
        }
      >
        <div className="-guts">
          {messageContainer}
          <div className="col -w25 show">
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMessageRejoin;
