const makeDate = (dateString) => {
  if (dateString instanceof Date) {
    return dateString;
  }
  if (!dateString || typeof dateString !== "string") {
    return null;
  }
  const datePortion = dateString.split("T")[0];

  let [yyyy, mm, dd] = datePortion.split("-");
  mm = mm.padStart(2, 0);
  return new Date(`${yyyy}-${mm}-${dd}`);
};

export default makeDate;
