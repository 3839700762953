import React from "react";
import SkeletonWrapper, { P, IMG } from "./SkeletonWrapper.jsx";

const ReviewsList = () => (
  <ul className="reviewsWrapper">
    {new Array(2).fill(0).map((item, i) => (
      <SkeletonWrapper key={i} tagType="li">
        <div className="reviewHead">
          <div>
            <div className="-name">
              <P className="-rounded" />
            </div>
            <div className="-date">
              <P className="-rounded" />
            </div>
          </div>
          <div>
            <IMG className="-round" />
          </div>
        </div>
        <div className="-review">
          <P className="-rounded" />
          <P className="-rounded" />
        </div>
      </SkeletonWrapper>
    ))}
  </ul>
);

export default ReviewsList;
