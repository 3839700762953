/**
 * Loyalty modals content for login welcome.
 * Child of LoyaltyModal.jsx
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { markMessageAsSeen, createMessage } from "State/accountMessage/flows";
import { getMessages } from "State/accountMessage/selectors";
import { withStoreData } from "UI/hoc/withStoreData";

class LoyaltyWelcome extends Component {
  constructor(props) {
    super(props);
    this.goingToNextStep = false;
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    try {
      this.props.markMessageAsSeen(10);
    } catch (e) {
      console.error(e);
    }
  }

  next() {
    this.props.setContent("gift");
  }

  render() {
    let { storeData } = this.props;
    return (
      <div className="loyaltyStep -success">
        <img
          src={`${storeData.track.images}loyalty/logos/bff_badge.png`}
          alt="Book of the Month BFF Badge"
        />
        <div className="-content">
          <h4>Welcome to the fam!</h4>
          <p>
            BOTM BFFs are a special group of our top members who get sweet perks
            and gifts. It’s our way of saying thanks for your support.
          </p>
        </div>
        <div className="-actions">
          <button
            className="primary -flexible"
            onClick={this.next}
            style={{ marginBottom: "12px", width: "100%" }}
          >
            Claim my first gift
          </button>
          <p style={{ textAlign: "center " }}>
            <a onClick={() => this.props.setOverlay(null)}>Close</a>
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bffMessage: getMessages(state, "Bff welcome"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ markMessageAsSeen, createMessage }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(LoyaltyWelcome),
);
