/**
 * Reusable field set for Name, email,  display name, password and avatar.
 *
 */

import React, { useState, useRef, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOverlay } from "State/ui/creators";
import styled from "styled-components";
import DynamicInput from "UI/elements/form/DynamicInput";
import { validationRegExp } from "UI/elements/form/util";
import { Error } from "UI/elements/form/Error";
import { clearError } from "State/error/creators";
import { updateAccount, createAccount, checkEmail } from "State/account/flows";
import ClickTracker from "UI/elements/ClickTracker";
import TermsAgreementBlock from "UI/components/TermsAgreementBlock.tsx";
import { useWindowSize } from "UI/hooks";
import { withStoreData } from "UI/hoc/withStoreData";
import { push } from "connected-react-router";
import { RegionContext } from "UI/contexts/RegionContext";

const FieldsetAccountInfo = ({
  buttonText = "Save",
  account,
  top = 30,
  bottom = 20,
  overrideAction,
  onChangeTextCb,
  successCB,
  failCB,
  noButton,
  fieldsToShow = ["email", "password"],
  showSecondaryLink,
  joinData,
  overrideSubmitError,
  formId = "default",
  validFormCheckCB,
  noAutoComplete,
  logClickData,
  showTermsBlock,
  ...props
}) => {
  const { storeData } = props;
  const { store } = storeData;
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const { locationHeader, canadaMode } = useContext(RegionContext);

  const [oAccount, setOAccount] = useState({
    firstName: account?.firstName || joinData?.firstName || null,
    lastName: account?.lastName || joinData?.lastName || null,
    displayName: account?.displayName || joinData?.displayName || null,
    email: account?.email || joinData?.email || null,
    pictureUrl: account?.pictureUrl,
    password: "",
    passwordConf: "",
    trackId: 1,
    countryCode: locationHeader.countryHeader,
  });

  const [activeField, setActiveField] = useState("");
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [altIsSubmitting, setAltIsSubmitting] = useState(false);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const displayNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfRef = useRef(null);
  let formIsValid =
    (oAccount.firstName?.length >= 1 || !fieldsToShow.includes("firstName")) &&
    (oAccount.lastName?.length >= 1 || !fieldsToShow.includes("lastName")) &&
    (oAccount.email?.length >= 1 || !fieldsToShow.includes("email")) &&
    (oAccount.password?.length >= 1 || !fieldsToShow.includes("password")) &&
    (oAccount.passwordConf?.length >= 1 ||
      !fieldsToShow.includes("passwordConf")) &&
    invalidFields?.length <= 0;

  //Need a better way to handle links in shared error component.
  let errorToShow =
    !isSubmitting && !altIsSubmitting && props.error && !overrideSubmitError ? (
      <StyledErrorWrapper>
        <Error message={props.error.error} top={0} bottom={30} />
      </StyledErrorWrapper>
    ) : null;

  if (
    props.error.error == "Looks like you already have an account." &&
    !props.landingPage &&
    !overrideSubmitError
  ) {
    errorToShow = (
      <StyledErrorWrapper>
        <div className="error">
          <p>{props.error.error}</p>
          <p>
            &nbsp;Please{" "}
            <a href="https://www.bookofthemonth.com/login" className="link">
              log in here
            </a>
            .
          </p>
        </div>
      </StyledErrorWrapper>
    );
  }

  if (overrideSubmitError) {
    errorToShow = (
      <StyledErrorWrapper>
        <Error message={overrideSubmitError} top={0} bottom={30} />
      </StyledErrorWrapper>
    );
  }

  useEffect(() => {
    props.clearError();
  }, []);

  useEffect(() => {
    const formIsValid = checkIfFormIsValid();
    if (validFormCheckCB) {
      validFormCheckCB(formIsValid);
    }
  }, [oAccount, account]);

  useEffect(() => {
    if (account) {
      const accountObj = {
        firstName: account?.firstName || null,
        lastName: account?.lastName || null,
        displayName: account?.displayName || null,
        email: account?.email || null,
        pictureUrl: account?.pictureUrl,
        password: "",
        passwordConf: "",
        trackId: 1,
        countryCode: locationHeader.countryHeader,
      };
      setOAccount(accountObj);
    }
  }, [account]);

  useEffect(() => {
    if (props.error) {
      setIsSubmitting(false);
    }
  }, [props.error]);

  const validateInputData = (field, data, fromBlur = false) => {
    if (
      oAccount?.[field]?.length &&
      oAccount?.[field].replace(/\s/g, "").length == 0
    ) {
      return false;
    } else if (fromBlur && oAccount?.[field]?.length < 1) {
      clearErrorField(field);
      return true;
    } else {
      if (field == "passwordConf") {
        return true;
      }
      return validationRegExp?.[field]?.test(data || oAccount?.[field]);
    }
  };

  const checkIfFormIsValid = () => {
    let fieldIsValid = true;
    let invalidFormFields = [];
    fieldsToShow.map((f) => {
      fieldIsValid = checkIfValid(f, oAccount[f]);
      if (!fieldIsValid) {
        invalidFormFields.push(f);
      }
    });
    if (invalidFormFields?.length >= 1) {
      return false;
    } else return true;
  };

  const checkIfValid = (field, data) => {
    const isValid = validateInputData(field, data);
    if (isValid && invalidFields.includes(field)) {
      clearErrorField(field);
      if (["password", "passwordConf"].includes(field)) {
        checkPasswordMatch(field);
      }
    }
    return isValid;
  };

  const getAndSetInvalidFields = (errorField) => {
    if (!invalidFields.includes(errorField)) {
      setInvalidFields([errorField, ...invalidFields]);
    }
    setIsSubmitting(false);
    if (failCB) {
      setIsSubmitting(false);
      failCB();
    }
  };

  const checkPasswordMatch = (field) => {
    const pArray = ["password", "passwordConf"];
    if (
      pArray?.includes(field) &&
      pArray.every((f) => fieldsToShow.includes(f)) &&
      oAccount.password?.length >= 1 &&
      oAccount.passwordConf?.length >= 1 &&
      oAccount.password != oAccount.passwordConf
    ) {
      if (oAccount.password != oAccount.passwordConf) {
        getAndSetInvalidFields("passwordConf");
      } else if (invalidFields.includes("passwordConf")) {
        clearErrorField("passwordConf");
      }
    }
  };

  const clearErrorField = (field) => {
    if (invalidFields.includes(field)) {
      const newInvalidFields = invalidFields.filter((f) => f !== field);
      setInvalidFields(newInvalidFields);
    }
  };

  const handleInputChange = (f, v) => {
    props.clearError();
    setIsSubmitting(false);
    let oUpdate = oAccount;
    oUpdate[f] = v;
    setOAccount({ ...oUpdate });
    checkIfValid(f, v);
    if (onChangeTextCb) {
      onChangeTextCb({ ...oAccount });
    }
  };

  const onBlur = (field) => {
    setActiveField("");
    const fieldIsVaild = validateInputData(field, null, true);
    if (!fieldIsVaild) {
      getAndSetInvalidFields(field);
    } else {
      checkPasswordMatch(field);
    }
    const isValid = checkIfFormIsValid();
    if (validFormCheckCB) {
      validFormCheckCB(isValid);
    }
  };

  const clearIt = (field) => {
    clearErrorField(field);
    let oUpdate = oAccount;
    oUpdate[field] = "";
    setOAccount({ ...oAccount });
  };

  const buttonAction = async (evt, isAlt = false) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const successFn = () => {
      if (successCB) {
        successCB(oAccount, isAlt);
      }
      setIsSubmitting(false);
      setAltIsSubmitting(false);
    };

    const failFn = (e) => {
      //e obj => { error: e.message, type: 'password' }
      console.log("logged- hitting fail e:", e);
      setIsSubmitting(false);
      setAltIsSubmitting(false);
      // getAndSetInvalidFields(e?.type);
      // setActiveField(e?.type);
      if (failCB) {
        setIsSubmitting(false);
        failCB(e);
      }
    };

    let errorField = fieldsToShow.find((field) => !validateInputData(field));
    if (errorField) {
      getAndSetInvalidFields(errorField);
    } else {
      if (isAlt) {
        setAltIsSubmitting(true);
      } else {
        setIsSubmitting(true);
      }

      try {
        let isUser = false;
        if (overrideAction) {
          overrideAction(oAccount);
        } else {
          if (account && account.id) {
            await props?.updateAccount(
              oAccount,
              store,
              successFn,
              failFn,
              showTermsBlock,
            );
          } else {
            await props.createAccount(
              {
                ...oAccount,
                trackId: storeData?.track?.id,
                countryCode: locationHeader.countryHeader,
              },
              successFn,
              failFn,
              showTermsBlock,
            );
          }
        }
      } catch (e) {
        console.log("ERROR-->", e);
        failFn(e);
      }
    }
  };

  return (
    <StyledAccountFormWrapper
      top={top}
      bottom={bottom}
      id={formId}
      onSubmit={(evt) => {
        evt.preventDefault();
        buttonAction(evt);
      }}
    >
      {errorToShow}
      {fieldsToShow.includes("firstName") && (
        <DynamicInput
          placeholder={activeField == "firstName" ? "Jane" : "First name"}
          label="First name"
          value={oAccount.firstName}
          accessibilityLabel={`First name`}
          onChangeText={(v) => {
            handleInputChange("firstName", v);
          }}
          onFocus={() => setActiveField("firstName")}
          onBlur={() => onBlur("firstName")}
          isInFocus={activeField == "firstName"}
          clearIt={() => clearIt("firstName")}
          error={
            invalidFields.includes("firstName")
              ? "Please enter a valid first name."
              : null
          }
          forwardRef={firstNameRef}
          required={"required"}
          name={"given-name"}
          inputId={"given-name-" + formId}
          autoComplete={noAutoComplete ? "off" : "given-name"}
          size={isMobile ? "full" : "half"}
        />
      )}

      {fieldsToShow.includes("lastName") && (
        <DynamicInput
          placeholder={activeField == "lastName" ? "Doe" : "Last name"}
          label="Last name"
          value={oAccount.lastName}
          accessibilityLabel={`Last name`}
          onChangeText={(v) => {
            handleInputChange("lastName", v);
          }}
          onFocus={() => setActiveField("lastName")}
          onBlur={() => onBlur("lastName")}
          isInFocus={activeField == "lastName"}
          clearIt={() => clearIt("lastName")}
          error={
            invalidFields.includes("lastName")
              ? "Please enter a valid last name."
              : null
          }
          forwardRef={lastNameRef}
          required={"required"}
          name={"family-name"}
          inputId={"family-name-" + formId}
          autoComplete={noAutoComplete ? "off" : "family-name"}
          size={isMobile ? "full" : "half"}
        />
      )}

      {fieldsToShow.includes("displayName") && (
        <DynamicInput
          placeholder={
            activeField == "displayName" ? "Bookie Monster" : "Display name"
          }
          label="Display name"
          value={oAccount.displayName}
          accessibilityLabel={`Display name`}
          onChangeText={(v) => {
            handleInputChange("displayName", v);
          }}
          onFocus={() => setActiveField("displayName")}
          onBlur={() => onBlur("displayName")}
          isInFocus={activeField == "displayName"}
          clearIt={() => clearIt("displayName")}
          error={
            invalidFields.includes("displayName")
              ? "Please enter a valid display name."
              : null
          }
          forwardRef={displayNameRef}
          required={"required"}
          name={"userName"}
          inputId={"userName-" + formId}
          autoComplete={noAutoComplete ? "off" : "userName"}
          size={"full"}
        />
      )}

      {fieldsToShow.includes("email") && (
        <DynamicInput
          placeholder={
            activeField == "email" ? "email@mail.com" : "Email address"
          }
          label="Email address"
          value={oAccount.email}
          accessibilityLabel={`Email address`}
          onChangeText={(v) => {
            handleInputChange("email", v);
          }}
          noSpaces
          onFocus={() => setActiveField("email")}
          onBlur={() => onBlur("email")}
          isInFocus={activeField == "email"}
          clearIt={() => clearIt("email")}
          error={
            invalidFields.includes("email")
              ? "Please enter a valid email."
              : null
          }
          forwardRef={emailRef}
          required={"required"}
          name={"email"}
          inputId={"email-" + formId}
          autoComplete={noAutoComplete ? "off" : "email"}
          size={"full"}
        />
      )}

      {fieldsToShow.includes("password") && (
        <DynamicInput
          placeholder={activeField == "password" ? "" : "Password"}
          label="Password"
          value={oAccount.password}
          accessibilityLabel={`Password`}
          onChangeText={(v) => {
            handleInputChange("password", v);
          }}
          noSpaces
          onFocus={() => setActiveField("password")}
          onBlur={() => onBlur("password")}
          isInFocus={activeField == "password"}
          clearIt={() => clearIt("password")}
          error={
            invalidFields.includes("password")
              ? "Please enter a valid password."
              : null
          }
          forwardRef={passwordRef}
          required={"required"}
          name={"password"}
          inputId={"password-" + formId}
          autoComplete={noAutoComplete ? "off" : "password"}
          inputType={"password"}
          size={"full"}
          secureTextEntry
        />
      )}

      {fieldsToShow.includes("passwordConf") && (
        <DynamicInput
          placeholder={activeField == "passwordConf" ? "" : "Confirm password"}
          label="Confirm Password"
          value={oAccount.passwordConf}
          accessibilityLabel={`Confirm password`}
          onChangeText={(v) => {
            handleInputChange("passwordConf", v);
          }}
          noSpaces
          onFocus={() => setActiveField("passwordConf")}
          onBlur={() => onBlur("passwordConf")}
          isInFocus={activeField == "passwordConf"}
          clearIt={() => clearIt("passwordConf")}
          error={
            invalidFields.includes("passwordConf")
              ? "Password does not match."
              : null
          }
          forwardRef={passwordConfRef}
          required={"required"}
          name={"passwordConf"}
          inputId={"passwordConf-" + formId}
          autoComplete={noAutoComplete ? "off" : "passwordConf"}
          inputType={"password"}
          size={"full"}
          secureTextEntry
        />
      )}
      {showTermsBlock && (
        <TermsAgreementBlock
          knockout={showTermsBlock == "isKnockedout"}
          cta={`${buttonText}${
            showSecondaryLink ? '" or "Pick a book later' : ""
          }`}
          canShowCanadaCopy={true}
          isCentered
          top={0}
          mustShowTerms={true}
        />
      )}
      {!noButton && (
        <StyledActionsWrapper>
          <ClickTracker
            ctaType={"button"}
            id="account-submit"
            style={"primary fullWidth"}
            handleClick={formIsValid ? (evt) => buttonAction(evt) : null}
            isPending={isSubmitting}
            title={isSubmitting ? "Updating..." : buttonText}
            isDisabled={!formIsValid}
            logClickData={logClickData}
            type={"submit"}
          />
        </StyledActionsWrapper>
      )}

      {showSecondaryLink && (
        <StyledLinkActionsWrapper>
          <p className="center" style={{ marginBottom: "10px" }}>
            or
          </p>
          <div className="center">
            <ClickTracker
              ctaType={"link"}
              tabIndex={0}
              id="pick-later-link"
              handleClick={(evt) => buttonAction(evt, true)}
              isPending={altIsSubmitting}
              title={"Pick a book later"}
              logClickData={"Clicked- Pick a book later"}
              size={18}
              isCentered
            />
          </div>
        </StyledLinkActionsWrapper>
      )}
    </StyledAccountFormWrapper>
  );
};

export const StyledAccountFormWrapper = styled.form.attrs({
  className: "sc-StyledAccountFormWrapper",
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
`;

export const StyledActionsWrapper = styled.div.attrs({
  className: "sc-StyledActionsWrapper",
})`
  flex: 1;
`;

export const StyledErrorWrapper = styled.div.attrs({
  className: "sc-StyledErrorWrapper",
})`
  width: 100%;
`;

export const StyledLinkActionsWrapper = styled.div.attrs({
  className: "sc-StyledLinkActionsWrapper",
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
`;

function mapStateToProps(state) {
  return {
    store: state,
    account: state.account,
    joinData: state.joinData,
    error: state.error,
    storeState: {
      getState: () => {
        let { analytics, account, store, joinData } = state;
        return { analytics, account, store, joinData };
      },
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setOverlay, updateAccount, createAccount, checkEmail, clearError, push },
    dispatch,
  );
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(FieldsetAccountInfo),
);
