import React, { useContext } from "react";
import styled from "styled-components";
import { RegionContext } from "../../contexts/RegionContext";
import { connect } from "react-redux";
import { AppState } from "../../../types";
import getCanadianImg from "../../../utils/getCanadianImg";
import { PdpProductImage } from "../../../../../types/product";

type ProductImageProps = {
  img?: string;
  imgs?: PdpProductImage[];
  title?: string;
  size?:
    | "xLarge"
    | "large"
    | "medium"
    | "medium-nonResponsive"
    | "small"
    | "mini"
    | "responsive"
    | "custom"
    | "authorProfile"
    | "authorsCarousel";
  noShadow?: boolean;
  hasBorder?: boolean;
  isActive?: boolean;
  top?: number;
  bottom?: number;
  custom?: any;
  flag?: null | string | JSX.Element;
  path?: string;
  onClick?: (...args: any) => void;
  noWebp?: boolean;
} & PropsFromState;

const UnconnectedProductImage: React.FC<ProductImageProps> = ({
  img = "",
  imgs = [],
  title = "",
  size = "medium",
  noShadow = false,
  hasBorder = false,
  isActive = false,
  top = 0,
  bottom = 0,
  custom,
  flag,
  path = `//static.bookofthemonth.com/covers/list/`,
  onClick,
  noWebp,
  account,
}) => {
  //TODO: remove this once Toronto Drift is done.
  const { canadaMode } = useContext(RegionContext);
  const isCanada = account?.countryCode
    ? account?.countryCode == "CA"
    : canadaMode;

  if (!img && (!imgs || !imgs.length)) {
    return null;
  }
  if (imgs && imgs.length === 1) {
    img = imgs[0].img;
  } else {
    //Drift -- check attributes for correct image & replaced displayImg below
  }
  const displayImg = getCanadianImg(img, isCanada);

  return (
    <StyledJacketWrapper
      id={"bookJacketWrapper"}
      className={`${size}`}
      custom={custom}
      isActive={isActive}
      top={top}
      bottom={bottom}
    >
      <StyledImage
        noShadow={noShadow}
        hasBorder={hasBorder}
        isActive={isActive}
        onClick={onClick}
      >
        {noWebp ? null : (
          <source srcSet={`${path}${displayImg}.webp`} type="image/webp" />
        )}
        <source srcSet={`${path}${displayImg}.jpg`} type="image/jpg" />
        <img
          src={`${path}${displayImg}.jpg`}
          alt={title}
          className={`${size}`}
        />
      </StyledImage>
      {flag && flag}
    </StyledJacketWrapper>
  );
};

interface IStyledJacketWrapper {
  noShadow?: boolean;
  isActive?: boolean;
  top?: number;
  bottom?: number;
  custom?: any;
}

interface IStyledImage {
  noShadow?: boolean;
  hasBorder?: boolean;
  isActive?: boolean;
  top?: number;
  bottom?: number;
  custom?: any;
}

type PropsFromState = Pick<AppState, "account">;

const mapStateToProps = (state: AppState): PropsFromState => ({
  account: state.account,
});

export const ProductImage = connect(mapStateToProps)(UnconnectedProductImage);

const StyledJacketWrapper = styled.div<IStyledJacketWrapper>`
  position: relative;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  background: linear-gradient(135deg, #fafafa 27%, #ededed 100%);
  outline: ${(props) =>
    props.isActive ? `2px solid var(--color-primary5)` : `none`};

  &.authorProfile {
    width: 153px;
    height: 153px;
  }
  &.xLarge {
    width: 200px;
    height: 300px;
    @media screen and (max-width: 480px) {
      width: 152px;
      height: 228px;
    }
  }

  &.large {
    width: 152px;
    height: 228px;
    @media screen and (max-width: 480px) {
      width: 120px;
      height: 180px;
    }
  }
  &.medium {
    width: 120px;
    height: 180px;
    @media screen and (max-width: 480px) {
      width: 96px;
      height: 144px;
    }
  }
  &.small {
    width: 96px;
    height: 144px;
  }
  &.mini {
    width: 56px;
    height: 84px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 860px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      width: 11vh;
      height: 16vh;
    }
  }
  ${(props) => props.custom || null}
`;

const StyledImage = styled.picture<IStyledImage>`
  img {
    border: ${(props) =>
      props.hasBorder ? `1px solid var(--color-gray2)` : `none`};
    &.authorProfile {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `4px 4px 12px 0px rgba(34,34,34, 0.2)`};
      width: 153px;
      height: 153px;
    }
    &.xLarge {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `4px 4px 12px 0px rgba(34,34,34, 0.2)`};
      width: 200px;
      height: 300px;
      @media screen and (max-width: 480px) {
        width: 152px;
        height: 228px;
      }
    }
    &.large {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `4px 4px 12px 0px rgba(34,34,34, 0.2)`};
      width: 152px;
      height: 228px;
      @media screen and (max-width: 480px) {
        width: 120px;
        height: 180px;
      }
    }
    &.medium {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `2px 2px 12px 0px rgba(34,34,34, 0.2)`};
      width: 120px;
      height: 180px;
      @media screen and (max-width: 480px) {
        width: 96px;
        height: 144px;
      }
    }
    &.medium-nonResponsive {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `4px 4px 12px 0px rgba(34,34,34, 0.2)`};
      width: 120px;
      height: 180px;
    }
    &.small {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `2px 2px 8px 0px rgba(34, 34, 34, 0.24)`};
      width: 96px;
      height: 144px;
    }

    &.mini {
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `2px 2px 8px 0px rgba(34, 34, 34, 0.24)`};
      width: 56px;
      height: 84px;
      @media only screen and (min-device-width: 768px) and (max-device-width: 860px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        width: 11vh;
        height: 16vh;
      }
    }

    &.responsive {
      width: 100%;
      display: block;
      object-fit: contain;
      align-self: center;
      box-shadow: ${(props) =>
        props.noShadow
          ? `transparent`
          : `2px 2px 8px 0px rgba(34, 34, 34, 0.24)`};
    }
  }
`;
