import React, { useState } from "react";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import ApplePayButton, { ApplePayButtonTypes } from "./ApplePayButton";
import { IPaymentRequestWeb } from "../../../../../types/apple_pay";
import { canUseApplePay } from "../../../utils/apple_pay/canUseApplePay";
import { payWithApplePay } from "../../../utils/apple_pay/payWithApplePay";
import { getBTToken } from "../../../state/memberPaymentMethod";
import ClickTracker from "../../../ui/elements/ClickTracker";
import braintree from "braintree-web";
import { setOverlay } from "../../../state/ui/creators";
import { Dispatch } from "../../../types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

export interface ApplePayBlockProps {
  top?: number;
  bottom?: number;
  buttonStyle?: "default" | "fat";
  cta: ApplePayButtonTypes;
  nonSupportedButtonCta?: string;
  paymentRequestObj?: IPaymentRequestWeb; // to build this obj create function simular to createApplePayRequest in snes
  paymentForFn: (nounce: string) => void;
  setOverlay: (overlay: string, title: string, data?: any) => void;
  showingFallBackCB?: (v: boolean) => void;
  norCheckboxAction?: () => void;
  btnIsDisabled?: boolean;
}

const ApplePayBlock: React.FC<ApplePayBlockProps> = ({
  top,
  bottom,
  buttonStyle,
  cta,
  paymentRequestObj,
  paymentForFn,
  nonSupportedButtonCta,
  setOverlay,
  showingFallBackCB,
  norCheckboxAction,
  btnIsDisabled,
}) => {
  const canSeeApplePay = canUseApplePay(); //Checks supported device and browser.
  const [applePayInstance, setApplePayInstance] = useState(null);

  useAsyncEffect(async () => {
    const braintreeToken: any = await getBTToken();
    let createdApplePayInstance: any = null;
    let clientInstance = null;
    if (canSeeApplePay) {
      try {
        clientInstance = await braintree.client.create({
          authorization: braintreeToken,
        });
      } catch (clientErr) {
        console.error("Error creating client:", clientErr);
        return;
      }
      try {
        createdApplePayInstance = await braintree.applePay.create({
          client: clientInstance,
        });
      } catch (applePayErr) {
        console.error("Error creating applePayInstance:", applePayErr);
        return;
      }
    }

    let hasActiveCard = false;
    hasActiveCard = window.ApplePaySession
      ? await ApplePaySession.canMakePaymentsWithActiveCard(
          createdApplePayInstance?.merchantIdentifier,
        )
      : false;

    return () => {
      if (createdApplePayInstance && hasActiveCard === true) {
        setApplePayInstance(createdApplePayInstance);
      } else if (showingFallBackCB) {
        showingFallBackCB(true);
      }
    };
  }, []);

  const applePayAction = () => {
    if (paymentRequestObj) {
      payWithApplePay(applePayInstance, paymentRequestObj, paymentForFn);
    } else {
      console.log(
        "applepay test logging - WS ApplePayBlock applePayAction no paymentRequestObj!!!!",
      );
      //handle error in error handling ticket?
    }
  };

  const applePayButton = (
    <ApplePayButton
      top={top}
      bottom={bottom}
      buttonStyle={buttonStyle}
      applePayInstance={applePayInstance}
      cta={cta}
      ctaAction={norCheckboxAction || applePayAction}
    />
  );

  if (applePayInstance) {
    return (
      <div style={{ width: "100%" }} data-test="apple-pay-block">
        {applePayButton}
      </div>
    );
  } else {
    return (
      <ClickTracker
        ctaType={"button"}
        id="apple-pay-unsupported-cta"
        style={`primary fullWidth ${
          norCheckboxAction || btnIsDisabled ? "-disabled" : ""
        }`}
        handleClick={
          norCheckboxAction
            ? norCheckboxAction
            : () =>
                setOverlay("editAccount", "", {
                  type: "unsupported Apple Pay device",
                  header: "Sorry! Your Apple Pay payment is not processing.",
                  dek: "Please update your payment method or try one of the below actions.",
                  finePrint:
                    "This card will replace your current default payment method.",
                })
        }
        title={nonSupportedButtonCta}
        isDisabled={false}
        logClickData={"clicks_unsupported_applepay_cta"}
        bottom={20}
      />
    );
  }
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(null, mapDispatchToProps)(ApplePayBlock);
