import { useContext } from "react";
import { RegionContext } from "../contexts/RegionContext";

type countryCodeType = "US" | "CA";

const useRegion = () => {
  const { canadaMode, locationHeader } = useContext(RegionContext);
  // countryCode set here to be either CA || US...
  // locationHeader.countryHeader can be something other than US/CA.
  // anyone NOT in CA will be US for countryCode
  const countryCode: countryCodeType = canadaMode ? "CA" : "US";

  return { canadaMode, locationHeader, countryCode };
};

export { useRegion };
