import React, { useState } from "react";
import { TBotyFinalist } from "../../../../types/boty";
import * as backend from "../../state/boty/api";

export type BotyContextProps = {
  children?: JSX.Element;
  botyFinalists: TBotyFinalist[];
  getBotyFinalists: () => void;
  isLoading: boolean;
  bffShipsFreeBotyImages: { desktop: string; mobile: string };
  bffShipsFreeBotyWebstoreImages: () => void;
};

type TBffShipsFreeBotyWebstoreImages = {
  desktop: string;
  mobile: string;
};

const defaultState: BotyContextProps = {
  botyFinalists: [],
  getBotyFinalists: () => {},
  isLoading: false,
  bffShipsFreeBotyImages: { desktop: "", mobile: "" },
  bffShipsFreeBotyWebstoreImages: () => {},
};

const BotyContext = React.createContext<BotyContextProps>(defaultState);

const BotyProvider: React.FC<[BotyContextProps]> = ({ children }) => {
  const [botyFinalists, setBotyFinalists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [bffShipsFreeBotyImages, setBffShipsFreeBotyImages] =
    useState<TBffShipsFreeBotyWebstoreImages>({
      mobile: "not_empty",
      desktop: "not_empty",
    });

  const bffShipsFreeBotyWebstoreImages = async () => {
    const images = await backend.bffShipsFreeBotyWebstoreImages(2023);
    setBffShipsFreeBotyImages(images);
  };

  const getBotyFinalists = async () => {
    if (!isLoading && (!botyFinalists || botyFinalists.length == 0)) {
      try {
        const finalists = await backend.getFinalists(2023);
        setIsLoading(false);
        if (finalists) {
          setBotyFinalists(finalists);
        }
      } catch (e) {
        setIsLoading(false);
        console.warn("TCL: getFinalists -> e", e);
      }
    }
  };

  return (
    <BotyContext.Provider
      value={{
        botyFinalists,
        getBotyFinalists,
        isLoading,
        bffShipsFreeBotyImages,
        bffShipsFreeBotyWebstoreImages,
      }}
    >
      {children}
    </BotyContext.Provider>
  );
};

export { BotyProvider, BotyContext };
