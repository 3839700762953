import React from "react";
import { Route } from "react-router-dom";
import loadable from "@loadable/component";
import LoadingCoinSkeleton from "../ui/loadingSkeletons/LoadingCoinSkeleton";

const HelpCenter = loadable(() => import("../ui/pages/help/HelpCenter"), {
  fallback: <LoadingCoinSkeleton />,
});
const Unskip = loadable(() => import("../ui/pages/help/Unskip"), {
  fallback: <LoadingCoinSkeleton />,
});
const CancelPause = loadable(() => import("../ui/pages/help/CancelPause"), {
  fallback: <LoadingCoinSkeleton />,
});
const HelpCenterCategory = loadable(
  () => import("../ui/pages/help/HelpCenterCategory"),
  { fallback: <LoadingCoinSkeleton /> },
);
const ArticlePage = loadable(() => import("../ui/pages/help/ArticlePage"), {
  fallback: <LoadingCoinSkeleton />,
});
const RequestForm = loadable(() => import("../ui/pages/help/RequestForm"), {
  fallback: <LoadingCoinSkeleton />,
});
const MessageReceivedConfirmation = loadable(
  () => import("../ui/pages/help/MessageReceivedConfirmation"),
  { fallback: <LoadingCoinSkeleton /> },
);

const HelpCenterRoutes = (() => [
  <Route exact path="/help-center" component={HelpCenter} />,
  <Route exact path="/help-center/unskip" component={Unskip} />,
  <Route exact path="/help-center/cancel-pause" component={CancelPause} />,
  <Route exact path="/help-center/request-form" component={RequestForm} />,
  <Route
    exact
    path="/help-center/request-form/confirmation-message"
    component={MessageReceivedConfirmation}
  />,
  <Route exact path="/help-center/:path" component={HelpCenterCategory} />,
  <Route exact path="/help-center/:path/:article" component={ArticlePage} />,
])();

export default HelpCenterRoutes;
