import React from "react";
import SkeletonWrapper, { P, InvisibleBlock } from "./SkeletonWrapper.jsx";

const UserFlowsSkeleton = () => (
  <SkeletonWrapper className="emailGate">
    <div className="content -narrow">
      <P />
      <P />
      <P />
      <InvisibleBlock width="auto" height={300} />
    </div>
  </SkeletonWrapper>
);

export default UserFlowsSkeleton;
