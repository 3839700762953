import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import LogoBom from "UI/elements/LogoBom.jsx";
import { ProductImage } from "UI/elements/product/ProductImage";
import { Avatar } from "UI/components/account/Avatar";

const RecommendEmail = (props) => {
  const {
    overlay,
    account,
    showMemberReview,
    memberReview,
    store,
    appFeatures,
  } = props;

  const connections = appFeatures?.find((f) => f?.feature == "Connections");
  const socialUser =
    (connections?.status == "Beta" && props?.account?.isBeta) ||
    connections?.status == "Active";

  let accountAvatar;
  if (socialUser) {
    accountAvatar = account?.profileImg ? account?.profileImg : "";
  } else {
    accountAvatar = account?.pictureUrl ? account?.pictureUrl : "";
  }

  return (
    <StyledEmailWrapper>
      <LogoBom />
      <ProductImage
        imgs={overlay?.data?.images}
        size={"small"}
        title={`${overlay?.data?.pdpTitle}`}
        top={20}
        bottom={30}
      />
      <h2 className="subhead">Your friend thinks you’ll like this!</h2>
      {memberReview && showMemberReview && (
        <StyledMessageBlock id={"StyledMessageBlock"}>
          <Avatar
            showEdit={false}
            size={60}
            bottom={20}
            avatarImage={accountAvatar}
            isSocial={socialUser}
          />
          <h5 style={{ marginBottom: 5 }}>{account?.firstName} says...</h5>
          <p className="essay">
            <em>“{memberReview}”</em>
          </p>
        </StyledMessageBlock>
      )}
      <p>
        On top of snagging this book for only{" "}
        <strong>${store?.data?.referralPlan?.price}</strong>, you also might
        move higher on {account?.firstName}’s friend list, just for redeeming
        their referral. It’s a win-win.
      </p>
      <button className="primary -fulWidth">Redeem offer now</button>
    </StyledEmailWrapper>
  );
};

export const StyledEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .subhead {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  svg {
    width: 200px;
  }
  .jacketWrapper {
    max-width: 160px;
    margin: 20px auto 30px;
    box-shadow: 0px 4px 15px 0 rgba(41, 41, 51, 0.3);
  }
  button {
    margin-top: 20px;
    width: 234px;
    cursor: default;
  }
  @media screen and (max-width: 680px) {
    .subhead {
      margin-top: 20px;
      margin-bottom: 8px;
    }
  }
`;

export const StyledMessageBlock = styled.div`
  width: 100%;
  margin: 20px auto;
  border-top: 1px solid var(--color-gray2);
  border-bottom: 1px solid var(--color-gray2);
  padding: 30px 0;
  img {
    width: 75px;
    height: 75px;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
    store: state.store,
    appFeatures: state?.appFeatures || [],
  };
}

export default connect(mapStateToProps)(RecommendEmail);
