/*
 * Component to handle Apple Pay
 */
import { IPaymentRequestWeb } from "../../../../types/apple_pay";

export const payWithApplePay = async (
  applePayInstance: any,
  paymentRequestObj: IPaymentRequestWeb,
  paymentForFn: (nonce: string) => void,
) => {
  const applePaymentRequest =
    applePayInstance.createPaymentRequest(paymentRequestObj);

  const session = new ApplePaySession(3, applePaymentRequest);

  session.onvalidatemerchant = async function (event) {
    try {
      const merchantSession = await applePayInstance.performValidation({
        validationURL: event.validationURL,
        displayName: "Book of the Month",
      });
      session.completeMerchantValidation(merchantSession);
    } catch (validationErr) {
      // You should show an error to the user, e.g. 'Apple Pay failed to load.'
      console.error("Error validating merchant:", validationErr);
      session.abort();
    }
  };

  // TODO: Not 100% sure if ever need to do something here. Don't think so but leaving in for now.
  // session.onshippingcontactselected = function (event) {};

  session.onpaymentauthorized = async function (event) {
    try {
      const payload = await applePayInstance.tokenize({
        token: event.payment.token,
      });
      // Function to call, ie: join
      paymentForFn(payload.nonce);

      session.completePayment(ApplePaySession.STATUS_SUCCESS);
    } catch (tokenizeErr) {
      console.error("Error tokenizing Apple Pay:", tokenizeErr);
      session.completePayment(ApplePaySession.STATUS_FAILURE);
    }
  };

  session.oncancel = async function (event) {
    if (process.env.NEXT_PUBLIC_LOAD_SNIPPET === "true") {
      console.log(`logged click tracking: close-apple-pay-sheet`);
      // log click needed here.
    }
  };

  session.begin();
};
