import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts";
import { Button } from "../../elements/form/Button";
import { Error } from "../../elements/form/Error";
import { Avatar } from "../account/Avatar";
import { ModalEditWrapper } from "../structure/ModalEditWrapper";

// @TODO: Need account props and save function passed in as props.
interface IEditAvatar {
  updateAvatar?: (av?: string) => void;
  accountAvatar?: string;
  error?: string;
  hideHeader?: boolean;
  showPreview?: boolean;
  buttonText?: string;
}

export const EditAvatar: React.FC<IEditAvatar> = ({
  updateAvatar,
  accountAvatar,
  error,
  hideHeader,
  showPreview,
  buttonText,
}) => {
  const avatars = [
    "Happy",
    "Teen",
    "Family_Drama",
    "Slow_Build",
    "Police",
    "Snarky",
    "Unlikeable_Narrator",
    "Supernatural",
    "Buzzy",
    "Rural",
    "Scary",
    "Murder",
  ];

  const initialAvatar = accountAvatar ? accountAvatar : "Happy";
  const [selectedAvatar, setSelectedAvatar] = useState(initialAvatar);
  const [pending, setPending] = useState(false);

  return (
    <ModalEditWrapper header={hideHeader ? "" : "Customize avatar"}>
      {error && <Error message={error} />}
      {showPreview && (
        <StyledPreviewWrapper>
          <Avatar
            showEdit={false}
            size={200}
            imgSize={200}
            left={0}
            handleClick={() => null}
            top={0}
            bottom={0}
            avatarImage={selectedAvatar}
          />
        </StyledPreviewWrapper>
      )}

      <StyledIconsWrapper>
        {avatars.map((icon: string, index: number) => (
          <li
            className={`${selectedAvatar == icon ? "active" : "nonactive"}`}
            key={index}
            onClick={() => setSelectedAvatar(icon)}
            tabIndex={0}
            onKeyPress={() => setSelectedAvatar(icon)}
          >
            <img
              alt="avatar"
              src={`//static.bookofthemonth.com/Bookmojis/${icon}.svg`}
            />
          </li>
        ))}
      </StyledIconsWrapper>

      <Button
        id="edit avatar"
        style="primary fullWidth"
        handleClick={() => {
          setPending(true);
          updateAvatar?.(selectedAvatar);
        }}
        isPending={pending}
        title={
          pending ? "Updating..." : buttonText ? buttonText : "Save changes"
        }
      />
    </ModalEditWrapper>
  );
};

export const StyledIconsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  li {
    border: 2px solid var(--color-white);
    &.active {
      border: 2px solid var(--color-primary5);
    }
    &:hover {
      cursor: hand;
      cursor: pointer;
    }
  }
  img {
    display: block;
    width: 100px;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    img {
      width: 80px;
    }
  }
`;

export const StyledPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    display: block;
    width: 200px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    img {
      width: 180px;
      margin-bottom: 12px;
    }
  }
`;
