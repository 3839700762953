import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { getMessages } from "State/accountMessage/selectors";
import { boxCount } from "State/box/selectors";
import { setOverlay } from "State/ui/creators";
import BoxIcon from "UI/elements/BoxIcon.jsx";
import { withRegion } from "UI/hoc";
import { COLORS } from "CSS/Consts";
import { snesRedirect } from "Utils/snesRedirect";

class BoxNav extends Component {
  constructor() {
    super();
  }

  render() {
    let { boxCount, account, trueNorthFeature, canadaMode, isDarkMode } =
      this.props;

    let boxActive = this.props.path === "/box";
    let boxItemCount = boxCount;
    const showTrueNorthFeature =
      (trueNorthFeature?.status == "Beta" &&
        trueNorthFeature?.isBetaEligible) ||
      trueNorthFeature?.status == "Active";
    const shouldShowBoxNav =
      !canadaMode || (canadaMode && showTrueNorthFeature);
    const boxIcon = (
      <BoxIcon
        boxItems={boxItemCount}
        color={isDarkMode ? COLORS.offwhite1 : COLORS.black}
        label={
          account && account.firstName !== null
            ? account.firstName + "’s box"
            : "Your box"
        }
      />
    );

    const boxNav = (
      <Fragment>
        <a
          className={`desktopLink ${boxActive ? "active" : ""}`}
          onClick={() => snesRedirect("snes", "/box")}
        >
          {boxIcon}
        </a>
        <a
          className={`mobileLink  ${boxActive ? "active" : ""}`}
          onClick={() => snesRedirect("snes", "/box")}
        >
          {boxIcon}
        </a>
      </Fragment>
    );

    if (!shouldShowBoxNav) {
      return null;
    }

    return (
      <Fragment>
        <li
          tabIndex="0"
          style={{ paddingTop: "0px" }}
          className="iconLink myBoxLink -forDesktop"
        >
          {boxNav}
        </li>
        <li
          style={{ paddingTop: "0px" }}
          className="iconLink myBoxLink -forMobile"
        >
          {boxNav}
        </li>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  const trueNorthFeature = state?.appFeatures?.find(
    (f) => f?.feature == "True North",
  );
  return {
    account: state.account,
    boxCount: boxCount(state),
    overlay: state.ui ? state.ui.overlay : null,
    isReallyLoggedIn:
      (state.account &&
        !["Visitor", "Lead", "User"].includes(state.policy.type)) ||
      false,
    seesUpsells: getMessages(state, "See upsells"),
    seesUpsellsCredit: getMessages(state, "See upsells credit"),
    trueNorthFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push }, dispatch);
}

export default withRegion(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(BoxNav),
);
