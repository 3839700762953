import React from "react";
import { Route } from "react-router-dom";
import loadable from "@loadable/component";

import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
const ExperimentDisplay = loadable(
  () => import("UI/_fred/experiments/ExperimentDisplay.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);

const Internal = (() => [
  <Route
    exact
    path="/current-experiments"
    component={ExperimentDisplay}
    key={"current-experiments"}
  />,
])();
export default Internal;
