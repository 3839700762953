import React from "react";
import { IMG } from "./SkeletonWrapper.jsx";

export const LifestyleHeaderSkeleton = ({ children, style, noBackground }) => (
  <div style={style}>
    <section
      className={
        "-lifestyleImage -noPadding" + (noBackground ? " -noBackground" : "")
      }
    >
      <div className="innerContainer">
        <IMG className="-heroImage -botyHero" width="100%" height="785px" />
      </div>
    </section>
    {children}
  </div>
);

export default LifestyleHeaderSkeleton;
