import { createSelector } from "reselect";

const getAccount = (state) => state.account;
const getLoginFlow = (state) => state.loginFlow;
const getCurrentCycle = (state) =>
  state.store && state.store.data && state.store.data.currentCycle;
const isPickingPeriodOpen = (state) =>
  state.store && state.store.data && state.store.data.pickingPeriodOpen;
const renewalTranch = (state) => state.account && state.account.tranche;
const getPolicy = (state) => state.policy;
const getStoreData = (state) => state.store?.data;

export const isYearlyMember = createSelector(
  getAccount,
  (account) => account?.renewalPlan?.cycles === 12,
);

export const renewalPlanId = createSelector(
  [getAccount],
  (account) => account?.renewalPlan?.id,
);

export const canPick = createSelector(
  [getAccount],
  (account) => account && account.cycleActions?.canPick,
);

export const canShip = createSelector(
  [getAccount],
  (account) => account && !account?.cycleActions?.cycleResponseIsShipment,
);

export const canShipWhenClosed = createSelector(
  [getAccount],
  (account) =>
    account &&
    account?.policy?.type === "Member" &&
    !account.cycleActions?.hasCycleResponse &&
    account.loyaltyStats &&
    account.loyaltyStats.months === 0 &&
    account.loyaltyStats.streakType === "Enrollment" &&
    (account.credits > 0 || account.cycles > 0),
);

export const canHaveFutureBox = createSelector(
  [getAccount],
  (account) =>
    account &&
    account.policy.canShip &&
    account.cycleActions?.hasCycleResponse &&
    (account.credits > 0 || account.cycles > 0 || account.renewalPlan),
);

export const hasShipped = createSelector(
  [getAccount],
  (account) => !!(account && account.cycleActions?.cycleResponseIsShipment),
);

export const hasSkipped = createSelector(
  [getAccount],
  (account) => !!(account && account.cycleActions?.cycleResponseIsSkip),
);

export const canSkip = createSelector(
  getAccount,
  hasSkipped,
  hasShipped,
  (account, hasSkipped, hasShipped) => {
    return (
      !!account &&
      !hasSkipped &&
      !hasShipped &&
      account?.policy?.canShip &&
      account?.policy?.type !== "Rejoin"
    );
  },
);

export const renewalDate = createSelector(
  renewalTranch,
  getAccount,
  getCurrentCycle,
  (renewalTranch, account, currentCycle) => {
    if (account.renewalPlan) {
      let cycleStartDate = new Date(currentCycle.startDate);
      cycleStartDate.setMonth(
        cycleStartDate.getMonth() + account.renewalPlan.cycles,
      );
      cycleStartDate.setDate(renewalTranch);
      return cycleStartDate;
    }
    return null;
  },
);

export const filedChargeback = createSelector(
  getAccount,
  (account) =>
    account?.policy?.type === "Ban" &&
    account?.policy?.subType === "Chargeback",
);

export const underAge = createSelector(
  getAccount,
  (account) =>
    account?.policy?.type === "Ban" && account?.policy?.subType === "Under Age",
);

export const deleteInfo = createSelector(
  getAccount,
  (account) =>
    account?.policy?.type === "Ban" &&
    account?.policy?.subType === "Delete Info",
);

export const mustRenew = createSelector(
  getPolicy,
  (policy) => policy.mustRenew,
);

export const canActivelyCancel = createSelector([], (i) => false);

export const canJoinAndShip = createSelector(
  getAccount,
  isPickingPeriodOpen,
  getStoreData,
  (acct, canPick) =>
    Boolean(
      (acct && // logged in and lead or rejoin with no credits
        ((canPick && acct?.cycleActions?.canRejoinWithShipment) ||
          acct?.policy?.type === "Lead" ||
          acct?.policy?.type === "Visitor" ||
          acct?.policy?.type === "User")) ||
        !acct, // not logged in
    ),
);

export const isACRejoin = createSelector(
  getLoginFlow,
  getPolicy,
  (lf, policy) =>
    lf === "rejoin-a" ||
    lf === "rejoin-c" ||
    lf === "rejoin-f" ||
    policy?.subType === "Rejoin A" ||
    policy?.subType === "Rejoin C" ||
    policy?.subType === "Gift Rejoin",
);

export const isCCFail = createSelector(
  getAccount,
  getStoreData,
  (acct, { ccFailurePolicy }) =>
    acct &&
    acct.policy &&
    acct.policy.type === "Rejoin" &&
    acct.policy.subType === "CC Fail",
);
