import React from "react";
import Button from "UI/components/Button.jsx";
import styled from "styled-components";
import Logo from "UI/elements/Logo.jsx";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { MEDIAQUERIES } from "CSS/Consts.js";
import LogoBom from "UI/elements/LogoBom";

const GiftPreviewModal = ({
  image,
  headline,
  subhead,
  sender,
  message,
  redeemText,
  isEmail,
  giftName,
  ...props
}) => {
  let giftNameLabel = giftName?.split("-")?.[0];
  giftNameLabel = giftNameLabel ? `${giftNameLabel}-month` : "";

  return (
    <StyledOuterContainer>
      <StyledHeaderContainer>
        <h4>{headline}</h4>
        <p>{subhead}</p>
        <div></div>
      </StyledHeaderContainer>
      <StyledLogoWrapper>
        {isEmail && <LogoBom width={227} />}
      </StyledLogoWrapper>
      <StyledInnerContainer>
        <img src={image}></img>
      </StyledInnerContainer>
      {isEmail && (
        <StyledRedeemTextWrapper>
          <h2>You might like it. </h2>
          <p>
            {sender ? sender : "[Name]"} has gifted you a {giftNameLabel}{" "}
            membership to Book of the Month. Get ready to enjoy a shortlist of
            the best new fiction we’ve come across. You pick what speaks to you.
            We send it your way.
          </p>
          <p>
            Use this code to redeem your gift:
            <br /> <StyledBold>[gift_code]</StyledBold>
          </p>
          <StyledButtonWrapper>
            <Button
              modifier={{ width: "295px", heigth: "48px" }}
              action={() => (
                <Link
                  className="link"
                  onClick={() => props.push("/gift")}
                ></Link>
              )}
              text={"Get your gift"}
            />
          </StyledButtonWrapper>
        </StyledRedeemTextWrapper>
      )}
    </StyledOuterContainer>
  );
};

export const StyledOuterContainer = styled.div`
  /* width: 594px; */
  /* height: 588px; */
  border-radius: 4px;
  background-color: var(--color-white);
  display: "flex";
  justify-content: center !important;
  justify-items: center !important;
  /* align-items: center; */
  align-content: center;
  padding: 0;
  text-align: center;
`;

export const StyledHeaderContainer = styled.div`
  /* margin-top: 60px; */
  display: "flex";
  justify-content: center !important;
  justify-items: center !important;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-gray2);
  @media screen and (max-width: 480px) {
    h4 {
      max-width: 293px !important;
      font-size: 24px !important;
      margin: 0 auto 8px auto !important;
    }
    p {
      font-size: 16px !important;
      max-width: 229px !important;
      margin: 0 auto !important;
    }
    padding: 0 !important;
    margin-bottom: 20px !important;
    padding-bottom: 20px !important;
  }
`;

export const StyledInnerContainer = styled.div`
  text-align: center;
  /* width: 514px; */
  /* height: 342px; */
  /* margin-top: 50px !important; */
  background-size: cover;
  margin: 0;
  img {
    width: 100%;
    /* height: 342px; */
    /* min-width: 375px;
    min-height: 250px */
  }

  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    img {
      width: 100% !important;
      /* height: auto; */
    }
  }
`;
export const StyledRedeemTextWrapper = styled.div`
  margin-top: 40px;

  h2 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    h2 {
      margin-bottom: 12px;
    }
  }
`;

export const StyledMesseageTextWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  &.ital {
    font-style: italic;
  }
`;

export const StyledBold = styled.span`
  font-weight: 900;
`;

export const StyledButtonWrapper = styled.div`
  margin: 30px 0;
`;

export const StyledA = styled.a`
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
`;

export const StyledLogoWrapper = styled.div``;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(GiftPreviewModal);
