import * as actions from "./actions";

export function showModal(modal) {
  return {
    type: actions.SET_ACTIVE_MODAL,
    payload: modal,
  };
}

export function hideModal() {
  return { type: actions.NO_ACTIVE_MODAL };
}
