import * as React from "react";

import styled from "styled-components";
import Link from "../../elements/navigation/Link";

export interface AvatarProps {
  showEdit?: boolean;
  handleClick?: (...args: any) => void;
  size?: number;
  left?: number;
  avatarImage?: string;
  editSize?: number;
  imgSize?: number;
  top?: number;
  bottom?: number;
  imgTop?: number;
  imgBottom?: number;
  isSocial?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
  showEdit,
  handleClick,
  size,
  left,
  avatarImage,
  editSize,
  imgSize,
  top,
  bottom,
  imgTop,
  imgBottom,
  isSocial,
}) => {
  const displayedAvatar = avatarImage ? avatarImage : "Happy";
  return (
    <StyledAvatar
      id={"StyledAvatar"}
      onClick={handleClick}
      size={size}
      left={left}
      imgSize={imgSize}
      top={top}
      bottom={bottom}
      imgTop={imgTop}
      imgBottom={imgBottom}
      isSocial={isSocial}
    >
      {isSocial ? (
        <img
          src={`https://static.bookofthemonth.com/Bookmojis/${avatarImage}`}
        />
      ) : (
        <img
          src={`//static.bookofthemonth.com/Bookmojis/${displayedAvatar}.svg`}
        />
      )}

      {showEdit && handleClick && (
        <Link
          text={"Edit avatar"}
          size={editSize ? editSize : 18}
          navigate={handleClick}
          data-test="edit-link"
        />
      )}
    </StyledAvatar>
  );
};

interface IStyledAvatar {
  size?: number;
  left?: number;
  imgSize?: number;
  top?: number;
  bottom?: number;
  imgTop?: number;
  imgBottom?: number;
  isSocial?: boolean;
}

export const StyledAvatar = styled.div<IStyledAvatar>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  width: ${(props) => (props.size ? `${props.size}px` : "100%")};
  margin-left: ${(props) => (props.left ? `${props.left}px` : "auto")};
  img {
    width: ${(props) => (props.imgSize ? `${props.imgSize}px` : "100%")};
    margin-top: ${(props) => (props.imgTop ? `${props.imgTop}px` : 0)};
    margin-bottom: ${(props) => (props.imgBottom ? `${props.imgBottom}px` : 0)};
    border-radius: ${(props) => (props.isSocial ? `100px` : "100%")};
  }
  p {
    text-align: center;
  }
`;
