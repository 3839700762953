import { push } from "connected-react-router";
import * as actions from "./actions";
import * as creators from "./creators";
import { setJoinFlow } from "../joinFlow/creators";

import * as accountApi from "../account/api";
import * as planApi from "../policy/planApi";
import * as svcApi from "../svc/api";

export const monthRestraints = [
  { promo: "GRAMMY", max: 1 },
  { promo: "REV55", max: 1 },
];

function setJoinStep(step) {
  return (dispatch) => {
    dispatch(creators.pureJoinStep(step));
    dispatch(push("/enroll/" + step));
  };
}

export function setJoinStepFrequency() {
  return setJoinStep(actions.JOIN_FREQUENCY);
}

export function setJoinStepGenre() {
  return setJoinStep(actions.JOIN_GENRE);
}

export function setJoinStepEmail() {
  return setJoinStep(actions.JOIN_EMAIL);
}

export function setJoinStepEmailFB() {
  return setJoinStep(actions.JOIN_EMAIL_FB);
}

export function setJoinStepShipping() {
  return setJoinStep(actions.JOIN_SHIPPING);
}

export function setJoinStepBilling() {
  return setJoinStep(actions.JOIN_BILLING);
}

export function setJoinStepConfirmation() {
  return setJoinStep(actions.JOIN_CONFIRMATION);
}

export function setJoinStepPlan() {
  return setJoinStep(actions.JOIN_PLAN);
}

export function setJoinStepLoggingIn() {
  return setJoinStep(actions.JOIN_LOGGING_IN);
}

export function setJoinStepProduct() {
  return setJoinStep(actions.JOIN_PRODUCT);
}

export function getJoinTaxRate(zip) {
  return async (dispatch) => {
    if (zip && zip.length >= 5) {
      try {
        const tax = await svcApi.getZipTax(zip);
        dispatch(creators.setJoinTaxRate(tax));
      } catch (err) {
        dispatch(creators.setJoinTaxRate(0));
      }
    }
  };
}

export const getPlan = (planId) => async (dispatch) => {
  const plan = await planApi.get(planId);
  dispatch(creators.setJoinPlan(plan));
};
