/**
 * QuoteOrderSummary.jsx
 * Shows the totals for the join purchase if a user has items in their box
 * Child of Billing.jsx
 */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BIcon from "UI/elements/BIcon.jsx";
import Spinner from "UI/elements/Spinner";
import { removeFromBox } from "State/box/flows";
import { ProductImage } from "UI/elements/product/ProductImage";
import { countryFuturePlanPrice } from "Utils/countryPricing";
import { useFreshQuote } from "UI/hooks/useQuote";
import { RegionContext } from "UI/contexts/RegionContext";
import { useWindowSize } from "UI/hooks";
import { WIDTHS } from "CSS/Consts";
import { snesRedirect } from "Utils/snesRedirect";

const QuoteOrderSummary = ({
  changePlanInline,
  account,
  box,
  promo,
  plan,
  mustRenew,
  isReferral,
}) => {
  const [removing, setRemoving] = useState(null);
  const { canadaMode } = useContext(RegionContext);
  const isCanadaMode = canadaMode || account?.countryCode == "CA";
  const futurePlanPrice = countryFuturePlanPrice(
    plan,
    account?.countryCode || isCanadaMode,
  );
  const quote = useFreshQuote({
    promoId: promo?.id,
    planId: plan?.id,
    countryCode: isCanadaMode ? "CA" : "US",
  });

  if (!plan || !quote) {
    return null;
  }

  let { lines } = quote;
  let hasBooks = !!box?.books?.length;
  let botmId = box?.books?.find((b) => b.isSelection)?.product?.id;
  let hasCommitment = mustRenew || promo?.mustRenew ? true : false;

  if (box?.quote?.lines?.products) {
    lines.products = box.quote.lines.products;
  }

  let itemLines = [],
    totalsLines = [];

  const freeItemPromo =
    lines["promo"] && promo && promo.adjustmentType === "Free Item";

  const [width] = useWindowSize();
  const isMobile = width < WIDTHS.PX.narrow;

  if (lines["subscription"]) {
    itemLines.push(
      <tr key="subscription">
        {(hasBooks && (
          <td key={1}>
            <div className="summaryPlan" style={{ textAlign: "center" }}>
              <BIcon size={isMobile ? 56 : 80} />
            </div>
          </td>
        )) ||
          null}
        <td key={2} colSpan={!hasBooks ? "2" : "1"}>
          <div className={!hasBooks ? "summaryPlan" : ""}>
            {!hasBooks && <BIcon />}
            <div className="-details" style={{ marginLeft: 12 }}>
              <h5>{lines["subscription"].description}</h5>
              {!hasCommitment && (
                <p className="miniText -finePrint">Skip or cancel anytime!</p>
              )}
              {changePlanInline}
            </div>
          </div>
        </td>
        <td key={3}>
          <span
            className={
              lines["subscription"].strikethrough && !freeItemPromo
                ? "strikethrough"
                : ""
            }
          >
            {lines["subscription"].price}
            {isReferral && (
              <>
                <br />
                <span className={"smallText strikethrough"}>
                  ${futurePlanPrice}
                </span>
              </>
            )}
          </span>
        </td>
      </tr>,
    );
  }

  if (lines["products"]) {
    // product lines
    for (let pLineName in lines["products"]) {
      let pLineValue = lines["products"][pLineName],
        productId = pLineName.split("-")[1],
        formattedText = [],
        action = null;

      for (let child of pLineValue.description.children) {
        const CustomTag = `${child.nodeType}`;
        let className = "";
        if (child.nodeType === "span") {
          className = "smallText";
        }
        if (child?.children?.[0]) {
          formattedText.push(
            <CustomTag className={className} key={child.nodeType}>
              {child.children[0].text}
            </CustomTag>,
          );
        }
      }

      if (botmId == productId) {
        action = (
          <a
            onClick={() => snesRedirect("snes", "/the-best-new-books")}
            className="miniText"
            tabIndex="0"
          >
            Change
          </a>
        );
      } else {
        action = (
          <a
            className="miniText"
            onClick={() => {
              setRemoving(productId);
              removeFromBox(productId);
            }}
          >
            {removing == productId ? (
              <Spinner size={"small"} position={"left"} />
            ) : (
              "Remove"
            )}
          </a>
        );
      }
      itemLines.push(
        <tr key={pLineName}>
          <td className="imageHolder">
            <ProductImage
              img={pLineValue?.icon?.split("/")?.pop()}
              size={"mini"}
              title={pLineValue?.title}
              custom={{ marginRight: 20 }}
            />
          </td>
          <td>
            {formattedText}
            <br />
            {action}
          </td>
          <td>
            <span className={pLineValue.strikethrough ? "strikethrough" : ""}>
              {pLineValue.price}
            </span>
          </td>
        </tr>,
      );
    }
  }

  if (lines["promo"]) {
    const promoPrice =
      freeItemPromo &&
      lines["products"] &&
      Object.keys(lines["products"]).length === 1
        ? "FREE"
        : lines["promo"].price;
    const promoLine = (
      <tr className="promoLineItem" key="promo">
        <td colSpan="2" className="smallText -bold">
          {lines["promo"].description}
        </td>
        <td className="smallText -bold">{promoPrice}</td>
      </tr>
    );
    if (freeItemPromo) {
      itemLines.push(promoLine);
    } else {
      itemLines = [...itemLines.slice(0, 1), promoLine, ...itemLines.slice(1)];
    }
  }

  if (lines["shipping"]) {
    itemLines.push(
      <tr className="separator" key={"shipping"}>
        <td colSpan="2">{lines["shipping"].description}</td>
        <td style={{ whiteSpace: "nowrap" }}>{lines["shipping"].price}</td>
      </tr>,
    );
  }
  if (lines["tax"]) {
    totalsLines.push(
      <tr key="tax">
        <td>{lines["tax"].description}</td>
        <td>{lines["tax"].price}</td>
      </tr>,
    );
  }
  if (lines["total"]) {
    totalsLines.push(
      <tr className="separator" key="total">
        <td>{lines["total"].description}</td>
        <td>{lines["total"].price}</td>
      </tr>,
    );
  }
  return (
    <div className="orderSummary">
      <h4>Order summary</h4>
      <table className="dataTable">
        <tbody>{itemLines}</tbody>
      </table>
      <table className="dataTable">
        <tbody>{totalsLines}</tbody>
      </table>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, removeFromBox }, dispatch);
}

export default connect(null, mapDispatchToProps)(QuoteOrderSummary);
