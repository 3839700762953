export const _FRIEND_TIER_STEPS = {
  step1: {
    image:
      "https://static.bookofthemonth.com/Relationship/Icons/Badge/Friend_Icon.svg",
    imageAlt: "Friend!",
    hed: "Welcome, Friend!",
    superCopy: null,
    dek: "Welcome to the wonderful world of perks! Let us show you around your new benefits.",
    showClose: true,
    buttonCTA: "See your perks",
    nextStep: "step2",
    logClickData: "clicked-friend-perk-modal-see-step2",
  },
  step2: {
    image:
      "https://static.bookofthemonth.com/Relationship/Friend/Book_Stack.gif",
    imageAlt: "Low price add-ons!",
    hed: "Lower Priced Add-Ons",
    superCopy: null,
    dek: `You’re now saving $5 on every add-on.${"\n"}You’re welcome!`,
    showClose: false,
    buttonCTA: "Next",
    nextStep: "step3",
    logClickData: "clicked-friend-perk-modal-see-step3",
  },
  step3: {
    image: "https://static.bookofthemonth.com/Relationship/Friend/HeartBox.gif",
    imageAlt: "A Roomier Box",
    hed: "A Roomier Box",
    superCopy: null,
    dek: "You can now choose up to 5 books each month.",
    showClose: false,
    buttonCTA: "Next",
    nextStep: "step4",
    logClickData: "clicked-friend-perk-modal-see-step3",
  },
  step4: {
    image:
      "https://static.bookofthemonth.com/Relationship/Friend/Heart_Books.png",
    imageAlt: "More choices",
    hed: "Even More Choices",
    superCopy: null,
    dek: "Now you can choose from member faves in addition to our monthly selections.",
    showClose: false,
    buttonCTA: "Next",
    nextStep: "step5",
    logClickData: "clicked-friend-perk-modal-see-step4",
  },
  step5: {
    image: "https://static.bookofthemonth.com/Relationship/Friend/BBB.png",
    imageAlt: "Blue Box Boutique",
    hed: "Blue Box Boutique",
    superCopy: null,
    dek: "Enjoy shopping exclusive items you won’t find anywhere else.",
    showClose: false,
    buttonCTA: "Next",
    nextStep: "step6",
    logClickData: "clicked-friend-perk-modal-see-step5",
  },
  step6: {
    image:
      "https://static.bookofthemonth.com/Relationship/Friend/Voting_Lolly_Icon_Friend.png",
    imageAlt: "Vote for Book of the Year",
    hed: "Vote for Book of the Year",
    superCopy: null,
    dek: "You’ve got a say in who gets the Lolly.",
    showClose: false,
    buttonCTA: "Next",
    nextStep: "step7",
    logClickData: "clicked-friend-perk-modal-see-step6",
  },
  step7: {
    image:
      "https://static.bookofthemonth.com/Relationship/Icons/Badge/Friend_Icon.svg",
    imageAlt: "We’re Friends!",
    hed: "We’re officially Friends!",
    superCopy: null,
    dek: "That’s it! Pretty cool, right? Enjoy your new rewards and thanks for being a member.",
    showClose: false,
    buttonCTA: "See your perks",
    nextStep: null,
    closeNGoPath: "/relationship-status",
    logClickData: "clicked-friend-perk-modal-view-now",
  },
};

export const _BFF_TIER_STEPS = {
  step1: {
    image:
      "https://static.bookofthemonth.com/Relationship/Icons/Badge/BFF_Icon.svg",
    imageAlt: "BFF",
    hed: "Hey there, BFF!",
    superCopy: null,
    dek: "BFFs get even more sweet perks, and yes, that includes gifts! It’s our fun way of saying thanks for your support.",
    showClose: true,
    buttonCTA: "Claim your first gift",
    nextStep: "step2",
    logClickData: "clicked-bff-perk-modal-clain-first-gift",
  },
  step2: {
    image: "https://static.bookofthemonth.com/Relationship/BFF/Tote.png",
    imageAlt: "Book tote",
    hed: "Reading looks good on you.",
    superCopy: null,
    dek: "First things first: All our BBFs get a free tote — and if you want it, yours is coming this month.",
    showClose: false,
    buttonCTA: null,
    nextStep: null,
    logClickData: null,
    specialActions: "toteActions",
  },
  step3: {
    image:
      "https://static.bookofthemonth.com/Relationship/BFF/BFF_Bday_Book.png",
    imageAlt: "BFF Birthday",
    hed: "Ain’t no birthday like a BFF birthday.",
    superCopy: null,
    dek: "Tell us your birthday and we’ll gift you a free add-on during your birthday month.",
    showClose: false,
    buttonCTA: null,
    nextStep: null,
    logClickData: null,
    specialActions: "birthdayActions",
  },
  step4: {
    image:
      "https://static.bookofthemonth.com/Relationship/Icons/Badge/BFF_Icon.svg",
    imageAlt: "We’re BFF’s!",
    hed: "You’re officially a BFF!",
    superCopy: null,
    dek: "See the other benefits you’ll get as a BFF. We think they’re pretty sweet!",
    showClose: false,
    buttonCTA: "See your perks!",
    nextStep: null,
    closeNGoPath: "/relationship-status",
    logClickData: "clicked-bff-perk-modal-view-now",
  },
};
