import * as actions from "./actions";

export function setWishlist(wishlist) {
  return {
    type: actions.SET_WISHLIST,
    payload: wishlist,
  };
}

export function setWishlistUpdateSuccess(wishlistUpdate) {
  return {
    type: actions.SET_WISHLIST_UPDATE_SUCCESS,
    payload: wishlistUpdate,
  };
}
export function setWishlistUpdateError(wishlistError) {
  return {
    type: actions.SET_WISHLIST_UPDATE_ERROR,
    payload: wishlistError,
  };
}
