import React from "react";
import styled from "styled-components";
import { COLORS } from "CSS/Consts.js";

const DynamicSelect = ({ ...props }) => {
  let {
    label,
    selectedValue,
    onChangeSelection,
    placeholder,
    autoComplete,
    required,
    error,
    name,
    id,
    selectOptions = [],
    size = "full",
    onBlur,
    onFocus,
    isInFocus,
    custom,
    textCustom,
    labelCustom,
    autoFocus,
  } = props;

  let inputSize;

  switch (size) {
    case "full":
      inputSize = "100%";
      break;
    case "half":
      inputSize = "49%";
      break;
    case "third":
      inputSize = "32%";
      break;
    case "halfPlus":
      inputSize = "60%";
      break;
    case "halfMinus":
      inputSize = "38%";
      break;
  }

  return (
    <StyledOuterWrapper size={inputSize}>
      <StyledInputWrapper error={error}>
        <select
          onChange={(ev) => onChangeSelection(ev.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          value={selectedValue ? selectedValue : ""}
          required={required}
          name={name}
          id={id}
          autoComplete={autoComplete}
          className="input"
        >
          <option value="" disabled selected>
            {placeholder}
          </option>
          {selectOptions.map((option) => {
            return (
              <option
                value={option?.value}
                key={option?.value}
                className="input"
              >
                {option?.label}
              </option>
            );
          })}
        </select>

        {label && (selectedValue || isInFocus) ? (
          <StyledLabelWrapper>
            <StyledLabel error={error}>{label}</StyledLabel>
          </StyledLabelWrapper>
        ) : null}
      </StyledInputWrapper>
      {error ? (
        <StyledErrorWrapper>
          <p>{error}</p>
        </StyledErrorWrapper>
      ) : null}
    </StyledOuterWrapper>
  );
};

export const StyledOuterWrapper = styled.div.attrs({
  className: "sc-StyledOuterWrapper",
})`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: 20px;
  width: ${(props) => (props.size ? `${props.size}` : `100%`)};
`;

export const StyledInputWrapper = styled.div.attrs({
  className: "sc-StyledInputWrapper",
})`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  font-size: 1.6rem;
  border: 1px solid;
  border-color: ${(props) =>
    props.error ? COLORS.error6 : "var(--color-gray2)"};
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  background: ${COLORS.white}
    url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzI4MkEyQyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K")
    no-repeat center right 20px;
  height: 54px;

  select {
    height: 100%;
    width: 105%;
    appearance: none;
    background: none;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  select:invalid {
    color: var(--color-gray4);
  }
`;

export const StyledLabelWrapper = styled.div.attrs({
  className: "sc-StyledLabelWrapper",
})`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${COLORS.white};
  padding-left: 4px;
  padding-right: 4px;
  left: 12px;
  top: -8px;
  border-radius: 3px;
`;

export const StyledLabel = styled.div.attrs({ className: "sc-StyledLabel" })`
  font-size: 12px;
  color: ${(props) => (props.error ? COLORS.error6 : COLORS.black)};
`;

export const StyledClearIt = styled.div.attrs({
  className: "sc-StyledClearIt",
})`
  position: absolute;
  top: 6px;
  right: 8px;
`;

export const StyledErrorWrapper = styled.div.attrs({
  className: "sc-StyledErrorWrapper",
})`
  padding-left: 8px;
  text-align: left;
  margin-top: 4px;
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${COLORS.error6};
  }
`;

export default DynamicSelect;
